var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasProduct
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "py-4 mt-6 ml-2", attrs: { outlined: "" } },
            [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("h3", { staticClass: "title font-weight-regular mb-2" }, [
                    _vm._v("Related Subscriptions"),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "my-2",
                    staticStyle: { "max-width": "120px" },
                    attrs: {
                      src: _vm.getBookCover(_vm.related_product),
                      alt: "related_product.display_title",
                    },
                    on: { error: _vm.loadDefaultBookCover },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.related_product.display_title)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      on: {
                        click: function ($event) {
                          return _vm.linkAction(_vm.related_product)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                View Premium Options\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }