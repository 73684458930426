var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "max-width": "300",
            transition: "fade-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _vm.isLoggedIn && !_vm.concurrent
                    ? _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "primary--text",
                            attrs: {
                              icon: "",
                              text: "",
                              role: "button",
                              "aria-label": "Cart",
                              href: _vm.getRoute("cart_page"),
                            },
                          },
                          on
                        ),
                        [
                          _vm.getItemsCount !== 0
                            ? [
                                _c(
                                  "v-badge",
                                  {
                                    staticClass: "mt-1 pt-1",
                                    attrs: { color: "accent2", right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                staticClass: "caption",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getItemsCount
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "black" } },
                                      [_vm._v("mdi-cart-outline")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _vm.isLoggedIn && !_vm.concurrent
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "black" } },
                                      [
                                        _vm._v(
                                          "\n                        mdi-cart-outline\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tooltipOpen,
            callback: function ($$v) {
              _vm.tooltipOpen = $$v
            },
            expression: "tooltipOpen",
          },
        },
        [
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "tooltip-content",
              on: { mouseenter: _vm.showTooltip, mouseleave: _vm.hideTooltip },
            },
            [_vm._v("Cart")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "max-width": "300",
            transition: "fade-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "primary--text",
                        attrs: {
                          icon: "",
                          text: "",
                          role: "button",
                          "aria-label": "Recent Updates",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleClick({
                              event: "fire",
                              value: "show-notification-modal",
                            })
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ma-0", attrs: { color: "black" } },
                        [
                          _vm._v(
                            "\n                    mdi-bell-outline\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tooltipNext,
            callback: function ($$v) {
              _vm.tooltipNext = $$v
            },
            expression: "tooltipNext",
          },
        },
        [
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "tooltip-content",
              on: {
                mouseenter: _vm.showTooltipNext,
                mouseleave: _vm.hideTooltipNext,
              },
            },
            [_vm._v("\n            Recent Updates\n        ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }