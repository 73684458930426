<template>
    <v-dialog
        width="500"
        v-model="dialog">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                    }">
                Delete Search
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="mr-2 accent2--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="mb-0 mt-5">
                            Are you sure you wish to delete this saved search?
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="deleteSearch()">
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                dialog: false,
                searchId: 0
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            showModal(id) {
                if (id) {
                    this.searchId = id;
                    this.dialog = true;
                }
            },
            deleteSearch() {
                this.$http.post(Routing.generate('delete_search', {
                        'searchId': this.searchId
                    })).then(response => {
                        this.dialog = false
                        this.searchId = 0;
                        EventBus.fire('get-saved-searches');
                    });
            }
        },
        created() {
            EventBus.listen('open-confirm-remove-search', (id) => this.showModal(id))
        }
    }
</script>
