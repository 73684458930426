<template>
    <div class="parent-tt">
        <div ref="typedElement"></div>
    </div>
</template>

<script>
import Typed from "typed.js";

export default {
    props: {
        text: {
            type: String,
            required: true
        },
        speed: {
            type: Number,
            required: false
        },
        stopped: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['start', 'done'],
    mounted() {

        /** @type {HTMLDivElement} */
        const rootElement = this.$refs.typedElement;

        const oThis = this;

        let typed = new Typed(rootElement, {
            strings: [this.text],
            typeSpeed: parseInt(this.speed) || 8,
            startDelay: 0,
            loop: false,
            showCursor: false,
            cursorChar: "|",
            autoInsertCss: true,
            onBegin: function (self) {
                oThis.$emit('start');
            },
            onStop(arrayPos, self) {
                oThis.$emit('done');
            },
            onComplete: function (self) {
                oThis.$emit('done');
            },
        });

        this.$watch(() => this.stopped, (newValue) => {

            if (newValue) {
                typed.stop();
            }
        });
    }
}
</script>
