var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4", attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0 px-3" },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "font-weight-bold mb-0" }, [
                      _vm._v(
                        "\n                        Saved Searches (" +
                          _vm._s(
                            _vm.savedSearches.length
                              ? _vm.savedSearches.length
                              : 0
                          ) +
                          ")\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-3" },
                    [
                      _vm.loading
                        ? _c("v-progress-linear", {
                            attrs: {
                              color: "grey lighten-2",
                              indeterminate: "",
                              rounded: "",
                              height: "6",
                            },
                          })
                        : [
                            _vm.savedSearches.length
                              ? _c(
                                  "v-list",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { dense: "", subheader: "" },
                                  },
                                  _vm._l(
                                    _vm.savedSearches,
                                    function (result, index) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: index,
                                          attrs: { href: result.url },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.clickedSavedSearch(
                                                result
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { attrs: { href: result.url } },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(result.label) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-action",
                                            { staticClass: "ma-0" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 pa-0 right",
                                                  attrs: {
                                                    "x-small": "",
                                                    text: "",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.deleteSearch(
                                                        result.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "\n                                            delete\n                                        "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "mb-0 caption" }, [
                                  _vm._v(
                                    "\n                            No saved searches\n                        "
                                  ),
                                ]),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm-remove-search"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }