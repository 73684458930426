var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "grey lighten-4", attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "py-1 px-3 pb-3" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", dense: "" } },
            [
              _c("v-col", [
                _c("p", { staticClass: "font-weight-bold mb-0" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.title) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "", small: "", color: "black" },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.expand = !_vm.expand
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.expand ? "mdi-minus" : "mdi-plus") +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(_vm.componentName, {
            tag: "component",
            attrs: { expand: _vm.expand },
            on: {
              closeExpand: () => (_vm.expand = false),
              updateAdvancedSearch: (value) => this.updateAdvancedSearch(value),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }