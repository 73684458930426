<template>
    <div>
        <template v-if="pdf.access">
            <v-layout row class="hidden-md-and-up">
                <v-flex class="text-xs-right d-inline pt-2">
                    <span class="font-weight-bold">Adjust font Size:</span>
                    <v-btn fab small color="primary" @click="zoomOut()">
                        <v-icon dark>remove</v-icon>
                    </v-btn>
                    <v-btn fab small color="primary" @click="zoomIn()">
                        <v-icon dark>add</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-row>
                <v-col
                    :class="{
                        'pa-2': !getLoggedIn ,
                        'pr-12': !isMobilePort
                        }">
                    <pdf-iframe
                        v-if="!stillLoading"
                        :url="pdf.pdf_url_full"
                        :pdf="pdf"
                        :contentProtection="contentProtection">
                    </pdf-iframe>
                    <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"></v-progress-circular>
                </v-col>
            </v-row>
            <back-to-top></back-to-top>
        </template>
        <template v-else-if="stillLoading">
            <v-layout row>
                <v-flex xs12 text-center py-12>
                    <v-progress-circular
                        indeterminate
                        color="primary"></v-progress-circular>
                </v-flex>
            </v-layout>
        </template>
        <template v-else>
            <v-layout>
                <v-flex :class="{ 'pa-2': !getLoggedIn }">
                    <p class="text-center my-12">You do not have access to this book.</p>
                </v-flex>
            </v-layout>
        </template>
    </div>
</template>

<script>
    import BackToTop from '../../partials/buttons/back_to_top'
    import PdfIframe from './iframe.vue';
    import Mobile from '../../inc/mixins/mobile';
    import {AppParameters} from "../../../AppParameters";
    export default {
        props: ['youtubeid', 'buylink', 'documentid', 'contentProtection'],
        data() {
            return {
                pdf: [],
                loaded: false,
                zoom: 1,
            }
        },
        computed: {
            isIE11() {
                return !!window.MSInputMethodContext && !!document.documentMode;
            },
            getColor() {
                if (this.getLoggedIn) {
                    return 'accent2';
                }
                return 'primary';
            },
            getLoggedIn() {
                if (this.$store.getters.getLoggedIn !== '') {
                    return true;
                }
                return false;
            },
            stillLoading() {
                if (this.loaded) {
                    return false;
                }
                return true;
            }
        },
        components: {
            BackToTop,
            PdfIframe
        },
        mixins: [Mobile],
        methods: {
            updatePDF(data) {
                this.loaded = false;
                this.pdf = data;

                if (AppParameters.astm_remove_links && this.pdf.is_astm) {
                    this.pdf['pdf_url_full'] += '&removelinks=1';
                }

                if (this.pdf.access) {
                    let iframe = document.getElementById('pdfViewer');
                    if (iframe) {
                        setTimeout(function(){
                            iframe.contentWindow.location.reload();
                        }, 500);
                    }
                }
                if (_.toNumber(this.contentProtection)) {
                    window.addEventListener('keydown', (event) => {
                        if (event.ctrlKey && (event.key == "p" || event.charCode === 16 || event.charCode === 112 || event.keyCode === 80) ){
                            if (this.isIE11) {
                                alert('Printing functionality is restricted without a Digital Codes Premium subscription.');
                            }
                            event.preventDefault();
                        }
                    });
                }
                this.loaded = true;
            },
            pdfEvent(id) {
                let iframe = window.frames['pdfViewer'].contentDocument != undefined ? window.frames['pdfViewer'].contentDocument : window.frames['pdfViewer'].document;
                iframe.getElementById(id).click();
            },
            zoomIn() {
                window.frames['pdfViewer'].postMessage('zoomIn');
            },
            zoomOut() {
                window.frames['pdfViewer'].postMessage('zoomOut');
            },
            reloadIframe() {
                window.frames['pdfViewer'].location.reload();
            }
        },
        created() {
            EventBus.listen('update-pdf-url', (data) => this.updatePDF(data));
            EventBus.listen('pdf-event', (data) => this.pdfEvent(data));
        },
        mounted() {
            this.$nextTick(() => {
                // noinspection JSCheckFunctionSignatures
                window.addEventListener('resize', _.debounce(this.reloadIframe, 500));
            });
        }
    }
</script>
