<template>
    <v-tooltip 
        bottom
        v-if="accessDescriptors[accessLevel]"
        max-width="300"
        transition="fade-transition"
        v-model="tooltipOpen">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                v-bind="attrs"
                v-on="on"
                :color="accessDescriptors[accessLevel].backGroundColor"
                size="24"
                tabindex="0"
                @focus="tooltipOpen" 
                @blur="tooltipOpen"
                @keydown.enter="tooltipOpen"
                @keydown.space="tooltipOpen">
                <v-icon small :color="accessDescriptors[accessLevel].iconColor">
                    {{ accessDescriptors[accessLevel].icon }}
                </v-icon>
            </v-avatar>
        </template>
        <span class="tooltip-content"
            @mouseenter="showTooltip"
            @mouseleave="hideTooltip">{{ accessDescriptors[accessLevel].description }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'AccessIcon',
        data() {
            return {
                accessDescriptors: {
                    'basic': {
                        icon: 'mdi-lock-open-variant-outline',
                        iconColor: 'primary',
                        backGroundColor: 'primaryLight',
                        description: 'Basic read only access available.',
                    },
                    'premium': {
                        icon: 'mdi-lock-outline',
                        iconColor: 'grey darken-4',
                        backGroundColor: 'grey lighten-2',
                        description: 'Premium subscription required.',
                    },
                    'premiumActive': {
                        icon: 'mdi-creation',
                        iconColor: 'grey darken-4',
                        backGroundColor: 'accent2Light',
                        description: 'Premium tools and features active.',
                    },
                }
            }
        },
        props: {
            accessLevel: {
                type: String,
                required: true
            }
        }
    }
</script>