var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { flat: "", rounded: "", color: _vm.options && _vm.options.color },
    },
    [
      _c(
        "v-card-text",
        [
          _vm.image
            ? _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("img", {
                      class: _vm.image.class,
                      attrs: {
                        width: _vm.image.width,
                        src: _vm.asset(
                          require("../../images/" + _vm.image.src),
                          "default"
                        ),
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0" }, [
                _c("h2", {
                  staticClass: "primary--text font-weight-regular line-height",
                  domProps: { innerHTML: _vm._s(_vm.heading) },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm._t("description"),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.description) },
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }