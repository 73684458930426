var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v(
                "\n            Pending Enterprise Invitation " +
                  _vm._s(
                    _vm.enterpriseInvitations.length > 1
                      ? `${_vm.enterprise + 1} of ${
                          _vm.enterpriseInvitations.length
                        }`
                      : ""
                  ) +
                  "\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeInvitationAlertBox()
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _vm.message
                ? _c(
                    "v-alert",
                    {
                      attrs: { type: _vm.type, dismissible: "" },
                      on: {
                        input: function ($event) {
                          return _vm.closeAlert()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.message) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h3",
                      { staticClass: "font-weight-regular text-center" },
                      [
                        _vm._v(
                          "\n                        You've been invited by "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.activeEnterprise
                                ? _vm.activeEnterprise.name
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(
                          " to join their organization on ICC Digital Codes. You can accept or decline the invitation. If you decline, you won't see the invitation again unless you receive a new one.\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "grey lighten-4 text-center" },
            [
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "atuo" } },
                    [
                      _vm.done
                        ? [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.closeInvitationAlertBox()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Close\n                        "
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirm()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Accept\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirm(true)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Decline\n                        "
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }