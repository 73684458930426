<template>
    <div>
        <back-to-main-menu
            @backToMain="backToMain">
        </back-to-main-menu>
        <v-list-item class="mt-2">
            Premium Tools
        </v-list-item>
        <v-card
            v-if="(!isActiveUser || canStartTrial) && !hasActiveTrial && !hasCompleteSubscription"
            flat
            class="accent2Light ma-4 rounded-lg">
            <v-card-text>
                <v-row dense>
                    <v-col cols="auto">
                        <v-icon
                            color="accent2">
                            mdi-creation
                        </v-icon>
                    </v-col>
                    <v-col>
                        <p class="font-weight-bold">
                            Get full access to these features and more with a Premium subscription!
                        </p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <start-trial-button
                            block
                            color="accent2">
                        </start-trial-button>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <link-list-icons :links="links">
        </link-list-icons>
    </div>
</template>

<script>
import LinkListIcons from "./LinkListIcons.vue";
import BackToMainMenu from "./BackToMainMenu.vue";
import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
import {AppParameters} from "../../AppParameters";
import StartTrialButton from "../buttons/StartFreeTrial.vue";

export default {
    name: 'PremiumToolsPanel',
    components: {StartTrialButton, LinkListIcons, BackToMainMenu},
    mixins: [megaMenuMixin],
    data() {
        return {
            links: [
                {
                    icon: 'mdi-navigation-outline',
                    iconColor: 'accent2',
                    title: 'ICC AI Navigator',
                    link: this.getRoute('ai_navigator'),
                    active: AppParameters.ai_navigator_enabled
                },
                {
                    icon: 'mdi-forum-outline',
                    iconColor: 'accent2',
                    title: 'Premium Answers',
                    chip: 'New',
                    link: this.getRoute('premium_answers'),
                    active: AppParameters.enable_premium_answers_page
                },
                {
                    icon: 'mdi-calculator-variant-outline',
                    iconColor: 'accent2',
                    title: 'Compliance Calculators',
                    chip: 'New',
                    link: this.getRoute('compliance_calculators'),
                    active: AppParameters.compliance_calculators_enabled
                },
                {
                    icon: 'mdi-text-box-search-outline',
                    iconColor: 'accent2',
                    title: 'Advanced Search',
                    link: this.getRoute('sections_search_page', {section: 'content'}),
                    active: true,
                    checkAccess: true
                },
                {
                    icon: 'mdi-clock-fast',
                    iconColor: 'accent2',
                    title: 'Quick Access',
                    link: this.getRoute('quick_access'),
                    active: true,
                    checkAccess: true
                },
                {
                    icon: 'mdi-tag-multiple-outline',
                    iconColor: 'accent2',
                    title: 'My Project Tags',
                    link: this.getRoute('dashboard_my_tags'),
                    active: true,
                    checkAccess: true
                },
                {
                    icon: 'mdi-bookmark-multiple-outline',
                    iconColor: 'accent2',
                    title: 'My Notes and Bookmarks',
                    link: this.getRoute('notes'),
                    active: true,
                    checkAccess: true
                },
                {
                    icon: 'mdi-history',
                    iconColor: 'accent2',
                    title: 'Sharing History',
                    link: this.getRoute('dashboard_sharing'),
                    active: true,
                    checkAccess: true
                }
            ]
        }
    }
}
</script>
