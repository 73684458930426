<template>
    <div>
        <v-dialog
            v-model="assignModal"
            persistent
            :key="modalKey"
            max-width="800px"
            scrollable>
            <v-card flat>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Assign Subscription
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="confirmCloseIfStarted()">close</v-icon>
                </v-card-title>
                <v-stepper
                    v-model="step"
                    :key="modalKey">
                    <v-stepper-header class="steps_header">
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                            :rules="[() => rules.email]">
                            Enter Email
                            <p>
                                <small
                                    v-if="step > 1 && emails"
                                    class="font-weight-bold">
                                    Email(s): {{emails.length}}
                                </small>
                                <small v-else-if="errorMessage">{{errorMessage}}</small>
                            </p>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                            :rules="[() => rules.subscriptions]">
                            Select Books
                            <p>
                                <small
                                    v-if="step > 2 && select"
                                    class="font-weight-bold">
                                    Subscriptions: {{select.length}}
                                </small>
                                <small v-else-if="errorMessage">{{errorMessage}}</small>
                            </p>
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="step > 3"
                            step="3">
                            Notify
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card flat>
                                <v-card>
                                    <v-row>
                                        <v-col>
                                            Type email address for whom you want to send.
                                            <p>
                                                <small class="font-weight-bold black--text">Note:</small> <small class="black--text">To add multiple recipients, please add a comma after each email address, each Email address is requiring one license!</small>
                                            </p>
                                            <v-text-field
                                                data-qa="dashboard-licenses-assign-email"
                                                solo
                                                id="emailId"
                                                label="Enter email address"
                                                v-model="input_emails">
                                            </v-text-field>
                                            <small v-if="validatedUsers.invalid">
                                                <span class="red--text text--lighten-1 font-weight-bold">Invalid Email(s):</span>
                                                <span
                                                    class="font-weight-bold"
                                                    v-for="email in validatedUsers.invalid">
                                                    {{email}},
                                                </span>
                                            </small>
                                            <br>
                                            <small v-if="validatedUsers.valid">
                                                <span class="green--text text--lighten-1 font-weight-bold">Valid Emails:</span>
                                                <span
                                                    class="font-weight-bold"
                                                    v-for="email in validatedUsers.valid">
                                                    {{email}},
                                                </span>
                                            </small>
                                        </v-col>
                                    </v-row>
                                    <v-btn
                                        color="primary"
                                        @click="validateInputs()">
                                        <span v-if="!loading">Continue</span>
                                        <v-progress-circular
                                            v-else
                                            :size="20"
                                            indeterminate color="white">
                                        </v-progress-circular>
                                    </v-btn>
                                </v-card>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <span class="black--text">Select subscriptions which you want to assign</span>
                            <p
                                v-if="rules.subscriptions === true && step <= 2"
                                class="black--text">
                                <small>This will only show subscriptions that have <b>{{emails.length}}</b> or more available licenses!</small>
                                <alert
                                    v-if="alreadyAssigned"
                                    :message="alreadyAssignedMessage"
                                    notificationType="warning"
                                    dismissible
                                    @dismiss="alreadyAssigned = false"
                                    >
                                </alert>
                            </p>
                            <v-combobox
                                data-qa="dashboard-licenses-assign-subscription-select"
                                solo
                                multiple
                                v-if="this.subscriptions.length !== 0"
                                v-model="select"
                                :items="getSortedSubscriptions()"
                                item-text="title"
                                label="Select subscriptions"
                                chips
                                deletable-chips
                                tags>
                                <template slot="item" slot-scope="data">
                                    <template>
                                        <v-list-item-avatar>
                                            <img :src="data.item.avatar">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.title"></v-list-item-title>
                                            <v-list-item-subtitle v-html="'Available licenses: ' + data.item.licenses_available"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-combobox><br/>
                            <v-btn v-if="this.subscriptions.length !== 0" color="primary" @click="validateSubscriptions()">Continue</v-btn>
                            <v-btn
                                data-qa="dashboard-licenses-assign-back-button"
                                @click="updateSelect()">
                                Back
                            </v-btn>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <p class="black--text">Leave a message and assign subscriptions</p>
                            <v-textarea
                                data-qa="dashboard-licenses-assign-message"
                                solo
                                v-model="message"
                                color="primary">
                                <div slot="label">
                                    Message <small>(optional)</small>
                                </div>
                            </v-textarea>
                            <v-btn
                                data-qa="dashboard-licenses-assign-button"
                                color="primary"
                                @click="assignSubscription()"
                                :disabled="loading">
                                <span v-if="!loading">Assign Subscriptions</span>
                                <v-progress-circular
                                    v-else
                                    :size="20"
                                    indeterminate color="white">
                                </v-progress-circular>
                            </v-btn>
                            <v-btn
                                data-qa="dashboard-licenses-assign-back-button"
                                @click.native="step = 2">
                                Back
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>
        <v-dialog v-model="endAssignModalConfirm" max-width="500px">
            <v-card>
                <v-card-title>
                    Please confirm
                </v-card-title>
                <v-card-text>
                    <p>You have entered information that will not be saved when you exit this screen. Are you sure you want to leave?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        light
                        @click="endAssignModalConfirm=false">
                        Cancel
                    </v-btn>
                    <v-btn
                        light
                        color="primary"
                        @click="close()">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import EmailBind from "../mixins/susbcriptions_email";
    import Mobile from '../../../inc/mixins/mobile';
    import Alert from "../../../notifications/AlertBar.vue";

    export default {
        props:{
            userId: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                validatedUsers: {},
                assignModal: false,
                endAssignModalConfirm: false,
                loading: false,
                modalKey: 0,
                email_alert: true,
                emails: [{
                    email: ''
                }],
                subscriptions: [],
                select: [],
                step: 1,
                rules: {
                    'email': true,
                    'subscriptions': true
                },
                input_emails:'',
                errorMessage:'',
                message: '',
                alreadyAssigned: false,
                alreadyAssignedMessage: '',
                existingAssignments: {
                    emails: [],
                    books: []
                }
            }
        },
        components: {
            Alert
        },
        mixins: [
            EmailBind,
            Mobile
        ],
        methods: {
            confirmCloseIfStarted() {
                if (this.input_emails) {
                    this.endAssignModalConfirm = true;
                } else {
                    this.close();
                }
            },

            assignSubscription(){
                this.loading = true;
                let data = {
                    emails: this.emails,
                    subscriptions: this.select,
                    message: this.message,
                    userId: this.userId
                };

                this.$http.post(Routing.generate('assign_subscription', data))
                    .then(response => {
                        this.loading = false;
                        this.close();
                        this.updateSelect();
                        this.sendEmailNotification(this.ASSIGN_SUBSCRIPTION, data);
                        this.$session.set('switch-to-license-tab', 'AssignedByMe');
                        this.reloadSubscriptions();
                    });
            },
            getSortedSubscriptions(){
                return _.orderBy(this.subscriptions, ['year', 'title'], ['desc', 'asc']);
            },
            getSubscriptions(){
                const assigneeEmails = this.emails.map(email => email.email);

                this.$http.get(Routing.generate('get_assign_subscription', {userId: this.userId, emails: assigneeEmails}))
                    .then(response => {
                        const {assignedSubscriptions, subscriptions} = response.data;

                        let unassignableSubscriptions = new Set();
                        let unassignableEmails = [];
                        for (let email in assignedSubscriptions) {
                            if (assignedSubscriptions.hasOwnProperty(email)) {
                                unassignableEmails.push(email);

                                assignedSubscriptions[email].forEach(bookSku => {
                                    unassignableSubscriptions.add(bookSku);
                                });
                            }
                        }

                        if (unassignableSubscriptions.size > 0) {
                            this.existingAssignments.emails = unassignableEmails;
                            this.alreadyAssignedMessage = `Subscriptions already assigned to ${unassignableEmails.map(email => `<b>${email}</b>`).join(', ')} have been disabled for selection.`;
                            this.alreadyAssigned = true;
                        } else {
                            this.alreadyAssigned = false;
                        }

                        for(let i=0; i<= subscriptions.length; i++){
                            if(subscriptions[i] !== undefined){
                                let licenses = subscriptions[i]['license_configuration'].available;
                                    if(licenses >= this.emails.length) {

                                        let orderId = subscriptions[i]['id'];
                                        let expDate = subscriptions[i]['expiration_date'];
                                        let titleSuffix = (orderId && expDate) ? ` (#${orderId}, Exp. ${expDate})` : '';

                                        this.subscriptions.push({
                                            title: this.getBookTitle(subscriptions[i].document_info) + titleSuffix,
                                            id: subscriptions[i].id,
                                            expiration_date: subscriptions[i].expiration_date,
                                            avatar: this.getBookCover(subscriptions[i].document_info),
                                            orderId: subscriptions[i].purchase_id,
                                            bookSku: subscriptions[i].book_sku,
                                            licenses_available: licenses,
                                            year: subscriptions[i].document_info.year.year,
                                            disabled: unassignableSubscriptions.has(subscriptions[i].book_sku),
                                        });

                                    }
                                }
                        }

                        this.subscriptions.sort(function(a, b) {
                            let titleA = a.title.toUpperCase(), // ignore upper and lowercase
                                titleB = b.title.toUpperCase(); // ignore upper and lowercase
                            if (titleA < titleB) {
                                return -1;
                            }
                            if (titleA > titleB) {
                                return 1;
                            }
                            // names must be equal
                            return 0;
                        });

                        this.subscriptions = this.subscriptions.sort();
                        if(this.subscriptions.length === 0){
                            this.rules.subscriptions = false;
                            this.errorMessage = 'Sorry you do not have any subscriptions with '+this.emails.length+' or more licenses!';
                        }else{
                            this.rules.subscriptions = true;
                        }
                        this.loading = false;
                        this.step = 2;
                    });
            },

            getAvailableLicense(licenses){
                let result =0;
                for(let i = 0; i <= licenses.length; i++){
                    if(licenses[i] !== undefined){
                        result = result + licenses[i].license_configuration.available;
                    }
                }

                return result;
            },
            validateInputs() {
                this.emails = [{'email': ''}];
                this.errorMessage = '';
                this.rules.email = true;
                // Check multiple recepients
                if ('' !== this.input_emails.trim() && this.input_emails.indexOf(',') !== -1) {
                    var receipents = this.input_emails.split(',');
                    for (var i = 0; i < receipents.length; i++) {
                        var cleanInput = receipents[i].trim();
                        receipents[i] = {'email': cleanInput};
                    }
                    this.emails = receipents;
                } else {
                    this.emails[0].email = this.input_emails.trim();
                }
                if(!this.checkIfIsEmpty(this.emails)) {
                    this.rules.email = false;
                    this.errorMessage = 'Please enter email.';
                } else if(!this.validateEmail(this.emails)) {
                    this.rules.email = false;
                    this.errorMessage = 'Please enter valid Email(s).';
                } else {
                    this.getSubscriptions();
                }
            },
            checkIfIsEmpty(emails){
                for(let i = 0; i < emails.length; i++) {
                    if (emails[i].email.length === 0) {
                        return false;
                    }
                }
                return true;
            },
            validateEmail(emails) {
                let re = /^([\w-]+(?:\.[\w\-\+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                for(let i = 0; i < emails.length; i++) {
                    if (re.test(emails[i].email) === false) {
                        return false;
                    }
                }
                return true;
            },

            updateSelect(){
                this.select = [];
                this.subscriptions = [];
                this.step = 1;
                this.emails = [{'email': ''}];
                this.input_emails = '';
                this.errorMessage = '';
            },

            validateSubscriptions() {
                this.rules.subscriptions = true;
                this.errorMessage = '';
                if(this.select.length > 0){
                    this.step = 3;
                }else {
                    this.rules.subscriptions = false;
                    this.errorMessage = 'Please select at least one subscription to assign.';
                }
            },

            close() {
                this.assignModal = false;
                this.modalKey +=1;
                this.endAssignModalConfirm = false;
            },

        },
        created() {
            EventBus.listen('show-modal-assign-subscription', () => this.assignModal = true);
        },
    }

</script>
