<template>
    <v-container class="pa-0">
        <v-row
            dense
            align="end">
            <v-col
                cols="12"
                sm="4">
                <p class="caption text-left mb-0">Quantity</p>
                <v-text-field
                    dense
                    outlined
                    hide-details
                    class="mt-1"
                    readonly
                    :value="item.quantity"
                    type="number"
                    :min="1">
                </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6">
                <v-btn
                    fab
                    x-small
                    class="mx-1 mb-1"
                    depressed
                    :dark="item.quantity > 1"
                    :disabled="item.quantity === 1"
                    @click="handleChange('decrement')"
                    color="primary">
                    <v-icon dark>remove</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    x-small
                    class="mx-1 mb-1"
                    depressed
                    @click="handleChange('increment')"
                    color="primary">
                    <v-icon dark>add</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import debounce from "lodash.debounce";
    export default {
        props: {
            item: {
                required: true,
                type: Object
            },
            request: {
                required: false,
                type: String
            },
            type: {
                required: false,
                type: String
            }
        },
        methods: {
            handleChange: debounce(function(type) {
                this.quantityTrigger(type);
            }, 500),
            quantityTrigger(type) {
                if (this.request && this.request === 'modal') {
                    if (type === 'increment')
                        EventBus.fire('increment-quantity-modal-'+this.type, this.item);
                    if (type === 'decrement')
                        EventBus.fire('decrement-quantity-modal-'+this.type, this.item);
                } else {
                    if (type === 'increment')
                        EventBus.fire('increment-quantity', this.item);
                    if (type === 'decrement')
                        EventBus.fire('decrement-quantity', this.item);
                }

            }
        },
        beforeUnmount() {
            this.handleChange().cancel();
        }
    }
</script>
