var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expand,
            expression: "expand",
          },
        ],
      },
      [
        _vm._l(_vm.visibleTitleScopes, function (value, index) {
          return _c(
            "div",
            { key: index, staticClass: "pb-2" },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    outlined: _vm.isActiveTitleGroup !== value.key,
                    "text-color":
                      _vm.isActiveTitleGroup === value.key
                        ? "primary"
                        : "black",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.activeScope(value)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getGroupName(value)) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        _vm._v(" "),
        _vm.activeTitleGroup
          ? _c(
              "div",
              { staticClass: "text-right mt-3" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", small: "", color: "gray darken-2" },
                    on: {
                      click: function ($event) {
                        return _vm.removeTitleScope()
                      },
                    },
                  },
                  [_vm._v("\n                Reset\n            ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.expand,
            expression: "!expand",
          },
        ],
      },
      [
        _vm.activeTitleGroup
          ? _c(
              "v-chip",
              {
                staticClass: "pr-1 mt-1 text-wrap",
                staticStyle: { height: "auto" },
                attrs: {
                  "x-small": "",
                  color: "primaryLight",
                  "text-color": "primary",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(this.activeTitleGroup.title) +
                    "\n            "
                ),
                _c(
                  "v-icon",
                  {
                    attrs: { size: "19" },
                    on: {
                      click: function ($event) {
                        return _vm.removeTitleScope()
                      },
                    },
                  },
                  [_vm._v("close\n            ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }