var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.categories.length
    ? _c(
        "v-container",
        { attrs: { fluid: !_vm.isExtraLarge } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c("v-col", [
                        _c("h3", { staticClass: "text-h6 font-weight-bold" }, [
                          _vm._v(
                            "\n                        Categories\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.categories, function (category, key) {
              return _c(
                "v-col",
                { key: key, attrs: { cols: "auto" } },
                [
                  _c(
                    "v-chip",
                    {
                      attrs: { outlined: "", href: _vm.getLink(category.slug) },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(category.label) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }