var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "data-qa": "share-section-modal",
        scrollable: "",
        persistent: "",
        "max-width": "700px",
        fullscreen: _vm.isMobilePort,
      },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v("\n            Share This Section Link\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: { click: _vm.closeShareModal },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _vm.alert_message
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                light: "",
                                dismissible: "",
                                value: _vm.alert_status,
                                type: _vm.alert_type,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.alert_message) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.emails, function (email, index) {
                return _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                "data-qa": "share-section-modal-email-" + index,
                                label: "Email",
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                              },
                              model: {
                                value: email.email,
                                callback: function ($$v) {
                                  _vm.$set(email, "email", $$v)
                                },
                                expression: "email.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            index === 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      "data-qa": "share-section-modal-add-more",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNewEmail()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Add More\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      "data-qa":
                                        "share-section-modal-remove-" + index,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeEmail(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Remove\n                        "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          "data-qa": "share-section-modal-textarea",
                          id: "share-message-" + _vm.content_id,
                          outlined: "",
                          rows: "5",
                          "auto-grow": "",
                          "hide-details": "",
                          value:
                            "Hi,\n\nPlease check out this link to the " +
                            _vm.book_name +
                            " for " +
                            _vm.section_title +
                            " \n\n" +
                            _vm.url,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-qa": "share-section-modal-close",
                    color: "primary",
                    text: "",
                  },
                  on: { click: _vm.closeShareModal },
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-qa": "share-section-modal-copy-url",
                    color: "primary",
                  },
                  on: { click: _vm.copyURL },
                },
                [_vm._v("\n                Copy URL\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-qa": "share-section-modal-share",
                    color: "primary",
                    loading: _vm.shareLoading,
                    disabled: _vm.shareLoading,
                  },
                  on: { click: _vm.inputFiledValidation },
                },
                [_vm._v("\n                Share\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }