import axios from 'axios';

export class QuickConsultUserCreditsApi {

    static async getUserCreditsInfo() {
        return axios.get(Routing.generate('get_user_credits')).then((res) => {
            return res.data;
        });
    }

    static async processUnhandledAnswerDeclinedQuestions() {
        return axios.post(Routing.generate('return_credits_for_declined_questions')).then((res) => {
            return res.data;
        });
    }

    static async logCreditPurchaseActions(
        sessionId,
        selectedQuantity,
        selectedPrice,
        lastAction,
        isCancelled,
        orderId,
        orderStatus,
    ) {
        return axios.post(
            Routing.generate('log_credit_purchase_actions'),
            {
                session_id: sessionId,
                selected_quantity: selectedQuantity,
                selected_price: selectedPrice,
                last_user_action: lastAction,
                user_canceled: isCancelled,
                order_id: orderId,
                order_status: orderStatus,
            }
        ).then((res) => {
            return res.data;
        });
    }
}
