<template>
    <v-dialog
        v-model="dialog_process_annotation_bookmark"
        width="500">
        <v-card flat>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                {{ action }} Notes
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="dialog_process_annotation_bookmark = false">close</v-icon>
            </v-card-title>
            <v-card-text class="text-center pt-8">
                <p> {{ message }} </p>
                <v-alert
                    :value="error_status"
                    color="error"
                    icon="warning"
                    outlined
                    >
                    {{ error_message }}
                </v-alert>    
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    primary
                    @click="dialog_process_annotation_bookmark = false">
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    @click="submitValidation">
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from './../inc/mixins/mobile'

    export default {
        data: function() {
            return {
                dialog_process_annotation_bookmark: false,
                error_status: false,
                error_message: '',
                message: '',
                checked_annotations_bookmarks : [],
                action : 'Accept'
            };
        },
        mixins: [
            Mobile
        ],
        methods: {
            setAcceptModal(param) {
                this.action = 'Accept';  
                this.message = 'All the notes will be assigned their corresponding tags automatically. Do you wish to update?';
                this.showModal(param);
            },
            setRejectModal(param) {
                this.action = 'Reject'; 
                this.message = 'Are you sure you want to reject the content shared with you?';
                this.showModal(param);
            },
            showModal(param) {         
                this.error_status = false;
                this.error_message =  '';
                this.dialog_process_annotation_bookmark = true;
                this.checked_annotations_bookmarks = param;
            },
            submitValidation() {
                if (this.checked_annotations_bookmarks.length == 0) {
                    this.error_status = true;
                    this.error_message = 'Please select at least one Note to ' + this.action.toLowerCase() + '!';
                } else {
                    this.$http.get(Routing.generate('accept_reject_annotation_bookmarks', {
                        'action' : this.action,
                        'annotationsBookmarks' : this.checked_annotations_bookmarks
                        }))
                        .then(response => {
                            if (response.data.success) {
                                this.error_status = false;                                
                                this.dialog_process_annotation_bookmark = false;
                                this.showSharedWithMeHistoryTab();
                            } else {
                                this.error_status = true;
                                this.error_message = response.data.message;
                            }
                        });                    
                }
            },
            showSharedWithMeHistoryTab : function() {
                EventBus.fire('show-shared-with-me-history-tab');
                EventBus.fire('update-shared-with-me');
            }
        },
        created() {
            EventBus.listen('show-modal-accept-received-annotation-bookmarks', (param) => this.setAcceptModal(param));
            EventBus.listen('show-modal-reject-received-annotation-bookmarks', (param) => this.setRejectModal(param));
        }
    }
</script>
