<template>
    <v-bottom-sheet
        v-model="menuVisible"
        max-width="600px"
    >
        <v-list dense>
            <v-subheader
                class="d-flex justify-space-between align-center"
                style="border-bottom: 1px solid gray; margin-bottom: 8px;"
            >
                <div class="text-truncate text--primary ml-3">
                    {{ title }}
                </div>
                <v-icon @click="closeMenu" style="cursor: pointer; margin-right: 8px;">mdi-close</v-icon>
            </v-subheader>

            <v-list-item
                v-for="(item, key) in typeToEventMap"
                :key="key"
                @click="handleClick(item.event, item.type)"
            >
                <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                        <v-list-item-icon style="width: 40px; margin: 8px 8px 8px 0 !important;">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <span>{{ item.title }}</span>
                        <v-list-item-icon v-if="!hasContentPremium && item.type === 'premium'" class="ml-2">
                            <v-icon small>mdi-lock-outline</v-icon>
                        </v-list-item-icon>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-bottom-sheet>
</template>

<script>
import Mobile from '../../inc/mixins/mobile';
export default {
    mixins: [Mobile],
    data() {
        return {
            menuVisible: false,
            sectionId: null,
            title: null,
            hasContentPremium: false,
            typeToEventMap: {
                copyText: {
                    title: 'Copy text',
                    icon: 'mdi-content-copy',
                    event: 'execute-section-action-copy-text',
                    type: 'premium'
                },
                openNoteModal: {
                    title: 'Add note, bookmark or highlight',
                    icon: 'mdi-bookmark-plus-outline',
                    event: 'execute-section-action-open-note-modal',
                    type: 'premium'
                },
                togglePrint: {
                    title: 'Print',
                    icon: 'mdi-printer-outline',
                    event: 'execute-section-action-toggle-print',
                    type: 'premium'
                },
                copyLink: {
                    title: 'Copy link',
                    icon: 'mdi-link',
                    event: 'execute-section-action-copy-link',
                    type: 'free'
                },
            }
        };
    },
    methods: {
        handleClick(eventName, type) {
            if (type === 'premium' && !this.hasContentPremium) {
                EventBus.fire('subscription-prompt');
            } else {
                EventBus.fire(eventName, {sectionId: this.sectionId});
            }
            this.menuVisible = false;
        },
        closeMenu() {
            this.menuVisible = false;
        }

    },
    created() {
        /**
         * WARNING:: This component is injected so we need to update Vuetify for some components to work.
         */
        this.$vuetify = window.vm.$vuetify;

        EventBus.listen('show-section-actions-mobile', (event) => {
            this.menuVisible = this.isMobilePort;
            this.sectionId = event.sectionId;
            this.hasContentPremium = event.hasContentPremium;
            this.title = event.title;
        });
    },
};
</script>
