var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSingle
    ? _c(
        "div",
        [
          !_vm.isMobilePort
            ? _c("index-desktop", {
                attrs: {
                  "document-info": _vm.documentInfo,
                  "exam-id": _vm.examId,
                },
              })
            : _c("index-mobile", {
                attrs: {
                  "document-info": _vm.documentInfo,
                  "exam-id": _vm.examId,
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }