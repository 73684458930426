<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search title categories"
                        outlined
                        hide-details
                        clearable
                        background-color="white">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-treeview
                    v-model="categories"
                    dense
                    selectable
                    selection-type="independent"
                    return-object
                    :search="query"
                    selected-color="accent2"
                    :items="sortedCategories"
                    item-key="id">
                    <template v-slot:label="{ item }">
                        <p class="mb-0 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-apply-button
                class="mt-2"
                :filter="{
                    key: 'categories',
                    value: categories
                }"
                @clearSearch="removeAll(true)">
            </filter-apply-button>
        </div>
        <div v-show="!expand">
            <template v-if="activeCategories.length > 0">
                <v-card
                    flat
                    :max-height="activeCategories.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeCategories.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeCategories"
                        :key="key"
                        small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary">
                        {{ item }}
                        <v-icon @click="removeCategory(item)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeCategories.length > 0"
                        @click="removeAll(true)">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all categories
            </p>
        </div>
    </div>
</template>

<script>
    import FilterApplyButton from "./FilterApplyButton.vue";
    import { EventNames } from "../../../classes/EventNames";
    import TabbedSearch from "../mixins/TabbedSearch";
    import Search from "../../inc/mixins/search"

    export default {
        components: {
            FilterApplyButton
        },
        data() {
            return {
                query: null,
                items: [],
                categories: []
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        computed: {
            activeCategories() {
                const categories = this.filterCategories[this.searchTab];
                if (categories) {
                    return categories;
                }
                return [];
            },
            sortedCategories() {
                const cats = this.$store.getters.getSearchNavigationCategories;
                return cats.Category.concat(cats.Location);
            }
        },
        mixins: [
            TabbedSearch,
            Search
        ],
        methods: {
            removeCategory(category) {
                this.$store.commit('removeTabbedCategory', {
                    tab: this.searchTab,
                    category
                });
                this.categories = this.categories.filter(item => item.name !== category);
            },
            removeAll() {
                this.$store.commit('removeTabbedCategory', {
                    tab: this.searchTab,
                    removeAll: true
                });
                this.categories = [];
            }
        },
        created() {
            EventBus.listen(EventNames.REMOVE_ACTIVE_CATEGORY, (name) => this.removeCategory(name));
            EventBus.listen(EventNames.REMOVE_ALL_ACTIVE_CATEGORIES, () => this.removeAll());
        },
        mounted() {
            if (this.activeCategories && this.activeCategories.length > 0) {
                this.categories = this.sortedCategories.filter(item => this.activeCategories.includes(item.name));
            }
        }
    }
</script>
