var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "primary--text headline" }, [
                  _c("span", { staticClass: "licenses-step-4" }, [
                    _vm._v("Assigned To Me"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 mr-2 primary",
                      on: {
                        click: function ($event) {
                          return _vm.refreshSubscriptions("AssignedToMe")
                        },
                      },
                    },
                    [
                      !_vm.isRefreshing
                        ? _c("span", [_vm._v("Refresh Licenses")])
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "white",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    Assigned to Me licenses are those where another user has purchased a premium subscription and assigned dedicated usage access to me.\n                    Usage of the premium licenses assigned to me are restricted to my individual account but are subject to the assignee revoking access if they choose to.\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.subscriptions.length > 0
        ? _c("v-data-table", {
            staticClass: "elevation-0 no-margin",
            attrs: {
              items: _vm.subscriptions,
              "items-per-page": _vm.pagination.itemsPerPage,
              loading: _vm.loading,
              options: _vm.pagination,
              "server-items-length": _vm.pagination.totalItems,
              "must-sort": "",
              "footer-props": {
                itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function (header, index) {
                          return _c(
                            "td",
                            {
                              key: index,
                              staticClass: "caption font-weight-bold",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(header.text) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "subtitle-2",
                            attrs: { width: "40%" },
                          },
                          [
                            _vm.checkStatus(item) !== "Revoked"
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.bookLink(
                                          item.subscription.document_info
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("assigned-row", {
                                      attrs: { row_data: item },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c("assigned-row", {
                                      attrs: { row_data: item },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "subtitle-2" }, [
                          _vm._v(_vm._s(item.assigned_by.email)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(item.created_at, "MMMM Do, YYYY")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                item.subscription.expiration_date,
                                "MMMM Do, YYYY"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.checkSubscriptionType(
                                item.subscription.subscription_type
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "subtitle-2" }, [
                          _vm._v(
                            _vm._s(_vm.checkStatus(item)) +
                              "\n                    "
                          ),
                          _vm.checkStatus(item) === "Revoked"
                            ? _c("p", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        item.updated_at,
                                        "MMMM Do, YYYY"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              874233621
            ),
          })
        : _c(
            "v-data-table",
            {
              staticClass: "elevation-0 no-margin",
              attrs: { items: _vm.subscriptions, loading: _vm.loading },
            },
            [
              _c("template", { slot: "no-data" }, [
                _c(
                  "p",
                  { staticClass: "text-center mt-6" },
                  [
                    _vm.subscriptions.length === 0 && !_vm.loading
                      ? [
                          _vm._v(
                            "\n                    No results found!\n                "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                    Loading\n                "
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }