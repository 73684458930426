<template>
    <v-row justify="center">
        <v-dialog
                v-model="modalVisible"
                persistent
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Concurrent Session Timeout!
                </v-card-title>

                <v-card-text v-if="modalVisible">

                    <p>
                        There has been no activity on this browser since last {{ timeoutMinutes }} minutes.
                    </p>

                    <p>If you want to continue with the current session, please click <strong>Stay Signed in</strong>
                        button below, or else you will be disconnected in
                        <seconds-countdown
                                :seconds="continueTimeoutSeconds"
                                class="font-weight-bold"
                        ></seconds-countdown>
                        seconds
                    </p>

                </v-card-text>

                <v-card-actions class="grey lighten-4">
                    <v-spacer></v-spacer>
                    <v-btn color="primary white--text" @click="clickedSignIn">
                        Stay Signed in
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
// previous: https://github.com/InternationalCodeCouncil/premiumACCESS/pull/7325/files#diff-5188d7ee615e0d37d135d87d69b43c537b47a0a801e86c9c15278036d8a2ffff
import {ApiService} from "../../api/ApiService";
import {AppParameters} from "../../AppParameters";
import SecondsCountdown from "../partials/SecondsCountdown.vue";

export default {
    components: {SecondsCountdown},
    data() {
        return {
            modalVisible: false,
            timeoutMinutes: AppParameters.concurrent_idle_timeout_minutes,
            continueTimeoutSeconds: 30
        }
    },
    computed: {
        visible() {
            return this.$store.getters.isConcurrentTimeoutWarningDialogVisible;
        }
    },
    methods: {
        concurrentLogout() {

            ApiService.logoutConcurrent().then(() => {

                localStorage.setItem('showConcurrentTimeoutDialog', "yes");

                this.setUserProfile({type: 'personal'}).finally(() => {
                    window.location.reload();
                });

            });
        },
        clickedSignIn() {
            this.$store.commit('setConcurrentTimeoutWarningDialogVisible', false);
        }
    },
    mounted() {

        this.$watch(() => this.$store.getters.isConcurrentTimeoutWarningDialogVisible, (newValue) => {
            this.modalVisible = newValue;
        }, {
            immediate: true
        });

        let waitingForResponseTimeout;

        this.$watch(() => this.modalVisible, (newValue) => {

            // dialog opened!
            // Give it another 30 seconds to respond, otherwise - LOGOUT, and reload page
            if (newValue) {

                waitingForResponseTimeout = setTimeout(() => {
                    this.concurrentLogout();
                }, this.continueTimeoutSeconds * 1000);

            } else {
                clearTimeout(waitingForResponseTimeout);
            }

        }, {
            immediate: true
        });
    }
}
</script>
