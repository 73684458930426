import axios from "axios";

export default {
    methods: {
        confirmRemove(user) {
            this.toBeRemoved = user;
            this.confirmDialog = true;
        },
        removeUser() {
            this.clearRequest();
            this.loading = true;
            let axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel};
            let route = 'enterprise_user_delete';
            if(_.isNull(this.toBeRemoved.user_id)){
                route = 'enterprise_invited_user_delete';
            }
            axios.delete(Routing.generate(route,
                {userId: this.toBeRemoved.id}), {
                cancelToken: axiosSource.token
            }).then((response) => {
                if (response.data && response.data.code == 200) {
                    let _this = this;
                    this.users = _.filter(this.users, function(o) {
                        return o.id !== _this.toBeRemoved.id || o.role !== _this.toBeRemoved.role;
                    });
                    this.confirmDialog = false;
                    this.loading = false;
                    this.getUsers(this.$store.getters.enterpriseId);
                } else {
                    this.handleError(response.data.message);
                }
            }).catch(this.handleError);
        },
        denyRemove() {
            this.confirmDialog = false;
            this.toBeRemoved = {};
        },
        clearRequest() {
            if (this.request) {
                this.request.cancel();
                this.request = null;
            }
        },
        updateUser(user, newRole, userStatus, entId = null) {
            if (0 === userStatus && null !== userStatus) {
                newRole = 0;
            }
            let enterpriseId = entId ? entId : this.$store.getters.enterpriseId;
            this.clearRequest();
            this.loading = true;
            let axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel};
            axios.patch(Routing.generate('enterprise_update_user'), {
                enterpriseId: enterpriseId,
                userId: user.id,
                newRole: newRole,
                status: userStatus,
                cancelToken: axiosSource.token
            }).then((response) => {
                if (response.data && response.data.code == 200) {
                    if (null !== newRole) {
                        user.role = newRole;
                    }
                    if (null !== userStatus) {
                        user.status = userStatus;
                    }
                    this.loading = false;
                    this.getUsers(enterpriseId);
                } else {
                    this.handleError(response.data.message);
                }
            }).catch(this.handleError);
        },
        handleError(message = 'Sorry, something went wrong') {
            this.statusAlert.message = message;
            this.statusAlert.type = 'error';
        },
        getUsers(entId) {
            this.loading = true;
            this.$http.get(Routing.generate('get_enterprise_users', {enterpriseId: entId}))
                .then(response => {
                    if (response.data && response.data.code == 200) {
                        this.enterpriseName = response.data.company_name;
                        this.users = response.data.users;
                        this.activeUsers = response.data.activeUsers;
                        this.pendingUsers = response.data.pendingUsers;
                        this.enterpriseTotalSeats = response.data.enterpriseTotalSeats;
                        this.totalUsers = response.data.totalUsers;
                        this.selectedIDs = _.compact(_.flatMap(this.users, function(o) { return o.user_id; }));
                        this.loading = false;
                        EventBus.fire('get-enterprise-details', {
                            enterpriseName: this.enterpriseName
                        });
                    }
            });
        }
    },
    created() {
        EventBus.listen('getUsers', (entId) => this.getUsers(entId));
    }
}
