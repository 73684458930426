var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-2 note-list-item" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "default-color",
                  attrs: { size: "32", color: "grey lighten-3" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.user.first_name[0]) +
                      _vm._s(_vm.data.user.last_name[0]) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("p", [
                _c("span", { staticClass: "font-weight-bold default-color" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.data.user.first_name) +
                      " " +
                      _vm._s(_vm.data.user.last_name) +
                      "\n                    "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.createdByCurrentUser(),
                          expression: "createdByCurrentUser()",
                        },
                      ],
                      staticClass: "ml-1 default-color",
                    },
                    [_vm._v("(You)")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 text--secondary default-color",
                    staticStyle: { color: "rgba(0,0,0,.6)!important" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getFormattedDate(_vm.data.created_date)) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.data.modified_date &&
                              _vm.data.created_date !== _vm.data.modified_date,
                            expression:
                              "data.modified_date && data.created_date !== data.modified_date",
                          },
                        ],
                        staticClass: "ml-1 default-color",
                      },
                      [
                        _vm._v(
                          "\n                        (Edited " +
                            _vm._s(
                              _vm.getFormattedDate(_vm.data.modified_date)
                            ) +
                            ")\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.type === "note"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "note-list-quote font-italic pa-1 mb-0 mt-1 left default-color",
                    },
                    [
                      _vm._v(
                        '\n                "' +
                          _vm._s(_vm.data.annotation.quote) +
                          '"\n            '
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.getDescription()
                ? _c("div", {
                    staticClass: "highlight-text",
                    domProps: { innerHTML: _vm._s(_vm.getDescription()) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-chip",
                {
                  staticClass: "mt-1",
                  attrs: { small: "", color: _vm.getTagColor(_vm.data.tag) },
                },
                [
                  _c("span", { staticClass: "default-color" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getTagName(_vm.data.tag)) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-menu",
                {
                  attrs: { left: "", "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            { attrs: { icon: "" }, on: { click: on.click } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    color: "rgba(0,0,0,.54) !important",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            mdi-dots-vertical\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    {
                      staticStyle: { "max-width": "120px" },
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showShareModal(_vm.data.id)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2" },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-share-variant-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Share")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showEditModal(_vm.data)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2" },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-pencil-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Edit")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showDeleteModal(_vm.data)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "red--text",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-delete-outline")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "red--text" },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }