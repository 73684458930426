<template>
    <div>
        <premium-answers-header>
        </premium-answers-header>
        <v-divider>
        </v-divider>
        <div class="grey lighten-5">
            <v-container class="content-container pt-0">
                <account-access-expand>
                </account-access-expand>
                <v-row>
                    <v-col v-if="activeTab === 0">
                        <template v-if="tabName === 'premium-answer'">
                            <premium-trial-block></premium-trial-block>
                            <v-row>
                                <v-col class="pt-0"
                                    :class="isMobilePort && 'px-0'">
                                    <search-questions
                                        :filters="filters"
                                        @enterClicked="enterClicked"
                                        @clearFilters="clearFilters">
                                    </search-questions>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h6 class="text-h6 pa-2">
                                        {{paHeading}}
                                    </h6>
                                    <answer-list
                                        :loading="loading"
                                        :premium-answers="premiumAnswers"
                                        :lazy-loading="lazyLoading"
                                        :show-more="totalResults > filters.limit && totalResults !== premiumAnswers.length"
                                        :page="page"
                                        @titleClick="(data) => filters.documentIds = [data]"
                                        @topicClick="(data) => filters.topicIds = [data]"
                                        @nextPage="page++">
                                    </answer-list>
                                </v-col>
                            </v-row>
                        </template>
                        <div v-else class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate>
                            </v-progress-circular>
                        </div>
                    </v-col>
                    <v-col v-else>
                        <your-questions
                            v-if="tabName === 'quick-consult'"
                            :isSuccessTrue="isSuccessTrue">
                        </your-questions>
                        <div v-else class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate>
                            </v-progress-circular>
                        </div>
                    </v-col>
                    <v-col
                       cols="12"
                       sm="3">
                        <what-is-premium-answers
                            :tab="activeTab">
                        </what-is-premium-answers>
                        <template v-if="activeTab === 0">
                            <recently-viewed-questions class="mt-3">
                            </recently-viewed-questions>
                            <DidYouKnow class="mt-6"/>
                            <premium-answer-disclaimer
                                :viewPage="false"
                                class="mt-3">
                            </premium-answer-disclaimer>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
    import WhatIsPremiumAnswers from "../../components/premium-answers/WhatIsPremiumAnswers.vue";
    import SearchQuestions from "../../components/premium-answers/SearchQuestions.vue";
    import RecentlyViewedQuestions from "../../components/premium-answers/RecentlyViewedQuestions.vue";
    import AnswerList from "../../components/premium-answers/AnswerList.vue";
    import AccountAccessExpand from "../../components/expands/AccountAccessExpand.vue";
    import Mobile from "../../components/inc/mixins/mobile";
    import {PremiumAnswersApi} from "../../api/PremiumAnswers";
    import DidYouKnow from "../../components/premium-answers/DidYouKnow.vue";
    import PremiumAnswerDisclaimer from "../../components/premium-answers/PremiumAnswerDisclaimer.vue";
    import PremiumAnswersHeader from "../../components/premium-answers/PremiumAnswersHeader.vue";
    import {AppParameters} from "../../AppParameters";
    import YourQuestions from "../../components/premium-answers/YourQuestions.vue";
    import PremiumTrialBlock from "../../components/premium-answers/PremiumTrialBlock.vue";

    export default {
        name: 'PremiumAnswers',
        data() {
            return {
                loading: true,
                lazyLoading: false,
                page: 1,
                filters: {
                    search: '',
                    documentIds: [],
                    topicIds: [],
                    limit: 10
                },
                getPremiumAnswersDebounced: null,
                premiumAnswers: [],
                totalResults: 0,
                paHeading: '',
                checkEnterClicked: false,
                quickConsultEnabled: AppParameters.quick_consult_enabled,
                isSuccessTrue: false
            }
        },
        props: {
            tabName: {
                type: String,
                required: true
            }
        },
        computed: {
            activeTab: {
                get() {
                    return this.$store.getters.getPremiumAnswersTab
                },
                set(value) {
                    this.$store.commit('setPremiumAnswersTab', value)
                }
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            YourQuestions,
            PremiumAnswersHeader,
            AccountAccessExpand,
            AnswerList,
            RecentlyViewedQuestions,
            SearchQuestions,
            WhatIsPremiumAnswers,
            DidYouKnow,
            PremiumAnswerDisclaimer,
            PremiumTrialBlock
        },
        watch: {
            page(value) {
                if (value !== 1) {
                    this.getPremiumAnswersDebounced(true)
                }
            }
        },
        methods: {
            async getPremiumAnswers(append = false) {
                this.loading = !append
                this.totalResults = 0
                this.lazyLoading = true
                const filters = Object.assign({}, this.filters, { page: this.page })
                const results = await PremiumAnswersApi.getPremiumAnswers(filters)
                if (!append) {
                    this.premiumAnswers = results.data.results
                } else {
                    this.premiumAnswers.push(...results.data.results)
                }
                this.totalResults = results.data.totalResults
                this.loading = false
                this.lazyLoading = false
                this.paHeading = `${this.totalResults} questions`;
            },
            checkParams() {
                const urlParams = new URLSearchParams(window.location.search)
                let params = Object.fromEntries(urlParams.entries())
                if (params.page !== undefined) {
                    this.page = params.page
                }
                if (params.topic !== undefined) {
                    this.filters.topicIds = [params.topic]
                }
                if (params.document !== undefined) {
                    this.filters.documentIds = [params.document]
                }
                if (params.successMessage !== undefined) {
                    this.isSuccessTrue = true;
                }
                window.history.replaceState(null, '', window.location.pathname)
            },
            enterClicked() {
                this.premiumAnswers = [];
                this.page = 1;
                this.getPremiumAnswers();
                this.checkEnterClicked = true;
            },
            clearFilters() {
                this.filters = {
                    search: '',
                    documentIds: [],
                    topicIds: [],
                    limit: 10
                }
                this.page = 1
            }
        },
        created() {
            this.checkParams()
        },
        mounted() {
            this.getPremiumAnswersDebounced =  _.debounce((data) => this.getPremiumAnswers(data), 500);
            this.getPremiumAnswers()

            // when either filter changed - update immediately
            this.$watch(() => [this.filters.documentIds, this.filters.topicIds], (newValue) => {
                this.loading = true
                this.premiumAnswers = [];
                this.page = 1;
                this.getPremiumAnswersDebounced();

            });

            /** @type {HTMLDivElement} */
            const el = document.querySelector('#main-content-container > div.body.overflow-hidden');

            if (el) {
                el.style.setProperty('min-height', '1px', 'important');
            }
        }
    }
</script>
