var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        fullscreen: _vm.isMobileAndTablet,
        "max-width": "700px",
      },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _vm.type
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [_c("tag-label", { attrs: { tag: _vm.type.tag } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.type.title) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "accent2--text",
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  _vm.modal = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        close\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _vm.loading
                    ? [
                        _c(
                          "v-container",
                          { staticClass: "text-center py-12" },
                          [
                            _c("v-progress-circular", {
                              staticClass: "py-6",
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        this.tagType === "premiumcontent"
                          ? _c("premium-content", { attrs: { data: _vm.data } })
                          : this.tagType === "es"
                          ? _c("e-s-content", { attrs: { data: _vm.data } })
                          : this.tagType === "errata"
                          ? _c("errata-content", { attrs: { data: _vm.data } })
                          : this.tagType === "srcc"
                          ? _c("s-r-c-c-content", { attrs: { data: _vm.data } })
                          : this.tagType === "video"
                          ? _c("video-content", { attrs: { data: _vm.data } })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }