import Vue from 'vue';
import VueSession from 'vue-session';

Vue.use(VueSession, {persist: true});

export const FavoritesModule = {
    state: {
        favorites: Vue.prototype.$session.has('favorites') ?
            Vue.prototype.$session.get('favorites') : [],
        favoritesPromise: Vue.prototype.$session.has('favoritesPromise') ?
            Vue.prototype.$session.get('favoritesPromise') : false,
        favoritesLoading: Vue.prototype.$session.has('favoritesLoading') ?
            Vue.prototype.$session.get('favoritesLoading') : false,
    },
    getters: {
        getFavorites : (state) => state.favorites,
        getFavoritesPromise : (state) => state.favoritesPromise,
        getFavoritesLoading : (state) => state.favoritesLoading
    },
    mutations: {
        setFavorites (state, data) {
            Vue.prototype.$session.set('favorites', data);
            state.favorites = data;
        },
        setFavoritesPromise (state, value) {
            Vue.prototype.$session.set('favoritesPromise', value);
            state.favoritesPromise = value;
        },
        setFavoritesLoading (state, value) {
            Vue.prototype.$session.set('favoritesLoading', value);
            state.favoritesLoading = value;
        }
    }
};
