export default {
    data(){
        return {
            ASSIGN_SUBSCRIPTION: 'assigned.created',
            REASSIGN_SUBSCRIPTION: 'reassign.created',
            REVOKE_SUBSCRIPTION: 'revoke.created',
            CREATE_CONCURRENT_CODE: 'concurrent.created',
            UPDATE_CONCURRENT_CODE: 'concurrent.updated'
        }
    },

    methods: {
        sendEmailNotification(event, data){
            this.$http.get(Routing.generate('send_email_notification', {event: event, data: data}));
        },
    }
}
