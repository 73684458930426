<template>
    <div v-if="questions.length && isLoggedIn()">
        <v-row>
            <v-col>
                <h2 class="font-weight-regular">
                    Recently viewed questions
                </h2>
            </v-col>
        </v-row>
        <div
            v-for="(item, key) in questions"
            :key="key">
            <v-divider>
            </v-divider>
            <p class="subtitle-1 my-3 font-weight-bold">
                <a :href="getRoute('premium_answers_view', {answerId: item.tagId, slug: item.slug})">
                    {{item.question}}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
    import {PremiumAnswersApi} from "../../api/PremiumAnswers";

    export default {
        name: 'RecentlyViewedQuestions',
        data() {
            return {
                questions: []
            }
        },
        props: {
            id: {
                required: false,
                type: Number
            }
        },
        methods: {
            async getRecentlyViewedAnswers() {
                const response = await PremiumAnswersApi.getRecentlyViewedAnswers(this.id)
                this.questions = response.data
            },
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            }
        },
        mounted() {
            this.getRecentlyViewedAnswers()
        }
    }
</script>
