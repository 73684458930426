export default {
    computed: {
        userCount: {
            get() {
                return this.$store.getters.getUserCount;
            },
            set() {
                this.$store.commit('setUserCount');
            }
        },
        hasUpdate: {
            get() {
                return this.$store.getters.hasUpdate;
            },
            set(value) {
                this.$store.commit('setHasUpdate', value);
            }
        },
        updatesData: {
            get() {
                return this.$store.getters.getUpdatesData;
            },
            set(value) {
                this.$store.commit('setUpdatesData', value);
            }
        },
    }
}
