var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pageReference === "sign-in"
        ? [
            "" !== _vm.inviteFirstname
              ? _c("enterprise-sign-in", {
                  attrs: {
                    "form-action": _vm.formAction,
                    referer: _vm.referer,
                    token: _vm.token,
                    "invite-firstname": _vm.inviteFirstname,
                    "invite-email": _vm.inviteEmail,
                    "invite-enterprise": _vm.inviteEnterprise,
                  },
                })
              : _c("sign-in-form", {
                  attrs: {
                    "form-action": _vm.formAction,
                    referer: _vm.referer,
                    token: _vm.token,
                  },
                }),
          ]
        : _vm.pageReference === "confirm"
        ? [
            _c("confirm-register-form", {
              attrs: {
                token: _vm.token,
                "user-email": _vm.userEmail,
                error: _vm.error,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }