<template>
    <div>
        <profile-block></profile-block>
        <updates-block class="mt-6"></updates-block>
        <concurrent-block class="mt-6"></concurrent-block>
    </div>
</template>

<script>
    import ProfileBlock from './ProfileBlock.vue';
    import UpdatesBlock from './UpdatesBlock.vue';
    import ConcurrentBlock from './ConcurrentBlock.vue';

    export default {
        components: {
            ProfileBlock,
            UpdatesBlock,
            ConcurrentBlock
        }
    }
</script>