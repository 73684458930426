var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "open-on-click": "",
        "allow-overflow": "",
        "z-index": "1005",
        "max-width": "500px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onMenu }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    "max-width": "300",
                    transition: "fade-transition",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "logo",
                                  attrs: {
                                    "data-qa": "navigation-logo-menu",
                                    icon: "",
                                    text: "",
                                    role: "button",
                                    "aria-label": "ICC Solutions",
                                  },
                                },
                                { ...onMenu, ...onTooltip }
                              ),
                              [
                                _c("v-icon", { attrs: { color: "black" } }, [
                                  _vm._v("mdi-apps"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.tooltipOpen,
                    callback: function ($$v) {
                      _vm.tooltipOpen = $$v
                    },
                    expression: "tooltipOpen",
                  },
                },
                [
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "tooltip-content",
                      on: {
                        mouseenter: _vm.showTooltip,
                        mouseleave: _vm.hideTooltip,
                      },
                    },
                    [_vm._v("More ICC Solutions")]
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "menu-icons" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-sm": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-center primary--text my-4 title font-weight-bold",
                      },
                      [_vm._v("ICC Family of Solutions")]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "text-center", attrs: { row: "", wrap: "" } },
                _vm._l(_vm.icons, function (icon, index) {
                  return _vm.icons
                    ? _c(
                        "v-flex",
                        { key: index, attrs: { xs4: "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "py-2",
                              attrs: {
                                "data-qa":
                                  "navigation-logo-menu-" + icon.fileSlug,
                                flat: "",
                                href: icon.link,
                                "aria-label": _vm.ariaLabelForUrl(icon.link),
                                target: "_blank",
                              },
                            },
                            [
                              _c("v-card-text", [
                                _c("img", {
                                  attrs: { src: icon.dataUri, alt: icon.text },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "mb-0 primary--text" }, [
                                  _vm._v(_vm._s(icon.text)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }