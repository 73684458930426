var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "flex d-flex flex-column" },
    [
      _c(
        "v-card-title",
        { staticClass: "py-2 white--text", class: _vm.getPrimaryColor() },
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.asset(
                        require("../../../images/icons/diamond_" +
                          (_vm.singleCollection ? "collection" : _vm.type) +
                          ".png"),
                        "default"
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "span",
                  { staticClass: "ma-auto" },
                  [
                    _vm.type === "single" && !_vm.singleCollection
                      ? [
                          _vm._v(
                            "\n                        Singles\n                    "
                          ),
                        ]
                      : _vm.type === "collection" || _vm.singleCollection
                      ? [
                          _vm._v(
                            "\n                        Collections\n                    "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                        Complete\n                    "
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "text-center px-8 flex d-flex flex-column" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "90",
                      src: _vm.asset(
                        require("../../../images/icons/package_" +
                          (_vm.singleCollection ? "collection" : _vm.type) +
                          ".png"),
                        "default"
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  !_vm.hasCollections
                    ? _c("v-col", { staticClass: "text-center" }, [
                        _c(
                          "h4",
                          [
                            _vm.type === "complete"
                              ? [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.product.document_info.title) +
                                      "\n                    "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.product.document_info.book_year
                                          ? _vm.product.document_info.book_year
                                          : _vm.product.document_info.year
                                              .year +
                                              " " +
                                              _vm.product.document_info.title
                                      ) +
                                      "\n                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ])
                    : _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "caption mb-0 text-left" }, [
                            _vm._v("Available Collections"),
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              solo: "",
                              "hide-details": "",
                              dense: "",
                              "menu-props": { auto: true, overflowY: true },
                              items: _vm.collections,
                              "item-text": "display_title",
                              "item-value": "id",
                              label: "Select a collection",
                            },
                            model: {
                              value: _vm.collectionValue,
                              callback: function ($$v) {
                                _vm.collectionValue = $$v
                              },
                              expression: "collectionValue",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "", align: "end" } },
            [
              _c(
                "v-col",
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: {
                          transition: "scale-transition",
                          type: "list-item",
                        },
                      })
                    : [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", { staticClass: "text-center" }, [
                              _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(
                                  "\n                                Quantity\n                            "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          {
                            attrs: {
                              dense: "",
                              align: "center",
                              justify: "center",
                            },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      dark: _vm.product.quantity > 1,
                                      disabled: _vm.product.quantity === 1,
                                      color: "primary",
                                    },
                                    on: { click: _vm.decrementQuantity },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("remove"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    readonly: "",
                                    value: _vm.product.quantity,
                                    type: "number",
                                    min: 1,
                                    solo: "",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      dark: "",
                                      "x-small": "",
                                      color: "primary",
                                    },
                                    on: { click: _vm.incrementQuantity },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("add"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", [
                              _c(
                                "p",
                                { staticClass: "mb-0 mt-5 font-weight-bold" },
                                [
                                  _vm._v(
                                    "\n                                Per License\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isYearly
                                ? _c("p", { staticClass: "caption mb-0" }, [
                                    _vm._v(
                                      "\n                                $" +
                                        _vm._s(
                                          _vm.pricePerItem(
                                            _vm.product,
                                            _vm.isMember
                                          )
                                        ) +
                                        "\n                                " +
                                        _vm._s("Year") +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm.isThreeYearly
                                ? _c("p", { staticClass: "caption mb-0" }, [
                                    _vm._v(
                                      "\n                                $" +
                                        _vm._s(
                                          _vm.pricePerItem(
                                            _vm.product,
                                            _vm.isMember
                                          )
                                        ) +
                                        "\n                                " +
                                        _vm._s("for 3 Years") +
                                        "\n                            "
                                    ),
                                  ])
                                : _c("p", { staticClass: "caption mb-0" }, [
                                    _vm._v(
                                      "\n                                $" +
                                        _vm._s(
                                          _vm.pricePerItem(
                                            _vm.product,
                                            _vm.isMember
                                          )
                                        ) +
                                        "\n                                " +
                                        _vm._s("Month") +
                                        "\n                            "
                                    ),
                                  ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", { staticClass: "center-text" }, [
                              _c(
                                "p",
                                { staticClass: "mb-0 font-weight-bold" },
                                [
                                  _vm._v(
                                    "\n                                Total\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h1",
                                {
                                  class:
                                    _vm.type === "complete"
                                      ? "accent2--text"
                                      : "primary--text",
                                },
                                [
                                  _vm._v(
                                    "\n                                $" +
                                      _vm._s(
                                        _vm.getProductTotalPrice(
                                          _vm.product,
                                          _vm.isMember
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isYearly
                                ? _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                                ($" +
                                        _vm._s(
                                          (
                                            _vm.getProductTotalPrice(
                                              _vm.product,
                                              _vm.isMember
                                            ) / 12
                                          ).toFixed(2)
                                        ) +
                                        "/Month)\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isThreeYearly
                                ? _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                                ($" +
                                        _vm._s(
                                          (
                                            _vm.getProductTotalPrice(
                                              _vm.product,
                                              _vm.isMember
                                            ) / 36
                                          ).toFixed(2)
                                        ) +
                                        "/Month)\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: _vm.product.document_info.document_id,
                                dark: "",
                                block: "",
                                color: _vm.getPrimaryColor(),
                                disabled: _vm.showSubscribeButton,
                              },
                              on: { click: _vm.addItem },
                            },
                            [
                              !_vm.addToCartLoading
                                ? [
                                    _vm._v(
                                      "\n                                Subscribe Now\n                            "
                                    ),
                                  ]
                                : [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        size: 20,
                                        indeterminate: "",
                                        color: "white",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }