var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.my_featured_books.length > 0
    ? _c(
        "div",
        { staticClass: "recommended recommended-book-list text-center" },
        [
          _c(
            "div",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("h1", { staticClass: "primary--text mr-4" }, [
                  _vm._v("Related Resources"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.my_featured_books, function (result, index) {
              return _c(
                "v-flex",
                {
                  key: index,
                  attrs: { md3: "", sm6: "", xs12: "", "text-center": "" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "result-items-container",
                      class: { mx: _vm.$vuetify.breakpoint.smAndDown },
                      attrs: { flat: "" },
                    },
                    [
                      _c("v-card-text", [
                        _c("img", {
                          staticClass: "mt-2 mb-1",
                          attrs: {
                            src: _vm.getFeaturedItemCover(
                              result.featured_image
                            ),
                            width: "100%",
                          },
                          on: {
                            error: _vm.loadDefaultBookCover,
                            click: function ($event) {
                              return _vm.redirect(result.shop_url)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.redirect(result.shop_url)
                              },
                            },
                          },
                          [_vm._v(_vm._s(result.name))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }