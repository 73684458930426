var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.subHeading
      ? _c(
          "h3",
          {
            staticClass: "fs-20 text-center teal--text font-weight-medium mb-2",
          },
          [_vm._v("\n        " + _vm._s(_vm.subHeading) + "\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.mainHeading
      ? _c(
          "h2",
          {
            staticClass: "text-center oxygen mb-4",
            class: _vm.isMobilePort ? "fs-24" : "fs-32",
          },
          [_vm._v("\n        " + _vm._s(_vm.mainHeading) + "\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }