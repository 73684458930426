<template>
    <v-dialog
        v-model="dialog"
        max-width="500px">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Save Your Search
                <v-spacer>
                </v-spacer>
                <v-icon
                    small
                    class="mr-2
                    accent2--text"
                    @click.stop="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="mb-0">
                            Name your Saved Search
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            hide-details
                            solo
                            v-model="label"
                            placeholder="Saved search label">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click.stop="dialog = false">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveSearch()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                dialog: false,
                label: ''
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            saveSearch() {
                let formData = new FormData();
                formData.append('label', this.label);
                formData.append('search_id', this.$store.getters.getSearchId);
                this.$http.post(Routing.generate('save_search'), formData)
                    .then(response => {
                        EventBus.fire('get-saved-searches');
                    });
                this.resetSaveSearch();
            },
            resetSaveSearch() {
                this.dialog = false;
                this.label = '';
            }
        },
        created() {
            EventBus.listen('open-add-search', () => this.dialog = true)
        }
    }
</script>
