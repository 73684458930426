<template>
    <outlined-card :showDivider="false">
        <template v-slot:title>
            <h2 class="text-h6 font-weight-bold">
                Digital Codes Updates
            </h2>
        </template>
        <template v-slot:content>
            <template
                v-if="loadingData">
                <v-progress-linear
                    class="mt-3"
                    indeterminate
                    color="primary">
                </v-progress-linear>
            </template>
            <div v-else>
                <v-expansion-panels
                    accordion
                    flat
                    v-model="activePanel"
                    >
                    <v-expansion-panel
                        v-for="(update, key) in updates"
                        :key="key">
                        <v-divider v-if="key === 0"></v-divider>
                        <v-expansion-panel-header class="px-0 py-3">
                            <h3 class="fs-16 font-weight-medium">
                                {{update.title}}
                            </h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="recent-update-wrapper link-color">
                            <div v-html="update.description">
                            </div>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </template>
    </outlined-card>
</template>

<script>

    import OutlinedCard from '../../../components/cards/OutlinedCard.vue';
    import { ApiService } from '../../../api/ApiService';
    import { User } from '../../../classes/User';

    export default {
        data(){
            return {
                updates: [],
                updatePreference: true,
                loadingData: true
            }
        },
        components: {
            OutlinedCard
        },
        computed: {
            activePanel: {
                get() {
                    const user = this.$store.getters.getUser;
                    if (user) {
                        const savedPreference = user.getPreference([User.UPDATES_BLOCK_SECTION]);
                        if (typeof savedPreference === 'undefined') {
                            return 0;
                        }
                        const updateCount = this.$store.getters.getUpdateCount;
                        const hasUpdate = parseInt(savedPreference) !== parseInt(updateCount);

                        return hasUpdate ? 0 : null;
                    }

                    return null;
                },
                set(newVal) {
                    if (newVal === 0) return; // Do nothing if the panel is expanded

                    const updatesCount = _.size(this.updates);
                    if (this.updatePreference) {
                        ApiService.updatePreference({
                            [User.UPDATES_BLOCK_SECTION]: updatesCount,
                        });
                        this.updatePreference = false;
                    }
                }
            },
            hasNewUpdates() {
                return parseInt(this.userCount) !== parseInt(this.$store.getters.getUpdateCount);
            }
        },
        mounted() {
            ApiService.getUpdates()
                .then((response)=> {
                    if (response.data) {
                        this.updates = response.data;
                        this.updatesData = response.data; // store in vuex to use in modal
                    }
                })
                .finally(()=>this.loadingData = false)
        }
    }
</script>
<style>
    .recent-update-wrapper img {
        max-width: 100%;
    }
    .recent-update-wrapper .v-expansion-panel-content__wrap {
        padding: 4px !important;
    }
</style>