<template>
    <div v-show="expand">
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    This Exact Phrase
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.exact"
                    placeholder="Ex. Bearing Wall Structure"
                    data-aq="proximity-word-search"
                    @input="handleSearchInput('exact')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    All of the words
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.all"
                    placeholder="Ex. Area, Building, Brick"
                    data-aq="proximity-word-search"
                    @input="handleSearchInput('all')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    Any of the words
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.any"
                    placeholder="Ex. Area, Building, Brick"
                    data-aq="proximity-word-search"
                    @input="handleSearchInput('any')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    None of the words
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.none"
                    placeholder="Ex. Concrete, Cement"
                    data-aq="proximity-word-search"
                    @input="handleSearchInput('none')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <h3 class="text-center mt-2">
                    OR
                </h3>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    Proximity search
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.proximity"
                    placeholder="Ex. Fire"
                    data-aq="proximity-word-search"
                    @input="handleSearchInput('proximity')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p class="mb-0">
                    Within 50 words of
                </p>
                <v-text-field
                    dense
                    hide-details
                    @keydown="keydown"
                    outlined
                    clearable
                    v-model="search.proximityWithin"
                    placeholder="Ex. Content"
                    data-aq="near-word-search"
                    @input="handleSearchInput('proximityWithin')"
                    background-color="white">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-spacer>
            </v-spacer>
            <v-col cols="auto">
                <v-btn
                    class="elevation-0"
                    color="primary"
                    small
                    @click="contentSearch">
                    Apply
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    outlined
                    small
                    @click="clearSearch()">
                    Clear
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'AdvancedSearch',
        data() {
            return {
                search: {
                    exact: '',
                    any: '',
                    all: '',
                    none: '',
                    proximity: '',
                    proximityWithin: ''
                },
                activeAdvance: true,
                activeNear: false
            }
        },
        props: {
            expand: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            contentSearch() {
                // {...search} is to prevent object from being observable, because it will
                // always change its state and send wrong info to the request. This is
                // related to filters being able to be applied independently from eachother
                EventBus.fire('update-content-search-filters', {key: 'advancedSearch', value: {...this.search}, performSearch: false})
                this.$emit('updateAdvancedSearch', this.search);
            },
            keydown(event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                    this.contentSearch();
                }
            },
            handleSearchInput(searchType) {
                if (searchType === 'proximity' || searchType === 'proximityWithin') {
                    this.resetAdvanceSearch();
                    this.activeNear = true;
                    this.activeAdvance = false;
                } else if (['exact', 'any', 'all', 'none'].includes(searchType)) {
                    this.resetNearSearch();
                    this.activeNear = false;
                    this.activeAdvance = true;
                }
            },
            resetAdvanceSearch() {
                this.search.exact = '';
                this.search.any = '';
                this.search.all = '';
                this.search.none = '';
            },
            resetNearSearch() {
                this.search.proximity = '';
                this.search.proximityWithin = '';
            },
            clearSearch(key = null) {
                if (key) {
                    let search = this.search;
                    if (key === 'nearSearch') {
                        search.proximity = '';
                        search.proximityWithin = '';
                    } else {
                        search[key] = '';
                    }
                } else {
                    this.resetNearSearch();
                    this.resetAdvanceSearch();
                }
                // {...search} is to prevent object from being observable, because it will
                // always change its state and send wrong info to the request. This is
                // related to filters being able to be applied independently from eachother
                EventBus.fire('update-content-search-filters', {key: 'advancedSearch', value: {...this.search}, performSearch: false})
            }
        },
        mounted() {
            EventBus.listen('set-default-search', (searchTerm) => this.search.any = searchTerm)
            EventBus.listen('set-advanced-search', (searchTerms) => this.search = searchTerms)
            EventBus.listen('clear-advanced-search', (key) => this.clearSearch(key))
        }
    }
</script>
