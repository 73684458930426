var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "mb-4 roboto" }, [
                  _vm._v(
                    "\n                    Testimonials\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            _vm._l(_vm.testimonials, function (item, key) {
              return _c("v-col", { key: key }, [
                _c("div", { staticClass: "mx-15" }, [
                  _c("blockquote", { staticClass: "quoted" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item.text) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "font-weight-bold mb-0 mt-3 caption" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.name) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }