<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <breadcrumb title="Move Notes"></breadcrumb>
        <v-container :class="{'pr-3': isMobilePort}">
            <v-row justify-xl="center">
                <v-col xl="10">
                    <v-card>
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>
                                <v-icon>loop</v-icon> Move Notes
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pa-0">
                            <v-container class="pt-2 pb-0">
                                <v-row>
                                    <v-col>
                                        <p>Use the tool below to move or clone your notes to a newer book version.  You can use the "Select All Notes" to select all your notes or select each note individually.  You can also use the "Move All" and "Clone All" buttons to move/clone all your notes at one time.  When you move a note it moves it from one version to another.  When you clone a note it duplicates it for the other version.  After the process starts we will let you know if you need to update any notes.</p>
                                        <v-divider>
                                        </v-divider>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container class="white pt-0">
                                <v-row align="center">
                                    <v-col>
                                        <v-row dense>
                                            <v-col>
                                                <p class="caption mb-0">Move Notes From</p>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            dense
                                            align="center">
                                            <v-col cols="auto">
                                                <graphic
                                                    :title="migrateFromBook"
                                                    thumbnail
                                                    width="40px"
                                                    >
                                                </graphic>
                                            </v-col>
                                            <v-col>
                                                <a  @click="bookLink(migrateFromBook)">
                                                    <book-title
                                                        class="data-notes-titles ml-2"
                                                        :title="getBookTitle(migrateFromBook)"
                                                        :print_version_edition="printEdition(migrateFromBook.print_version_edition)"
                                                        :printing_text="migrateFromBook.print_info"
                                                        :print_version="migrateFromBook.print_version">
                                                    </book-title>
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-row dense class="d-inline-block">
                                            <v-col>
                                                <v-radio-group
                                                    v-model="anyBook"
                                                    row
                                                    dense
                                                    hide-details
                                                    class="mt-0 any-book">
                                                    <v-radio
                                                        label="Move notes to a different version of this book"
                                                        :value="false">
                                                    </v-radio>
                                                    <v-radio
                                                        label="Move notes to a different premium book"
                                                        :value="true">
                                                    </v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col>
                                                <v-autocomplete
                                                    required
                                                    hide-details
                                                    :items="migrateToBooks"
                                                    item-text="display_title"
                                                    item-value="document_id"
                                                    label="Select a book to move to"
                                                    solo
                                                    v-model="selectedDocument"
                                                    clearable>
                                                    <template v-slot:selection="{ item }">
                                                        <v-row
                                                            dense
                                                            align="center">
                                                            <v-col cols="auto">
                                                                <graphic
                                                                    :title="item"
                                                                    thumbnail
                                                                    width="40px"
                                                                    >
                                                                </graphic>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <h4 class="subtitle-2">
                                                                    {{item.display_title}}
                                                                </h4>
                                                                <p class="caption mb-0">
                                                                    {{item.print_version}}
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    <template v-slot:no-data>
                                                        <v-row>
                                                            <v-col>
                                                                <p class="caption text-center mt-4">
                                                                    <template v-if="loading.autoComplete">
                                                                        Loading Books
                                                                    </template>
                                                                    <template
                                                                        v-else-if="migrateToBooks.length === 0 && !loading.autoComplete">
                                                                        No books found for this selection
                                                                    </template>
                                                                    <template v-else>
                                                                        No books found for this search criteria
                                                                    </template>
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <v-row
                                                            dense
                                                            @click="setMigrateToBook(item.document_id)"
                                                            align="center">                                                      
                                                            <v-col cols="auto">
                                                                <graphic
                                                                    :title="item"
                                                                    thumbnail
                                                                    width="40px"
                                                                    >
                                                                </graphic>    
                                                            </v-col>
                                                            <v-col>
                                                                <h4 class="subtitle-2">
                                                                    {{item.display_title}}
                                                                </h4>
                                                                <p class="caption mb-0">
                                                                    {{item.print_version}}
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container class="grey lighten-3">
                                <template v-if="filteredResults.length > 0">
                                    <v-row>
                                        <v-col>
                                            <v-btn
                                                color="primary"
                                                @click="migrateAction('Move')">
                                                Move
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="migrateAction('Clone')">
                                                Clone
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-checkbox
                                                hide-details
                                                v-model="selectAllCheckbox"
                                                label="Select All">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col>
                                        <v-data-iterator
                                            :items="filteredResults"
                                            hide-default-footer>
                                            <template v-slot:item="data">
                                                <template v-for="(chapters, chapterId) in data.item.chapter">
                                                    <template v-for="(sections, sectionId) in chapters">
                                                        <v-row
                                                            align="center"
                                                            v-for="(result, noteId) in sections"
                                                            :key="noteId"
                                                            v-if="result instanceof Object">
                                                            <v-col cols="auto">
                                                                <v-checkbox
                                                                    :id="'checkbox_'+result.id"
                                                                    class="pa-0 ma-0"
                                                                    hide-details
                                                                    :class="{'body-2': $vuetify.breakpoint.smAndDown}"
                                                                    color="primary"
                                                                    :disabled="!result.isCodeAdmin"
                                                                    v-if="result.type == 'annotation' || result.type == 'highlight'"
                                                                    :value="'annotation_'+result.id"
                                                                    v-model="selectedBookValues.sections"
                                                                    @click.native="childChecked(chapterId, 'annotation_'+result.id)">
                                                                </v-checkbox>
                                                                <v-checkbox
                                                                    :id="'checkbox_'+result.bookmarkId"
                                                                    class="pa-0 ma-0"
                                                                    hide-details
                                                                    :class="{'body-2': $vuetify.breakpoint.smAndDown}"
                                                                    color="primary"
                                                                    :disabled="!result.isCodeAdmin"
                                                                    v-if="result.type == 'bookmark'"
                                                                    :value="'bookmark_'+result.bookmarkId"
                                                                    v-model="selectedBookValues.sections"
                                                                    @click.native="childChecked(chapterId, 'bookmark_'+result.bookmarkId)">
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col>
                                                                <v-card>
                                                                    <v-card-title class="pa-2 pb-0">
                                                                        <a
                                                                            target="_blank"
                                                                            @click="redirectToSection(data.item.info.document_id, chapterId, sectionId)">
                                                                            <h3 class="subtitle-1">
                                                                                {{result.section}}
                                                                            </h3>
                                                                        </a>
                                                                    </v-card-title>
                                                                    <v-card-text class="pa-2 py-0">
                                                                        <v-row dense>
                                                                            <v-col>
                                                                                <p
                                                                                    v-if="result.type == 'note' || result.type == 'annotation'"
                                                                                    class="note-list-quote font-italic pa-1 mb-0 mt-1">
                                                                                    {{result.content }}
                                                                                </p>
                                                                                <div
                                                                                    v-if="getDescription(result)"
                                                                                    class="mt-2 ml-1 description"
                                                                                    v-html="getDescription(result)">
                                                                                </div>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row
                                                                            dense
                                                                            align="center">
                                                                            <v-col cols="auto">
                                                                                <v-btn
                                                                                    depressed
                                                                                    fab
                                                                                    small>
                                                                                    <v-icon :color="getTagColor(result.tagId)">
                                                                                        {{getIcon(result.type) }}
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-col>
                                                                            <v-col>
                                                                                <h5>Created By: {{ result.creatorName }}
                                                                                </h5>
                                                                                <h5>Project Tag:
                                                                                    {{getTagName(result.tagId) }}
                                                                                </h5>
                                                                                <h5>Timestamp: {{result.created_at | moment("MMMM Do, YYYY")}}
                                                                                </h5>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row
                                                                            v-if="!result.isCodeAdmin"
                                                                            dense>
                                                                            <v-col>
                                                                                <p class="caption error--text mb-0">*
                                                                                    You do not have permission to migrate this note.
                                                                                </p>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </template>
                                            </template>
                                            <template slot="no-data">
                                                <v-row>
                                                    <v-col class="text-center py-6">
                                                        <template v-if="filteredResults.length == 0 && load">
                                                            <p class="mb-0">You do not have any bookmarks or notes.</p>
                                                        </template>
                                                        <template v-else-if="filteredResults.length == 0">
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="primary">
                                                            </v-progress-circular>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-data-iterator>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filteredResults.length > 0">
                                    <v-col>
                                        <v-btn
                                            color="primary"
                                            @click="migrateAction('Move')">
                                            Move
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="migrateAction('Clone')">
                                            Clone
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                persistent
                v-model="dialog"
                width="700">
                <v-card>
                    <v-card-title
                        class="primary white--text"
                        primary-title>
                        {{ dialogHeader }}
                    </v-card-title>
                    <v-card-text class="pa-4 text-center body-2">
                        <p class="mt-4" v-if="processPercentage < 1" v-html="dialogMessage"></p>
                        <p class="mt-2" v-html="migrationExists"></p>
                        <p class="mt-2" v-html="migrationPendings"></p>
                        <p v-if="procesingMigrations">
                            <v-progress-linear
                                class="mt-8"
                                v-model="processPercentage"
                                height="25"
                                reactive>
                                <strong class="white--text">{{ processPercentage }}%</strong>
                            </v-progress-linear>
                        </p>
                        <p class="text-center" v-if="procesingMigrations && processPercentage">
                            {{ processCounter }}
                        </p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="!procesingMigrations && (migrationPendings.length || (dialogMessage.length && !error))"
                            color="red white--text"
                            @click="closeDialog">
                            No
                        </v-btn>
                        <v-btn
                            v-if="viewBookButton && !migrationPendings.length"
                            color="red white--text"
                            @click="closeDialog">
                            Close
                        </v-btn>
                        <v-btn
                            v-if="procesingMigrations || !error"
                            :disabled="!!procesingMigrations"
                            color="primary"
                            @click="processMigrations">
                            Continue
                        </v-btn>
                        <v-btn
                            v-if="viewBookButton"
                            :disabled="!!procesingMigrations"
                            color="primary"
                            @click="goToTargetBook">
                            <span v-if="1 == this.isOwnUser">
                                View Book
                            </span>
                            <span v-else>
                                View Notes
                            </span>
                        </v-btn>
                        <v-btn
                            v-if="migrationPendings.length"
                            color="primary"
                            @click="goToNotesCompare">
                            Process Manually
                        </v-btn>
                        <v-btn
                            v-if="error && !migrationPendings.length && !viewBookButton"
                            color="primary"
                            @click="closeDialog">
                            Ok
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <back-to-top></back-to-top>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumb from '../../content/partials/page_titles';
    import BookTitle from "../licenses/parts/content_title";
    import BackToTop from '../../partials/buttons/back_to_top.vue';
    import Mobile from './../../inc/mixins/mobile';
    import NoteMixins from './mixins/note';
    import Graphic from "../../titles/cover/Graphic";
    import Moment from 'moment';

    export default {
        data: function () {
            return {
                migrations: {
                    existing: [],
                    new : [],
                    failed: []
                },
                loading: {
                    autoComplete: true,
                },
                dialog: false,
                dialogHeader: '',
                dialogMessage: '',
                migrationExists: '',
                migrationPendings: '',
                processCounter: '',
                error: false,
                processAction: '',
                procesingMigrations: false,
                processPercentage: 0,
                viewBookButton: false,
                selectAllCheckbox: false
            };
        },
        props: ['userId', 'documentId', 'isOwnUser', 'isConcurrent'],
        mixins: [NoteMixins, Mobile],
        components: {
            Breadcrumb,
            BookTitle,
            BackToTop,
            Graphic,
            Moment
        },
        watch: {
            anyBook(showAllBooks) {
                this.migrateToBooks = showAllBooks ? this.allMasterNewPrintingBooks : this.bookNewPrintings;
            },
            selectAllCheckbox(value) {
                this.selectAll(value);
            }
        },
        methods: {
            decideRoute() {
                if (1 == this.isOwnUser) {
                    return this.getRoute('notes');
                }
                else {
                    return this.getRoute("admin_note_migrations", {'userId': this.userId});
                }
            },
            processMigrations() {
                let processed = 0;
                let _this = this;
                _this.procesingMigrations = true;
                _this.processPercentage = 1; // Init
                _this.processCounter = '';
                _this.migrations.existing = [];
                _this.migrations.new = [];
                _this.migrations.failed = [];

                _.each(_this.selectedBookValues.sections, function (note) {
                    let noteInfo = note.split('_');
                    let postData = {
                        'userId': _this.userId,
                        'fromBook': _this.fromBookId,
                        'toBook': _this.selectedMigrateTo,
                        'noteType': noteInfo[0],
                        'noteId': noteInfo[1],
                        'action': _this.processAction
                    };
                    if (_this.procesingMigrations) {
                        if (_this.processPercentage < 100) { // This check may not be required
                            _this.$http.post(Routing.generate('migrate_notes_process'), postData)
                                .then(response => {
                                    if (response.data) {
                                        processed++;
                                        if (true == response.data.existing) {
                                            _this.migrations.existing.push(postData.noteId);
                                        } else if (true == response.data.new) {
                                            _this.migrations.new.push(postData.noteId);
                                        } else if (true == response.data.failed) {
                                            _this.migrations.failed.push(postData.noteId);
                                        }
                                        _this.processPercentage = Math.ceil(processed / _this.selectedBookValues.sections.length * 100);
                                        _this.processCounter = '(' + processed + '/' + _this.selectedBookValues.sections.length + ')';

                                        if (_this.processPercentage > 99 && _this.procesingMigrations) {
                                            _this.showStats();
                                        }
                                    }
                                });
                        }
                    } else {
                        _this.showStats();
                    }
                });
            },
            selectAll(active) {
                this.selectedBookValues.sections = [];
                if (active) {
                    this.errorCheck = true;
                    _.each(this.chapterNotes, (userNotes) => {
                        _.each(userNotes,  (userNote) =>  {
                            let split = userNote.split('_');
                            let checkbox = (document.getElementById('checkbox_'+split[1]));
                            if (!_.isUndefined(checkbox) && !checkbox.disabled) {
                                this.selectedBookValues.sections.push(userNote);
                                this.errorCheck = false;
                            }
                        });
                    });
                    if (this.errorCheck) {
                        this.error = true;
                        this.dialogHeader = 'Warning';
                        this.dialogMessage = '<p class="error--text mb-0 text-center">You do not own any notes to migrate.</p>';
                        this.dialog = true;
                    }
                }
            },
            migrateAction(action) {
                this.processAction = action;
                this.dialogHeader = this.processAction + ' Notes';
                this.dialogMessage = '';
                this.dialog = true;
                this.procesingMigrations = false;
                this.migrations.existing = this.migrations.new = this.migrations.failed = [];

                if (!this.selectedMigrateTo) {
                    this.error = true;
                    this.dialogMessage = '<p class="error--text mb-0 text-center">Please select any book to migrate the notes.</p>';
                } else if (_.size(this.selectedBookValues.sections) < 1) {
                    this.error = true;
                    this.dialogMessage = '<p class="error--text mb-0 text-center">Please select notes to migrate.</p>';
                } else {
                    this.dialogMessage = 'You are about to ' + this.processAction.toLowerCase() + ' <b>' + this.selectedBookValues.sections.length + '</b>';
                    this.dialogMessage += ' selected note(s) from <b>' + this.fromBookTitle + '</b> to <b>' + this.toBookTitle + '.</b><br><br>';
                    this.dialogMessage += ' Are you sure you would like to proceed?';
                }
            },
            showStats() {
                this.dialog = true;
                this.error = true; // To hide other buttons and dispaly stat message
                this.processPercentage = 0;
                this.dialogHeader = 'Notes Processed';
                this.viewBookButton = this.migrations.existing.length || this.migrations.new.length;
                this.dialogMessage = 'Total <b>' + this.migrations.new.length + '</b> out of <b>' + this.selectedBookValues.sections.length + '</b> note(s) migrated sucessfully!';

                if (this.procesingMigrations && this.migrations.existing.length) {
                    this.migrationExists = '<b>' + this.migrations.existing.length + '</b> note(s) found already migrated.';
                    this.dialogMessage = this.selectedBookValues.sections.length == this.migrations.existing.length ? '' : this.dialogMessage;
                }
                if (this.procesingMigrations && this.migrations.failed.length) {
                    this.migrationPendings = '<b>' + this.migrations.failed.length + '</b> note(s) requires you to process manually.<br>Notes were not successfully moved. Would you like to manually process your notes?';
                    this.dialogMessage = this.selectedBookValues.sections.length == this.migrations.failed.length ? '' : this.dialogMessage;
                }
                this.selectedBookValues = {
                    all: false,
                    books: [],
                    chapters: [],
                    sections: []
                };
                this.procesingMigrations = false;
            },
            closeDialog() {
                this.dialog = false;
                this.error = false;
                this.viewBookButton = false;
                this.dialogMessage = '';
                this.migrationExists = '',
                this.migrationPendings = '';

                if (this.procesingMigrations) {
                    this.processPercentage = 0;
                    this.procesingMigrations = false;
                    // Show abort status
                    this.dialog = true;
                    this.dialogHeader = 'Notes Processed';
                    this.error = true; // To hide other buttons and dispaly stat message
                    this.dialogMessage = 'Total <b>' + this.migrations.new.length + '</b> out of <b>' + this.selectedBookValues.sections.length + '</b> note(s) migrated sucessfully!';
                    if (this.procesingMigrations && this.migrations.existing.length) {
                        this.migrationExists = '<b>' + this.migrations.existing.length + '</b> note(s) found already migrated.';
                    }
                }
                // Refersh notes for the move action performed
                if (this.migrations.new.length && 'Move' == this.processAction) {
                    window.location.reload();
//                      TODO: don't do page reload
//                    this.getAnnotationAndBookmarks(this.fromBookId);
//                    this.selectedBookValues = {
//                        all: false,
//                        books: [],
//                        chapters: [],
//                        sections: []
//                    };
                }
                this.migrations = {
                    existing: [],
                    new: [],
                    failed: []
                };
            },
            goToNotesCompare() {
                let route = ('1' == this.isOwnUser) ? "migrate_notes_compare" : "admin_migrate_notes_compare";
                let postData = {
                    userId: this.userId,
                    fromBook: this.fromBookId,
                    toBook: this.selectedMigrateTo,
                    action: this.processAction,
                    notes: JSON.stringify(this.migrations.failed)
                };
                let form = document.createElement("form");
                let field = document.createElement("input");
                form.method = "POST";
                form.action = Routing.generate(route);
                field.value = JSON.stringify(postData);
                field.name = "postData";
                form.appendChild(field);
                document.body.appendChild(form);
                form.submit();
            },
            goToTargetBook() {
                if (1 == this.isOwnUser) {
                    let selectedBook = _.find(this.migrateToBooks, {'document_id': this.selectedMigrateTo});

                    if (selectedBook && selectedBook.slug) {
                        this.goToRoute('content_document_slug', {documentSlug: selectedBook.slug});
                    }
                    else if(selectedBook) {
                        this.goToRoute('content_document', {'documentId': selectedBook.document_id,'chapterId': 'toc'});
                    }
                } else {
                    this.goToRoute('admin_note_migrations', {userId: this.userId});
                }
            }
        },
        created() {
            this.setTags();
            this.setBookInfo(this.documentId);
            this.setAllBooksList(this.documentId);
        }
    }
</script>

<style lang="scss" scoped>
    .cover {
        max-width: 40px;
    }
    .v-select-list {
        padding: 0;
    }
</style>
