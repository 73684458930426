// for all things on /cart

export const CartModule = {
    state: {
        cartUpdateInProgress: false
    },
    getters: {
        isCartUpdateInProgress: (state) => state.cartUpdateInProgress,
    },
    mutations: {
        setCartUpdateInProgress(state, value) {
            state.cartUpdateInProgress = !!value;
        }
    }
}
