var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.cardHeader) +
                  " Project Tag\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-expand-transition",
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error",
                            },
                          ],
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mb-0",
                                  attrs: { type: "error", dense: "" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.error) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name",
                              solo: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.tag.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tag, "name", $$v)
                              },
                              expression: "tag.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.mask,
                                expression: "mask",
                              },
                            ],
                            staticClass: "ma-0 pa-0",
                            attrs: { "hide-details": "", solo: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          top: "",
                                          "nudge-bottom": "105",
                                          "nudge-left": "16",
                                          "close-on-content-click": false,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { style: _vm.swatchStyle },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.menu,
                                          callback: function ($$v) {
                                            _vm.menu = $$v
                                          },
                                          expression: "menu",
                                        },
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-0" },
                                              [
                                                _c("v-color-picker", {
                                                  attrs: { flat: "" },
                                                  model: {
                                                    value: _vm.tag.color,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tag,
                                                        "color",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "tag.color",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.tag.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.tag, "color", $$v)
                              },
                              expression: "tag.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { primary: "", text: "" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.saveTag } },
                [
                  !_vm.saving
                    ? _c("span", [
                        _vm._v("\n                    Save\n                "),
                      ])
                    : _c("v-progress-circular", {
                        attrs: { size: 20, indeterminate: "", color: "white" },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }