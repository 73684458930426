<template>
    <div>
            <alert-bar
                    v-if="error"
                    class="mt-2"
                    :message="error"
                    notificationType="error"
                    >
                </alert-bar>

        <v-autocomplete
            dense
            v-model="selectedCodeCycleLocation"
            :items="jurisdictionItems"
            hide-details
            @change="changeCodeCycleLocation"
            outlined
            label="Jurisdiction"
            :loading="isLoading"
            :disabled="isLoading">
        </v-autocomplete>

        <v-autocomplete
            class="mt-4"
            dense
            v-model="selectedCodeCycleYear"
            :items="yearItems"
            @change="changeCodeCycleYear"
            label="Year"
            outlined
            hide-details
            :loading="isLoading"
            :disabled="isLoading">
        </v-autocomplete>
        
      <template v-if="'' !== locationNote">
        <p class="mt-4 mb-0">
          {{ locationNote }}
        </p>

        <div @click="showLocationNoteModal = true"
             class="primary--text mt-1 font-weight-bold cursor-pointer d-flex align-center">
          Location Note
          <v-icon color="primary" class="ml-1">
            mdi-help-circle-outline
          </v-icon>
        </div>

        <confirm-dialog :is-open="showLocationNoteModal"
                        title="Location Note"
                        @closed="showLocationNoteModal = false"
        >
          <div class="mt-4">
            When you select either “I-Codes” or choose a US state, this message tells you which codes AI Navigator
            is using for its answers. As the ICC trains ICC AI Navigator on more custom code sets, messages will
            reference specific state codes. If a state uses only the I-Codes, or if AI Navigator is not yet trained
            on that states amended codes, this message will tell you that AI Navigator is using the best matched
            I-Codes for that state.
          </div>
          <template v-slot:buttons>
            <div class="w-full d-flex justify-center">
              <template>
                <v-btn class="mr-4" @click="showLocationNoteModal = false" color="primary">
                  Close
                </v-btn>
              </template>
            </div>
          </template>
        </confirm-dialog>
      </template>
    </div>
</template>

<script>
import Constants from '../../../constants';
import { User } from '../../../classes/User';
import { ApiService } from '../../../api/ApiService';
import { AsyncUtils } from '../../../util/AsyncUtils';
import ConfirmDialog from "../../ConfirmDialog.vue";
import AlertBar from "../../notifications/AlertBar.vue";
import {EventNames} from "../../../classes/EventNames";

export default {
    components: { ConfirmDialog, AlertBar },
    data() {
        return {
            error: null,
            isLoading: false,

            // raw list of jurisdictions as returned from /jurisdictions endpoint
            jurisdictions: [],
            selectedCodeCycleLocation: Constants.defaultCodeCycleLocation,
            selectedCodeCycleYear: Constants.defaultCodeCycleYear,

            // previously selected
            locationFallback: null,
            yearFallback: null,

            showLocationNoteModal: false,
        }
    },
    computed: {
        jurisdictionItems() {

            const unique = new Set();

            for (const item of this.jurisdictions) {
                unique.add(item.jurisdiction);
            }

            return [...unique].map((item) => {
                return {
                    text: item,
                    value: item
                }
            })
        },
        yearItems() {
            return (this.jurisdictions || []).filter((item) => {
                return item.jurisdiction === this.selectedCodeCycleLocation;
            }).map((item) => {
                return {
                    text: item.cycleYear,
                    value: item.cycleYear
                }
            });
        },
        selectedJurisdiction() {

            // find by location/year
            const found = (this.jurisdictions || []).find((item) => {
                return item.jurisdiction === this.selectedCodeCycleLocation && item.cycleYear === this.selectedCodeCycleYear;
            })

            return found ?? null;
        },
        locationNote() {
            return this.selectedJurisdiction?.primaryMessage || "";
        }
    },
    methods: {
        changeCodeCycleLocation() {

            this.locationFallback = this.selectedCodeCycleLocation;
            // this.yearFallback = null;

            ApiService.updatePreference({
                [User.CODE_CYCLE_LOCATION]: this.selectedCodeCycleLocation,
                [User.CODE_CYCLE_YEAR]: this.selectedCodeCycleYear,
            });
        },
        changeCodeCycleYear() {
            this.yearFallback = this.selectedCodeCycleYear;
            ApiService.updatePreference(User.CODE_CYCLE_YEAR, this.selectedCodeCycleYear);
        },
        async loadJurisdictions() {
            this.error = null;
            this.isLoading = true;

            return ApiService.getJurisdictionsCached().then((res) => {
                this.jurisdictions = (res || []);
            }).catch((err) => {
                console.error(err);
                this.error = 'Error loading jurisdictions. Try again later';
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    created() {

        // wait until user is ready
        AsyncUtils.awaitUntil(() => {
            return this.$store.getters.getUser !== null;
        }, 200).then(() => {

            const user = this.$store.getters.getUser;

            const lastLocation = user.getPreference(User.CODE_CYCLE_LOCATION);

            if (lastLocation) {
                this.locationFallback = lastLocation;

                this.yearFallback = null;
                const lastYear = user.getPreference(User.CODE_CYCLE_YEAR);

                if (lastYear) {
                    this.yearFallback = lastYear;
                }
            } else {
                this.locationFallback = Constants.defaultCodeCycleLocation;
                this.yearFallback = Constants.defaultCodeCycleYear;
            }

            // load available dropdown selections
            this.loadJurisdictions().then(() => {

                // prefill with previously selected values
                this.$nextTick(() => {

                    const containsLocationFallback = this.jurisdictionItems.find((item) => {
                        return item.value === this.locationFallback;
                    });

                    this.selectedCodeCycleLocation = containsLocationFallback ? this.locationFallback : Constants.defaultCodeCycleLocation;

                    const containsYearFallback = this.yearItems.find((item) => {
                        return item.value === this.yearFallback;
                    });

                    this.selectedCodeCycleYear = containsYearFallback ? this.yearFallback : Constants.defaultCodeCycleYear;

                });

            });
        });
    },
    mounted() {

        // when wanting to update location/year from another component
        EventBus.listen(EventNames.AI_NAVIGATOR_OPTIONS_UPDATE, (data) => {

            const {location, year} = data;

            if (location) {
                this.selectedCodeCycleLocation = location;
            }

            if (year) {
                this.selectedCodeCycleYear = year;
            }

        });

        // when location changed, update year
        this.$watch(() => this.selectedCodeCycleLocation, (newValue, oldValue) => {

            const yearFound = (this.yearItems || []).find((item) => {
                return item.value === this.selectedCodeCycleYear;
            });

            // select first year from available list as fallback
            if (yearFound === undefined) {
                const available = (this.yearItems || []);

                if (available.length) {
                    this.selectedCodeCycleYear = this.yearItems[0].value;
                }
            }

        }, {immediate: true});

        this.$watch(() => this.selectedCodeCycleYear, (newValue, oldValue) => {
            // do nothing for now
        }, {immediate: true});

        this.$watch(() => this.selectedJurisdiction, (newValue, oldValue) => {

            const {jurisdiction, cycleYear, namespace, primaryMessage, bookIds} = (newValue || {});

            // update global store
            this.$store.commit('setCodeCycleLocation', jurisdiction);
            this.$store.commit('setCodeCycleYear', cycleYear);
            this.$store.commit('setCodeCycle', namespace);
            this.$store.commit('setCodeCycleBookIds', bookIds || []);
            this.$store.commit('setLocationNote', primaryMessage || "");

        }, {immediate: true});
    }
}
</script>