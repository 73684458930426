<template>
    <div>
        <back-to-main-menu
            @backToMain="backToMain">
        </back-to-main-menu>
        <v-list-item class="my-2">
            Enterprise Admin Tools
        </v-list-item>
        <link-list-icons :links="links">
        </link-list-icons>
    </div>
</template>

<script>
    import BackToMainMenu from "./BackToMainMenu.vue";
    import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
    import LinkListIcons from "./LinkListIcons.vue";

    export default {
        name: 'EnterprisePanel',
        components: {LinkListIcons, BackToMainMenu},
        mixins: [megaMenuMixin],
        data() {
            return {
                links: [
                    {
                        title: 'Account Overview',
                        icon: 'mdi-account-group-outline',
                        iconColor: 'cdpBlue',
                        link: Routing.generate('enterprise_account_overview'),
                        active: true
                    },
                    {
                        title: 'Manage Users',
                        icon: 'mdi-account-cog-outline',
                        iconColor: 'cdpBlue',
                        link: Routing.generate('enterprise_manage_users'),
                        active: true
                    },
                    {
                        title: 'Activity Report',
                        icon: 'mdi-view-quilt-outline',
                        iconColor: 'cdpBlue',
                        link: Routing.generate('enterprise_activity_report'),
                        active: true
                    }
                ]
            }
        }
    }
</script>
