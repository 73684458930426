export class GeoLocator {

    /**
     * TODO: cache this for Firefox even if they forgot to check "remember this decision"
     * @return {Promise<GeolocationPosition|null>}
     */
    static async getCurrentPosition() {

        if ('geolocation' in navigator && 'permissions' in navigator) {

            return new Promise(async (resolve, reject) => {

                try {

                    navigator.geolocation.getCurrentPosition((position) => {
                        resolve(position);
                    }, (err) => {
                        reject(err);
                    });

                } catch (err) {
                    reject(err);
                }

            });
        }

        return null;
    }
}
