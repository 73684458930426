var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticStyle: { "z-index": "20001" },
      attrs: {
        width: "1200",
        scrollable: "",
        fullscreen: _vm.isMobileAndTablet,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "min-height": "80vh" } },
        [
          _c(
            "div",
            { staticClass: "primary white--text" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "5" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-3",
                                  attrs: { size: "40px", color: "white" },
                                },
                                [
                                  _vm._v(
                                    "\n                                mdi-" +
                                      _vm._s(_vm.category?.icon) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "h3",
                              { staticClass: "word-break" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.category?.name) +
                                    "\n                                "
                                ),
                                _vm.isMobilePort && _vm.category?.description
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-1",
                                        attrs: { small: "", color: "white" },
                                        on: {
                                          click: function ($event) {
                                            _vm.expand = !_vm.expand
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    mdi-information\n                                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 caption word-break font-weight-bold",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.title) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.sectionTitle
                              ? _c(
                                  "p",
                                  { staticClass: "mb-0 caption word-break" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.sectionTitle) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isMobilePort
                    ? [
                        _vm.category?.description
                          ? _c(
                              "v-col",
                              {
                                staticClass: "pt-0",
                                attrs: {
                                  cols: _vm.isMobilePort && "12",
                                  order: _vm.isMobilePort ? "2" : undefined,
                                  "align-self": "center",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 mr-4 caption word-break mt-3",
                                    class: _vm.isMobilePort && "mx-2",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.category?.description) +
                                        "\n                            "
                                    ),
                                    _vm.category?.learnMoreUrl
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "white--text text-decoration-underline pl-1",
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                            attrs: {
                                              href: _vm.category.learnMoreUrl,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.category?.learnMoreLabel
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _c("v-spacer"),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text mr-4",
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        close\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isMobilePort && _vm.category?.description
                ? _c("v-expand-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.expand,
                            expression: "expand",
                          },
                        ],
                      },
                      [
                        _c("v-divider"),
                        _vm._v(" "),
                        _c("p", { staticClass: "ma-2 caption" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.category?.description) +
                              "\n                        "
                          ),
                          _vm.category?.learnMoreUrl
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "white--text text-decoration-underline pl-1",
                                  staticStyle: { "font-size": "14px" },
                                  attrs: {
                                    href: _vm.category.learnMoreUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.category?.learnMoreLabel)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoading
            ? _c("loading-linear")
            : [
                _vm.tabs.length
                  ? [
                      _vm.category?.dropdown
                        ? _c(
                            "div",
                            { staticClass: "grey lighten-3 pa-3" },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  items: _vm.tabs,
                                  "item-value": "key",
                                  "item-text": "title",
                                  "background-color": "white",
                                  "menu-props": { bottom: true, offsetY: true },
                                },
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-tabs",
                            {
                              staticStyle: { flex: "unset" },
                              attrs: {
                                "show-arrows": "",
                                color: "primary",
                                "background-color": "grey lighten-3",
                                "active-class": "tags-history-tab",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.tabs, function (item) {
                              return _c("v-tab", { key: item.id }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.title) +
                                    "\n                    "
                                ),
                              ])
                            }),
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          staticClass: "fill-height overflow-auto",
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.tags, function (item, key) {
                          return _c(
                            "v-tab-item",
                            { key: key, staticClass: "fill-height" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", height: "100%" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass: "fill-height",
                                      class:
                                        _vm.getInsightType(item) === "PDF" ||
                                        _vm.getInsightType(item) ===
                                          "compliance calculators"
                                          ? "pa-0"
                                          : "pa-5",
                                    },
                                    [
                                      _vm.getInsightType(item) ===
                                      "premium answers"
                                        ? _c("premium-answers-content", {
                                            attrs: { tag: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) ===
                                      "compliance calculators"
                                        ? _c("i-frame-content", {
                                            attrs: { tag: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) === "PDF"
                                        ? _c("p-d-f-content", {
                                            attrs: { tag: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) === "Link"
                                        ? _c("link-content", {
                                            attrs: { tagData: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) === "Video"
                                        ? _c("video-content", {
                                            staticClass: "text-center",
                                            attrs: { tagData: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) === "Text"
                                        ? _c("title-content", {
                                            attrs: { tagData: item },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInsightType(item) === "xml"
                                        ? _c("x-m-l-id-content", {
                                            attrs: { tagData: item },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.displayClearCalcFooter
                        ? _c("clear-calcs-footer")
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hasTagRating
                        ? _c("premium-tag-rating", {
                            attrs: { tag: _vm.tagId },
                            on: { submit: _vm.onRatingSubmit },
                          })
                        : _vm._e(),
                    ]
                  : _c("div", { staticClass: "pa-5" }, [
                      _c("h2", [_vm._v("No results found...")]),
                    ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }