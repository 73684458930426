var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.titles.length
    ? _c(
        "v-container",
        { attrs: { fluid: !_vm.isExtraLarge } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c("v-col", [
                        _c("h3", { staticClass: "text-h6 font-weight-bold" }, [
                          _vm._v(
                            "\n                        Related Titles\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("title-list", {
                    attrs: {
                      titles: _vm.titles,
                      loading: _vm.loading,
                      itemsPerPage: _vm.itemsPerPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.itemsPerPage < _vm.titles.length
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = _vm.itemsPerPage + 10
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Show More\n                "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v(
                              "\n                    mdi-chevron-down\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }