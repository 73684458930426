<template>
    <v-card>

        <v-card-title>
            <a :href="getNoteSectionLink(item)" target="_blank">{{ item.section_title || item.chapter_title }}</a>
        </v-card-title>

        <v-card-text>

            <div class="mb-5">

                <template v-if="item.type === 'bookmark'">
                    <p class="mt-2 ml-1" v-if="item.text"
                       v-html="item.text">
                    </p>
                </template>

                <template v-else>

                    <p class="note-list-quote font-italic pa-1 mb-0 mt-1"
                       v-if="item.text || item.content">
                        {{ item.content || item.text }}
                    </p>

                    <p class="mt-2 ml-1" v-if="item.annotation"
                       v-html="item.annotation">
                    </p>

                </template>

            </div>

            <div class="d-flex justify-space-between">

                <div class="d-flex">
                    <div class="px-2">
                        <v-btn
                                depressed
                                fab
                                small>
                            <v-icon :color="getTagColor(item.tag)">
                                bookmark
                            </v-icon>
                        </v-btn>
                    </div>

                    <div>
                        <h5>Created By: {{ item.firstName + ' ' + item.lastName }}</h5>
                        <h5>Project Tag: {{ item.tag }}</h5>
                        <h5>Timestamp: {{ noteDateFormatted(item) }}</h5>
                    </div>
                </div>

                <div class="pa-5">

                    <v-btn
                            small
                            v-if="$vuetify.breakpoint.smAndUp"
                            @click="editNote(item)"
                            :disabled="!item.canEditNote">
                        Edit
                    </v-btn>
                    <v-btn
                            small
                            v-if="$vuetify.breakpoint.smAndUp"
                            @click="showDeleteModal(item)"
                            :disabled="!item.canDeleteNote">
                        Remove
                    </v-btn>

                </div>
            </div>

        </v-card-text>
    </v-card>
</template>

<script>
import NoteMixins from "./mixins/note";
import Mobile from "../../inc/mixins/mobile";
import {DateUtil} from "../../../util/DateUtil";

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    mixins: [NoteMixins, Mobile],
    methods: {
        getNoteSectionLink(note) {

            const contentId = note['section_number'];

            return Routing.generate('content_id_redirect', {
                'contentId': contentId
            });
        },
        noteDateFormatted(note) {

            if (note.modified_date && note.modified_date.date) {
                return DateUtil.timeToMdy(note.modified_date.date);
            }

            return '';
        },
        getTagColor(tagName){

            const allTags = this.$store.getters.getTags || [];

            const result = _.find(allTags, function(item) {
                return item.tag === tagName;
            });

            return result ? `#${result.color}` : this.$getConst('defaultTagColor');
        }
    }
}
</script>

<style scoped>

</style>
