var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.setChapterHeight,
          expression: "setChapterHeight",
        },
      ],
      staticClass: "chapter-wrapper pa-0 overscroll-contain",
      style: `background: ${_vm.$vuetify.theme.themes.light.primaryLight};`,
      attrs: { id: "chapter-wrapper" },
    },
    [
      !_vm.loading
        ? [
            _c(
              "a",
              {
                class: !_vm.activeOrCurrentChapter
                  ? "primary--text"
                  : "grey--text text--darken-4",
                attrs: { tabindex: "-1", href: _vm.overviewUrl },
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0 toc-chapter-item",
                    class: {
                      monotonesLightGreen: _vm.focusedChapter == "overview",
                    },
                  },
                  [
                    _c("v-col", {
                      staticClass: "pa-0 px-1",
                      attrs: { cols: "1" },
                    }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "pl-0 ml-1 toc-chapter-item-focused",
                        attrs: { tabindex: "0" },
                        on: { focus: _vm.makeFocus },
                      },
                      [
                        _c("p", { staticClass: "mb-0 body-2" }, [
                          _vm._v(
                            "\n                        Overview\n                    "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("chapter-item", {
              attrs: {
                chapters_data: _vm.chapters,
                disableToc: _vm.disableToc,
              },
            }),
          ]
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }