var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        large: "",
        outlined: _vm.outlined,
        color: _vm.color,
        href: _vm.getRoute("premium_features"),
        block: _vm.block,
      },
    },
    [_vm._v("\n    Learn More\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }