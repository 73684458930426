<template>
    <div class="cp">
        <v-divider>
        </v-divider>
        <v-row
            dense
            align="center"
            @click="showExpand = !showExpand">
            <v-col>
                <slot>
                </slot>
            </v-col>
            <v-col cols="auto">
                <v-btn icon>
                    <v-icon>
                        {{ showExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-expand-transition>
            <div
                class="my-2"
                v-if="showExpand">
                <slot name="expand">
                </slot>
            </div>
        </v-expand-transition>
        <v-divider>
        </v-divider>
    </div>
</template>

<script>
    export default {
        name: 'DividerExpand',
        data() {
            return {
                showExpand: false
            }
        },
        props: {
            openByDefault: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            if (this.openByDefault) {
                this.showExpand = true
            }
        }
    }
</script>