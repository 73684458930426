<template>

    <v-snackbar
            app
            top
            right
            dark
            v-model="showError"
            :timeout="timeout"
            color="red">

        <v-row
                id="error_text"
                dense
                align="center">
            <v-col cols="auto">
                <v-icon class="mr-2" x-large>
                    mdi-alert-rhombus-outline
                </v-icon>
            </v-col>
            <v-col>
                <p class="mb-0 font-weight-regular">{{ errorText }}</p>
            </v-col>
        </v-row>

    </v-snackbar>

</template>

<script>
import {EventNames} from "../../classes/EventNames";

export default {
    data() {
        return {
            showError: false,
            timeout: 3000,
            errorText: ''
        }
    },
    methods: {
        onErrorCallback(message) {
            let {text, timeout} = message;

            if (typeof text !== "string") {
                text = 'Something went wrong! Please try again.';
            }

            if (typeof timeout !== "number") {
                timeout = 4000;
            }

            this.errorText = text;
            this.timeout = timeout;
            this.showError = true;
        }
    },
    mounted() {
        EventBus.listen(EventNames.ERROR, this.onErrorCallback);
    }
}
</script>

<style scoped>
#error_text {
    font-size: 16px;
}
</style>
