<template>
    <v-list
        dense
        class="py-0">
        <v-list-item :href="getAiHref()">
            <v-list-item-icon class="mr-2">
                    <v-icon color="primary">
                        mdi-navigation-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title
                        class="black--text">
                        {{ getLabelText() }}
                    </v-list-item-title>
                </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    props: {
        search: {
            required: false,
            type: String,
            default: ''
        },
        premiumUser: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    methods: {
        getLabelText() {
            if (this.premiumUser && '' !== this.search) {
                return `Ask AI Navigator about "${ this.search }"`;
            } else if ('' !== this.search) {
                return `Ask AI Navigator code questions about "${ this.search }"`;
            } else {
                return `Ask AI Navigator code questions`;
            }
        },
        getAiHref() {
            if (this.premiumUser && '' !== this.search) {
                return this.getRoute('ai_navigator',  {'ask': this.search});
            } else {
                return this.getRoute('ai_navigator');
            }
        }
    }
}
</script>
