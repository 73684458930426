<template>
    <v-dialog
        v-model="associated_books"
        :fullscreen="isMobilePort"
        max-width="700px"
        scrollable>
        <v-card max-height="600px">
            <v-card-title
                    class="primary white--text"
                    :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Associated Books
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="dialogClose">close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <i>
                            This book has associated titles or versions available.<br />
                            Please select one of the available titles to view the code book contents.
                        </i>
                    </v-col>
                </v-row>
                <v-row
                    v-if="associated_books_array"
                    v-for="(book, index) in associated_books_array"
                    :key="index">
                    <v-col>
                        <v-card class="associated-books-card">
                            <v-card-text @click="fireRedirectToBookToc(book)">
                                <v-row align="center">
                                    <v-col
                                        cols="3">
                                        <img
                                            class="cover"
                                            :src="getBookCover(book)"
                                            @error="loadDefaultBookCover"
                                            width="100%" />
                                    </v-col>
                                    <v-col>
                                        <p class="subtitle-2 font-weight-bold mb-0 primary--text">{{ book.display_title || book.title }} - {{ book.year.year }}</p>
                                        <p
                                            class="caption"
                                            v-if="book.print_version_year">
                                            {{ getBookPrintingInfo(book) }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialogClose">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                associated_books_array: [],
                associated_books: false
            };
        },
        mixins: [Mobile],
        methods: {
            getAssociatedBookInfo(book) {
                let documentId = book.document_id;
                if(book.master_document_id && documentId !== book.master_document_id) {
                    documentId = book.master_document_id;
                }
                this.$http.get(
                    Routing.generate("associated_books", { document_id: documentId })
                ).then(result => {
                    // Will always include master book also
                    if (result.data.result_data.length > 1) {
                        // Show modal only when some published books are associated to it
                        this.associated_books_array = result.data.result_data;
                        this.associated_books = true;
                    } else {
                        // Redirect to the book TOC page
                        this.fireRedirectToBookToc(book);
                    }
                });
            },
            fireRedirectToBookToc(book) {
                EventBus.fire("redirect-to-book-toc", book);                
            },
            dialogClose() {
                this.associated_books = false;
            },
        },
        created() {
            EventBus.listen("get-associated-books", parameter =>
                this.getAssociatedBookInfo(parameter)
            );
        }
    };

</script>

<style lang="scss">
    .associated-books-card {
        cursor: pointer;
    }
</style>
