var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "print-menu-content",
      attrs: { id: _vm.getPrintMenuId(_vm.sectionId), title: "" },
    },
    [
      _c(
        "a",
        {
          staticClass: "print-one-section",
          on: {
            click: function ($event) {
              return _vm.printSelected(_vm.sectionId)
            },
          },
        },
        [_vm._v("Print just this section")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "print-all-sections",
          on: {
            click: function ($event) {
              return _vm.printSelected(_vm.sectionId, 1)
            },
          },
        },
        [_vm._v("Print with sub sections")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }