var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.accessDescriptors[_vm.accessLevel]
    ? _c(
        "v-tooltip",
        {
          attrs: {
            bottom: "",
            "max-width": "300",
            transition: "fade-transition",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-avatar",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              color:
                                _vm.accessDescriptors[_vm.accessLevel]
                                  .backGroundColor,
                              size: "24",
                              tabindex: "0",
                            },
                            on: {
                              focus: _vm.tooltipOpen,
                              blur: _vm.tooltipOpen,
                              keydown: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.tooltipOpen.apply(null, arguments)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "space",
                                      32,
                                      $event.key,
                                      [" ", "Spacebar"]
                                    )
                                  )
                                    return null
                                  return _vm.tooltipOpen.apply(null, arguments)
                                },
                              ],
                            },
                          },
                          "v-avatar",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: "",
                              color:
                                _vm.accessDescriptors[_vm.accessLevel]
                                  .iconColor,
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.accessDescriptors[_vm.accessLevel].icon
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2953064824
          ),
          model: {
            value: _vm.tooltipOpen,
            callback: function ($$v) {
              _vm.tooltipOpen = $$v
            },
            expression: "tooltipOpen",
          },
        },
        [
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "tooltip-content",
              on: { mouseenter: _vm.showTooltip, mouseleave: _vm.hideTooltip },
            },
            [_vm._v(_vm._s(_vm.accessDescriptors[_vm.accessLevel].description))]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }