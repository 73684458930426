import axios from 'axios'

export const ProfileApi = {
    http: axios.create(),
    getProfiles(user = null) {
        const param = user ? {userId: user} : {};
        return this.http.get(Routing.generate('get_user_profiles', param));
    },
    setProfile(profile) {
        let metadata = _.isUndefined(profile.metadata) ? null : profile.metadata;
        return this.http.put(
            Routing.generate('set_user_profile', {type: profile.type, metadata: metadata})
        );
    },
    setProfilePreferences(profilePreferences){
        return this.http.post(Routing.generate('save_user_page_activity_states'), 
            { userPreferences: JSON.stringify(profilePreferences) })
    }

}
