<template>
    <v-container
        id="chapter-wrapper"
        class="chapter-wrapper pa-0 overscroll-contain"
        v-resize="setChapterHeight"
        :style="`background: ${$vuetify.theme.themes.light.primaryLight};`">
        <template v-if="!loading">
            <a
                tabindex="-1"
                :href="overviewUrl"
                :class="!activeOrCurrentChapter ? 'primary--text' : 'grey--text text--darken-4'">
                <v-row
                    class="ma-0 toc-chapter-item"
                    :class="{
                        'monotonesLightGreen': focusedChapter == 'overview',
                    }">
                    <v-col cols="1" class="pa-0 px-1"></v-col>
                    <v-col
                        class="pl-0 ml-1 toc-chapter-item-focused"
                        tabindex="0"
                        @focus="makeFocus">
                        <p class="mb-0 body-2">
                            Overview
                        </p>
                    </v-col>
                </v-row>
            </a>
            <chapter-item
                :chapters_data="chapters"
                :disableToc="disableToc">
            </chapter-item>
        </template>
        <v-row v-else>
            <v-col class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import ChapterItem from './ChapterItem';

    export default {
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            chapterId: {
                required: false,
                type: Number,
                default: 0
            },
            examId: {
                type: String,
                required: false,
                default: ''
            }
        },
        components: {
            ChapterItem
        },
        data() {
            return {
                chapters: null,
                headerHeight: 0,
                loading: false
            }
        },
        computed: {
            chapterArray() {
                return this.$store.getters.getChapters;
            },
            activeOrCurrentChapter(){
                const id =  this.chapterId ? this.chapterId : this.activeChapter;
                this.focusedChapter = !id ? 'overview' : null;

                return id;
            },
            overviewUrl() {
                let url = Routing.generate('title_landing', {documentSlug: this.documentInfo.document_slug});
                if (this.isExam) {
                    url = url + '?examId='+this.examId;
                }
                return url;
            },
            disableToc(){
                return !this.$store.getters.isExamSession && this.isContentRestricted(this.documentInfo);
            },
            focusedChapter: {
                get() {
                    return this.$store.getters.getFocusedTocSection;
                },
                set(val) {
                    this.$store.commit('setFocusedSection', val);
                }
            }
        },
        watch: {
            documentInfo(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getChapters();
                }
            }
        },
        methods: {

            setChapterHeight() {
                let element = document.getElementById('chapter-wrapper');
                let navigation = document.getElementById('navigation-app'); //main header
                if (!navigation) { //check if exam header is available
                    navigation = document.getElementById('digital-reference-navigation');
                }
                if (element && navigation) {
                    if (this.headerHeight === 0) {
                        let chapterHeader = document.getElementById('chapter-wrap-header');
                        this.headerHeight = chapterHeader ? chapterHeader.clientHeight : 0;
                        const appSystemBar = document.getElementById('app-system-bar');
                        if (appSystemBar) {
                            this.headerHeight += appSystemBar.clientHeight;
                        }
                    }
                    element.style.height = (window.innerHeight - this.headerHeight - navigation.clientHeight)+'px';
                }
            },
            getChapters() {
                this.loading = true;
                let route = this.documentInfo.is_xml ? 'get_book_chapters' : 'get_pdf_chapters';
                this.$http.get(Routing.generate(route, { 'documentId': this.documentInfo.document_id })).then(
                    response => {
                        if (response.data) {
                            this.chapters = response.data;
                            if ('' !== this.examId) {
                                this.chapters.examId = this.examId;
                                response.data.examId = this.examId;
                            }
                            this.$store.commit('setChapters', response.data);
                            this.$store.commit('setChapterSections', _.find(response.data, {content_id: _.toNumber(this.chapterId)}));
                            this.getChapterID();
                            this.setExpandedActive();
                            this.loading = false;
                        }
                    }
                );
            },
            getChapterID() {
                let chapterId;
                let slugBefore = 'chapter';
                if (this.isXML) {
                    slugBefore = this.documentslug;
                }
                let url = window.location.pathname.split('/');
                let i;
                for (i = 0; i < url.length; i++) {
                    if(!this.isXML && 'content' == url[i]) {
                        let chapterSlug = url[i+2];

                        if(chapterSlug && 'chapter' != url[i+1]) {
                            let pdfChapter = _.find(this.chapterArray, {'pdf_chapter_slug': _.toString(chapterSlug)});

                            if(pdfChapter) {
                                EventBus.fire('show-book-content', pdfChapter);
                            }
                        }
                    } else if (url[i] == slugBefore) {
                        if (!this.isXML) {
                            chapterId = url[i+1];
                            let chapter = _.find(this.chapterArray, {'id': _.toNumber(chapterId)});
                            EventBus.fire('show-book-content', chapter);
                        } else {
                            let chapter = _.find(this.chapterArray, {'chapter_slug': url[i+1]});
                            if (chapter) {
                                EventBus.fire('update-chapter-title', chapter.title);
                                chapterId = chapter.content_id;
                            }
                        }
                        if (_.isInteger(chapterId)) {
                            this.activeChapter = chapterId;
                        }
                    } else if (i === 3) {  // Check for non slug XML books.
                        chapterId = url[i+1];
                        let chapter = _.find(this.chapterArray, {'content_id': _.toNumber(chapterId)});

                        if(chapter) {
                            EventBus.fire('update-chapter-title', chapter.title);
                            this.activeChapter = chapterId;
                        }
                    }
                }
                if (window.location.hash) {
                    this.$store.commit('setActiveHash', window.location.hash.substr(1));
                }
            },
            setExpandedActive() {
                let chapter =  _.find(this.$store.getters.getChapters, {content_id: _.toNumber(this.activeChapter)});
                if (chapter !== undefined) {
                    this.$store.commit('setExpandedActive', [{content_id: chapter.content_id, active: true}]);
                    // Add our parent list of sections.
                    if (window.location.hash) {
                        this.getExpandParent(chapter.sub_sections);
                    }
                }
            },
            getExpandParent(obj) {
                let _this = this;
                let activeHash = _.replace(_this.$store.getters.getActiveHash, new RegExp('text-id-','g'), '');
                _.forEach(obj, function(value) {
                    // Check to see if our item contains hash.
                    let filter = _.filterDeep(value, function(value, key) {
                        if (key === 'id' && value === activeHash || key === 'content_id' && value === _.toNumber(activeHash)) return true
                    });
                    if (_.size(filter) > 0) {
                        _this.$store.commit('addExpandedActive', {content_id: value.content_id, active: true});
                        if (!_.isUndefined(value.sub_sections)) {
                            _this.getExpandParent(value.sub_sections);
                        }
                    }
                });
            },
            makeFocus(){
                this.focusedChapter =  'overview';
            },
        },
        mounted() {
            if (this.documentInfo) {
                this.getChapters();
            }
            EventBus.listen('resize-chapters-panel', this.setChapterHeight);
        }
    }
</script>

<style lang="scss">
    #toc-wrapper-container {
        .chapter-wrapper {
            overflow-y: scroll;
        }
    }
    .legend-wrapper {
        .mini-text {
            font-size: 10px;
        }
        .v-tabs {
            .v-tabs__container {
                height: auto;
            }
            .active-tab-border {
                border: 1px solid #EEEEEE!important;
            }
        }
    }
    .toc-chapter-item:hover {
        background-color: var(--v-monotonesLightGreen-base) !important;
    }
</style>