import {QuickAccessApi} from "../api/QuickAccessApi";

const quickAccessGetterTypes = {
    titles: 'Titles',
    titlesLoading: 'TitlesLoading',
    recentlyAccessed: 'RecentlyAccessed'
}

const quickAccessMutationTypes = {
    setTitles: 'SetTitles',
    setTitlesLoading: 'SetTitlesLoading',
    setRecentlyAccessed: 'SetRecentlyAccessed'
}

const quickAccessActionTypes = {
    getTitles: 'GetTitles',
    getRecentlyAccessed: 'GetRecentlyAccessed'
}

const getters = {
    [quickAccessGetterTypes.titles]: (state) => state.titles,
    [quickAccessGetterTypes.titlesLoading]: (state) => state.titlesLoading,
    [quickAccessGetterTypes.recentlyAccessed]: (state) => state.recentlyAccessed
}

const mutations = {
    [quickAccessMutationTypes.setTitles](state, data) {
        state.titles = data;
        state.titlesLoading = false;
    },
    [quickAccessMutationTypes.setTitlesLoading](state) {
        state.titlesLoading = true;
    },
    [quickAccessMutationTypes.setRecentlyAccessed](state, data) {
        state.recentlyAccessed = data;
    }
}

const state = {
    titles: [],
    titlesLoading: false,
    recentlyAccessed: []
}

const actions = {
    async [quickAccessActionTypes.getTitles]({state, commit}, search) {
        commit(quickAccessMutationTypes.setTitlesLoading)

        try {
            const response = await QuickAccessApi.getAutoSuggest(search);
            commit(quickAccessMutationTypes.setTitles, response.data)
        } catch (error) {
            console.error(error)
        }
    },
    async [quickAccessActionTypes.getRecentlyAccessed]({state, commit}, search) {
        try {
            const response = await QuickAccessApi.getRecentlyAccessed(search);
            commit(quickAccessMutationTypes.setRecentlyAccessed, response.data)
        } catch (error) {
            console.error(error)
        }
    }
}

export const QuickAccessModule = {
    namespaced: true,
    quickAccessGetterTypes,
    quickAccessMutationTypes,
    state,
    getters,
    mutations,
    actions
}
