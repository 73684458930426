import Moment from 'moment';
import ConcurrencyModal from './../parts/concurrency_modal.vue';

export default {
    data() {
        return {
            pagination: {
                sortBy: 'subscription.document_info.year.year',
                descending: true,
                itemsPerPage: 10,
                rowsPerPageItems: [10, 20, 30, 50, 50, 100, 250]
            },
            concurrent_codes: [],
            loading: true,
            expand: false,
            componentKey: 0,
            access_code: 1,
            removeCodeModal: false,
            codeForRemove: []
        }
    },
    components: {
        ConcurrencyModal,
    },
    methods: {
        getConcurrentCodes() {
            this.loading = true;
            this.$http.get(Routing.generate('get_concurrent_codes', {userId: this.userId}))
                .then(response => {
                    _.forEach(response.data, function(value) {
                        _.sortBy(value.assigned_subscriptions, function (el) {
                            return el.subscription.document_info.title;
                        });
                    });
                    this.concurrent_codes = response.data;
                    this.loading = false;
                });
        },
        checkStatus(item) {
            let expire = Moment(item.expiration_date, "YYYY-MM-DD"),
                today = Moment(new Date(), "YYYY-MM-DD");
            return (expire > today) ? 'Active' : 'Expired';
        },
        openConcurrencyModal: function (item = null) {
            EventBus.fire('show-modal-concurrency-code', item);
        },
        getCodeAccessLogs: function (access_code_id, access_code) {
            this.access_code = access_code;
            this.$http.get(Routing.generate('get_access_logs',{'codeId' : access_code_id}))
                .then(response => {
                    this.exportCsv(response.data, 'concurrent_code', this.access_code);
                });
        },
        removeCodeAlertModal(item) {
            this.removeCodeModal = true;
            this.codeForRemove = item;
        },
        removeCode() {
            if (this.codeForRemove) {
                let id = this.codeForRemove.id;
                this.$http.get(Routing.generate('remove_concurrent_code', {'codeId': id}))
                    .then(response => {
                        this.removeCodeModal = false;
                        this.$session.set('switch-to-license-tab', 'Concurrent');
                        this.reloadSubscriptions();
                    });
            }
        },
        printEdition(data) {
            if (!_.isUndefined(data)) {
                return data.version;
            }
        }
    },
    beforeMount() {
        this.getConcurrentCodes();
    },
    created() {
        EventBus.listen('change-modal-key', () => this.componentKey +=1);
        EventBus.listen('get-concurrent-codes', () => this.getConcurrentCodes());
    }
}
