
export const AiNavigatorModule = {
    state: {
        codeCycleLocation: '',
        codeCycleYear: '',
        codeCycle: '',
        codeCycleBookIds: [],
        locationNote: '',
    },
    getters: {
        getCodeCycleLocation: (state) => state.codeCycleLocation,
        getCodeCycleYear: (state) => state.codeCycleYear,
        getCodeCycle: (state) => state.codeCycle,
        getCodeCycleBookIds: (state) => state.codeCycleBookIds,
        getLocationNote: (state) => state.locationNote
    },
    mutations: {
        setCodeCycleLocation(state, value) {
            state.codeCycleLocation = value;
        },
        setCodeCycleYear(state, value) {
            state.codeCycleYear = value;
        },
        setCodeCycle(state, value) {
            state.codeCycle = value;
        },
        setCodeCycleBookIds(state, value) {
            state.codeCycleBookIds = value;
        },
        setLocationNote(state, value) {
            state.locationNote = value;
        }
    }
}