var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.getId() } },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "mb-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "float-right content-search-action-icons",
                      },
                      [
                        _vm.showIcon
                          ? _c(
                              "span",
                              [
                                _vm.$store.getters.getDebugShowResultScores
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { color: "red" },
                                                        on: {
                                                          click:
                                                            _vm.openFullExplanationLink,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    help_outline\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          683455801
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "tooltip-content" },
                                          [
                                            _vm._v(
                                              "\n                                Score: " +
                                                _vm._s(_vm.resultScore) +
                                                "\n                                "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                Term Frequency: " +
                                                _vm._s(_vm.termFrequency) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      transition: "fade-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "item-link",
                                                {
                                                  attrs: {
                                                    meta: _vm.meta,
                                                    documentId: _vm.documentId,
                                                    breadcrumbs: _vm.breadcrumbs
                                                      ? _vm.breadcrumbs
                                                      : {},
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "right mr-2",
                                                        attrs: {
                                                          tabindex: "0",
                                                        },
                                                        on: {
                                                          keyup: [
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.showTooltip(
                                                                "input"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "space",
                                                                  32,
                                                                  $event.key,
                                                                  [
                                                                    " ",
                                                                    "Spacebar",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return _vm.showTooltip(
                                                                "input"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                        input\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1924689721
                                    ),
                                    model: {
                                      value: _vm.tooltips.input,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tooltips, "input", $$v)
                                      },
                                      expression: "tooltips.input",
                                    },
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tooltip-content",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.showTooltip("input")
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.hideTooltip("input")
                                          },
                                          focus: function ($event) {
                                            return _vm.showTooltip("input")
                                          },
                                          blur: function ($event) {
                                            return _vm.hideTooltip("input")
                                          },
                                        },
                                      },
                                      [_vm._v("Jump to this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      transition: "fade-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: onMenu }) {
                                            return [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    transition: "0",
                                                    location: "start",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on: onTooltip,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "right mr-2",
                                                                  attrs: {
                                                                    tabindex:
                                                                      "0",
                                                                  },
                                                                  on: {
                                                                    keyup: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        )
                                                                          return null
                                                                        return _vm.showTooltip(
                                                                          "file"
                                                                        )
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "space",
                                                                            32,
                                                                            $event.key,
                                                                            [
                                                                              " ",
                                                                              "Spacebar",
                                                                            ]
                                                                          )
                                                                        )
                                                                          return null
                                                                        return _vm.showTooltip(
                                                                          "file"
                                                                        )
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                                {
                                                                  ...onMenu,
                                                                  ...onTooltip,
                                                                }
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "file_copy"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "second-event",
                                                              rawName:
                                                                "v-second-event",
                                                              value: {
                                                                method:
                                                                  "registerClick",
                                                                value: "2",
                                                              },
                                                              expression:
                                                                "{ method: 'registerClick', value: '2' }",
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sectionCopyAction(
                                                                false
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                          Copy this Section to Clipboard (text only)\n                                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "second-event",
                                                              rawName:
                                                                "v-second-event",
                                                              value: {
                                                                method:
                                                                  "registerClick",
                                                                value: "2",
                                                              },
                                                              expression:
                                                                "{ method: 'registerClick', value: '2' }",
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.sectionCopyAction(
                                                                true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                          Copy this Section to Clipboard (with formatting)\n                                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3006388069
                                    ),
                                    model: {
                                      value: _vm.tooltips.file,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tooltips, "file", $$v)
                                      },
                                      expression: "tooltips.file",
                                    },
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tooltip-content",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.showTooltip("file")
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.hideTooltip("file")
                                          },
                                          focus: function ($event) {
                                            return _vm.showTooltip("file")
                                          },
                                          blur: function ($event) {
                                            return _vm.hideTooltip("file")
                                          },
                                        },
                                      },
                                      [_vm._v("Copy this Section to Clipboard")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      transition: "fade-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "second-event",
                                                        rawName:
                                                          "v-second-event",
                                                        value: {
                                                          method:
                                                            "registerClick",
                                                          value: "3",
                                                        },
                                                        expression:
                                                          "{ method: 'registerClick', value: '3' }",
                                                      },
                                                    ],
                                                    staticClass: "right mr-2",
                                                    attrs: { tabindex: "0" },
                                                    on: {
                                                      click:
                                                        _vm.sectionPrintAction,
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.showTooltip(
                                                            "print"
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "space",
                                                              32,
                                                              $event.key,
                                                              [" ", "Spacebar"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.showTooltip(
                                                            "print"
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "local_printshop\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2011982984
                                    ),
                                    model: {
                                      value: _vm.tooltips.print,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tooltips, "print", $$v)
                                      },
                                      expression: "tooltips.print",
                                    },
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tooltip-content",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.showTooltip("print")
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.hideTooltip("print")
                                          },
                                          focus: function ($event) {
                                            return _vm.showTooltip("print")
                                          },
                                          blur: function ($event) {
                                            return _vm.hideTooltip("print")
                                          },
                                        },
                                      },
                                      [_vm._v("Print this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      transition: "fade-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "second-event",
                                                        rawName:
                                                          "v-second-event",
                                                        value: {
                                                          method:
                                                            "registerClick",
                                                          value: "4",
                                                        },
                                                        expression:
                                                          "{ method: 'registerClick', value: '4' }",
                                                      },
                                                    ],
                                                    staticClass: "right mr-2",
                                                    attrs: { tabindex: "0" },
                                                    on: {
                                                      click:
                                                        _vm.sectionShareAction,
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return _vm.showTooltip(
                                                            "link"
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "space",
                                                              32,
                                                              $event.key,
                                                              [" ", "Spacebar"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.showTooltip(
                                                            "link"
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "link\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3956500051
                                    ),
                                    model: {
                                      value: _vm.tooltips.link,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tooltips, "link", $$v)
                                      },
                                      expression: "tooltips.link",
                                    },
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tooltip-content",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.showTooltip("link")
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.hideTooltip("link")
                                          },
                                          focus: function ($event) {
                                            return _vm.showTooltip("link")
                                          },
                                          blur: function ($event) {
                                            return _vm.hideTooltip("link")
                                          },
                                        },
                                      },
                                      [_vm._v("Share this Section")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.canBeBookmarked
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          top: "",
                                          transition: "fade-transition",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "second-event",
                                                            rawName:
                                                              "v-second-event",
                                                            value: {
                                                              method:
                                                                "registerClick",
                                                              value: "5",
                                                            },
                                                            expression:
                                                              "{ method: 'registerClick', value: '5' }",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "right mr-2",
                                                        attrs: {
                                                          tabindex: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.sectionBookmarkAction(
                                                              _vm.getId()
                                                            )
                                                          },
                                                          keyup: [
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.showTooltip(
                                                                "bookmark"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "space",
                                                                  32,
                                                                  $event.key,
                                                                  [
                                                                    " ",
                                                                    "Spacebar",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return _vm.showTooltip(
                                                                "bookmark"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            _vm.note.bookmarks
                                                              .length
                                                              ? "bookmark"
                                                              : "bookmark_border"
                                                          ) +
                                                          "\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2327718270
                                        ),
                                        model: {
                                          value: _vm.tooltips.bookmark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tooltips,
                                              "bookmark",
                                              $$v
                                            )
                                          },
                                          expression: "tooltips.bookmark",
                                        },
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tooltip-content",
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.showTooltip(
                                                  "bookmark"
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.hideTooltip(
                                                  "bookmark"
                                                )
                                              },
                                              focus: function ($event) {
                                                return _vm.showTooltip(
                                                  "bookmark"
                                                )
                                              },
                                              blur: function ($event) {
                                                return _vm.hideTooltip(
                                                  "bookmark"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Bookmark this Section")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "waffle-container",
                            on: { click: _vm.showActionIcons },
                          },
                          [_vm._v("apps")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-result-row" },
                      [
                        _c("item-header", {
                          attrs: {
                            title: _vm.getHighlightTitle(_vm.highlight),
                            meta: _vm.meta,
                            documentId: _vm.documentId,
                            breadcrumbs: _vm.breadcrumbs,
                            search: _vm.search,
                          },
                        }),
                        _vm._v(" "),
                        _c("item-content", {
                          attrs: {
                            title: _vm.getHighlightTitle(_vm.highlight),
                            content: _vm.getContent(),
                            sectionId: _vm.sectionId,
                            type: _vm.getType(),
                            breadcrumbs: _vm.breadcrumbs,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "mt-0 pt-0" }, [
                    _c(
                      "p",
                      { staticClass: "mb-0" },
                      [
                        _c("item-breadcrumb", {
                          attrs: {
                            meta: _vm.meta,
                            breadcrumbs: _vm.breadcrumbs ? _vm.breadcrumbs : {},
                            documentId: _vm.documentId,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("note-list", { attrs: { data: _vm.note } }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", right: "", color: "success", timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v("\n        Content was copied to buffer\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }