import axios from 'axios'

export const ComplianceCalculatorsApi = {
    http: axios.create(),
    getCalculators() {
        return this.http.get(Routing.generate('get_compliance_calculators'))
    },
    logCalculatorClick(apiData) {
        return this.http.post(Routing.generate('log_lp_calculator_click'), apiData);
    }
}
