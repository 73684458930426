export default {
    methods: {
        /**
         *
         * @param {string} url
         * @return {string}
         */
        asset(url) {

            // absolute URL already - return directly!
            if (url && url.startsWith('http')) {
                return url;
            }

            if (this.$store.getters.getCDNUrl !== '/') {
                return this.$store.getters.getCDNUrl + url;
            }
            return url;
        },
        
        getCDNUrl(){
            let url = '';
            if (this.$store.getters.getCDNUrl !== '/') {
                return this.$store.getters.getCDNUrl;
            }
            
            return url;
        }
    }
}
