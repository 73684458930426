<template>
    <div class="recommended recommended-book-list text-center" v-if="my_featured_books.length > 0">
        <div
            row
            wrap
            >
            <v-flex xs12>
                <h1 class="primary--text mr-4">Related Resources</h1>
            </v-flex>
        </div>
        <v-layout
            row
            wrap
            >
            <v-flex
                md3
                sm6
                xs12
                text-center
                v-for="(result, index) in my_featured_books"
                :key="index">
                <v-card
                    flat
                    class="result-items-container"
                    :class="{ 'mx': $vuetify.breakpoint.smAndDown }">
                    <v-card-text>
                        <img
                            class="mt-2 mb-1"
                            :src="getFeaturedItemCover(result.featured_image)" @error="loadDefaultBookCover"
                            @click="redirect(result.shop_url)"
                            width="100%"/>
                        <a @click="redirect(result.shop_url)">{{result.name}}</a>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        props: {
            chapterid: {
                type: Number,
                required: true,
            },
        },
        data () {
            return {
                my_featured_books : [],
            }
        },
        methods: {
            getFeaturedBooks : function () {
                this.$http.get(Routing.generate('featured_books', { 'chapterId': this.chapterid }))
                .then(response => {
                    this.my_featured_books = response.data;
                })
            },
            redirect: function(url) {                
                window.open(url, '_blank');
            }
        },
        beforeMount: function() {
        this.getFeaturedBooks();
        }
    }
</script>
