var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "subtitle-1 mb-0 font-weight-bold",
      on: { click: _vm.headerLinkClicked },
    },
    [
      _c(
        "item-link",
        {
          attrs: {
            meta: _vm.meta,
            documentId: _vm.documentId,
            breadcrumbs: _vm.breadcrumbs ? _vm.breadcrumbs : {},
          },
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.heading) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }