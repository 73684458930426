<template>
    <v-menu
        v-model="menu"
        open-on-hover
        offset-y
        right
        allow-overflow
        z-index="1005"
        content-class="user-profile-menu"
        nudge-width="180"
        :max-width="activeProfile ? '300px' : '240px'">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-on="on"
                v-bind="attrs"
                @keydown.enter.stop.prevent="openMenu"
                @keydown.space.stop.prevent="openMenu"
                @click="!isMobilePort && !activeProfile ? goToRoute('security_login'): ''">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <profile-icon
                            :profile="activeProfile ? activeProfile : {type: 'personal'}"
                            apply-background>
                        </profile-icon>
                    </v-col>
                    <v-col>
                        <template v-if="activeProfile">
                            <h4 class="text-left">
                                {{trimmedText(activeProfile.name, 18)}}
                            </h4>
                            <h5
                                class="font-weight-regular text-uppercase text-left"
                                style="font-size:10px">
                                {{activeProfile.type}}
                            </h5>
                        </template>
                        <template v-else>
                            <h4 class="text-left">
                                Sign In
                            </h4>
                        </template>
                    </v-col>
                </v-row>
            </v-btn>
        </template>
        <active-profile>
        </active-profile>
        <v-list role="menu" class="py-0 grey lighten-4">
            <v-list-item
                style="min-height: 20px;"
                class="grey lighten-2">
                <v-list-item-title class="text-center">
                    <h6>
                        <template v-if="activeProfile">
                            select from available profiles
                        </template>
                        <template v-else>
                            Not a Member of ICC Digital Codes?
                        </template>
                    </h6>
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <profiles-list>
        </profiles-list>
    </v-menu>
</template>

<script>
import Mobile from './../../inc/mixins/mobile'

import ProfilesList from './ProfilesList';
import ActiveProfile from './ActiveProfile';
import ProfileIcon from "./ProfileIcon";

export default {
    data(){
        return {
            menu: false
        }
    },
    components: {
        ProfileIcon,
        ProfilesList,
        ActiveProfile
    },
    mixins: [
        Mobile
    ],
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
    mounted() {
        this.$store.dispatch('profile/GetProfiles');
    }
}
</script>
<style lang="scss">
.user-profile-menu {
    max-height: calc(100vh - 70px);
    overflow-y: auto !important;
    .list-item {
        min-height: 49px;
    }
}

</style>
