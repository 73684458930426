<template>
    <v-container
        class="content-container px-12 pt-8">
        <v-row>
            <v-col>
                <f-a-qs
                    title="Billing Questions"
                    :faqs="faqs">
                </f-a-qs>
            </v-col>
        </v-row>
        <v-row class="my-9">
            <v-col class="text-center">
                <p class="fs-20 font-weight-bold">
                    Contact sales for more information or schedule a demo
                </p>
                <v-btn
                    color="primary"
                    target="_blank"
                    :href="this.$getConst('demoUrl')"
                    outlined>
                    Get a Demo
                </v-btn>
                <v-btn
                    color="primary"
                    class="ml-2 font-weight-bold"
                    :href="`mailto:${this.$getConst('sales_email_address')}`">
                    Contact Sales
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import mobile from '../inc/mixins/mobile';
import FAQs from '../expands/FAQs.vue';

export default {
    mixins:[mobile],
    components: {FAQs},
    data(){
        return {
            faqs: [
                    {
                        title: 'What does Digital Codes Premium cost?',
                        description: 'Digital Codes Premium subscriptions are priced based on the title or collection contents and the billing duration for the subscription. <a class="text-decoration-underline" href="#pricing_table">The form above</a> provides ability for users to create their Premium bundle based upon the codes they need and see the specific pricing for desired license quantity and duration options.'
                    },
                    {
                        title: 'What’s the difference between available tiers – Premium Singles, Premium Collections and Premium Complete?',
                        description: '<p>The primary difference across the tier options is the scope of titles available to the Premium customer.</p>' +
                            '<ul class="mb-4">' +
                            '<li>Premium Singles include individual titles within an active subscription, along with all earlier cycles for the same title.  Subscribing to 2024 IBC, for example, includes Premium features across the 2021 IBC, 2018 IBC, 2015, IBC, etc.</li>' +
                            '<li>Premium Collections offer the same feature set as Premium Singles across a grouped set of similar titles.  Premium Collections include access to all earlier cycles for included titles.</li>' +
                            '<li>Premium Complete provides access to all the International Codes, International Commentaries, ICC Standards, custom State Codes and more through the convenience of a single subscription. <a class="text-decoration-underline" href="' + this.getRoute('collections_landing',{documentSlug: 'premium-complete'}) + '" target="_blank">Learn more about Premium Complete.</a></li>' +
                            '</ul>'
                    },
                    {
                        title: 'Is there a free option available?',
                        description: '<p>You can try a Digital Codes Premium Complete subscription free of charge through a 14 day <a class="text-decoration-underline" href="' + this.getRoute('trial_signup') + '" target="_blank">trial</a>, which includes all <a class="text-decoration-underline" href="' + this.getRoute('premium_features') + '" target="_blank">contents and features</a> of the Complete tier.</p>' +
                            '<p>Additionally, a number of titles on Digital Codes are available with Basic tier which provides access to full contents in a read only format.  Look for the [insert unlock icon here] icon to identify titles with a Basic allowance.</p>'
                    },
                    {
                        title: 'What is included within my Digital Codes Premium subscription?',
                        description: '<p>You will have access to Premium features for titles that are part of your purchased subscription - Singles, Collections or Complete. Additionally, some features are included as part of any Premium subscription. <a class="text-decoration-underline" href="' + this.getRoute('premium_features') + '" target="_blank">Learn more about Premium Features.</a></p>'
                    },
                    {
                        title: 'Do you have Enterprise options to support larger teams?',
                        description: '<p>ICC Digital Codes Premium Enterprise provides convenient access for an entire organization. <a class="text-decoration-underline" href="' + this.getRoute('premium_features') + '" target="_blank">Learn more about Premium</a> for Teams or contact our sales team for more information at <a class="text-decoration-underline" href="mailto:' + this.$getConst('sales_email_address') + '">' + this.$getConst('sales_email_address') + '.</p>'
                    },
                    {
                        title: 'What forms of payment are accepted for Digital Codes Premium subscriptions?',
                        description: '<p>Premium subscriptions can be purchased online with a credit card and will be set to auto-renew by default. To pay for subscriptions without a credit card or for Enterprise and Custom Solutions, contact our sales team at <a class="text-decoration-underline" href="mailto:' + this.$getConst('sales_email_address') + '">' + this.$getConst('sales_email_address') + '.</p>'
                    },
                    {
                        title: 'How can I cancel my subscription?',
                        description: '<p>To cancel your subscription, sign in to Digital Codes, open billing, and select “Cancel”. Your subscription will not auto-renew, and you will not be charged again for that subscription. You will have access to your subscription until the final day of the original billing end date.</p>'
                    },
                    {
                        title: 'Can I change my subscription?',
                        description: '<p>While you cannot change your existing subscription during an active period you can purchase new subscription to add titles onto your existing access</p>'
                    },
                    {
                        title: 'What duration options are available for Digital Codes Premium subscriptions?',
                        description: '<p>Digital Codes Premium subscriptions are available in three convenient billing durations – monthly, annually and 3-year periods.  A 10% discount is included in annual durations while a 40% discount is included in 3-year durations when compared to month-to-month costs.</p>'
                    },
                    {
                        title: 'How can I reach out to a salesperson?',
                        description: '<p>You can connect with a Digital Codes Sales Specialist for a personalized quote or to schedule a demo at <a class="text-decoration-underline" href="mailto:' + this.$getConst('sales_email_address') + '">' + this.$getConst('sales_email_address') + '.</p>'
                    }
            ]
        }
    }
}

</script>