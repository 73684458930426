import axios from 'axios'
const client = axios.create();

export const TitlesApi = {
    getTitleInfo(documentId) {
        return client.get(Routing.generate('get_book_info', {documentId: documentId}));
    },
    getLegendContent(documentId) {
        return client.get(Routing.generate('get_legend_content', {documentId: documentId}));
    },
    getTitlesByCategory(data, page = 1) {
        return client.get(Routing.generate('search_results', {category: [data.category], year: [data.year], page: page, limit: 10}));
    },
    getTitles(data) {
        return client.get(Routing.generate('search_results', data));
    }
}
