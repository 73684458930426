export const EventNames = {
    ERROR: 'error',
    LOCATION_CHANGED: 'location:changed',
    FAST_TITLE_SEARCH_ENABLED: 'search:fast_title_search_enabled',
    EVALUATION_REDIRECT_MODAL_SHOW: 'evaluation_redirect_modal_show',

    // Search
    INIT_CONTENT_SEARCH: 'init-content-search',
    INIT_TITLE_SEARCH: 'init-title-search',
    INIT_GRAPHICS_SEARCH: 'init-graphics-search',
    RESET_TITLE_SEARCH: 'reset-title-search',
    REMOVE_ACTIVE_CATEGORY: 'remove-title-category',
    REMOVE_ALL_ACTIVE_CATEGORIES: 'remove-all-title-categories',

    // Title search filters
    SEARCH_FILTER_TITLE_SCOPES_CHANGED: 'search-filter-title-scopes-changed',
    REMOVE_TITLE_GROUP: 'remove-title-group',
    REMOVE_ACTIVE_YEAR: 'remove-title-year',
    REMOVE_ALL_ACTIVE_YEARS: 'remove-all-title-years',
    REMOVE_ACTIVE_TITLE: 'remove-active-title',
    REMOVE_ALL_ACTIVE_TITLES: 'remove-all-active-titles',

    // Content search filter
    SEARCH_FILTER_CONTENT_TYPES_CHANGED: 'search-filter-content-types-changed',

    // Graphics search filter
    UPDATE_GRAPHICS_SEARCH: 'update-graphics-search',
    RESET_GRAPHICS_SEARCH: 'reset-graphics-search',

    // AI Navigator
    AI_NAVIGATOR_OPTIONS_UPDATE: 'ai:options-update'
}
