var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expand,
            expression: "expand",
          },
        ],
      },
      [
        _c(
          "v-row",
          { attrs: { dense: "" } },
          [
            _c(
              "v-col",
              [
                _c("v-text-field", {
                  attrs: {
                    dense: "",
                    label: "Search title years",
                    outlined: "",
                    "hide-details": "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-card",
          {
            staticClass: "mt-2",
            staticStyle: { "overflow-y": "scroll" },
            attrs: { flat: "", "max-height": "200px" },
          },
          [
            _c("v-treeview", {
              attrs: {
                "open-all": "",
                dense: "",
                selectable: "",
                multiple: true,
                "return-object": "",
                search: _vm.query,
                "item-key": "id",
                "selected-color": "accent2",
                items: _vm.years,
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function ({ item }) {
                    return [
                      _c("p", { staticClass: "my-1 caption text-wrap" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectedYears,
                callback: function ($$v) {
                  _vm.selectedYears = $$v
                },
                expression: "selectedYears",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("filter-apply-button", {
          staticClass: "mt-2",
          attrs: {
            filter: {
              key: "years",
              value: _vm.selectedYears,
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.expand,
            expression: "!expand",
          },
        ],
      },
      [
        _vm.activeYears.length > 0
          ? [
              _c(
                "v-card",
                {
                  staticClass: "grey lighten-4",
                  style:
                    _vm.activeYears.length > 6 ? "overflow-y: scroll;" : "",
                  attrs: {
                    flat: "",
                    "max-height":
                      _vm.activeYears.length > 6 ? "200px" : "unset",
                  },
                },
                _vm._l(_vm.activeYears, function (item, key) {
                  return _c(
                    "v-chip",
                    {
                      key: key,
                      staticClass: "pr-1 mt-1 text-wrap",
                      attrs: {
                        small: "",
                        color: "primaryLight",
                        "text-color": "primary",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item) +
                          "\n                    "
                      ),
                      _c(
                        "v-icon",
                        {
                          attrs: { size: "19" },
                          on: {
                            click: function ($event) {
                              return _vm.removeYear(item)
                            },
                          },
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right mt-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: "",
                        color: "gray darken-2",
                        disabled: !_vm.activeYears.length > 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeAll()
                        },
                      },
                    },
                    [_vm._v("\n                    Reset\n                ")]
                  ),
                ],
                1
              ),
            ]
          : _c("p", { staticClass: "mb-0" }, [
              _vm._v("\n            Searching all years\n        "),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }