export default {
    data() {
        return {
            accessedDocumentId: 0,
            lastAccessedSection: 0,
        }
    },
    methods: {
        createAccessedDocument(documentId, sectionId, sectionTitle, type) {
            let data = {
                documentId: documentId,
                sectionId: sectionId,
                sectionTitle: sectionTitle,
                type: type
            };
            this.$http.get(Routing.generate('create_accessed_document', {
                data : data
            })).then(response => {
                    this.accessedDocumentId = response.data.id;
                });
        },

        trackClickedAction(action) {
            if (this.accessedDocumentId !== 0) {
                this.$http.get(Routing.generate('create_accessed_document_action', {
                    accessedDocumentId: this.accessedDocumentId,
                    action: action
                }));
            }
        },
    }
}
