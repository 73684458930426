var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "exam-tips-container grey lighten-3",
      class: {
        "pb-8 mt-8": !_vm.isMobilePort,
      },
    },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("h1", {
                  staticClass: "font-weight-regular pt-5 pb-2 oxygen",
                  domProps: { innerHTML: _vm._s(_vm.sectionData.title) },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "feature-tips",
              class: {
                "mobile-scroll": _vm.isMobilePort,
              },
            },
            _vm._l(_vm.sectionData.features, function (feature) {
              return _c(
                "v-col",
                {
                  key: feature.id,
                  attrs: { cols: !_vm.isMobilePort ? 6 : 12 },
                },
                [
                  _c("v-sheet", { staticClass: "py-5 px-10" }, [
                    feature.title
                      ? _c("h4", [_vm._v(" " + _vm._s(feature.title) + " ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(feature.description) },
                    }),
                    _vm._v(" "),
                    feature.buttonText
                      ? _c(
                          "p",
                          { attrs: { align: "center" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "accent2 white--text",
                                attrs: {
                                  small: _vm.small,
                                  href: feature.link,
                                  target: feature.target
                                    ? feature.target
                                    : "_self",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(feature.buttonText) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }