var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center", class: _vm.containerPadding },
    [
      _vm.showMessage
        ? _c("p", { staticClass: "mb-2" }, [
            _vm._v("\n        Loading Content ...\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-progress-linear", {
        attrs: { active: true, color: "primary", indeterminate: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }