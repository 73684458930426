var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "pdfViewer",
    attrs: {
      allowfullscreen: "true",
      webkitallowfullscreen: "true",
      mozallowfullscreen: "true",
      name: "pdfViewer",
      id: "pdfViewer",
      src: _vm.url,
    },
    on: { load: _vm.iframeLoaded },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }