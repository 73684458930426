var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container px-12 pt-8" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("f-a-qs", {
                attrs: { title: "Billing Questions", faqs: _vm.faqs },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-9" },
        [
          _c(
            "v-col",
            { staticClass: "text-center" },
            [
              _c("p", { staticClass: "fs-20 font-weight-bold" }, [
                _vm._v(
                  "\n                Contact sales for more information or schedule a demo\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    target: "_blank",
                    href: this.$getConst("demoUrl"),
                    outlined: "",
                  },
                },
                [_vm._v("\n                Get a Demo\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2 font-weight-bold",
                  attrs: {
                    color: "primary",
                    href: `mailto:${this.$getConst("sales_email_address")}`,
                  },
                },
                [_vm._v("\n                Contact Sales\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }