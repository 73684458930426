var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column fill-height" },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("h3", { staticClass: "my-1" }, [
                        _vm._v(
                          "\n                        Location / I-Codes\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g({ attrs: { icon: "" } }, on),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            color: "black !important",
                                          },
                                        },
                                        [_vm._v("mdi-information-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "tooltip-content" }, [
                            _vm._v(
                              'Select a US state or "I-Codes" for ICC model code'
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("navigator-location-selection"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "divider-expand",
        {
          staticClass: "my-6 flex-grow-1",
          staticStyle: { "max-width": "95%" },
          scopedSlots: _vm._u([
            {
              key: "expand",
              fn: function () {
                return [_c("navigator-tips-block")]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v("\n        Tips for using AI Navigator\n        ")]
      ),
      _vm._v(" "),
      _c("navigator-sidebar-disclaimer-block"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }