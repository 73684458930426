<template>
    <v-row align="center">
        <v-col
            v-if="showYears"
            cols="12"
            sm="3">
            <v-select
                outlined
                v-model="yearSelected"
                dense
                class="area-field-focus"
                :items="availableYears"
                hide-details
                clearable
                @change="updateYear()"
                :loading="yearsLoading"
                :disabled="yearsLoading"
                label="Year"
                data-aq="find-codes-year-filter">
            </v-select>
        </v-col>
    </v-row>
</template>

<script>

import Mobile from "../inc/mixins/mobile";

export default {
    mixins: [
        Mobile
    ],
    props: {
        category: {
            required: false,
            type: String
        },
        showYears: {
            required: false,
            type: Boolean
        },
        loading: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            yearSelected: null,
            yearsLoading: true,
            availableYears: []
        }
    },
    watch: {
        category(value) {
            this.getYears(value);
        }
    },
    methods: {
        getYears(value) {
            this.yearSelected = null;
            if (value) {
                this.yearsLoading = true;
                this.$http.get(Routing.generate('year_search_filters', {
                    'category': this.category
                })).then(response => {
                    this.availableYears = this.$store.getters.getSearchNavigationCategoryNames.includes(this.category)
                        ? response.data
                        : _.concat('Current Adoption', response.data);
                    if (!this.yearSelected && this.availableYears.includes('Current Adoption')) {
                        this.yearSelected = 'Current Adoption';
                    }
                    this.yearsLoading = false;
                });
            }
        },
        updateYear() {
            let data = {
                resetPage: true,
                query: {
                    year: this.yearSelected
                }
            }
            EventBus.fire('update-search-data', data);
        }
    }
}
</script>
