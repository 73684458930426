import { load } from 'recaptcha-v3';
import { AppParameters } from '../AppParameters';

export const GoogleRecaptchaV3 = {
    loaded: null,
    recaptcha: async function () {
        if (!this.loaded && AppParameters.google_recaptcha_v3_key) {
            this.loaded = await load(AppParameters.google_recaptcha_v3_key)
        }
        return this.loaded
    },
    execute: async function (action) {
        let recaptcha = await this.recaptcha();
        if (!recaptcha) {
            return null;
        }
        
        return await recaptcha.execute(action);
    }
}