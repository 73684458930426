<template>
    <v-row justify="center">
        <v-col>
            <v-btn
                block
                data-qa="landing-begin-trial"
                dark
                class="cdpBlue"
                @click="trialClick">
                <span class="roboto caption">Start Free 14-day Premium Complete Trial</span>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        mixins: [Mobile],
        methods: {            
            trialClick() {
                this.gtmEvent('Sign Up', 'Free Trial', 'Vertical Pricing Trial CTA');
                this.goToRoute('trial_signup')
            }
        }
    }
</script>
