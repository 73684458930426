var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "text-center" },
            [
              _c("v-col", [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                    No results found\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "primary", small: "" },
                      on: { click: _vm.searchAcrossAllTitlesClicked },
                    },
                    [
                      _vm._v(
                        "\n                    Search " +
                          _vm._s(_vm.defaultSearch) +
                          " across All Titles\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }