var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass: "body-2",
      class: {
        "mb-0": _vm.$vuetify.breakpoint.mdAndUp,
        "mb-4": _vm.$vuetify.breakpoint.smAndDown,
      },
    },
    [
      _vm._v("\n    Membership discount is not applied.  "),
      _c(
        "a",
        {
          staticClass: "font-weight-bold",
          attrs: {
            href: "https://www.iccsafe.org/membership/membership-benefits/",
            target: "_blank",
          },
        },
        [_vm._v("Click here")]
      ),
      _vm._v(" to learn about membership benefits.\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }