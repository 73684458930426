<template>
    <v-container>
        <v-row
            align="center"
            justify="center">
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All Project Tags"
                    v-model="tag"
                    :items="tag_list"
                    item-value="id"
                    item-text="label"
                    v-on:change="filterResult($event,'tag')"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All Categories"
                    v-model="category"
                    :items="category_list"
                    v-on:change="filterResult($event,'category')"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All My Library Titles"
                    v-model="book"
                    :items="book_list"
                    v-on:change="filterResult($event,'book')"
                    item-text="book"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="auto">
                <v-btn
                    color="primary"
                    large
                    class="ma-0"
                    @click="resetFilter"
                    block
                    title="Reset All Filters">
                    Reset
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col md="auto">
                <v-btn
                    color="primary"
                    class="mx-0"
                    @click="showRejectSharedWithMeModal"
                    :block="$vuetify.breakpoint.smAndDown">Reject Notes
                </v-btn>
            </v-col>
            <v-col md="auto">
                <v-btn
                    color="primary"
                    class="mx-0"
                    @click="showAcceptSharedWithMeModal"
                    :block="$vuetify.breakpoint.smAndDown">Accept Notes
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels accordion>
                    <v-data-iterator
                        class="sharing-data-iterator"
                        :items="filteredResults"
                        :items-per-page.sync="pagination.rowsPerPage">
                        <template v-slot:item="data">
                            <v-expansion-panel>
                                <template v-slot:actions>
                                    <v-icon v-if="isPremiumSubscribed(data.item.info.sku)" @click="checkPanelEvent(data)" color="primary">expand_more</v-icon>
                                    <v-btn
                                        v-else
                                        small
                                        :href="data.item.info.document_url"
                                        target="_blank"
                                        color="amber darken-2"
                                        class="white--text">
                                        Get Premium Subscription
                                    </v-btn>
                                </template>
                                <v-expansion-panel-header>
                                    <v-row
                                        align="center"
                                        :dense="isMobilePort">
                                        <v-col cols="auto">
                                            <a class="notes_book_link" @click="bookLink(data.item.info)">
                                                <graphic
                                                    :title="data.item.info"
                                                    thumbnail
                                                    width="70px"
                                                    additionalStyles="max-width: 70px;"
                                                    >
                                                </graphic>
                                            </a>
                                        </v-col>
                                        <v-col>
                                            <a class="notes_book_link" @click="bookLink(data.item.info)">
                                                <book-title
                                                    class="data-notes-titles ml-2"
                                                    style="min-width: 100%"
                                                    :title="getBookTitle(data.item.info)"
                                                    :print_version_edition="printEdition(data.item.info.print_version_edition.version)"
                                                    :printing_text="data.item.info.print_info"
                                                    :print_version="data.item.info.print_version">
                                                </book-title>
                                            </a>
                                        </v-col>
                                        <v-col cols="auto">
                                            <template v-if="isPremiumSubscribed(data.item.info.sku)">
                                                <p class="v-label theme--light mb-0">{{countNotesInChapter(data.item.chapter)}} Notes</p>
                                                <v-checkbox
                                                    hide-details
                                                    :key="data.item.info.id"
                                                    class="ma-0 pa-0"
                                                    v-model="selectedBookValues.books"
                                                    :value="data.item.info.id"
                                                    label="Select all notes"
                                                    checked="false"
                                                    @click.native="selectBookSections(data.item)">
                                                </v-checkbox>
                                            </template>
                                            <template v-else>
                                                <i>*You need to first purchase the premium subscription for this book to accept the shared content.</i>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                    color="grey lighten-4"
                                    class="sharing-expand">
                                    <div
                                        v-for="(chapters, chapterId) in data.item.chapter"
                                        :key="chapterId">
                                        <div
                                            v-for="(sections, sectionId) in chapters"
                                            :key="sectionId">
                                            <v-row
                                                v-if="result instanceof Object"
                                                v-for="(result, noteId) in sections"
                                                :key="noteId"
                                                align="center"
                                                class="ma-0">
                                                <v-col>
                                                    <v-card outline>
                                                        <v-card-title>
                                                            <h3
                                                                style="cursor: pointer"
                                                                class="mt-1 ml-1"
                                                                :class="{
                                                                    'title': !isMobilePort,
                                                                    'body-2': isMobilePort
                                                                }"
                                                                @click="redirectToSection(data.item.info.document_id, chapterId, sectionId)">
                                                                {{result.section}}
                                                            </h3>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row :dense="isMobilePort">
                                                                <v-col>
                                                                    <p
                                                                        v-if="result.type == 'note' || result.type == 'annotation'"
                                                                        class="note-list-quote font-italic pa-1 mb-0 mt-1">
                                                                        {{result.content }}
                                                                    </p>
                                                                    <p
                                                                        v-if="getDescription(result)"
                                                                        class="mt-2 ml-1"
                                                                        v-html="getDescription(result)">
                                                                    </p>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="auto">
                                                                    <v-btn depressed fab small>
                                                                        <v-icon :color="getTagColor(result.tagId)">
                                                                            {{getIcon(result.type) }}
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col>
                                                                    <h5>Project Tag: {{getTagName(result.tagId) }}</h5>
                                                                    <h5>Shared By: <i>{{ result.creatorName }} on {{ result.request_date }}</i></h5>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="1"
                                                    class="text-center">
                                                    <v-label
                                                        class="grey darken-4 px-2"
                                                        :class="{'caption': isMobilePort}"
                                                        :for="'checkbox_'+result.id">
                                                        Select for Accept and/or Reject
                                                    </v-label>
                                                    <v-checkbox
                                                        :id="'checkbox_'+result.id"
                                                        class="pa-0 ma-0"
                                                        hide-details
                                                        :class="{'body-2': $vuetify.breakpoint.smAndDown}"
                                                        color="primary"
                                                        width="auto"
                                                        v-if="result.type == 'annotation' || result.type == 'highlight'"
                                                        :value="'annotation_'+result.id"
                                                        v-model="selectedBookValues.sections"
                                                        @click.native="childChecked(chapterId, 'annotation_'+result.id)">
                                                    </v-checkbox>
                                                    <v-checkbox
                                                        :id="'checkbox_'+result.id"
                                                        class="pa-0 ma-0"
                                                        hide-details
                                                        :class="{'body-2': $vuetify.breakpoint.smAndDown}"
                                                        color="primary"
                                                        width="auto"
                                                        v-if="result.type == 'bookmark'"
                                                        :value="'bookmark_'+result.bookmarkId"
                                                        v-model="selectedBookValues.sections"
                                                        @click.native="childChecked(chapterId, 'bookmark_'+result.bookmarkId)">
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <template slot="no-data">
                            <v-row>
                                <v-col class="text-center">
                                    <template v-if="filteredResults.length == 0 && load">
                                        <p>No Results Found</p>
                                    </template>
                                    <template v-else>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary">
                                        </v-progress-circular>
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <modal-process-received-annotation-bookmarks></modal-process-received-annotation-bookmarks>
        <back-to-top></back-to-top>
    </v-container>
</template>

<script>
    import BackToTop from '../../../partials/buttons/back_to_top.vue';
    import ModalProcessReceivedAnnotationBookmarks from '../../../modals/process_received_annotation_bookmarks';
    import NoteMixins from './../mixins/sharing';
    import BookTitle from "./../../licenses/parts/content_title";
    import Moment from 'moment';
    import Notes from '../../../titles/notesModal/index';
    import Mobile from './../../../inc/mixins/mobile';
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [NoteMixins, Mobile],
        components: {
            BackToTop,
            ModalProcessReceivedAnnotationBookmarks,
            BookTitle,
            Moment,
            Notes,
            Graphic
        },

        created() {
            this.page = 'SharedWithMe';
            this.setRoute();
            window.addEventListener('scroll', this.handleScroll, {passive: true});
        }
    }
</script>

<style>
    .pdf-title-note .v-input__control {
        min-height: 38px !important;
    }

    .pdf-title-note label, input, i {
        font-size: 13px;
    }

    .note-image-width {
        min-width: 70px;
        max-width: 70px;
    }

    .note-checkbox-section .v-label {
        font-size: 14px;
    }

    .data-notes-titles p:first-child {
        font-size: 18px;
        font-weight: 600;
        max-width: 800px;
    }
    .data-notes-titles p {
        cursor: pointer!important;
    }
    .note-chapter-details {
        position: absolute;
        top: 25px;
        right: 80px;
    }

    .note-checkbox-section {
        text-align: center;
    }

    .note-checkbox-section .v-input__control {
        margin: 0 auto;
    }

    .notes-list-data .v-expansion-panel__header {
        cursor: default;
    }

    .v-expansion-panel__header .notes_book_link {
        display: flex;
        align-items: center;
    }

    .notes-list-data .v-expansion-panel{
        display: list-item!important;
    }
    .notes-action-bar-fixed {
        position: fixed;
        top: 65px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 5;
    }
    .shared_premium_required {
        position: absolute;
        top: 10px;
        right: 30px;
    }
    .note-action-bar-layout {
        max-width: 1150px;
        margin: 0 auto;
    }
</style>
