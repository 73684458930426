<template>
    <v-dialog
        data-qa="share-section-modal"
        scrollable
        persistent
        v-model="modal"
        max-width="700px"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Share This Section Link
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="closeShareModal">close</v-icon>
            </v-card-title>
            <v-card-text class="pt-0">
                <v-row v-if="alert_message">
                    <v-col>
                        <v-alert
                            light
                            dismissible
                            :value="alert_status"
                            :type="alert_type">
                            {{ alert_message }}
                        </v-alert>
                    </v-col>
                </v-row>
                <div v-for="(email, index) in emails">
                    <v-row align="center">
                        <v-col>
                            <v-text-field
                                :data-qa="'share-section-modal-email-'+index"
                                label="Email"
                                outlined
                                dense
                                v-model="email.email"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                text
                                data-qa="share-section-modal-add-more"
                                @click="addNewEmail()"
                                v-if="index === 0">
                                Add More
                            </v-btn>
                            <v-btn
                                text
                                :data-qa="'share-section-modal-remove-'+index"
                                @click="removeEmail(index)"
                                v-else>
                                Remove
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-row>
                    <v-col>
                        <v-textarea
                            data-qa="share-section-modal-textarea"
                            :id="'share-message-'+content_id"
                            outlined
                            rows="5"
                            auto-grow
                            hide-details
                            :value="'Hi,\n\nPlease check out this link to the '+book_name+' for '+section_title+' \n\n'+url">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    data-qa="share-section-modal-close"
                    color="primary"
                    text
                    @click="closeShareModal">
                    Close
                </v-btn>
                <v-btn
                    data-qa="share-section-modal-copy-url"
                    color="primary"
                    @click="copyURL">
                    Copy URL
                </v-btn>
                <v-btn
                    data-qa="share-section-modal-share"
                    color="primary"
                    :loading="shareLoading"
                    :disabled="shareLoading"
                    @click="inputFiledValidation">
                    Share
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        name: 'ShareSectionModal',
        data: function() {
            return {
                modal: false,
                emails: [{
                    'email': ''
                }],
                alert_status: false,
                alert_message: '',
                alert_type: 'success',
                share_loading: false,
                content_id: '',
                document_id: '',
                chapter_id: '',
                book_name: '',
                section_title: '',
                url: ''
            };
        },
        mixins: [
            Mobile
        ],
        computed: {
            shareLoading: function() {
                return this.share_loading;
            }
        },
        methods: {
            showModal(param) {
                this.content_id = param.content_id;
                this.document_id = param.document_id;
                this.chapter_id = param.chapter_id;
                this.book_name = param.book_name;
                this.section_title = param.section_title;
                this.$http.post(Routing.generate('get_url_slug', {
                    'documentId' : this.document_id,
                    'chapterId' : this.chapter_id
                })).then(response => {
                    if (!response.data.success) {
                        this.url = response.data.slugURL+'#'+param.section_id;
                    }
                });
                this.modal = true;
            },
            addNewEmail(){
                this.emails.push({email: ''});
            },
            removeEmail(index) {
                this.emails.splice(index, 1);
            },
            copyURL() {

                const container = document.querySelector('.v-dialog');

                let _this = this;
                this.$copyText(this.url, container).then(function (e) {
                    _this.alert_message = 'Link copied to clipboard.';
                    _this.alert_type = 'success';
                    _this.alert_status = true;
                });
            },
            inputFiledValidation() {
                if (!this.checkIfIsEmpty(this.emails)) {
                    this.alert_message = 'Please enter an email.';
                    this.alert_type = 'error';
                    this.alert_status = true
                } else if (!this.validateEmail(this.emails)) {
                    this.alert_message = 'Please enter a valid email.';
                    this.alert_type = 'error';
                    this.alert_status = true;
                } else {
                    this.share_loading = true;
                    let formData = new FormData();
                    formData.append('email', JSON.stringify(this.emails));
                    // Hack for value being blank when using v-model.
                    formData.append('message', document.getElementById('share-message-'+this.content_id).value);
                    formData.append('book', this.book_name);
                    formData.append('section', this.section_title);
                    this.$http.post(Routing.generate('share_chapter_section'), formData).then(response => {
                        if (response.data.success) {
                            this.alert_message = 'Section link shared successfully!';
                            this.alert_type = 'success';
                            this.alert_status = true;
                            setTimeout(() => this.closeShareModal(), 2000);
                        } else {
                            this.alert_message = 'Oops, an error occurred while sharing this link. Please try after sometime.';
                            this.alert_type = 'error';
                            this.alert_status = true;
                        }
                        this.share_loading = false;
                    });
                }
            },
            checkIfIsEmpty(emails){
                for(let i = 0; i < emails.length; i++) {
                    if (emails[i].email.length === 0) {
                        return false;
                    }
                }
                return true;
            },
            validateEmail(emails) {
                let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                for(let i = 0; i < emails.length; i++) {
                    if (re.test(emails[i].email) === false) {
                        return false;
                    }
                }
                return true;
            },
            cleanIt(string) {
                return _.startCase(_.toLower(string.replace(/\s\s+/g, ' ')));
            },

            closeShareModal(){
                this.modal = false;
                EventBus.fire('force-rerender-share-sections');
            }
        },
        created() {
            EventBus.listen('show-modal-share-sections', (param) => this.showModal(param));
        }
    }
</script>
