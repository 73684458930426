<template>
    <v-container class="container mt-13">
        <div class="search-text-button">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-card fab outlined @click="show = !show" v-bind="attrs" v-on="on" v-show="show">
                        <v-icon large>
                            mdi-chevron-left
                        </v-icon>
                    </v-card>
                </template>
                <span class="tooltip-content">Click to toggle Search Content</span>
            </v-tooltip>
        </div>
        <v-card v-show="!show" max-width="480" max-height="40">
            <v-row justify="space-between" dense>
                <v-col cols="auto" class="pa-0">
                    <v-text-field v-model="searchQuery" solo flat dense label="Search text..." @change="searchText" type="text" height="15">
                    </v-text-field>
            </v-col>
            <v-col cols="auto" class="pa-0 pt-2 pr-3" v-show="this.searchQuery != '' && this.defaultCount != 0">
               <span class="caption">{{ this.currentCount }}</span> &#x2f; <span class="caption">{{ this.defaultCount }}</span>
            </v-col>
            <v-col cols="auto" class="pa-0 pt-1">
                <span class="vl pa-1"></span>
                    <v-btn icon color="black" @click="clearMessage" class="">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                    <v-btn icon color="black" @click="searchBackWard">
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn icon color="black" @click="searchForwad">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn outlined class="rounded" icon color="black" @click="closeTextSearch">
                        <v-icon large> mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        searchQuery: "",
        defaultCount: 0,
        currentCount:0,
        show: false,
        backward: false,
    }),
    watch: {
        defaultCount: function(newValue) {
            if(newValue !== 0) {
                this.currentCount = 1;
            }
        },
    },
    methods: {
        searchText: function() {

            if(!this.searchQuery) {
                return
            }

            let keyword = this.searchQuery?.toLocaleLowerCase();
            let section = document.getElementById("app");
            let text = section.textContent.toLocaleLowerCase();

            let reg = new RegExp(keyword, "g");
            let matches = text.match(reg);

            // calculate result count
            this.defaultCount = matches ? matches.length : 0;
            
            if(this.currentCount !== this.defaultCount) {
                this.currentCount++;
            }
            return window.find(keyword,false,this.backward);

        },
        clearMessage: function() {
            this.searchQuery = '';
            this.currentCount = 0;
            this.defaultCount = 0;
        },
        searchForwad: function() {
            this.backward = false;
            this.searchText();
        },
        searchBackWard: function () {
            this.backward = true;
            if(this.currentCount !== 1){
                this.currentCount--;
                return window.find(this.searchQuery,false,this.backward);
            }
        },
        closeTextSearch: function () {
            this.searchQuery = '';
            this.currentCount = 0;
            this.defaultCount = 0;
            return this.show = true;
        }
    },
    mounted(){
        EventBus.listen('hide-exam-search-box', () => this.closeTextSearch());
    }
};
</script>

<style scoped>

.container {
    top: 85px;
    width: auto;
    position: fixed;
    z-index: 10000;
    right: 0;
}

.search-text-button {
    z-index: 200;
    right: 0;
    position: fixed;
    top: 140px;
}

.vl {
    border-left: 1px solid rgb(64, 65, 64);
    height: 35px;
}

</style>
