var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "font-weight-bold",
      attrs: {
        light: "",
        large: "",
        color: "accent2",
        elevation: "0",
        href: _vm.getRoute("ai_navigator"),
      },
    },
    [_vm._v("\n    Use AI Navigator\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }