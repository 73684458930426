var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("v-avatar", { attrs: { size: "32", color: "grey lighten-3" } }, [
            _vm._v(
              "\n            " + _vm._s(_vm.userNameInitials) + "\n        "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", [
        _c("p", { staticClass: "caption font-weight-bold mb-0" }, [
          _vm._v("\n            " + _vm._s(_vm.comment.name) + " "),
          _c("span", { staticClass: "font-weight-regular text--secondary" }, [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.comment.date))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "text-body-2",
          domProps: { innerHTML: _vm._s(_vm.comment.body) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }