<template>
    <div>
        <h3
            v-if="subHeading"
            class="fs-20 text-center teal--text font-weight-medium mb-2">
            {{ subHeading }}
        </h3>
        <h2
            v-if="mainHeading"
            :class="isMobilePort ? 'fs-24' : 'fs-32'"
            class="text-center oxygen mb-4">
            {{ mainHeading }}
        </h2>
    </div>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    export default {
        name: 'SubMainHeading',
        props: {
            subHeading: {
                required: false,
                type: String
            },
            mainHeading: {
                required: false,
                type: String
            }
        },
        mixins: [Mobile]
    }
</script>
