var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "roboto" },
    [
      _c(
        "h3",
        {
          staticClass:
            "primary--text text-uppercase text-center mb-5 font-weight-bold",
        },
        [_vm._v("\n        Popular Custom Code Collections by State\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        _vm._l(_vm.popularSearches, function (item, i) {
          return _c(
            "v-col",
            { key: i, attrs: { cols: "12", lg: "3" } },
            [
              _c("v-avatar", {
                staticClass: "mr-2",
                attrs: { color: "accent2", size: "5", rounded: "0" },
              }),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.getUrl(item.param) } }, [
                _vm._v(_vm._s(item.label)),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }