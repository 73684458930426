<template>
    <v-card
        :height="isMobilePort ? 'auto' : '270'"
        outlined>
        <h3 class="pl-4 pt-3 title font-weight-bold">
            Your Managed Subscriptions
        </h3>
        <div v-if="showActiveLicenses">
            <v-card-title class="font-weight-bold subtitle-1">
                {{this.activeLicenses.totalActivelicenses }} active license(s) on {{ this.activeLicenses.totalActiveSubscriptions }} subscription(s)
            </v-card-title>
            <v-card-text>
                <div v-if="activeLicenses.licensesToRenew">
                    <v-icon color="primary"> mdi-autorenew </v-icon>
                    {{ activeLicenses.licensesToRenew }} license(s) set to auto-renew
                </div>
                <div class="pt-3" v-if="activeLicenses.licensesToExpire">
                    <v-icon color="accent2">mdi-autorenew-off</v-icon>
                    {{ activeLicenses.licensesToExpire }} license(s) set to expire
                </div>
            </v-card-text>
            <v-card-actions :class="[isActiveLicensesClass]">
                <v-row align="start"
                    class="pl-3 font-weight-bold"
                    no-gutters
                    >
                    <v-col cols="12">
                        <a  :href="getLicenseConfigUrl()"
                            class="text-decoration-none"
                            target="_blank">
                            Configure Licenses
                        </a>
                    </v-col>
                    <v-col cols="12" class="pt-2 pb-2">
                        <a
                            class="text-decoration-none"
                            @click="goToTheShop(getShopManagePaymentsPage(), true)">
                            Manage Payment Options
                        </a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </div>
        <div v-else>
            <v-card-text class="font-weight-medium subtitle-1">
                <p>You don't have any active managed subscriptions.</p>
            </v-card-text>
            <v-card-actions :class="[!isMobilePort ? ['mt-16','pt-8'] : '']">
                <start-free-trial
                    v-if="showStartTrialAdmin"
                    :disabled="remoteProfile"
                    :large="false"
                    directStartTrial
                    class="mx-2">
                </start-free-trial>
                <v-btn
                    outlined
                    color="primary"
                    class="mx-2"
                    :class="isMobilePort && 'mb-3'"
                    @click="goToRoute('premium_features')"
                    >
                    Explore Premium
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
import StartFreeTrial from "../buttons/StartFreeTrial.vue";
import Mobile from "../inc/mixins/mobile";

export default {
    components: {StartFreeTrial},
    data: () => ({
        activeLicenses: {}
    }),
    mixins: [Mobile],
    props: {
        userPremiumSubscriptions: {
            required: true,
            type: Array
        },
        shopurl: {
            required: true,
            type: String
        },
        remoteProfile: {
            required: false,
            type: Object
        },
        userId: {
            type: String,
            required: false
        }
    },
    watch: {
        userPremiumSubscriptions: function() {
          this.setActiveLicenses();
        }
    },
    computed: {
        isActiveLicensesClass() {
            if (this.activeLicenses) {
                let renewing = this.activeLicenses.licensesToRenew;
                let expiring = this.activeLicenses.licensesToExpire;

                if (expiring && !renewing) {
                    return 'mt-8';
                } else if (!expiring && renewing) {
                    return 'mt-11';
                } else if (!expiring && !renewing) {
                    return 'mt-14 pt-6';
                }
            }
            return '';
        },
        showActiveLicenses() {
            return this.userPremiumSubscriptions.length &&
                   this.userPremiumSubscriptions.filter((e) => e.status === 'active');
        },
        showStartTrialAdmin() {
            const allowedProfileNames = [
                    this.$getConst("userTier").single,
                    this.$getConst("userTier").collection,
                    this.$getConst("userTier").complete,
                    this.$getConst("userTier").staff,
                    this.$getConst("userTier").trial
                ];

            if(this.remoteProfile) {
                return !allowedProfileNames.includes(this.remoteProfile.name);
            }

            return true;
        }
    },
    methods: {
        getShopManagePaymentsPage() {
            return this.shopurl + this.$getConst("links").shopManagePayments;
        },
        setActiveLicenses() {
            if(this.userPremiumSubscriptions.length) {

                this.activeLicenses = this.userPremiumSubscriptions.reduce((count, e) => {
                    if(e.status === 'active') {
                        count.totalActiveSubscriptions++;
                        count.totalActivelicenses = count.totalActivelicenses + parseInt(e.licences);
                    }
                    if (e.sub_status === 'active' && 'recurring' === e.renewal_type) {
                        count.licensesToRenew = count.licensesToRenew + parseInt(e.licences);
                    } else if (e.sub_status === 'active' || e.sub_status === 'cancelled') {
                        count.licensesToExpire = count.licensesToExpire + parseInt(e.licences);
                    } else if (e.sub_status === 'expired') {
                        count.licensesRefunded = count.licensesRefunded + parseInt(e.licences);
                    } else {
                        count.licensesExpired = count.licensesExpired + parseInt(e.licences);
                    }

                    return count;
                }, {
                    totalActiveSubscriptions: 0,
                    totalActivelicenses: 0,
                    licensesToRenew: 0,
                    licensesToExpire: 0,
                    licensesRefunded: 0,
                    licensesExpired: 0
                });
            }
        },
        getLicenseConfigUrl() {
            return this.userId ?
                Routing.generate("admin_subscriptions", {userId: this.userId}) :
                Routing.generate("license_configuration");
        }
    },
    created() {
        this.setActiveLicenses();
    }
};
</script>
