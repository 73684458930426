<template>
    <v-dialog
        v-model="dialog"
        width="670">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                No Categories Available
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="accent2--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                Looks you do not have any categories available.  
                                <a @click="goToRoute('dashboard_landing')">
                                    Click here
                                </a> to visit and sync your library.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';
    export default {
        data: function() {
            return {
                dialog: false,
            }
        },
        mixins: [Mobile],
        created() {
            EventBus.listen("show-no-categories-modal", () => this.dialog = true);
        }
    }
</script>
