<template>
    <span style="width: 100%;">
        <v-row class="pa-4">
            <v-col xs12>
                <v-row>
                    <v-col
                        cols="3">
                        <img style="max-width: 100%" :src="getBookCover(item.document_info)" @error="loadDefaultBookCover"/>
                    </v-col>
                    <v-col
                        cols="9">
                        <p class="font-weight-bold mb-0">
                            <template v-if="item.document_info.year">
                                {{item.document_info.year.year}} {{item.document_info.title}}
                            </template>
                            <template v-else>
                                {{item.document_info.title}}
                            </template>
                        </p>
                        <p class="caption mb-0 text--grey">Item #: {{item.document_info.sku}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="caption mb-0">Subscription Price</p>
                        <span class="caption font-weight-bold">${{ itemPrice }}</span>
                    </v-col>
                    <v-col>
                        <p class="caption mb-0">Total Item Price</p>
                        <span class="caption font-weight-bold">${{ itemTotal }}</span>
                    </v-col>
                    <v-col
                        cols="auto">
                        <v-btn
                            text
                            icon
                            @click="showExtra = !showExtra">
                            <template v-if="!showExtra">
                                <v-icon>keyboard_arrow_down</v-icon>
                            </template>
                            <template v-else>
                                <v-icon>keyboard_arrow_up</v-icon>
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-show="showExtra">
                    <v-row
                        dense
                        align="center">
                        <v-col>
                            <quantity :item="item"></quantity>
                        </v-col>
                        <v-col cols="auto">
                            <a
                                @click="openRemoveModal"
                                class="right font-weight-bold remove_link error--text caption">
                                Remove
                            </a>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <duration :item="item"></duration>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
    </span>
</template>

<script>
    import Item from './../mixins/item';

    import Duration from './duration';
    import Quantity from './quantity';

    export default {
        data() {
            return {
                showExtra: false
            }
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            itemPrice: {
                required: true,
                type: String
            },
            itemTotal: {
                required: true,
                type: String
            }
        },
        mixins: [Item],
        components: {
            Duration,
            Quantity
        }
    }
</script>
