var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("back-to-main-menu", { on: { backToMain: _vm.backToMain } }),
      _vm._v(" "),
      _c("v-list-item", { staticClass: "mt-2" }, [
        _vm._v("\n        Premium Tools\n    "),
      ]),
      _vm._v(" "),
      (!_vm.isActiveUser || _vm.canStartTrial) &&
      !_vm.hasActiveTrial &&
      !_vm.hasCompleteSubscription
        ? _c(
            "v-card",
            {
              staticClass: "accent2Light ma-4 rounded-lg",
              attrs: { flat: "" },
            },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { attrs: { color: "accent2" } }, [
                            _vm._v(
                              "\n                        mdi-creation\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                        Get full access to these features and more with a Premium subscription!\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("start-trial-button", {
                            attrs: { block: "", color: "accent2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("link-list-icons", { attrs: { links: _vm.links } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }