var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-expansion-panels",
        {
          staticClass: "notes-expansion-panel",
          model: {
            value: _vm.defaultExpansion,
            callback: function ($$v) {
              _vm.defaultExpansion = $$v
            },
            expression: "defaultExpansion",
          },
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "pt-2 pb-0 px-0",
                  attrs: { "hide-actions": "" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-icon", { staticClass: "text--secondary" }, [
                            _vm._v(
                              _vm._s(
                                _vm.expansionState
                                  ? "mdi-chevron-right"
                                  : "mdi-chevron-up"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._t("title"),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { staticClass: "px-6" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [_c("v-col", [_vm._t("content")], 2)],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }