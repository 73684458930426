export const NotesModule = {
    state: {
        annotations: [],
        bookmarks: []
    },
    getters: {
        getAnnotations: (state) => state.annotations,
        getBookmarks: (state) => state.bookmarks
    },
    mutations: {
        setAnnotations(state, annotations) {
            state.annotations = annotations;
        },
        setBookmarks(state, bookmarks) {
            state.bookmarks = bookmarks;
        }
    }
}
