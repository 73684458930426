<template>
    <v-expansion-panels accordion v-model="openPanels" :multiple="true">

        <v-data-iterator
                :items="data"
                :items-per-page="10"
                class="w-full">

            <template v-slot:no-data>
                <div class="pt-12 text-md-center">
                    <p>You do not have any bookmarks or notes.</p>
                </div>
            </template>

            <template v-slot:default="{ items, isExpanded, expand }">

                <v-expansion-panel
                        v-for="(item, index) in items"
                        :key="index">

                    <v-expansion-panel-header>
                        <v-row align="center">

                            <v-col>
                                <title-cover
                                        :title="item.document"
                                        type="list"
                                        :preventLink="true"
                                        :showVersion="true"
                                        :showFavorite="false"
                                        :showLinkIcon="false"
                                        :listOutline="false">
                                </title-cover>
                            </v-col>

                            <v-col
                                    cols="auto"
                                    class="pr-7">

                                <div>

                                    <v-btn
                                            type="button"
                                            color="primary"
                                            :class="{'small': $vuetify.breakpoint.smAndDown}"
                                            @click.native.stop="migrateNotesClicked(item.document.document_id)"
                                    >
                                        Migrate Notes
                                    </v-btn>

                                    <v-btn
                                            type="button"
                                            color="primary"
                                            :class="{'small': $vuetify.breakpoint.smAndDown}"
                                            class="ml-4"
                                            @click.native.stop="clickedNotesLog(item.document.document_id)">
                                        <span v-if="true">Notes Log</span>
                                        <v-progress-circular
                                                v-else
                                                :size="20"
                                                indeterminate
                                                color="white">
                                        </v-progress-circular>
                                    </v-btn>

                                </div>

                                <div class="d-flex justify-space-between align-center mt-3">

                                    <div>{{ item.notes.length }} Notes</div>

                                    <div>
                                        <label class="vuetify-checkbox-label v-label theme--light"
                                               @click.stop>

                                            <input type="checkbox"
                                                   v-model="documentSelectAllSelections"
                                                   :value="item.document.document_id"
                                                   class="vuetify-checkbox ma-1 pa-0"
                                                   @change="selectAllDocumentNotesChanged(item.document.document_id)"
                                                   @click.stop>
                                            Select all notes
                                        </label>
                                    </div>

                                </div>
                            </v-col>

                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="notes-expand grey lighten-4">

                        <v-data-iterator :items="item.notes">
                            <template v-slot:default="{ items, isExpanded, expand }">

                                <template v-for="(item, index) in items">
                                    <v-row>

                                        <v-col md="11">
                                            <note-card :item="item"></note-card>
                                        </v-col>

                                        <v-col cols="12" sm="1">

                                            <label
                                                    v-if="item.type === 'annotation' || item.type === 'highlight'"
                                                    class="vuetify-checkbox-label v-label theme--light">
                                                <input type="checkbox" class="vuetify-checkbox"
                                                       v-model="selectedNotes"
                                                       :value="'annotation_' + item.id"
                                                       @change="noteSelectionChanged(item)">
                                                Select for Sharing and/or Printing
                                            </label>

                                            <label v-if="item.type === 'bookmark'"
                                                   class="vuetify-checkbox-label v-label theme--light">
                                                <input type="checkbox" class="vuetify-checkbox"
                                                       v-model="selectedNotes"
                                                       :value="'bookmark_' + item.bookmarkId"
                                                       @change="noteSelectionChanged(item)">
                                                Select for Sharing and/or Printing
                                            </label>

                                        </v-col>

                                    </v-row>
                                </template>

                            </template>
                        </v-data-iterator>


                    </v-expansion-panel-content>
                </v-expansion-panel>

            </template>
        </v-data-iterator>

    </v-expansion-panels>
</template>

<script>
import TitleCover from "./../../titles/cover/Index";
import NoteMixins from "./mixins/note";
import Mobile from "../../inc/mixins/mobile";
import NoteCard from "./NoteCard";
import {MapOfArrays} from "../../../classes/MapOfArrays";
import {ArrayUtils} from "../../../util/ArrayUtils";

/**
 * @typedef Note
 * @property {number} id
 * @property {number} bookmarkId
 * @property {number} documentInfoId
 * @property {string} document_id
 *
 */

/**
 * @typedef {Object} DocumentWithNotes
 * @property {{document_id: number}} document
 * @property {Note[]} notes
 */

export default {
    emits: ['selectionChanged', 'panelSelectionChanged'],
    props: {
        data: {
            type: Array,
            required: true
        },
        pages: {
            type: Number,
            required: false,
            default: 20
        }
    },
    components: {
        NoteCard,
        TitleCover
    },
    mixins: [NoteMixins, Mobile],
    data() {
        return {
            openPanels: [],
            selectedNotes: [],
            documentSelectAllSelections: []
        }
    },
    computed: {
        documentNoteIdsMap() {

            /** @type {DocumentWithNotes[]} */
            let data = this.data;

            // document_id = [note_id, note_id...
            const documentNotes = new MapOfArrays();

            if (data) {

                data.forEach((value) => {

                    const ids = value.notes.map((note) => {
                        return this.getUniqueIdForNote(note);
                    });

                    documentNotes.push(value.document.document_id, ids);
                })
            }

            return documentNotes;
        },
        selectedNoteDocumentIds() {

            const documentIds = [];

            /** @type {DocumentWithNotes[]} */
            let data = this.data;

            data.forEach((value) => {

                for (const note of value.notes) {

                    const noteId = this.getUniqueIdForNote(note);

                    if (noteId && this.selectedNotes.includes(noteId)) {
                        documentIds.push(value.document.document_id);
                        break;
                    }
                }

            });

            return documentIds;
        },
    },
    methods: {
        migrateNotesClicked(documentId) {
            const routeName = window.location.toString().includes('admin') ? "admin_migrate_notes" : "migrate_notes";

            const route = Routing.generate(routeName, {
                documentId: documentId,
                userId: this.userId
            });

            window.open(route, '_blank');
        },
        clickedNotesLog(document_id) {

            this.pdfLoading = true;
            this.exportPdf(Routing.generate('generate_notes_log', {documentId: document_id}), 'notes_migration_log.pdf');
            this.pdfLoading = false;
        },
        getUniqueIdForNote(note) {

            if (note.type === 'bookmark') {
                return 'bookmark_' + note.bookmarkId;
            }

            return 'annotation_' + note.id;
        },
        /**
         *
         * @param {Note} note
         */
        noteSelectionChanged(note) {

            // A note was selected that belongs to this Document
            const documentId = parseInt(note.document_id);
            this.syncSelectAllNotesForDocument(documentId);
        },
        syncSelectAllNotesForDocument(documentId) {
            // should "Select all notes" still be selected for that document?
            const documentAllNotes = this.documentNoteIdsMap.getArray(documentId);

            if (ArrayUtils.containsAll(this.selectedNotes, documentAllNotes)) {
                this.documentSelectAllSelections.push(documentId);
            } else {
                ArrayUtils.removeInPlaceWhere(this.documentSelectAllSelections, documentId);
            }
        },
        /**
         *
         * @param {number} documentId
         */
        selectAllDocumentNotesChanged(documentId) {

            const isChecked = this.documentSelectAllSelections.includes(documentId);

            // what notes are part of this title?
            const documentNotes = this.documentNoteIdsMap.getArray(documentId);

            if (isChecked) {

                // add notes from this Document to overall selections
                const notAlreadySelected = documentNotes.filter((id) => {
                    return !this.selectedNotes.includes(id);
                });

                this.selectedNotes.push(...notAlreadySelected);

            } else {

                // uncheck all notes associated with that Document
                this.selectedNotes = this.selectedNotes.filter((id) => {
                    return !documentNotes.includes(id);
                });
            }
        }
    },
    mounted() {

        this.$watch(() => this.selectedNotes, () => {
            const notes = Array.from(this.selectedNotes ?? []);
            this.$emit('selectionChanged', notes);
        }, {
            immediate: true
        });

        this.$watch(() => this.openPanels, () => {
            this.$emit('panelSelectionChanged', Array.from(this.openPanels));
        });

        // "Select all notes" clicked
        EventBus.listen('notes:updateSelection', (values) => {

            if (Array.isArray(values)) {
                this.selectedNotes = values;

                // which "select all" boxes should be pre-checked?
                this.documentSelectAllSelections = Array.from(this.selectedNoteDocumentIds ?? []);
            }

        });

        EventBus.listen('notes:expand_all', () => {
            this.openPanels = Array.from(Array(this.data.length).keys());
        });

        EventBus.listen('notes:collapse_all', () => {
            this.openPanels = [];
        });

    }
}
</script>

<style scoped>
.w-full {
    width: 100%;
}
</style>
