<template>
    <div>
        <mega-menu
            :concurrent="concurrent">
        </mega-menu>

        <app-system-bar></app-system-bar>

        <navigation
            ref="appNavigation"
            v-if="!isMobileAndTablet"
            :concurrent="concurrent">
        </navigation>
        <navigation-mobile
            ref="appNavigation"
            :concurrent="concurrent"
            v-else>
        </navigation-mobile>
    </div>
</template>

<script>
    import MobileMixin from './../../inc/mixins/mobile';

    import MegaMenu from '../../mega-menu/MegaMenu';
    import Navigation from './Main';
    import NavigationMobile from './MainMobile';
    import AppSystemBar from "../../AppSystemBar";

    export default {
        mixins: [MobileMixin],
        components: {
            AppSystemBar,
            MegaMenu,
            Navigation,
            NavigationMobile
        },
        props: {
            concurrent: {
                required: false,
                type: String
            }
        }
    }
</script>
