var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showMessage
        ? _c(
            "p",
            {
              staticClass: "text-center black--text emailVerificationSent py-3",
            },
            [
              _c("v-icon", { attrs: { color: "black" } }, [
                _vm._v("mdi-check-circle-outline"),
              ]),
              _vm._v(
                " A verification email has been sent to your inbox.\n    "
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "h1",
                          { staticClass: "primary--text font-weight-regular" },
                          [
                            _vm._v(
                              "\n                            Verify your email\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "h3",
                          { staticClass: "primary--text font-weight-regular" },
                          [
                            _vm._v(
                              "\n                            One more step to complete\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                            Please, check your email for the "
                          ),
                          _c("b", [_vm._v('"ICC Verify your email"')]),
                          _vm._v(" letter.\n                        "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("\n                            If you "),
                          _c("b", [_vm._v("don't see our email")]),
                          _vm._v(
                            " in your Primary email folder, \n                            please check all your folders (Promotions, Spam, etc.) \n                            or use the button below.\n                        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.showMessage,
                                block: !_vm.isMobileAndTablet,
                                loading: _vm.showLoader,
                                large: "",
                                color: "primary",
                              },
                              on: { click: _vm.resendEmail },
                            },
                            [
                              _vm._v(
                                "\n                            Resend Verification Email\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isMobileAndTablet
                ? _c("v-col", { attrs: { cols: "6" } }, [
                    _c("img", {
                      attrs: {
                        "max-width": "100%",
                        src: _vm.asset(
                          require("../../images/illistrations/key_holder.webp")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }