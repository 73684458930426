<template>
    <v-btn
        large
        :outlined="outlined"
        :color="color"
        :href="getRoute('premium_features')"
        :block="block">
        Learn More
    </v-btn>
</template>

<script>
    export default {
        name: 'PremiumLearnMore',
        props: {
            outlined: {
                required: false,
                type: Boolean
            },
            block: {
                required: false,
                type: Boolean,
                default: false
            },
            color: {
                required: false,
                type: String,
                default: 'primary'
            }
        }
    }
</script>
