var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container" },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("img", {
              staticStyle: { width: "180px", "margin-bottom": "1em" },
              attrs: {
                src: _vm.asset(require("../../images/premium_large.png")),
                alt: "Digital Codes Premium",
              },
            }),
            _vm._v(" "),
            _c("h1", { staticClass: "primary--text oxygen mb-4" }, [
              _vm._v("Start your Free 14-Day Trial"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "primary--text mb-0" }, [
              _vm._v("\n                Already have an account? "),
              _c(
                "a",
                {
                  staticClass: "font-weight-bold",
                  attrs: { href: _vm.getRoute("security_login") },
                },
                [_vm._v("Sign In")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "7" } },
            [
              _c("div", {}, [
                _c("div", { staticClass: "primary--text title oxygen" }, [
                  _vm._v(
                    "\n                    The ICC sign in credentials also grant you access to the\n                    following products and services:\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex px-2 py-5 primary--text",
                    attrs: { id: "product_list" },
                  },
                  [
                    _c("ul", [
                      _c("li", [_vm._v("myICC")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("ICC Shop")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("cdpACCESS")]),
                    ]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", [_vm._v("ICC Safe")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Learning Center")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Assessment Center")]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("registration-form", {
                attrs: { "is-trial-registration": true },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { lg: "5" } },
            [
              _c("up-sell-vertical", {
                attrs: {
                  image: _vm.upsellImage,
                  heading:
                    "Experience how ICC Digital Codes <b>Premium Complete</b> can help you:",
                  options: { color: "grey lighten-3" },
                },
                scopedSlots: _vm._u([
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _c("ul", [
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Search over " +
                                _vm._s(_vm.titleCount) +
                                " of the latest codes and referenced standards"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Instantly identify compliance requirements using Advanced Search"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Understand code requirement updates with Significant Changes, Revision History and\n                            Errata\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Download and enjoy offline content using the premium mobile app"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Build code expertise with Commentaries, Significant Changes and Code Essentials"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Capture project specific requirements with inline Notes and Annotations"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "pa-1" }, [
                            _vm._v(
                              "Collaborate and share files, notes and bookmarks effortlessly"
                            ),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }