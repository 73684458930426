var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("p", { staticClass: "primary--text caption mb-0" }, [
              _vm._v(
                "\n                Concurrent Use licenses allow the individual who purchases the premium subscription to make access available to anyone who knows the concurrent code. Concurrent access is limited by the number of simultaneous users who are able to access the premium content and features at the same time. Concurrent codes, associated books and quantity are subject to change at the discretion of the individual who purchased the premium licenses.\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary licenses-step-5_1",
                  attrs: { block: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openConcurrencyModal()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                Generate Concurrent Code\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "" } },
        [
          _c(
            "v-data-iterator",
            {
              staticClass: "elevation-0 no-margin parent-row",
              attrs: {
                items: _vm.concurrent_codes,
                "items-per-page": _vm.pagination.rowsPerPage,
                loading: _vm.loading,
                "single-expand": true,
                "footer-props": {
                  itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
                },
                "server-items-length": _vm.pagination.totalItems,
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (data) {
                    return [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(data.item.access_code) +
                                        "\n                            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "grey lighten-4" },
                            [
                              _c("v-data-table", {
                                staticClass: "no-margin details-row",
                                attrs: {
                                  headers: _vm.license,
                                  items: data.item.assigned_subscriptions,
                                  "hide-default-footer": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "tr",
                                            { staticClass: "green lighten-5" },
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c("graphic", {
                                                            attrs: {
                                                              title:
                                                                item
                                                                  .subscription
                                                                  .document_info,
                                                              thumbnail: "",
                                                              width: "40px",
                                                              additionalClasses:
                                                                "mr-2",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.bookLink(
                                                                  item
                                                                    .subscription
                                                                    .document_info
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("book-title", {
                                                              attrs: {
                                                                title:
                                                                  _vm.getBookTitle(
                                                                    item
                                                                      .subscription
                                                                      .document_info
                                                                  ),
                                                                print_version_edition:
                                                                  _vm.printEdition(
                                                                    item
                                                                      .subscription
                                                                      .document_info
                                                                      .print_version_edition
                                                                      .version
                                                                  ),
                                                                printing_text:
                                                                  item
                                                                    .subscription
                                                                    .document_info
                                                                    .print_info,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Configured Licences"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              item.licenses
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [_vm._v("Created")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                item.created_at,
                                                                "MMMM Do, YYYY"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Purchased Date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                item
                                                                  .subscription
                                                                  .purchase_date,
                                                                "MMMM Do, YYYY"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Expiration Date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                item
                                                                  .subscription
                                                                  .expiration_date,
                                                                "MMMM Do, YYYY"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Subscription Type"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.checkSubscriptionType(
                                                                item
                                                                  .subscription
                                                                  .subscription_type
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Subscription Status"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.checkStatus(
                                                                item.subscription
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getCodeAccessLogs(
                                                                      item.id,
                                                                      item.access_code
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    Usage Report\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goToTheShop(
                                                                      _vm.shopEncodesUrl
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              item.subscription
                                                                .subscription_type ===
                                                              "M"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Manage"
                                                                    ),
                                                                  ])
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      "Renew"
                                                                    ),
                                                                  ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openConcurrencyModal(
                                                                      _vm.parent
                                                                        .item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    Edit\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "error",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeCodeAlertModal(
                                                                      _vm.parent
                                                                        .item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    Remove\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "template",
                { slot: "no-data" },
                [
                  !_vm.concurrent_codes_loaded
                    ? _c(
                        "v-card",
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: true },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.concurrent_codes_loaded
                    ? _c("p", { staticClass: "mt-4" }, [
                        _vm._v("You do not have any Premium subscriptions."),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ConcurrencyModal", {
        key: _vm.componentKey,
        attrs: { "user-id": _vm.userId },
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.removeCodeModal,
            callback: function ($$v) {
              _vm.removeCodeModal = $$v
            },
            expression: "removeCodeModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "\n                Remove Concurrent code!\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "\n                    All subscriptions currently configured under the concurrent code will be moved over to\n                    Personal Use and become available for assigning to another user or configuring within\n                    a different concurrent code.\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.removeCodeModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeCode()
                        },
                      },
                    },
                    [_vm._v("Remove")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }