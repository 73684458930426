import {ExpirableStorage} from "../classes/ExpirableStorage";

/**
 * @typedef {Object|null} ImpersonatingUser
 * @property {number} id
 * @property {string} name
 * @property {string} email
 */

export const UtilsModule = {
    state: {
        cdn : '',
        logged_in: false,
        is_admin: false,
        /** @type {User} */
        user: null,
        userId: '',
        /** @type {ImpersonatingUser} */
        switchUser: ExpirableStorage.get('switch_user'),
        switchUserReturnUrl: ExpirableStorage.get('switch_user_return_url'),
        gaEnabled: true,
        gtmEnabled: false,
        footerVisible: true,
        mobileActionsVisible: true,
        hideGlobalBanner: ExpirableStorage.has('hide_global_banner') ?
            ExpirableStorage.get('hide_global_banner') : null,
        concurrentTimeoutWarningDialogVisible: false,
        isUserLoggingOut: false,
    },
    getters: {
        getCDNUrl : (state) => state.cdn,
        getLoggedIn : (state) => state.logged_in,
        isAdmin: (state) => state.is_admin,
        /** @return {User} */
        getUser: (state) => state.user,
        getUserId: (state) => state.userId,
        /** @return {ImpersonatingUser} */
        getSwitchUser: (state) => state.switchUser,
        getSwitchUserReturnUrl: (state) => state.switchUserReturnUrl,
        isGaEnabled: (state) => state.gaEnabled,
        isGtmEnabled: (state) => state.gtmEnabled,
        isFooterVisible: (state) => state.footerVisible,
        isMobileActionsVisible: (state) => state.mobileActionsVisible,
        isGlobalBannerHidden: (state) => state.hideGlobalBanner,
        isConcurrentTimeoutWarningDialogVisible: (state) => state.concurrentTimeoutWarningDialogVisible,
        isUserLoggingOut: (state) => state.isUserLoggingOut,
    },
    mutations: {
        setGlobalBannerHidden (state, data) {
            ExpirableStorage.set('hide_global_banner', data);
            state.hideGlobalBanner = data;
        },
        setCDNUrl (state, url) {
            state.cdn = url;
        },
        setLoggedIn (state, status) {
            state.logged_in = status;
        },
        setIsAdmin (state, status) {
            state.is_admin = status;
        },
        setUser(state, user) {
            state.user = user;
        },
        setUserId (state, status) {
            state.userId = status;
        },
        setSwitchUser(state, value) {
            state.switchUser = value;
            ExpirableStorage.set('switch_user', value);
        },
        leaveSwitchUser(state) {
            state.switchUser = null;
            ExpirableStorage.remove('switch_user');
        },
        setSwitchUserReturnUrl(state, value) {
            state.switchUserReturnUrl = value;
            ExpirableStorage.set('switch_user_return_url', value);
        },
        setGaEnabled (state, status) {
            state.gaEnabled = status;
        },
        setGtmEnabled (state, status) {
            state.gtmEnabled = status;
        },
        setFooterVisible(state, value) {
            state.footerVisible = !!value;
        },
        setMobileActionsVisible(state, value) {
            state.mobileActionsVisible = !!value;
        },
        setConcurrentTimeoutWarningDialogVisible(state, value) {
            state.concurrentTimeoutWarningDialogVisible = !!value;
        },
        setIsUserLoggingOut(state, value){
            state.isUserLoggingOut = value;
        }
    }
}
