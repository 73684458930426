var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-iterator", {
    attrs: {
      items: _vm.titles,
      "item-key": "document_id",
      "items-per-page": _vm.itemsPerPage,
      "hide-default-footer": "",
      search: _vm.search,
      loading: _vm.loading,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "no-data",
          fn: function () {
            return [
              _vm._t("no-data", function () {
                return [
                  _vm.displayTitleSearchNoResultMsg
                    ? [_c("no-titles-result")]
                    : _c("p", [
                        _vm._v(
                          "\n                No results found\n            "
                        ),
                      ]),
                ]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "default",
          fn: function ({ items }) {
            return [
              _c(
                "v-row",
                _vm._l(items, function (item, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      attrs: {
                        cols: _vm.displayInCols ? _vm.displayInCols : "12",
                        lg: "6",
                      },
                    },
                    [
                      _c("title-card", {
                        attrs: {
                          "document-info": item,
                          "display-border": _vm.displayBorder,
                          "border-color": _vm.borderColor,
                          "right-arrow-color": _vm.rightArrowColor,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }