var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticStyle: { "font-size": "14px" } }, [
      _c(
        "div",
        { staticClass: "d-flex align-start mb-2" },
        [
          _c(
            "v-icon",
            { staticClass: "primary--text pr-2", attrs: { small: "" } },
            [_vm._v("mdi-lightbulb-outline")]
          ),
          _vm._v(
            "\n            Questions should be 500 characters or less. Shorter questions are best.\n        "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-start mb-2" },
        [
          _c(
            "v-icon",
            { staticClass: "primary--text pr-2", attrs: { small: "" } },
            [_vm._v("mdi-lightbulb-outline")]
          ),
          _vm._v(
            '\n\n            You do not have to specify a codebook, but if you are sure of the codebook you want\n            an answer from, say "In the IBC" or something similar.\n        '
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-start mb-2" },
        [
          _c(
            "v-icon",
            { staticClass: "primary--text pr-2", attrs: { small: "" } },
            [_vm._v("mdi-lightbulb-outline")]
          ),
          _vm._v(
            "\n            Ask AI Navigator just one question per submission. Multi-part questions get better\n            answers when asked as multiple questions.\n        "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-0" }, [
      _c(
        "a",
        {
          staticClass: "font-weight-bold",
          attrs: { href: "/features/ai-navigator", target: "_blank" },
        },
        [_vm._v("\n            Learn more\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }