<template>
    <v-card
        outlined
        color="white"
        class="rounded-lg light-border-color"
        :class="{ 'scroll': titles.length > itemsPerPage }"
        target="_blank"
        height="398px">
        <v-card-text class="pa-2">            
            <v-row align="center"
                class="included-title-card rounded-lg ml-2 mt-2"
                v-for="(item, key) in titles"
                :key="key"
                tabindex="0" 
                @click="clickAction(item)"
                @keyup.enter="clickAction(item)"
                @keyup.space.prevent="clickAction(item)">
                <v-col
                    cols="auto"
                    class="py-0">
                    <graphic
                        :title="item"
                        thumbnail
                        width="50px"
                        additionalStyles="max-width: 50px;">
                    </graphic>
                </v-col>
                <v-col>
                    <p
                        class="mb-0 font-weight-bold"
                        v-html="item.display_title">
                    </p>
                </v-col>
            </v-row>                
        </v-card-text>
    </v-card>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import Graphic from "../../titles/cover/Graphic";

    export default {
        data() {
            return {
                itemsPerPage: 5
            }
        },
        props: {
            titles: {
                required: true,
                type: Array
            },
            loading: {
                required: true,
                type: Boolean
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            Graphic
        },
        methods: {
            clickAction(clickAction) {
                window.open(clickAction.link, '_blank');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .included-title-card:hover {
        opacity: .7;
    }
    .scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }
</style>
