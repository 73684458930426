<template>
    <span v-if="tagtype === 'es'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="es">
            </tag-label>
        </a>
    </span>
    <span v-else-if="tagtype === 'errata'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="errata">
            </tag-label>
        </a>
    </span>
    <span v-else-if="tagtype === 'premiumcontent'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="premium">
            </tag-label>
        </a>
    </span>
    <span v-else-if="tagtype === 'srcc'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="srcc">
            </tag-label>
        </a>
    </span>
    <span v-else-if="tagtype === 'video'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="video">
            </tag-label>
        </a>
    </span>
    <span v-else-if="tagtype === 'cdp'" class="pr-1">
        <a
            class="tag-label"
            :class="[showOnPremium,showOnPublic]"
            @click="fireModal()">
            <tag-label tag="cdp">
            </tag-label>
        </a>
    </span>
</template>

<script>
    import TagLabel from './../../tags/partials/tag_label';

    export default {
        props: [
            'tagTypeShow',
            'tagtype',
            'contentid',
            'show_on_public',
            'show_on_premium'
        ],
        data() {
            return {
                mouseOn: false
            }
        },
        components: {
            TagLabel
        },
        watch: {
            mouseOn: function (val) {
                if (val) {
                    this.fireMouseEvent();
                } else {
                    this.debounceModal.cancel();
                }
            }
        },
        computed: {
            showOnPremium: function () {
                return {
                    premiumTags: this.show_on_premium,
                    nonPremiumTags: !this.show_on_premium
                }
            },
            showOnPublic: function () {
                return {
                    publicTags: this.show_on_pubic,
                    nonPublicTags: !this.show_on_public
                }
            }
        },
        methods: {
            fireModal() {
                if (this.tagtype === 'cdp') {
                    EventBus.fire('showCDPModal', {'contentId': this.contentid, 'reportTypeShow': this.tagTypeShow});
                } else {
                    if (this.isNewPremiumTagCategoriesEnabled() && 'premiumcontent' === this.tagtype) {
                        this.displayTagCategories();
                    } else {
                        EventBus.fire('show-bonus-modal', {'contentid': this.contentid, 'report_type': this.tagtype, 'report_type_show': this.tagTypeShow});
                    }
                }
            },
            premiumClicked() {
                this.fireModal();
            },
            displayTagCategories(){
                let tagElement = document.getElementById('tag-category-' + this.contentid);
                if (tagElement) { //display element if already in DOM
                    EventBus.fire('premium-insights-section-expand-' + this.contentid);
                    const navigationBarHeight = document.getElementById('navigation-app')?.getBoundingClientRect().height;
                    const stickyChapterInfoHeight = document.getElementById('sticky-chapter-info').getBoundingClientRect().height;
                    const systemBarHeight = document.getElementById('app-system-bar')?.getBoundingClientRect().height;
                    const totalOffsetHeight = stickyChapterInfoHeight + (navigationBarHeight || 0) + (systemBarHeight || 0) + 5;

                    this.$scrollTo(tagElement, 500, {offset: totalOffsetHeight * -1});
                }
            },
            fireMouseEvent() {
                this.debounceModal();
            }
        },
        created() {
            this.debounceModal = _.debounce(this.fireModal, 750);
        }
    }
</script>

<style lang="scss">
    .chapter_styling {
        .tag-label {
            display: inline-block;
            text-decoration: none!important;
            .v-chip__content {
                color: #FFFFFF!important;
            }
        }
    }
</style>
