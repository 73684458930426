<template>
    <iframe
        ref="pdfViewer"
        v-on:load="iframeLoaded"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        name="pdfViewer"
        id="pdfViewer"
        :src="url"></iframe>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                required: true
            },
            pdf: {
                type: Object,
                required: true
            },
            contentProtection: {
                type: String,
                required: true
            }
        },
        methods: {
            iframeLoaded() {
                document.getElementById("base-template").style.overflowY = "hidden";
                let iframe = window.frames['pdfViewer'].contentDocument != undefined ? window.frames['pdfViewer'].contentDocument : window.frames['pdfViewer'].document;
                let script = document.createElement('style');
                script.innerHTML = '#documentName{display:none;}';
                if (!this.pdf.cp_public) {
                    script.innerHTML = script.innerHTML + 'body{-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}';
                }
                if (_.toNumber(this.contentProtection)) {
                    script.innerHTML = script.innerHTML + '#print{display:none;}';
                    script.innerHTML = script.innerHTML + '#download{display:none;}';
                }
                iframe.getElementsByTagName('head')[0].appendChild(script);
            }
        },
        mounted() {

            setTimeout(() => {
                this.updateFrameHeight(this.$refs.pdfViewer);
            }, 2000);

            window.addEventListener("resize", () => {
                this.updateFrameHeight(this.$refs.pdfViewer);
            });
        }
    }
</script>
<style lang="scss">
    #pdfViewer {
        width: 100%;
        height: calc(100vh - 160px);
        border: 0;
    }
</style>
