<template>
    <v-card class="note-list mt-1" v-if="hasBookmarksOrAnnotations" outlined flat>
        <v-expansion-panels v-model="panel" class="notes-expansion-panel-new">
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-chevron-down">
                    <div class="font-weight-bold">My Notes</div>
                </v-expansion-panel-header>
                <v-divider>
                </v-divider>
                <v-expansion-panel-content class="pa-0">
                    <template
                        v-for="item in bookmarks">
                        <v-card
                            :id="'bookmark-list-item-'+item.id"
                            class="note-list-item"
                            flat>
                            <v-card-text class="pa-0">
                                <note-list-item
                                    type="bookmark"
                                    :data="item">
                                </note-list-item>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template
                        v-for="item in annotations">
                        <v-card
                            :id="'annotation-list-item-'+item.id"
                            class="note-list-item"
                            flat>
                            <v-card-text class="pa-0">
                                <note-list-item
                                    type="note"
                                    :data="item">
                                </note-list-item>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import NoteListItem from './noteListItem';

    export default {
        props: {
            data: {
                required: false,
                type: Object
            }
        },
        components: {
            NoteListItem
        },
        data() {
            return {
                panel: 0
            }
        },
        computed: {
            tags() {
                return this.$store.getters.getTags;
            },
            bookmarks() {
                return (this.data && 'bookmarks' in this.data) ? this.data.bookmarks : [];
            },
            annotations() {
                return (this.data && 'annotations' in this.data) ? this.data.annotations : [];
            },
            hasBookmarksOrAnnotations() {
                return this.bookmarks.length || this.annotations.length;
            }
        }
    }
</script>

<style lang="scss">
    .note-list{
        .v-expansion-panel__header {
            background: #EEEEEE;
        }
        .v-expansion-panel__header__icon i {
            color: rgba(0,0,0,.87)!important;
        }
        .icons .v-icon:hover {
            color: #0B5940;
        }
    }
    .notes-expansion-panel-new {
        .v-expansion-panel::before {
            box-shadow: unset!important;
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
    }
</style>
