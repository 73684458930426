export const UserDefaultPreferences = {
    libraryPage: {
        favoriteSection: {
            viewMode: 'list',
        },
        titlesRegion: {
            activeSecondaryFilterTab: 1,
            mobileFilterCollapse: true,
            viewMode: 'list',
            titlesAcrossValue: null
        }
    }
}
