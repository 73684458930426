var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-sharing" },
    [
      _c("breadcrumb", { attrs: { title: "Sharing History" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { class: { "pr-3": _vm.isMobilePort }, attrs: { id: "contentParent" } },
        [
          _vm.concurrent == 1
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "py-6 my-12",
                          attrs: {
                            value: true,
                            type: "info",
                            color: "primary",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.info_message) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { xl: "10", "offset-xl": "1" } }, [
                _c("p", { staticClass: "mt-3" }, [
                  _vm._v(
                    "\n                    This page is for managing notes you have previously shared with another user or had another\n                    user share with you. To share new notes with another premium user, please visit the "
                  ),
                  _c("a", { on: { click: _vm.goToNotesPage } }, [
                    _vm._v("My Notes"),
                  ]),
                  _vm._v(" page.\n                "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xl: "10", "offset-xl": "1" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "fixed-tabs": "",
                            "hide-slider": "",
                            "show-arrows": "",
                            "background-color": "primary",
                            dark: "",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              ref: "sharedByMeTab",
                              staticClass: "text-capitalize tab-class",
                              attrs: { disabled: _vm.concurrent == 1 },
                              on: {
                                click: function ($event) {
                                  _vm.panel = "SharedByMe"
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Shared By Me\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              staticClass: "text-capitalize tab-class",
                              attrs: { disabled: _vm.concurrent == 1 },
                              on: {
                                click: function ($event) {
                                  _vm.panel = "SharedWithMe"
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Shared With Me\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              ref: "sharedWithMeHistoryTab",
                              staticClass: "text-capitalize tab-class",
                              attrs: { disabled: _vm.concurrent == 1 },
                              on: {
                                click: function ($event) {
                                  _vm.panel = "SharedHistory"
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Shared With Me History\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "keep-alive",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                { class: { "pa-0": _vm.isMobilePort } },
                                [
                                  _vm.getPanel === "SharedByMe"
                                    ? [_c(_vm.getPanel, { tag: "component" })]
                                    : _vm.getPanel === "SharedWithMe"
                                    ? [_c(_vm.getPanel, { tag: "component" })]
                                    : _vm.getPanel === "SharedHistory"
                                    ? [_c(_vm.getPanel, { tag: "component" })]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }