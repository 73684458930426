<template>
    <v-row class="exam-timeline-container"
        :class="{
                'pt-12' : !isMobilePort,
                'pa-1' : isMobilePort
            }">
        <v-col 
            :cols="isMobilePort ? 12 : 6"
            :style="!isMobilePort ? 'position: relative;' : ''"
            >
            <img
                :src="asset(require('../../../images/exam_timeline.webp'), 'default')"/>
            <div class="get-started-button" v-if="!isMobilePort">
                <v-btn
                    rounded
                    class="mt-4 white--text"
                    dark
                    x-large
                    color="accent2"
                    target="_blank"
                    :href="link">
                    Get Started
                </v-btn>
            </div>
        </v-col>
        <v-col
            v-if="3 === persona || 4 === persona"
            :cols="isMobilePort ? 12 : 6">
            <h1 class="pb-5 pl-3 roboto">
                Schedule and Access your exam with these easy steps
            </h1>
            <v-timeline
                dense
                class="pt-0"
                >
                <v-timeline-item
                    v-for="step in steps"
                    :key="step.step"
                    color="accent2"
                    class="pb-3"
                    small>
                    <h6 class="pb-1 oxygen">{{step.step}}</h6>
                    <p class="subtitle-1" v-html="step.description"></p>
                    <p class="caption" v-html="step.subDescription"></p>
                </v-timeline-item>
            </v-timeline>
            <div class="text-center pt-8" v-if="isMobilePort">
                <v-btn
                    rounded
                    class="mt-4 white--text"
                    dark
                    x-large
                    color="accent2"
                    target="_blank"
                    :href="link">
                    Get Started
                </v-btn>
            </div>
        </v-col>
        <v-col
            v-if="2 === persona"
            :cols="isMobilePort ? 12 : 6">
            <h1 class="pb-5 pl-3 roboto" align="center">
                Checkout the Exams
            </h1>
            <p class="pb-5 pl-3 roboto" align="center">Easily find the exam you need in the exam catalog.</p>
            <p align="center">
                <v-btn
                    rounded
                    class="mt-4 white--text"
                    dark
                    x-large
                    color="accent2"
                    target="_blank"
                    :href="persona2Link">
                    Purchase Your Exam Today
                </v-btn>
            </p>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import {AppParameters} from "../../../AppParameters";
    import Constants from "../../../constants";

    export default {
        data() {
            return {
                persona2Link: Constants.links.iccSafeExamCatalog,
                link: '',
                steps: [
                   {
                       step: 'STEP 1',
                       description: '<a target="_self" href="#exam_resources">Preview the exam experience</a> with the reference titles relevant to your exam.  For any titles not available in digital format, obtain a copy for exam day.',
                       subDescription: ''
                   },
                   {
                       step: 'STEP 2',
                       description: 'Find the exam you want to take within your purchased exams table and click <b>Take Exam</b>.',
                       subDescription: ''
                   },
                   {
                       step: 'STEP 3',
                       description: 'Find your exam and click <b>Book</b>. Review and Agree to <b>Code of Ethics</b>.',
                       subDescription: ''
                   },
                   {
                       step: 'STEP 4',
                       description: 'Choose the <b>Online Proctoring</b> tab and select a timezone.',
                       subDescription: ''
                   },
                   {
                       step: 'STEP 5',
                       description: 'Select today’s date on the calendar and select your time.',
                       subDescription: 'ProctorU schedules exams in 15-minute intervals. If a proctor is available within the current 15 minutes, you will be able to book into that time slot.'
                   },
                   {
                       step: 'STEP 6',
                       description: 'Click <b>Submit Booking</b> to receive the time your exam will be ready.',
                       subDescription: ''
                   },
                   {
                       step: 'STEP 7',
                       description: 'Click back to the launch page and then click <b>Details</b>.',
                       subDescription: ''  
                   },
                   {
                       step: 'STEP 8',
                       description: 'Once your exam is available, click <b>Launch Exam</b> to connect to the proctor and start your test.',
                       subDescription: ''
                   }
               ] 
            }
        },
        props: {
            persona: {
                required: true,
                type: Number
            }
        },
        mixins: [
            Mobile,
            Constants
        ],
        mounted(){
            if (3 === this.persona) {
                this.link = `${AppParameters.my_icc_url}/login`;
            } else {
                this.link = `${AppParameters.my_icc_url}/login?redirect=%2Fresources%2Fmember-resources`;
            }
        }
    }
</script>

<style
    lang="scss"
    scoped>
    .exam-timeline-container {
        img {
            max-width: 100%;
            filter: drop-shadow(0px 2.94444px 16.1944px rgba(0, 0, 0, 0.22));
            border-radius: 11.7778px;
        }
        h1 {
            line-height: 42px;
        }
        .get-started-button{
            position: absolute;
            top: 13%;
            left: 38%;
        }
        .v-timeline::before {
            top: 40px;
            height: calc(100% - 100px)
        }
        @media (min-width: 700px) and (max-width: 1000px){
            .get-started-button{
                position: absolute;
                top: 6%;
                left: 30%;
            }
        }
    }
</style>
