export default {
    bind(el, binding, vnode) {
        el.addEventListener('click', () => {
            //  Check that click was inside the element
            if ((el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value.method
                vnode.context[binding.value.method](binding.value.value);
            }
        })
    }
}
