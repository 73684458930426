import Vue from 'vue';
import VueSession from 'vue-session';

Vue.use(VueSession, {persist: true});

import Routing from '../../../public/bundles/fosjsrouting/js/router.min.js';
import EventBus from '../components/inc/events';
const routes = require('../../js/fos_js_routes.json');
Routing.setRoutingData(routes);

export const SearchModule = {
    state: {
        defaultSearch: Vue.prototype.$session.has('defaultSearchTerm') ?
            Vue.prototype.$session.get('defaultSearchTerm') : '',
        searchTitles: [],
        searchYears: [],
        searchCategories: [],
        searchTypes: [],
        searchSource: [],
        filterPanel: 0,
        userTitles: [],
        isCodesPage: false,
        scrollTarget: null,
        tabbedSearchType: null,
        tabbedInit: [],
        tabbedSearchValues: {},
        titleGroups: [
            {
                title: 'My Premium Titles',
                key: 'mine'
            },
            {
                title: 'Recently Added',
                key: 'recently_added'
            },
            {
                title: 'Recently Viewed',
                key: 'recently_viewed'
            },
        ],
        tabbedGroups: {},
        tabbedCategories: {},
        tabbedYears: {},
        tabbedTitleIds: {},
        tabbedTitles: [],
        tabbedSearchUrl: {},
        searchTitlesUrl: '',

        // Legacy
        titleSearchCategory: 'all-titles',
        contentSearchContentSources: Vue.prototype.$session.has('contentSearchContentSources') ?
            Vue.prototype.$session.get('contentSearchContentSources') : [],
        page: 0,
        search_page_url : Routing.generate('search_page'),
        limit: 20,
        searchId: null
    },
    getters: {
        getDefaultSearch : (state) => state.defaultSearch,
        getSearchTitles : (state) => state.searchTitles,
        getSearchYears : (state) => state.searchYears,
        getSearchCategories : (state) => state.searchCategories,
        getSearchTypes : (state) => state.searchTypes,
        getSearchSource : (state) => state.searchSource,
        getFilterPanel: (state) => state.filterPanel,
        getUserTitles: (state) => state.userTitles,
        getIsCodesPage : (state) => state.isCodesPage,
        getScrollTarget : (state) => state.scrollTarget,
        getTabbedSearchType: (state) => state.tabbedSearchType,
        getTabbedSearchValues: (state) => state.tabbedSearchValues,
        getTitleGroups: (state) => state.titleGroups,
        getTabbedGroups: (state) => state.tabbedGroups,
        getTabbedCategories: (state) => state.tabbedCategories,
        getTabbedYears: (state) => state.tabbedYears,
        getTabbedTitleIds: (state) => state.tabbedTitleIds,
        getTabbedTitles: (state) => state.tabbedTitles,
        getTabbedSearchUrl: (state) => state.tabbedSearchUrl,

        // Legacy
        getTitleSearchCategory: (state) => state.titleSearchCategory,
        getPage: (state) => state.page,
        getSearchPageUrl: (state) => state.search_page_url,
        getLimit: (state) => state.limit,
        getSearchId: (state) => state.searchId
    },
    mutations: {
        forgetDefaultSearch(state, value) {
            Vue.prototype.$session.remove('defaultSearchTerm');
        },
        setDefaultSearch (state, value) {
            state.defaultSearch = value;
        },
        setSearchTitles (state, titles) {
            state.searchTitles = titles;
        },
        setSearchYears (state, years) {
            state.searchYears = years;
        },
        setSearchCategories (state, categories) {
            state.searchCategories = categories;
        },
        setSearchTypes (state, data) {
            state.searchTypes = data;
        },
        setSearchSource (state, data) {
            state.searchSource = data;
        },
        setFilterPanel (state, data) {
            state.filterPanel = data;
        },
        setUserTitles (state, data) {
            state.userTitles = data;
        },
        setIsCodesPage (state, value) {
            state.isCodesPage = value;
        },
        setScrollTarget (state, value) {
            state.scrollTarget = value;
        },
        setTabbedSearchType (state, value) {
            const {tab, eventType} = value;
            if (tab && eventType) {
                state.tabbedSearchType = tab;
                if (!state.tabbedInit.includes(tab)) {
                    EventBus.fire(eventType);
                    state.tabbedInit.push(tab);
                }
            }
        },
        setTabbedSearchValues (state, value) {
            const {tab, searchValue} = value;
            if (tab && searchValue) {
                Vue.set(state.tabbedSearchValues, tab, searchValue);
            }
        },
        setTabbedGroups (state, value) {
            const {tab, group} = value;
            if (tab && group) {
                Vue.set(state.tabbedGroups, tab, group);
            }
        },
        removeTabbedGroup (state, value) {
            const {tab, group, removeAll = false} = value;
            if (tab) {
                if (removeAll) {
                    state.tabbedGroups[tab] = [];
                } else {
                    state.tabbedGroups[tab] = state.tabbedGroups[tab].filter(item => item !== group);
                }
            }
        },
        setTabbedCategories (state, value) {
            const {tab, categories} = value;
            if (tab && categories) {
                Vue.set(state.tabbedCategories, tab, categories);
            }
        },
        removeTabbedCategory (state, value) {
            const {tab, category, removeAll = false} = value;
            if (tab) {
                if (removeAll) {
                    state.tabbedCategories[tab] = [];
                } else {
                    state.tabbedCategories[tab] = state.tabbedCategories[tab].filter(item => item !== category);
                }
            }
        },
        setTabbedYears (state, value) {
            const {tab, years} = value;
            if (tab && years) {
                Vue.set(state.tabbedYears, tab, years);
            }
        },
        setTabbedTitleIds (state, value) {
            const {tab, titles} = value;
            if (tab && titles) {
                Vue.set(state.tabbedTitleIds, tab, titles);
            }
        },
        setTabbedTitles (state, value) {
            const {tab, titles} = value;
            if (tab && titles) {
                Vue.set(state.tabbedTitles, tab, titles);
            }
        },
        removeTabbedYear (state, value) {
            const {tab, year, removeAll = false} = value;
            if (tab) {
                if (removeAll) {
                    state.tabbedYears[tab] = [];
                } else {
                    state.tabbedYears[tab] = state.tabbedYears[tab].filter(item => item !== year);
                }
            }
        },
        setTabbedSearchUrl (state, value) {
            const {
                tab,
                searchUrl,
                updateSearchTerm = false,
                removeSid = false
            } = value;
            if (tab && searchUrl) {
                let updatedSearchUrl = searchUrl;
                let params = new URLSearchParams(searchUrl);

                // If search term has changed between tabs we need to update the URL.
                if (updateSearchTerm) {
                    params.set('searchTermAny', state.defaultSearch);
                }
                // If content search then we need to remove the sid parameter since it's considered a new search.
                if (removeSid) {
                    params.delete('sid');
                }
                updatedSearchUrl = params.toString();
                Vue.set(state.tabbedSearchUrl, tab, updatedSearchUrl);
            }
        },

        // Legacy
        setTitleSearchCategory (state, category) {
            Vue.prototype.$session.set('titleSearchCategory', category);
            state.titleSearchCategory = category;
        },
        setPage (state, page) {
            state.page = page;
        },
        setLimit (state, value) {
            state.limit = value;
        },
        setSearchId(state, value) {
            state.searchId = value;
        }
    }
};
