<template>
    <v-row :justify="center ? 'center' : null">
        <v-col
            cols="auto"
            v-for="(item, key) in this[type]"
            :key="key">
            <h3
                :class="isMobilePort && 'caption'"
                class="font-weight-bold">
                <v-icon
                    color="accent2"
                    class="mb-1">
                    {{item.icon}}
                </v-icon>
                {{item.title}}
            </h3>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    export default {
        name: 'InlineIcons',
        data() {
            return {
                askSearchSearch: [
                    {
                        icon: 'mdi-help',
                        title: 'Ask'
                    },
                    {
                        icon: 'mdi-magnify',
                        title: 'Search'
                    },
                    {
                        icon: 'mdi-share-variant-outline',
                        title: 'Share'
                    }
                ],
                calculatorCodeCompliance: [
                    {
                        icon: 'mdi-calculator-variant-outline',
                        title: 'Perform calculations'
                    },
                    {
                        icon: 'mdi-book-open-variant',
                        title: 'Access in codes'
                    },
                    {
                        icon: 'mdi-thumb-up-outline',
                        title: 'Stay in compliance'
                    }
                ]
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            center: {
                required: false,
                type: Boolean,
                default: false
            },
            type: {
                required: true,
                type: String
            }
        }
    }
</script>
