<template>
    <expandable-panel
        v-if="hasBookmarksOrAnnotations"
        :expand="expand"
        :id="`notes-expansion-panel-wrapper-${sectionId}`"
        class="notes-expansion-panel-wrapper"
        outlined>
        <template v-slot:title>
            <div style="display: inline">
                <span class="text--secondary font-weight-bold">
                    NOTES ({{ bookmarks.length + annotations.length }})
                </span>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="openNoteModal"
                        style="float: right; cursor: pointer;"
                        >
                        mdi-bookmark-plus-outline
                        </v-icon>
                    </template>
                    <span class="tooltip-content">Add note, bookmark or highlight</span>
                </v-tooltip>
            </div>
        </template>
        <template v-slot:content>
            <v-card
                :key="item.id"
                v-for="item in bookmarks"
                :id="'bookmark-list-item-' + item.id"
                flat>
                <v-card-text class="pa-0 notes-bookmarks-section">
                    <note-list-item
                        type="bookmark"
                        :data="item"
                        :tags="tags">
                    </note-list-item>
                </v-card-text>
            </v-card>
            <v-card
                v-for="item in annotations"
                :key="item.id"
                flat>
                <v-card-text class="pa-0 notes-bookmarks-section">
                    <note-list-item
                        type="note"
                        :data="item"
                        :tags="tags">
                    </note-list-item>
                </v-card-text>
            </v-card>
        </template>
    </expandable-panel>
</template>

<script>
    import NoteListItem from './noteListItem';
    import ExpandablePanel from "./ExpandablePanel";

    export default {
        data() {
            return {
                notesList: this.data,
                expand: this.expandSection,
            }
        },
        computed: {
            bookmarks() {
                return this.notesList.bookmarks;
            },
            annotations() {
                return this.notesList.annotations;
            },
            hasBookmarksOrAnnotations() {
                return this.bookmarks.length || this.annotations.length;
            }
        },
        props: {
            data: {
                required: true,
                type: Object
            },
            tags: {
                required: true,
                type: Array
            },
            sectionId: {
                required: false,
                type: Number
            },
            expandSection: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            NoteListItem,
            ExpandablePanel
        },
        methods: {
            updateNoteList(data) {
                this.expand = true;
                if (data.annotations?.length) {
                    this.updateList('annotations', data.annotations);
                } else {
                    this.updateList('bookmarks', data.bookmarks);
                }
            },
            updateList(type, newItems) {
                if (!this.notesList[type]) {
                    this.notesList[type] = [];
                }
                newItems.forEach(newItem => {
                    const index = this.notesList[type].findIndex(existing => existing.id === newItem.id);
                    if (index !== -1) {
                        this.notesList[type].splice(index, 1, newItem);
                    } else {
                        this.notesList[type].push(newItem);
                    }
                });
            },
            hideMyNotes: function(data) {
                const { type ,id } = data;
                switch (type) {
                    case 'annotation':
                        this.notesList.annotations = this.notesList.annotations.filter(element => element.id !== id);
                        break;
                    case 'bookmark':
                        this.notesList.bookmarks = this.notesList.bookmarks.filter(element => element.id !== id);
                        break;
                }
            },
            openNoteModal(){
                EventBus.fire('execute-section-action-open-note-modal', {sectionId: this.sectionId});
            }
        },
        created() {
            if (!_.isUndefined(this.sectionId)) {
                EventBus.listen('update-note-list-'+this.sectionId, (item) => this.updateNoteList(item));
                EventBus.listen('remove-notes'+this.sectionId, (data)=> this.hideMyNotes(data));
            }
        },
    }
</script>

<style lang="scss">
    .notes-expansion-panel {
        .v-expansion-panel:before {
            box-shadow: unset!important;
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
        .v-expansion-panel-header {
            padding-left: 0 !important;
            padding-right: 0 !important;
            min-height: 40px;
        }
    }
</style>
