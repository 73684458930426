<template>
    <div class="search-item-breadcrumb">
        <a
                :href="getBookUrl([], getDocumentId())"
                target="_blank"
                @click="clickedFreeBreadcrumbsLink">{{ documentTitle }}</a>

        <template v-if="breadcrumbs">

            <span v-if="isChapter(breadcrumbs)">
               <span color="primary">/</span> <a :href="generateBaseSlug(breadcrumbs)" @click="registerClick(breadcrumbs, getDocumentId())" target="_blank">{{ breadcrumbs.chapter }}</a>
            </span>
            <span v-if="isSection(breadcrumbs)">
                <span color="primary">/</span> <a :href="getSectionUrl(breadcrumbs)" @click="registerClick(breadcrumbs, getDocumentId())" target="_blank">{{ breadcrumbs.section }}</a>
            </span>
            <span v-if="isSubsection(breadcrumbs)">
                <span color="primary">/</span> <a :href="getSubSectionUrl(breadcrumbs, this.meta.contentid)" @click="registerClick(breadcrumbs, getDocumentId())" target="_blank">{{ breadcrumbs.subSection }}</a>
            </span>

        </template>

        <template v-else>
            <v-progress-linear
                v-if="isBusy"
                class="breadcrumb-linear-container"
                color="primary"
                buffer-value="0"
                stream
                reverse
            ></v-progress-linear>
         </template>
    </div>
</template>

<script>

    import Breadcrumbs from '../../mixins/breadcrumbs';
    import Constants from "../../../../constants";

    export default {
        props: ['meta', 'documentId', 'breadcrumbs'],
        mixins: [Breadcrumbs],
        data() {
            return {
                isBusy: false
            };
        },
        computed: {
            documentTitle() {
                if (this.meta) {
                    // Return titles that are defined in the breadcrumbs object.
                    if (this.breadcrumbs['title']) {
                        return this.breadcrumbs['title'];
                    }

                    const displayTitle = this.meta['displaytitle'];
                    const year = this.meta['year'];

                    if (this.meta['doctype'] === Constants.contentTypeIccEsXml) {
                        return displayTitle;
                    }

                    return `${year} ${displayTitle}`;
                }

                return null;
            }
        },
        methods: {
            getDocumentId() {
                if(this.documentId) {
                    return this.documentId;
                } else if(this.meta.documentid) {
                    return this.meta.documentid;
                } else {
                    return 0;
                }
            },
            clickedFreeBreadcrumbsLink() {
                this.gtmEvent('Content Search', 'Result Click', 'Breadcrumb Link');
            }
        }
    }
</script>

<style lang="css">
    .search-item-breadcrumb .v-icon {
         padding-bottom: 2px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .breadcrumb-linear-container {
        max-width: 38px;
        display: inline-block;
        justify-content: left;
    }
</style>
