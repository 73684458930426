var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isChapters
    ? _c(
        "div",
        [
          _vm._l(_vm.chapters, function (chapter, index) {
            return [
              chapter.link || chapter.title
                ? _c(
                    "v-row",
                    {
                      key: "chapter" + index,
                      staticClass: "ma-0 toc-chapter-item",
                      class: {
                        monotonesLightGreen:
                          _vm.isActiveItem(chapter) ||
                          _vm.focusedChapter == chapter?.content_id,
                        disabled: _vm.disableToc,
                      },
                      style: `padding-left: ${_vm.padding}px;`,
                      attrs: {
                        align: "center",
                        id: _vm.setActiveChapterID(chapter),
                      },
                    },
                    [
                      _vm.isXML
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0 px-1",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    role: "button",
                                    "aria-label": `Chapter Item Pointer Icons ${_vm.chaptersTitle(
                                      chapter
                                    )}`,
                                    "aria-expanded": _vm.isExpanded(
                                      chapter.content_id
                                    )
                                      ? "true"
                                      : "false",
                                    tabindex:
                                      _vm.isSubSection(chapter.sub_sections) &&
                                      "COPYRIGHT" !=
                                        _vm.chaptersTitle(chapter) &&
                                      "PREFACE" != _vm.chaptersTitle(chapter)
                                        ? 0
                                        : -1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSubSection(
                                        chapter.content_id
                                      )
                                    },
                                    focus: function ($event) {
                                      return _vm.makeFocus(chapter)
                                    },
                                  },
                                },
                                [
                                  _vm.isSubSection(chapter.sub_sections) &&
                                  "COPYRIGHT" != _vm.chaptersTitle(chapter) &&
                                  "PREFACE" != _vm.chaptersTitle(chapter)
                                    ? _c(
                                        "v-icon",
                                        {
                                          class: _vm.isActiveItem(chapter)
                                            ? "primary--text"
                                            : "grey--text text--darken-4",
                                          attrs: {
                                            id: "chapter-toggle-" + index,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.isExpanded(
                                                  chapter.content_id
                                                )
                                                  ? "mdi-chevron-down"
                                                  : "mdi-chevron-right"
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 cp toc-chapter-item-focused",
                          class: {
                            xs11:
                              _vm.isXML && _vm.$store.getters.getLoggedIn == 1,
                            xs12:
                              !_vm.isXML || _vm.$store.getters.getLoggedIn == 0,
                          },
                          attrs: { tabindex: "0" },
                          on: {
                            focus: function ($event) {
                              return _vm.makeFocus(chapter)
                            },
                            click: function ($event) {
                              return _vm.setActiveSection(chapter)
                            },
                            contextmenu: function ($event) {
                              $event.preventDefault()
                              return _vm.showContextMenu(chapter, $event)
                            },
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.setActiveSection(chapter)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.setActiveSection(chapter)
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              key: index,
                              staticClass: "mb-0 py-2 body-2",
                              class: {
                                "pl-6": !_vm.isXML,
                              },
                            },
                            [
                              _c("a", {
                                staticClass: "fs-14",
                                class: _vm.isActiveItem(chapter)
                                  ? "primary--text"
                                  : "grey--text text--darken-4",
                                attrs: { href: "javascript:void(0);" },
                                domProps: {
                                  innerHTML: _vm._s(_vm.chaptersTitle(chapter)),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isSubSection(chapter.sub_sections) &&
              _vm.isExpanded(chapter.content_id)
                ? [
                    _c("chapters", {
                      key: "subsection" + index,
                      attrs: {
                        chapters_data: _vm.subSections(chapter.sub_sections),
                        sub_section: true,
                        padding: _vm.padding + 16,
                      },
                    }),
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }