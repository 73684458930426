<template>
    <v-container>
        <v-row>
            <v-col>
                <template v-if="content">
                    <div v-html="content"></div>
                </template>
                <template v-else>
                    <v-progress-circular
                        class="my-12"
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                content: ''
            }
        },
        methods: {
            getContent() {
                this.$http.get(Routing.generate('get_webinar_content'))
                    .then(response => {
                        if (response.data) {
                            this.content = response.data;
                        }
                    })
            }
        },
        created() {
            this.getContent();
        }
    }
</script>
