<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                {{title}}
            </v-card-title>
            <v-card-text class="mt-5">
                <v-row align="center">
                    <v-col cols="auto">
                        <v-icon
                            size="60"
                            color="error">
                            mdi-alert-circle-outline
                        </v-icon>
                    </v-col>
                    <v-col>
                        <p
                            class="mb-0"
                            v-html="message">
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider>
            </v-divider>
            <v-card-actions class="grey lighten-4">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeModal()">
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data() {
            return {
                dialog: false,
                title: 'Ooops',
                message: 'Something went wrong'
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            showModal(data) {
                const {title, message} = data
                this.title = !_.isUndefined(title) ? title : this.title
                this.message = !_.isUndefined(message) ? message : this.message
                this.dialog = true;
            },
            closeModal() {
                this.title = 'Ooops'
                this.message = 'Something went wrong'
                this.dialog = false
            }
        },
        mounted() {
            EventBus.listen('showErrorModal', (data) => this.showModal(data))
        }
    }
</script>
