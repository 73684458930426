var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", { staticClass: "font-weight-regular" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "http://www.iccsafe.org/errata-central/",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                    Visit Errata Central to access all posted errata documents.\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.errata_headers,
                  items: _vm.data,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        item.tag.is_active
                          ? _c("tr", [
                              _c("td", { staticClass: "font-weight-bold" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link-table-txt",
                                    attrs: {
                                      target: "_blank",
                                      href: item.tag.url,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.tag.title))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(item.tag.category) + " "),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.tag.published_date,
                                      "MMMM Do, YYYY"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.tag.effective_date,
                                      "MMMM Do, YYYY"
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }