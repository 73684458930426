var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-bottom-sheet",
    {
      attrs: {
        "content-class": _vm.contentClass,
        inset: "",
        "hide-overlay": "",
        "max-width": "1024px",
      },
      model: {
        value: _vm.sheet,
        callback: function ($$v) {
          _vm.sheet = $$v
        },
        expression: "sheet",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "newIccStyles",
          style: !_vm.loading.html
            ? _vm.styles.htmlContainer
            : "height: 100px;",
        },
        [
          _c(
            "v-container",
            {
              ref: "headerContainer",
              staticClass: "accent2 py-0",
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "py-0" }, [
                    _c("p", { staticClass: "caption white--text mb-0" }, [
                      _vm._v(
                        "\n                        Link Preview\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading.html
            ? [
                _vm.title
                  ? _c(
                      "v-container",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "grey lighten-3" },
                          [
                            _c("v-col", { staticClass: "py-1" }, [
                              _c(
                                "p",
                                {
                                  staticClass: "caption mb-0 font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.title) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAstmLink
                  ? _c(
                      "v-container",
                      { ref: "htmlContainer", staticClass: "py-0" },
                      [
                        _c("p", { domProps: { innerHTML: _vm._s(_vm.html) } }),
                        _vm._v(" "),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(_vm.htmlSecondPara) },
                        }),
                      ]
                    )
                  : _vm.html
                  ? _c("v-container", {
                      ref: "htmlContainer",
                      domProps: { innerHTML: _vm._s(_vm.html) },
                    })
                  : _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c("v-col", { attrs: { cols: "auto" } }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(
                                  "\n                            No preview found for this content. Click the link to view this content.\n                        "
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            : _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              transition: "scale-transition",
                              type: "sentences",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }