var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Payment Method")]),
      _vm._v(" "),
      !_vm.invalidVisaCart
        ? _c("p", [_vm._v(_vm._s(_vm.visaCardNumber))])
        : _vm._e(),
      _vm._v(" "),
      _vm.invalidVisaCart
        ? _c("alert-bar", {
            staticClass: "mt-3",
            attrs: {
              id: "display_http_messages",
              message: `Payment method information not found. <a href='${_vm.getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                    Update your payment method</a> to continue.`,
              notificationType: "warning",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }