export default {
    data() {
        return {
            pagination: {
                descending: true,
                page: 1,
                itemsPerPage: 10,
                sortBy: '',
                totalItems: 0,
                rowsPerPageItems: [10, 20, 30, 50, 50, 100, 250]
            },
            subscriptions: [],
            loading: true,
            int: false
        }
    },
    watch: {
        'pagination.page': function () {
            this.getAssignedToMeSubscriptions();
        },
        'pagination.itemsPerPage': function () {
            this.getAssignedToMeSubscriptions();
        }
    },
    methods: {
        getAssignedToMeSubscriptions: function () {
            this.loading = true;
            if (this.int) {
                this.$scrollTo(document.getElementById('license-configuration'), {duration: 0, offset: 66,});
                this.$nextTick(() => {
                    this.subscriptions = [];
                });
            } else {
                this.subscriptions = [];
            }
            let paginationData = _.pick(this.pagination, ['descending', 'page', 'sortBy', 'itemsPerPage']);
            paginationData.rowsPerPage = paginationData.itemsPerPage;
            delete paginationData.itemsPerPage;

            this.$http.post(Routing.generate('assign_to_me_subscription', {
                userId: this.userId,
                pagination: paginationData
            })).then(response => {
                this.subscriptions = response.data.subscriptions;
                this.pagination.totalItems = response.data.resultTotal;
                this.loading = false;
                this.int = true;
            });
        },
        bookLink(book) {

            // No click action for premium complete book
            if (!_.isUndefined(book.content_type) && 'Premium Complete' === book.content_type.name) {
                return false;
            }

            if (book.document_slug) {
                window.location.href = Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug
                });
            } else {
                window.location = Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': 'toc'
                });
            }
        },
        checkStatus(item){
            let currentDate = new Date(),
                expirationDate = new Date(item.subscription.expiration_date),
                msg = '';

            if(item.status){
                if(currentDate <= expirationDate){
                    msg = 'Active';
                }else {
                    msg = 'Expired';
                }
            } else{
                msg = 'Revoked';
            }
            return msg;
        },
    },
    created() {
        EventBus.listen('change-panel-assign-by-me', () => this.getAssignedToMeSubscriptions());
    },
}
