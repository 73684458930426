var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { height: _vm.isMobilePort ? "auto" : "270", outlined: "" } },
    [
      _c("h3", { staticClass: "pl-4 pt-3 title font-weight-bold" }, [
        _vm._v(
          "\n        Your Active Profile: " +
            _vm._s(_vm.currentUserProfile) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-card-title",
        { staticClass: "font-weight-bold subtitle-1" },
        [
          _vm.showPremiumIcon
            ? _c("access-icon", { attrs: { accessLevel: "premiumActive" } })
            : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.premiumTier) + "\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "font-weight-medium text-wrap" }, [
        _c("p", [_vm._v(_vm._s(_vm.activeTierMessage))]),
      ]),
      _vm._v(" "),
      "" !== _vm.tierAssignmentsMessage
        ? _c("v-card-text", { staticClass: "font-weight-medium text-wrap" }, [
            _c("p", {
              staticClass: "font-italic",
              domProps: { innerHTML: _vm._s(_vm.tierAssignmentsMessage) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isFreeTrialUser
        ? _c(
            "v-card-actions",
            { staticClass: "mt-1", class: _vm.isMobilePort && "mb-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-1 mr-2 font-weight-medium mt-1",
                  attrs: { depressed: "", color: "accent2" },
                  on: {
                    click: function ($event) {
                      return _vm.goToRoute("collections_landing", {
                        documentSlug: "premium-complete",
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            Subscribe to Premium Complete\n         "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-medium mt-1",
                  attrs: { outlined: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancelTrial()
                    },
                  },
                },
                [_vm._v("\n            Cancel Trial\n         ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }