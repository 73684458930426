var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticClass: "info-banner",
      attrs: { absolute: true, color: "primary", top: true, timeout: 0 },
      model: {
        value: _vm.notification,
        callback: function ($$v) {
          _vm.notification = $$v
        },
        expression: "notification",
      },
    },
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { flat: "" },
          on: {
            click: function ($event) {
              _vm.notification = false
            },
          },
        },
        [_c("v-icon", { attrs: { color: "lightgreen" } }, [_vm._v("done")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }