<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="800"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Concurrent Access
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="mr-2 accent2--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <concurrent-access>
                </concurrent-access>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';
    import ConcurrentAccess from "../concurrent/ConcurrentAccess";

    export default {
        data: function() {
            return {
                dialog: false
            }
        },
        components: {
            ConcurrentAccess
        },
        mixins: [Mobile],
        created() {
            EventBus.listen('show-modal-concurrent-access', () => this.dialog = true);
        }
    }
</script>
