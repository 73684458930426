<template>
    <div class="pa-3">
        <h2
            class="mb-5"
            v-html="tagData.title">
        </h2>
        <h4>
            Question in Detail:
        </h4>
        <p v-html="tagData.question">
        </p>

        <div class="my-5" v-if="attachmentUrls">
            <strong>Attachment(s):</strong>

            <template v-for="item in attachmentUrls">
                <a :href="item" target="_blank">
                    <v-icon>attachment</v-icon> {{ labelFromUrl(item) }}
                </a> &nbsp;
            </template>
        </div>

        <h4>
            ICC Opinion:
        </h4>
        <p v-html="tagData.content">
        </p>
    </div>
</template>

<script>
    import {UrlUtil} from "../../../util/UrlUtil";

    export default {
        props: {
            tag: {
                required: true,
                type: Object
            }
        },
        computed: {
            tagData(){
                return this.tag?.tag;
            },
            attachmentUrls(){
                return this.tag?.attachment_urls || [];
            }
        },
        methods: {
            labelFromUrl(url) {
                return UrlUtil.nameFromUrl(url)
            }
        }
    }
</script>
