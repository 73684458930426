var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp" },
    [
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        {
          attrs: { dense: "", align: "center" },
          on: {
            click: function ($event) {
              _vm.showExpand = !_vm.showExpand
            },
          },
        },
        [
          _c("v-col", [_vm._t("default")], 2),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "" } },
                [
                  _c("v-icon", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.showExpand ? "mdi-chevron-up" : "mdi-chevron-down"
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-expand-transition", [
        _vm.showExpand
          ? _c("div", { staticClass: "my-2" }, [_vm._t("expand")], 2)
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }