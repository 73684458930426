const storage = window.localStorage;

export const ExamModule = {
    state: {
        activeExam: storage.getItem('active_exam'),
        examSession: storage.getItem('exam_session') ?? false,
    },
    getters: {
        getActiveExam: (state) => state.activeExam,
        isExamSession: (state) => state.examSession,
    },
    mutations: {
        setActiveExam(state, value) {
            storage.setItem('active_exam', value);
            state.activeExam = value;
        },
        /** @param {boolean} value */
        setExamSession(state, value) {
            storage.setItem('exam_session', value);
            state.examSession = value;
        },
    }
}