export default {
    methods : {
        isSubsection : function (breadcrumbs) {
            return _.size(breadcrumbs.subSection) > 0 && !_.isUndefined(typeof(breadcrumbs.subSection));
        },
        isSection : function (breadcrumbs){
            return breadcrumbs.section !== '' && 'undefined' !== typeof(breadcrumbs.section);
        },
        isChapter : function (breadcrumbs){
            return breadcrumbs.chapter !== '' && 'undefined' !== typeof(breadcrumbs.chapter);
        },
        getBookUrl: function(breadcrumbs, documentId) {
            let url = "";
            if (breadcrumbs.documentSlug !== '' && 'undefined' !== typeof(breadcrumbs.documentSlug)) {
                url = Routing.generate(
                    'content_document_slug',
                    {
                        'documentSlug': breadcrumbs.documentSlug
                    }
                )
            } else if('undefined' !== typeof(breadcrumbs.isXml) && false === breadcrumbs.isXml){
                url = Routing.generate(
                    'content_book_details_toc',
                    {
                        'documentId': documentId
                    }
                )
            } else {
                url = Routing.generate(
                    'content_document',
                    {
                        'documentId': documentId
                    }
                )
            }

            return url;
        },
        generateBaseSlug: function(breadcrumbs) {
            return Routing.generate(
                'content_document_slug',
                {
                    'documentSlug': breadcrumbs.documentSlug,
                    'chapterSlug': breadcrumbs.chapterSlug,
                }
            );
        },
        getSectionUrl: function(breadcrumbs) {
            return this.generateBaseSlug(breadcrumbs) + '#text-id-' + breadcrumbs.sectionID;
        },
        getSubSectionUrl: function(breadcrumbs, contentId) {
            let targetContentId = (!_.isUndefined(breadcrumbs.sectionTagId) && !_.isEmpty(breadcrumbs.sectionTagId))
                ? '#'+ breadcrumbs.sectionTagId
                : '#text-id-' + contentId;
            return this.generateBaseSlug(breadcrumbs) + targetContentId;
        },
        registerClick: function(breadcrumbs, documentId) {
            this.gtmEvent('Content Search', 'Result Click', 'Expanded Breadcrumb Link Click');
            
            this.$http.get(Routing.generate('content_search_result_search_click', {
                resultBookId: documentId,
                resultSectionNumber: breadcrumbs.subSection,
                resultSectionTitle: breadcrumbs.title,
                actionTaken: 1
            }));
        }
    }
}
