<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h2 class="primary--text headline">
                        <span class="licenses-step-4">Assigned To Me</span>
                    </h2>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="ml-1 mr-2 primary"
                        @click="refreshSubscriptions('AssignedToMe')">
                        <span v-if="!isRefreshing">Refresh Licenses</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="caption mb-0">
                        Assigned to Me licenses are those where another user has purchased a premium subscription and assigned dedicated usage access to me.
                        Usage of the premium licenses assigned to me are restricted to my individual account but are subject to the assignee revoking access if they choose to.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table
            v-if="subscriptions.length > 0"
            :items="subscriptions"
            :items-per-page="pagination.itemsPerPage"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="pagination.totalItems"
            must-sort
            class="elevation-0 no-margin"
            :footer-props="{
                itemsPerPageOptions: pagination.rowsPerPageItems
            }">
            <template v-slot:header>
                <tr>
                    <td  
                        v-for="(header, index) in headers"
                        :key="index"
                        class="caption font-weight-bold">
                        {{header.text}}
                    </td>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td width="40%" class="subtitle-2">
                        <a @click="bookLink(item.subscription.document_info)" v-if="checkStatus(item) !== 'Revoked'">
                           <assigned-row :row_data="item"></assigned-row>
                        </a>
                        <span v-else>
                            <assigned-row :row_data="item"></assigned-row>
                        </span>
                    </td>
                    <td class="subtitle-2">{{item.assigned_by.email}}</td>
                    <td class="subtitle-2">{{item.created_at | moment("MMMM Do, YYYY")}}</td>
                    <td class="subtitle-2">{{item.subscription.expiration_date | moment("MMMM Do, YYYY")}}</td>
                    <td class="subtitle-2">{{checkSubscriptionType(item.subscription.subscription_type)}}</td>
                    <td class="subtitle-2">{{checkStatus(item)}}
                        <p v-if="checkStatus(item) === 'Revoked'">
                            {{item.updated_at | moment("MMMM Do, YYYY")}}
                        </p>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-data-table
            v-else
            :items="subscriptions"
            :loading="loading"
            class="elevation-0 no-margin"
            >
            <template slot="no-data">
                <p class="text-center mt-6">
                    <template v-if="subscriptions.length === 0 && !loading">
                        No results found!
                    </template>
                    <template v-else>
                        Loading
                    </template>
                </p>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import InfoBanner from '../../banners/info_banner';
    import Licenses from '../../inc/mixins/licenses.js';
    import AssignedToMe from './mixins/assigned_to_me.js';
    import AssignedRow from './parts/assigned_row.vue';

    export default {
        mixins: [Licenses, AssignedToMe],
        data() {
            return {
                headers: [
                    {text: 'Book Title', align: 'left', value: 'di.title'},
                    {text: 'Assigned By', align: 'left', value: 'a.assignedBy'},
                    {text: 'Assigned Date', value: 'a.createdAt'},
                    {text: 'Expiration Date', align: 'left', value: 's.expirationDate'},
                    {text: 'Subscription Type', align: 'left', value: 's.subscriptionType'},
                    {text: 'Subscription Status', align: 'left', value: 'a.status'},
                ]
            }
        },
        components: {
            InfoBanner,
            AssignedRow,
        },
        created() {
            this.getAssignedToMeSubscriptions();
        }
    }

</script>
