<template>
    <img
            :src="asset(src)"
            alt=""
            @load="$emit('load')"
    />
</template>

<script>
export default {
    name: 'AssetImage',
    props: {
        src: {
            type: String,
            required: true
        }
    },
    mounted() {
        // TODO
    }
}
</script>
