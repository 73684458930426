var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.modalTitle) + "\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.formModel,
                    callback: function ($$v) {
                      _vm.formModel = $$v
                    },
                    expression: "formModel",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            ref: "tagName",
                            staticClass: "mx-2 mt-4",
                            attrs: {
                              "hide-detials": "",
                              rules: _vm.rules,
                              placeholder: "Tag Name",
                              solo: "",
                            },
                            model: {
                              value: _vm.tag.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tag, "name", $$v)
                              },
                              expression: "tag.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: _vm.mask,
                                expression: "mask",
                              },
                            ],
                            staticClass: "mx-2 mt-n5",
                            attrs: { "hide-details": "", solo: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          top: "",
                                          "nudge-bottom": "105",
                                          "nudge-left": "16",
                                          "close-on-content-click": false,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { style: _vm.swatchStyle },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.menu,
                                          callback: function ($$v) {
                                            _vm.menu = $$v
                                          },
                                          expression: "menu",
                                        },
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pa-0" },
                                              [
                                                _c("v-color-picker", {
                                                  attrs: { flat: "" },
                                                  model: {
                                                    value: _vm.tag.color,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tag,
                                                        "color",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "tag.color",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.tag.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.tag, "color", $$v)
                              },
                              expression: "tag.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errorMessage
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "error--text ml-2 pb-0" },
                            [_vm._v(_vm._s(_vm.errorMessage))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeModal } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.saveTag } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }