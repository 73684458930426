<template>
    <div>
        <breadcrumb title="Licenses"></breadcrumb>
        <v-container>
            <v-row>
                <v-col>
                    <alert-component
                        v-for="(alert, index) in alerts_role"
                        :key="index"
                        v-if="getActiveRole(alert.id)"
                        elid="alert-notes-new"
                        elclass="mb-2"
                        color="primary"
                        type="info"
                        :title="alert.text"
                        :dismissible="true"
                        cookieid="notes-license">
                    </alert-component>
                </v-col>
            </v-row>
            <template v-if="!isConcurrent">
                <v-row align="center">
                    <v-col
                        class="pr-0"
                        cols="auto">
                        <p class="primary--text caption mb-0">
                            <a @click="$tours['licensesTour'].start()">
                                Licenses Help
                            </a>
                        </p>
                    </v-col>
                    <v-col class="pl-0">
                        <span class="licenses-step-0">
                            <v-btn
                                v-if="!userId"
                                icon
                                color="primary"
                                @click="$tours['licensesTour'].start()">
                                <v-icon>help</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <template v-if="!$vuetify.breakpoint.smAndDown">
                    <v-card class="mb-8">
                        <v-tabs
                            fixed-tabs
                            hide-slider
                            show-arrows
                            v-model="active_tab"
                            background-color="primary"
                            dark>
                            <v-tab
                                class="text-capitalize tab-class"
                                ref="allSubscriptions"
                                @click="panelAction('Purchases')">
                                Purchases/Overview
                            </v-tab>
                            <v-tab
                                class="text-capitalize tab-class"
                                ref="personalSubscription"
                                @click="panelAction('PersonalUse')">
                                Personal Use
                            </v-tab>
                            <v-tab
                                class="text-capitalize tab-class"
                                ref="sharedByMeTab"
                                @click="panelAction('AssignedByMe')">
                                Assigned By Me
                            </v-tab>
                            <v-tab
                                class="text-capitalize tab-class"
                                ref="sharedToMeTab"
                                @click="panelAction('AssignedToMe')">
                                Assigned to Me
                            </v-tab>
                            <v-tab
                                class="text-capitalize tab-class pr-1"
                                ref="concurrentSubscriptions"
                                @click="panelAction('Concurrent')">
                                Concurrent
                            </v-tab>
                        </v-tabs>
                        <v-row>
                            <v-col class="pb-0">
                                <keep-alive>
                                    <template v-if="getPanel === 'Purchases'">
                                        <component
                                            :user-id="user"
                                            v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template v-else-if="getPanel === 'PersonalUse'">
                                        <component
                                            :user-id="user"
                                            v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template v-else-if="getPanel === 'AssignedByMe'">
                                        <component
                                            :user-id="user"
                                            v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template ref="assignedToMeSubscriptions" v-else-if="getPanel === 'AssignedToMe'">
                                        <component
                                            :user-id="user"
                                            v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template v-else-if="getPanel === 'Concurrent'">
                                        <component
                                            :user-id="user"
                                            v-bind:is="getPanel">
                                        </component>
                                    </template>
                                </keep-alive>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-else>
                    <v-row>
                        <v-col>
                            <v-select
                                solo
                                v-model="panel_mobile"
                                hide-details
                                :items="panel_items">
                            </v-select>
                        </v-col>
                    </v-row>
                    <keep-alive>
                        <template v-if="getMobilePanel === 'purchase-overview'">
                            <purchases-mobile :user-id="user"></purchases-mobile>
                        </template>
                        <template v-else-if="getMobilePanel === 'personal-use'">
                            <personal-use-mobile :user-id="user"></personal-use-mobile>
                        </template>
                        <template v-else-if="getMobilePanel === 'assigned-by-me'">
                            <assigned-by-me-mobile :user-id="user"></assigned-by-me-mobile>
                        </template>
                        <template v-else-if="getMobilePanel === 'assigned-to-me'">
                            <assigned-to-me-mobile :user-id="user"></assigned-to-me-mobile>
                        </template>
                        <template v-else-if="getMobilePanel === 'concurrent'">
                            <concurrent-mobile :user-id="user"></concurrent-mobile>
                        </template>
                    </keep-alive>
                </template>
                <v-tour name="licensesTour" :steps="steps" :callbacks="licensesTourCallback"></v-tour>
            </template>
        </v-container>
    </div>
</template>

<script>
    import alertComponent from '../../content/alert';

    //Components
    import Purchases from '../../dashboard/licenses/purchases';
    import PersonalUse from '../../dashboard/licenses/personal_use';
    import AssignedByMe from '../../dashboard/licenses/assigned_by_me';
    import AssignedToMe from '../../dashboard/licenses/assigned_to_me';
    import Concurrent from '../../dashboard/licenses/concurent';
    import Breadcrumb from '../../content/partials/page_titles'
    //Components Mobile
    import PurchasesMobile from './mobile/purchases_mobile';
    import PersonalUseMobile from './mobile/personal_use_mobile';
    import AssignedToMeMobile from './mobile/assigned_to_me_mobile';
    import AssignedByMeMobile from './mobile/assigned_by_me_mobile';
    import ConcurrentMobile from './mobile/concurrent_mobile';

    export default {
        props: {
          userId: {
              type: String,
              required:false
          }
        },
        data() {
            return {
                panel: 'Purchases',
                panel_mobile: 'purchase-overview',
                panel_items: [
                    {
                        text: 'Purchases/Overview',
                        value: 'purchase-overview'
                    },
                    {
                        text: 'Personal Use',
                        value: 'personal-use'
                    },
                    {
                        text: 'Assigned by Me',
                        value: 'assigned-by-me'
                    },
                    {
                        text: 'Assigned to Me',
                        value: 'assigned-to-me'
                    },
                    {
                        text: 'Concurrent',
                        value: 'concurrent'
                    }
                ],
                active_tab: 0,
                user_role: [],
                user: this.userId ? this.userId : null,
                alerts_role: [
                    {
                        id: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
                        text: 'You have access to all Premium titles through the Admin Role. Subscription access does not expire, but you are restricted to self use only meaning titles cannot be assigned or made concurrent.  Only your subscriptions purchased through the ICC store will be displayed within the configuration tabs.'
                    },
                    {
                        id: ['ROLE_STAFF'],
                        text: 'You have access to all Premium titles through the Staff Role. Subscription access does not expire, but you are restricted to self use only meaning titles cannot be assigned or made concurrent.  Only your subscriptions purchased through the ICC store will be displayed within the configuration tabs.'
                    },
                    {
                        id: ['CONCURRENT'],
                        text: 'You are connected to Digital Codes Premium through a concurrent subscription.  At this time the configuration can not be changed.  Only your subscriptions purchased through the ICC store will be displayed within the configuration tabs.'
                    },
                    {
                        id: ['ROLE_TRIAL_USER'],
                        text: 'You are connected to Digital Codes Premium through a trial subscription.  Only your subscriptions purchased through the ICC store will be displayed within the configuration tabs.'
                    }
                ],
                licensesTourCallback: {
                    onPreviousStep: this.prevTab,
                    onNextStep: this.checkTab,
                    onStop: this.cancelUserTour,
                },
                steps: [
                    {
                        target: '.licenses-step-0',
                        content: 'Would you like to learn how to configure your premium licenses?',
                        params: {
                            placement: this.checkMobile('right'),
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-1',
                        content: 'Purchases/Overview tab lists out all of your Premium subscriptions, grouped by title. Click View Details to see subscription type, status and expiration date.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-2',
                        content: 'Personal Use tab lists your Premium subscriptions that are configured for your own use only. Personal use is the default configuration for a premium license after purchase. These licenses can be assigned to another individual or converted to concurrent access at any time.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-3',
                        content: 'Assigned By Me allows you to transfer usage of any Premium license to another individual. Once assigned, the licenses is dedicated to the assignee meaning that it is no longer available under Personal Use. You have access to a usage report to see how the assigned individual is using the Premium subscription, which can be revoked or reassigned at any time.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-3_1',
                        content: 'To assign a Premium subscription to another individual, click the Assign Subscription and follow the required steps. You will be prompted to enter an email address, select the Premium title to be assigned and enter an optional message to accompany the email notification.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-4',
                        content: 'Assigned to Me tab lists out the Premium subscriptions that another individual has assigned to you. As the assignee, you do not have the ability to reassign or convert these licenses to concurrent but you can see the assigned date, subscription type, status and expiration date.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-5',
                        content: 'Concurrent access allows for sharing of Premium subscriptions through a shared code. Concurrent codes are required to be unique and control how many users can access your configured Premium titles at the same time. A concurrent code with 3 licenses, for example, would allow the first 3 users to enter the code to leverage Premium features while the 4th user would be prompted to wait for a license to become available. A usage report is available so that you can see how often your concurrent code is being accessed and by whom.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    },
                    {
                        target: '.licenses-step-5_1',
                        content: 'To configure a code, click on Generate Concurrent Code and follow the required steps. You will be prompted to generate a unique concurrent code (or enter one of your choosing), define the number of licenses to be grouped under the code, add the desired titles to the code for access and enter a recipient list to share the concurrent code via email. You can edit your code and the books grouped under the code at any time.',
                        params: {
                            placement: 'bottom',
                            enableScrolling: false
                        }
                    }
                ]
            }
        },
        components: {
            alertComponent,
            Purchases,
            PersonalUse,
            AssignedByMe,
            AssignedToMe,
            Concurrent,
            PurchasesMobile,
            PersonalUseMobile,
            AssignedToMeMobile,
            AssignedByMeMobile,
            ConcurrentMobile,
            Breadcrumb
        },
        methods: {
            panelAction(panel) {
                this.panel = panel;
                if (!_.isUndefined(this.$tours) && !_.isUndefined(this.$tours['licensesTour'])) {
                    this.$tours['licensesTour'].stop();
                }
            },
            prevTab(currentStep) {
                this.checkTab(currentStep-2);
            },
            checkTab(currentStep) {
                switch (currentStep) {
                    case 1:
                        this.panel = 'PersonalUse';
                        this.active_tab = 1;
                        break;
                    case 2:
                    case 3:
                        this.panel = 'AssignedByMe';
                        this.active_tab = 2;
                        break;
                    case 4:
                        this.panel = 'AssignedToMe';
                        this.active_tab = 3;
                        break;
                    case 5:
                    case 6:
                        this.panel = 'Concurrent';
                        this.active_tab = 4;
                        break;
                    default:
                        this.panel = 'Purchases';
                        this.active_tab = 0;
                }

            },
            checkMobile(value) {
                if (this.$vuetify.breakpoint.smAndDown) {
                    return 'bottom';
                }
                return value;
            },
            populateUserRole() {
                this.$http.post(Routing.generate('get_user_role'))
                    .then(response => {
                        this.user_role = response.data;
                    });
            },
            getAndSetTourPreferences(data = []){
                this.$http.post(Routing.generate('get_user_preferences', data))
                    .then(response => {
                        if(!this.userId && response.data.preferences.license_tour){
                            this.$tours['licensesTour'].start();
                        }
                    });
            },
            cancelUserTour(){
                let data = {
                    licenseTour: 0
                };
                this.getAndSetTourPreferences(data);
            },
            switchTabTo(tab){
                this.panelAction(tab);
                this.$session.remove('switch-to-license-tab');
            },
            syncSubscriptions(){
                this.$http.get(Routing.generate('sync_shop_purchase'));
                this.reloadSubscriptions();
            },
            refreshLicenses() {
                if(this.$session.has('switch-to-license-tab')) {
                    let tabTitle = this.$session.get('switch-to-license-tab');
                    if('AssignedByMe' === tabTitle) {
                        this.active_tab = 2;
                    } else if('Concurrent' === tabTitle) {
                        this.active_tab = 4;
                    }
                    this.switchTabTo(this.$session.get('switch-to-license-tab'));
                }
            },
            switchToTab: function(element, etype, custom) {
                const evt = custom ?? new Event(etype, {bubbles: true});
                element.$el.dispatchEvent(evt);
            }
        },
        computed: {
            getPanel() {
                return this.panel;
            },
            getMobilePanel() {
                return this.panel_mobile;
            },
            activeTab() {
                return this.active_tab;
            },
            getActiveRole() {
                return (rolesGroup) => {
                    let flag = false;
                    let _this = this;
                    _.forEach(rolesGroup, function(role) {
                        if (_.includes(_this.user_role, role)) {
                            flag = true;
                        } else if (role === 'CONCURRENT' && _this.$session.has('active-concurrent')) {
                            flag = true;
                        }
                    });

                    return flag;
                }
            },
            isConcurrent() {
                return this.$session.has('active-concurrent');
            }
        },
        beforeMount(){
          this.getAndSetTourPreferences();
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            const assignedToMe = urlParams.get('assignedToMe');
            const refElement = this.$refs['sharedToMeTab'];

            if(refElement && assignedToMe) {
                this.switchToTab(refElement,'click',this.panelAction('AssignedToMe'));
            }
        },
        created() {
            this.refreshLicenses();
            EventBus.listen('change-panel-assign-by-me', () => this.switchTabTo('AssignedByMe'));
            EventBus.listen('change-license-tab-to', (tab) => this.switchTabTo(tab));
            EventBus.listen('sync-subscriptions', () => this.syncSubscriptions());
            this.populateUserRole();
        },
    }
</script>

<style lang="scss">
    .no-margin {
        table {
            margin: 0;
        }
    }
    .tab-class{
        padding: 0px 15px;
        background: #fff;
        color: #0b5940 !important;
        border: 1px solid #0b5940;
        border-right: 0;
    }
    .v-tab--active {
        background: #0b5940 !important;
        color: #fff !important;
    }
    .v-tour > * {
        z-index: 1;
    }
</style>
