export default {
    data() {
        return {
            contentSearchDefault: {
                all: '',
                any: '',
                exact: '',
                exactSection: '',
                none: '',
                proximity: '',
                proximityWithin: '',
                isEventTriggered: false
            }
        }
    },
    computed: {
        defaultSearch: {
            get() {
                return this.$store.getters.getDefaultSearch;
            },
            set(value) {
                this.$store.commit('setDefaultSearch', value);
            }
        },
        titleGroups: {
            get() {
                return this.$store.getters.getTitleGroups;
            }
        },
        filterGroups: {
            get() {
                return this.$store.getters.getTabbedGroups;
            }
        },
        filterCategories: {
            get() {
                return this.$store.getters.getTabbedCategories;
            }
        },
        filterYears: {
            get() {
                return this.$store.getters.getTabbedYears;
            }
        },
        filterTitleIds: {
            get() {
                return this.$store.getters.getTabbedTitleIds;
            }
        },
        filterTitles: {
            get() {
                return this.$store.getters.getTabbedTitles;
            }
        }
    }
}
