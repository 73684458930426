<template>
    <v-container :fluid="!isExtraLarge">
        <v-row align="center">
            <v-col>
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-icon
                            left
                            color="primary">
                            mdi-book
                        </v-icon>
                    </v-col>
                    <v-col>
                        <h3>
                            All Included Titles
                        </h3>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                md="6"
                xl="4">
                <v-text-field
                    v-model="search"
                    dense
                    clearable
                    outlined
                    rounded
                    class="white"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search across included titles">
                </v-text-field>
            </v-col>
        </v-row>
        <title-list
            :titles="titles"
            :loading="loading"
            :search="search"
            :itemsPerPage="itemsPerPage">
        </title-list>
        <v-row
            v-if="itemsPerPage < titles.length"
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    @click="itemsPerPage = itemsPerPage+10">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col>
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-icon
                            left
                            color="primary">
                            mdi-clipboard-text-multiple-outline
                        </v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <h3>
                            Exclusive Bonus Content ({{bonusContents.length}})
                        </h3>
                    </v-col>
                    <v-col>
                        <v-tooltip
                            right
                            color="black"
                            transition="fade-transition"
                            v-model="tooltips.bonus">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    tabindex="0"
                                    @keyup.enter="showTooltip('bonus')"
                                    @keyup.space="showTooltip('bonus')">
                                    info
                                </v-icon>
                            </template>
                            <span class="tooltip-content"
                            @mouseenter="showTooltip('bonus')"
                            @mouseleave="hideTooltip('bonus')"
                            @focus="showTooltip('bonus')"
                            @blur="hideTooltip('bonus')">
                                <p class="caption mb-0">
                                    These titles are only available as a part of the Premium Complete subscription.</p>
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                md="6"
                xl="4">
                <v-text-field
                    v-model="exclusiveSearch"
                    dense
                    clearable
                    outlined
                    rounded
                    class="white"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search across bonus content">
                </v-text-field>
            </v-col>
        </v-row>
        <title-list
            :titles="bonusContents"
            :loading="loading"
            :search="exclusiveSearch"
            :itemsPerPage="itemsPerPage">
        </title-list>
        <v-row
            v-if="itemsPerPage < bonusContents.length"
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    @click="itemsPerPage = itemsPerPage+10">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="6" v-if="recentlyAdded.length > 0">
                <v-row
                    dense>
                    <v-col cols="auto">
                        <v-icon left>
                            mdi-update
                        </v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <h3>
                            Recently Added
                        </h3>
                    </v-col>
                    <v-col>
                        <v-tooltip
                            right
                            color="black"
                            transition="fade-transition"
                            v-model="tooltips.recent">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    tabindex="0"
                                    @keyup.enter="showTooltip('recent')"
                                    @keyup.space="showTooltip('recent')">
                                    info
                                </v-icon>
                            </template>
                            <span class="tooltip-content"
                            @mouseenter="showTooltip('recent')"
                            @mouseleave="hideTooltip('recent')"
                            @focus="showTooltip('recent')"
                            @blur="hideTooltip('recent')">
                                <p class="caption mb-0">
                                    The number of titles included within Premium Complete is always expanding.
                                    <br/>These are the most recent titles added to Premium Complete within the last 30 days.</p>
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <title-list-dense
                            :titles="recentlyAdded"
                            :loading="loading">
                        </title-list-dense>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="6" v-if="excludedTitles.length > 0">
                <v-row
                    dense>
                    <v-col cols="auto">
                        <v-icon left>
                            mdi-notebook-remove-outline
                        </v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <h3>
                            Available Add-on Titles (Not Included with Premium Complete)
                        </h3>
                    </v-col>
                    <v-col>
                        <v-tooltip
                            right
                            color="black"
                            transition="fade-transition"
                            v-model="tooltips.addon">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    tabindex="0"
                                    @keyup.enter="showTooltip('addon')"
                                    @keyup.space="showTooltip('addon')">
                                    info
                                </v-icon>
                            </template>
                            <span class="tooltip-content"
                            @mouseenter="showTooltip('addon')"
                            @mouseleave="hideTooltip('addon')"
                            @focus="showTooltip('addon')"
                            @blur="hideTooltip('addon')">
                                <p class="caption mb-0">
                                    While Premium Complete is the largest collection of titles available
                                    <br/>it does not include everything that is available on Digital Codes.
                                    <br/>The titles listed here are not currently a part of Premium Complete.</p>
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <title-list-dense
                            :titles="excludedTitles"
                            :loading="loading">
                        </title-list-dense>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import TitleList from '../../content/title/TitleList.vue';
    import TitleListDense from '../../content/partials/title_list_dense';

    export default {
        props: {
            documentId: {
                required: true,
                type: Number
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            TitleList,
            TitleListDense
        },
        data() {
            return {
                tooltips: {
                    bonus: false,
                    recent: false,
                    addon: false
                },
                loading: true,
                search: '',
                exclusiveSearch: '',
                itemsPerPage: 10,
                titles: [],
                recentlyAddedDocuments: [],
                excludedTitles: [],
                bonusContents: []
            }
        },
        methods: {
            getIncludedTitles() {
                this.$http.get(
                    Routing.generate("get_complete_titles")
                ).then(response => {
                    if (response.data) {
                        this.titles = response.data;

                        const xmlOrPremiumPdfCount = this.titles.filter((title) => {
                            return title.is_xml || title.is_premium_pdf;
                        }).length;

                        EventBus.fire('set-collection-count', xmlOrPremiumPdfCount);
                    }
                    this.loading = false;
                });
            },
            getPremiumBonusTitles() {
                this.$http.get(
                    Routing.generate("get_exclusive_bonus_content")
                ).then(response => {
                    if (response.data) {
                        this.bonusContents = response.data;
                    }
                    this.loading = false;
                });
            },
            getPremiumTitlesExcluded() {
                this.$http.get(
                    Routing.generate("get_excluded_titles")
                ).then(response => {
                    if (response.data) {
                        this.excludedTitles = response.data;
                    }
                    this.loading = false;
                });
            },
            exportPCTitlesToCsv() {
                this.exportCsv(this.titles, 'premium-complete-titles');
            },
            showTooltip(key) {
                this.$set(this.tooltips, key, true);
            },
            hideTooltip(key) {
                this.$set(this.tooltips, key, false);
            }
        },
        created() {
            this.getIncludedTitles();
            this.getPremiumBonusTitles();
            this.getPremiumTitlesExcluded();
            EventBus.listen("export-premium-complete-titles-list", () => this.exportPCTitlesToCsv());
        },
        computed: {
            recentlyAdded() {
                if (this.titles) {
                    return _.filter(this.titles, function(item) {
                        return item.recently_added;
                    });
                }
            }
        }
    }
</script>