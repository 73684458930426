var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.concurrencyModal,
            callback: function ($$v) {
              _vm.concurrencyModal = $$v
            },
            expression: "concurrencyModal",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  !_vm.editItem
                    ? [
                        _vm._v(
                          "\n                    Generate Concurrent Code\n                "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n                    Update Concurrent Code\n                "
                        ),
                      ],
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmCloseIfStarted()
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "steps_container" },
                [
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        { staticClass: "height-auto" },
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.step > 1,
                                step: "1",
                                rules: [() => _vm.rules.accessCode],
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Create Code\n                            "
                              ),
                              _vm.step > 1
                                ? _c("p", [
                                    _c(
                                      "small",
                                      { staticClass: "black--text" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Code:")]
                                        ),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(this.accessCode) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.step > 2,
                                step: "2",
                                rules: [() => _vm.rules.assignments],
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Select Books\n                            "
                              ),
                              _vm.step > 2
                                ? _c("small", { staticClass: "license_info" }, [
                                    _c(
                                      "small",
                                      { staticClass: "black--text" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              title:
                                                "Amount of licenses selected",
                                            },
                                          },
                                          [_vm._v("Licenses: ")]
                                        ),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.licenses) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "black--text" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              title:
                                                "No of selected subscriptions",
                                            },
                                          },
                                          [_vm._v("Subscriptions: ")]
                                        ),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.select.length) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm.additionalTab
                            ? _c(
                                "v-stepper-step",
                                {
                                  attrs: {
                                    complete: _vm.step > 3,
                                    step: "3",
                                    rules: [() => _vm.rules.assignLicense],
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Subscriptions\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.additionalTab ? _c("v-divider") : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.isCompleted(
                                  _vm.additionalTab ? 4 : 3
                                ),
                                step: _vm.additionalTab ? 4 : 3,
                                rules: [() => _vm.rules.adminEmail],
                              },
                            },
                            [
                              _vm._v(
                                "\n                            User Access\n                            "
                              ),
                              _vm.rules.adminEmail === true &&
                              "" != _vm.admin_emails[0].email &&
                              _vm.admin_emails
                                ? _c("small", { staticClass: "black--text" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Admins: ")]
                                    ),
                                    _vm._v(
                                      _vm._s(_vm.admin_emails.length) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm.step == (_vm.additionalTab ? 4 : 3)
                                ? _c("small", [
                                    _vm._v(_vm._s(_vm.errorMessage)),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: _vm.additionalTab ? 5 : 4,
                                rules: [() => _vm.rules.email],
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Notify\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c("v-col", [
                                        _c(
                                          "p",
                                          { staticClass: "black--text" },
                                          [
                                            _vm._v(
                                              "\n                                            Type or auto generate access code you want to create. "
                                            ),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                                Concurrent code must be 6 or more alphanumeric characters and unique.\n                                            "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              "mb-1":
                                                _vm.$vuetify.breakpoint.smAndUp,
                                            },
                                            attrs: {
                                              "data-qa":
                                                "dashboard-licenses-concurrent-code",
                                              solo: "",
                                              label: "Enter access code",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.accessCode,
                                              callback: function ($$v) {
                                                _vm.accessCode = $$v
                                              },
                                              expression: "accessCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-licenses-concurrent-auto-generate",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.autoGenerateCode()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Auto Generate\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.checkAccessCode()
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "mt-0 pt-0",
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-force-logout",
                                                  label:
                                                    "Force logout any connections with old concurrent code",
                                                  color: "primary",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.logout,
                                                  callback: function ($$v) {
                                                    _vm.logout = $$v
                                                  },
                                                  expression: "logout",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.errorMessage
                                ? _c(
                                    "p",
                                    { staticClass: "error--text caption" },
                                    [_vm._v(_vm._s(_vm.errorMessage))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-qa":
                                      "dashboard-licenses-concurrent-continue",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validateInputs()
                                    },
                                  },
                                },
                                [
                                  !_vm.loading
                                    ? _c("span", [_vm._v("Continue")])
                                    : _c("v-progress-circular", {
                                        attrs: {
                                          size: 20,
                                          indeterminate: "",
                                          color: "white",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _c(
                                          "span",
                                          { staticClass: "black--text" },
                                          [
                                            _vm._v(
                                              "Select books for the Concurrent Code"
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _vm.rules.assignments === true &&
                                        _vm.step <= 2
                                          ? _c(
                                              "small",
                                              { staticClass: "black--text" },
                                              [
                                                _vm._v(
                                                  "\n                                            Group your Digital Codes Premium titles under a common code for shared access."
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v("Note:")]
                                                ),
                                                _vm._v(
                                                  " This will convert the license from personal use\n                                            to concurrent.\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _vm.max_number > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "black--text" },
                                              [
                                                _vm._v(
                                                  "\n                                            Select quantity of license for this Concurrent Code, Max: (" +
                                                    _vm._s(_vm.max_number) +
                                                    ")\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.max_number > 0
                                    ? _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "auto" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "pa-0 pt-1",
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-license-quantity",
                                                  solo: "",
                                                  "hide-details": "",
                                                  "single-line": "",
                                                  disabled: _vm.disabled,
                                                  type: "number",
                                                  min: 1,
                                                  max: _vm.max_number,
                                                },
                                                model: {
                                                  value: _vm.licenses,
                                                  callback: function ($$v) {
                                                    _vm.licenses = $$v
                                                  },
                                                  expression: "licenses",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-slider", {
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-slider",
                                                  min: 1,
                                                  max: _vm.max_number,
                                                  step: "1",
                                                  disabled: _vm.disabled,
                                                  "thumb-label": "",
                                                  "hide-details": "",
                                                  ticks: "",
                                                },
                                                model: {
                                                  value: _vm.licenses,
                                                  callback: function ($$v) {
                                                    _vm.licenses = $$v
                                                  },
                                                  expression: "licenses",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "data-qa":
                                                      "dashboard-licenses-concurrent-continue",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getSubscriptions()
                                                    },
                                                  },
                                                },
                                                [
                                                  !_vm.disabled
                                                    ? _c("span", [
                                                        _vm._v("Continue"),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("Change"),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    "You don't have any available subscriptions! "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "You can buy Subscriptions from "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: _vm.goToTheShop,
                                                      },
                                                    },
                                                    [_vm._v("here")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              this.subscriptions.length
                                ? _c("v-combobox", {
                                    attrs: {
                                      "data-qa":
                                        "dashboard-licenses-concurrent-subscription",
                                      solo: "",
                                      items: this.sortedSubscriptions,
                                      "item-text": "title",
                                      label: "Select subscriptions",
                                      multiple: "",
                                      "small-chips": "",
                                      "menu-props": {
                                        top: true,
                                        maxHeight: "60vh",
                                        contentClass: "books_menu",
                                        offsetY: true,
                                      },
                                      "hide-details": "",
                                      chips: "",
                                      "deletable-chips": "",
                                      tags: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (data) {
                                            return [
                                              [
                                                _c("v-list-item-avatar", [
                                                  _c("img", {
                                                    attrs: {
                                                      src: data.item.avatar,
                                                    },
                                                  }),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          data.item.title
                                                        ),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          "Available licenses for concurrent: " +
                                                            data.item
                                                              .license_configuration
                                                              .available
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3290160620
                                    ),
                                    model: {
                                      value: _vm.select,
                                      callback: function ($$v) {
                                        _vm.select = $$v
                                      },
                                      expression: "select",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2" }, [_vm._v(" ")]),
                              _vm._v(" "),
                              _vm.toRestore.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "large",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                "Note: These Subscriptions will be restored to personal use,\n                                        because they don't have enough licenses!\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.toRestore,
                                            function (item, index) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  { key: index },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _c("small", [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                index + 1 < _vm.toRestore.length
                                                  ? _c("v-divider", {
                                                      key: `divider-${index}`,
                                                    })
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessage
                                ? _c(
                                    "p",
                                    { staticClass: "error--text caption" },
                                    [_vm._v(_vm._s(_vm.errorMessage))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isBookMenuOpen
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { color: "error" } },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "", left: "" } },
                                            [_vm._v("mdi-minus-circle")]
                                          ),
                                          _vm._v(
                                            " Close Menu\n                                "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      this.subscriptions.length !== 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-licenses-concurrent-back",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.step = 1
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Back\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      this.subscriptions.length !== 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-0 primary",
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-licenses-concurrent-continue",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkIfSubscriptionsHaveMultipleTransactions()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Continue\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.additionalTab
                            ? _c(
                                "v-stepper-content",
                                { attrs: { step: "3" } },
                                [
                                  _vm._v(
                                    "\n                            Select which subscription you want to use.\n                            "
                                  ),
                                  _vm.rules.assignLicense === true &&
                                  _vm.step <= 3
                                    ? _c("p", [
                                        _c("small", [
                                          _vm._v(
                                            "You have to choose which subscription you want to assign to this concurrent code!"
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.select, function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        item.grouped_license.length > 1
                                          ? _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      color: "white",
                                                    },
                                                  },
                                                  [
                                                    _c("v-toolbar-title", [
                                                      _vm._v(
                                                        _vm._s(item.title)
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("v-spacer"),
                                                    _vm._v(" "),
                                                    _vm.isRequiredLicenseQuantityMatch() &&
                                                    _vm.totalSelectedLicense(
                                                      index
                                                    ) === _vm.licenses
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "green",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "check_circle"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [_vm._v("error")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "sub",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        You have selected\n                                        "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                        attrs: { id: index },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalSelectedLicenseInGroup
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                                        from\n                                        "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.licenses)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " licenses!\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("v-data-table", {
                                                  key: item.id,
                                                  attrs: {
                                                    headers: _vm.headers,
                                                    items: item.grouped_license,
                                                    "hide-default-footer": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "tr",
                                                              {
                                                                class: {
                                                                  lightgreen:
                                                                    _vm
                                                                      .selectedLicense[
                                                                      item.id
                                                                    ],
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-xs-left",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.purchase_id
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "moment"
                                                                        )(
                                                                          item.expiration_date,
                                                                          "MMMM Do, YYYY"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .license_configuration
                                                                          .available
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-xs-right",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-menu",
                                                                      {
                                                                        attrs: {
                                                                          "offset-y":
                                                                            "",
                                                                          "max-height":
                                                                            "200px",
                                                                          disabled:
                                                                            _vm.isRequiredLicenseQuantityMatch() &&
                                                                            _vm
                                                                              .selectedLicense[
                                                                              item
                                                                                .id
                                                                            ] !==
                                                                              undefined &&
                                                                            !_vm
                                                                              .selectedLicense[
                                                                              item
                                                                                .id
                                                                            ],
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              small:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                Selected  \n                                                                "
                                                                                        ),
                                                                                        _vm
                                                                                          .selectedLicense[
                                                                                          item
                                                                                            .id
                                                                                        ]
                                                                                          ? _c(
                                                                                              "b",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "(" +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .selectedLicense[
                                                                                                        item
                                                                                                          .id
                                                                                                      ]
                                                                                                    ) +
                                                                                                    ")"
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "b",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "(0)"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-list",
                                                                          [
                                                                            _vm
                                                                              .selectedLicense[
                                                                              item
                                                                                .id
                                                                            ]
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "a",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list__tile list__tile--link",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.generateLicenses(
                                                                                                item,
                                                                                                0,
                                                                                                index
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "pl-3 font-weight-bold list__tile__title",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "0"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.totalSelectedLicense(
                                                                              index
                                                                            ) ===
                                                                              _vm.licenses &&
                                                                            _vm
                                                                              .selectedLicense[
                                                                              item
                                                                                .id
                                                                            ]
                                                                              ? _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    item
                                                                                      .license_configuration
                                                                                      .available,
                                                                                    function (
                                                                                      n
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: n,
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateLicenses(
                                                                                                  item,
                                                                                                  n,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-title",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  n
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) <
                                                                                  _vm.licenses &&
                                                                                _vm
                                                                                  .selectedLicense[
                                                                                  item
                                                                                    .id
                                                                                ] &&
                                                                                _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) !==
                                                                                  _vm.licenses
                                                                              ? _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    item
                                                                                      .license_configuration
                                                                                      .available,
                                                                                    function (
                                                                                      n
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: n,
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateLicenses(
                                                                                                  item,
                                                                                                  n,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-title",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  n
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) ===
                                                                                  _vm.licenses &&
                                                                                !_vm
                                                                                  .selectedLicense[
                                                                                  item
                                                                                    .id
                                                                                ]
                                                                              ? _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    item
                                                                                      .license_configuration
                                                                                      .available,
                                                                                    function (
                                                                                      n
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: n,
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateLicenses(
                                                                                                  item,
                                                                                                  n,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-title",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  n
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) <
                                                                                  _vm.licenses &&
                                                                                _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) <
                                                                                  item
                                                                                    .license_configuration
                                                                                    .available &&
                                                                                _vm.totalSelectedLicense(
                                                                                  index
                                                                                ) !==
                                                                                  0
                                                                              ? _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    _vm.totalSelectedLicenseCount(
                                                                                      _vm.totalSelectedLicense(
                                                                                        index
                                                                                      ),
                                                                                      item
                                                                                        .license_configuration
                                                                                        .available
                                                                                    ),
                                                                                    function (
                                                                                      n
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: n,
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateLicenses(
                                                                                                  item,
                                                                                                  n,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-title",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  n
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    item
                                                                                      .license_configuration
                                                                                      .available,
                                                                                    function (
                                                                                      n
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: n,
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateLicenses(
                                                                                                  item,
                                                                                                  n,
                                                                                                  index
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-title",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  n
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.errorMessage
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "caption error--text pt-2",
                                        },
                                        [_vm._v(_vm._s(_vm.errorMessage))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _vm.additionalTab
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-licenses-concurrent-back",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.step = 2
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Back\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.additionalTab
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-0 primary",
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-licenses-concurrent-continue",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.validateSubscriptions()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Continue\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: _vm.additionalTab ? 4 : 3 } },
                            [
                              _c("span", { staticClass: "black--text" }, [
                                _vm._v(
                                  "\n                                Admin users of a concurrent code have the permissions to view, create, edit and delete notes of other user’s notes created on premium content.\n                                To authorize another user as a concurrent admin user, please enter an email address below.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-bold green--text caption",
                                },
                                [
                                  _vm._v(
                                    "\n                                (This is an optional step. You can continue without entering any email address.)\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Note:")]
                                ),
                                _vm._v(
                                  "\n                                To add multiple concurrent admin users, please add a comma after each email address.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row wrap" },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs9: "" } },
                                            [
                                              _c("v-text-field", {
                                                class: {
                                                  "mb-1":
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-email",
                                                  solo: "",
                                                  label: "Enter email address",
                                                },
                                                model: {
                                                  value: _vm.input_admin_emails,
                                                  callback: function ($$v) {
                                                    _vm.input_admin_emails = $$v
                                                  },
                                                  expression:
                                                    "input_admin_emails",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-qa":
                                      "dashboard-licenses-concurrent-back",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.step = _vm.additionalTab ? 3 : 2
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Back\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-qa":
                                      "dashboard-licenses-concurrent-continue",
                                    color: "primary",
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validateAdminEmails()
                                    },
                                  },
                                },
                                [
                                  !_vm.loading
                                    ? _c("span", [_vm._v("Continue")])
                                    : _c("v-progress-circular", {
                                        attrs: {
                                          size: 20,
                                          indeterminate: "",
                                          color: "white",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: _vm.additionalTab ? 5 : 4 } },
                            [
                              _c("span", { staticClass: "black--text" }, [
                                _vm._v(
                                  "To notify a user that a concurrent code has been generated, please enter an email address below."
                                ),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _vm.rules.email === true
                                ? _c("small", { staticClass: "black--text" }, [
                                    _vm._v(
                                      '\n                                Also, you may send emails later by switching to the "Notify Later" button!\n                            '
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  "\n                                If you do not want to notify users by emails that a concurrent code has been created right now, you may do that later!"
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Note:")]
                                ),
                                _vm._v(
                                  " To add multiple recipients, please add a comma\n                                after each email address.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-switch", {
                                staticClass: "ml-1",
                                attrs: {
                                  label:
                                    _vm.assign_emails === false
                                      ? "I will notify users now."
                                      : "I will notify users later.",
                                },
                                model: {
                                  value: _vm.assign_emails,
                                  callback: function ($$v) {
                                    _vm.assign_emails = $$v
                                  },
                                  expression: "assign_emails",
                                },
                              }),
                              _vm._v(" "),
                              !_vm.assign_emails
                                ? _c(
                                    "v-card-text",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                class: {
                                                  "mb-1":
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-email-notify",
                                                  solo: "",
                                                  "hide-details": "",
                                                  label: "Enter email address",
                                                },
                                                model: {
                                                  value: _vm.input_emails,
                                                  callback: function ($$v) {
                                                    _vm.input_emails = $$v
                                                  },
                                                  expression: "input_emails",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-textarea", {
                                                staticClass: "black--text",
                                                attrs: {
                                                  "data-qa":
                                                    "dashboard-licenses-concurrent-email-message",
                                                  label: "Message",
                                                  solo: "",
                                                  rows: "4",
                                                  "auto-grow": "",
                                                  counter: "400",
                                                },
                                                model: {
                                                  value: _vm.description,
                                                  callback: function ($$v) {
                                                    _vm.description = $$v
                                                  },
                                                  expression: "description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.rules.email !== true && _vm.errorMessage
                                ? _c(
                                    "p",
                                    { staticClass: "caption error--text" },
                                    [_vm._v(_vm._s(_vm.errorMessage))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-qa":
                                      "dashboard-licenses-concurrent-back",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.step = _vm.additionalTab ? 4 : 3
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Back\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-qa":
                                      "dashboard-licenses-concurrent-continue",
                                    color: "primary",
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validateEmails()
                                    },
                                  },
                                },
                                [
                                  !_vm.loading
                                    ? _c("span", [_vm._v("Continue")])
                                    : _c("v-progress-circular", {
                                        attrs: {
                                          size: 20,
                                          indeterminate: "",
                                          color: "white",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.endConcurrencyModalConfirm,
            callback: function ($$v) {
              _vm.endConcurrencyModalConfirm = $$v
            },
            expression: "endConcurrencyModalConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n                Please confirm\n            "),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "You have entered information that will not be saved when you exit this screen. Are you sure you want to leave?"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", primary: "" },
                      on: {
                        click: function ($event) {
                          _vm.endConcurrencyModalConfirm = false
                        },
                      },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("\n                    Yes\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }