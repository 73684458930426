var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("actionable-alert", {
    attrs: { visible: _vm.visible, "action-text": "Jump to Section" },
    on: { onAction: _vm.clickedReturn, onDismiss: _vm.clickedClose },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c(
              "v-icon",
              { staticClass: "mr-1", attrs: { color: "blue darken-4" } },
              [_vm._v("\n            mdi-history\n        ")]
            ),
            _vm._v("\n\n        Continue where you left off: "),
            _vm.sectionTitle
              ? _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("(" + _vm._s(_vm.sectionTitle) + ")"),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }