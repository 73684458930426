<template>
    <v-card
        elevation="8"
        :id="'notePreview'+note.id"
        class="mx-auto note-preview"
        outlined
        max-width="350px"
        :style="style"
        @mouseover="mouse = true"
        @mouseleave="mouse = false; card = false">
        <v-layout row>
            <v-flex
                class="px-4 pt-2">
                <h4 class="mb-1">My Note</h4>
                <div
                    v-if="noteText"
                    v-html="noteText">
                </div>
                <h5 class="font-weight-regular mb-2">Project Tag: {{ tagName }}</h5>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import ContentActions from '../inc/contentActions';

    export default {
        data() {
            return {
                noteData: {},
                card: false,
                mouse: false,
                top: 0,
                left: 0
            }
        },
        computed: {
            style() {
                let top = (this.card) ? this.top : -10000;
                let visibility = (this.card) ? 'visible' : 'hidden';
                return 'z-index: 200; visibility: '+visibility+'; position: absolute; top:'+top+'px; left:'+this.left+'px;';
            },
            noteText() {
                if (!_.isUndefined(this.noteData.annotation)) {
                    return this.noteData.annotation.text;
                }
            },
            tagName() {
                if (this.noteData.tag) {
                    let tag = _.find(window.vm.$store.getters.getTags, {'id': _.toInteger(this.noteData.tag)});
                    let name = tag ? tag.tag : '';
                    // For shared tags
                    if(!tag){
                        this.$http.get(Routing.generate('get_tags', {tagIds: [this.noteData.tag]}))
                            .then(response => {
                               if(response.data[0]){
                                   name = response.data[0].label;
                               }
                            })
                    }
                    return name;
                }
            }
        },
        props: {
            note: {
                required: true,
                type: Object
            },
            isNewXml: {
                required: false,
                type: Boolean
            }
        },
        mixins: [ContentActions],
        methods: {
            toggleNote(data) {
                if (!_.isUndefined(data.mouse)) {
                    this.mouse = data.mouse;
                }
                if (data.event) {
                    if (this.isNewXml) {
                        this.top = data.event.layerY-(document.getElementById('notePreview'+this.note.id).offsetHeight+15);
                        this.left = data.event.layerX-(document.getElementById('notePreview'+this.note.id).offsetWidth/2);
                    } else {
                        // Get width of PA sidebar and check to see if we have env banner.
                        let offsetX = document.getElementById('notePreview'+this.note.id).offsetWidth/2,
                            offsetY = document.getElementById('notePreview'+this.note.id).offsetHeight+15,
                            envBanner = document.getElementById('environment_banner');
                        if (!_.isNull(envBanner)) {
                            offsetY = offsetY + envBanner.clientHeight;
                        }
                        this.top = data.event.pageY - offsetY;
                        this.left = data.event.pageX - offsetX;
                    }
                }

                if (this.mouse === true && data.modal === false || _.isUndefined(data.modal)) {
                    return false;
                }
                this.card = data.modal;
            }
        },
        mounted() {
            this.noteData = this.note;
            EventBus.listen(
                'toggle-note-preview-'+this.note.annotation.id,
                (data) => this.toggleNote(data)
            );
            EventBus.listen(
                'update-note-preview-'+this.note.annotation.id,
                (data) => this.noteData = data
            );
        }
    }
</script>

<style lang="scss">
    .note-preview {
        p {
            margin-bottom: 0!important;
        }
    }
</style>
