export default {
    data() {
        return {
            pagination: {
                descending: true,
                page: 1,
                itemsPerPage: 10,
                sortBy: '',
                totalItems: 0,
                rowsPerPageItems: [10, 20, 30, 50, 50, 100, 250]
            },
            subscriptions: [],
            loading: true,
            int: false,
            expand: false
        }
    },
    watch: {
        'pagination.page': function () {
            this.getSelfAssignedSubscription();
        },
        'pagination.itemsPerPage': function () {
            this.getSelfAssignedSubscription();
        }
    },
    methods: {
        getSelfAssignedSubscription: function () {
            this.loading = true;
            if (this.int) {
                this.$scrollTo(document.getElementById('license-configuration'), {duration: 0, offset: 66,});
                this.$nextTick(() => {
                    this.subscriptions = [];
                });
            } else {
                this.subscriptions = [];
            }
            let paginationData = _.pick(this.pagination, ['descending', 'page', 'sortBy', 'itemsPerPage']);
            paginationData.rowsPerPage = paginationData.itemsPerPage;
            delete paginationData.itemsPerPage;
            this.$http.post(Routing.generate('get_subscription_paginated', {
                userId: this.userId,
                pagination: paginationData
            })).then(response => {
                this.subscriptions = [];
                for(let i=0; i<=response.data.subscriptions.length; i++){
                    if(response.data.subscriptions[i] !== undefined &&
                        this.getAvailableLicense(response.data.subscriptions[i].group_licenses) > 0){
                        this.subscriptions.push(response.data.subscriptions[i]);
                    }
                }
                this.subscriptions = response.data.subscriptions;
                this.pagination.totalItems = response.data.resultTotal;
                this.loading = false;
                this.int = true;
            });
        },
        getAvailableLicense(licenses){
            let result =0;
            for(let i =0; i<= licenses.length; i++){
                if(licenses[i] !== undefined){
                    result = result + licenses[i].license_configuration.available;
                }
            }

            return result;
        },
        bookLink(book) {

            // No click action for premium complete book
            if (!_.isUndefined(book.content_type) && 'Premium Complete' === book.content_type.name) {
                return false;
            }

            if (book.document_slug) {
                window.location.href = Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug
                });
            } else {
                window.location = Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': 'toc'
                });
            }
        },

        checkStatus(item){
            let currentDate = new Date(),
                expirationDate = new Date(item.expiration_date),
                msg = '';
            if(currentDate <= expirationDate){
                msg = 'Active';
            }else {
                msg = 'Expired';
            }
            return msg;
        },
    }
}
