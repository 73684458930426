var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticClass: "title-reference",
    attrs: {
      solo: "",
      "hide-details": "",
      items: _vm.vSelectData,
      "item-text": "printing_text",
      "item-value": _vm.itemIdentifier,
      placeholder: "Versions",
      flat: "",
      dense: "",
      "menu-props": { offsetY: true },
    },
    on: { change: _vm.gotToTitle },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ active, item, attrs, on }) {
          return [
            _vm.isExam
              ? _c(
                  "v-row",
                  { staticClass: "mb-0 options-width pb-2 pt-2" },
                  [
                    item.isAvailableOnDigitalCodes
                      ? _c("v-col", [
                          _vm._v(
                            "\n                " + _vm._s(item.title) + " "
                          ),
                          _c("br"),
                          item.id === _vm.selected
                            ? _c("span", [_vm._v("(Currently Viewing)")])
                            : _vm._e(),
                        ])
                      : _c("v-col", { staticClass: "disabled-text" }, [
                          _vm._v(
                            "\n                Reference Not Available through Digital Codes\n            "
                          ),
                        ]),
                  ],
                  1
                )
              : _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.print_version_edition.version) +
                          ": " +
                          _vm._s(item.printing_text) +
                          " "
                      ),
                      item.document_id === _vm.selected
                        ? _c("span", [_vm._v("(Currently Viewing)")])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
          ]
        },
      },
      {
        key: "selection",
        fn: function ({ active, item, attrs, on }) {
          return [
            _vm.isExam
              ? _c("p", { staticClass: "mb-0 caption primary--text" }, [
                  _vm._v("\n            Change Reference Title\n        "),
                ])
              : _c("p", { staticClass: "mb-0 fs-14" }, [
                  _vm._v("\n            Version: \n            "),
                  _c(
                    "span",
                    { staticClass: "black--text" },
                    [
                      _vm._v(
                        "\n              " + _vm._s(item.printing_text) + " "
                      ),
                      item.latest_version_id !== item.id
                        ? [_vm._v("(Historical)")]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }