<template>
    <div class="content-block-wrapper mt-5">

        <v-progress-linear
                v-if="isLoading"
                :size="32"
                color="primary"
                indeterminate
        ></v-progress-linear>

        <template v-else>

            <div class="content-block-header primary pa-3 white--text rounded-t-lg d-flex align-center cursor-pointer"
                 @click="clickedExpand"
                 :class="isExpanded ? 'rounded-t-lg' : 'rounded-lg'" :id="name">

                <v-icon large color="white"
                        class="mr-2">
                    <template v-if="isExpanded">
                        mdi-chevron-up
                    </template>
                    <template v-else>
                        mdi-chevron-down
                    </template>
                </v-icon>

                <slot name="title"></slot>
                <div v-if="!hasTitleSlot" class="font-weight-bold">{{ this.title }}</div>
            </div>

            <v-expand-transition>
                <div v-show="isExpanded" class="content-block-content pa-3 rounded-b-lg elevation-2">
                    <slot></slot>
                </div>
            </v-expand-transition>
        </template>

    </div>
</template>

<script>
import profilePreferences from './inc/mixins/profilePreferences.mixin';

export default {
    name: 'SectionCollapsible',
    props: {
        name: {
            type: String,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: false,
            default: true
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: 'Untitled'
        },
    },
    mixins: [
        profilePreferences
    ],
    data() {
        return {
            isExpanded: true,
            PremiumAccessContent: this.$getConst('links').premiumAccessContents,
            premiumSubscription: this.$getConst('links').premiumSubscription,
            PremiumTitles: this.$getConst('links').PremiumTitles,
        }
    },
    computed: {
        rememberStateKey() {
            return `section:${this.name}`;
        },
        hasTitleSlot() {
            return !!this.$slots['title'];
        }
    },
    methods: {
        clickedExpand(event) {
            //if user redirecting programitically then always display section
            this.isExpanded = event.pointerId !== -1 ? !this.isExpanded : true;
            this.userPreferencesData.libraryPage[this.rememberStateKey] = this.isExpanded;
            this.saveUserProfilePreference();
        },

    },
    created() {
        this.$store.watch(() => this.$store.getters.getUser, (user) => {
            if (user) {
                this.loadProfilePreferences();
                this.isExpanded = this.userPreferencesData.libraryPage[this.rememberStateKey] ?? true;
            }
        }, {
            immediate: true
        });
    }
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}

.content-block-header {
    font-size: 1.25rem;
}

.content-block-content {
    border: 1px solid #0b5940 !important;
}
</style>
