<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    Personal Use licenses are those where the individual who purchases the premium subscription is the individual who is able to use the premium subscription.  Personal use licenses provide dedicated access to the individual as identified by the email address, but can be configured to be assigned to another individual or made concurrent.
                </p>
            </v-col>
        </v-row>
        <v-expansion-panels accordion>
            <v-data-iterator
                :items="subscriptions"
                :items-per-page="pagination.rowsPerPage"
                :loading="loading"
                :single-expand="true"
                :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                class="elevation-0 no-margin parent-row">
                <template v-slot:item="data">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row
                                dense
                                align="center">
                                <v-col cols="auto">
                                    <a @click="bookLink(data.item.document_info)">
                                        <graphic
                                            :title="data.item.document_info"
                                            thumbnail
                                            width="40px"
                                            additionalClasses="mr-2">
                                        </graphic>
                                    </a>
                                </v-col>
                                <v-col>
                                    <a @click="bookLink(data.item.document_info)">
                                        <book-title
                                            :title="getBookTitle(data.item.document_info)"
                                            :print_version_edition="printEdition(data.item.document_info.print_version_edition.version)"
                                            :printing_text="data.item.document_info.print_info"></book-title>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="grey lighten-4">
                            <v-data-table
                                :headers="license"
                                :items="data.item.group_licenses"
                                hide-default-footer
                                class="no-margin details-row">
                                <template v-slot:item="{ item }">
                                    <tr class="green lighten-5 text-center">
                                        <td>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Licenses</p>
                                                    {{item.license_configuration.available}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Type</p>
                                                    {{checkSubscriptionType(item.subscription_type)}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                    {{checkStatus(item)}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Purchase Date</p>
                                                    {{item.purchase_date | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                    {{item.expiration_date | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn
                                                        small
                                                        @click="goToTheShop(shopEncodesUrl)">
                                                        <span v-if="item.subscription_type === 'M'">Manage</span>
                                                        <span v-else>Renew</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </template>
            </v-data-iterator>
        </v-expansion-panels>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import PersonalUse from '../mixins/personal_use.js';
    import Graphic from "../../../titles/cover/Graphic";
    import Constants from "../../../../constants/index";

    export default {
        mixins: [Licenses, PersonalUse],
        components: {
            Graphic
        },
        data() {
            return {
                expand: false,
                title_details: [
                    {text: 'Book Title', align: 'left', value: 'di.title', expand: true},
                ],
                shopEncodesUrl: Constants.shopEncodesUrl
            }
        },
        mounted() {
            this.getSelfAssignedSubscription();
        }
    }

</script>
