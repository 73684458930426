<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        :style="dialogStyle"
        content-class="mobile-chapter-content">
        <div id="mobile-chapter-header" class="white">
            <v-divider></v-divider>
            <v-row class="px-3">
                <v-col class="py-2">
                    <v-row>
                        <v-col>
                            <h4>
                                <a
                                    class="black--text"
                                    @click="bookLink(documentInfo)">{{ getBookTitle(documentInfo) }}</a>
                            </h4>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                small
                                icon
                                @click="dialog = false"
                                class="close">
                                <v-icon class="black--text">clear</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense align="end" v-if="documentInfo && isSingleTitle(documentInfo) && '' === examId">
                        <v-col class="pt-0">
                            <adoption-profile 
                                v-if="documentInfo.title_type === 'Custom Code'" 
                                :documentInfo="documentInfo">
                            </adoption-profile>
                            <template v-if="documentInfo.supports_versions">
                                <title-reference
                                    :document-id="documentInfo.document_id">
                                </title-reference>
                            </template>
                        </v-col>
                        <v-col class="pt-0" cols="auto">
                            <favorite-icon
                                v-if="documentInfo && isSingleTitle(documentInfo) && '' === examId"
                                :document_id="documentInfo.document_id"
                                :show-message="false">
                            </favorite-icon>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-tabs
                v-if="showTabs"
                v-model="activeTab"
                height="40px"
                grow
                color="primary"
                >
                <v-tab
                    :class="activeTab === 0 ? 'primary--text' : 'grey--darken-1'">
                    <v-icon class="pr-1" v-if="isContentRestricted(documentInfo) && '' === examId">
                        mdi-lock-outline
                    </v-icon>
                    Contents
                </v-tab>
                <v-tab
                    v-on:click="showUserNotes()"
                    :class="activeTab === 1 ? 'primary--text' : 'grey--darken-1'">
                    <v-icon class="pr-1" v-if="freeView">
                        mdi-lock-outline
                    </v-icon>
                    Notes
                </v-tab>
                <v-tab
                    v-if="isCodeInsightsFilterEnabled() && showInsightTab"
                    v-on:click="showUserNotes()"
                    :class="activeTab === 2 ? 'primary--text' : 'grey--darken-1'">
                    <v-icon class="pr-1" v-if="freeView">
                        mdi-lock-outline
                    </v-icon>
                    Insights
                </v-tab>
            </v-tabs>
        </div>
        <div id="chapter-wrapper-mobile" class="white">
            <keep-alive>
                <chapter-panel
                    v-if="activeTab === 0"
                    :document-info="documentInfo"
                    :exam-id="examId">
                </chapter-panel>
                <notes-panel
                    v-else-if="activeTab === 1"
                    :free-view="freeView"
                    :document-info="documentInfo">
                </notes-panel>
                <panel-insights
                    v-else-if="activeTab === 2 && isCodeInsightsFilterEnabled() && showInsightTab"
                    :codeInsightsData="codeInsightsData"
                    :free-view="freeView"
                    :document-info="documentInfo">
                </panel-insights>
            </keep-alive>
        </div>
    </v-dialog>
</template>

<script>
    import ChapterPanel from './PanelChapters.vue';
    import NotesPanel from './PanelNotes.vue';
    import PanelInsights from "./PanelInsights.vue";
    import TitleReference from '../partials/titleReference.vue';
    import FavoriteIcon from '../../content/favorite.vue';
    import AdoptionProfile from "./AdoptionProfile.vue";
    import IndexMixin from './mixins/index'
    import Legend from './../inc/mixins/legend.js';
    import LegendNavigation from './../inc/mixins/legendNavigation';
    import {PremiumBonusInsightsApi} from "../../../api/PremiumBonusInsights";

    export default {
        data() {
            return {
                mobileView: true,
                dialog: false,
                activeTab: 0,
                heightSet: false,
                codeInsightsData: {}
            };
        },
        computed: {
            showInsightTab() {
                if (!_.isUndefined(this.codeInsightsData.data)
                    && !_.isEmpty(this.codeInsightsData.data.premiumContentTypes)) {
                    return true;
                } else {
                    return false;
                }
            },
            dialogStyle() {
            return {
                marginTop: this.dialog ? '64px' : '0', // Adjust the top margin as needed
            };
            },
        },
        mixins: [
            IndexMixin,
            Legend,
            LegendNavigation
        ],
        components: {
            ChapterPanel,
            NotesPanel,
            PanelInsights,
            TitleReference,
            FavoriteIcon,
            AdoptionProfile 
        },
        methods: {
            setChapterWrapperHeight() {
                // Grab our header height and set chapter view height.
                let chapterHeight = 0,
                    offsetTop = document.getElementById('mobile-chapter-header').offsetHeight,
                    chapterWrapper = document.getElementById('chapter-wrapper-mobile');
                chapterHeight = window.innerHeight - offsetTop;
                chapterWrapper.style.height = chapterHeight+'px';
                this.heightSet = true;
            },
            async getInsights() {
                this.codeInsightsData = await PremiumBonusInsightsApi.getInsightsSidebar(this.documentInfo.document_id);
            }
        },
        mounted() {
            this.getInsights();
        },
        created() {
            EventBus.listen('toggle-mobile-legend', (data) => this.dialog = data);
        },
        updated() {
            this.$nextTick(function () {
                if (!this.heightSet && this.dialog) {
                    this.setChapterWrapperHeight();
                }
            })
        }
    }
</script>

<style lang="scss">
    #chapter-wrapper-mobile {
        overflow-y: scroll;
    }
    #mobile-chapter-header {
        .v-tabs {
            .v-tabs__container {
                height: auto;
            }
            .active-tab-border {
                border: 1px solid #EEEEEE!important;
            }
            .v-tab{
                font-weight: 600 !important;
                letter-spacing: normal;
            }
        }
        .v-slide-group__prev,.v-slide-group__next{
            display: none;
        }
        .v-tab--active{
            background: unset !important;
        }
    }
    
</style>
