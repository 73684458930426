var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", { staticClass: "text-body-2 font-weight-bold" }, [
              _vm._v(
                "\n                Comments (" +
                  _vm._s(_vm.comments.length) +
                  ")\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.notificationMessage
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("alert", {
                    attrs: {
                      message: _vm.notificationMessage,
                      notificationType: _vm.notificationType,
                      dismissible: "",
                    },
                    on: { dismiss: _vm.dismissAlert },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.comments, function (comment, key) {
        return _c("comment", { key: key, attrs: { comment: comment } })
      }),
      _vm._v(" "),
      _vm.canComment
        ? [
            _vm.addComment
              ? [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "32", color: "grey lighten-3" } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.userNameInitials) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Add a comment",
                              outlined: "",
                              "hide-details": "",
                              dense: "",
                            },
                            model: {
                              value: _vm.userComment,
                              callback: function ($$v) {
                                _vm.userComment = $$v
                              },
                              expression: "userComment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled:
                                  !_vm.userComment.length ||
                                  _vm.submittingComment,
                                depressed: "",
                                color: "primary",
                                loading: _vm.submittingComment,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.postComment()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Submit\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.addComment = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Cancel\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { depressed: "" },
                            on: {
                              click: function ($event) {
                                _vm.addComment = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Add Comment\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }