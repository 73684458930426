var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error
        ? _c("alert-bar", {
            staticClass: "mt-2",
            attrs: { message: _vm.error, notificationType: "error" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-autocomplete", {
        attrs: {
          dense: "",
          items: _vm.jurisdictionItems,
          "hide-details": "",
          outlined: "",
          label: "Jurisdiction",
          loading: _vm.isLoading,
          disabled: _vm.isLoading,
        },
        on: { change: _vm.changeCodeCycleLocation },
        model: {
          value: _vm.selectedCodeCycleLocation,
          callback: function ($$v) {
            _vm.selectedCodeCycleLocation = $$v
          },
          expression: "selectedCodeCycleLocation",
        },
      }),
      _vm._v(" "),
      _c("v-autocomplete", {
        staticClass: "mt-4",
        attrs: {
          dense: "",
          items: _vm.yearItems,
          label: "Year",
          outlined: "",
          "hide-details": "",
          loading: _vm.isLoading,
          disabled: _vm.isLoading,
        },
        on: { change: _vm.changeCodeCycleYear },
        model: {
          value: _vm.selectedCodeCycleYear,
          callback: function ($$v) {
            _vm.selectedCodeCycleYear = $$v
          },
          expression: "selectedCodeCycleYear",
        },
      }),
      _vm._v(" "),
      "" !== _vm.locationNote
        ? [
            _c("p", { staticClass: "mt-4 mb-0" }, [
              _vm._v("\n      " + _vm._s(_vm.locationNote) + "\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "primary--text mt-1 font-weight-bold cursor-pointer d-flex align-center",
                on: {
                  click: function ($event) {
                    _vm.showLocationNoteModal = true
                  },
                },
              },
              [
                _vm._v("\n      Location Note\n      "),
                _c(
                  "v-icon",
                  { staticClass: "ml-1", attrs: { color: "primary" } },
                  [_vm._v("\n        mdi-help-circle-outline\n      ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "confirm-dialog",
              {
                attrs: {
                  "is-open": _vm.showLocationNoteModal,
                  title: "Location Note",
                },
                on: {
                  closed: function ($event) {
                    _vm.showLocationNoteModal = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "w-full d-flex justify-center" },
                            [
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showLocationNoteModal = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Close\n            "
                                    ),
                                  ]
                                ),
                              ],
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  101612737
                ),
              },
              [
                _c("div", { staticClass: "mt-4" }, [
                  _vm._v(
                    "\n        When you select either “I-Codes” or choose a US state, this message tells you which codes AI Navigator\n        is using for its answers. As the ICC trains ICC AI Navigator on more custom code sets, messages will\n        reference specific state codes. If a state uses only the I-Codes, or if AI Navigator is not yet trained\n        on that states amended codes, this message will tell you that AI Navigator is using the best matched\n        I-Codes for that state.\n      "
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }