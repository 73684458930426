<template>
    <v-row>
        <v-col>
            <h4 :class="{'fs-16' : displayBigger}">License Quantity</h4>
            <v-row class="my-1" align="center" dense>
                <v-col cols="auto">
                    <v-btn
                        icon
                        small
                        color="primary"
                        :disabled="quantity === 1 || actionDisabled"
                        @click="decrementQuantity">
                        <v-icon large>mdi-minus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-text-field
                        class="center-aligned-input"
                        outlined
                        :value="quantity"
                        @input="updateQuantity"
                        hide-details
                        dense
                        style="max-width: 50px;"
                    />
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        :disabled="actionDisabled"
                        icon
                        small
                        color="primary"
                        @click="incrementQuantity">
                        <v-icon large>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        actionDisabled: {
            required: false,
            type: Boolean,
            default: false
        },
        displayBigger: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('PricingModule', ['quantity']),
    },
    methods: {
        ...mapActions('PricingModule', ['setQuantity']),
        decrementQuantity() {
            if (this.quantity > 1) {
                this.setQuantity(this.quantity - 1);
            }
        },
        incrementQuantity() {
            this.setQuantity(this.quantity + 1);
        },
        updateQuantity(value) {
            const quantity = parseInt(value, 10);
            if (quantity >= 1) {
                this.setQuantity(quantity);
            }
        },
    },
};
</script>
<style>
.center-aligned-input .v-input__control .v-input__slot input {
    text-align: center;
}
</style>
