export default {
    data() {
        return {
            errors: [],
        }
    },
    computed: {
        hasError() {
            return this.errors.length > 0;
        },
        loginFailedCount: {
            get: function () {
                return this.$store.getters.getLoginFailedCount;
            },
            set: function (value) {
                this.$store.commit('setLoginFailedCount', value);
            }
        }        
    },
}
