var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "chat-message",
        { staticClass: "mb-1", attrs: { type: "system" } },
        [
          _vm.response
            ? _c(
                "div",
                [
                  _c("text-typed", {
                    attrs: { text: _vm.responseText, stopped: _vm.clickedStop },
                    on: {
                      start: _vm.onServerTypingStart,
                      done: _vm.onServerTypingDone,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isTyping &&
                            !_vm.clickedStop &&
                            _vm.responseSections,
                          expression:
                            "!isTyping && !clickedStop && responseSections",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold text-sm mt-3 mb-1" },
                        [_vm._v("Referenced sections")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-chip-group",
                        {
                          attrs: { column: "" },
                          model: {
                            value: _vm.sectionSelected,
                            callback: function ($$v) {
                              _vm.sectionSelected = $$v
                            },
                            expression: "sectionSelected",
                          },
                        },
                        _vm._l(_vm.responseSections, function (chip, index) {
                          return _c(
                            "v-chip",
                            {
                              key: index,
                              class:
                                chip.meta_xmlId === _vm.sectionSelected
                                  ? "text--white"
                                  : "primary--text",
                              attrs: {
                                value: chip.meta_xmlId,
                                text: _vm.displayChipInfo(chip),
                                small: "",
                                color:
                                  chip.meta_xmlId === _vm.sectionSelected
                                    ? "primary"
                                    : "primaryLight",
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "ai-reference-section",
                                domProps: {
                                  innerHTML: _vm._s(_vm.displayChipInfo(chip)),
                                },
                              }),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("section-content-proxy", {
                        attrs: { "section-meta": _vm.selectedChip },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.clickedStop
            ? _c("div", [
                _vm._v(
                  "\n            Response generation was stopped, try entering a new question.\n        "
                ),
              ])
            : _vm.error
            ? _c("div", { staticClass: "red--text" }, [
                _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
              ])
            : _vm.isWaitingServerResponse
            ? _c("text-typed", {
                attrs: {
                  text: _vm.randomThinkingText,
                  stopped: _vm.clickedStop,
                  speed: 5,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isWaitingServerResponse || _vm.isTyping
        ? _c(
            "div",
            { staticClass: "server-message-stop" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: "" },
                  on: { click: _vm.stopGeneratingClicked },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-close")]),
                  _vm._v("\n            Stop generating response\n        "),
                ],
                1
              ),
            ],
            1
          )
        : _vm.responseText && !_vm.clickedStop
        ? _c(
            "div",
            {
              staticClass: "server-message-rating text-sm d-flex align-center",
            },
            [
              _c("strong", [_vm._v("Rate this answer")]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rateAnswerClicked(true)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.userFeedback === 1
                          ? "mdi-thumb-up"
                          : "mdi-thumb-up-outline"
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.rateAnswerClicked(false)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.userFeedback === -1
                          ? "mdi-thumb-down"
                          : "mdi-thumb-down-outline"
                      )
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }