var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          key: _vm.modalKey,
          attrs: { persistent: "", "max-width": "800px", scrollable: "" },
          model: {
            value: _vm.assignModal,
            callback: function ($$v) {
              _vm.assignModal = $$v
            },
            expression: "assignModal",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v(
                    "\n                Assign Subscription\n                "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmCloseIfStarted()
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper",
                {
                  key: _vm.modalKey,
                  model: {
                    value: _vm.step,
                    callback: function ($$v) {
                      _vm.step = $$v
                    },
                    expression: "step",
                  },
                },
                [
                  _c(
                    "v-stepper-header",
                    { staticClass: "steps_header" },
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.step > 1,
                            step: "1",
                            rules: [() => _vm.rules.email],
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Enter Email\n                        "
                          ),
                          _c("p", [
                            _vm.step > 1 && _vm.emails
                              ? _c(
                                  "small",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                                Email(s): " +
                                        _vm._s(_vm.emails.length) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm.errorMessage
                              ? _c("small", [_vm._v(_vm._s(_vm.errorMessage))])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.step > 2,
                            step: "2",
                            rules: [() => _vm.rules.subscriptions],
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Select Books\n                        "
                          ),
                          _c("p", [
                            _vm.step > 2 && _vm.select
                              ? _c(
                                  "small",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                                Subscriptions: " +
                                        _vm._s(_vm.select.length) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm.errorMessage
                              ? _c("small", [_vm._v(_vm._s(_vm.errorMessage))])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 3, step: "3" } },
                        [
                          _vm._v(
                            "\n                        Notify\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _vm._v(
                                            "\n                                        Type email address for whom you want to send.\n                                        "
                                          ),
                                          _c("p", [
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "font-weight-bold black--text",
                                              },
                                              [_vm._v("Note:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "black--text" },
                                              [
                                                _vm._v(
                                                  "To add multiple recipients, please add a comma after each email address, each Email address is requiring one license!"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              "data-qa":
                                                "dashboard-licenses-assign-email",
                                              solo: "",
                                              id: "emailId",
                                              label: "Enter email address",
                                            },
                                            model: {
                                              value: _vm.input_emails,
                                              callback: function ($$v) {
                                                _vm.input_emails = $$v
                                              },
                                              expression: "input_emails",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.validatedUsers.invalid
                                            ? _c(
                                                "small",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "red--text text--lighten-1 font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Invalid Email(s):"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.validatedUsers.invalid,
                                                    function (email) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(email) +
                                                              ",\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.validatedUsers.valid
                                            ? _c(
                                                "small",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "green--text text--lighten-1 font-weight-bold",
                                                    },
                                                    [_vm._v("Valid Emails:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.validatedUsers.valid,
                                                    function (email) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(email) +
                                                              ",\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.validateInputs()
                                        },
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c("span", [_vm._v("Continue")])
                                        : _c("v-progress-circular", {
                                            attrs: {
                                              size: 20,
                                              indeterminate: "",
                                              color: "white",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c("span", { staticClass: "black--text" }, [
                            _vm._v(
                              "Select subscriptions which you want to assign"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.rules.subscriptions === true && _vm.step <= 2
                            ? _c(
                                "p",
                                { staticClass: "black--text" },
                                [
                                  _c("small", [
                                    _vm._v(
                                      "This will only show subscriptions that have "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.emails.length)),
                                    ]),
                                    _vm._v(" or more available licenses!"),
                                  ]),
                                  _vm._v(" "),
                                  _vm.alreadyAssigned
                                    ? _c("alert", {
                                        attrs: {
                                          message: _vm.alreadyAssignedMessage,
                                          notificationType: "warning",
                                          dismissible: "",
                                        },
                                        on: {
                                          dismiss: function ($event) {
                                            _vm.alreadyAssigned = false
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          this.subscriptions.length !== 0
                            ? _c("v-combobox", {
                                attrs: {
                                  "data-qa":
                                    "dashboard-licenses-assign-subscription-select",
                                  solo: "",
                                  multiple: "",
                                  items: _vm.getSortedSubscriptions(),
                                  "item-text": "title",
                                  label: "Select subscriptions",
                                  chips: "",
                                  "deletable-chips": "",
                                  tags: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          [
                                            _c("v-list-item-avatar", [
                                              _c("img", {
                                                attrs: {
                                                  src: data.item.avatar,
                                                },
                                              }),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.title
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("v-list-item-subtitle", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      "Available licenses: " +
                                                        data.item
                                                          .licenses_available
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3442187601
                                ),
                                model: {
                                  value: _vm.select,
                                  callback: function ($$v) {
                                    _vm.select = $$v
                                  },
                                  expression: "select",
                                },
                              })
                            : _vm._e(),
                          _c("br"),
                          _vm._v(" "),
                          this.subscriptions.length !== 0
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.validateSubscriptions()
                                    },
                                  },
                                },
                                [_vm._v("Continue")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-qa":
                                  "dashboard-licenses-assign-back-button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateSelect()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Back\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c("p", { staticClass: "black--text" }, [
                            _vm._v("Leave a message and assign subscriptions"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-textarea",
                            {
                              attrs: {
                                "data-qa": "dashboard-licenses-assign-message",
                                solo: "",
                                color: "primary",
                              },
                              model: {
                                value: _vm.message,
                                callback: function ($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    "\n                                Message "
                                  ),
                                  _c("small", [_vm._v("(optional)")]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-qa": "dashboard-licenses-assign-button",
                                color: "primary",
                                disabled: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.assignSubscription()
                                },
                              },
                            },
                            [
                              !_vm.loading
                                ? _c("span", [_vm._v("Assign Subscriptions")])
                                : _c("v-progress-circular", {
                                    attrs: {
                                      size: 20,
                                      indeterminate: "",
                                      color: "white",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-qa":
                                  "dashboard-licenses-assign-back-button",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.step = 2
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Back\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.endAssignModalConfirm,
            callback: function ($$v) {
              _vm.endAssignModalConfirm = $$v
            },
            expression: "endAssignModalConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n                Please confirm\n            "),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "You have entered information that will not be saved when you exit this screen. Are you sure you want to leave?"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", light: "" },
                      on: {
                        click: function ($event) {
                          _vm.endAssignModalConfirm = false
                        },
                      },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { light: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("\n                    Yes\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }