<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="isMobilePort"
        persistent
        scrollable
        :max-width="500">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Login
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="mr-2 accent2--text"
                    @click="closeAndResetModal()">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-row v-if="message">
                    <v-col class="pt-3">
                        <p class="mb-0">
                            {{message}}
                        </p>
                    </v-col>
                </v-row>
                <login
                    :show-logo="false"
                    :isDense="true"
                    :isModal="true"
                    :from-cart="fromCart">
                </login>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    import Login from './../content/partials/login';

    export default {
        data() {
            return {
                dialog: false,
                message: '',
                fromCart: false
            }
        },
        mixins: [Mobile],
        components: {
            Login
        },
        methods: {
            renderModal(data) {
                if (data) {
                    if (data.message) {
                        this.message = data.message;
                    }
                    if (data.fromCart) {
                        this.fromCart = true;
                    }
                }
                this.dialog = true;
            },
            closeAndResetModal() {
                this.dialog = false;
                this.errors = [];
            }
        },
        created() {
            EventBus.listen('openLoginModal', (data) => this.renderModal(data));
        }
    }
</script>
