var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "650" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          !_vm.trialActive && !_vm.isPremiumComplete
            ? _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v("\n            Upgrade to Premium!\n            "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                close\n            ")]
                  ),
                ],
                1
              )
            : _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                close\n            ")]
                  ),
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "lighten-3 px-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-3", attrs: { align: "center" } },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: _vm.asset(
                              require("../../images/icc_digital_codes_premium.png")
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pt-0 pb-2" }, [
                    _c(
                      "p",
                      { attrs: { align: "center" } },
                      [
                        _vm.isPremiumComplete || _vm.trialActive
                          ? [
                              _vm._v(
                                "\n                            For content printing within Digital Codes Premium, please utilize the section level printing controls available within the Premium toolbar for each section.\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                            Printing is a feature of Digital Codes Premium.  To access printing capabilities, please subscribe to a Digital Codes Premium subscription.\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-2", attrs: { align: "center" } },
                    [
                      !_vm.trialActive && !_vm.isPremiumComplete
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    dark: "",
                                    color: "accent2",
                                    href: _vm.getRoute("title_landing", {
                                      documentSlug:
                                        _vm.documentInfo.document_slug,
                                    }),
                                    "data-aq": "start-premium-trial-button",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Upgrade to Premium\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dark: "",
                                    color: "accent2",
                                    "data-aq": "start-premium-trial-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToRoute("trial_signup")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Start Premium Trial\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { dark: "", color: "accent2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.quickStartUrl()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Quick Start\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }