<template>
    <v-row dense>
        <v-col v-if="$getConst('premiumCompleteBundleLevels').includes(accessLevel)">
            <h4>
                This title is bundled exclusively with a Premium Complete subscription.
            </h4>
            <p class="mb-0 pt-5">
                Click Learn More below to view subscription options for Premium Complete.
            </p>
        </v-col>
        <v-col v-else>
            <h4>
                This title is bundled exclusively with Premium Complete and Premium Collection subscriptions.
            </h4>
            <p class="mb-0 pt-5">
                Click Learn More below to view subscription options for available collections and Premium Complete.
            </p>
        </v-col>
    </v-row>
</template>
<script>

export default {
    props: {
        accessLevel: {
            required: true,
            type: Number
        }
    }
}
</script>
