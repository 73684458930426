import Url from '../../inc/mixins/url';
import Session from '../../inc/mixins/session';
import Mobile from '../../inc/mixins/mobile';

import Breadcrumb from "../../content/partials/breadcrumb";
import SearchNavigation from "../partials/navigation";
import BookCover from "../../titles/cover/Index";

export default {
    data() {
        return {
            categoryLegend: false,
            showMoreLoading: false,
            results: [],
            limit: 20,
            page: 1,
            itemsPerPage: -1,
            pagination: {
                currentPage: 1,
                totalResults: 0
            },
            activeCategory: null,
            activeSearchTerm: '',
            searchData: {
                category: null,
                astmSubject: null,
                page: 1,
                searchTerm: '',
                year: null
            },
            resultsLoading: true,
            isAstmSubjectPage: false,
            isAstmStandardPage: false,
            breadcrumbData: {
                title: 'Find Codes',
                breadcrumb: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: this.getRoute('index_landing')
                    },
                    {
                        text: 'Find Codes',
                        disabled: true,
                        href: ''
                    }
                ]
            }
        }
    },
    mixins: [
        Url,
        Session,
        Mobile
    ],
    components: {
        BookCover,
        Breadcrumb,
        SearchNavigation
    },
    computed: {
        categories() {
            let categories = [];
            const temp = this.$store.getters.getSearchNavigationCategories;
            const storeCategories = JSON.parse(JSON.stringify(temp));

            _.forEach(storeCategories.Category, function (value) {
                categories.push(value);
                if (_.size(value.children) > 0) {
                    _.forEach(value.children, function (value) {
                        categories.push(value);
                    });
                }
            });
            _.forEach(storeCategories.Location, function (value) {
                categories.push(value);
                if (_.size(value.children) > 0) {
                    _.forEach(value.children, function (value) {
                        categories.push(value);
                    });
                }
            });

            return categories;
        },
        showYearFilter() {
            return this.searchData.category &&
                this.searchData.category !== this.$getConst('astmCategory');
        }
    },
    watch: {
        searchData: {
            handler(value) {
                if (value.category) {
                    if (
                        value.category !== this.activeCategory ||
                        value.astmSubject !== this.activeAstmSubject
                    ) {
                        this.updateBreadcrumb(value.category);
                        this.updateUrl(value);
                        this.resetData();
                    }
                    this.isAstmSubjectPage = !value.searchTerm && value.category === this.$getConst('astmCategory') && !value.astmSubject;
                    this.isAstmStandardPage = value.category === this.$getConst('astmCategory') &&
                        !_.isEmpty(value.astmSubject);
                }
                if (value.searchTerm !== this.activeSearchTerm) {
                    this.updateUrl(value);
                }
                EventBus.fire('set-dropdown-category', value.astmSubject ? value.astmSubject : value.category);
                this.getResults();
            },
            deep: true
        }
    },
    methods: {
        setCategoryFromUrl() {
            let searchArray = {};
            let search = this.getUrlSearchParam();
            if (search) {
                this.$set(searchArray, 'searchTerm', search);
                this.defaultSearch = search;
                this.$store.commit('forgetDefaultSearch');
            }

            let urlParts = window.location.pathname.substring(1).split('/');
            let lastUrlPart = _.last(urlParts);
            let isAstm = _.includes(urlParts, this.$getConst('astmSlug'));
            if (isAstm && lastUrlPart !== this.$getConst('astmSlug')) {
                this.$set(searchArray, 'category', this.$getConst('astmCategory'));
                this.$set(searchArray, 'astmSubject', lastUrlPart);
                this.$session.set('categoryBreadcrumb', this.$getConst('astmCategory'));
                this.$session.set('categoryBreadcrumbSlug', this.$getConst('astmCategory'));
            } else {
                let category = _.find(
                    this.categories,
                    function (o) {
                        return _.toLower(o.slug) === _.toLower(
                            lastUrlPart
                        );
                    });
                if (category) {
                    this.$set(searchArray, 'category', category.name);
                    this.$session.set('categoryBreadcrumb', category.name);
                    this.$session.set('categoryBreadcrumbSlug', category.slug);
                    if (!this.$store.getters.getSearchNavigationCategoryNames.includes(category.name)) {
                        this.$set(searchArray, 'year', 'Current Adoption');
                    }
                }

            }

            if (!_.isEmpty(searchArray)) {
                this.updateSearchData({query: searchArray});
            } else {
                this.getResults();
            }
        },
        updateSearchData(data) {
            if (data.resetPage) {
                this.resetData()
            }

            const searchArray = Object.assign({}, this.searchData)
            if (_.has(data, 'category') && data.query.category !== this.activeCategory) {
                this.$set(searchArray, 'astmSubject', null);
                this.$set(searchArray, 'page', 1);
                this.$set(searchArray, 'searchTerm', '');
                this.$set(searchArray, 'year', null);
            }

            let _this = this;
            _.forEach(data.query, function(value, key) {
                if (_.has(searchArray, key)) {
                    _this.$set(searchArray, key, value);
                }
            });

            this.searchData = searchArray;
        },
        updateBreadcrumb(category) {
            let breadCrumbData = this.breadcrumbData;
            breadCrumbData.breadcrumb[1].text = category;

            let categories = this.$store.getters.getSearchNavigationCategories;
            let locations = categories ? categories.Location : null;

            if(locations) {
                if(_.find(locations, {'name': category})) {
                    category = category + ' Building Codes';
                }
            }

            breadCrumbData.title = category;
        },
        updateUrl(data) {
            const historyState = data.key;
            let url;
            let title = 'Building Codes - ICC Digital Codes';

            let search = '';
            if (data.searchTerm) {
                search = '?s=' + data.searchTerm;
            }

            let categoryObject = _.find(
                this.categories,
                function (o) {
                    return _.toLower(o.name) === _.toLower(
                        data.category
                    );
                });
            if (categoryObject) {
                if (data.astmSubject) {
                    url = this.getRoute(
                        'codes_astm_subject',
                        {
                            'category': categoryObject.slug,
                            'parentCategory': _.toLower(categoryObject.parentCategory),
                            'astmSubject': this.searchData.astmSubject
                        }
                    )
                } else if (categoryObject.parentCategory) {
                    url = this.getRoute(
                        'codes_category_parent',
                        {
                            'category': categoryObject.slug,
                            'parentCategory': _.toLower(categoryObject.parentCategory),
                        }
                    )
                } else {
                    url = this.getRoute(
                        'codes_category',
                        {
                            'category': categoryObject.slug,
                        }
                    )
                }
                title = categoryObject.name+' '+title;
            } else {
                url = this.getRoute('codes')
            }

            document.title = title;
            history.pushState(
                historyState,
                'ICC - Find Codes',
                url + search
            )
        },
        getUrlSearchParam() {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            return params.s ? params.s : null;
        },
        resetData(){
            this.page = 1;
            this.results = [];
        },
        showMore(){
            this.showMoreLoading = true;
            this.page = this.page + 1;
            this.getResults();
        },
        getResults() {
            let search = this.searchData;
            this.resultsLoading = this.page === 1;
            this.activeCategory = null;
            this.activeAstmSubject = null;

            this.$http.get(Routing.generate('search_results', {
                category: search.category ? [search.category] : [],
                astmSubject: search.astmSubject,
                page: this.page,
                limit: this.limit,
                search: search.searchTerm,
                year: search.year ? [search.year] : []
            })).then(response => {
                const newData = response.data.data;
                this.results.push(...newData);
                this.pagination = response.data.pagination;
                this.activeCategory = this.searchData.category;
                this.activeSearchTerm = this.searchData.searchTerm;
                this.activeAstmSubject = this.searchData.astmSubject;
                this.resultsLoading = false;
                this.showMoreLoading = false;
            });
        }
    },
    created() {
        if (this.isLargeAndUp) {
            this.categoryLegend = true;
        }
        EventBus.listen('trigger-category-legend', () => this.categoryLegend = !this.categoryLegend);
        EventBus.listen('update-search-data', (data) => this.updateSearchData(data));
    },
    mounted() {
        this.setCategoryFromUrl();
        this.$store.commit('setIsCodesPage', true)
    }
}
