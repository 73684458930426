var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      class: { "py-12": !_vm.isMobilePort },
      attrs: { id: "exam_resources", "no-gutters": "" },
    },
    [
      _c(
        "v-col",
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "primary d-flex justify-center" },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "white--text",
                      attrs: { id: "exams_header" },
                    },
                    [
                      _vm._v(
                        "\n                    PRONTO Exam Resources\n                "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "ma-0 pa-0" },
                [
                  _vm.persona === 3 || _vm.persona === 4
                    ? _c(
                        "v-sheet",
                        {
                          staticClass: "ma-3 px-3",
                          attrs: { outlined: "", rounded: "" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [
                                    _vm._v(
                                      "Important: Not all Exam References are available as Digital References."
                                    ),
                                  ]),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                Verify all references through MyICC on the "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.myExamLink,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("My Exams")]
                                  ),
                                  _vm._v(" or "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.myCatalogLink,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Exam Catalog")]
                                  ),
                                  _vm._v(
                                    " tab. Click on Exam Info next to the appropriate exam and review any non digital exam references. For those non-digital exam references, the candidate will be responsible for providing their own instance of the resource title.\n                            "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-sheet",
                    {
                      staticClass: "d-none d-md-block ma-3 pa-3 blue lighten-5",
                      attrs: { outlined: "", rounded: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [_c("h3", [_vm._v("Exam Name")])]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("h3", [_vm._v("Digital References")]),
                          ]),
                          _vm._v(" "),
                          _c("v-col"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.exams
                    ? _vm._l(_vm.exams, function (exam) {
                        return _c(
                          "v-sheet",
                          {
                            key: exam.examId,
                            staticClass: "exam-background ma-3 pa-3",
                            attrs: { outlined: "", rounded: "" },
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex align-center",
                                    attrs: { cols: "12", lg: "4" },
                                  },
                                  [
                                    _c("h3", { staticClass: "mb-0" }, [
                                      _vm._v(_vm._s(exam.examTitle)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4" } },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "pt-1 text-decoration-underline font-weight-bold",
                                      },
                                      [_vm._v("References")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      exam.references,
                                      function (reference, key) {
                                        return _c("div", { key: key }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-3 font-italic caption",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(reference.title) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-space-evenly",
                                    attrs: { cols: "12", lg: "4" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "button-text-wrap",
                                        attrs: {
                                          height: _vm.isMobilePort
                                            ? "50px"
                                            : "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToExamPreview(exam)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Simulate Exam Experience With Digital Codes\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { color: "primary" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "white--text",
                                            attrs: { href: _vm.examLink },
                                          },
                                          [_vm._v("Schedule Exam In MyICC")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-sheet",
                    {
                      staticClass: "exam-background ma-3 pa-3",
                      attrs: { outlined: "", rounded: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: "12", lg: "4" },
                            },
                            [
                              _c("h3", { staticClass: "mb-0" }, [
                                _vm._v(_vm._s(_vm.sampleExam.examTitle)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pt-1 text-decoration-underline font-weight-bold",
                                },
                                [_vm._v("References")]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.sampleExam.references,
                                function (reference) {
                                  return _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mb-3 font-italic caption",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(reference.titleName) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-column justify-space-evenly",
                              attrs: { cols: "12", lg: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "button-text-wrap",
                                  attrs: {
                                    height: _vm.isMobilePort ? "50px" : "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToExamPreview(_vm.sampleExam)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Simulate Exam Experience With Digital Codes\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }