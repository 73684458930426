var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-5 fill-height" },
    [
      _vm.provider === "youtube" && _vm.contentId
        ? _c("div", { staticClass: "video-container" }, [
            _c("iframe", {
              staticClass: "video",
              attrs: {
                src: "https://www.youtube.com/embed/" + _vm.contentId,
                title: "YouTube video player",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                allowfullscreen: "",
              },
            }),
          ])
        : [
            _c("p", [
              _vm._v("\n            Video failed to embed: "),
              _c("a", { attrs: { href: _vm.tagContent, target: "_blank" } }, [
                _vm._v(_vm._s(_vm.tagContent)),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }