var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 container-height" },
    [
      _c(
        "v-container",
        { class: !_vm.isMobileAndTablet ? "algin-center justify-center" : "" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "grey lighten-5",
                      attrs: {
                        flat: "",
                        width: _vm.isMobileAndTablet ? 800 : 700,
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-center" },
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "250px",
                              height: "95px",
                              src: _vm.asset(
                                require("../../images/enterprise/ent-premium-blue-logo.png"),
                                "default"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline font-weight-bold mb-3",
                          class: _vm.isMobileAndTablet ? "ml-4" : "",
                        },
                        [
                          _vm._v(
                            "\n                    Create your Digital Code account\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "subtitle-1 mb-0",
                          class: _vm.isMobileAndTablet ? "ml-4" : "",
                        },
                        [
                          _c("p", { staticClass: "black--text" }, [
                            _vm._v(
                              "\n                        Welcome " +
                                _vm._s(_vm.inviteFirstname) +
                                ", you have been invited to join\n                        "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.inviteEnterprise))]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("registration-form", {
                            attrs: {
                              isEnterprise: true,
                              enterpriseData: _vm.enterpriseData,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }