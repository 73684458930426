<template class="pb-5">
    <div>
        <v-container class="yellow lighten-5">
            <v-row>
                <v-col cols="auto" class="pb-0">
                    <v-icon
                        small
                        color="primary">
                        mdi-check-circle
                    </v-icon>
                </v-col>
                <v-col class="pb-0">
                    <p class="mb-0 primary--text">
                        You have an active premium subscription
                        to access this {{ isCurrentDocumentCollection ? 'collection' : 'title' }}
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <p class="mb-0">
                        Your subscription allows you to access all of the digital library
                        content with exclusive features like My Library, Advanced search tool,
                        Notes tool and much more
                    </p>
                </v-col>
            </v-row>
            <a
                class="my-2"
                target="_blank"
                href="https://support.iccsafe.org/?ht_kb_category=digital-codes">
                <v-row>
                    <v-col cols="auto" class="pb-0">
                        <v-icon small class="primary--text">
                            mdi-book-variant-multiple
                        </v-icon>
                    </v-col>
                    <v-col class="pb-0">
                        <span class="primary--text font-weight-bold">
                            Quick Start Guide
                        </span><br/>
                        <span class="caption black--text"> Quick Start Guide for Digital Codes</span>
                    </v-col>
                    <v-col cols="auto" class="pb-0">
                        <v-icon
                            small
                            color="accent2">
                                mdi-arrow-right
                        </v-icon>
                    </v-col>
                </v-row>
            </a>
            <a
                class="my-2"
                @click="openRecentUpdatesModal">
                <v-row>
                    <v-col cols="auto" class="pb-0">
                        <v-icon small class="primary--text">
                            mdi-bullhorn
                        </v-icon>
                    </v-col>
                    <v-col class="pb-0">
                        <span class="primary--text font-weight-bold">
                            What's New in Premium
                        </span><br/>
                        <span v-for="(item, key) in updates"
                            :key="key"
                            class="caption black--text">
                            {{ item.title }}
                        </span><br/>
                    </v-col>
                    <v-col cols="auto" class="pb-0">
                        <v-icon
                            small
                            color="accent2">
                                mdi-arrow-right
                        </v-icon>
                    </v-col>
                </v-row>
            </a>
            <a
                class="my-2"
                :href="this.getRoute('webinars')"
                target="_blank">
                <v-row>
                    <v-col cols="auto" class="pb-0">
                        <v-icon small class="primary--text">
                            mdi-laptop
                        </v-icon>
                    </v-col>
                    <v-col class="pb-0">
                        <span class="primary--text font-weight-bold">
                            Premium Webinar
                        </span><br/>
                        <span class="caption black--text"> Click to Register Now</span>
                    </v-col>
                    <v-col cols="auto" class="pb-0">
                        <v-icon
                            small
                            color="accent2">
                                mdi-arrow-right
                        </v-icon>
                    </v-col>
                </v-row>
            </a>
        </v-container>
        <v-container class="pt-0">
            <v-row>
                <v-col class="pb-0">
                    <a @click="showPriceComponent">
                        <v-row>
                            <v-col cols="auto" class="pb-0 cdpBlue--text font-weight-bold">
                                <span>
                                    Additional Licenses
                                </span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="pb-0">
                                <v-icon right>
                                    {{menu ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                </v-icon>
                            </v-col>
                        </v-row>                        
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                updates: [],
                menu: false,
            };
        },
        computed: {
            isCurrentDocumentCollection() {
                const contentType = this.$store.getters.getDocumentInfo['content_type']?.name;
                return contentType === 'Collection' || contentType === 'Premium Complete';
            }
        },
        methods: {
            openRecentUpdatesModal() {
                EventBus.fire('show-notification-modal');
            },
            showPriceComponent() {
                this.menu = this.menu ? false : true;
                EventBus.fire('show-price-component', this.menu);
            }
        },
        created() {
            this.$http.get(Routing.generate('get_updates')).then(
                response => {
                    if (response.data) {
                        this.updates = response.data;
                    }
                }
            );
        }
    }
</script>
