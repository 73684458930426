var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", scrollable: "" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        {
          style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-4 pb-2" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mt-n2", attrs: { color: "accent2" } },
                        [
                          _vm._v(
                            "\n                        mdi-creation\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "h2",
                      { staticClass: "fs-20 font-weight-bold text-break" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.title ? _vm.title : "Add to Cart") +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-n2",
                          attrs: { small: "", icon: "" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _c("v-icon", { attrs: { color: "accent" } }, [
                            _vm._v(
                              "\n                            mdi-close\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.modalLoading
            ? _c(
                "v-card-text",
                [_c("v-row", [_c("v-col", [_c("loading-linear")], 1)], 1)],
                1
              )
            : _c(
                "v-card-text",
                { staticClass: "px-4", attrs: { id: "addToCartText" } },
                [
                  _c("alert-bar", {
                    staticClass: "mt-2",
                    attrs: {
                      message: _vm.message,
                      "notification-type": _vm.type,
                    },
                    on: {
                      dismiss: function ($event) {
                        ;[(_vm.message = null), (_vm.type = null)]
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("h4", { staticClass: "mt-1" }, [
                            _vm._v(
                              "\n                        Billing Frequency\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn-toggle",
                            {
                              staticClass: "mt-2 rounded",
                              attrs: {
                                tile: "",
                                "active-class": "primary white--text",
                              },
                              model: {
                                value: _vm.frequency,
                                callback: function ($$v) {
                                  _vm.frequency = $$v
                                },
                                expression: "frequency",
                              },
                            },
                            [
                              _vm.availableFrequencies.includes("M")
                                ? _c(
                                    "v-btn",
                                    {
                                      class: {
                                        "primary--text": _vm.frequency !== "M",
                                      },
                                      style: `border: 1px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`,
                                      attrs: { outlined: "", value: "M" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Monthly\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.availableFrequencies.includes("B")
                                ? _c(
                                    "v-btn",
                                    {
                                      class: {
                                        "primary--text": _vm.frequency !== "B",
                                      },
                                      style: `border: 1px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`,
                                      attrs: { outlined: "", value: "B" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Annually\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.availableFrequencies.includes("C")
                                ? _c(
                                    "v-btn",
                                    {
                                      class: {
                                        "primary--text": _vm.frequency !== "C",
                                      },
                                      style: `border: 1px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`,
                                      attrs: { outlined: "", value: "C" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            3-Year\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("h4", [
                            _vm._v(
                              "\n                        Number of Licenses\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "my-1",
                              attrs: { align: "center", dense: "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "primary",
                                        disabled: _vm.quantity === 1,
                                      },
                                      on: { click: _vm.decrementQuantity },
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v(
                                          "\n                                    mdi-minus\n                                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-text-field", {
                                    staticStyle: { "max-width": "50px" },
                                    attrs: {
                                      outlined: "",
                                      "hide-details": "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.quantity,
                                      callback: function ($$v) {
                                        _vm.quantity = $$v
                                      },
                                      expression: "quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.quantity++
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v(
                                          "\n                                    mdi-plus\n                                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-0",
                      model: {
                        value: _vm.options,
                        callback: function ($$v) {
                          _vm.options = $$v
                        },
                        expression: "options",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("h4", [
                              _vm._v(
                                "\n                            Premium Tier\n                        "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.isMember
                            ? _c(
                                "v-col",
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        rounded: "",
                                        small: "",
                                        color:
                                          "primaryLight primary--text font-weight-bold",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Member Pricing Applied\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.collectionBundle
                        ? _c("p", { staticClass: "mb-0 mt-2" }, [
                            _vm._v(
                              "\n                    Only available as a part of following collection or Premium Complete\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.selection, function (item, key) {
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1",
                              class: { primaryLight: item.sku === _vm.options },
                              style:
                                item.sku === _vm.options
                                  ? `border: 2px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`
                                  : "",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.options = item.sku
                                },
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "py-1 px-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("v-radio", {
                                            attrs: { value: item.sku },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-col", [
                                        _c("h4", { staticClass: "fs-16" }, [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.title) +
                                              "\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.description) +
                                              "\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        item.learnMore
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-decoration-underline",
                                                attrs: {
                                                  target: "_blank",
                                                  href: item.learnMore,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Learn more\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "auto" },
                                        },
                                        [
                                          _vm.loading[item.key]
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  size: "18",
                                                  width: "2",
                                                  color: "primary",
                                                },
                                              })
                                            : [
                                                item.prices
                                                  ? [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass: "fs-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                $" +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatPrice"
                                                                )(
                                                                  item.pricePerLicense
                                                                )
                                                              ) +
                                                              " / license\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item.bestValue
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "mt-2 px-2",
                                                              attrs: {
                                                                color:
                                                                  "accent2Light",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm.completeSavingsEnabled
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                                                        15% Off During Trial\n                                                    "
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _vm._v(
                                                                          "\n                                                        Best Value\n                                                    "
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : _c(
                                                      "h4",
                                                      { staticClass: "fs-16" },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Price Unavailable\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                For orders without a credit card or for Enterprise and Custom Solutions, contact our sales team ("
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `mailto:${this.$getConst(
                            "sales_email_address"
                          )}`,
                          title: "Contact Digital Codes Sales",
                        },
                      },
                      [_vm._v(_vm._s(this.$getConst("sales_email_address")))]
                    ),
                    _vm._v(").\n            "),
                  ]),
                ],
                1
              ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                { staticClass: "py-0 px-1" },
                [
                  _vm.savingsText.length
                    ? _c(
                        "v-sheet",
                        {
                          staticClass: "rounded py-0 px-2 py-1 my-1",
                          attrs: { color: "accent2Light" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-n1",
                                      attrs: { color: "accent2" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                mdi-currency-usd\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", [
                                _c("h4", { staticClass: "font-weight-bold" }, [
                                  _vm._v(
                                    "\n                                Discounts Applied:\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  _vm._l(_vm.savingsText, function (item, key) {
                                    return _c("li", { key: key }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.discount) +
                                          "% " +
                                          _vm._s(item.label) +
                                          "\n                                "
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h2", [
                          _vm._v(
                            "\n                            Total\n                        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "h2",
                          [
                            _vm.activeTier && _vm.activeTier.displayedPrice
                              ? [
                                  _vm._v(
                                    "\n                                $" +
                                      _vm._s(
                                        _vm._f("formatPrice")(
                                          _vm.activeTier.displayedPrice
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(_vm.billingPeriodText) +
                                      "\n                            "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                                $0\n                            "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                target: "_blank",
                                color: "primary",
                                href: _vm.getRoute("premium_features"),
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Learn About Premium\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "accent2",
                                depressed: "",
                                disabled:
                                  _vm.loading.addToCart ||
                                  !_vm.activeTier ||
                                  !_vm.activeTier.displayedPrice,
                                loading: _vm.loading.addToCart,
                              },
                              on: { click: _vm.addItem },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "\n                                mdi-cart-outline\n                            "
                                ),
                              ]),
                              _vm._v(
                                "\n                            Add to Cart\n                        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }