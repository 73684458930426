<template>
    <div>
        <a
            v-if="documentInfo?.base_code_info"
            @click="redirectToBaseTitle(documentInfo?.base_code_info.document_slug)"
            class="grey--text text--darken-3">
            {{ documentInfo?.base_code_info.year }} {{ documentInfo?.base_code_info.short_title }}
        </a>
        <p
            v-if="documentInfo.custom_code_profile ||
                (documentInfo.adoption_notes && documentInfo.adoption_notes !== '') ||
                documentInfo.custom_code_profile"
            class="mb-1 fs-14 d-inline-block">
            <template v-if="documentInfo.custom_code_profile && documentInfo.custom_code_profile.value === 'amended_not_integrated'">
                <a class="text-decoration-underline" :href="documentInfo.amendment_url">{{ documentInfo.custom_code_profile.label }}</a>
            </template>
            <template v-else-if="documentInfo.custom_code_profile">
                <span class="grey--text text--darken-3">{{ documentInfo.custom_code_profile.label }}</span>
            </template>
            <v-icon
                v-if="documentInfo.adoption_notes && documentInfo.adoption_notes !== ''"
                color="primary"
                small
                class="pl-1"
                @click="dislayAdoptionNote">mdi-help-circle-outline
            </v-icon>
        </p>
        <p
            class="mb-0 fs-14 grey--text text--darken-3"
            v-if="documentInfo.effective_date">Effective Date: {{ documentInfo.effective_date }}</p>
        <confirm-dialog 
            :is-open="showCancelConfirmDialog"
            title="Adoption Note"
            @closed="showCancelConfirmDialog = false"
            :can-be-closed="true"
            :width="1250">
            <div
                class="mt-4"
                v-html="documentInfo.adoption_notes">
            </div>
            <template v-slot:buttons>
                <div class="w-full d-flex justify-center">
                    <template>
                        <v-btn class="mr-4" @click="showCancelConfirmDialog = false" color="primary">
                            Close
                        </v-btn>
                    </template>
                </div>
            </template>
        </confirm-dialog>
    </div>    
</template>
<script>
import ConfirmDialog from '../../ConfirmDialog.vue';

export default {
    data() {
        return {
            showCancelConfirmDialog: false
        }
    },
    components: {
        ConfirmDialog
    },
    props: {
        documentInfo: {
            type: Object,
            required: true
        }
    },
    methods:{
        dislayAdoptionNote(){
            this.showCancelConfirmDialog = true;
        },
        redirectToBaseTitle(slug){
            window.location.href = Routing.generate("content_document_slug", {
                                documentSlug: slug
                            });
        }
    }
}
</script>
