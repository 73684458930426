<template>
    <div class="dashboard-sharing">
        <breadcrumb title="Sharing History"></breadcrumb>
        <v-container
            id="contentParent"
            :class="{'pr-3': isMobilePort}">
            <v-row v-if="concurrent == 1">
                <v-col>
                    <v-alert
                        class="py-6 my-12"
                        :value="true"
                        type="info"
                        color="primary">
                        {{ info_message }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">
                    <p class="mt-3">
                        This page is for managing notes you have previously shared with another user or had another
                        user share with you. To share new notes with another premium user, please visit the <a @click="goToNotesPage">My Notes</a> page.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">
                    <v-card class="mb-5">
                        <v-tabs
                            fixed-tabs
                            hide-slider
                            show-arrows
                            background-color="primary"
                            dark>
                            <v-tab
                                ref="sharedByMeTab"
                                v-on:click="panel = 'SharedByMe'"
                                :disabled="concurrent == 1"
                                class="text-capitalize tab-class">
                                Shared By Me
                            </v-tab>
                            <v-tab
                                v-on:click="panel = 'SharedWithMe'"
                                :disabled="concurrent == 1"
                                class="text-capitalize tab-class">
                                Shared With Me
                            </v-tab>
                            <v-tab
                                ref="sharedWithMeHistoryTab"
                                v-on:click="panel = 'SharedHistory'"
                                :disabled="concurrent == 1"
                                class="text-capitalize tab-class">
                                Shared With Me History
                            </v-tab>
                        </v-tabs>
                        <keep-alive>
                            <v-card flat>
                                <v-card-text :class="{'pa-0': isMobilePort}">
                                    <template v-if="getPanel === 'SharedByMe'">
                                        <component
                                                v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template v-else-if="getPanel === 'SharedWithMe'">
                                        <component
                                                v-bind:is="getPanel">
                                        </component>
                                    </template>
                                    <template v-else-if="getPanel === 'SharedHistory'">
                                        <component
                                                v-bind:is="getPanel">
                                        </component>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </keep-alive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumb from '../../content/partials/page_titles';
    import BackToTop from '../../partials/buttons/back_to_top';
    import InfoBanner from '../../banners/info_banner';
    import Mobile from '../../inc/mixins/mobile';
    // Tabs
    import SharedByMe from './partials/shared_by_me'
    import SharedWithMe from './partials/shared_with_me'
    import SharedHistory from './partials/shared_history'

    export default {
        data: function () {
            return {
                info_message: 'Sharing bookmarks, annotations and highlights is currently not supported while concurrent access is active.',
                panel: 'SharedByMe',
                componentKey: 0
            }
        },
        props: ['concurrent'],
        components: {
            Breadcrumb,
            BackToTop,
            InfoBanner,
            SharedByMe,
            SharedWithMe,
            SharedHistory
        },
        mixins: [Mobile],
        computed: {
            getPanel() {
                return this.panel;
            }
        },
        methods: {
            switchToSharedByMeTab: function () {
                this.componentKey += 1;
                this.$refs.sharedByMeTab.click();
            },

            switchToSharedWithMeHistoryTab: function () {
                this.$refs.sharedWithMeHistoryTab.click();
            },
            goToNotesPage(){
                window.open(Routing.generate('notes'), '_blank');
            }
        },
        created() {
            EventBus.listen('show-shared-by-me-tab', () => this.switchToSharedByMeTab());
            EventBus.listen('show-shared-with-me-history-tab', () => this.switchToSharedWithMeHistoryTab());
        }
    }
</script>

<style lang="scss">
    .dashboard-sharing {
        .v-input--selection-controls__input {
            margin: 10px auto;
        }
        .v-expansion-panel-header--active {
            border-bottom: 1px solid #d6d6d6;
        }
        .sharing-data-iterator {
            width: 100%;
        }
        .sharing-expand {
            max-height: 350px;
            overflow-y: scroll;
        }
        .tab-class {
            max-width: none;
            border: 1px solid #0b5940;
        }
    }
</style>
