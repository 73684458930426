var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbar.timeout, top: "" },
          model: {
            value: _vm.snackbar.active,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "active", $$v)
            },
            expression: "snackbar.active",
          },
        },
        [
          _vm.snackbar.status == "success"
            ? [
                _c("v-icon", { staticClass: "primary--text mr-2" }, [
                  _vm._v("check_circle"),
                ]),
              ]
            : _vm.snackbar.status == "error"
            ? [
                _c("v-icon", { staticClass: "error--text mr-2" }, [
                  _vm._v("remove_circle"),
                ]),
              ]
            : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.snackbar.message) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "error", text: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar.active = false
                },
              },
            },
            [_vm._v("\n            Close\n        ")]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("breadcrumb", { attrs: { title: "Manually Process Notes" } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "justify-xl": "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "10" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "primary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", [_vm._v("compare_arrows")]),
                              _vm._v(
                                " Manually Process Notes\n                        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-2 pb-0" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "\n                                        After processing your notes, the system found some notes that require you to manually highlight the section text for the new version. Please use the cursor to highlight the desired text in the new version section. Once highlighted, you may click the highlight icon to add your new note. Your note title, description, and tag category will be automatically applied.\n                                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          '\n                                        You can skip a note using the "Skip Note" button and you can also move between notes using the "Prev" and "Next" buttons. Click the "Cancel" button to return back to the Migrate Notes section.\n                                    '
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-container",
                            { staticClass: "white" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class: _vm.isMobilePort
                                        ? "text-center"
                                        : "text-left",
                                      attrs: { cols: "12", sm: "10" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 font-weight-regular",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        You are in the process of manually moving notes. Notes were not moved over automatically because the book content does not match. Please apply the note to the correct text content.\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      class: _vm.isMobilePort
                                        ? "text-center"
                                        : "text-right",
                                      attrs: { cols: "12", sm: "2" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "font-weight-regular" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.currentNote + 1) +
                                              " of " +
                                              _vm._s(_vm.totalNotes) +
                                              " Notes\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-container",
                            { staticClass: "grey lighten-3" },
                            [
                              !_vm.loading.books
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "caption mb-0",
                                                  },
                                                  [_vm._v("Moving Note From")]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                dense: "",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _vm.fromBookData
                                                    ? _c("img", {
                                                        staticClass: "cover",
                                                        attrs: {
                                                          src: _vm.getBookCover(
                                                            _vm.fromBookData
                                                          ),
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          error:
                                                            _vm.loadDefaultBookCover,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-col", [
                                                _c("h3", {
                                                  staticClass:
                                                    "primary--text font-weight-regular",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getBookTitle(
                                                        _vm.fromBookData
                                                      )
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("p", {
                                                  staticClass: "mb-0 caption",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getBookPrintingInfo(
                                                        _vm.fromBookData
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "caption mb-0",
                                                  },
                                                  [_vm._v("Moving Note To")]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                dense: "",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _vm.toBookData
                                                    ? _c("img", {
                                                        staticClass: "cover",
                                                        attrs: {
                                                          src: _vm.getBookCover(
                                                            _vm.toBookData
                                                          ),
                                                          width: "100%",
                                                        },
                                                        on: {
                                                          error:
                                                            _vm.loadDefaultBookCover,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-col", [
                                                _c("h3", {
                                                  staticClass:
                                                    "primary--text font-weight-regular",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getBookTitle(
                                                        _vm.toBookData
                                                      )
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("p", {
                                                  staticClass: "mb-0 caption",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.getBookPrintingInfo(
                                                        _vm.toBookData
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: { indeterminate: true },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          !_vm.loading.note
                                            ? [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "py-2" },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c("v-col", [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mt-1 ml-1",
                                                              class: {
                                                                title:
                                                                  !_vm.isMobilePort,
                                                                "body-2":
                                                                  _vm.isMobilePort,
                                                              },
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redirectToSection(
                                                                      _vm
                                                                        .notesData
                                                                        .document_id,
                                                                      _vm
                                                                        .notesData
                                                                        .content_id,
                                                                      _vm
                                                                        .notesData
                                                                        .section_id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.notesData
                                                                    .chapter_title
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c("v-col", [
                                                          _vm.notesData.content
                                                            ? _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "note-list-quote font-italic pa-1 mb-0 mt-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .notesData
                                                                          .content
                                                                      ) +
                                                                      "\n                                                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.notesData
                                                            .commentText
                                                            ? _c("p", {
                                                                staticClass:
                                                                  "mt-2 ml-1",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      _vm
                                                                        .notesData
                                                                        .commentText
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  fab: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "#" +
                                                                        _vm.getTagColor(
                                                                          _vm
                                                                            .notesData
                                                                            .tagId
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          _vm.getIcon(
                                                                            _vm.isBookmark()
                                                                              ? "bookmark"
                                                                              : "annotation"
                                                                          )
                                                                        ) +
                                                                        "\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("v-col", [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Created By: " +
                                                                _vm._s(
                                                                  _vm.notesData
                                                                    .firstName
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.notesData
                                                                    .lastName
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("h5", [
                                                            _vm._v(
                                                              "\n                                                            Project Tag:\n                                                            " +
                                                                _vm._s(
                                                                  _vm.getTagName(
                                                                    _vm
                                                                      .notesData
                                                                      .tagId
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("h5", [
                                                            _vm._v(
                                                              "\n                                                            Timestamp: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "moment"
                                                                  )(
                                                                    _vm
                                                                      .notesData
                                                                      .created_date,
                                                                    "MMMM Do, YYYY"
                                                                  )
                                                                ) +
                                                                "\n                                                        "
                                                            ),
                                                          ]),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("v-divider"),
                                                _vm._v(" "),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "caption mb-0 mr-2",
                                                      },
                                                      [_vm._v("Status")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.getNoteStatus(
                                                              _vm.fromNoteId
                                                            ).color,
                                                          "text-color": "white",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getNoteStatus(
                                                                    _vm.fromNoteId
                                                                  ).icon
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.getNoteStatus(
                                                                _vm.fromNoteId
                                                              ).text
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-spacer"),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "info",
                                                          disabled:
                                                            _vm.isMoved(
                                                              _vm.fromNoteId
                                                            ) ||
                                                            _vm.isSkipped(
                                                              _vm.fromNoteId
                                                            ),
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.skipNoteModal,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Skip Note\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                staticClass:
                                                                  "my-12",
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c(
                                        "v-stepper",
                                        {
                                          model: {
                                            value: _vm.step,
                                            callback: function ($$v) {
                                              _vm.step = $$v
                                            },
                                            expression: "step",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-stepper-header",
                                            [
                                              _c(
                                                "v-stepper-step",
                                                {
                                                  attrs: {
                                                    complete: _vm.step > 1,
                                                    step: "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                Select Chapter or Section\n                                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c(
                                                "v-stepper-step",
                                                {
                                                  attrs: {
                                                    complete: _vm.step > 2,
                                                    step: "2",
                                                  },
                                                },
                                                [
                                                  _vm.isBookmark()
                                                    ? [
                                                        _vm._v(
                                                          "\n                                                    Bookmark Content\n                                                "
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          "\n                                                    Highlight Content\n                                                "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c(
                                                "v-stepper-step",
                                                { attrs: { step: "3" } },
                                                [
                                                  _vm._v(
                                                    "\n                                                Confirm & Apply Note\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-stepper-items",
                                            [
                                              _c(
                                                "v-stepper-content",
                                                { attrs: { step: "1" } },
                                                [
                                                  _vm.isMobilePort
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c("v-col", [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mb-2 text-center caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            Select Chapter or Section\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        justify: "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                disabled:
                                                                  _vm.loading
                                                                    .items ||
                                                                  !_vm.hasActiveSection,
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.selectSection,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Continue\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          class: {
                                                            "text-center":
                                                              _vm.loading.items,
                                                          },
                                                        },
                                                        [
                                                          _vm.loading.items
                                                            ? _c(
                                                                "v-progress-circular",
                                                                {
                                                                  staticClass:
                                                                    "my-12",
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "overflow-y-auto grey lighten-5",
                                                                  attrs: {
                                                                    flat: "",
                                                                    "max-height":
                                                                      "400px",
                                                                    transition:
                                                                      "slide-y-transition",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "py-sm-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-treeview",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              dense:
                                                                                "",
                                                                              rounded:
                                                                                "",
                                                                              hoverable:
                                                                                "",
                                                                              activatable:
                                                                                "",
                                                                              "active-class":
                                                                                "grey lighten-3",
                                                                              search:
                                                                                _vm.search,
                                                                              items:
                                                                                _vm.items,
                                                                              "item-children":
                                                                                "sub_sections",
                                                                              "item-key":
                                                                                "content_id",
                                                                              "item-text":
                                                                                "title",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "label",
                                                                                  fn: function ({
                                                                                    item,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "a",
                                                                                        {
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.setActive(
                                                                                                  item.content_id,
                                                                                                  item.title
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                            " +
                                                                                              _vm._s(
                                                                                                item.title
                                                                                              ) +
                                                                                              "\n                                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ]
                                                                            ),
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        justify: "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                disabled:
                                                                  _vm.loading
                                                                    .items ||
                                                                  !_vm.hasActiveSection,
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.selectSection,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Continue\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-stepper-content",
                                                { attrs: { step: "2" } },
                                                [
                                                  !_vm.loading.chapterSection
                                                    ? [
                                                        _vm.isMobilePort
                                                          ? _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c("v-col", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2 text-center caption",
                                                                    },
                                                                    [
                                                                      _vm.isBookmark()
                                                                        ? [
                                                                            _vm._v(
                                                                              "\n                                                                    Bookmark Content\n                                                                "
                                                                            ),
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\n                                                                    Highlight Content\n                                                                "
                                                                            ),
                                                                          ],
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              justify: "end",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.step = 1
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Back\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      disabled:
                                                                        !_vm.hasMadeSelection,
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.step = 3
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Continue\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "pt-4 chapter_styling chapter-expand migrate-compare",
                                                                    attrs: {
                                                                      id: "contentParent",
                                                                    },
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.chapterContent
                                                                        ),
                                                                    },
                                                                  }),
                                                                ],
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              justify: "end",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.step = 1
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Back\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      disabled:
                                                                        !_vm.hasMadeSelection,
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.step = 3
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Continue\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-progress-circular",
                                                                  {
                                                                    staticClass:
                                                                      "my-12",
                                                                    attrs: {
                                                                      indeterminate:
                                                                        "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-stepper-content",
                                                { attrs: { step: "3" } },
                                                [
                                                  _vm.isMobilePort
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c("v-col", [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mb-2 text-center caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            Confirm & Apply Note\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.editNoteData
                                                    ? [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.editNoteData = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Save\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              align: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    "hide-details":
                                                                      "",
                                                                    solo: "",
                                                                    dense: "",
                                                                    label:
                                                                      "Select Tag from the list",
                                                                    items:
                                                                      _vm.tags,
                                                                    "item-text":
                                                                      "tag",
                                                                    "item-value":
                                                                      "id",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.selectedTag,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.selectedTag =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "selectedTag",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.openTagModal,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Add Tag\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "vue-editor",
                                                                  {
                                                                    attrs: {
                                                                      "editor-toolbar":
                                                                        _vm.customToolbar,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.editorData,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.editorData =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "editorData",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              justify: "end",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.step = 2
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Back\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      disabled:
                                                                        _vm
                                                                          .loading
                                                                          .noteProcessing,
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.onSave,
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.loading
                                                                      .noteProcessing
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Apply Note"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "v-progress-circular",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                                indeterminate:
                                                                                  "",
                                                                                color:
                                                                                  "white",
                                                                              },
                                                                          }
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c("v-col", [
                                                              _c(
                                                                "h3",
                                                                {
                                                                  staticClass:
                                                                    "mt-1 ml-1",
                                                                  class: {
                                                                    title:
                                                                      !_vm.isMobilePort,
                                                                    "body-2":
                                                                      _vm.isMobilePort,
                                                                  },
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        _vm.chapterTitle
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c("v-col", [
                                                              _vm
                                                                .highlightedText
                                                                .text
                                                                ? _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "note-list-quote font-italic pa-1 mb-0 mt-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                                " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .highlightedText
                                                                              .text
                                                                          ) +
                                                                          "\n                                                            "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.editorData
                                                                ? _c("p", {
                                                                    staticClass:
                                                                      "mt-2 ml-1",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.editorData
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      depressed:
                                                                        "",
                                                                      fab: "",
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "#" +
                                                                            _vm.getTagColor(
                                                                              _vm.selectedTag
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    " +
                                                                            _vm._s(
                                                                              _vm.getIcon(
                                                                                _vm.isBookmark()
                                                                                  ? "bookmark"
                                                                                  : "annotation"
                                                                              )
                                                                            ) +
                                                                            "\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-col", [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "Created By: " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .notesData
                                                                        .firstName
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .notesData
                                                                        .lastName
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "\n                                                                Project Tag:\n                                                                " +
                                                                    _vm._s(
                                                                      _vm.getTagName(
                                                                        _vm.selectedTag
                                                                      )
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "\n                                                                Timestamp: " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "moment"
                                                                      )(
                                                                        _vm
                                                                          .notesData
                                                                          .created_date,
                                                                        "MMMM Do, YYYY"
                                                                      )
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ]),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "grey lighten-3",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.editNoteData = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                Edit Details\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: { dense: "", justify: "end" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: _vm.isMobilePort,
                                                    color: "error",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.finish()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                Cancel\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.currentNote > 0
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: _vm.isMobilePort,
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          --_vm.currentNote
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Prev Note\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.currentNote !== _vm.totalNotes - 1
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: _vm.isMobilePort,
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.nextNote()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Next Note\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.currentNote === _vm.totalNotes - 1
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: _vm.isMobilePort,
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.finish()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Finish\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("tag-edit-modal"),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primary white--text" }, [
                _vm._v("\n                Confirm\n            "),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", { staticClass: "mt-8 text-center" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.dialogMessage) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("\n                    No\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.skipNote },
                    },
                    [_vm._v("\n                    Yes\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }