<template>
    <v-select
        solo
        hide-details
        v-model="selected"
        :items="vSelectData"
        item-text="printing_text"
        :item-value="itemIdentifier"
        @change="gotToTitle"
        placeholder="Versions"
        flat
        dense
        class="title-reference"
        :menu-props="{offsetY: true}">
        <template v-slot:item="{ active, item, attrs, on }">
            <v-row v-if="isExam" class="mb-0 options-width pb-2 pt-2">
                <v-col v-if="item.isAvailableOnDigitalCodes">
                    {{ item.title }} <br /><span v-if="item.id === selected">(Currently Viewing)</span>
                </v-col>
                <v-col v-else class="disabled-text">
                    Reference Not Available through Digital Codes
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    {{ item.print_version_edition.version }}: {{ item.printing_text }} <span v-if="item.document_id === selected">(Currently Viewing)</span>
                </v-col>
            </v-row>
        </template>
        <template v-slot:selection="{ active, item, attrs, on }">
            <p v-if="isExam" class="mb-0 caption primary--text">
                Change Reference Title
            </p>
            <p v-else class="mb-0 fs-14">
                Version: 
                <span class="black--text">
                  {{ item.printing_text }} <template v-if="item.latest_version_id !== item.id">(Historical)</template>
                </span>
            </p>
        </template>
    </v-select>
</template>

<script>
    import {SuperAxios} from "../../../api/SuperAxios";

    export default {
        data() {
            return {
                selected: null,
                associatedBooksArray: [],
                exam: [],
                examReferences: []
            };
        },
        props: {
            documentId: {
                required: false,
                type: Number
            },
            currentBookId:{
                required: false
            },
            examId: {
                type: String,
                required: false,
                default: ''
            }
        },
        computed: {
            vSelectData() {
                return this.examId !== '' ? this.exam?.references : this.associatedBooksArray;
            },
            isExam() {
                return this.examId !== '';
            },
            itemIdentifier() {
                return this.isExam && this.vSelectData ? 'id' : 'document_id';
            }
        },
        methods: {
            getAssociatedBookInfo() {
                this.selected = this.documentId;
                this.$http.get(
                    Routing.generate("associated_books", {document_id: this.documentId})
                ).then(result => {
                    if (!_.isUndefined(result.data.result_data)) {
                        if (_.size(result.data.result_data) > 0) {
                            this.associatedBooksArray = _.sortBy(result.data.result_data, [function (o) {
                                return o.associate_sort_order;
                            }]);

                            this.$store.commit('setAssociatedBooks', this.associatedBooksArray);
                        }
                    }
                });
            },
            gotToTitle() {
                let book;
                if (this.exam.references) {
                    book = _.find(this.exam.references, { id: this.selected });
                    window.location = Routing.generate(
                        'title_landing',
                        {
                            'documentSlug': book.document_slug,
                            'examId': this.examId
                        }
                    );
                } else {
                    let book = _.find(this.associatedBooksArray, { document_id: this.selected });
                    let chapterSlug = null;
                    let sectionId = null;

                    if (book && book.document_slug) {
                        const urlParts = document.URL.split('/content/');

                        if (urlParts.length > 1) {
                            const targets = urlParts[1].split('/');
                            const sourceDocSlug = targets[0];
                            chapterSlug = targets[1] ? targets[1] : null;

                            if(chapterSlug) {
                                if(chapterSlug.includes('#')) {
                                    let chParts = chapterSlug.split('#');
                                    chapterSlug = chParts[0];

                                    if(!chParts[1].includes('text-id')) {
                                        sectionId = chParts[1].replace(sourceDocSlug, book.document_slug);
                                    }
                                }
                            }
                            this.bookLink(book, chapterSlug, sectionId);
                        }
                    }
                }
            },
            setExamInfo() {
                if('sample-exam' === this.examId) {
                    this.selected = this.currentBookId;
                    this.exam = this.getSampleExam();
                } else {
                    this.getExamDetails();
                }
            },
            getSampleExam() {
                return {
                    examId: 'sample-exam',
                    examTitle: 'Sample Exam',
                    references: [
                    {
                        id: 1698,
                        document_slug: 'IBC2021P1',
                        title: '2021 International Building Code (IBC)',
                        printingInfo: 'First Version: Oct 2020',
                        isAvailableOnDigitalCodes: true
                    },
                    {
                        id: 1722,
                        document_slug: 'IRC2021P1',
                        title: '2021 International Residential Code (IRC)',
                        printingInfo: 'First Version: Dec 2020',
                        isAvailableOnDigitalCodes: true
                    }
                ]};
            },
            getExamDetails() {
                let _this = this;
                const apiUrl = Routing.generate('exam_details_by_code', {
                    examCode: this.examId
                });
                SuperAxios.getOnce(apiUrl).then(response => {
                    var valObj = response.data.exams.filter(function(elem){
                        if(elem.examId.toLowerCase() === _this.examId.toLowerCase()){
                            return elem;
                        }
                    });
                    this.exam = valObj[0];
                    this.selected = this.currentBookId;
                });
            },
            menuOpen(){
                EventBus.fire('hide-exam-search-box');
            }
        },
        mounted() {
            if('' !== this.examId) {
                this.setExamInfo();
            } else {
                this.getAssociatedBookInfo();
            }
        }
    }
</script>

<style lang="scss">
.title-reference {

  .v-select__slot {
    width: auto !important;

    input {
      max-width: 0 !important;
    }
  }

  .v-input__slot {
    padding: 0px !important;
  }

  .mdi-menu-down::before {
    color: black !important;
  }
}
</style>
