var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(
                  "\n                Concurrent Session Timeout!\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.modalVisible
                ? _c("v-card-text", [
                    _c("p", [
                      _vm._v(
                        "\n                    There has been no activity on this browser since last " +
                          _vm._s(_vm.timeoutMinutes) +
                          " minutes.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "If you want to continue with the current session, please click "
                        ),
                        _c("strong", [_vm._v("Stay Signed in")]),
                        _vm._v(
                          "\n                    button below, or else you will be disconnected in\n                    "
                        ),
                        _c("seconds-countdown", {
                          staticClass: "font-weight-bold",
                          attrs: { seconds: _vm.continueTimeoutSeconds },
                        }),
                        _vm._v(
                          "\n                    seconds\n                "
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "grey lighten-4" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary white--text" },
                      on: { click: _vm.clickedSignIn },
                    },
                    [
                      _vm._v(
                        "\n                    Stay Signed in\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }