var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "title-cover-container", attrs: { flat: "" } },
    [
      _c("v-card-text", [
        _c(
          "a",
          { attrs: { title: "Click to access", href: _vm.title.link } },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [_c("graphic", { attrs: { title: _vm.title } })],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showTitle
              ? _c(
                  "v-row",
                  { attrs: { dense: "", justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-center", attrs: { cols: "auto" } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.title.display_title) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.title.is_collection
              ? _c(
                  "v-row",
                  { attrs: { dense: "", justify: "center" } },
                  [
                    _vm.title.subscription_required
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-img", {
                              attrs: {
                                width: "30px",
                                src: _vm.asset(
                                  require("../../../images/icons/premium-only.png")
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.title.is_xml
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-img", {
                              attrs: {
                                width: "30px",
                                src: _vm.asset(
                                  require("../../../images/icons/pdf_book_cover.png")
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }