<template>
    <div>
        <v-container class="content-container">
            <v-row>
                <v-col>
                    <hero-banner
                        title="ICC Digital Codes Premium Revit<sup>®</sup> Add-In"
                        subtitle="Use building codes and standards directly within your BIM application"
                        :button="{
                            link: revitDownloadUrl,
                            target: '_blank',
                            text: 'Download and Install',
                            color: 'primary'
                        }"
                        :image="{
                            src: 'revit/app_docked.webp',
                            caption: 'Digital Codes Premium I-Code chapter referenced during foundation design in Revit<sup>®</sup>.'
                        }"
                        :show-border="false"
                        :gtm="{
                            category: 'Product Discovery',
                            action: 'Download',
                            label: 'Revit Download Button Click'
                        }">
                    </hero-banner>
                </v-col>
            </v-row>
        </v-container>
        <div class="grey lighten-4">
            <v-container class="content-container">
                <v-row>
                    <v-col>
                        <h2 class="mb-4 mt-5 roboto">
                            Design with Confidence
                        </h2>
                        <p>
                            Get your architectural drawings right the first time and reduce the need for rework. <b>ICC Digital Codes Premium Revit<sup>®</sup> Add-In</b> lets you validate construction plans with published building codes during the design process.
                        </p>
                        <p>
                            ICC Digital Codes Premium is more than just codes. It’s an entire toolset that gives you deeper insights, greater efficiency, and stronger collaboration — all at your fingertips. Stay connected to the most trusted source of building codes and standards, at your desk or in the field.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container py-8">
            <v-row>
                <v-col>
                    <h2 class="mb-4 roboto">
                        What You Get with the Revit<sup>®</sup> Add-in
                    </h2>
                    <p>
                        Enjoy the benefits of <b>ICC Digital Codes Premium</b> without leaving Revit<sup>®</sup>.  Get access to more than 1000 <b>I-Codes, Commentaries and Referenced Standards</b>.
                    </p>
                    <p>
                        <b>Use the full-featured version of ICC Digital Codes Premium:</b> Simplify your Autodesk Revit<sup>®</sup> workflows with the full suite of <a :href="getRoute('premium_features')">Digital Codes Premium features</a>.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    lg="3"
                    v-for="(item, key) in whatYouGetBlocks"
                    :key="key">
                    <v-card
                        flat
                        :class="key % 2 === 0 && 'primary'"
                        :style="key % 2 !== 0 && 'border: 1px solid '+$vuetify.theme.themes.light.primary"
                        :min-height="!isMobilePort && '210px'">
                        <v-card-text
                            class="text-center"
                            :class="key % 2 === 0 ? 'white--text' : 'primary--text'">
                            <v-icon :color="key % 2 === 0 ? 'white' : 'primary'">
                                {{item.icon}}
                            </v-icon>
                            <h4
                                v-html="item.title"
                                class="text-center my-2">
                            </h4>
                            <p
                                class="mb-0 text-left"
                                v-html="item.description">
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <testimonials :testimonials="testimonials">
        </testimonials>
        <v-container class="content-container">
            <v-row>
                <v-col>
                    <img
                        class="my-5"
                        :src="asset(require('../../images/icc_digital_codes_premium.png'), 'default')"/>
                    <h2>
                        Tools to Help Design Professionals Succeed
                    </h2>
                    <ul class="mt-5">
                        <li
                            v-for="(item, key) in helpTools"
                            :key="key"
                            v-html="item">
                        </li>
                    </ul>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        rounded
                        class="mt-4 white--text"
                        dark
                        x-large
                        color="primary"
                        target="_blank"
                        :href="revitDownloadUrl"
                        @click.stop="logGtm()">
                        Download and Install
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col
                    cols="12"
                    sm="6">
                    <img
                        class="mx"
                        :src="asset(require('../../images/revit/digital-codes-premium-for-revit-copy-paste.webp'), 'default')"/>
                    <p class="caption mb-0">
                        I-Code chapter referenced and copy/pasted directly into a Revit<sup>®</sup> project.
                    </p>
                </v-col>
                <v-col
                    cols="12"
                    sm="6">
                    <img
                        class="mx"
                        :src="asset(require('../../images/revit/digital-codes-premium-for-revit-my-library.webp'), 'default')"/>
                    <p class="caption mb-0">
                        2021 I-Codes library opened within Revit<sup>®</sup>.
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header
                                class="white--text"
                                color="primary">
                                System Requirements
                                <template v-slot:actions>
                                    <v-icon color="white">
                                        $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="mt-3">
                                <v-row
                                    v-for="(requirement, key) in systemRequirements"
                                    :key="key"
                                    :class="key % 2 == 0 ? 'grey lighten-4' : ''"
                                    align="center">
                                    <v-col class="py-6">
                                        <p
                                            class="mb-0 font-weight-bold"
                                            v-html="requirement.title">
                                        </p>
                                    </v-col>
                                    <v-col
                                        style="border-left: 1px solid white;"
                                        class="py-6">
                                        <p
                                            class="mb-0"
                                            v-html="requirement.description">
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
        <div class="grey lighten-4 mt-5">
            <v-container class="content-container">
                <v-row>
                    <v-col>
                        <h2 class="mb-5">
                            Support Information
                        </h2>
                        <p>
                            Our Customer Success Advocates are ready to assist you with any of your needs.<br/>
                            You can email us at any time at <a class="text-decoration-underline" href="mailto:customersuccess@iccsafe.org">customersuccess@iccsafe.org</a>
                        </p>
                        <p>
                            Speak to us by calling at <a href="tel:+18884227233" class="text-decoration-underline">1 888 ICC SAFE (888 422 7233)</a>, option 0.<br/>
                            We are available Monday – Friday 7:00 AM – 6:00 PM Central.
                        </p>
                        <p>
                            Autodesk<sup>®</sup> and Revit<sup>®</sup> are registered trademarks of Autodesk, Inc., in the USA and other countries.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container">
            <v-row>
                <v-col>
                    <hero-banner
                        title="Download the ICC Digital Codes Premium Revit<sup>®</sup> Add-In"
                        :button="{
                            link: revitDownloadUrl,
                            target: '_blank',
                            text: 'Download and Install',
                            color: 'primary'
                        }"
                        :image="{
                            src: 'revit/digital-codes-premium-for-revit-code-title.webp',
                            caption: '2021 International Residential Code opened within Revit<sup>®</sup>.'
                        }"
                        :show-border="false"
                        :gtm="{
                            category: 'Product Discovery',
                            action: 'Download',
                            label: 'Revit Download Button Click'
                        }">
                    </hero-banner>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import HeroBanner from "../banners/HeroBanner";
    import Testimonials from "./partials/Testimonials";

    import Mobile from './../inc/mixins/mobile';
    import {AppParameters} from "../../AppParameters";

    export default {
        data() {
            return {
                revitDownloadUrl: AppParameters.revit_download_url,
                systemRequirements: [
                    {
                        title: 'Autodesk<sup>®</sup> Revit<sup>®</sup> version',
                        description: 'Autodesk<sup>®</sup> Revit<sup>®</sup> 2025, Autodesk<sup>®</sup> Revit<sup>®</sup> 2024, Autodesk<sup>®</sup> Revit<sup>®</sup> 2023'
                    },
                    {
                        title: 'Operating System',
                        description: '64-bit Microsoft<sup>®</sup> Windows<sup>®</sup> 10 or Windows<sup>®</sup> 11'
                    },
                    {
                        title: 'CPU Type',
                        description: 'Intel<sup>®</sup> i-Series, Xeon<sup>®</sup>, AMD<sup>®</sup> Ryzen or above'
                    },
                    {
                        title: 'Memory',
                        description: 'Memory 8 GB RAM – 16 GB RAM (Based on Revit<sup>®</sup>)'
                    },
                    {
                        title: 'Video Display Resolutions',
                        description: '1680 x 1050 px with true color and above'
                    },
                    {
                        title: 'Disk Space',
                        description: '30 MB free disk space'
                    },
                    {
                        title: 'Connectivity',
                        description: 'Internet connection for access to ICC content libraries'
                    }
                ],
                whatYouGetBlocks: [
                    {
                        icon: 'mdi-timer-sand-full',
                        title: 'Design Faster',
                        description: 'The native add-in streamlines your research so you can get the information you need without interrupting your flow.'
                    },
                    {
                        icon: 'mdi-file-document-multiple',
                        title: 'Reduce Rework',
                        description: 'Easily reference building codes and other standards during design. Get your architectural drawings right the first time. Copy items directly into your drawings.'
                    },
                    {
                        icon: 'mdi-magnify',
                        title: 'Find Information Fast',
                        description: 'Perform powerful searches across titles to find the content you need quickly — including all ASTM standards referenced in the building codes.'
                    },
                    {
                        icon: 'mdi-account-multiple',
                        title: 'Collaborate Better',
                        description: 'Add and organize notes, highlights and bookmarks inline. Easily share code sections with others by email. Give your entire team simultaneous access to Digital Codes Premium.'
                    },
                ],
                testimonials: [
                    {
                        text: 'The access to all the data, connections, history and related standards make this option invaluable to anyone involved in the codes world-architects, engineers , contractors and the general will benefit with this selection of products. I will save a hundred hours with this premium set of digital codes. Thank You!',
                        name: '— Design Professional and ICC Digital Codes Premium user'
                    },
                    {
                        text: 'I would recommend to any architect, interior designer, structural engineer, electrical engineer, etc.',
                        name: '— Professional Architect and ICC Digital Codes Premium user'
                    }
                ],
                helpTools: [
                    'All the codes for your project in one place',
                    'International Building Code and International Residential Codes, including official local codes for many jurisdictions',
                    'Copy code text and illustrations directly into Revit<sup>®</sup>',
                    'Ability to tag code sections and share with colleagues',
                    'Access to I-Codes, supplemental materials and hundreds of standards referenced in the building codes',
                    'Powerful Premium search and other tools deliver efficiency and promote collaboration across your team'
                ]
            }
        },
        components: {
            Testimonials,
            HeroBanner
        },
        mixins: [
            Mobile
        ],
        methods: {
            logGtm() {
                if (this.gtm) {
                    this.gtmEvent(
                        this.gtm.category,
                        this.gtm.label,
                        this.gtm.action
                    )
                }
            }
        }
    }
</script>
