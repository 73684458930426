<template>
    <v-btn
        text
        small
        @click="showCommentary = !showCommentary">
        <v-icon left>
            {{showCommentary ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}}
        </v-icon>
        {{showCommentary ? 'Hide Commentary' : ' Show Commentary'}}
    </v-btn>
</template>

<script>
    const commentaryElements = [
        'commentary',
        'commentry', // This was added for a typo in the code PRA-7496
        'commentary_general'
    ];

    export default {
        name: 'TitlesCommentaryButton',
        data() {
            return {
                showCommentary: true,
            }
        },
        watch: {
            showCommentary(val) {
                commentaryElements.forEach((element) => {
                    let elements = document.getElementsByClassName(element);
                    if (elements) {
                        for (let value of elements) {
                            value.style.display = val ? 'block' : 'none';
                        }
                    }
                })
            }
        }
    }
</script>
