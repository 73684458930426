var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { align: "center", dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _vm._l(_vm.getBreadcrumbItems, function (item, index) {
            return [
              _c(
                "a",
                {
                  key: index,
                  staticClass: "caption font-weight-medium",
                  attrs: { href: item.href },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.text) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "caption font-weight-medium" }, [
                _vm._v(" / "),
              ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _vm.documentInfo.display_title
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "min-width": "500px",
                    "max-height": "400px",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "caption font-weight-medium",
                                    staticStyle: { "margin-top": "2px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getTitles()
                                      },
                                    },
                                  },
                                  "div",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.getBookTitle(_vm.documentInfo)) +
                                    "\n                    "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mt-n1 ml-n1",
                                    staticStyle: { "margin-bottom": "-1px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        mdi-chevron-down\n                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3025644564
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _vm.loading
                        ? _c(
                            "v-card-text",
                            [
                              _c("v-progress-linear", {
                                attrs: { color: "primary", indeterminate: "" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-list",
                            { staticClass: "py-0" },
                            _vm._l(_vm.categoryTitles, function (item, index) {
                              return _c(
                                "v-list-item",
                                { key: index, attrs: { href: item.link } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "caption" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.getBookTitle(item)) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getRoute("codes_category", {
                              category: _vm.documentCategory.slug,
                            }),
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "py-0" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "grey lighten-4" },
                                [
                                  _c("v-col", [
                                    _c("p", { staticClass: "mb-0 caption" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.getCodesCategoryText) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }