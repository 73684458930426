var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Select State",
      outlined: "",
      "hide-details": "",
      items: _vm.states,
    },
    model: {
      value: _vm.state,
      callback: function ($$v) {
        _vm.state = $$v
      },
      expression: "state",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }