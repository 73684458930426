var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    { attrs: { value: _vm.loading, "z-index": "300" } },
    [_c("v-progress-circular", { attrs: { indeterminate: "", size: "64" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }