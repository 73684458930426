var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "accent2Light" },
    [
      _c("v-col", { staticClass: "text-center" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold mb-0" },
          [
            _c("v-icon", { attrs: { color: "accent2" } }, [
              _vm._v("\n                mdi-creation\n            "),
            ]),
            _vm._v(
              "\n            Try Premium Complete FREE for 14 days\n            "
            ),
            _c("start-free-trial", {
              staticClass: "font-weight-bold fs-14 ml-1",
              class: _vm.isMobilePort ? "mt-2" : "",
              attrs: { color: "accent2", large: false },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }