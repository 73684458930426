<template>
    <div>
        <v-row align="center">
            <v-col cols="auto" class="py-0">
                <graphic
                    :title="documentInfo"
                    :thumbnail="documentInfo.is_astm"
                    :width="'60px'"
                    minHeight="240px"
                    >
                </graphic>
            </v-col>
            <v-col>
                <v-row
                    dense
                    align-content="space-between"
                    class="fill-height">
                    <v-col
                        :cols="isActiveAdmin() ? 8 : 12"
                        class="pt-0 pl-0">
                        <h2
                            class="text-h5 font-weight-bold"
                            :class="{'subtitle-1': isMobileAndTablet}"
                            >
                            {{ getBookTitle(documentInfo, documentInfo.is_astm ? false : true) }}
                        </h2>
                    </v-col>
                    <v-col
                        cols="4"
                        class="pt-0"
                        align="center"
                        v-if="isActiveAdmin()
                            && documentInfo
                            && documentInfo.content_type
                            && documentInfo.content_type.name === 'Premium Complete'">
                        <v-btn color="primary" v-bind="btnSize">
                            <span v-if="!csvLoading" @click="exportToCSV()">Export Titles List</span>
                            <v-progress-circular
                                v-else
                                :size="20"
                                indeterminate
                                color="white">
                            </v-progress-circular>
                        </v-btn>
                    </v-col>
                    <v-col
                        v-if="count"
                        cols="12">
                        <p class="primary--text mb-0 font-weight-bold">
                            Collection includes access to content and features across {{count}} titles
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <access-level-message
            class="mt-4"
            id="access_level_message"
            v-if="isSingle && '' === examId && !isPremiumSubscribed(documentInfo.sku) && !isExcludedFromPricing(documentInfo.document_id)"
            :documentInfo="documentInfo">
        </access-level-message>
        <v-row v-if="documentInfo.is_astm">
            <v-col>
                <v-alert
                    text
                    color="cdpBlue"
                    border="left"
                    icon="mdi-information-outline">
                    <p class="mb-0 accent--text">
                        The version on Digital Codes is the specific version referenced within the 2021 I-Codes.
                    </p>
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="documentInfo.adoption_notes">
            <v-col>
                <h3 class="text-h6 font-weight-bold mb-3">Adoption Note</h3>
                <p class="mb-0 text-body-1" v-html="documentInfo.adoption_notes">
                </p>
            </v-col>
        </v-row>
        <v-row v-if="documentInfo.description" class="link-color">
            <v-col>
                <h3 class="text-h6 font-weight-bold mb-3">About This Title</h3>
                <div class="text-body-1" v-html="documentInfo.description">
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import AccessLevelMessage from '../landing/AccessLevelMessage';
    import TitleCover from "../../titles/cover/Index";
    import Graphic from "../../titles/cover/Graphic";
    import {AppParameters} from '../../../AppParameters';

    export default {
        data() {
            return {
                count: 0,
                description: '',
                csvLoading: false
            }
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            isSingle: {
                required: false,
                type: Boolean,
                default: false
            },
            examId: {
                required: false,
                type: String,
                default: ''
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            TitleCover,
            Graphic,
            AccessLevelMessage
        },
        computed: {
            btnSize () {
              const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
              return size ? { [size]: true } : {};
            }
        },
        methods: {
            exportToCSV() {
                this.csvLoading = true;
                EventBus.fire('export-premium-complete-titles-list');
                this.csvLoading = false;
            },
            getExcludedDocumentIdsForSubscription() {
                return AppParameters.pricing_page_excluded_ids;
            },
            isExcludedFromPricing(id) {
                const excluded = this.getExcludedDocumentIdsForSubscription();
                if(Array.isArray(excluded) && excluded.length > 0) {
                    return excluded.includes(id);
                }
                return false;
            }
        },
        created() {
            EventBus.listen('set-collection-count', (count) => this.count = count);
        }
    }
</script>
