<template></template>
<script>
    import Vue from 'vue'
    import Hotjar from 'vue-hotjar'

    export default {
        props: {
            env: {
                type: String,
                required: false
            }
        },
        mounted() {
            Vue.use (Hotjar, {
                id: this.env === 'prod' ? '1858264' : '1783365'
            })
            if (this.$hj) {
                this.$hj('identify', this.$store.getters.getUserId ? this.$store.getters.getUserId : null, {
                    authenticated: this.$store.getters.getLoggedIn ? 'yes' : 'no',
                    has_premium: this.hasPremiumSubscription ? 'yes' : 'no',
                    has_trial: this.trialActive ? 'Yes' : 'No',
                })
            }
        }
    }
</script>
