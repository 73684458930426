<template>
    <v-container :fluid="!isExtraLarge">
        <v-row align="center">
            <v-col 
            cols="12"
            md="6"
            sm="6">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-icon
                            left
                            color="primary">
                            fa-book
                        </v-icon>
                    </v-col>
                    <v-col>
                        <h3>
                            All Included Titles
                        </h3>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="12"
                md="6"
                xl="4"
                sm="6">
                <v-text-field
                    v-model="search"
                    dense
                    clearable
                    outlined
                    rounded
                    class="white"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search across included titles">
                </v-text-field>
            </v-col>
        </v-row>
        <title-list
            :titles="titles"
            :loading="loading"
            :search="search"
            :itemsPerPage="itemsPerPage">
        </title-list>
        <v-row
            v-if="itemsPerPage < titles.length"
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    @click="itemsPerPage = itemsPerPage+10">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import TitleList from '../../content/title/TitleList.vue';

    export default {
        props: {
            documentId: {
                required: true,
                type: Number
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            TitleList
        },
        data() {
            return {
                loading: true,
                search: '',
                itemsPerPage: 10,
                titles: []
            }
        },
        methods: {
            getIncludedTitles() {
                this.$http.get(
                    Routing.generate("get_collection_titles", {documentId: this.documentId})
                ).then(response => {
                    if (response.data) {
                        this.titles = response.data;

                        const xmlOrPremiumPdfCount = this.titles.filter((title) => {
                            return title.is_xml || title.is_premium_pdf;
                        }).length;

                        EventBus.fire('set-collection-count', xmlOrPremiumPdfCount);
                    }
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.getIncludedTitles();
        }
    }
</script>

<style lang="scss" scoped>
    .included-title-card:hover {
        opacity: .7;
    }
</style>
