export class BrowserUtils {

    static isSafari() {
        return /constructor/i.test(window.HTMLElement) || (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    }

    static openInNewTab(url, openInCurrentWindowOnFail = false) {
        const newWindow = window.open(url, '_blank');

        // blocked?
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

            if (openInCurrentWindowOnFail) {
                window.location = url;
            }
        }
    }
}