var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.expand,
          expression: "expand",
        },
      ],
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                This Exact Phrase\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Bearing Wall Structure",
                  "data-aq": "proximity-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("exact")
                  },
                },
                model: {
                  value: _vm.search.exact,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "exact", $$v)
                  },
                  expression: "search.exact",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                All of the words\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Area, Building, Brick",
                  "data-aq": "proximity-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("all")
                  },
                },
                model: {
                  value: _vm.search.all,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "all", $$v)
                  },
                  expression: "search.all",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                Any of the words\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Area, Building, Brick",
                  "data-aq": "proximity-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("any")
                  },
                },
                model: {
                  value: _vm.search.any,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "any", $$v)
                  },
                  expression: "search.any",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                None of the words\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Concrete, Cement",
                  "data-aq": "proximity-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("none")
                  },
                },
                model: {
                  value: _vm.search.none,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "none", $$v)
                  },
                  expression: "search.none",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", [
            _c("h3", { staticClass: "text-center mt-2" }, [
              _vm._v("\n                OR\n            "),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                Proximity search\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Fire",
                  "data-aq": "proximity-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("proximity")
                  },
                },
                model: {
                  value: _vm.search.proximity,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "proximity", $$v)
                  },
                  expression: "search.proximity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("\n                Within 50 words of\n            "),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  clearable: "",
                  placeholder: "Ex. Content",
                  "data-aq": "near-word-search",
                  "background-color": "white",
                },
                on: {
                  keydown: _vm.keydown,
                  input: function ($event) {
                    return _vm.handleSearchInput("proximityWithin")
                  },
                },
                model: {
                  value: _vm.search.proximityWithin,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "proximityWithin", $$v)
                  },
                  expression: "search.proximityWithin",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "elevation-0",
                  attrs: { color: "primary", small: "" },
                  on: { click: _vm.contentSearch },
                },
                [_vm._v("\n                Apply\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.clearSearch()
                    },
                  },
                },
                [_vm._v("\n                Clear\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }