var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "back-to-top",
    {
      staticStyle: { "z-index": "199" },
      attrs: {
        bottom: _vm.isMobileAndTablet ? "66px" : "86px",
        right: _vm.isMobileAndTablet ? "10px" : "40px",
        visibleoffset: "400",
      },
    },
    [
      _c(
        "v-btn",
        { staticClass: "primary", attrs: { icon: "", dark: "" } },
        [_c("v-icon", [_vm._v("arrow_drop_up")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }