<template>
    <v-btn
        :large="large"
        :block="block"
        color="primary"
        target="_blank"
        href="https://www.iccsafe.org/products-and-services/technical-opinions/request-for-code-opinion/">
        Ask your question
    </v-btn>
</template>

<script>
    export default {
        name: 'AskQuestionButton',
        props: {
            large: {
                required: false,
                type: Boolean,
                default: false
            },
            block: {
                required: false,
                type: Boolean,
                default: false
            }
        }
    }
</script>
