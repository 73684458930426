var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "login" },
        [
          _vm.content
            ? _c(
                "v-col",
                { attrs: { order: "2", "order-sm": "1", cols: "12", sm: "6" } },
                [
                  _c("div", {
                    staticClass: "login-content",
                    domProps: { innerHTML: _vm._s(_vm.content) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { order: "1", "order-sm": "2", cols: "12", sm: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("h2", { staticClass: "primary--text title" }, [
                      _vm._v("Sign In"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.errors.length > 0
                        ? _c(
                            "div",
                            { staticClass: "error-message mb-4" },
                            _vm._l(_vm.errors, function (error, key) {
                              return _c(
                                "span",
                                [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: "red",
                                        "text-color": "white",
                                        small: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(error) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showResendMessage
                        ? _c(
                            "div",
                            {
                              staticClass: "error-message mb-4 red--text",
                              attrs: { small: "" },
                            },
                            [
                              _vm._v(
                                "\n                        Your email account is not verified.\n                        "
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "light",
                                  attrs: {
                                    dark: "",
                                    small: _vm.isDense,
                                    color: "accent2",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resendEmail()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Resend Email\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.textLoading
                        ? _c("v-progress-circular", {
                            staticClass: "mb-3 ml-3",
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "accent2",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.emailResponse
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "error-message mb-4 green--text font-weight-bold",
                              attrs: { small: "" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.emailResponse) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          class: { "mx-4": _vm.$vuetify.breakpoint.xsOnly },
                          attrs: {
                            "lazy-validation": "",
                            id: "login_form",
                            method: "post",
                            action: _vm.formaction,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.loginSubmit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _vm.referer != ""
                            ? _c("input", {
                                attrs: { type: "hidden", name: "_target_path" },
                                domProps: { value: _vm.referer },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "border",
                                    class: {
                                      "input-group--dirty": _vm.dirtyEmail,
                                    },
                                    attrs: {
                                      solo: "",
                                      "hide-details": "",
                                      id: "emailAddress",
                                      name: "email",
                                      maxlength: "160",
                                      placeholder: "Email address",
                                      required: "",
                                    },
                                    on: { keyup: _vm.keyMonitor },
                                    model: {
                                      value: _vm.email_address,
                                      callback: function ($$v) {
                                        _vm.email_address = $$v
                                      },
                                      expression: "email_address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-4 border",
                                    class: {
                                      "input-group--dirty": _vm.dirtyPwd,
                                    },
                                    attrs: {
                                      solo: "",
                                      "hide-details": "",
                                      id: "password",
                                      name: "password",
                                      maxlength: "50",
                                      type: "password",
                                      placeholder: "Password",
                                      required: "",
                                    },
                                    on: { keyup: _vm.keyMonitor },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("input", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    id: "rememberMe",
                                    type: "checkbox",
                                    name: "rememberMe",
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "rememberMe" } }, [
                                  _vm._v("Keep me logged in for 30 days"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.csrfToken,
                                expression: "csrfToken",
                              },
                            ],
                            attrs: { type: "hidden", name: "csrfToken" },
                            domProps: { value: _vm.csrfToken },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.csrfToken = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    large: "",
                                    type: "submit",
                                    color: "primary",
                                  },
                                  on: { click: _vm.loginSubmit },
                                },
                                [
                                  _vm._v(
                                    "\n                                Login\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("p", { staticClass: "mb-0" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    href: "https://www.iccsafe.org/myicc/?change-password=true",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Forgot Your Password?")]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }