<template>
    <v-container class="pb-0">
        <v-row>
            <v-col sm="auto">
                <title-graphic
                        :title="item.document_info"
                        thumbnail
                        width="120px"
                        additionalStyles="max-width: 120px;"
                        class="mr-1"
                ></title-graphic>
            </v-col>
            <v-col class="primary--text">
                <v-row dense>
                    <v-col sm="6">
                        <h4 class="subtitle-1 font-weight-bold">
                            <template v-if="item.document_info.year">
                                {{item.document_info.year.year}} {{item.document_info.title}}
                            </template>
                            <template v-else>
                                {{item.document_info.title}}
                            </template>
                        </h4>
                        <p class="caption mb-0 text--grey">Item #: {{item.document_info.sku}}</p>
                    </v-col>
                    <v-col class="text-right">
                        <p class="body-2 mb-0 mt-1">Subscription Price</p>
                        <span class="subheading font-weight-bold">${{ itemPrice }}</span>
                    </v-col>
                    <v-col class="text-right">
                        <p class="body-2 mb-0 mt-1">Total Item Price</p>
                        <span class="subheading font-weight-bold">${{ itemTotal }}</span>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="4">
                        <quantity :item="item"></quantity>
                    </v-col>
                    <v-col>
                        <duration :item="item"></duration>
                    </v-col>
                    <v-col
                        sm="auto"
                        align-self="end" class="mb-5">

                        <v-btn
                                color="red accent-4"
                                small
                                @click="openRemoveModal"
                                class="white--text">
                            <v-icon>mdi-delete-outline</v-icon>
                            Remove
                        </v-btn>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Item from './../mixins/item';
    import Duration from './duration';
    import Quantity from './quantity';
    import TitleGraphic from "../../titles/cover/Graphic.vue";
    import Graphic from "../../titles/cover/Graphic.vue";
    export default {
        props: {
            item: {
                required: true,
                type: Object
            },
            itemPrice: {
                required: true,
                type: String
            },
            itemTotal: {
                required: true,
                type: String
            },
            itemLoading: {
                required: false,
                type: String
            }
        },
        mixins: [Item],
        components: {
            Graphic,
            TitleGraphic,
            Duration,
            Quantity
        }
    }
</script>

<style>
    .cart-item-block {
        position: relative;
    }
    .cart-item-overlay{
        position: absolute;
        top: -16px;
        left: -15px;
        right: -16px;
        bottom: -8px;
        background-color: rgba(0,0,0, 0.2);
        transition: background-color 0.5s;
        z-index: 999;
    }
    .item-cart-progress-circular {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
</style>
