var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.viewPage && "disclaimer" }, [
    _c("h4", { staticClass: "py-3 gray--darken-1" }, [_vm._v("Disclaimer")]),
    _vm._v(" "),
    _c("p", { staticClass: "caption" }, [
      _vm._v(
        "\n        ICC Premium Answers™ consist of advisory code opinions and interpretations issued by the International Code Council, Inc. (“ICC”) staff based on the ICC model codes.  ICC Premium Answers do not imply approval of an equivalency, specific product, specific design, or specific installation and cannot be republished in any form implying ICC approval. As ICC Premium Answers are only advisory, the final code-related decision is the responsibility of the designated authority charged with the administration and enforcement of codes.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "caption" }, [
      _vm._v(
        "\n        ICC will make reasonable efforts to provide accurate information as part of its ICC Premium Answers service.  However, ICC makes no guarantees or warranties, express or implied, as to the accuracy of any information provided, including, without limitation, any warranties of merchantability or fitness for a particular purpose. ICC will not be held liable for any damages or loss, whether direct, indirect, consequential, or punitive, that may arise through your use of any information provided as part of ICC Premium Answers.\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }