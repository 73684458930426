export const DocumentModule = {
    state: {
        isDocumentPage: false,
        documentInfo: {},
        contentPremium: false,
        contentBasic: false,
        contentProtection: false,
        isFullScreenContent: localStorage.getItem('isFullScreenContent') === 'true' ||
            false,
        associatedBooks: [],
        currentSectionFromTop: null,
    },
    getters: {
        getIsDocumentPage : (state) => state.isDocumentPage,
        getDocumentInfo : (state) => state.documentInfo,
        getContentPremium : (state) => state.contentPremium,
        getContentBasic : (state) => state.contentBasic,
        getContentProtection : (state) => state.contentProtection,
        getIsFullScreenContent : (state) => state.isFullScreenContent,
        getAssociatedBooks : (state) => state.associatedBooks,
        getCurrentSectionFromTop: (state) => state.currentSectionFromTop
    },
    mutations: {
        setIsDocumentPage (state, data) {
            state.isDocumentPage = data;
        },
        setDocumentInfo (state, data) {
            state.documentInfo = data;
        },
        setContentPremium (state, data) {
            state.contentPremium = data;
        },
        setContentBasic (state, data) {
            state.contentBasic = data;
        },
        setContentProtection(state, data) {
            state.contentProtection = data;
        },
        setIsFullScreenContent (state, data) {
            localStorage.setItem('isFullScreenContent', data);
            state.isFullScreenContent = data;
        },
        setAssociatedBooks(state, data) {
            state.associatedBooks = data;
        },
        setCurrentSectionFromTop(state, data) {
            state.currentSectionFromTop = data;
        }
    }
};
