<template>
    <v-dialog
        scrollable
        v-model="data.show"
        :fullscreen="isMobileAndTablet"
        max-width="600"
        >
        <v-card height="400">
            <v-card-title
                class="primary white--text py-0"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                    }">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                       <a class="white--text" href="https://icc-es.org/" target="_blank">
                        <img
                            class="mt-n9 es-logo-text"
                            :src="asset(require('../../images/es_scope_logo.png'), 'default')"/>
                       </a>
                    </v-col>
                    <v-col class="ml-9 scope-text">
                        <h6>{{data.acNumber}} Scope</h6>
                    </v-col>
                    <v-spacer v-if="!isMobilePort"></v-spacer>
                    <v-col cols="auto">
                         <p class="font-weight-bold text-caption mb-0">
                            <a class="white--text" href="https://icc-es.org/evaluation-report-program/acceptance-criteria/" target="_blank">What is an AC Scope?</a>
                        </p>
                    </v-col>
                    <v-col cols="auto pl-10">
                        <v-icon
                            class="white--text acscope-close-icon"
                            @click="data.show = false">
                            close
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text
                v-if="data.acScopeError"
                class="pb-0">
                <v-container>
                    <v-row class="pt-5">
                        <v-col class="text-center">
                            <p v-html="data.errorMessage"></p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-text v-else class="pa-8">
                <p class="subtitle-1"><b>AC Scope Content</b></p>
                <template v-if="loading">
                    <v-container class="text-center py-12">
                        <v-progress-circular
                            class="py-6"
                            indeterminate
                            color="primary">
                        </v-progress-circular>
                    </v-container>
                </template>
                <template v-else>
                    <p v-html="acScopeContent"></p>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                loading: false,
                acScopeContent: '',
                data: {
                    show: false,
                    acNumber: '',
                    acScopeError: false,
                    errorMessage: ''
                }
            };
        },
        mixins: [Mobile],
        methods: {
            showAcScopeModal(data) {
                this.data = data;
                this.loading = true;
                this.$http.get(Routing.generate('get_ac_scope', {acId: data.acNumber}))
                    .then(response => {
                        this.acScopeContent = '';
                        if (response.data && response.data.data.ac_scope_field) {
                            this.acScopeContent = response.data.data.ac_scope_field;
                        }
                        if(!this.acScopeContent.length) {
                            this.data.errorMessage = "The requested AC scope text could not be displayed.  <br /> Please contact ES Admin (<a href=\"https://icc-es.org/contact-us/\">ICC Evaluation Service, LLC (ICC-ES)</a>) with the report and AC scope title to resolve.";
                            this.data.acScopeError = true;
                        }
                        this.loading = false;
                    });
            },
            closeModal() {
                this.data = {show: false, acScopeError: false};
            },

            showError(data) {
                this.data.errorMessage = data.errorMessage;
                this.data.acScopeError = true;
                this.data.show = true;
            }
        },
        created() {
            EventBus.listen("acScopeModal", (data) => this.showAcScopeModal(data));
        }
    };

</script>
<style lang="scss" scoped>
 .acscope-close-icon {
    font-size: 20px !important;
 }
 .es-logo-text {
    position: absolute;
    left: -20px;
    width: 70px;
    background: #0b5940;
    border: 3px solid;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
 }
 /* width */
::-webkit-scrollbar {
  width: 30px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5!important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd!important;
}
@media screen and (max-width: 600px) {
  .es-logo-text {
    top: 34px;
    left: 0;
    width: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width:900px){
    .es-logo-text {
        top: 34px;
        left: 0;
        width: 60px;
    }
    .scope-text{
        margin-left: 50px !important;
    }
}

</style>
