export class StringUtil {

    /**
     *
     * @param {number} length
     * @return {string}
     */
    static randomString(length) {

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        let counter = 0;
        let result = "";

        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }

        return result;
    }

    /**
     *
     * @param {string} search
     * @param {string} subject
     * @returns {string}
     */
    static highlightSearchString(search, subject) {

        let pos = subject.toLowerCase().indexOf(search.toLowerCase());

        if (pos > -1) {

            let before = subject.substring(0, pos);
            let middle = subject.substring(pos, pos + search.length);
            let after = subject.substring(pos + search.length);

            return `<strong>${before}</strong>${middle}<strong>${after}</strong>`;
        }

        return subject;
    }

    /**
     *
     * @param {string} email
     * @return {boolean}
     */
    static isEmailValid(email) {

        if (email) {
            return !!email.match(/\S+@\S+\.\S+/);
        }

        return false;
    }

    /**
     *
     * @param {string} str
     * @param {string} chars
     * @return {string}
     */
    static customTrim(str, chars) {
        let startFrom = 0;
        let endAt = str.length;

        if (typeof chars !== 'string') {
            chars = '';
        }

        // trim from left
        for (let i = 0; i < str.length; i++) {
            const char = str.charAt(i);

            if (chars.includes(char)) {
                startFrom++;
            } else {
                break;
            }
        }

        // trim from right
        for (let i = str.length - 1; i >= 0; i--) {
            const char = str.charAt(i);

            if (chars.includes(char)) {
                endAt--;
            } else {
                break;
            }
        }

        return str.slice(startFrom, endAt);
    }

    static findIntersectionFromStart(strOne, strTwo) {

        // find intersection in strTwo from right to left
        for (let i = strOne.length; i > 0; i--) {
            let subVal = strOne.substring(0, i);
            let index = strTwo.indexOf(subVal);
            if (index >= 0) {
                return ({position: index, length: i});
            }
        }
        return null;
    }

    static findIntersection(strOne, strTwo) {
        let bestResult = null;

        //find intersection in strOne from left to right
        for (let i = 0; i < strOne.length - 1; i++) {
            let result = this.findIntersectionFromStart(strOne.substring(i), strTwo);
            if (result) {
                if (!bestResult) {
                    bestResult = result;
                } else {
                    if (result.length > bestResult.length) {
                        bestResult = result;
                    }
                }
            }
            if (bestResult && bestResult.length >= strOne.length - i) {
                break;
            }

        }
        return bestResult;
    }

    /**
     *
     * @param {string} str
     * @returns {boolean}
     */
    static isNumeric(str) {
        return !isNaN(str);
    }

    /**
     *
     * @param {string} str
     * @returns {string}
     */
    static stripTags(str) {
        str = str.toString();
        return str.replace(/<\/?[^>]+>/gi, '');
    }

    /**
     *
     * @param {string} html
     * @returns {string}
     */
    static stripLinks(html) {
        return html.replaceAll(/<\/?a[^>]*>/gi, '');
    }

    /**
     *
     * @param {string} searchString
     * @param {string} prefix
     * @return {boolean}
     */
    static startsWithIgnoreCase(searchString, prefix) {
        if (searchString && prefix) {
            return searchString.toLowerCase().startsWith(prefix.toLowerCase());
        }

        return false;
    }

    /**
     *
     * @param {string} searchString
     * @param {string} suffix
     * @return {boolean}
     */
    static endsWithIgnoreCase(searchString, suffix) {
        if (searchString && suffix) {
            return searchString.toLowerCase().endsWith(suffix.toLowerCase());
        }

        return false;
    }

    static slugToTitle(slug) {
        // Replace hyphens with spaces
        let title = slug.replace(/-/g, ' ');

        // Remove extra spaces
        title = title.replace(/\s+/g, ' ').trim();

        // Capitalize each word
        title = title.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });

        return title;
    }

    static ucWord(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
