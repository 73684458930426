var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c("confirm-dialog", {
        attrs: {
          "is-open": _vm.dialog,
          borderStyle: "",
          showHeaderBorder: "",
          noGrayActionArea: "",
          fullscreen: _vm.isMobilePort,
        },
        on: {
          closed: function ($event) {
            return _vm.emitClosed("frequency")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v("\n        " + _vm._s(_vm.dialogTitle) + "\n    "),
                ]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c("div", { staticClass: "py-2" }, [
                    _c("h3", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.displayDocumentTitle)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-2" }, [
                    _c("h3", [_vm._v("License Quantity")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.item.licences) +
                          " licenses\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.hasLicenseRemovalRequest
                      ? _c("p", { staticClass: "mb-0 green--text darken-3" }, [
                          _vm._v(
                            "\n                Changes to " +
                              _vm._s(_vm.item.next_renewal_license) +
                              " on auto-renew\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-4" },
                    [
                      _c("h3", [_vm._v("Billing Frequency")]),
                      _vm._v(" "),
                      _vm.step === 1
                        ? _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: {
                                mandatory: "",
                                column: "",
                                dense: "",
                                "hide-details": "",
                              },
                              on: { change: _vm.updateFrequency },
                              model: {
                                value: _vm.frequency,
                                callback: function ($$v) {
                                  _vm.frequency = $$v
                                },
                                expression: "frequency",
                              },
                            },
                            [
                              _c("v-radio", {
                                staticClass: "mb-1",
                                attrs: {
                                  disabled:
                                    !_vm.getAvailableFrequencies.includes("M"),
                                  label: _vm.labelMessages.M,
                                  value: "M",
                                },
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                staticClass: "mb-1",
                                attrs: {
                                  disabled:
                                    !_vm.getAvailableFrequencies.includes("B"),
                                  label: _vm.labelMessages.B,
                                  value: "B",
                                },
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                staticClass: "mb-1",
                                attrs: {
                                  disabled:
                                    !_vm.getAvailableFrequencies.includes("C"),
                                  label: _vm.labelMessages.C,
                                  value: "C",
                                },
                              }),
                            ],
                            1
                          )
                        : [
                            _c(
                              "p",
                              { staticClass: "mb-0 green--text darken-3" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.selectedBillingType) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                    Changed from " +
                                  _vm._s(`${_vm.renewalBillingType}
                    ${
                      _vm.isAutoRenewSubscriptionType(
                        _vm.item.next_renewal_subscription_type
                      )
                        ? "(on auto-renew)"
                        : ""
                    }`) +
                                  "\n                "
                              ),
                            ]),
                          ],
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          class: _vm.isQuantityChanged
                            ? "green--text darken-3"
                            : "",
                        },
                        [
                          _vm._v(
                            "\n                Auto-renews " +
                              _vm._s(_vm.formatAutoRenewDate()) +
                              " for $" +
                              _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                              " / " +
                              _vm._s(_vm.selectedBillingType) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isQuantityChanged
                        ? _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "\n                Changed from $" +
                                _vm._s(
                                  _vm._f("formatPrice")(_vm.initialPrice)
                                ) +
                                " / " +
                                _vm._s(_vm.renewalBillingType) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.savingsText.length
                    ? _c("discount-text", {
                        attrs: { savingsText: _vm.savingsText },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("payment-method-display", { attrs: { "show-alert": "" } }),
                  _vm._v(" "),
                  _vm.step === 2 && !_vm.invalidVisaCart
                    ? _c("p", [
                        _vm._v(
                          "\n            No payment required. This change will take effect when your subscription auto-renews on " +
                            _vm._s(_vm.formatAutoRenewDate()) +
                            ".\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("contact-link-info"),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("alert-bar", {
                                attrs: {
                                  dismissible: "",
                                  id: "display_http_messages",
                                  message: _vm.message,
                                  notificationType: _vm.notifyType,
                                },
                                on: { dismiss: _vm.dismissAlert },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("action-buttons", {
                        attrs: {
                          step: _vm.step,
                          isDisableReviewBtn: _vm.isDisableReviewBtn,
                          loadingSubmit: _vm.loadingSubmit,
                        },
                        on: {
                          "go-back": _vm.handleGoBack,
                          close: function ($event) {
                            return _vm.emitClosed("frequency")
                          },
                          "review-changes": _vm.reviewChanges,
                          "submit-and-pay": _vm.submitAndPay,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1503384748
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }