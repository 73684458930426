var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    attrs: { src: _vm.asset(_vm.src), alt: "" },
    on: {
      load: function ($event) {
        return _vm.$emit("load")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }