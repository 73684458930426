export default {
    data(){
        return {
            categoriesWithPdf: [
                {
                    'years': [2015, 2018],
                    'name': 'I-Codes'
                },
                {
                    'years': [2015, 2018],
                    'name': 'Commentaries'
                },
                {
                    'year': [2019],
                    'name': 'California'
                },
            ]
        }
    }
}
