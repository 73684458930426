
export const NotificationModule = {
    namespaced: true,
    state: {
        notificationType: null,
        notificationMessage: ''
    },
    getters: {
        notificationMessage: (state) => state.notificationMessage,
        notificationType: (state) => state.notificationType,
    },
    mutations: {
        SET_NOTIFICATION_MESSAGE(state, value) {
            state.notificationMessage = value.message;
            state.notificationType = !value.success ? 'error' : 'success';
        },
        RESET_NOTIFICATION_MESSAGE(state) {
            state.notificationMessage = '';
            state.notificationType = null;
        }
    },
    actions: {
        setNotificationMessage({ commit }, message) {
            commit('SET_NOTIFICATION_MESSAGE', message);
        },
        dismissAlert({ commit }){
            commit('RESET_NOTIFICATION_MESSAGE');
        },
        displayErrorMsg({ dispatch }, error) {
            let notifyObj  = {
                success: false,
                message: ''
            };
            if (!error.response) {
                notifyObj.message = 'Network error: Something went wrong or you are currently offline.';
            } else {
                notifyObj.message = error.response.data?.error || 'An unexpected error occurred.';
            }
            dispatch('setNotificationMessage', notifyObj); // dispatch the action
        }
    }
}
