<template>
    <div>
        <alert id="display_http_messages"
            :message="notificationMessage"
            :notificationType="notificationType"
            dismissible
            @dismiss="dismissAlert">
        </alert>
        <new-question-form
            v-if="askQuestionFormVisible"
            :creditBalance="userCreditsInfo?.balance">
        </new-question-form>
        <question-view v-else-if="showQuestion" :id="showQuestion"></question-view>
        <template v-else>
            <your-questions-intro-block
                class="mb-5"
                :userCreditsInfo="userCreditsInfo"
                @changeCreditBalance="updateCreditBalance">
            </your-questions-intro-block>
            <your-questions-list></your-questions-list>
        </template>
    </div>
</template>

<script>
import YourQuestionsIntroBlock from "./questions/YourQuestionsIntroBlock.vue";
import YourQuestionsList from "./questions/YourQuestionsList.vue";
import NewQuestionForm from "./questions/NewQuestionForm.vue";
import QuestionView from "./questions/QuestionView.vue";
import Alert from '../notifications/AlertBar.vue';
import { QuickConsultUserCreditsApi } from "../../api/QuickConsultUserCreditsApi";

import { mapActions, mapGetters } from 'vuex';

export default {
    components: {QuestionView, NewQuestionForm, YourQuestionsList, YourQuestionsIntroBlock, Alert},
    data() {
        return {
            askQuestionFormVisible: false,
            showQuestion: null,
            userCreditsInfo: null
        }
    },
    props: {
        isSuccessTrue: {
            type: Boolean,
            required: false
        }
    },
    created() {
        const path = window.location.pathname;
        this.askQuestionFormVisible = path.includes('/ask-question');

        const match = (path.match(/\/quick-consult\/(\d+)/));
        if (match !== null) {
            this.showQuestion = match[1];
        }
        //if coming with success
        if (this.isSuccessTrue) {
            const notifyObj = {
                success: true,
                message: 'Question submitted successfully. Please allow 2 business days for a response from the ICC team.'
            }
            this.setNotificationMessage(notifyObj);
        }
        if (this.isAuthenticated) {
            this.getUserCredits();
        }

        EventBus.listen('update-credits', () => {
            this.getUserCredits();
        });
    },
    computed: {
        ...mapGetters('NotificationModule', [
            'notificationType',
            'notificationMessage'
        ]),
    },
    methods: {
        ...mapActions('NotificationModule', [
            'setNotificationMessage',
            'dismissAlert',
            'displayErrorMsg'
        ]),
        updateCreditBalance(val){
            this.userCreditsInfo.balance = val;
        },
        async getUserCredits() {
            try {
                this.userCreditsInfo = await QuickConsultUserCreditsApi.getUserCreditsInfo();
            } catch (error) {
                this.displayErrorMsg(error);
                setTimeout(() => this.$scrollTo(
                    document.getElementById('display_http_messages'),
                    {offset: -140}
                ), 500);
            }
        },
    }
}
</script>