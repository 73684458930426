<template>
    <v-card
        flat
        rounded
        :color="options && options.color">
        <v-card-text>
            <v-row v-if="image">
                <v-col class="text-center">
                    <img
                        :class="image.class"
                        :width="image.width"
                        :src="asset(require('../../images/'+image.src), 'default')"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <h2
                        class="primary--text font-weight-regular line-height"
                        v-html="heading">
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <slot name="description"></slot>
                    <div v-html="description"></div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            image: {
                required: false,
                type: Object
            },
            heading: {
                required: true,
                type: String
            },
            description: {
                required: false,
                type: String
            },
            options: {
                required: false,
                type: Object
            }
        }
    }
</script>

<style scoped lang="scss">
img {
    max-width: 100%;
    margin-bottom: 1em;
}
.line-height {
    line-height: 30px !important;
}
</style>
