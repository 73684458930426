var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "hero-section",
        { staticClass: "py-8" },
        [
          _c("h3", { staticClass: "roboto text-center text-uppercase mb-5" }, [
            _vm._v("Browse Available Contents By:"),
          ]),
          _vm._v(" "),
          _c("category-collection-buttons", {
            attrs: { "x-large-buttons": "" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("carousel-banner", { attrs: { "additional-classes": "my-10" } }),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "elevation-2 primary" },
        [_c("premium-upsell")],
        1
      ),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "py-8 grey lighten-4" },
        [_c("upsell-mobile")],
        1
      ),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "py-5" },
        [_c("popular-publishers")],
        1
      ),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "grey lighten-4" },
        [_c("customer-testimonials")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "primary py-10 elevation-2" },
        [_c("premium-compare")],
        1
      ),
      _vm._v(" "),
      _c("v-container", [_c("category-map")], 1),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "py-2" },
        [_c("popular-code-collections")],
        1
      ),
      _vm._v(" "),
      _c("hero-section", { staticClass: "grey lighten-4 py-5" }, [
        _c(
          "div",
          { staticClass: "roboto" },
          [
            _c(
              "h3",
              { staticClass: "primary--text text-uppercase text-center mb-5" },
              [_vm._v("Contents by Industry Topic")]
            ),
            _vm._v(" "),
            _c(
              "v-row",
              _vm._l(_vm.contentsByIndustryTopic, function (item, i) {
                return _c(
                  "v-col",
                  { key: i, attrs: { cols: "12", lg: "4" } },
                  [
                    _c("v-avatar", {
                      staticClass: "mr-2",
                      attrs: { color: "#FFA201", size: "5", rounded: "0" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: _vm.getTopicUrlFromName(item) } },
                      [_vm._v(_vm._s(item))]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hero-section", [_c("stay-connected")], 1),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "py-8 elevation-2 primary" },
        [_c("who-we-are")],
        1
      ),
      _vm._v(" "),
      _c(
        "hero-section",
        { staticClass: "py-8" },
        [_c("frequently-asked-questions")],
        1
      ),
      _vm._v(" "),
      _c("hero-section", { staticClass: "py-5" }, [_c("subscribe-now")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }