<template>
    <v-card flat>
        <v-expansion-panels
            v-model="defaultExpansion"
            class="notes-expansion-panel">
            <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="pt-2 pb-0 px-0">
                    <v-row dense>
                        <v-col>
                            <v-icon class="text--secondary">{{ expansionState ? 'mdi-chevron-right' : 'mdi-chevron-up' }}</v-icon>
                            <slot name="title"></slot>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-6">
                    <v-row dense>
                        <v-col>
                            <slot name="content"></slot>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    export default {
        emits: ['changeExpansion'],
        data() {
            return {
                defaultExpansion: this.expand ? 0 : 1,
            }
        },
        watch:{
            expand(newVal){
                if(newVal) {
                    this.defaultExpansion = newVal ? 0 : 1;
                }
            },
            defaultExpansion(newVal){
                this.$emit('changeExpansion', newVal);
            }
        },
        computed: {
            expansionState(){
                return this.defaultExpansion ?? true;
            }
        },
        props: {
            expand: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>
