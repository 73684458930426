var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iFrameContent",
    attrs: {
      title: "",
      src: _vm.iFrameUrl,
      width: "100%",
      height: _vm.tag.tag.iframe_height ? _vm.tag.tag.iframe_height : 500,
      frameborder: "0",
    },
    on: {
      load: function ($event) {
        _vm.tag.tag.iframe_height
          ? null
          : _vm.updateFrameHeight(_vm.$refs.iFrameContent)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }