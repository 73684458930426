<template>
    <v-container
        style="min-height: 100vh"
        fluid
        class="grey lighten-5"
        data-qa="activity-report">
        <enterprise-name></enterprise-name>
        <v-row class="mx-2">
            <v-col>
                <enterprise-nav :handleTabClick="tabClicked" :tab="2">
                </enterprise-nav>
            </v-col>
        </v-row>
        <v-row class="mx-2">
            <v-col>
                <keep-alive>
                    <account-overview v-if="panel === 'Account Overview'"></account-overview>
                    <manage-users v-else-if="panel === 'Manage Users'"></manage-users>
                    <activity-report v-else :profile-id="profileId"></activity-report>
                </keep-alive>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import EnterpriseNav from './../partials/enterprise_nav';
    import ActivityReport from './partials/activity_report';
    import ManageUsers from './../users/partials/manage_users';
    import AccountOverview from './../overview/partials/account_overview';
    import EnterpriseName from './../partials/enterprise_name';

    export default {
        props: ["profileId"],
        data: function () {
            return {
                loading: true,
                panel: 'Activity Report'
            };
        },
        mixins: [Mobile],
        components: {
            EnterpriseNav,
            ActivityReport,
            ManageUsers,
            AccountOverview,
            EnterpriseName
        },
        methods: {
            tabClicked(activeTab){
                this.panel = activeTab;
            }
        },
        computed: {
            getActivePanel() {
                return this.panel;
            }
        }
    }
</script>
