<template>
    <v-card
        flat
        class="cart_checkout_card"
        :class="{ 'ml-2': $vuetify.breakpoint.mdAndUp }">
        <v-toolbar
            color="primary"
            flat
            dark>
            <v-toolbar-title class="title font-weight-thin">Summary</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-container class="pa-0">
                <v-row>
                    <v-col class="primary--text">
                        Subtotal (<span>{{ itemCount }}</span> {{ getItemText }})
                    </v-col>
                    <v-col cols="auto">
                        <span class="font-weight-bold">${{ totalPrice }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="black--text">
                        <p>Have a Discount Code? You can enter it when you checkout.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            depressed
                            color="accent2"
                            @click="goToCheckout">
                            Proceed to Checkout
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        computed: {
            getItemText() {
                return (this.itemCount > 1) ? 'Items' : 'Item';
            },
        },
        props: {
            itemCount: {
                type: Number,
                required: true
            },
            totalPrice: {
                type: String,
                required: true
            }
        },
        methods: {
            goToCheckout() {
                EventBus.fire('go_to_checkout');
            }
        }
    }
</script>

<style lang="scss">
    .cart_checkout_card {
        .v-card__text {
            border: 1px solid #0B5940;
        }
    }
</style>
