var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "font-weight-bold oxygen fs-24",
              class: {
                "caption mobile-title": _vm.isMobilePort,
              },
              style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
            },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "accent2" } },
                [_vm._v("\n                    mdi-creation\n                ")]
              ),
              _vm._v("\n                Premium Code Tools\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-0 pb-2" },
            [
              _c(
                "v-row",
                { staticClass: "grey lighten-4 pa-0 ma-0" },
                [
                  _c("v-col", { staticClass: "text-center pa-0" }, [
                    _c("img", {
                      staticClass: "section-img",
                      attrs: {
                        alt: "Premium Tools",
                        src: _vm.asset(
                          require("../../images/premium_tools/codes-code-tools.png"),
                          "default"
                        ),
                      },
                    }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass: "overflow-hidden text-center pa-3 body-1",
                      },
                      [
                        _vm._v(
                          "\n                        Copy codes, print code sections, bookmark and more with a Premium subscription.\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      !_vm.isAuthenticated || _vm.canStartTrial
                        ? _c("start-free-trial", {
                            staticClass: "mx-1",
                            attrs: {
                              large: false,
                              directStartTrial: _vm.canStartTrial,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          class: _vm.isTrialAvailable ? "mx-1" : "",
                          attrs: {
                            outlined: _vm.isTrialAvailable,
                            depressed: "",
                            color: _vm.isTrialAvailable ? "primary" : "accent2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openSubscribeToPremiumDialog()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Subscribe To This Title\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }