var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mx-auto note-preview",
      style: _vm.style,
      attrs: {
        elevation: "8",
        id: "notePreview" + _vm.note.id,
        outlined: "",
        "max-width": "350px",
      },
      on: {
        mouseover: function ($event) {
          _vm.mouse = true
        },
        mouseleave: function ($event) {
          _vm.mouse = false
          _vm.card = false
        },
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { staticClass: "px-4 pt-2" }, [
            _c("h4", { staticClass: "mb-1" }, [_vm._v("My Note")]),
            _vm._v(" "),
            _vm.noteText
              ? _c("div", { domProps: { innerHTML: _vm._s(_vm.noteText) } })
              : _vm._e(),
            _vm._v(" "),
            _c("h5", { staticClass: "font-weight-regular mb-2" }, [
              _vm._v("Project Tag: " + _vm._s(_vm.tagName)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }