<template>
    <div>
        <v-chip
            v-if="applyBackground"
            class="px-1"
            label
            :color="getProfileColor(profile)">
            <v-icon>
                {{ icon }}
            </v-icon>
        </v-chip>
        <v-icon
            v-else
            :color="getIconColor()">
            {{ icon }}
        </v-icon>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                icon: ''
            }
        },
        props: {
            profile: {
                required: true,
                type: Object
            },
            applyBackground: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        methods: {
            setData() {
                switch (this.profile.type) {
                    case 'personal':
                        this.icon = 'mdi-account';
                        break;
                    case 'concurrent':
                        this.icon = 'mdi-account-supervisor';
                        break;
                    case 'enterprise':
                        this.icon = 'mdi-office-building';
                        break;
                }
            },
            getIconColor() {
                if (this.profile.is_active) {
                    return this.profile.name.toLowerCase() === 'basic' ?
                        'white' :
                        'black'
                }
                return this.getProfileColor(this.profile)
            }
        },
        mounted() {
            this.setData();
        }
    }
</script>
