<template>
    <div class="roboto">

        <h3 class="primary--text text-uppercase text-center mb-5 font-weight-bold">
            Popular Custom Code Collections by State
        </h3>

        <v-row>
            <v-col cols="12" lg="3"
                   v-for="(item,i) in popularSearches"
                   :key="i">

                <v-avatar
                        color="accent2"
                        size="5" rounded="0" class="mr-2">
                </v-avatar>

                <a :href="getUrl(item.param)">{{ item.label }}</a>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    data() {
        return {
            popularSearches: [
                {
                    label: 'California Codes',
                    param: {category: this.convertCategoryToSlug('California')}
                },
                {
                    label: 'New Jersey Codes',
                    param: {category: this.convertCategoryToSlug('New Jersey')}
                },
                {
                    label: 'New York City Codes',
                    param: {category: this.convertCategoryToSlug('New York City')}
                },
                {
                    label: 'Ohio Codes',
                    param: {category: this.convertCategoryToSlug('Ohio')}
                },
                {
                    label: 'Virginia Codes',
                    param: {category: this.convertCategoryToSlug('Virginia')}
                },
                {
                    label: 'Florida Codes',
                    param: {category: this.convertCategoryToSlug('Florida')}
                },
                {
                    label: 'New York State Codes',
                    param: {category: this.convertCategoryToSlug('New York')}
                },
                {
                    label: 'North Carolina Codes',
                    param: {category: this.convertCategoryToSlug('North Carolina')}
                },
                {
                    label: 'South Carolina Codes',
                    param: {category: this.convertCategoryToSlug('South Carolina')}
                },
                {
                    label: 'Washington State Codes',
                    param: {category: this.convertCategoryToSlug('Washington')}
                }
            ]
        }
    },
    methods: {
        convertCategoryToSlug(category) {
            return _.toLower(_.replace(category, /\s+/g, '-'));
        },
        getUrl(param){
            return this.getRoute('codes_category', param);
        }
    }
}
</script>
