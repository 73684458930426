var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("breadcrumb", { attrs: { title: "Move Notes" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { class: { "pr-3": _vm.isMobilePort } },
        [
          _c(
            "v-row",
            { attrs: { "justify-xl": "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "10" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "primary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", [_vm._v("loop")]),
                              _vm._v(" Move Notes\n                        "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-2 pb-0" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("p", [
                                        _vm._v(
                                          'Use the tool below to move or clone your notes to a newer book version.  You can use the "Select All Notes" to select all your notes or select each note individually.  You can also use the "Move All" and "Clone All" buttons to move/clone all your notes at one time.  When you move a note it moves it from one version to another.  When you clone a note it duplicates it for the other version.  After the process starts we will let you know if you need to update any notes.'
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-container",
                            { staticClass: "white pt-0" },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-col", [
                                            _c(
                                              "p",
                                              { staticClass: "caption mb-0" },
                                              [_vm._v("Move Notes From")]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: { dense: "", align: "center" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("graphic", {
                                                attrs: {
                                                  title: _vm.migrateFromBook,
                                                  thumbnail: "",
                                                  width: "40px",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", [
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bookLink(
                                                      _vm.migrateFromBook
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("book-title", {
                                                  staticClass:
                                                    "data-notes-titles ml-2",
                                                  attrs: {
                                                    title: _vm.getBookTitle(
                                                      _vm.migrateFromBook
                                                    ),
                                                    print_version_edition:
                                                      _vm.printEdition(
                                                        _vm.migrateFromBook
                                                          .print_version_edition
                                                      ),
                                                    printing_text:
                                                      _vm.migrateFromBook
                                                        .print_info,
                                                    print_version:
                                                      _vm.migrateFromBook
                                                        .print_version,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "d-inline-block",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass: "mt-0 any-book",
                                                  attrs: {
                                                    row: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                  },
                                                  model: {
                                                    value: _vm.anyBook,
                                                    callback: function ($$v) {
                                                      _vm.anyBook = $$v
                                                    },
                                                    expression: "anyBook",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label:
                                                        "Move notes to a different version of this book",
                                                      value: false,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label:
                                                        "Move notes to a different premium book",
                                                      value: true,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  required: "",
                                                  "hide-details": "",
                                                  items: _vm.migrateToBooks,
                                                  "item-text": "display_title",
                                                  "item-value": "document_id",
                                                  label:
                                                    "Select a book to move to",
                                                  solo: "",
                                                  clearable: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              align: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c("graphic", {
                                                                  attrs: {
                                                                    title: item,
                                                                    thumbnail:
                                                                      "",
                                                                    width:
                                                                      "40px",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          item.display_title
                                                                        ) +
                                                                        "\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "caption mb-0",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          item.print_version
                                                                        ) +
                                                                        "\n                                                            "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "no-data",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c("v-col", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "caption text-center mt-4",
                                                                },
                                                                [
                                                                  _vm.loading
                                                                    .autoComplete
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                                                                    Loading Books\n                                                                "
                                                                        ),
                                                                      ]
                                                                    : _vm
                                                                        .migrateToBooks
                                                                        .length ===
                                                                        0 &&
                                                                      !_vm
                                                                        .loading
                                                                        .autoComplete
                                                                    ? [
                                                                        _vm._v(
                                                                          "\n                                                                    No books found for this selection\n                                                                "
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _vm._v(
                                                                          "\n                                                                    No books found for this search criteria\n                                                                "
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              align: "center",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.setMigrateToBook(
                                                                  item.document_id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "auto",
                                                                },
                                                              },
                                                              [
                                                                _c("graphic", {
                                                                  attrs: {
                                                                    title: item,
                                                                    thumbnail:
                                                                      "",
                                                                    width:
                                                                      "40px",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-col", [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "subtitle-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        item.display_title
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "caption mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        item.print_version
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.selectedDocument,
                                                  callback: function ($$v) {
                                                    _vm.selectedDocument = $$v
                                                  },
                                                  expression:
                                                    "selectedDocument",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-container",
                            { staticClass: "grey lighten-3" },
                            [
                              _vm.filteredResults.length > 0
                                ? [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.migrateAction(
                                                      "Move"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Move\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.migrateAction(
                                                      "Clone"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Clone\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                "hide-details": "",
                                                label: "Select All",
                                              },
                                              model: {
                                                value: _vm.selectAllCheckbox,
                                                callback: function ($$v) {
                                                  _vm.selectAllCheckbox = $$v
                                                },
                                                expression: "selectAllCheckbox",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-data-iterator",
                                        {
                                          attrs: {
                                            items: _vm.filteredResults,
                                            "hide-default-footer": "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  _vm._l(
                                                    data.item.chapter,
                                                    function (
                                                      chapters,
                                                      chapterId
                                                    ) {
                                                      return [
                                                        _vm._l(
                                                          chapters,
                                                          function (
                                                            sections,
                                                            sectionId
                                                          ) {
                                                            return _vm._l(
                                                              sections,
                                                              function (
                                                                result,
                                                                noteId
                                                              ) {
                                                                return result instanceof
                                                                  Object
                                                                  ? _c(
                                                                      "v-row",
                                                                      {
                                                                        key: noteId,
                                                                        attrs: {
                                                                          align:
                                                                            "center",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "auto",
                                                                              },
                                                                          },
                                                                          [
                                                                            result.type ==
                                                                              "annotation" ||
                                                                            result.type ==
                                                                              "highlight"
                                                                              ? _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pa-0 ma-0",
                                                                                    class:
                                                                                      {
                                                                                        "body-2":
                                                                                          _vm
                                                                                            .$vuetify
                                                                                            .breakpoint
                                                                                            .smAndDown,
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        id:
                                                                                          "checkbox_" +
                                                                                          result.id,
                                                                                        "hide-details":
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled:
                                                                                          !result.isCodeAdmin,
                                                                                        value:
                                                                                          "annotation_" +
                                                                                          result.id,
                                                                                      },
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.childChecked(
                                                                                              chapterId,
                                                                                              "annotation_" +
                                                                                                result.id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .selectedBookValues
                                                                                            .sections,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.selectedBookValues,
                                                                                              "sections",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "selectedBookValues.sections",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            result.type ==
                                                                            "bookmark"
                                                                              ? _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pa-0 ma-0",
                                                                                    class:
                                                                                      {
                                                                                        "body-2":
                                                                                          _vm
                                                                                            .$vuetify
                                                                                            .breakpoint
                                                                                            .smAndDown,
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        id:
                                                                                          "checkbox_" +
                                                                                          result.bookmarkId,
                                                                                        "hide-details":
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled:
                                                                                          !result.isCodeAdmin,
                                                                                        value:
                                                                                          "bookmark_" +
                                                                                          result.bookmarkId,
                                                                                      },
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.childChecked(
                                                                                              chapterId,
                                                                                              "bookmark_" +
                                                                                                result.bookmarkId
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .selectedBookValues
                                                                                            .sections,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.selectedBookValues,
                                                                                              "sections",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "selectedBookValues.sections",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          [
                                                                            _c(
                                                                              "v-card",
                                                                              [
                                                                                _c(
                                                                                  "v-card-title",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pa-2 pb-0",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "a",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            target:
                                                                                              "_blank",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.redirectToSection(
                                                                                                data
                                                                                                  .item
                                                                                                  .info
                                                                                                  .document_id,
                                                                                                chapterId,
                                                                                                sectionId
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "h3",
                                                                                          {
                                                                                            staticClass:
                                                                                              "subtitle-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                            " +
                                                                                                _vm._s(
                                                                                                  result.section
                                                                                                ) +
                                                                                                "\n                                                                        "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pa-2 py-0",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          [
                                                                                            result.type ==
                                                                                              "note" ||
                                                                                            result.type ==
                                                                                              "annotation"
                                                                                              ? _c(
                                                                                                  "p",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "note-list-quote font-italic pa-1 mb-0 mt-1",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                                " +
                                                                                                        _vm._s(
                                                                                                          result.content
                                                                                                        ) +
                                                                                                        "\n                                                                            "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _vm.getDescription(
                                                                                              result
                                                                                            )
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mt-2 ml-1 description",
                                                                                                    domProps:
                                                                                                      {
                                                                                                        innerHTML:
                                                                                                          _vm._s(
                                                                                                            _vm.getDescription(
                                                                                                              result
                                                                                                            )
                                                                                                          ),
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            dense:
                                                                                              "",
                                                                                            align:
                                                                                              "center",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                cols: "auto",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    depressed:
                                                                                                      "",
                                                                                                    fab: "",
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          _vm.getTagColor(
                                                                                                            result.tagId
                                                                                                          ),
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                                    " +
                                                                                                        _vm._s(
                                                                                                          _vm.getIcon(
                                                                                                            result.type
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                                                                                "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-col",
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Created By: " +
                                                                                                    _vm._s(
                                                                                                      result.creatorName
                                                                                                    ) +
                                                                                                    "\n                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Project Tag:\n                                                                                " +
                                                                                                    _vm._s(
                                                                                                      _vm.getTagName(
                                                                                                        result.tagId
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Timestamp: " +
                                                                                                    _vm._s(
                                                                                                      _vm._f(
                                                                                                        "moment"
                                                                                                      )(
                                                                                                        result.created_at,
                                                                                                        "MMMM Do, YYYY"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    !result.isCodeAdmin
                                                                                      ? _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                dense:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              [
                                                                                                _c(
                                                                                                  "p",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "caption error--text mb-0",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "*\n                                                                                You do not have permission to migrate this note.\n                                                                            "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              }
                                                            )
                                                          }
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "template",
                                            { slot: "no-data" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center py-6",
                                                    },
                                                    [
                                                      _vm.filteredResults
                                                        .length == 0 && _vm.load
                                                        ? [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mb-0",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "You do not have any bookmarks or notes."
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm.filteredResults
                                                            .length == 0
                                                        ? [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                              }
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.filteredResults.length > 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.migrateAction(
                                                    "Move"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Move\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.migrateAction(
                                                    "Clone"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Clone\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "700" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "primary white--text",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.dialogHeader) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-4 text-center body-2" },
                    [
                      _vm.processPercentage < 1
                        ? _c("p", {
                            staticClass: "mt-4",
                            domProps: { innerHTML: _vm._s(_vm.dialogMessage) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "mt-2",
                        domProps: { innerHTML: _vm._s(_vm.migrationExists) },
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "mt-2",
                        domProps: { innerHTML: _vm._s(_vm.migrationPendings) },
                      }),
                      _vm._v(" "),
                      _vm.procesingMigrations
                        ? _c(
                            "p",
                            [
                              _c(
                                "v-progress-linear",
                                {
                                  staticClass: "mt-8",
                                  attrs: { height: "25", reactive: "" },
                                  model: {
                                    value: _vm.processPercentage,
                                    callback: function ($$v) {
                                      _vm.processPercentage = $$v
                                    },
                                    expression: "processPercentage",
                                  },
                                },
                                [
                                  _c("strong", { staticClass: "white--text" }, [
                                    _vm._v(_vm._s(_vm.processPercentage) + "%"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.procesingMigrations && _vm.processPercentage
                        ? _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.processCounter) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      !_vm.procesingMigrations &&
                      (_vm.migrationPendings.length ||
                        (_vm.dialogMessage.length && !_vm.error))
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "red white--text" },
                              on: { click: _vm.closeDialog },
                            },
                            [
                              _vm._v(
                                "\n                        No\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.viewBookButton && !_vm.migrationPendings.length
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "red white--text" },
                              on: { click: _vm.closeDialog },
                            },
                            [
                              _vm._v(
                                "\n                        Close\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.procesingMigrations || !_vm.error
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !!_vm.procesingMigrations,
                                color: "primary",
                              },
                              on: { click: _vm.processMigrations },
                            },
                            [
                              _vm._v(
                                "\n                        Continue\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.viewBookButton
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !!_vm.procesingMigrations,
                                color: "primary",
                              },
                              on: { click: _vm.goToTargetBook },
                            },
                            [
                              1 == this.isOwnUser
                                ? _c("span", [
                                    _vm._v(
                                      "\n                            View Book\n                        "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                            View Notes\n                        "
                                    ),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.migrationPendings.length
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.goToNotesCompare },
                            },
                            [
                              _vm._v(
                                "\n                        Process Manually\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.error &&
                      !_vm.migrationPendings.length &&
                      !_vm.viewBookButton
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.closeDialog },
                            },
                            [
                              _vm._v(
                                "\n                        Ok\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("back-to-top"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }