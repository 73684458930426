var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        { staticClass: "faqs-expand", attrs: { accordion: "", flat: "" } },
        _vm._l(_vm.faqs, function (item, key) {
          return _c(
            "v-expansion-panel",
            { key: key },
            [
              _c("v-expansion-panel-header", { staticClass: "px-0 py-3" }, [
                _c("h3", { staticClass: "fs-20 font-weight-medium" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.title) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("v-expansion-panel-content", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(item.description) },
                }),
              ]),
              _vm._v(" "),
              _c("v-divider"),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }