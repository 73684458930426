<template>
    <v-card
        flat
        id="autocomplete-results"
        class="autocomplete-results">
        <div v-if="!isLoading">
            <template v-for="(item) in results">
                <title-list
                    :title="item"
                    type="list"
                    :showVersion="true"
                    :showFavorite="true"
                    :showLinkIcon="false"
                    :preventLink="true"
                    :listOutline="false">
                </title-list>
                <v-divider v-if="results.length"></v-divider>
            </template>
        </div>
        <v-layout row v-else>
            <v-flex class="xs12 px-4">
                <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import TitleList from "../../titles/cover/Index";

    export default {
        props: ['results', 'isLoading', 'search', 'position', 'fromFavorites'],
        components: {
            TitleList
        },
    }
</script>

<style lang="scss">
    #autocomplete-results {
        border: 1px solid #adadad;
        max-height: 250px;
        overflow: auto;
    }
</style>
