var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("premium-answers-header"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container pt-0" },
            [
              _c("account-access-expand"),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _vm.loading
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [_c("loading", { staticClass: "my-12" })],
                                    1
                                  ),
                                ],
                                1
                              )
                            : [
                                _c(
                                  "v-card-text",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "fs-16",
                                              attrs: {
                                                href: _vm.getRoute(
                                                  "premium_answers"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mt-n1",
                                                  attrs: {
                                                    color: "primary",
                                                    left: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    mdi-arrow-left\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                                                All questions\n                                            "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-divider"),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", [
                                          _c("h2", {
                                            staticClass: "mb-6",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.premiumAnswer.question
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "question-details",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.premiumAnswer
                                                  .questionInDetail
                                              ),
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c("v-col", [
                                          _c("h2", [
                                            _vm._v(
                                              "\n                                                Answer from ICC Team\n                                            "
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c("access-icon", {
                                              attrs: {
                                                accessLevel:
                                                  _vm.hasPremiumSubscription
                                                    ? "premiumActive"
                                                    : "premium",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  {
                                    class:
                                      !_vm.premiumAnswer.access &&
                                      "accent2Light text-center",
                                    style: `border-left: 5px solid ${_vm.$vuetify.theme.themes.light.accent2}!important;`,
                                  },
                                  [
                                    _vm.premiumAnswer.access
                                      ? [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c("div", {
                                                  staticClass:
                                                    "question-details",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.premiumAnswer.answer
                                                    ),
                                                  },
                                                }),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            [
                                              _vm.premiumAnswer.books
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "gray--darken-1 font-weight-medium view-page-sub-header",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Relevant Code Sections\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.premiumAnswer.books,
                                                        function (item, key) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              key: key,
                                                              staticClass:
                                                                "my-1",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: item.targetUrl,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        item.bookName
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "premium-answer-disclaimer",
                                                    {
                                                      attrs: { viewPage: true },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.$store.getters.getLoggedIn !== ""
                                            ? _c(
                                                "v-expand-transition",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.premiumAnswer
                                                              .showRatings,
                                                          expression:
                                                            "premiumAnswer.showRatings",
                                                        },
                                                      ],
                                                      attrs: {
                                                        dense: "",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "fs-16 mb-0 font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        Was this answer helpful?\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("v-rating", {
                                                            attrs: {
                                                              "background-color":
                                                                "primary",
                                                              color: "warning",
                                                              hover: "",
                                                              length: "5",
                                                              size: "32",
                                                              value:
                                                                _vm.ratingValue,
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.ratingUpdated,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _c(
                                          "div",
                                          { staticClass: "accent2Light" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "accent--text",
                                                        attrs: { size: "34px" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    mdi-lock-outline\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "h2",
                                                      { staticClass: "mt-4" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Subscribe to Digital Codes Premium to view full answer\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mt-3 fs-16 mb-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    This feature requires a Digital Codes Premium subscription\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("ask-search-share", {
                                                      attrs: { center: "" },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("start-free-trial"),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("v-divider"),
                                _vm._v(" "),
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "auto" } },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "gray--darken-1 font-weight-medium view-page-sub-header mb-1",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Titles\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("show-titles", {
                                              attrs: {
                                                booksInfo:
                                                  _vm.premiumAnswer.booksInfo,
                                                answerViewPage: true,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            class:
                                              !_vm.isMobilePort && "text-right",
                                          },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "gray--darken-1 font-weight-medium view-page-sub-header mb-1",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Topics\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("show-topics", {
                                              attrs: {
                                                topics:
                                                  _vm.premiumAnswer.topics,
                                                answerViewPage: true,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.relatedPremiumAnswers.length
                        ? [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "h2",
                                    { staticClass: "font-weight-regular mt-6" },
                                    [
                                      _vm._v(
                                        "\n                                        Related questions\n                                    "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.relatedPremiumAnswers.length
                              ? _c("answer-list", {
                                  attrs: {
                                    loading: _vm.relatedAnswersLoading,
                                    "premium-answers":
                                      _vm.relatedPremiumAnswers,
                                    "show-more": false,
                                    "show-scroll-to-top": false,
                                  },
                                  on: {
                                    titleClick: (data) =>
                                      _vm.goToRoute("premium_answers", {
                                        document: data,
                                      }),
                                    topicClick: (data) =>
                                      _vm.goToRoute("premium_answers", {
                                        topic: data,
                                      }),
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("what-is-premium-answers"),
                      _vm._v(" "),
                      _vm.getId()
                        ? _c("recently-viewed-questions", {
                            staticClass: "mt-3",
                            attrs: { id: _vm.getId() },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("DidYouKnow"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }