var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-notes", attrs: { "data-qa": "dashboard-notes" } },
    [
      _c("breadcrumb", { attrs: { title: "My Notes" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { id: "contentParent" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xl: "10", "offset-xl": "1" } },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        color: "primary",
                        type: "info",
                        dismissible: "",
                      },
                      model: {
                        value: _vm.topBanner,
                        callback: function ($$v) {
                          _vm.topBanner = $$v
                        },
                        expression: "topBanner",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    The Annotations and Reports pages have combined together into the My Notes page. Annotations\n                    created for tags and bookmarks are called Notes.\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.error_status
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { xl: "10", "offset-xl": "1" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            value: _vm.error_status,
                            color: "cyan",
                            icon: "info",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.error_message) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { id: "tableHeadingContainer" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xl: "10", "offset-xl": "1" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "",
                              "menu-props": { bottom: true, offsetY: true },
                              label: "All Project Tags",
                              loading: _vm.tagsFilterLoading,
                              disabled: _vm.tagsFilterLoading,
                              items: _vm.tagsOptionsItems,
                              solo: "",
                            },
                            model: {
                              value: _vm.tag,
                              callback: function ($$v) {
                                _vm.tag = $$v
                              },
                              expression: "tag",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "",
                              "menu-props": { bottom: true, offsetY: true },
                              label: "All Categories",
                              loading: _vm.categoryTitleFiltersLoading,
                              disabled: _vm.categoryTitleFiltersLoading,
                              items: _vm.categoriesDropdownValues,
                              solo: "",
                            },
                            model: {
                              value: _vm.category,
                              callback: function ($$v) {
                                _vm.category = $$v
                              },
                              expression: "category",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "hide-details": "",
                              "menu-props": { bottom: true, offsetY: true },
                              label: "All My Library Titles",
                              loading: _vm.categoryTitleFiltersLoading,
                              disabled: _vm.categoryTitleFiltersLoading,
                              items: _vm.titlesDropdownValues,
                              solo: "",
                            },
                            model: {
                              value: _vm.book,
                              callback: function ($$v) {
                                _vm.book = $$v
                              },
                              expression: "book",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-0",
                              attrs: {
                                color: "primary",
                                large: "",
                                block: "",
                                title: "Reset All Filters",
                              },
                              on: { click: _vm.clickedResetFilters },
                            },
                            [
                              _vm._v(
                                "\n                            Reset\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xl: "10", "offset-xl": "1" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "grey lighten-3" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "large",
                                      attrs: {
                                        color: "primary",
                                        block: _vm.isMobilePort,
                                      },
                                      on: { click: _vm.clickedExpandAll },
                                    },
                                    [
                                      _vm.openPanels.length === 0
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                title: "Show Tag Details",
                                              },
                                            },
                                            [_vm._v("Expand All")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              attrs: {
                                                title: "Hide Tag Details",
                                              },
                                            },
                                            [_vm._v("Collapse All")]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      class: {
                                        "my-3": _vm.isMobilePort,
                                      },
                                      attrs: {
                                        align: "center",
                                        dense: "",
                                        justify: _vm.isMobilePort
                                          ? "center"
                                          : "end",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "data-qa":
                                                "dashboard-notes-pdf-title",
                                              solo: "",
                                              "hide-details": "",
                                              label: "PDF Title",
                                            },
                                            model: {
                                              value: _vm.report_title,
                                              callback: function ($$v) {
                                                _vm.report_title = $$v
                                              },
                                              expression: "report_title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto", sm: "6" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "data-qa":
                                                  "dashboard-notes-generate-pdf",
                                                color: "primary",
                                                title: "Print Documents",
                                                loading: _vm.report_load,
                                              },
                                              on: {
                                                click: _vm.clickedGeneratePdf,
                                              },
                                            },
                                            [
                                              !_vm.report_load
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                                Generate PDF\n                                            "
                                                    ),
                                                  ])
                                                : _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                      color: "green",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.isOwnUser == "1"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "v-btn-right",
                                                  attrs: {
                                                    color: "primary",
                                                    title: "Share Tags",
                                                  },
                                                  on: {
                                                    click: _vm.clickedShare,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Share\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        dense: "",
                                        align: "center",
                                        justify: _vm.isMobilePort
                                          ? "center"
                                          : "end",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          class: {
                                            "text-center": _vm.isMobilePort,
                                          },
                                          attrs: { cols: "12", sm: "6" },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "v-label theme--light mb-0",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.selectedNotesCombined
                                                      .length
                                                  ) +
                                                  "\n                                            "
                                              ),
                                              _vm.selectedNotesCombined
                                                .length === 1
                                                ? _c("span", [_vm._v("Note")])
                                                : _c("span", [_vm._v("Notes")]),
                                              _vm._v(
                                                "\n                                            Currently Selected\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          class: {
                                            "text-center": _vm.isMobilePort,
                                          },
                                          attrs: { cols: "auto", sm: "6" },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "pa-0 ma-0",
                                            attrs: {
                                              "hide-details": "",
                                              label: "Select all notes",
                                            },
                                            on: {
                                              change: _vm.selectAllChanged,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xl: "10", "offset-xl": "1" } },
                [
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "pt-12 text-md-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { size: 32, indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _c("note-list", {
                        attrs: { data: _vm.data },
                        on: {
                          panelSelectionChanged: _vm.openPanelsChanged,
                          selectionChanged: _vm.onSelectionChanged,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("notes"),
          _vm._v(" "),
          _c("back-to-top"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }