var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _vm.$getConst("premiumCompleteBundleLevels").includes(_vm.accessLevel)
        ? _c("v-col", [
            _c("h4", [
              _vm._v(
                "\n            This title is bundled exclusively with a Premium Complete subscription.\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0 pt-5" }, [
              _vm._v(
                "\n            Click Learn More below to view subscription options for Premium Complete.\n        "
              ),
            ]),
          ])
        : _c("v-col", [
            _c("h4", [
              _vm._v(
                "\n            This title is bundled exclusively with Premium Complete and Premium Collection subscriptions.\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0 pt-5" }, [
              _vm._v(
                "\n            Click Learn More below to view subscription options for available collections and Premium Complete.\n        "
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }