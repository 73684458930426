<template ref="accountOverview">
    <div>
        <v-row v-if="isUsersExeeded">
            <v-col>
                <v-alert
                    v-model="isUsersExeeded"
                    class="mb-0"
                    color="error"
                    type="warning"
                    dismissible>
                    <h4>The Active User count for your Enterprise profile has exceeded the number of available seats.  Please contact your ICC Specialist to add additional seats or deactivate users from the Manage Users tab.</h4>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7">
                <v-card class="white rounded-xl" outlined>
                    <v-row>
                        <v-col>
                            <v-card flat>
                                <v-card-title class="py-2">
                                    <v-icon
                                        left>
                                        mdi-text-box-outline
                                    </v-icon>
                                <span class="text-h6 font-weight-bold">General</span>
                                </v-card-title>
                                <v-row>
                                    <v-col cols="12" md="6" class="d-flex flex-column py-0 py-md-3">
                                        <v-card-text>
                                            <p class="grey--text text-subtitle-1 mb-2">Active Purchase Orders</p>
                                            <p class="font-weight-bold text-subtitle-1 mb-2" v-if="ent.orders !== ''">{{ ent.orders}} <a class="pl-1 indigo--text lighten-1 text-caption text-decoration-underline" @click="showMoreDetails">Show more details</a></p>
                                        </v-card-text>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-4"></v-divider>
                                    </v-col>
                                    <v-col cols="12" md="6" class="d-flex flex-column py-0 py-md-3">
                                        <v-card-text>
                                            <p class="grey--text text-subtitle-1 mb-2">Subscription Level</p>
                                            <div class="pb-2">
                                                    <img
                                                        height="12px"
                                                        v-if="'' !== subscriptionType"
                                                        :src="asset(require('../../../../images/icons/diamond_' + subscriptionType + '.jpg'), 'default')"/>
                                                        <span class="accent2--text">{{ titleCase(subscriptionType) }}</span>
                                            </div>
                                        </v-card-text>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-4"></v-divider>
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0 py-md-3">
                                        <v-card-text>
                                            <p class="grey--text text-subtitle-1 mb-2">Start Date</p>
                                            <div class="font-weight-bold text-subtitle-1 pb-2">{{ ent.startDate }}</div>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0 py-md-3">
                                        <v-card-text>
                                            <p class="grey--text text-subtitle-1 mb-2">End Date</p>
                                            <div class="font-weight-bold text-subtitle-1 pb-2">
                                                {{ ent.endDate }}
                                                <v-chip
                                                        v-if="ent.isExpiring"
                                                        class="ma-2 pa-1"
                                                        small
                                                        color="warning"
                                                        label
                                                        text-color="white"
                                                >
                                                    Expiring Soon
                                                </v-chip>
                                            </div>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="5">
                <v-card class="white rounded-xl" height="100%" outlined>
                    <v-row>
                        <v-col>
                            <v-card flat class="m">
                                <v-card-title class="py-2">
                                    <v-icon
                                        left>
                                        person
                                    </v-icon>
                                <span class="text-h6 font-weight-bold">Users</span>
                                </v-card-title>
                                <v-row class="ma-3">
                                    <v-col cols="12" md="6">
                                        <v-card height="100%" class="d-flex flex-column justify-space-between primary rounded-xl">
                                            <v-card-title class="white--text">
                                                Active Users
                                            </v-card-title>
                                            <v-card-subtitle class="white--text text-h4 font-weight-bold">
                                                {{ activeUsers }}
                                            </v-card-subtitle>
                                        </v-card>       
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-card height="100%" class="d-flex flex-column justify-space-between warning rounded-xl">
                                            <v-card-title class="white--text" style="word-break: break-word">
                                                Available Enterprise Seats
                                            </v-card-title>
                                            <v-card-subtitle class="white--text text-h4 font-weight-bold">
                                                {{ numberOfSeats }}
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="white rounded-xl" outlined>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-card flat>
                                <v-card-title class="py-2">
                                    <v-icon
                                        left>
                                        mdi-text
                                    </v-icon>
                                    <span class="text-h6 font-weight-bold">Titles Included</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="7" class="text-right pa-5">
                            <v-text-field
                                v-model="searchQuery"
                                prepend-inner-icon="mdi-magnify"
                                rounded
                                outlined
                                dense
                                label="Search Titles"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6" :key="key" v-for="(item, key) in titles">
                            <v-divider v-if="key > 1" class="mx-4"></v-divider>
                            <v-list-item
                                v-if="titles"
                                :href="item.link">
                                <v-list-item-avatar
                                    rounded
                                    width="auto"
                                    height="auto">
                                    <graphic
                                        :title="item"
                                        thumbnail
                                        width="50px"
                                        additionalStyles="max-width: 50px;">
                                    </graphic>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="indigo--text lighten-1" v-html="item.display_title">
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-divider class="mx-4"></v-divider>
                    </v-row>
                    <v-row dense v-if="displayShowMore">
                        <v-col align="center" class="pa-1">
                            <a @click="showAllTitles" class="indigo--text lighten-1">
                                Show more <v-icon class="pl-1">mdi-chevron-down</v-icon>
                            </a>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="displayShowLess">
                        <v-col align="center" class="pa-1">
                            <a @click="showLessTitles" class="indigo--text lighten-1">
                                Show less <v-icon class="pl-1">mdi-chevron-up</v-icon>
                            </a>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="white rounded-xl" outlined>
                    <v-row>
                        <v-col md="7" cols="12">
                            <v-card flat>
                                <v-card-title class="py-2">
                                    <v-icon
                                        left>
                                        mdi-web
                                    </v-icon>
                                    <span class="text-h6 font-weight-bold">Domain Whitelisted</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col md="5" class="d-none d-md-block text-right pa-5">
                            <span class="caption">Contact <a class="indigo--text lighten-1" href="https://www.iccsafe.org/about/contact-icc/" target="_blank">ICC Customer Support</a> to add licenses or edit domains</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="3"
                            v-for="item in ent.domains"
                            :key="item.id">
                            <v-card flat>
                                <v-card-title class="py-2">
                                    <v-icon
                                        left
                                        small>
                                        mdi-web
                                    </v-icon>
                                    <span class="subtitle-1 font-weight-bold">{{ item.name }}</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="white rounded-xl" outlined>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-card flat>
                                <v-card-title class="py-2">
                                    <v-icon
                                        left>
                                        mdi-note-text-outline
                                    </v-icon>
                                    <span class="text-h6 font-weight-bold">Purchase Orders</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="5" class="d-none d-md-block text-right pa-5">
                            <span class="caption">Contact <a class="indigo--text lighten-1" href="https://www.iccsafe.org/about/contact-icc/" target="_blank">ICC Customer Support</a> to add licenses or renew existing orders</span>
                            <v-icon
                                right
                                @click="showPurchaseOrders"
                                link
                                >
                                {{ displayPurchaseOrderTable ? 'mdi-chevron-up' : 'mdi-chevron-down' }} 
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-5" v-if="displayPurchaseOrderTable" ref="purchaseOrderTable">
                        <v-col v-if="allOrders.length">
                            <v-data-table
                                :headers="headers"
                                :items="allOrders"
                                :items-per-page="5"
                            >
                                <template v-slot:item="{ item }">
                                    <tr :class="{active: (allOrders.indexOf(item) + 1) % 2 == 0}">
                                        <td :class="`no-border ${item.status === 'Active' ? 'font-weight-bold' : 'grey--text lighten-4'}`"># {{ item.purchase_order }}</td>
                                        <td :class="`no-border ${item.status === 'Active' ? 'font-weight-bold' : 'grey--text lighten-4'}`">{{ item.display_date_start }}</td>
                                        <td :class="`no-border ${item.status === 'Active' ? 'font-weight-bold' : 'grey--text lighten-4'}`">{{ item.display_date_end }}
                                            <v-chip
                                                v-if="item.isExpiring"
                                                class="ma-2 pa-1 font-weight-bold"
                                                small
                                                color="warning"
                                                label
                                                text-color="white"
                                                >
                                                Expiring Soon
                                            </v-chip>
                                        </td>
                                        <td :class="`no-border ${item.status === 'Active' ? 'font-weight-bold' : 'grey--text lighten-4'}`">{{ item.licenses }}</td>
                                        <td class="no-border">
                                            <v-chip
                                                :color="getStatusColor(item.status)"
                                                text-color="white"
                                                label
                                                class="pa-2 font-weight-bold">
                                                {{ item.status }}
                                            </v-chip>
                                        </td>
                                        <td class="no-border"><a class="indigo--text lighten-1" :href="shopUrl + 'ecodes/ecode/'" target="_blank">View Full Order Details</a></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col v-else class="text-center">
                            <template v-if="loading">Loading...</template>
                            <template v-else>No purchase orders found</template>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>    
</template> 

<script>
    import Mobile from '../../../inc/mixins/mobile';
    import Graphic from "../../../titles/cover/Graphic";
    export default {
        data: () => ({
            lodash: _,
            loading: false,
            ent: {
                enterpriseName: "",
                status: "",
                subscriptionType: "",
                startDate: "",
                endDate: "",
                titles: [],
                domains: [],
                orders: "",
                users: "",
                licenseMatch: true,
                endDateMatch: true,
                isExpiring: false
            },
            activeUsers: 0,
            numberOfSeats: 0,
            headers: [
                {
                    text: 'Order #',
                    align: 'start',
                    sortable: true,
                    value: 'purchase_order',
                },
                { 
                    text: 'Date of Purchase',
                    sortable: true,
                    value: 'date_start',
                },
                { 
                    text: 'Date of Expiry',
                    sortable: true,
                    value: 'date_end' 
                },
                { 
                    text: 'No of Licenses',
                    sortable: true,
                    value: 'licenses'
                },
                { 
                    text: 'Status',
                    sortable: true,
                    value: 'status'
                },
                { 
                    text: 'Action',
                    sortable: false,
                    value: 'action'
                },
                
            ],
            isUsersExeeded: false,
            allTitles: [],
            displayShowMore: false,
            displayShowLess: false,
            searchQuery: null,
            displayPurchaseOrderTable: true,
            allOrders: [],
            shopUrl: null,
        }),
        computed: {
            titles() {
                if (this.searchQuery && this.searchQuery.length > 2) {
                    let filteredTitles = this.allTitles
                        .filter((item) => {
                            return this.searchQuery
                                .toLowerCase()
                                .split(' ')
                                .every(v => `${item.display_title}`.toLowerCase().includes(v))
                        });
                    return filteredTitles;
                } else {
                    return this.allTitles;
                }
            },
            subscriptionType() {
                return this.ent.subscriptionType;
            }
        },
        mixins: [Mobile],
        components: {
            Graphic
        },
        methods: {
            getEnterpriseOverview() {
                this.loading = true;
                this.$http.get(Routing.generate('get_enterprise_overview', {enterpriseId: this.$store.getters.enterpriseId}))
                    .then(response => {
                        if (response.data.code == 200 && response.data.ent) {
                            this.ent = response.data.ent;
                            this.ent.subscriptionType = response.data.ent.subscriptionType;
                            this.ent.domains = response.data.ent.domains;
                            this.ent.orders = response.data.activeOrders.map(item => {
                                return item.purchase_order;
                            }).join(', ');
                            this.allOrders = response.data.allOrders;       
                            this.loading = false;
                            this.checkUsersMatch(response.data.ent.users);
                            EventBus.fire('get-enterprise-details', {
                                enterpriseName: this.ent.enterpriseName
                            });
                            this.allTitles = this.ent.titles;
                            this.shopUrl = response.data.shopUrl;
                            if(this.allTitles.length > 6){
                                this.allTitles = this.allTitles.slice(0,6);
                                this.displayShowMore = true;
                            }
                        }
                });
            },
            checkUsersMatch(usersCount) {
                let users = usersCount.split("/");
                let addedUsers = _.parseInt(users[0].trim());
                let allowedUsers =  _.parseInt(users[1].trim());
                this.numberOfSeats = allowedUsers;
                this.activeUsers = addedUsers;
                if(addedUsers > allowedUsers) {
                    this.isUsersExeeded = true;
                }
            },
            showAllTitles() {
                this.allTitles = this.ent.titles;
                this.displayShowMore = false;
                this.displayShowLess = true;
            },
            showLessTitles(){
                this.displayShowLess = false;
                if(this.allTitles.length > 6){
                    this.allTitles = this.allTitles.slice(0,6);
                    this.displayShowMore = true;
                }
            },
            showPurchaseOrders() {
                this.displayPurchaseOrderTable = !this.displayPurchaseOrderTable;
            },
            showMoreDetails() {
                this.displayPurchaseOrderTable = true;
                this.$nextTick(function() {
                    let purchaseTable = this.$refs.purchaseOrderTable;
                    if (purchaseTable) {
                        purchaseTable.scrollIntoView({behavior: 'smooth'});
                    }
                });
            },
            getStatusColor(status) {
                return status === 'Active' ? 'primary' : 'error';
            },
            titleCase(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
        },
        mounted() {
            this.getEnterpriseOverview();
        }
    }
</script>
<style scoped>
.no-border{
    border: none !important;
}
</style>
