var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "center", dense: "" } },
        [
          _c("v-col", { staticClass: "d-flex px-2" }, [
            _c(
              "a",
              {
                staticClass: "d-flex align-center",
                attrs: { href: _vm.clearCalcUrl, target: "_blank" },
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.asset(
                      require("../../../app/images/calculators/clearcals_icon.webp")
                    ),
                    alt: "ClearCalcs Logo",
                    title: "ClearCalcs Logo",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "black--text font-weight-bold" }, [
                  _vm._v("Powered by ClearCalcs"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "text-right px-2",
              class: !_vm.isMobilePort ? "pr-6" : "pt-2",
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-10",
                  attrs: {
                    color: "primary",
                    href: _vm.getRoute("compliance_calculators"),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                View More Calculators\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }