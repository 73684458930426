<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="isMobileAndTablet"
        max-width="500px">
        <v-card>
            <v-card-title
                class="font-weight-bold oxygen fs-24"
                :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`"
                :class="{
                    'caption mobile-title': isMobilePort
                    }">
                    <v-icon
                        class="mr-2"
                        color="accent2">
                        mdi-creation
                    </v-icon>
                    Premium Code Insights
                <v-spacer></v-spacer>
                <v-icon
                    class="black--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-row class="grey lighten-4 ma-auto">
                    <v-col class="text-center">
                        <img
                            alt="Code Insight"
                            class="my-1"
                            :src="imageSrc"
                            style="width: 75%;"/>
                    </v-col>
                </v-row>
                <div v-if="!loading">
                    <v-row>
                        <v-col>
                            <h2
                                class="text-center my-2 mx-6"
                                style="line-height: 32px;">
                                {{ category.name }}
                            </h2>
                            <p class="overflow-hidden text-center mb-0 pa-4 py-0 tag-description">
                                {{ category.description }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="auto" class="pb-0">
                            <v-btn
                                :outlined="isTrailAvailable"
                                depressed
                                :color="isTrailAvailable ? 'primary' : 'accent2'"
                                class="font-weight-bold"
                                :class="isTrailAvailable ? 'mr-4' : ''"
                                @click="openSubscribeToPremiumDialog()">
                                Subscribe To Premium
                            </v-btn>
                            <v-btn
                                v-if="!isLoggedIn || canStartTrial"
                                large
                                class="px-5 fs-16 font-weight-bold"
                                color="accent2"
                                max-height="35"
                                depressed
                                :href="getRoute('trial_signup')">
                                Start Free Trial
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <v-col cols="auto">
                            <v-btn
                                v-if="category?.learnMoreUrl && category?.learnMoreLabel"
                                large
                                text
                                class="px-5 fs-16 mt-2"
                                :href="category.learnMoreUrl">
                                {{category.learnMoreLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile'

    export default {
        data() {
            return {
                dialog: false,
                category: {},
                loading: false
            }
        },
        mixins: [
            Mobile
        ],
        computed: {
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
            imageSrc(){
                if (this.category?.codeInsightImage){
                   return this.asset(this.category.codeInsightImage, 'default')
                }
                return this.asset(require('../../images/tagging/upsell_header.png'), 'default')
            },
            documentInfo() {
                return this.$store.getters.getDocumentInfo;
            },
            isTrailAvailable() {
                return (!this.isActiveUser || this.canStartTrial);
            }
        },
        methods: {
            getTagCategoryInfo(params){
                this.loading = true;
                this.$http.get(Routing.generate('tag_category_info', params))
                    .then(response => {
                        this.loading = false;
                        if (response.data.data.length) {
                            this.category = response.data.data[0];
                        }
                    });
            },
            openSubscribeToPremiumDialog(){
                this.dialog = !this.dialog;
                EventBus.fire('show-premium-subscribe-dialog', this.documentInfo);
            }
        },
        created(){
            EventBus.listen('show-tag-restricted-dialog', (data) => { 
                this.dialog = true
                this.getTagCategoryInfo(data);
            });
        }
    }
</script>
<style lang="scss" scoped>
    .tag-description {
        max-height: 130px;
        overflow-y: auto;
    }
    @media (max-width: 820px) {
        .tag-description {
            max-height: 300px;
            overflow-y: auto;
        }
    }
</style>
