<template>
    <v-bottom-sheet
        :content-class="contentClass"
        v-model="sheet"
        inset
        hide-overlay
        max-width="1024px">
        <v-sheet
            class="newIccStyles"
            :style="!loading.html ? styles.htmlContainer : 'height: 100px;'">
            <v-container
                fluid
                ref="headerContainer"
                class="accent2 py-0">
                <v-row>
                    <v-col class="py-0">
                        <p class="caption white--text mb-0">
                            Link Preview
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <template v-if="!loading.html">
                <v-container
                    v-if="title"
                    class="py-0">
                    <v-row class="grey lighten-3">
                        <v-col class="py-1">
                            <p class="caption mb-0 font-weight-bold">
                                {{ title }}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    class="py-0"
                    v-if="isAstmLink"
                    ref="htmlContainer">
                    <p v-html="html"></p>
                    <p v-html="htmlSecondPara"></p>
                </v-container>
                <v-container
                    v-else-if="html"
                    v-html="html"
                    ref="htmlContainer">
                </v-container>
                <v-container v-else>
                    <v-row justify="center">
                        <v-col cols="auto">
                            <p class="mb-0">
                                No preview found for this content. Click the link to view this content.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <v-container v-else>
                <v-row>
                    <v-col>
                        <v-skeleton-loader
                            transition="scale-transition"
                            type="sentences">
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                sheet: false,
                sheetFade: false,
                entries: {},
                html: '',
                title: '',
                loading: {
                    html: false
                },
                styles: {
                    htmlContainer: ''
                },
                request: null,
                isAstmLink: false,
                htmlSecondPara: ''
            }
        },
        computed: {
            contentClass() {
                let contentClass = 'peak-ahead white mb-8';
                if (this.sheetFade) {
                    contentClass = 'peak-ahead-fade '+contentClass;
                }
                return contentClass;
            }
        },
        methods: {
            handleError() {
                if (!this.request) {
                    this.$set(this.loading, 'html', false);
                }
            },
            showPeakAhead(data) {
                if (this.request) {
                    this.request.cancel();
                    this.request = null;
                }
                if (data.show) {
                    this.html = this.title = '';
                    this.$set(this.loading, 'html', true);
                    this.sheet = true;
                    let entry = this.entries[data.targetId];

                    // Check to see if link is ASTM
                    if (data.isAstmLink) {
                        this.isAstmLink = true;
                        this.$nextTick(() => {
                            this.$set(this.loading, 'html', false);
                            this.setFade();
                        });

                        if (!_.isUndefined(entry)) {
                            this.title = entry.title ? entry.title : '';
                            this.html = entry.html;
                            this.htmlSecondPara = entry.secondPara;

                            this.$nextTick(() => {
                                this.$set(this.loading, 'html', false);
                                this.setFade();
                            });
                        } else {
                            let astmRefStd = null;
                            if (data.targetId.includes('_PromASTM_')) {
                                astmRefStd = data.targetId.match(/RefStd(.*)/);
                            } else if(data.targetId.includes('_ASTM_')) {
                                astmRefStd = data.targetId.match(/ASTM(.*)/);
                            }
                            astmRefStd = astmRefStd ? astmRefStd[1] : data.targetId;

                            if(astmRefStd) {
                                // Ajax call to get the ASTM standard details
                                let axiosSource = axios.CancelToken.source();
                                this.request = {cancel: axiosSource.cancel};

                                axios.get(Routing.generate('astm_standard_info', {iccDesignation: astmRefStd}), {
                                    cancelToken: axiosSource.token
                                })
                                .then((response) => {
                                    let result = response.data.data ? response.data.data : null;
                                    let astmDocument = result ? result.document : null;
                                    if(astmDocument) {
                                        let description = astmDocument.book_description.length > 450 ?
                                            astmDocument.book_description.substring(0, 450) + '...' :
                                            astmDocument.book_description;
                                        this.title = astmDocument.title;
                                        this.html = astmDocument.subtitle;
                                        this.htmlSecondPara = description;

                                        this.$set(this.entries, data.targetId, {
                                            title: this.title,
                                            html: this.html,
                                            secondPara: this.htmlSecondPara
                                        });
                                    } else {
                                        this.title = 'Error';
                                        this.html = 'Sorry, could not find the ASTM standard description.';
                                    }

                                    this.$nextTick(() => {
                                        this.$set(this.loading, 'html', false);
                                        this.setFade();
                                    });
                                })
                                .catch(this.handleError);
                            } else {
                                this.title = 'Error';
                                this.html = 'Sorry, could not find the ASTM standard description.';
                            }
                        }

                    } else {
                        this.$set(this.styles, 'htmlContainer', 'height: 200px');
                        this.isAstmLink = false;
                        // Check to see if content already exists within this component.
                        if (!_.isUndefined(entry)) {
                            this.html = entry.html;
                            this.title = entry.title ? entry.title : '';
                            this.$nextTick(() => {
                                this.$set(this.loading, 'html', false);
                                this.setFade();
                            })
                        } else {
                            // Check to see if content is already in the dom.
                            let element = document.getElementById(data.targetId);
                            if (element && '' === data.isEsAclink) {
                                // Remove not needed elements.
                                let tempElement = document.createElement('div');
                                tempElement.innerHTML = element.outerHTML;
                                tempElement.querySelectorAll('.trigger-section-action, .section-action-button-container, .tag-label').forEach(function(item, index){
                                    item.parentNode.removeChild(item);
                                });
                                this.html = tempElement.innerHTML;
                                this.$set(this.entries, data.targetId, {html: tempElement.innerHTML});
                                this.$nextTick(() => {
                                    this.$set(this.loading, 'html', false);
                                    this.setFade();
                                })
                            } else {
                                let axiosSource = axios.CancelToken.source();
                                this.request = { cancel: axiosSource.cancel};
                                let postData = {
                                    hash: data.targetId,
                                    document: data.documentId,
                                    isEsAcLink: data.isEsAcLink
                                }
                                axios.get(Routing.generate('get_section_content', postData), {
                                    cancelToken: axiosSource.token
                                })
                                .then((response) => {
                                    this.html = response.data.content ? response.data.content : '';
                                    if (response.data.documentTitle) {
                                        this.title = response.data.documentTitle;
                                    }
                                    if (this.html) {
                                        this.$set(this.entries, data.targetId, {html: this.html, title: this.title});
                                    }
                                    this.$nextTick(() => {
                                        this.$set(this.loading, 'html', false);
                                        this.setFade();
                                    })
                                })
                                .catch(this.handleError);
                            }
                        }
                    }
                } else {
                    this.sheet = false;
                }
            },
            setFade() {
                this.sheetFade = false;
                let height = 200;
                if (this.$refs.htmlContainer) {
                    height = this.$refs.htmlContainer.clientHeight + this.$refs.headerContainer.clientHeight;
                    if (height > 200) {
                        height = 200;
                        this.sheetFade = true;
                    }
                }
                this.$set(this.styles, 'htmlContainer', 'height: '+height+'px');
            }
        },
        created() {
            EventBus.listen('togglePeakAhead', (data) => this.showPeakAhead(data));
        }
    }
</script>

<style lang="scss">
    .peak-ahead {
        border-radius: 5px!important;
        overflow-y: hidden!important;
        .v-sheet.v-card:not(.v-sheet--outlined) {
            box-shadow: unset!important;
        }
        .reference_standard {
            padding: 10px;
        }
    }
    .peak-ahead-fade {
        position: relative;
    }
    .peak-ahead-fade::before {
        background-image: linear-gradient( top,
            rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
        background-image: -moz-linear-gradient( top,
            rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
        background-image: -ms-linear-gradient( top,
            rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
        background-image: -o-linear-gradient( top,
            rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
        background-image: -webkit-linear-gradient( top,
            rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
        content: "\00a0";
        height: 10%;
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 5;
    }
</style>
