<template>
    <v-row dense>
        <v-spacer>
        </v-spacer>
        <v-col cols="auto">
            <v-btn
                class="elevation-0"
                color="primary"
                small
                @click="performSearch()">
                Apply
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import Search from "../../inc/mixins/search";

    export default {
        name: 'FilterButtons',
        props: {
            filter: {
                required: false,
                default: null
            }
        },
        mixins: [
            Search
        ],
        methods: {
            performSearch() {
                const {key, value} = this.filter

                // Set our store values.
                const flatMap = value.map(item => item.name);
                if (key === 'years') {
                    this.$store.commit('setTabbedYears', {
                        tab: this.searchTab,
                        years: flatMap
                    });
                } else if (key === 'categories') {
                    this.$store.commit('setTabbedCategories', {
                        tab: this.searchTab,
                        categories: flatMap
                    });
                } else if (key === 'titles') {
                    const titles = value.map(item => item.id);
                    this.$store.commit('setTabbedTitleIds', {
                        tab: this.searchTab,
                        titles: titles
                    });
                    this.$store.commit('setTabbedTitles', {
                        tab: this.searchTab,
                        titles: value
                    });
                }
            }
        }
    }
</script>
