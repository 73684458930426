var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-3", attrs: { id: "breadcrumb" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: !_vm.isExtraLarge } },
        [
          _c(
            "v-row",
            {
              class: {
                "py-3": !_vm.isMobilePort,
              },
              attrs: {
                "no-gutters": "",
                align: "center",
                "fill-height": "",
                row: "",
                wrap: "",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        right: "",
                        "max-width": "300",
                        transition: "fade-transition",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mr-5",
                                    attrs: {
                                      icon: "",
                                      role: "button",
                                      "aria-label": "Edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleTOC()
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      "\n                                mdi-format-list-bulleted\n                            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.tooltipOpen,
                        callback: function ($$v) {
                          _vm.tooltipOpen = $$v
                        },
                        expression: "tooltipOpen",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "tooltip-content",
                          on: {
                            mouseenter: _vm.showTooltip,
                            mouseleave: _vm.hideTooltip,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Browse by Category\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    class: {
                      "mb-4": !_vm.isMobilePort,
                    },
                    attrs: { items: _vm.data.breadcrumb },
                    scopedSlots: _vm._u([
                      {
                        key: "divider",
                        fn: function () {
                          return [_c("v-icon", [_vm._v("chevron_right")])]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "h1",
                      {
                        staticClass: "primary--text display-1",
                        class: { title: _vm.isMobilePort },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.pageTitle) +
                            "\n                        "
                        ),
                        _vm.data.chapterSearch &&
                        _vm.data.chapterSearch === true
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "primary btn-radius",
                                attrs: { small: _vm.isMobilePort },
                                on: { click: _vm.backToContent },
                              },
                              [
                                _vm._v(
                                  "\n                            Return to Content\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.data.description
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.data.description) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }