<template>
    <v-container class="content-container">
        <v-row align="center">
            <v-col cols="auto">
                <img
                    class="mr-9"
                    style="max-width: 400px"
                    alt=""
                    :src="asset(require('../../../images/illistrations/gear_idea.webp'))"/>
            </v-col>
            <v-col>
                <v-row>
                    <v-col>
                        <h2 class="font-weight-regular primary--text">
                            Unlock exclusive tools and features with <strong>Premium Complete</strong>
                        </h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-for="(item, key) in items"
                        :key="key"
                        cols="12"
                        sm="6"
                        class="py-2">
                        <v-row
                            dense
                            align="center">
                            <v-col cols="auto">
                                <v-icon
                                    class="ml-3"
                                    color="primary">
                                    {{item.icon}}
                                </v-icon>
                            </v-col>
                            <v-col>
                                <a :href="item.link"
                                    target="_blank"
                                    class="text-decoration-underline font-weight-bold">
                                    {{item.text}}
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <slot name="button">
                        </slot>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                items: [
                    {
                        icon: 'mdi-comment-text',
                        text: 'Read Code commentaries',
                        link: 'https://codes.iccsafe.org/codes/commentaries'
                    },
                    {
                        icon: 'mdi-bookmark',
                        text: 'Tag Content',
                        link: 'https://support.iccsafe.org/?ht_kb=how-to-navigate-contents-across-premium-titles#bonus-content-tags'
                    },
                    {
                        icon: 'mdi-file-document-multiple',
                        text: 'Copy, Paste, and Print Sections',
                        link: 'https://support.iccsafe.org/?ht_kb=basic-vs-premium-view#premium-copy-paste'
                    },
                    {
                        icon: 'mdi-note-text',
                        text: 'Create Notes',
                        link: 'https://support.iccsafe.org/?ht_kb=how-to-create-notes'
                    },
                    {
                        icon: 'mdi-magnify-plus-outline',
                        text: 'Advanced Search',
                        link: 'https://support.iccsafe.org/?ht_kb=how-to-perform-advanced-searches'
                    },
                    {
                        icon: 'mdi-eye',
                        text: 'Link Preview',
                        link: 'https://support.iccsafe.org/?ht_kb=how-to-navigate-contents-across-premium-titles#link-previews'
                    },
                    {
                        icon: 'mdi-link',
                        text: 'In-Text Hyperlinks',
                        link: 'https://support.iccsafe.org/?ht_kb=basic-vs-premium-view#hyperlinks'
                    },
                    {
                        icon: 'mdi-account-supervisor',
                        text: 'Concurrent Access',
                        link: 'https://support.iccsafe.org/?ht_kb=understanding-premium-subscription-license-options#concurrent-access'
                    }
                ]
            }
        }
    }
</script>
