<template>
    <v-data-iterator
        :items="titles"
        item-key="document_id"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :search="search"
        :loading="loading">
        <template v-slot:no-data>
            <slot name="no-data">
                <template v-if="displayTitleSearchNoResultMsg">
                    <no-titles-result></no-titles-result>
                </template>
                <p v-else>
                    No results found
                </p>
            </slot>
        </template>
        <template v-slot:default="{ items }">
            <v-row>
                <v-col
                    v-for="(item, key) in items"
                    :key="key"
                    :cols="displayInCols ? displayInCols : '12'"
                    lg="6"
                >
                    <title-card
                        :document-info="item"
                        :display-border="displayBorder"
                        :border-color="borderColor"
                        :right-arrow-color="rightArrowColor"
                    ></title-card>
                </v-col>
            </v-row>
        </template>
    </v-data-iterator>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import NoTitlesResult from '../../search/titles/NoTitlesResult.vue';
    import TitleCard from './TitleCard.vue';

    export default {
        props: {
            titles: {
                required: true,
                type: Array
            },
            search: {
                required: false,
                type: String
            },
            loading: {
                required: true,
                type: Boolean
            },
            itemsPerPage: {
                required: false,
                type: Number,
                default: 10
            },
            displayBorder:{
                required: false,
                type: Boolean,
            },
            borderColor: {
                required: false,
                type: String,
            },
            rightArrowColor: {
                required: false,
                type: String,
            },
            displayInCols: {
                required: false,
                type: Number,
            },
            displayTitleSearchNoResultMsg:{
                required: false,
                type: Boolean,
                default: false
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            TitleCard,
            NoTitlesResult
        },
        watch: {
            titles: {
                handler(newVal) {
                    this.$forceUpdate();
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.included-title-card:hover {
    opacity: .7;
}
</style>
