<template>
    <v-row v-if="alert.message">
        <v-col>
            <v-alert
                class="mb-0 multi-line"
                :type="alert.type">{{ alert.message }}
                <template v-slot:append>
                    <v-icon
                        small
                        @click="alert.message = ''"
                        class="white--text">
                        mdi-close
                    </v-icon>
                </template>
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            alert: Object,
            default: {
                type: 'success',
                message: ''
            }
        }
    }
</script>
