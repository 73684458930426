var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "grey lighten-5 full-height",
      staticStyle: { "min-height": "100vh" },
      attrs: { fluid: "", "data-qa": "enterprise-manage-users" },
    },
    [
      _c("enterprise-name"),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2" },
        [
          _c(
            "v-col",
            [
              _c("enterprise-nav", {
                attrs: { handleTabClick: _vm.tabClicked, tab: 1 },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2" },
        [
          _c(
            "v-col",
            [
              _vm.panel === "Account Overview"
                ? _c("account-overview")
                : _vm.panel === "Manage Users"
                ? _c("manage-users")
                : _c("activity-report", {
                    attrs: { "profile-id": _vm.profileId },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }