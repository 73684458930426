import axios from 'axios'
import { z } from "zod";
const client = axios.create();

export const GraphicsApi = {
    getGraphicsResults(data) {
        const {
            search,
            titles,
            categories,
            years,
            page
        } = data;
        return client.get(Routing.generate('graphics_search_results', {
            searchTermAny: search,
            docInfoIds: titles,
            categories: categories,
            years: years,
            page: page,
            limit: 12
        }));
    },
    async getFigureContent(data) {
        const {
            documentId,
            contentId
        } = data;
        const apiResultRaw = await client.get(Routing.generate('get_figure_content', {
            documentId: documentId,
            contentId: contentId
        }));

        const resultSchema = z.object({
            content: z.string(),
            breadcrumbs: z.any(), //TODO:: define breadcrumbs schema
        });

        return resultSchema.parse(apiResultRaw.data);
    }
}