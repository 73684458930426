var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("breadcrumb", { attrs: { title: "Favorites" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "favorite-dashboard" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "grey lighten-5" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "primary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", { attrs: { color: "accent2" } }, [
                                _vm._v("favorite"),
                              ]),
                              _vm._v(
                                " Manage Favorites\n                        "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Click the heart icon while viewing any book to flag as a favorite. Once a book is flagged, it is added to the existing favorites and displayed as per the priority order below, that you can change via drag and drop. The top 10 favorite titles will appear in your left hand green sidebar for quick access from anywhere on the site."
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("p", [
                                    _vm._v(
                                      "To add a book as a favorite, please search for it below, and select the heart icon to add it as a favorite:"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("autocomplete"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v("Book Title"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.favoritesLoading
                            ? [
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      list: _vm.favorites,
                                      disabled: !_vm.enabled,
                                      "ghost-class": "ghost",
                                    },
                                    on: {
                                      end: function ($event) {
                                        return _vm.updateSortOrder()
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.favorites,
                                    function (favorite, index) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: favorite.id,
                                          attrs: { align: "center" },
                                        },
                                        [
                                          !_vm.isMobilePort
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "cp",
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            mdi-sort\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c("book-cover", {
                                                attrs: {
                                                  title: favorite.title,
                                                  type: "list",
                                                  showVersion: true,
                                                  showFavorite: true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  { staticClass: "text-center pt-6" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }