<template>
    <div>
        <search-header
            v-if="!isLoading"
            :total-results="pagination.totalResults"
            :search-term="search"
            :filters="filters"
            @showModal="() => dialog = true">
        </search-header>
        <v-row>
            <v-col>
                <title-list
                    :titles="titles"
                    :loading="isLoading"
                    :itemsPerPage="itemsPerPage"
                    displayBorder
                    displayUserTypeLabel
                    :displayInCols="isMobilePort ? 12 : 6"
                    :displayTitleSearchNoResultMsg="true">
                </title-list>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            fullscreen
            scrollable>
            <v-card>
                <v-card-title>
                    <h3>Title Filters</h3>
                    <v-spacer></v-spacer>
                    <v-icon
                        @click="dialog = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text class="pa-4">
                    <title-search-filters
                        :filters="filters">
                    </title-search-filters>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col>
                            <v-btn
                                block
                                outlined
                                @click="dialog = false"
                                color="primary">
                                Close
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row
            v-if="page < pagination.maxPerPage"
            dense
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    class="mb-4"
                    :loading="isLoading"
                    @click="showMore">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Mobile from "../../inc/mixins/mobile";
import TitleList from "../../content/title/TitleList.vue";
import SearchHeader from "./SearchHeader.vue";
import TitleSearchFilters from "./filters/TitleSearchFilters.vue";
import { TitlesApi } from "../../../api/Titles";
import {EventNames} from "../../../classes/EventNames";
import TabbedSearch from "../mixins/TabbedSearch";

export default {
    data() {
        return {
            search: '',
            titles: [],
            isLoading: false,
            limit: 20,
            page: 1,
            itemsPerPage: -1,
            pagination: {
                currentPage: 1,
                totalResults: 0
            },
            dialog: false,
            titleScope: null,
            categories: [],
            years: [],
            // filters
            filters: {
                titleScope: [],
                categories: [],
                years: []
            },
            resetAllData: false
        }
    },
    mixins: [
        Mobile,
        TabbedSearch
    ],
    components: {
        TitleList,
        SearchHeader,
        TitleSearchFilters
    },
    watch: {
        // Set the tabbed search value for title search so we can identify if we need to refresh the data on tab change.
        search(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$store.commit('setTabbedSearchValues', {
                    tab: this.$getConst('titleSearchType'),
                    searchValue: newVal
                });
            }
        },
        filterCategories: {
            handler() {
                if (this.resetAllData) {
                    return;
                }
                this.refreshData();
            },
            deep: true
        },
        filterYears: {
            handler() {
                if (this.resetAllData) {
                    return;
                }
                this.refreshData();
            },
            deep: true
        },
        filterGroups: {
            handler() {
                if (this.resetAllData) {
                    return;
                }
                this.refreshData();
            },
            deep: true
        }
    },
    methods:{
        showMore(){
            this.page = this.page + 1;
            this.getTitlesResult();
        },
        async getTitlesResult(){
            if (this.searchTab !== this.$getConst('titleSearchType')) {
                return;
            }

            this.isLoading = true;
            const group = this.filterGroups[this.$getConst('titleSearchType')] || [];
            const titleCategories = this.filterCategories[this.$getConst('titleSearchType')] || [];
            const titleYears = this.filterYears[this.$getConst('titleSearchType')] || [];

            const data = {
                search: this.search,
                page: this.page,
                limit: this.limit,
                year: titleYears,
                category: titleCategories
            }

            if (group && group.key) {
                switch (group.key) {
                    case 'recently_added':
                    case 'recently_viewed':
                        data.group = group.key;
                        break;
                    default:
                        data.mine = true;
                }
            }

            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);

            // Update search term in the URL
            if (this.search) {
                params.set('searchTermAny', this.search);
            } else {
                params.delete('searchTermAny');
            }

            // Update categories and years, removing previous entries
            params.delete('group');
            params.delete('categories');
            params.delete('years');
            if (group && group.key) {
                params.append('group', group.key);
            }
            titleCategories.forEach(v => params.append('categories', v));
            titleYears.forEach(v => params.append('years', v));

            // Update the URL without reloading the page
            window.history.pushState({}, "", `?${params.toString()}`);

            this.$store.commit('setTabbedSearchUrl', {
                tab: this.$getConst('titleSearchType'),
                searchUrl: `?${params.toString()}`,
                updateSearchTerm: true
            });

            try {
                const response = await TitlesApi.getTitles(data);
                const newData = response.data.data;
                this.titles.push(...newData);
                this.pagination = response.data.pagination;
                this.isLoading = false;
            } catch (error) {
                // Todo: Handle error
                console.log(error)
            }
            this.resetAllData = false;
        },
        resetData(){
            this.resetAllData = true;
            this.page = 1;
            this.titles = [];
            this.categories = [];
            this.years = [];

            EventBus.fire(EventNames.REMOVE_TITLE_GROUP);
            EventBus.fire(EventNames.REMOVE_ALL_ACTIVE_CATEGORIES);
            EventBus.fire(EventNames.REMOVE_ALL_ACTIVE_YEARS);

            this.getTitlesResult();
        },
        refreshData(){
            this.page = 1;
            this.titles = [];
            this.getTitlesResult();
        }
    },
    created() {
        EventBus.listen(EventNames.INIT_TITLE_SEARCH, () => {
            this.resetAllData = true;
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            this.search  = params.get('searchTermAny') || '';
            this.defaultSearch = this.search;

            // Check the URL for any filters, if so then set them in the store.
            const group = params.getAll('group');
            const categories = params.getAll('categories');
            const years = params.getAll('years');
            if (group && group.length > 0) {
                const activeGroup = this.titleGroups.find((titleGroup) => titleGroup.key === group[0]);
                this.$store.commit('setTabbedGroups', {
                    tab: this.$getConst('titleSearchType'),
                    group: activeGroup
                });
            }
            if (categories.length > 0) {
                this.$store.commit('setTabbedCategories', {
                    tab: this.$getConst('titleSearchType'),
                    categories: categories
                });
            }
            if (years.length > 0) {
                this.$store.commit('setTabbedYears', {
                    tab: this.$getConst('titleSearchType'),
                    years: years
                });
            }

            this.getTitlesResult();
        });
        EventBus.listen('update-titles-search', (data) => {
            if (this.search !== data) {
                this.search = data;
                this.refreshData();
            }
        });
        EventBus.listen(EventNames.RESET_TITLE_SEARCH, () => {
            this.resetData();
        });
    }
}
</script>
