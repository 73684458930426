<template>
    <v-card
        outlined>
        <v-card-title
            class="primaryLight text-center py-2"
            style="word-break: normal">
            <v-row dense>
                <v-col>
                    <img
                        width="auto"
                        height="90"
                        :src="asset(require('../../images/banners/premium_answers.png'))"
                        alt="PREMIUMANSWERS"
                        title="premiumanswers">
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text
            v-if="tab === 0"
            class="pt-4">
            <p>
                Premium Answers delivers a constantly expanding searchable database of questions and their carefully crafted interpretations from the ICC team of technical experts. Support for expedited custom questions is upcoming.
            </p>
            <v-row
                dense
                v-for="(item, key) in blurbs"
                :key="key">
                <v-col cols="auto">
                    <v-icon color="primary">
                        mdi-check
                    </v-icon>
                </v-col>
                <v-col>
                    <p class="mb-0">
                        {{item}}
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text
            v-else
            class="pt-4">
            <html-block
                slug="quick-ask">
                <p class="fs-16 mt-4 text-center font-weight-medium w-full">
                    No information yet.<br>  Please check back later.
                </p>
            </html-block>
            <sample-answer-button
                class="mt-2">
            </sample-answer-button>
        </v-card-text>
    </v-card>
</template>

<script>
    import HtmlBlock from "../HtmlBlock.vue";
    import { AppParameters } from "../../AppParameters";
    import SampleAnswerButton from "./questions/SampleAnswerButton.vue";

    export default {
        name: 'WhatIsPremiumAnswers',
        components: {SampleAnswerButton, HtmlBlock},
        data() {
            return {
                blurbs: [
                    'Real opinions from ICC experts',
                    'Additional questions added regularly',
                    'Useful links to relevant code sections',
                    'Broad collection of topics'
                ],
                sampleQuestionsPdfUrl: AppParameters.quick_consult_sample_questions_pdf
            }
        },
        props: {
            tab: {
                type: Number,
                default: 0
            }
        }
    }
</script>
