var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: {
                fab: "",
                "x-small": "",
                color: _vm.sectionId === _vm.activeBookmark ? "primary" : "",
              },
              on: {
                click: function ($event) {
                  return _vm.setActiveBookmark()
                },
              },
            },
            [
              _c(
                "v-icon",
                { attrs: { dark: "" } },
                [
                  _vm.sectionId === _vm.activeBookmark
                    ? [
                        _vm._v(
                          "\n                    bookmark\n                "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n                    bookmark_outline\n                "
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c(
            "v-card",
            { staticClass: "grey lighten-5 scroll", attrs: { flat: "" } },
            [
              _c("v-card-text", [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }