<template>
    <back-to-top
        :bottom="isMobileAndTablet ? '66px' : '86px'"
        :right="isMobileAndTablet ? '10px' : '40px'"
        visibleoffset="400"
        style="z-index: 199">
        <v-btn
            icon
            dark
            class="primary">
            <v-icon>arrow_drop_up</v-icon>
        </v-btn>
    </back-to-top>
</template>

<script>
    import BackToTop from 'vue-backtotop'
    import Mobile from '../../inc/mixins/mobile';

    export default {
        components: {
            BackToTop
        },
        mixins: [
            Mobile
        ]
    }
</script>
