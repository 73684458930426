<template>
    <v-row
        class="exam-tips-container grey lighten-3"
        :class="{
            'pb-8 mt-8': !isMobilePort
        }"
        >
        <v-col>
            <v-row>
                <v-col class="text-center">
                    <h1 class="font-weight-regular pt-5 pb-2 oxygen"
                        v-html="sectionData.title">
                    </h1>
                    
                </v-col>
            </v-row>
            <v-row class="feature-tips"
                :class="{
                    'mobile-scroll' : isMobilePort
                }">
                <v-col
                    :cols="!isMobilePort ? 6 : 12"
                    v-for="feature in sectionData.features"
                    :key="feature.id">
                    <v-sheet class="py-5 px-10" >
                        <h4 v-if="feature.title"> {{ feature.title }} </h4>
                        <p v-html="feature.description"></p>
                        <p v-if="feature.buttonText" align="center">
                            <v-btn
                                :small="small"
                                class="accent2 white--text"
                                :href="feature.link"
                                :target="feature.target ? feature.target : '_self'">
                                {{ feature.buttonText }}
                            </v-btn>
                        </p>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        props: {
            sectionData: {
                required: true,
                type: Object
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style
    lang="scss"
    scoped>
    .exam-tips-container {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        img {
            max-width: 100%;
        }
        .oxygen {
            font-family: Oxygen;
        }
        .exam-link-detail{
            font-size: 20px;
            line-height: 26px;
            font-weight: 700; 
        }
        @media (min-width: 768px) {
            h1 {
                font-size: 36px;
                line-height: 43px;
            }
            .feature-tips{
                padding: 10px 268px 10px 268px;
            }
        }
        @media (min-width: 768px) and (max-width: 1088px) {
            .feature-tips{
                padding: 10px 100px 10px 100px;
            }
        }
        @media (max-width: 768px) {
            .mobile-scroll {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(2, calc(100% - 50px));
                overflow-x: auto;
            }
        }
    }
</style>
