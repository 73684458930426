<template>
    <v-expand-transition>
        <v-sheet
            class="content-container"
            color="accent2Light"
            v-if="premiumInfoActive">
            <v-container class="py-0">
                <v-row
                    dense
                    align="center">
                    <v-col
                        class="px-5 pb-0">
                        <v-row
                            dense>
                            <v-col cols="auto">
                                <v-icon
                                    class="mt-2"
                                    color="accent2">
                                    mdi-creation
                                </v-icon>
                            </v-col>
                            <v-col>
                                <h2 :class="isMobilePort && 'title'">
                                    {{
                                        hasPremiumSubscription ?
                                            'Explore Premium tools and features' :
                                            'Subscribe to Digital Codes Premium'
                                    }}
                                </h2>
                                <h3
                                    :class="isMobilePort && 'caption'"
                                    class="font-weight-regular mt-2">
                                    <slot name="subText">
                                        {{
                                            hasPremiumSubscription ?
                                                'You have an active premium subscription to Digital Codes' :
                                                'Get access to full questions and answers with your subscription'
                                        }}
                                    </slot>
                                </h3>
                                <inline-icons :type="type">
                                </inline-icons>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        v-if="!hasPremium"
                        cols="12"
                        sm="auto"
                        :order="isMobilePort ? '12' : ''">
                        <premium-learn-more
                            outlined
                            color="accent"
                            :block="isMobilePort">
                        </premium-learn-more>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto"
                        :order="isMobilePort ? '12' : ''">
                        <start-free-trial
                            :block="isMobilePort">
                        </start-free-trial>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            @click="premiumInfoActive = false">
                            <v-icon
                                color="accent">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-expand-transition>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    import InlineIcons from "../icons/InlineIcons.vue";
    import StartFreeTrial from "../buttons/StartFreeTrial.vue";
    import PremiumLearnMore from "../buttons/PremiumLearnMore.vue";

    export default {
        name: 'AccountAccessExpand',
        components: {PremiumLearnMore, StartFreeTrial, InlineIcons},
        data() {
            return {
                premiumInfoActive: false
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            type: {
                required: false,
                type: String,
                default: 'askSearchSearch'
            }
        },
        methods: {
            toggleExpand() {
                this.premiumInfoActive = !this.premiumInfoActive
            }
        },
        created() {
            EventBus.listen('toggle-access-expand', () => this.toggleExpand());
        }
    }
</script>
