var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      on: {
        click: function ($event) {
          return _vm.openFeedbackModal()
        },
      },
    },
    [
      _vm._v("\n    Share your feedback "),
      _c("v-icon", { attrs: { color: "primary", small: "" } }, [
        _vm._v("mdi-comment-text-outline"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }