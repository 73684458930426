var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "overflow-auto",
      style: {
        background: _vm.$vuetify.theme.themes.light.primaryLight,
        height: `calc(100% - ${_vm.chapterWrapHeaderHeight}px)`,
      },
    },
    [
      _vm.freeView
        ? _c("trial-action", {
            attrs: {
              title: "Bookmark and add notes to code sections",
              content:
                "Organize thoughts by bookmarking sections and adding notes that you can refer to anytime.",
            },
          })
        : !_vm.freeView && (_vm.loading_tags || _vm.loading_notes)
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-row",
              { attrs: { dense: "" } },
              [
                _c(
                  "v-col",
                  [
                    _c("v-select", {
                      staticClass: "filter-by-tag white mb-3",
                      attrs: {
                        outlined: "",
                        dense: "",
                        items: _vm.tag_categories,
                        "item-text": "tag",
                        "item-value": "id",
                        clearable: true,
                        "hide-details": true,
                        attach: "",
                        chips: "",
                        "small-chips": "",
                        "deletable-chips": "",
                        label: "Filter By Project Tags:",
                        multiple: "",
                        "menu-props": { bottom: true, offsetY: true },
                      },
                      model: {
                        value: _vm.tag_value,
                        callback: function ($$v) {
                          _vm.tag_value = $$v
                        },
                        expression: "tag_value",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            class: _vm.freeView ? "disable-btn" : "",
                            attrs: {
                              color: "primary",
                              depressed: "",
                              title:
                                "Click the Manage Notes button to share, print and manage your notes",
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                !_vm.freeView ? _vm.goToRoute("notes") : null
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Manage Notes\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            class: _vm.freeView ? "disable-btn" : "",
                            attrs: {
                              outlined: "",
                              color: "primary",
                              title:
                                "A new print version is out! Click the Migrate Notes button to move or clone your notes",
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                !_vm.freeView ? _vm.migrateNotes() : null
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Move Notes\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.notesCheck
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _vm._l(_vm.chapter_notes, function (note, index) {
                          return [
                            _c(
                              "v-row",
                              {
                                key: index,
                                class: {
                                  "lightgreen accent2--text": _vm.activeNote(
                                    note.section_number
                                  ),
                                },
                              },
                              [
                                _c("v-col", { staticClass: "py-1" }, [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _c("a", {
                                      staticClass: "toc-chapter-item-focused",
                                      attrs: { tabindex: "0" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.chaptersTitle(note)
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showContent(note, true)
                                        },
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.showContent(note, true)
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("p", { staticClass: "caption text-center" }, [
                        _vm._v(
                          "You currently do not have any annotations or bookmarks for this book."
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
          ],
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "700" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  attrs: { "primary-title": "" },
                },
                [_vm._v("\n                Move Notes\n            ")]
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", { staticClass: "text-center pt-8" }, [
                  _vm._v(
                    "\n                    You currently do not have any notes to be moved right now.\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }