var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container py-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.asset(
                          require("../../../images/logo_dc.png"),
                          "default"
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "h1",
                      { staticClass: "primary--text font-weight-regular" },
                      [
                        _vm._v("\n                        Welcome back to"),
                        _c("br"),
                        _vm._v("ICC Digital Codes!\n                    "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: _vm.isMobilePort ? 12 : 6 } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h4",
                      { staticClass: "primary--text font-weight-regular" },
                      [
                        _vm._v(
                          "\n                        If you don't have an account?\n                        "
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-decoration-underline font-weight-bold",
                            attrs: { href: this.getRoute("signup") },
                          },
                          [_vm._v("Sign Up")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h2",
                      { staticClass: "primary--text font-weight-regular" },
                      [
                        _vm._v(
                          "\n                        The most trusted and authentic source of building codes, standards and more\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("login-form", {
                attrs: {
                  formAction: _vm.formAction,
                  referer: _vm.referer,
                  token: _vm.token,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pl-16",
              attrs: { cols: _vm.isMobilePort ? 12 : 6 },
            },
            [
              _c("h3", { staticClass: "font-weight-regular pb-5" }, [
                _vm._v(
                  "\n                Your credentials will allow you access to all ICC properties including:\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.properties, function (item, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      staticClass: "pa-0",
                      attrs: { cols: _vm.isMobilePort ? 12 : 6 },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { medium: "", color: "primary" } },
                                [
                                  _vm._v(
                                    "\n                                mdi-circle-small\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "py-0" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-decoration-underline",
                                attrs: { href: item.link, target: "_blank" },
                              },
                              [
                                _c("h4", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.text) +
                                      "\n                                "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  !_vm.isMobileAndTablet
                    ? _c("v-col", { attrs: { cols: "6" } }, [
                        _c("img", {
                          attrs: {
                            "max-width": "100%",
                            src: _vm.asset(
                              require("../../../images/illistrations/loud_speaker.webp")
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }