<template>
  <v-dialog max-width="700" ref="container">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text icon color="blue lighten-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title class="primary white--text headline">
          Enterprise Invite Link
          <v-spacer></v-spacer>
          <v-icon
            small
            class="mr-2 accent2--text"
            @click="() => (dialog.value = false)"
            >close</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row v-if="alert_message">
            <v-col>
              <v-alert
                light
                dismissible
                :value="alert_status"
                :type="alert_type"
              >
                {{ alert_message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                :id="'inviteModel-'+token"
                solo
                rows="5"
                auto-grow
                hide-details
                :value="url"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            data-qa="share-section-modal-close"
            color="primary"
            text
            @click="() => (dialog.value = false)"
          >
            Close
          </v-btn>
          <v-btn color="primary" @click="copyURL"> Copy URL </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialog: false,
      alert_message: "",
      alert_type: "success",
      alert_status: false,
      url:'',
    };
  },
  props: {
    token: String
  },
  methods: {
    copyURL() {
      const element =  document.getElementById("inviteModel-"+ this.token);
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
      if(element.value){
        this.alert_message = 'Link copied to clipboard.';
        this.alert_type = 'success';
        this.alert_status = true;
        element.sele
      }
    },
    getInviteLink() {
        return window.location.origin + Routing.generate('accept_enterprise_invite', {token: this.token});
    }
  },
  created(){
    this.url = this.getInviteLink();
  }
};
</script>
