var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        fullscreen: _vm.isMobileAndTablet,
        "max-width": "600",
      },
      model: {
        value: _vm.data.show,
        callback: function ($$v) {
          _vm.$set(_vm.data, "show", $$v)
        },
        expression: "data.show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { height: "400" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text py-0",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "white--text",
                        attrs: {
                          href: "https://icc-es.org/",
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "mt-n9 es-logo-text",
                          attrs: {
                            src: _vm.asset(
                              require("../../images/es_scope_logo.png"),
                              "default"
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "ml-9 scope-text" }, [
                    _c("h6", [_vm._v(_vm._s(_vm.data.acNumber) + " Scope")]),
                  ]),
                  _vm._v(" "),
                  !_vm.isMobilePort ? _c("v-spacer") : _vm._e(),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "font-weight-bold text-caption mb-0" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "white--text",
                            attrs: {
                              href: "https://icc-es.org/evaluation-report-program/acceptance-criteria/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("What is an AC Scope?")]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto pl-10" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text acscope-close-icon",
                          on: {
                            click: function ($event) {
                              _vm.data.show = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        close\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.data.acScopeError
            ? _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-5" },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(_vm.data.errorMessage),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card-text",
                { staticClass: "pa-8" },
                [
                  _c("p", { staticClass: "subtitle-1" }, [
                    _c("b", [_vm._v("AC Scope Content")]),
                  ]),
                  _vm._v(" "),
                  _vm.loading
                    ? [
                        _c(
                          "v-container",
                          { staticClass: "text-center py-12" },
                          [
                            _c("v-progress-circular", {
                              staticClass: "py-6",
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(_vm.acScopeContent) },
                        }),
                      ],
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }