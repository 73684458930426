var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: { transition: "scale-transition", type: "list-item" },
          })
        : [
            _vm.templateStyle && _vm.templateStyle === "button"
              ? _c(
                  "v-btn-toggle",
                  {
                    attrs: { dense: "" },
                    model: {
                      value: _vm.durationToggle,
                      callback: function ($$v) {
                        _vm.durationToggle = $$v
                      },
                      expression: "durationToggle",
                    },
                  },
                  _vm._l(_vm.duration, function (button, index) {
                    return button.active
                      ? _c(
                          "v-btn",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.changeDuration(button)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(button.text) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  1
                )
              : _c(
                  "v-container",
                  { staticClass: "select_duration pa-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "label",
                              { staticClass: "caption primary--text" },
                              [_vm._v("Billing Frequency")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.item.subscription_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "subscription_type", $$v)
                                  },
                                  expression: "item.subscription_type",
                                },
                              },
                              _vm._l(_vm.duration, function (chip, index) {
                                return _c("v-radio", {
                                  key: index,
                                  attrs: {
                                    value: chip.value,
                                    color: "warning",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeDuration(chip)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "primary--text" },
                                              [_vm._v(_vm._s(chip.text))]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }