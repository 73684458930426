<template>
    <div>
        <new-tag-modal></new-tag-modal>
        <bookmark-modal></bookmark-modal>
        <notes-modal></notes-modal>
        <share-note :key="shareModalKey"></share-note>
    </div>
</template>

<script>
    import NewTagModal from '../notesModal/tagsModal';
    import BookmarkModal from '../notesModal/bookmarkModal';
    import NotesModal from '../notesModal/notesModal';
    import ShareNote from '../../modals/share_annotation_bookmark';

    export default {
        data() {
            return {
                shareModalKey: 0
            }
        },
        components: {
            NewTagModal,
            BookmarkModal,
            NotesModal,
            ShareNote
        },
        created() {
            EventBus.listen('force-rerender-share-note', ()=> this.shareModalKey +=1);
        }
    }
</script>
