var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "white--text d-flex justify-center align-center",
      class: { primary: !_vm.error, error: _vm.error },
      staticStyle: { "min-height": "calc(32px + 2rem)" },
    },
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            staticStyle: { "max-width": "16rem" },
            attrs: { indeterminate: "", color: "white", height: "6" },
          })
        : [
            _vm.error
              ? [
                  _vm._v(
                    "\n            Something went wrong! " +
                      _vm._s(_vm.error) +
                      "\n        "
                  ),
                ]
              : _vm.isRatingSaved
              ? [_vm._v("\n            Thanks for your feedback!\n        ")]
              : [
                  _c("div", { staticClass: "mr-2 pl-2" }, [
                    _vm._v("Was this Code Insight useful?"),
                  ]),
                  _vm._v(" "),
                  _c("v-rating", {
                    attrs: {
                      "background-color": "white",
                      color: "warning",
                      hover: "",
                      length: "5",
                      size: "32",
                      value: _vm.value,
                    },
                    on: { input: _vm.ratingUpdated },
                  }),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }