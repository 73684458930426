var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "password_reset" },
        [
          _vm.content
            ? _c(
                "v-col",
                { attrs: { order: "2", "order-sm": "1", cols: "12", sm: "6" } },
                [
                  _c("div", {
                    staticClass: "login-content",
                    domProps: { innerHTML: _vm._s(_vm.content) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { order: "1", "order-sm": "2", cols: "12", sm: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("h2", { staticClass: "primary--text title" }, [
                      _vm._v("Password Reset"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "primary mb-5 text-left",
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "caption white--text --text mb-0",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        For security reasons, we need you to reset your password. We have sent an \n                                        email to your registered email address. Please check your inbox or spam \n                                        folder for further instructions.\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.length > 0
                        ? _c(
                            "div",
                            { staticClass: "error-message mb-4" },
                            _vm._l(_vm.errors, function (error, key) {
                              return _c(
                                "span",
                                [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: "red",
                                        "text-color": "white",
                                        small: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(error) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }