<template>
    <actionable-alert
            :visible="visible"
            action-text="Jump to Section"
            @onAction="clickedReturn"
            @onDismiss="clickedClose"
    >

        <template v-slot:text>

            <v-icon color="blue darken-4" class="mr-1">
                mdi-history
            </v-icon>

            Continue where you left off: <span class="font-weight-bold" v-if="sectionTitle">({{ sectionTitle }})</span>
        </template>

    </actionable-alert>
</template>

<script>
import {UserBookProgressApi} from "../api/UserBookProgressApi";
import ActionableAlert from "./ActionableAlert.vue";

export default {
    components: {ActionableAlert},
    props: {
        documentInfoId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            visible: false,
            isLoading: false,
            // from API response
            sectionId: null,
            sectionTitle: null
        }
    },
    methods: {
        clickedReturn() {
            const url = `/lookup/content-id/${this.sectionId}`;
            window.location = url;
        },
        clickedClose() {
            this.visible = false;

            UserBookProgressApi.clear(this.documentInfoId).then(() => {
                // do nothing
            });
        }
    },
    created() {

        UserBookProgressApi.fetch(this.documentInfoId).then((data) => {

            if (data.continue_url) {
                this.visible = true;

                this.sectionId = data.section_id;
                this.sectionTitle = data.section_title;
            }

        });
    }
}
</script>
