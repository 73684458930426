export const DebugModule = {
    state: {
        debugShowResultScores: false
    },
    getters: {
        getDebugShowResultScores: (state) => state.debugShowResultScores,
    },
    mutations: {
        setDebugShowResultScores(state, value) {
            state.debugShowResultScores = value;
        }
    }
}
