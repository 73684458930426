var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 container-height" },
    [
      _c(
        "div",
        { staticClass: "white" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h1", [
                      _vm._v(
                        "\n                        Account\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c("alert-bar", {
            staticClass: "mt-4",
            attrs: {
              dismissible: "",
              message: _vm.message,
              "notification-type": _vm.type,
            },
            on: {
              dismiss: function ($event) {
                ;[(_vm.message = null), (_vm.type = null)]
              },
            },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-4", attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "h2",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mt-n1 black--text" },
                                [
                                  _vm._v(
                                    "\n                                mdi-account-outline\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                            Personal Details\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.loading.user
                            ? _c("loading-linear", {
                                attrs: {
                                  "show-message": false,
                                  "container-padding": "px-0 pt-8 pb-4",
                                },
                              })
                            : [
                                _c(
                                  "p",
                                  { staticClass: "font-weight-bold mt-4 mb-1" },
                                  [
                                    _vm._v(
                                      "\n                                Name\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.name
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.name) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "font-weight-bold mt-4 mb-1" },
                                  [
                                    _vm._v(
                                      "\n                                Email\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.email
                                  ? _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.email) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c("v-card-text", [
                        _c(
                          "h2",
                          [
                            _c("v-icon", { staticClass: "mt-n1 black--text" }, [
                              _vm._v(
                                "\n                                mdi-shield-outline\n                            "
                              ),
                            ]),
                            _vm._v(
                              "\n                            Security\n                        "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "font-weight-bold mt-4 mb-1" }, [
                          _vm._v(
                            "\n                            Password\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                            Your password can be changed on your myICC account.\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              href: "https://my.iccsafe.org/profile/info/change-password",
                              title: "Manage Password",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Manage Password\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "h2",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mt-n1 black--text" },
                                [
                                  _vm._v(
                                    "\n                                mdi-cog-outline\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                            Preferences\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "font-weight-bold mt-4 mb-1" },
                            [
                              _vm._v(
                                "\n                            Title Image\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                            Title cards and title overview will display on Digital Codes with new icons or the print book cover.\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.loading.preferences
                            ? _c("loading-linear", {
                                attrs: {
                                  "show-message": false,
                                  "container-padding": "px-0 pt-4 pb-8",
                                },
                              })
                            : _c(
                                "v-radio-group",
                                {
                                  staticClass: "custom-radios",
                                  attrs: {
                                    disabled: _vm.loading.preferencesSaving,
                                  },
                                  model: {
                                    value: _vm.selected,
                                    callback: function ($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { value: "icon" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updatePreferences("icon")
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-1" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            New Digital Codes icons\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("list", {
                                                  attrs: {
                                                    listOutline: "",
                                                    "override-cover": true,
                                                    title: _vm.defaultTitle,
                                                    "show-version": false,
                                                    "show-favorite": false,
                                                    "show-link-icon": false,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "mt-2",
                                    attrs: { value: "cover" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updatePreferences("cover")
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-1" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Print book cover (legacy)\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("list", {
                                                  attrs: {
                                                    listOutline: "",
                                                    width: "70px",
                                                    "override-title-icon": true,
                                                    title: _vm.defaultTitle,
                                                    "show-version": false,
                                                    "show-favorite": false,
                                                    "show-link-icon": false,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }