var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return "1" !== _vm.isProntoReferer
    ? _c(
        "v-btn",
        {
          staticClass: "primary white--text",
          attrs: { small: _vm.small, href: _vm.getRoute("user_exams") },
        },
        [_vm._v("\n    Return to Exam Resources\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }