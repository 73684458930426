var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasBookmarksOrAnnotations
    ? _c("expandable-panel", {
        staticClass: "notes-expansion-panel-wrapper",
        attrs: {
          expand: _vm.expand,
          id: `notes-expansion-panel-wrapper-${_vm.sectionId}`,
          outlined: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { display: "inline" } },
                    [
                      _c(
                        "span",
                        { staticClass: "text--secondary font-weight-bold" },
                        [
                          _vm._v(
                            "\n                NOTES (" +
                              _vm._s(
                                _vm.bookmarks.length + _vm.annotations.length
                              ) +
                              ")\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              float: "right",
                                              cursor: "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openNoteModal.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                    mdi-bookmark-plus-outline\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2219223529
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "tooltip-content" }, [
                            _vm._v("Add note, bookmark or highlight"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _vm._l(_vm.bookmarks, function (item) {
                    return _c(
                      "v-card",
                      {
                        key: item.id,
                        attrs: {
                          id: "bookmark-list-item-" + item.id,
                          flat: "",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-0 notes-bookmarks-section" },
                          [
                            _c("note-list-item", {
                              attrs: {
                                type: "bookmark",
                                data: item,
                                tags: _vm.tags,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.annotations, function (item) {
                    return _c(
                      "v-card",
                      { key: item.id, attrs: { flat: "" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-0 notes-bookmarks-section" },
                          [
                            _c("note-list-item", {
                              attrs: {
                                type: "note",
                                data: item,
                                tags: _vm.tags,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2384945694
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }