<template>
    <div>
        <v-dialog
            v-model="concurrencyModal"
            persistent max-width="800px">
            <v-card flat>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    <template v-if="!editItem">
                        Generate Concurrent Code
                    </template>
                    <template v-else>
                        Update Concurrent Code
                    </template>
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="confirmCloseIfStarted()">close</v-icon>
                </v-card-title>
                <div class="steps_container">
                    <v-stepper v-model="step">
                        <v-stepper-header class="height-auto">
                            <v-stepper-step
                                :complete="step > 1"
                                step="1"
                                :rules="[() => rules.accessCode]">
                                Create Code
                                <p v-if="step > 1">
                                    <small class="black--text">
                                        <span class="font-weight-bold">Code:</span>
                                        {{this.accessCode}}
                                    </small>
                                </p>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :complete="step > 2"
                                step="2"
                                :rules="[() => rules.assignments]">
                                Select Books
                                <small
                                    class="license_info"
                                    v-if="step > 2">
                                    <small class="black--text">
                                        <span class="font-weight-bold" title="Amount of licenses selected">Licenses: </span>
                                        {{licenses}}
                                    </small><br/>
                                    <small class="black--text">
                                        <span class="font-weight-bold" title="No of selected subscriptions">Subscriptions: </span>
                                        {{select.length}}
                                    </small>
                                </small>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                v-if="additionalTab"
                                :complete="step > 3"
                                step="3"
                                :rules="[() => rules.assignLicense]">
                                Subscriptions
                            </v-stepper-step>
                            <v-divider v-if="additionalTab"></v-divider>
                            <v-stepper-step
                                :complete="isCompleted(additionalTab ? 4 : 3)"
                                :step="(additionalTab) ? 4 : 3"
                                :rules="[() => rules.adminEmail]">
                                User Access
                                <small
                                    class="black--text"
                                    v-if="rules.adminEmail === true && '' != admin_emails[0].email && admin_emails">
                                    <span class="font-weight-bold">Admins: </span>{{admin_emails.length}}
                                </small>
                                <small v-else-if="step == (additionalTab ? 4 : 3)">{{errorMessage}}</small>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                                :step="(additionalTab) ? 5 : 4"
                                :rules="[() => rules.email]">
                                Notify
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat>
                                    <v-row align="center">
                                        <v-col>
                                            <p class="black--text">
                                                Type or auto generate access code you want to create. <br/>
                                                <small>
                                                    Concurrent code must be 6 or more alphanumeric characters and unique.
                                                </small>
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col>
                                            <v-text-field
                                                data-qa="dashboard-licenses-concurrent-code"
                                                solo
                                                :class="{ 'mb-1': $vuetify.breakpoint.smAndUp }"
                                                label="Enter access code"
                                                v-model="accessCode"
                                                hide-details>
                                            </v-text-field>
                                        </v-col>
                                        <v-col sm="auto">
                                            <v-btn
                                                data-qa="dashboard-licenses-concurrent-auto-generate"
                                                @click="autoGenerateCode()">
                                                Auto Generate
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkAccessCode()">
                                        <v-col>
                                            <v-checkbox
                                                data-qa="dashboard-licenses-concurrent-force-logout"
                                                class="mt-0 pt-0"
                                                v-model="logout"
                                                label="Force logout any connections with old concurrent code"
                                                color="primary"
                                                hide-details>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <p v-if="errorMessage" class="error--text caption">{{errorMessage}}</p>
                                <v-btn
                                    data-qa="dashboard-licenses-concurrent-continue"
                                    color="primary"
                                    @click="validateInputs()">
                                    <span v-if="!loading">Continue</span>
                                    <v-progress-circular
                                        v-else
                                        :size="20"
                                        indeterminate
                                        color="white">
                                    </v-progress-circular>
                                </v-btn>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-card flat>
                                    <v-row>
                                        <v-col>
                                            <span class="black--text">Select books for the Concurrent Code</span><br/>
                                            <small
                                                class="black--text"
                                                v-if="rules.assignments === true && step <= 2">
                                                Group your Digital Codes Premium titles under a common code for shared access.<br/>
                                                <span class="font-weight-bold">Note:</span> This will convert the license from personal use
                                                to concurrent.
                                            </small>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <span
                                                v-if="max_number > 0"
                                                class="black--text">
                                                Select quantity of license for this Concurrent Code, Max: ({{max_number}})
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        align="center"
                                        v-if="max_number > 0">
                                        <v-col sm="auto">
                                            <v-text-field
                                                data-qa="dashboard-licenses-concurrent-license-quantity"
                                                solo
                                                v-model="licenses"
                                                class="pa-0 pt-1"
                                                hide-details
                                                single-line
                                                :disabled="disabled"
                                                type="number"
                                                :min="1"
                                                :max="max_number">
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-slider
                                                data-qa="dashboard-licenses-concurrent-slider"
                                                v-model="licenses"
                                                :min="1"
                                                :max="max_number"
                                                step="1"
                                                :disabled="disabled"
                                                thumb-label
                                                hide-details
                                                ticks>
                                            </v-slider>
                                        </v-col>
                                        <v-col sm="auto">
                                            <v-btn
                                                data-qa="dashboard-licenses-concurrent-continue"
                                                small
                                                @click="getSubscriptions()">
                                                <span v-if="!disabled">Continue</span>
                                                <span v-else>Change</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col class="text-center">
                                            <p class="caption">You don't have any available subscriptions! <br/>You can buy Subscriptions from <a @click="goToTheShop">here</a></p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-combobox
                                    data-qa="dashboard-licenses-concurrent-subscription"
                                    solo
                                    v-if="this.subscriptions.length"
                                    v-model="select"
                                    :items="this.sortedSubscriptions"
                                    item-text="title"
                                    label="Select subscriptions"
                                    multiple
                                    small-chips
                                    :menu-props="{  top: true, maxHeight: '60vh', contentClass: 'books_menu', offsetY: true}"
                                    hide-details
                                    chips
                                    deletable-chips
                                    tags>
                                    <template slot="item" slot-scope="data">
                                        <template disabled>
                                            <v-list-item-avatar>
                                                <img :src="data.item.avatar">
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.title"></v-list-item-title>
                                                <v-list-item-subtitle v-html="'Available licenses for concurrent: ' + data.item.license_configuration.available"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-combobox>

                                <div class="mt-2">&nbsp;</div>

                                <div v-if="toRestore.length">
                                    <v-list>
                                        <large class="red--text">Note: These Subscriptions will be restored to personal use,
                                            because they don't have enough licenses!
                                        </large>
                                        <template v-for="(item, index) in toRestore">
                                            <v-list-item :key="index">
                                                <v-list-item-title>
                                                    <small>{{ item.title }}</small>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-divider
                                                v-if="index + 1 < toRestore.length"
                                                :key="`divider-${index}`">
                                            </v-divider>
                                        </template>
                                    </v-list>
                                </div>
                                <p v-if="errorMessage" class="error--text caption">{{errorMessage}}</p>

                                <div v-if="isBookMenuOpen">
                                    <v-btn color="error">
                                        <v-icon dark left>mdi-minus-circle</v-icon> Close Menu
                                    </v-btn>
                                </div>

                                <div v-else>

                                    <v-btn
                                        data-qa="dashboard-licenses-concurrent-back"
                                        v-if="this.subscriptions.length !== 0"
                                        @click.native="step = 1">
                                        Back
                                    </v-btn>
                                    <v-btn
                                        data-qa="dashboard-licenses-concurrent-continue"
                                        class="ml-0 primary"
                                        v-if="this.subscriptions.length !== 0"
                                        @click="checkIfSubscriptionsHaveMultipleTransactions()">
                                        Continue
                                    </v-btn>
                                </div>


                            </v-stepper-content>
                            <v-stepper-content v-if="additionalTab" step="3">
                                Select which subscription you want to use.
                                <p v-if="rules.assignLicense === true && step <= 3">
                                    <small>You have to choose which subscription you want to assign to this concurrent code!</small>
                                </p>
                                <div v-for="(item, index) in select" :key="index">
                                    <v-card v-if="item.grouped_license.length > 1">
                                        <v-toolbar flat color="white">
                                            <v-toolbar-title>{{item.title}}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <span v-if="isRequiredLicenseQuantityMatch() && totalSelectedLicense(index) === licenses">
                                                <v-icon color="green">check_circle</v-icon>
                                            </span>
                                            <span v-else>
                                                <v-icon color="red">error</v-icon>
                                            </span>
                                        </v-toolbar>
                                        <sub class="mb-2">
                                            You have selected
                                            <span class="font-weight-bold" :id="index">{{totalSelectedLicenseInGroup}}</span>
                                            from
                                            <span class="font-weight-bold">{{licenses}}</span> licenses!
                                        </sub>
                                        <v-data-table
                                            :key="item.id"
                                            :headers="headers"
                                            :items="item.grouped_license"
                                            hide-default-footer>
                                            <template v-slot:item="{ item }">
                                                <tr :class="{'lightgreen': selectedLicense[item.id]}">
                                                    <td class="text-xs-left">{{item.purchase_id}}</td>
                                                    <td class="text-center">{{item.expiration_date | moment("MMMM Do, YYYY") }}</td>
                                                    <td class="text-center">{{item.license_configuration.available}}</td>
                                                    <td class="text-xs-right">
                                                        <v-menu
                                                            offset-y
                                                            max-height="200px"
                                                            :disabled="isRequiredLicenseQuantityMatch() && selectedLicense[item.id] !== undefined && !selectedLicense[item.id]">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    small
                                                                    v-on="on">
                                                                    Selected &nbsp;
                                                                    <b v-if="selectedLicense[item.id]">({{selectedLicense[item.id]}})</b>
                                                                    <b v-else>(0)</b>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <div v-if="selectedLicense[item.id]">
                                                                    <a
                                                                        class="list__tile list__tile--link"
                                                                        @click="generateLicenses(item, 0, index)">
                                                                        <div class="pl-3 font-weight-bold list__tile__title">0</div>
                                                                    </a>
                                                                </div>
                                                                <div v-if='totalSelectedLicense(index) === licenses && selectedLicense[item.id]'>
                                                                    <v-list-item
                                                                        disabled
                                                                        v-for="n in item.license_configuration.available"
                                                                        :key="n"
                                                                        @click="generateLicenses(item, n, index)">
                                                                        <v-list-item-title>{{n}}</v-list-item-title>
                                                                    </v-list-item>
                                                                </div>
                                                                <div v-else-if='totalSelectedLicense(index) < licenses && selectedLicense[item.id] && totalSelectedLicense(index) !== licenses'>
                                                                    <v-list-item
                                                                        v-for="n in item.license_configuration.available"
                                                                        :key="n"
                                                                        @click="generateLicenses(item, n, index)">
                                                                        <v-list-item-title>{{n}}</v-list-item-title>
                                                                    </v-list-item>
                                                                </div>
                                                                <div v-else-if='totalSelectedLicense(index) === licenses && !selectedLicense[item.id]'>
                                                                    <v-list-item
                                                                        disabled
                                                                        v-for="n in item.license_configuration.available"
                                                                        :key="n"
                                                                        @click="generateLicenses(item, n, index)">
                                                                        <v-list-item-title>{{n}}</v-list-item-title>
                                                                    </v-list-item>
                                                                </div>
                                                                <div v-else-if='totalSelectedLicense(index) < licenses && totalSelectedLicense(index) < item.license_configuration.available && totalSelectedLicense(index) !== 0'>
                                                                    <v-list-item
                                                                        v-for="n in totalSelectedLicenseCount(totalSelectedLicense(index), item.license_configuration.available)"
                                                                        :key="n"
                                                                        @click="generateLicenses(item, n, index)">
                                                                        <v-list-item-title>{{n}}</v-list-item-title>
                                                                    </v-list-item>
                                                                </div>
                                                                <div v-else>
                                                                    <v-list-item
                                                                        v-for="n in item.license_configuration.available"
                                                                        :key="n"
                                                                        @click="generateLicenses(item, n, index)">
                                                                        <v-list-item-title>{{n}}</v-list-item-title>
                                                                    </v-list-item>
                                                                </div>
                                                            </v-list>
                                                        </v-menu>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </div>
                                <p v-if="errorMessage" class="caption error--text pt-2">{{errorMessage}}</p>
                                <div class="mt-4">
                                    <v-btn
                                        data-qa="dashboard-licenses-concurrent-back"
                                        v-if="additionalTab"
                                        @click.native="step = 2">
                                        Back
                                    </v-btn>
                                    <v-btn
                                        data-qa="dashboard-licenses-concurrent-continue"
                                        v-if="additionalTab"
                                        @click="validateSubscriptions()"
                                        class="ml-0 primary">
                                        Continue
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                            <v-stepper-content :step="(additionalTab) ? 4 : 3">
                                <span class="black--text">
                                    Admin users of a concurrent code have the permissions to view, create, edit and delete notes of other user’s notes created on premium content.
                                    To authorize another user as a concurrent admin user, please enter an email address below.
                                </span>
                                <p class="font-weight-bold green--text caption">
                                    (This is an optional step. You can continue without entering any email address.)
                                </p>
                                <p class="caption">
                                    <span class="font-weight-bold">Note:</span>
                                    To add multiple concurrent admin users, please add a comma after each email address.
                                </p>
                                <v-card-text class="text-center">
                                    <div class="row wrap">
                                        <v-layout row wrap>
                                            <v-flex xs9>
                                                <v-text-field
                                                    data-qa="dashboard-licenses-concurrent-email"
                                                    solo
                                                    label="Enter email address"
                                                    v-model="input_admin_emails"
                                                    :class="{ 'mb-1': $vuetify.breakpoint.smAndUp }">
                                                </v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-card-text>
                                <v-btn
                                    data-qa="dashboard-licenses-concurrent-back"
                                    @click.native="step = additionalTab ? 3 : 2">
                                    Back
                                </v-btn>
                                <v-btn
                                    data-qa="dashboard-licenses-concurrent-continue"
                                    color="primary"
                                    @click="validateAdminEmails()"
                                    :disabled="loading">
                                    <span v-if="!loading">Continue</span>
                                    <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                                </v-btn>
                            </v-stepper-content>
                            <v-stepper-content :step="(additionalTab) ? 5 : 4">
                                <span class="black--text">To notify a user that a concurrent code has been generated, please enter an email address below.</span><br/>
                                <small class="black--text" v-if="rules.email === true">
                                    Also, you may send emails later by switching to the "Notify Later" button!
                                </small><br/>
                                <small>
                                    If you do not want to notify users by emails that a concurrent code has been created right now, you may do that later!<br/>
                                    <span class="font-weight-bold">Note:</span> To add multiple recipients, please add a comma
                                    after each email address.
                                </small>
                                <v-switch
                                    class="ml-1"
                                    :label="(assign_emails === false) ? 'I will notify users now.' : 'I will notify users later.'"
                                    v-model="assign_emails">
                                </v-switch>
                                <v-card-text v-if="!assign_emails" class="text-center">
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                data-qa="dashboard-licenses-concurrent-email-notify"
                                                solo
                                                hide-details
                                                label="Enter email address"
                                                v-model="input_emails"
                                                :class="{ 'mb-1': $vuetify.breakpoint.smAndUp }">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-textarea
                                                data-qa="dashboard-licenses-concurrent-email-message"
                                                label="Message"
                                                solo
                                                rows="4"
                                                auto-grow
                                                counter=400
                                                class="black--text"
                                                v-model="description">
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <p v-if="rules.email !== true && errorMessage" class="caption error--text">{{errorMessage}}</p>
                                <v-btn
                                    data-qa="dashboard-licenses-concurrent-back"
                                    @click.native="step = additionalTab ? 4 : 3">
                                    Back
                                </v-btn>
                                <v-btn
                                    data-qa="dashboard-licenses-concurrent-continue"
                                    color="primary"
                                    @click="validateEmails()"
                                    :disabled="loading">
                                    <span v-if="!loading">Continue</span>
                                    <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                                </v-btn>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="endConcurrencyModalConfirm"
            max-width="500px">
            <v-card>
                <v-card-title>
                    Please confirm
                </v-card-title>
                <v-card-text>
                    <p>You have entered information that will not be saved when you exit this screen. Are you sure you want to leave?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        primary
                        @click="endConcurrencyModalConfirm=false">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="close()">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import BindEmail from '../mixins/susbcriptions_email';
    import Mobile from '../../../inc/mixins/mobile';
    import {AsyncUtils} from "../../../../util/AsyncUtils";

    export default {
        data() {
            return {
                isBookMenuOpen: false,
                groupSelectedLicence: 0,
                concurrencyModal: false,
                endConcurrencyModalConfirm: false,
                loading: false,
                modalKey: 0,
                editItem: false,
                accessCode: '',
                subscriptions: [],
                max_number: 1,
                select: [],
                toRestore: [],
                step: 1,
                rules: {
                    'accessCode': true,
                    'assignments': true,
                    'assignLicense': true,
                    'adminEmail': true,
                    'email': true
                },
                errorMessage: '',
                message: '',
                licenses: 1,
                disabled: true,
                selectedLicense: [],
                admin_emails: [{
                    'email': ''
                }],
                input_admin_emails: '',
                emails: [{
                    'email': ''
                }],
                input_emails: '',
                assign_emails: false,
                headers: [
                    {text: 'Order Id', value: '', sortable: false},
                    {text: 'Expiration Date', value: '', sortable: false},
                    {text: 'Available License', value: '', sortable: false},
                    {text: 'Actions', value: '', align: 'right', sortable: false},
                ],
                originalCode: [],
                description: ' ',
                logout: false
            }
        },
        props: {
            userId: {
                type: String,
                required: false
            }
        },
        mixins: [
            BindEmail,
            Mobile
        ],
        computed: {
            additionalTab() {
                let check = false;
                this.select.forEach(value => {
                    if (value.grouped_license.length > 1) {
                        check = true;
                    }
                });
                return check;
            },
            totalSelectedLicenseInGroup() {
                return this.groupSelectedLicence;
            },
            sortedSubscriptions() {
                return _.orderBy(this.subscriptions, ['year', 'title'], ['desc', 'asc']);
            }
        },
        methods: {
            isCompleted(currentStep) {
                return this.step > currentStep;
            },
            confirmCloseIfStarted() {
                if (this.accessCode) {
                    this.endConcurrencyModalConfirm = true;
                } else {
                    this.close();
                }
            },
            getSubscriptions() {
                this.disabled = !this.disabled;
                this.subscriptions = [];
                this.select = [];
                if (this.disabled) {
                    this.$http.get(Routing.generate('get_subscription', {userId: this.userId, activeOnly: 1}))
                        .then(response => {
                            this.appendSubscriptions(response);
                        });
                }
            },
            addSubscriptionsToConcurrentCode() {
                let route = (this.editItem) ? 'update_concurrent_code' : 'create_concurrency_code',
                    data = {
                        'code_id': (this.editItem) ? this.originalCode.id : null,
                        'subscriptions': this.select,
                        'concurrent_code': this.accessCode,
                        'licenses': this.licenses,
                        'admin_emails': this.admin_emails,
                        'emails': this.emails,
                        'message': this.description,
                        'logout': this.checkAccessCode() ? this.logout : false,
                        'user': this.userId
                    },
                    emailEvent = (this.editItem) ? this.UPDATE_CONCURRENT_CODE : this.CREATE_CONCURRENT_CODE;

                this.$http.post(Routing.generate(route), data)
                    .then(response => {
                        this.loading = false;
                        this.close();
                        this.resetAllData();
                        this.sendEmailNotification(emailEvent, data);
                        this.$session.set('switch-to-license-tab', 'Concurrent');
                        this.reloadSubscriptions();
                    });
            },
            appendSubscriptions(response) {
                this.toRestore = [];
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    let licenses = this.getAvailableLicense(data[i].group_licenses);
                    let transformedData = {
                        title: this.getBookTitle(data[i].document_info),
                        year: data[i].document_info.year.year,
                        id: data[i].id,
                        license_configuration: {available: licenses.result},
                        avatar: this.getBookCover(data[i].document_info),
                        grouped_license: data[i].group_licenses,
                        orderId: data[i].purchase_id,
                        bookSku: data[i].book_sku,
                        disabled: licenses.result < this.licenses
                    };
                    if (licenses.result >= this.licenses) {
                        this.subscriptions.push(transformedData);
                    }
                    if (this.editItem && licenses.hasItems) {
                        if (!transformedData.disabled) {
                            this.select.push(transformedData);
                        } else {
                            this.toRestore.push(transformedData);
                        }
                    }

                }
            },
            countMaxNumberOfLicenses() {
                this.$http.get(Routing.generate('get_subscription', {userId: this.userId, activeOnly: 1}))
                    .then(response => {
                        let result = 0;
                        for (let i = 0; i < response.data.length; i++) {
                            let licenses = this.getAvailableLicense(response.data[i].group_licenses);
                            (licenses.result >= result) ? result = licenses.result : 0;
                        }
                        this.max_number = result;

                        if (this.editItem) {
                            this.licenses = this.originalCode.licenses;
                        }
                    });
            },
            getAvailableLicense(licenses) {
                let result = 0,
                    hasItems = false;
                for (let i = 0; i <= licenses.length; i++) {
                    if (licenses[i] !== undefined && licenses[i].status !== 'Expired') {
                        result = result + licenses[i].license_configuration.available;
                        if (this.editItem) {
                            let child = this.originalCode.assigned_subscriptions;
                            for (let l = 0; l < child.length; l++) {
                                if (licenses[i].id === child[l].subscription.id) {
                                    licenses[i].license_configuration.available += child[l].licenses;
                                    result = result + child[l].licenses;
                                    hasItems = true;
                                }
                            }
                        }
                    }
                }

                return {result: result, hasItems: hasItems};
            },
            checkIfSubscriptionsHaveMultipleTransactions() {
                if (this.select.length <= 0) {
                    this.rules.assignments = false;
                    this.errorMessage = 'Please select at least one subscription to assign.';
                } else {
                    this.rules.assignments = true;
                    this.errorMessage = '';
                    this.step = 3;
                    this.selectedLicense = [];
                }
            },
            generateLicenses(item, licenses, index) {
                item['license'] = licenses;
                this.$set(this.selectedLicense, item.id, licenses);
                this.getGroupedLicenseCount(item);
                this.groupSelectedLicence = this.totalSelectedLicense(index);
            },
            getGroupedLicenseCount(item) {
                let total = 0;
                if (item.grouped_license) {
                    item.grouped_license.forEach(function (value) {
                        if (value.license !== undefined) {
                            total += value.license;
                        }
                    });
                }
                let error = (total !== this.licenses);
            },
            totalSelectedLicense(index) {
                let totalCount = 0;
                if (this.select[index].grouped_license.length > 1) {
                    this.select[index].grouped_license.forEach(function (value) {
                        totalCount += isNaN(value.license) ? 0 : value.license;
                    });                    
                }
                return totalCount;
            },
            totalSelectedLicenseCount(totalSelectedLicense, licenses) {
                return licenses - totalSelectedLicense;
            },
            isRequiredLicenseQuantityMatch() {
                let license = true;
                for (let i = 0; i < this.select.length; i++) {
                    if (this.select[i].grouped_license.length > 1) {
                        let counter = 0;
                        this.select[i].grouped_license.forEach(function (value) {
                            counter += isNaN(value.license) ? 0 : value.license;
                        });
                        if (counter !== this.licenses) {
                            license = false;
                        }
                    }
                }
                return license;
            },
            validateSubscriptions() {
                if (!this.isRequiredLicenseQuantityMatch()) {
                    this.rules.assignLicense = false;
                    this.errorMessage = 'You have assigned wrong quantity of licenses for some subscriptions';
                } else {
                    this.rules.assignLicense = true;
                    this.errorMessage = '';
                    this.step = 4;
                }
            },
            validateAdminEmails() {
                if ('' !== this.input_admin_emails.trim() && this.input_admin_emails.indexOf(',') !== -1) {
                    var receipents = this.input_admin_emails.split(',');
                    for (var i = 0; i < receipents.length; i++) {
                        var cleanInput = receipents[i].trim();
                        receipents[i] = {'email': cleanInput};
                    }
                    this.admin_emails = receipents;
                } else {
                    this.admin_emails[0].email = this.input_admin_emails.trim();
                }
                if (this.checkIfIsEmpty(this.admin_emails)) {
                    if (!this.validateEmail(this.admin_emails)) {
                        this.rules.adminEmail = false;
                        this.errorMessage = 'Please enter valid Email.';
                    } else {
                        this.errorMessage = '';
                        this.rules.adminEmail = true;
                    }
                }
                if(this.rules.adminEmail) {
                    this.loading = false;
                    this.step = this.additionalTab ? 5 : 4;
                }
            },
            validateEmails() {
                if (!this.assign_emails) {
                    // Check multiple recepients
                    if ('' !== this.input_emails.trim() && this.input_emails.indexOf(',') !== -1) {
                        var receipents = this.input_emails.split(',');
                        for (var i = 0; i < receipents.length; i++) {
                            var cleanInput = receipents[i].trim();
                            receipents[i] = {'email': cleanInput};
                        }
                        this.emails = receipents;
                    } else {
                        this.emails[0].email = this.input_emails.trim();
                    }

                    if (!this.checkIfIsEmpty(this.emails)) {
                        this.rules.email = false;
                        this.errorMessage = 'Please enter email.';
                    } else if (!this.validateEmail(this.emails)) {
                        this.rules.email = false;
                        this.errorMessage = 'Please enter valid Email.';
                    } else {
                        this.addSubscriptionsToConcurrentCode();
                        this.loading = true;
                        this.rules.email = true;
                    }
                } else {
                    this.loading = true;
                    this.rules.email = true;
                    this.addSubscriptionsToConcurrentCode();
                }
            },
            checkIfIsEmpty(emails) {
                for (let i = 0; i < emails.length; i++) {
                    if (emails[i].email.length === 0) {
                        return false;
                    }
                }
                return true;
            },
            validateEmail(emails) {
                let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                for (let i = 0; i < emails.length; i++) {
                    if (re.test(emails[i].email) === false) {
                        return false;
                    }
                }
                return true;
            },
            autoGenerateCode: function () {
                let text = "",
                    possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                for (var i = 0; i < 8; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                this.accessCode = text;
            },
            validateInputs() {
                this.countMaxNumberOfLicenses();
                if (!this.accessCode) {
                    this.rules.accessCode = false;
                    this.errorMessage = 'Please enter access code.';
                } else if (this.accessCode.length < 6 || this.accessCode.length > 20) {
                    this.rules.accessCode = false;
                    this.errorMessage = 'Access code must be greater than 6 or less than 20 characters.';
                } else {
                    this.errorMessage = '';
                    this.rules.accessCode = true;
                    this.description = 'Concurrent code: ' + this.accessCode;
                    this.generateAccessCode();
                }
            },
            generateAccessCode: function () {
                this.loading = true;
                if (!this.editItem || this.accessCode !== this.originalCode.access_code) {
                    this.$http.get(Routing.generate('check_concurrent_code', {code: this.accessCode}))
                        .then(response => {
                            if (response.data.success === false) {
                                this.rules.accessCode = false;
                                this.errorMessage = 'This code cannot be validated. Please choose another code.';
                                this.loading = false;
                            } else {
                                this.getSubscriptions();
                                this.loading = false;
                                this.step = 2;
                            }
                        });
                } else {
                    this.getSubscriptions();
                    this.loading = false;
                    this.step = 2;
                }
            },
            updateSelect() {
                this.select = [];
                this.assignments = [];
                this.step = 1;
            },
            close() {
                this.concurrencyModal = false;
                this.resetAllData();
                this.modalKey += 1;
            },
            resetAllData() {
                this.select = [];
                this.subscriptions = [];
                this.accessCode = '';
                this.adminEmails = [{'email': ''}];
                this.emails = [{'email': ''}];
                this.input_admin_emails = '';
                this.input_emails = '';
                this.errorMessage = '';
                this.toRestore = [];
                this.step = 1;
                this.selectedLicense = [];
                this.max_number = 1;
            },
            setItemCode(item) {
                if (item) {
                    this.editItem = true;
                    this.originalCode = item;
                    this.accessCode = item.access_code;
                    this.logout = item.logout;
                    this.setCodeAdmins(item.id);
                }
                this.concurrencyModal = true
            },
            setCodeAdmins(codeId) {
                this.$http.get(Routing.generate('get_concurrent_code_admin_emails', {codeId: codeId}))
                    .then(response => {
                        this.input_admin_emails = response.data.emails;
                    });
            },
            checkAccessCode() {
                if (this.editItem && this.accessCode !== this.originalCode.access_code) {
                    return true;
                }
                return false;
            }
        },
        watch: {
            concurrencyModal(val) {
                !val && EventBus.fire('change-modal-key');
            }
        },
        created() {
            EventBus.listen('show-modal-concurrency-code', (item) => this.setItemCode(item));

            AsyncUtils.setIntervalOnce(() => {

                // TODO: must be a better way....
                const bm = document.querySelector('.books_menu');
                this.isBookMenuOpen = bm && bm.style.display !== 'none';

            }, 300, "bm");
        },
    }

</script>
<style lang="scss">
    .steps_container {
/*        max-height: 505px;*/
        overflow: auto;
        .v-slider__ticks-container {
            background: rgba(0,0,0,.26);
        }
    }
    .books_menu {
      overscroll-behavior: contain;
    }
</style>
<style lang="scss" scoped>
    .v-stepper__header small {
        line-height: normal;
    }
</style>
