export class AsyncUtils {

    static async loadScriptAsync(scriptUrl) {

        return new Promise((resolve, reject) => {

            let scriptElement = document.createElement('script');
            scriptElement.setAttribute('src', scriptUrl);

            scriptElement.addEventListener('load', () => {
                resolve();
            });

            scriptElement.addEventListener('error', () => {
                reject();
            });

            document.head.appendChild(scriptElement);

        });
    }

    /**
     *
     * @param {Function} conditionFunction
     * @param {number} interval
     * @return {Promise<boolean>}
     */
    static async awaitUntil(conditionFunction, interval = 1000) {

        return new Promise((resolve) => {

            let myInterval = setInterval(function () {

                if (conditionFunction() === true) {
                    clearInterval(myInterval);
                    resolve(true);
                }

            }, interval);
        });
    }

    /**
     * Do not allow creating same interval more than once
     * @param handler
     * @param timeout
     * @param name
     */
    static setIntervalOnce(handler, timeout, name = "") {

        const uid = `__interval:${name}`;

        clearInterval(window[uid]);
        window[uid] = setInterval(handler, timeout);
    }

    static setIntervalWithImmediate(handler, delayMs, delayImmediateMs = 0) {
        setTimeout(() => {
            setInterval(handler, delayMs);
        }, delayImmediateMs);
    }

    static async newPromiseThatResolvesIn(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    static async newPromiseThatFailsIn(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(reject, ms);
        });
    }

    static async newNetworkRequestLikePromiseThatResolvesTo(result, ms = 800) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {

                if (!window.navigator.onLine) {
                    reject("NetworkError when attempting to fetch resource.");
                    return;
                }

                resolve(result);

            }, ms);
        });
    }

    /**
     *
     * @param {Promise} promise
     * @param {function(string|null, *)} callback
     */
    static promiseToErrorFirstCallback(promise, callback) {
        promise.then(result => callback(null, result)).catch(error => callback(error, undefined));
    }

    /**
     *
     * @param {Promise} promise
     * @return {{cancel: (function(): void), promise: Promise}}
     */
    static toCancellablePromise(promise) {

        const controller = new AbortController();
        const cancellable = new Promise((resolve, reject) => {
            const signal = controller.signal;

            promise.then((value) => {

                if (!signal.aborted) {
                    resolve(value);
                }

            }).catch((error) => {

                if (!signal.aborted) {
                    reject(error);
                }

            });

            signal.addEventListener("abort", () => {
                reject(signal.reason);
            });

        });

        return {
            promise: cancellable,
            cancel: () => controller.abort()
        }
    }
}
