var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isloadingMetaInfo
    ? _c(
        "div",
        { staticClass: "d-flex flex-column pa-8 align-center" },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v("Loading link information"),
          ]),
          _vm._v(" "),
          _c("v-progress-linear", {
            attrs: { active: true, color: "primary", indeterminate: "" },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "pa-10" }, [
        _c("a", { attrs: { target: "_blank", href: _vm.linkUrl } }, [
          _c(
            "div",
            { staticStyle: { "font-size": "20px" } },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.tabTitle) + "\n            "
              ),
              _c("v-icon", { staticStyle: { top: "-12px" } }, [
                _vm._v("mdi-open-in-new"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMetaInfoAvailable
            ? _c(
                "div",
                [
                  _c(
                    "p",
                    { staticClass: "text-caption py-2 mb-1 black--text" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.metaInfo.title) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.metaInfo.faviconUrl,
                                      width: "30",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", [
                                _c(
                                  "p",
                                  { staticClass: "mb-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.metaInfo.author) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "mb-0 text-caption" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.linkUrl) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }