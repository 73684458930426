var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "roboto mb-5" }, [_vm._v("No matches found")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n        Your search for '"),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.allSearchParams)),
        ]),
        _vm._v(
          "'\n        did not return any matches from your Premium library.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm.didYouMean && _vm.didYouMean.length > 0
        ? _c("div", [
            _c("p", [_vm._v("Did you mean: ")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.didYouMean, function (item, index) {
                return _c("li", { staticClass: "font-weight-bold ml-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-capitalize",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doSearch(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.suggest))]
                  ),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("br"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "font-weight-bold mt-5" }, [
        _vm._v("\n        Search Tips:\n    "),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "font-weight-bold mt-5" }, [
        _vm._v("You Might Try:"),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.mostPopularSearchTerms, function (item, index) {
          return _c("li", [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.doSearch(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("p", { staticClass: "font-weight-bold mt-5" }, [
        _vm._v("Still can’t find what you are looking for?"),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.initLiveChat()
                },
              },
            },
            [_vm._v("Chat with us")]
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.showFeedbackModal()
                },
              },
            },
            [_vm._v("Submit Feedback")]
          ),
        ]),
        _vm._v(" "),
        _vm._m(2),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("Try Searching by Search Type")]),
      _vm._v(" "),
      _c("li", [_vm._v("Check the spelling of your search term")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Broaden your search by using fewer or more general terms."),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Expand your search filters to ensure your search term is being applied across the appropriate\n            contents.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "mailto:customersuccess@iccsafe.org" } }, [
        _vm._v("Email us"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v("\n            Call "),
      _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
        _vm._v("888-ICC-SAFE"),
      ]),
      _vm._v(" ("),
      _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
        _vm._v("888-422-7233"),
      ]),
      _vm._v(").\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }