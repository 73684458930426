const eventsToListen = [
    "scroll",
    "click",
    "mousedown",
    "mouseup",
    "mousemove",
    "keydown",
    "keypress",
    "keyup",
];

export const onUserActivity = (callback, throttleMs = 100) => {

    if (typeof callback !== "function") {
        return;
    }

    let timeout;

    const eventListener = () => {
        clearTimeout(timeout);

        // do not call callback too often
        timeout = setTimeout(() => {
            callback();
        }, throttleMs);
    }

    eventsToListen.forEach((eventName) => {
        document.addEventListener(eventName, eventListener);
    });
}
