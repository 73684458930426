export default {
    data() {
        return {
            pagination: {
                descending: true,
                page: 1,
                itemsPerPage: 10,
                totalItems: 0,
                rowsPerPageItems: [10, 20, 30, 50, 50, 100, 250]
            },
            subscriptions: [],
            loading: true,
            int: false,
            search: '',
            my_ecodes_url: 'ecodes/ecode/',
            expanded: [],
            singleExpand: false,
        }
    },
    watch: {
        'pagination.page': function () {
            this.getUserAllSubscriptions();
        },
        'pagination.itemsPerPage': function () {
            this.getUserAllSubscriptions();
        }
    },
    methods: {
        trimDate(date) {
            let dateWithoutHours = date.split('T');
            if (dateWithoutHours.length > 0) {
                return dateWithoutHours[0]
            }
            return date;
        },
        getUserAllSubscriptions() {
            this.loading = true;
            if (this.int) {
                this.$scrollTo(document.getElementById('license-configuration'), {duration: 0, offset: 66,});
                this.$nextTick(() => {
                    this.subscriptions = [];
                });
            } else {
                this.subscriptions = [];
            }
            let paginationData = _.pick(this.pagination, ['descending', 'page', 'sortBy', 'itemsPerPage']);
            paginationData.rowsPerPage = paginationData.itemsPerPage;
            delete paginationData.itemsPerPage;
            this.$http.post(Routing.generate('get_subscription_paginated', {
                userId: this.userId,
                pagination: paginationData
            })).then(response => {
                this.subscriptions = response.data.subscriptions;
                this.pagination.totalItems = response.data.resultTotal;
                this.loading = false;
                this.int = true;
            });
        },
        getSubscriptionActionLabel(type) {
            return 'M' === type ? 'Manage':'Renew';
        },
        gotToMyeCodes() {
            this.goToTheShop(this.my_ecodes_url);
        },

        licenseConfigurationTotal(licenses, type){
            let result =0;
            for(let i =0; i<= licenses.length; i++){
                if(licenses[i] !== undefined && licenses[i].status !== 'Expired'){
                    result += licenses[i].license_configuration[type];
                }
            }

            return result;
        },
        changeTabTo(tab){
            EventBus.fire('change-license-tab-to', tab);
        }
    }
}
