var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isActiveUser || _vm.canStartTrial
    ? _c(
        "v-btn",
        {
          staticClass: "font-weight-bold",
          attrs: {
            light: "",
            large: _vm.large,
            color: _vm.color,
            outlined: _vm.outlined,
            elevation: "0",
            "max-height": _vm.maxHeight,
            block: _vm.block,
          },
          on: {
            click: function ($event) {
              return _vm.handleDirectStartTrial()
            },
          },
        },
        [_vm._v("\n    Start Free Trial\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }