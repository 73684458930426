var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600" },
      model: {
        value: _vm.data.show,
        callback: function ($$v) {
          _vm.$set(_vm.data, "show", $$v)
        },
        expression: "data.show",
      },
    },
    [
      _vm.data.astmError
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "accent2 white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 white--text",
                      attrs: { medium: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.data.astmError
                ? _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("img", {
                              staticClass: "astm-image mt-n9",
                              attrs: {
                                src: _vm.asset(
                                  require("../../images/astm-logo.png"),
                                  "default"
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("h3", { staticClass: "mb-0" }, [
                              _vm._v("Error: " + _vm._s(_vm.data.errorMessage)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "accent2 white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 white--text",
                      attrs: { medium: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("img", {
                          staticClass: "astm-image mt-n9",
                          attrs: {
                            src: _vm.asset(
                              require("../../images/astm-logo.png"),
                              "default"
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("h3", { staticClass: "mb-0" }, [
                          _vm._v(
                            "We're taking you to your content now. You will be redirected to the ASTM International website."
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "By clicking OK below, you may receive email information from ASTM International."
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary white--text",
                              attrs: { text: "" },
                              on: { click: _vm.astmAccessHits },
                            },
                            [
                              _vm._v(
                                "\n                        OK\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-left", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey--text font-weight-bold",
                              attrs: { text: "" },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _vm._v(
                                "\n                        Cancel\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey--text font-weight-bold",
                              attrs: { text: "" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: { color: "accent2" },
                                model: {
                                  value: _vm.checkbox,
                                  callback: function ($$v) {
                                    _vm.checkbox = $$v
                                  },
                                  expression: "checkbox",
                                },
                              }),
                              _vm._v(
                                "Do not show this again\n                    "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }