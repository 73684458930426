var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { sm: "auto" } },
        [
          _c("graphic", {
            attrs: {
              title: _vm.row_data.subscription.document_info,
              thumbnail: "",
              width: "50px",
              additionalClasses: "mt-2 mr-2",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c("book-title", {
            attrs: {
              title: _vm.getBookTitle(_vm.row_data.subscription.document_info),
              print_version_edition: _vm.printEdition(
                _vm.row_data.subscription.document_info.print_version_edition
                  .version
              ),
              printing_text: _vm.row_data.subscription.document_info.print_info,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }