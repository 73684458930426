import {ExpirableStorage} from "../../../classes/ExpirableStorage";

export default {
    data () {
        return {
            accessCode: '',
            concurrentStatus: {
                type: '',
                message: '',
            },
            previousCodes: [],
            isPreviousCodesBusy: false,
            loading: {
                concurrentSubmit: false,
                previousCodes: true
            }
        }
    },
    computed: {
        hasError() {
            return !_.isEmpty(this.concurrentStatus.type) && !_.isEmpty(this.concurrentStatus.message);
        }
    },
    methods: {
        concurrentAccessValidation() {
            EventBus.fire('full-page-loader', true);
            if(!this.accessCode) {
                this.concurrentStatus = {
                    type: 'error',
                    message: 'Please enter an access code.'
                }
                EventBus.fire('concurrent-connection-callback')
                EventBus.fire('full-page-loader', false);
            } else {
                this.resetConcurrentStatus();
                return this.setUserProfile({
                    type: 'concurrent',
                    metadata: this.accessCode
                })
            }
        },
        resetConcurrentStatus() {
            this.concurrentStatus = {
                type: '',
                message: ''
            }
        },
        getPreviousUsedCodes() {
            this.isPreviousCodesBusy = true;
            this.$http.get(Routing.generate('get_recent_used_concurrent_codes'))
                .then(response => {
                    if (_.size(response.data) === 0) {
                        this.noCodesMessage = "You do not have any previously used concurrent codes.";
                        this.previousCodes = [];
                    } else {
                        this.previousCodes = response.data;
                        this.$set(this.loading, 'previousCodes', false);
                    }

                    this.isPreviousCodesBusy = false;
                }, error => {
                    this.isPreviousCodesBusy = false;
                });
        },
        removePreviousUsedCode(code) {

            if (!code || !("id" in code)) {
                return;
            }

            const apiUrl = Routing.generate('remove_recent_used_concurrent_code');

            this.isPreviousCodesBusy = true;

            this.$http.post(apiUrl, {
                codeId: code.id
            }).then((response) => {

                const data = response.data;

                if (data.success) {
                    const active = _.find(this.profiles, { is_active: true });

                    this.$store.commit('profile/RemoveProfile', code.accessCode)

                    if (active.metadata === code.accessCode) {
                        this.$session.remove('active-concurrent');
                        this.$session.set('notify_concurrent_logout', '1');
                        return this.setUserProfile({type: 'personal'})
                    }

                    // grab fresh list of codes just in case
                    this.getPreviousUsedCodes();
                } else {
                    // in case it fails, do not show busy loading icon indefinitely...
                    this.isPreviousCodesBusy = false;
                }
            });
        }
    }
}
