<template>
    <ul id="context-menu" v-click-outside-directive="hideContextMenu">
        <li class="context-menu-item"  @click="menuAction('open')">Open Link</li>
        <li class="context-menu-item"  @click="menuAction('new-tab')">Open Link In New Tab</li>
        <li class="context-menu-item"  @click="menuAction('copy')">Copy Link</li>
    </ul>
</template>

<style>
    #context-menu {
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        display: none;
        list-style: none;
        position: absolute;
        z-index: 2147483647;
        background-color: white;
        border: 1px solid #ebebeb;
        border-bottom-width: 0px;
    }

    #context-menu.active {
        display: block;
    }
    .context-menu-item {
        display: flex;
        cursor: pointer;
        padding: 8px 15px;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
    }

    .context-menu-item:hover {
        background-color: #ebebeb;
    }
</style>

<script>
export default {
    data(){
        return {
            clickedChapter: []
        }
    },
    methods:{
        menuAction(action) {
            if(this.clickedChapter){
                if(this.clickedChapter.isHtml){
                    let url = Routing.generate('content_show_chapter_content', { 'chapterId': this.clickedChapter.id }),
                        object = {
                            'action' : action,
                            'dataUrl' : url,

                        };
                    this.contextMenuAction(object);
                }else{
                    _.assign(this.clickedChapter, {'action': action});
                    EventBus.fire('show-book-content', this.clickedChapter);
                }
            }
        },

        //Check option selected from context menu on smart index
        contextMenuAction(data){
            switch (data.action) {
                case 'open':
                    window.location = data.dataUrl;
                    break;
                case 'new-tab':
                    window.open(data.dataUrl, '_blank');
                    break;
                case 'copy':
                    this.copyStringToClipboard(window.location.hostname+data.dataUrl);
                    break;
            }
            this.hideContextMenu();
        },

        //method for copy string into clipboard
        copyStringToClipboard(str) {
            // Create new element
            var el = document.createElement('textarea');
            // Set value (string to be copied)
            el.value = str;
            // Set non-editable to avoid focus and move outside of view
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
            // Select text inside element
            el.select();
            // Copy text to clipboard
            document.execCommand('copy');
            // Remove temporary element
            document.body.removeChild(el);
        },

        hideContextMenu: () => {
            document.getElementById("context-menu").classList.remove('active');
        }
    },
    created(){
        EventBus.listen('context-menu-action', (data) => this.contextMenuAction(data));
        EventBus.listen('clicked-item-chapter', (data) => this.clickedChapter = data);
    },
}

</script>
