var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c("search-header", {
            attrs: {
              "total-results": _vm.pagination.totalResults,
              "search-term": _vm.search,
              filters: _vm.filters,
            },
            on: { showModal: () => (_vm.dialog = true) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("title-list", {
                attrs: {
                  titles: _vm.titles,
                  loading: _vm.isLoading,
                  itemsPerPage: _vm.itemsPerPage,
                  displayBorder: "",
                  displayUserTypeLabel: "",
                  displayInCols: _vm.isMobilePort ? 12 : 6,
                  displayTitleSearchNoResultMsg: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("h3", [_vm._v("Title Filters")]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [
                  _c("title-search-filters", {
                    attrs: { filters: _vm.filters },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Close\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page < _vm.pagination.maxPerPage
        ? _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        text: "",
                        color: "primary",
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.showMore },
                    },
                    [
                      _vm._v("\n                Show More\n                "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          "\n                    mdi-chevron-down\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }