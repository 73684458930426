import axios from 'axios'

export const ContentSearchApi = {
    http: axios.create(),
    cancelTokenSource: null,

    getResults(data) {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }

        this.cancelTokenSource = axios.CancelToken.source();
        return this.http.post(Routing.generate('advanced_content_search_results'), data, {
            cancelToken: this.cancelTokenSource.token
        }).then((response) => {
            this.cancelTokenSource = null;
            return response;
        })
    }
};
