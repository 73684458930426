import { CartApi } from '../../../../api/CartApi';
import LogoMenu from '../LogoMenu';

export default {
    data () {
        return {
            cartItemCount: 0,
            username: '',
            fullName: '',
            email: '',
            showAstmModal: ''
        }
    },
    computed: {
        getItemsCount(){
            if(this.$session.has('cart_items_count')){
                this.cartItemCount = this.$session.get('cart_items_count');
            }
            return this.cartItemCount;
        }
    },
    props: {
        concurrent: {
            required: false,
            type: String
        }
    },
    components: {
        LogoMenu
    },
    methods: {
        showModal() {
            EventBus.fire('show-modal-concurrent-access');
        },

        isConcurrent() {
            if (this.concurrent) {
                return 'lightgreen';
            }
        },
        goToLogInPage(){
            window.location = this.getLoginUrl();
        },
        userLoaded() {

            /** @type {User} */
            const user = this.$store.getters.getUser;

            if (user) {
                this.getCartItemsCount();
                if (this.$session.has('cartItem')) {
                    //update cart page as well if synching cart from the session .
                    EventBus.fire('show-cart-loader');
                    this.addItemToCartFromSession().then((response)=>{
                        EventBus.fire('get-products');
                    })
                }
                this.username = user.first_name;
                this.username = user.first_name;
                this.fullName = user.first_name +' ' + user.last_name;
                this.email = user.email;
                this.showAstmModal = user.show_astm_modal;
            }
        },
        showEasyAdminMenu(){

            /** @type{Array} */
            const user = this.$store.getters.getUser;

            if (user) {
                const { has_admin_access, roles } = user;
                return Array.isArray(roles) && roles.includes('ROLE_SUPER_ADMIN');
            }

            return false;
        },
        showConcurrentModal() {
            EventBus.fire('show-modal-concurrent-access');
        },
        getCartItemsCount() {
            if(!this.$session.has('cart_items_count')) {
                CartApi.cartItemsCount()
                    .then(response => {
                        this.$session.set('cart_items_count', response.data.total);
                        this.cartItemCount = response.data.total;
                    });
            }
        },
        setCartNumberCount(data){
            let items = this.$session.get('cart_items_count');
            switch (data.type) {
                case 'increment':
                    this.$session.set('cart_items_count', ( items >= 0 && !null) ? items + data.qty : items);
                    this.cartItemCount = items + data.qty;
                    break;
                case 'decrement':
                    this.$session.set('cart_items_count', (items > 1 && !null)  ? items - data.qty : items);
                    this.cartItemCount = items - data.qty;
                    break;
                case 'exact':
                    this.$session.set('cart_items_count', data.qty);
                    this.cartItemCount = data.qty;
                    break;
            }
        },
        async addItemToCartFromSession(){
            if(this.$session.has('cartItem')){
                let data =  this.$session.get('cartItem');
                if (Array.isArray(data)) {
                    return CartApi.addMultipleItemsToCart(data)
                        .then(response => {
                            EventBus.fire('set-cart-items-count', {
                                type: 'increment',
                                qty: data[0].quantity
                            });
                            this.$session.remove('cartItem');
                        });
                } else {
                    return CartApi.addToCart(data)
                        .then(response => {
                            EventBus.fire('set-cart-items-count', {
                                type: 'increment',
                                qty: data.quantity
                            });
                            this.$session.remove('cartItem');
                        });
                }
            }
        },
    },
    created() {

        this.$store.watch(() => this.$store.getters.getUser, (user) => {

            if (user) {
                this.userLoaded();
            }

        }, {
            immediate: true
        });

        EventBus.listen('set-cart-items-count', (data) => this.setCartNumberCount(data));
        EventBus.listen('set-search-expand', (data) => this.expand = data);
    }
}
