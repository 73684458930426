<template>
    <actionable-alert
            :visible="isVisible"
            action-text="Migrate Notes Now"
            @onAction="migrateNotes"
            @onDismiss="dismissSnackbar"
    >

        <template v-slot:text>
            <v-icon color="blue darken-4" class="mr-1">mdi-information-outline</v-icon>
            You have notes created on a prior version of this title. Would you like to migrate your notes to the current version?
        </template>

    </actionable-alert>
</template>

<script>

import ActionableAlert from "../ActionableAlert.vue";
import {ApiService} from "../../api/ApiService";

const HIDE_NOTIFICATION_SESSION_KEY = 'hideMigrateNotificationForDocuments';

export default {
    components: {ActionableAlert},
    data() {
        return {
            isVisible: false,
            priorVersionDocumentId: null,
        }
    },
    computed: {
        currentDocumentId() {
            return this.$store.getters.getDocumentInfo?.document_id;
        },
        isIgnoring() {
            const documentIgnoreList = this.getSessionValueAsArray(HIDE_NOTIFICATION_SESSION_KEY);
            return documentIgnoreList.includes(this.currentDocumentId);
        }
    },
    methods: {
        migrateNotes() {
            window.location = Routing.generate('migrate_notes', {'documentId': this.priorVersionDocumentId});
        },
        dismissSnackbar() {
            this.isVisible = false;
            //remember it forever if logged in
            this.$http.post(Routing.generate('book_notes_migrate_ignore_list', {
                bookId: this.documentId
            })).then(() => {
                // do nothing
            });
        },
        checkAvailableMigrations(priorVersionDocumentId, latestDocumentId) {
            ApiService.needToMigrateNotes(priorVersionDocumentId, latestDocumentId).then(result => {
                if (result.is_notes_exist) {
                    this.isVisible = true;
                }
            });
        }
    },
    mounted() {

        this.$watch(() => this.$store.getters.getAssociatedBooks, (books) => {

            // first element => LATEST version
            // last element => oldest version
            if (this.$store.getters.getLoggedIn && Array.isArray(books) && books.length > 1) {

                // where is current title in relation to all associated titles in the list
                const indexOfCurrentBook = books.findIndex((book) => {
                    return book.document_id === this.currentDocumentId;
                });

                // have any title before this?
                if (indexOfCurrentBook > -1 && indexOfCurrentBook < (books.length - 1)) {
                    
                    const priorVersionDocumentId = books[indexOfCurrentBook + 1].document_id;
                    this.priorVersionDocumentId = priorVersionDocumentId;

                    const latestDocumentId = this.currentDocumentId;

                    this.checkAvailableMigrations(priorVersionDocumentId, latestDocumentId);
                }
            }

        }, {immediate: true});
    },

}
</script>
