<template>
    <v-row align="center">
        <v-col sm="auto">
            <graphic
                :title="row_data.subscription.document_info"
                thumbnail
                width="50px"
                additionalClasses="mt-2 mr-2"
                >
            </graphic>
        </v-col>
        <v-col>
            <book-title
                :title="getBookTitle(row_data.subscription.document_info)"
                :print_version_edition="printEdition(row_data.subscription.document_info.print_version_edition.version)"
                :printing_text="row_data.subscription.document_info.print_info">
            </book-title>
        </v-col>
    </v-row>
</template>
<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [Licenses],
        components: {
            Graphic
        },
        props: ['row_data']
    }
</script>
