import {SuperAxios} from "../../../api/SuperAxios";
import {ErrorHandler} from "../../../classes/ErrorHandler";

export default {
    data() {
        return {
            mostPopularSearchTerms: []
        }
    },
    created() {

        const url = Routing.generate('statistics_trending_searches', {
            limit: 5
        });

        SuperAxios.getCached(url).then((res) => {
            this.mostPopularSearchTerms = res.data;
        }).catch(ErrorHandler.handle);
    }
}
