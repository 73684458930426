var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      {
        staticClass: "license-book-title ma-0",
        class: { cp: _vm.cursorPointer, "body-2": _vm.isMobilePort },
      },
      [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
    ),
    _vm._v(" "),
    "Premium Complete" !== _vm.title
      ? _c("p", { staticClass: "ma-0", class: { cp: _vm.cursorPointer } }, [
          _vm.print_version
            ? _c("small", [_vm._v(" " + _vm._s(_vm.print_version) + " ")])
            : _c("small", [
                _vm._v(
                  " (" +
                    _vm._s(_vm.print_version_edition) +
                    " Version: " +
                    _vm._s(_vm.printing_text) +
                    ") "
                ),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }