var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-sheet",
            {
              staticClass: "rounded-lg pa-4",
              staticStyle: { border: "1px solid #eeeeee" },
            },
            [
              !_vm.documentInfo.is_astm
                ? _c("premium-status", {
                    attrs: {
                      documentInfo: _vm.documentInfo,
                      contentPremium: _vm.contentPremium,
                      contentProtection: _vm.contentProtection,
                      contentBasic: _vm.contentBasic,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "text-body-1 mb-0 py-2 font-weight-medium" },
                [_vm._v(_vm._s(_vm.displayMessage))]
              ),
              _vm._v(" "),
              !_vm.isEnterpriseOrConcurrentActive
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { depressed: "", color: "accent2" },
                        on: {
                          click: function ($event) {
                            return _vm.openSubscribeToPremiumDialog()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Subscribe to This Title\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("StartFreeTrial", {
                      staticClass: "ma-1",
                      attrs: {
                        large: false,
                        outlined: "",
                        color: "primary",
                        maxHeight: 35,
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }