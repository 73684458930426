var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("p", { staticClass: "primary--text caption mb-0" }, [
              _vm._v(
                "\n                Assigned to Me licenses are those where another user has purchased a premium subscription and assigned dedicated usage access to me.  Usage of the premium licenses assigned to me are restricted to my individual account but are subject to the assignee revoking access if they choose to.\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-data-table",
        {
          staticClass: "elevation-0 no-margin",
          attrs: {
            headers: _vm.title_details,
            items: _vm.subscriptions,
            expand: _vm.expand,
            "items-per-page": _vm.pagination.rowsPerPage,
            loading: _vm.loading,
            "must-sort": "",
            "footer-props": {
              itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c(
                    "td",
                    { staticClass: "body-2 px-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { shrink: "", "align-self-center": "" } },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.bookLink(
                                        _vm.data.item.subscription.document_info
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("graphic", {
                                    attrs: {
                                      title:
                                        _vm.data.item.subscription
                                          .document_info,
                                      thumbnail: "",
                                      width: "40px",
                                      additionalClasses: "mr-2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.bookLink(
                                      _vm.data.item.subscription.document_info
                                    )
                                  },
                                },
                              },
                              [
                                _c("book-title", {
                                  attrs: {
                                    title: _vm.getBookTitle(
                                      _vm.data.item.subscription.document_info
                                    ),
                                    print_version_edition: _vm.printEdition(
                                      _vm.data.item.subscription.document_info
                                        .print_version_edition.version
                                    ),
                                    printing_text:
                                      _vm.data.item.subscription.document_info
                                        .print_info,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "expand",
              fn: function (props) {
                return [
                  _c(
                    "v-card",
                    {
                      staticClass: "green lighten-5 text-center",
                      attrs: { flat: "" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs12: "" } }, [
                            _c(
                              "p",
                              { staticClass: "caption font-weight-bold mb-0" },
                              [_vm._v("Assigned By")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(props.item.assigned_by.email) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "caption font-weight-bold mb-0" },
                              [_vm._v("Assigned Date")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("moment")(
                                    props.item.created_at,
                                    "MMMM Do, YYYY"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "caption font-weight-bold mb-0" },
                              [_vm._v("Expiration Date")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("moment")(
                                    props.item.subscription.expiration_date,
                                    "MMMM Do, YYYY"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "caption font-weight-bold mb-0" },
                              [_vm._v("Subscription Type")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.checkSubscriptionType(
                                    props.item.subscription.subscription_type
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "caption font-weight-bold mb-0" },
                              [_vm._v("Subscription Status")]
                            ),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.checkStatus(props.item)) +
                                "\n                        "
                            ),
                            _vm.checkStatus(props.item) === "Revoked"
                              ? _c("p", { staticClass: "caption mb-0" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          props.item.updated_at,
                                          "MMMM Do, YYYY"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "template",
            { slot: "no-data" },
            [
              !_vm.subscriptions_loaded
                ? _c(
                    "v-card",
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: true },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.subscriptions_loaded
                ? _c("p", { staticClass: "mt-4" }, [
                    _vm._v("You do not have any Premium subscriptions."),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }