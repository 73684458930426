var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "primary--text font-weight-regular mb-5 oxygen",
                      },
                      [_vm._t("header")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      { staticClass: "primary--text font-weight-regular" },
                      [_vm._t("subHeading")],
                      2
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", [_c("v-col", [_vm._t("description")], 2)], 1),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasImageSlot
            ? _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "5" } },
                [_vm._t("image")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }