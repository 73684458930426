<template>
    <v-container
        :fluid="!isExtraLarge"
        v-if="categories.length">
        <v-row align="center">
            <v-col>
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <h3 class="text-h6 font-weight-bold">
                            Categories
                        </h3>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                cols="auto"
                v-for="(category, key) in categories"
                :key="key">
                <v-chip
                    outlined
                    :href="getLink(category.slug)">
                    {{ category.label }}
                </v-chip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile'

    export default {
        props: {
            categories: {
                required: true,
                type: Array
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            getLink(categorySlug) {
                return this.getRoute('codes_category', { 'category': categorySlug });
            }
        }
    }
</script>
