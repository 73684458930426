var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("alert", {
        attrs: {
          id: "display_http_messages",
          message: _vm.notificationMessage,
          notificationType: _vm.notificationType,
          dismissible: "",
        },
        on: { dismiss: _vm.dismissAlert },
      }),
      _vm._v(" "),
      _vm.askQuestionFormVisible
        ? _c("new-question-form", {
            attrs: { creditBalance: _vm.userCreditsInfo?.balance },
          })
        : _vm.showQuestion
        ? _c("question-view", { attrs: { id: _vm.showQuestion } })
        : [
            _c("your-questions-intro-block", {
              staticClass: "mb-5",
              attrs: { userCreditsInfo: _vm.userCreditsInfo },
              on: { changeCreditBalance: _vm.updateCreditBalance },
            }),
            _vm._v(" "),
            _c("your-questions-list"),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }