export default {
    props: {
        skus: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            related_product: []
        };
    },
    computed: {
        hasProduct() {
            return _.size(this.related_product) > 0;
        }
    },
    methods: {
        getRelatedProduct() {
            if (_.size(this.skus) > 0) {
                this.$http.get(Routing.generate('get_cart_related_product', {'skus': this.skus}))
                        .then(response => {
                            this.related_product = response.data.related_product;
                            if (_.size(this.related_product) > 0) {
                                this.related_product.recommanded = true;
                            }
                        });
            }
        },
    },
    created() {
        this.getRelatedProduct();
        EventBus.listen('related-products', (data) => this.getRelatedProduct());
    }
}
