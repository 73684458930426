var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sectionMeta
    ? _c(
        "div",
        { staticClass: "pa-2 rounded-lg" },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.loadingReferenceContent
                ? _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("section-content", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loadingReferenceContent && !_vm.referenceError,
                expression: "!loadingReferenceContent && !referenceError",
              },
            ],
            attrs: {
              type: "ai_navigator",
              "selected-section-data": _vm.sectionData,
            },
          }),
          _vm._v(" "),
          _vm.referenceError && !_vm.loadingReferenceContent
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(
                  "Something went wrong or data not\n        found.\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }