import axios from 'axios'

export const EnterpriseApi = {
    http: axios.create(),
    acceptInvitation(data) {
        return this.http.patch(Routing.generate('update_enterprise_invitation'), {
            invitation_id: data.invitation_id,
            response_status: data.response_status
        });
    }
}
