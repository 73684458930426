<template>
    <div>
        <v-card
            id="premiumAnswersFilter"
            outlined>
            <v-card-text>
                <h2 class="mb-5 text-h5 font-weight-medium">Quick Consult</h2>
                <v-card
                    class="mb-4 rounded-lg"
                    outlined
                    :style="`border: 2px solid ${$vuetify.theme.themes.light.primaryMedium}`">
                    <v-card-text class="px-6 py-2">
                        <v-row align="center">
                            <v-col>
                                <h6 class="text-h6 mb-4">
                                    Your Fast Track to Expert Code Opinions
                                </h6>
                                <p class="text-body-2 mb-2">
                                    Need answers to your code questions? Quick Consult offers expert opinions, personalized responses, and timely results.
                                </p>
                                <template v-for="(item, key) in marketingText">
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-icon
                                                color="primaryMedium">
                                                mdi-check-circle-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col>
                                            <p
                                                class="text-body-2 mb-2"
                                                :key="key"
                                                v-html="item">
                                            </p>
                                        </v-col>
                                    </v-row>
                                </template>
                                <p class="text-body-2">
                                    Don't let code uncertainty slow you down. Start your consultation today!
                                </p>
                                <sample-answer-button>
                                </sample-answer-button>
                            </v-col>
                            <v-col cols="auto">
                                <img
                                    alt="Quick Consult Marketing Image"
                                    width="231px"
                                    height="167px"
                                    :src="asset(require('../../../images/illistrations/qc_marketing_image.png'))"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <p>
                    Ask code questions on any of the International Codes (I-Codes and Standards). Our ICC experts will provide a professional opinion of how the codes apply to your specific circumstances within 2 business days.
                </p>
                <p>
                    This is a paid service and requires you to buy credits to ask questions regardless of your Premium subscription status.  For this beta launch we are offering introductory pricing.
                </p>
                <p v-if="isAuthenticated">
                    <b>{{ creditsAvailable }} credits
                        <!-- Leaving this here for now until next text is proposed. -->
                        <span v-if="hasExpiryDate"></span>
                        <span v-else> - buy credits to ask a question.</span>
                    </b>
                </p>
                <p v-else>
                    <b>
                        <a
                            class="text-decoration-underline"
                            :href="this.getRoute('security_login')">Sign in
                        </a> or
                        <a
                            class="text-decoration-underline"
                            :href="this.getRoute('signup')">
                            create an account
                        </a>
                        to buy credits to ask a question.
                    </b>
                </p>
                <alert
                    v-for="(item, key) in successfulReturnedCredits"
                    :key="key"
                    notificationType="info"
                    :message="'Question [' + item.key + '] has been declined by the ICC team and 1 credit has been refunded back to your account. Open the full question details to see why your question was declined. Thank you.'"
                />
                <div class="d-flex">
                    <v-btn
                        :disabled="!canAskQuestions"
                        color="primary"
                        :href="this.getRoute('ask_question')">
                        Ask New Question
                    </v-btn>
                    <div class="mx-1"></div>
                    <v-btn
                        :disabled="!isAuthenticated"
                        outlined
                        color="primary"
                        @click="openModal()">
                        Purchase Credits
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
        <confirm-dialog
            v-if="dialog"
            :is-open="dialog"
            borderStyle
            showHeaderBorder
            noGrayActionArea
            :fullscreen="isMobilePort"
            body-class="pa-4 pt-2"
            @closed="closeModal()">
            <template v-slot:title>
                Buy Quick Consult Credits
            </template>
            <template v-slot:default>
                <template v-if="step === 1">
                    <v-row dense>
                        <v-col>
                            <h4 class="fs-16">
                                Number of Credits
                            </h4>
                        </v-col>
                    </v-row>
                    <template
                        v-if="!loading.creditOptions"
                        v-for="(item, key) in creditOptions">
                        <v-radio-group
                            v-model="creditSelect"
                            class="mt-0"
                            hide-details>
                            <v-card
                                @click="creditSelect = item.qty"
                                outlined
                                :key="key"
                                class="mb-2 pa-2"
                                :class="{'primaryLight': item.qty === creditSelect}"
                                :style="item.qty === creditSelect ? `border: 2px solid ${$vuetify.theme.themes.light.primary}!important;` : ''">
                                <v-card-text class="py-1 px-2">
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-radio
                                                :value="item.qty">
                                            </v-radio>
                                        </v-col>
                                        <v-col>
                                            <h4 class="fs-16">
                                                {{ getCreditTitle(item.qty) }}
                                            </h4>
                                        </v-col>
                                        <v-col
                                            class="text-right"
                                            cols="auto">
                                            <template>
                                                <template v-if="item.total">
                                                    <h4 class="fs-16">
                                                        ${{ item.total | formatPrice }} <span v-if="item.savingsPercentage">(Save {{item.savingsPercentage.toFixed(0)}}%)</span>
                                                    </h4>
                                                </template>
                                                <h4
                                                    v-else
                                                    class="fs-16">
                                                    Price Unavailable
                                                </h4>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-radio-group>
                    </template>
                </template>
                <template v-else>
                    <v-row dense>
                        <v-col>
                            <h3>
                                Number of Credits
                            </h3>
                            <p class="mb-2">
                                {{ getCreditTitle(creditSelect) }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <h3>
                                Credit Expiration Date
                            </h3>
                            <p class="mb-2">
                                {{ DateUtil.timeToMdy(getCreditsExpiry) }}<br/>
                                <span v-if="hasExpiryDate">Existing credit expiration date will update to this date.</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <payment-method-display>
                            </payment-method-display>
                        </v-col>
                    </v-row>
                </template>
                <html-block
                    :class="{'mt-2': step === 1}"
                    slug="qc-terms-of-service">
                </html-block>
            </template>
            <template v-slot:buttons>
                <v-container class="py-0 px-2">
                    <alert id="display_http_messages"
                       :message="message"
                       :notificationType="type"
                       dismissible
                       @dismiss="dismissAlert">
                    </alert>
                    <v-row dense>
                        <v-col>
                            <h2>
                                Total Payment
                            </h2>
                        </v-col>
                        <v-col cols="auto">
                            <h2 v-if="!loading.creditOptions">
                                ${{ creditOptions.find(option => option.qty === creditSelect).total | formatPrice }}
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="invalidVisaCart"
                        justify="end"
                        dense>
                        <v-col cols="auto">
                            <v-btn
                                class="mr-1 font-weight-bold"
                                outlined
                                @click="closeModal()">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="accent2"
                                depressed
                                class="font-weight-bold"
                                @click="buyCredits(false)"
                                :loading="loading.paymentProfile || loading.proceedToCheckout"
                                :disabled="loading.paymentProfile">
                                Proceed to Checkout
                            </v-btn>
                        </v-col>
                    </v-row>
                    <action-buttons
                        v-else
                        :step="step"
                        :submitText="getSubmitBtnText()"
                        :isDisableReviewBtn="false"
                        :loading-submit="loading.checkout"
                        @go-back="backModal()"
                        @close="closeModal()"
                        @review-changes="reviewModal()"
                        @submit-and-pay="buyCredits(true)">
                    </action-buttons>
                </v-container>
            </template>
        </confirm-dialog>
        <checkout-modal>
        </checkout-modal>
    </div>
</template>

<script>
import {DateUtil} from "../../../util/DateUtil";
import ConfirmDialog from "../../ConfirmDialog.vue";
import Mobile from "../../inc/mixins/mobile";
import HtmlBlock from "../../HtmlBlock.vue";
import ActionButtons from "../../billing/subscription-update/ActionButtons.vue";
import SubscriptionChangeMixin from "../../billing/subscription-update/subcriptionChange.mixin";
import {BillingApi} from "../../../api/BillingApi";
import PaymentMethodDisplay from "../../billing/PaymentMethodDisplay.vue";
import {mapActions, mapGetters} from "vuex";
import { QuickConsultUserCreditsApi } from "../../../api/QuickConsultUserCreditsApi";
import { QuickConsultApi } from "../../../api/QuickConsultApi";
import {CartApi} from "../../../api/CartApi";
import CheckoutModal from "../../cart/partials/checkout_modals.vue";
import {AppParameters} from "../../../AppParameters";
import Alert from "../../notifications/AlertBar.vue";
import SampleAnswerButton from "./SampleAnswerButton.vue";

export default {
    emits: ['changeCreditBalance'],
    data() {
        return {
            quickConsultSku: AppParameters.quick_consult_sku,
            paymentProfileInitialized: false,
            userCredits: null,
            creditOptionsInitialized: false,
            dialog: false,
            logState: {},
            step: 1,
            paymentProfile: null,
            creditSelect: null,
            successfulReturnedCredits: [],
            loading: {
                creditOptions: true,
                paymentProfile: true,
                checkout: false,
                proceedToCheckout: false
            },
            creditOptions: [],
            marketingText: [
                '<strong>Expert Guidance:</strong> Our team has a deep understanding of the ICC codes and standards.',
                '<strong>Personalized Answers:</strong> Each question receives individual attention.',
                '<strong>Fast Responses:</strong> Questions are answered in 2 business days or less.'
            ],
            message: '',
            type: ''
        }
    },
    components: {SampleAnswerButton, Alert, PaymentMethodDisplay, ActionButtons, HtmlBlock, ConfirmDialog, CheckoutModal},
    props: {
        userCreditsInfo: {
            type: Object,
            required: false
        }
    },
    computed: {
        DateUtil() {
            return DateUtil;
        },
        creditsAvailable() {
            return this.userCreditsInfo ? this.userCreditsInfo?.balance : 0;
        },
        canAskQuestions() {
            return this.creditsAvailable > 0;
        },
        getCreditsExpiry() {
            const expiry = this.userCreditsInfo?.expiry;

            if (expiry) {
                return expiry;
            } else {
                // If expiry date is not available, then expected expiry date would be one year from today
                const today = new Date();
                today.setFullYear(today.getFullYear() + 1);
                return today.toISOString();
            }
        },
        hasExpiryDate() {
            return !!this.userCreditsInfo?.expiry;
        }
    },
    watch: {
        creditSelect() {
            this.logUserActions();
        }
    },
    mixins: [
        Mobile,
        SubscriptionChangeMixin
    ],
    methods: {
        setAlert(message, type = 'error') {
            this.message = message;
            this.type = type;
        },
        dismissAlert() {
            this.message = '';
            this.type = '';
        },
        ...mapActions('BillingModule', [
            'setPaymentProfile'
        ]),
        ...mapActions('NotificationModule', [
            'setNotificationMessage'
        ]),
        openModal() {
            this.logState = {
                userAction: 'Open',
                sessionId: crypto.randomUUID(),
                isCancelled: false,
                orderId: null,
                orderStatus: null,
            };
            this.logUserActions();
            if (!this.paymentProfile) {
                this.getPaymentMethodInfo();
            }
            if (!this.creditOptionsInitialized) {
                this.getCreditOptions();
            }
            this.dialog = true;
        },
        closeModal(isCompleted) {
            this.dialog = false;
            this.step = 1;
            if (!isCompleted) {
                this.logState.isCancelled = true;
                this.logUserActions();
            }
            this.dismissAlert()
        },
        backModal() {
            this.step = 1;
            this.logState.userAction = 'Open';
            this.logUserActions();
        },
        reviewModal() {
            this.step = 2;
            this.logState.userAction = 'Review';
            this.logUserActions();
        },
        getPaymentMethodInfo() {
            BillingApi.getPaymentProfile(parseInt(this.userid))
                .then((response)=> {
                    if (response.data.success) {
                        this.paymentProfile = response.data.payment_profile;
                        this.setPaymentProfile(this.paymentProfile);
                    } else {
                        this.setAlert('Failed to fetch the user payment profile')
                    }
                    this.loading.paymentProfile = false;
                }).catch((error) =>{
                this.setAlert(error)
            }).finally(() => {
                this.loading.paymentProfile = false;
            });
        },
        async buyCredits(proceedToCheckout) {
            this.logState.userAction = 'Submit Payment';
            this.logUserActions();
            let paymentDone = false;
            const data = {
                'quantity': this.creditSelect,
                'sku': this.quickConsultSku,
                'productType': 'credit',
                'proceedToCheckout': proceedToCheckout
            };

            this.loading.checkout = true;
            this.loading.proceedToCheckout = true;
            try {
                await CartApi.creditProductAddToCart(data).then((res) => {
                    if(res && res.data.quote_id && data.proceedToCheckout) {

                        const checkoutData = {
                            'billingAddress': this.paymentProfile.billing_address,
                            'customer_ref_num': this.paymentProfile.profile_id,
                            'cart_id': res.data.quote_id
                        };

                        CartApi.cartProceedToCheckout(checkoutData).then((resp) => {
                            if (resp && resp.data) {
                                EventBus.fire('update-credits')
                                paymentDone = true;
                                this.logState.orderId = resp.data.order_id;
                                this.logState.orderStatus = 'Payment Successful';
                                this.logUserActions();
                            }
                        }).catch((error) => {
                            this.setAlert(error);
                            this.logState.orderStatus = 'Payment Not Successful';
                            this.logUserActions();
                            // since there was an error, try to send the user to the shop
                            this.goToCheckout();
                        }).finally(() => {
                            this.loading.checkout = false;
                            this.closeModal(true);

                            if (paymentDone) {
                                const notifyObj = {
                                    success: true,
                                    message: 'Payment successful! ' + this.creditSelect + ' credits for Quick Consult added to your account.'
                                }
                                this.setNotificationMessage(notifyObj);
                            }
                        });
                    }
                });
                if (!proceedToCheckout) {
                    this.logState.userAction = 'Proceed to Checkout';
                    this.logUserActions();
                    this.closeModal(true);
                    this.goToCheckout();
                }
            } catch (error) {
                this.setAlert(error);
            }
        },
        goToCheckout() {
            EventBus.fire('go_to_checkout');
        },
        getCreditOptions() {
            QuickConsultApi.getCreditOptions().then((res) => {
                this.creditOptions = res;
                this.creditSelect = this.creditOptions[0].qty;
                this.creditOptionsInitialized = true;
                this.loading.creditOptions = false;

                // Determine the base price per credit (price of 1 credit)
                const basePriceOption = this.creditOptions.find(option => option.qty === 1);
                const basePrice = basePriceOption ? basePriceOption.total : null;

                // Calculate savings percentage for each credit option
                this.creditOptions.forEach(option => {
                    if (basePrice && option.qty && option.total) {
                        const totalBasePrice = option.qty * basePrice;
                        const savingsAmount = totalBasePrice - option.total;
                        option.savingsPercentage = ((savingsAmount / totalBasePrice) * 100);
                    } else {
                        option.savingsPercentage = 0;
                    }
                });
            }).catch((error) => {
                this.setAlert(error);
            })
        },
        getCreditTitle(qty) {
            let text = 'credits';
            if (qty === 1) {
                text = 'credit';
            }
            return qty + ' ' + text;
        },
        async processUnhandledAnswerDeclinedQuestions() {
            try {
                const result = await QuickConsultUserCreditsApi.processUnhandledAnswerDeclinedQuestions();
                if (result['return_credits_successful'] && result['return_credits_successful'].length > 0) {
                    this.successfulReturnedCredits = result['return_credits_successful'];
                    if (result['new_credit_balance'] !== null) {
                        this.$emit('changeCreditBalance', result['new_credit_balance']);
                    }
                }
            } catch (error) {
                this.setAlert(error);
            }
        },
        logUserActions() {
            try {
                const currentOption = this.creditOptions.find(option => option.qty === this.creditSelect);
                QuickConsultUserCreditsApi.logCreditPurchaseActions(
                    this.logState.sessionId,
                    currentOption.qty,
                    currentOption.total,
                    this.logState.userAction,
                    this.logState.isCancelled,
                    this.logState.orderId,
                    this.logState.orderStatus,
                );
            } catch (e) {
            }
        },
        getSubmitBtnText() {
            return 'Submit Payment';
        }
    },
    async created() {
        if (this.isAuthenticated) {
            this.processUnhandledAnswerDeclinedQuestions();
        }
    }
}
</script>