var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "sticky-chapter-info" } },
    [
      _vm.documentInfo
        ? _c(
            "v-row",
            { staticClass: "grey lighten-3 px-2", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.showFullScreen
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0 my-2",
                                  attrs: {
                                    slot: "activator",
                                    fab: "",
                                    small: "",
                                    color: _vm.getColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.pdfEvent("fullscreen")
                                    },
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c("v-icon", { staticClass: "white--text" }, [
                                    _vm._v("fullscreen"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                "x-small": "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleToc(true)
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "\n                            mdi-menu-close\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("h1", { staticClass: "primary--text caption" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getBookTitle(_vm.documentInfo)) +
                              "\n                        "
                          ),
                          "" === _vm.examId
                            ? _c(
                                "span",
                                [
                                  "1" == _vm.contentPremium
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "px-1",
                                          attrs: {
                                            dark: "",
                                            color: "accent2",
                                            "x-small": "",
                                            label: "",
                                          },
                                        },
                                        [
                                          "1" == _vm.contentProtection
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "white--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openAccessLevelPermissionModal(
                                                        "Premium Lite"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        PREMIUM LITE\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a",
                                                {
                                                  staticClass: "white--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openAccessLevelPermissionModal(
                                                        "Premium"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    PREMIUM\n                                "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      )
                                    : _c(
                                        "v-chip",
                                        {
                                          staticClass: "px-1",
                                          attrs: {
                                            dark: "",
                                            color: "primary",
                                            "x-small": "",
                                            label: "",
                                          },
                                        },
                                        [
                                          "1" == _vm.contentBasic &&
                                          "1" == _vm.contentProtection
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "white--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openAccessLevelPermissionModal(
                                                        "Basic"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        BASIC\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a",
                                                {
                                                  staticClass: "white--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openAccessLevelPermissionModal(
                                                        "Basic Plus"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    BASIC PLUS\n                                "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-0 caption text-left" }, [
                          _vm._v(_vm._s(_vm.getChapterTitle)),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "caption text-left mb-0" }, [
                          _vm._v(_vm._s(_vm.documentInfo.print_info)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "auto" } },
                        [
                          _vm.pdfurl != ""
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0 mr-1",
                                  attrs: { slot: "activator", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPdf()
                                    },
                                  },
                                  slot: "activator",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.asset(
                                        require("../../../images/icons/pdficon.png")
                                      ),
                                      width: "30",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          "" === _vm.examId
                            ? _c(
                                "span",
                                [
                                  _c("favorite", {
                                    attrs: {
                                      document_id: _vm.documentInfo.document_id,
                                      sku: _vm.documentInfo.sku,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }