<template>
    <v-row justify="center">
        <v-dialog
                v-model="dialogVisible"
                persistent
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Concurrent Access Timed Out!
                </v-card-title>
                <v-card-text>
                    You have been logged out of the concurrent access due inactivity on this browser since last
                    {{ timeoutMinutes }} minutes.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="primary" @click="dialogVisible = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {EasyStorage} from "../../classes/EasyStorage";
import {AppParameters} from "../../AppParameters";

export default {
    data() {
        return {
            dialogVisible: false,
            timeoutMinutes: AppParameters.concurrent_idle_timeout_minutes
        };
    },
    mounted() {

        // and don't show it again
        if (EasyStorage.removeItemWithStatus('showConcurrentTimeoutDialog')) {
            this.dialogVisible = true;
        }
    }
}
</script>
