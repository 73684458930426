<template>
    <div>
        <v-dialog
            v-model="protectedContent"
            width="650">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Read Only
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        class="accent2--text"
                        @click="modalClose()">
                        close
                    </v-icon>
                </v-card-title>
                <v-container class="lighten-3 px-3">
                    <v-row>
                        <v-col cols="12" align="center" class='pt-0 pb-3'>
                            <div>
                                <img :src="asset(require('../../images/icc_digital_codes_premium.png'))"/>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class='pt-0 pb-2'>
                            <p align="center">
                                The book content is read only.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                protectedContent: false
            };
        },
        mixins: [Mobile],
        methods: {
            notifyContentProtection() {
                this.protectedContent = true;
            },
            modalClose() {
                this.protectedContent = false;
            }
        },
        created() {
            EventBus.listen('protected-content-notificaton', () => { this.notifyContentProtection(); });
        }
    };
</script>
