<template>
    <v-alert
        border="left"
        v-if="message"
        colored-border
        elevation="0"
        :type="notificationType"
        :class="containerClass"
        :icon="icon"
        id=false
    >
        <v-row dense>
            <v-col
                class="py-0">
                <p class="mb-0 black--text alert-message">
                    <span v-html="message"></span>
                    <template v-if="notificationType === 'error'">
                        Please try again later or
                        <a
                            :href="$getConst('links')['contactSupport']"
                            class="text-decoration-underline"
                            target="_blank">
                            contact support
                        </a>
                        if the issue persists.

                    </template>
                </p>
                <div class="mb-0 addon-content" v-if="this.hasSlot()">
                    <slot />
                </div>
            </v-col>
            <v-col
                v-if="dismissible"
                cols="auto"
                class="py-0">
                <v-btn
                    icon
                    x-small
                    @click="$emit('dismiss')"
                    class="black--text"
                >
                    <v-icon class="font-weight-bold">mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    dissmissed: false,
    name: 'AlertBar',
    emits: ['dismiss'],
    props: {
        /**
         * Pass the notification type = ['success', 'error', 'warning', 'info']
         */
        notificationType: {
            type: String,
            required: false,
            value: 'success'
        },
        message: {
            type: String|Error,
            required: false,
            value: ''
        },
        dismissible: {
            type: Boolean,
            required: false,
            value: true
        }
    },
    computed: {
          icon() {
              switch(this.notificationType) {
                  case 'error': return 'mdi-alert-circle-outline'
                  case 'info': return 'mdi-information-outline'
                  case 'success': return 'mdi-check-circle-outline'
                  case 'warning': return 'mdi-alert-outline'
                  default:
                      throw new Error(`'Invalid alert type: ${this.notificationType}'`);
              }
          },

        containerClass() {
            return `icc-alert alert-${this.notificationType}`;
        }
    },
    methods: {
        hasSlot (name = 'default') {
            return !!this.$slots[name] || !!this.$scopedSlots[name];
        }
    }
}
</script>
<style lang="scss">
    @import '~vuetify/src/styles/settings/_colors.scss';

    @mixin icc-alert-color-override($palette, $accentColor, $backgroundColor)  {
        // alert's background color override
        background-color: map-get($palette, $backgroundColor);

        // Alert/s icon color override
        // @XXX: this stile should not target the close button!
        .v-alert__wrapper > .v-icon {
            // the base theme uses `!important`, so we have to use it too ;(
            color: map-get($palette, $accentColor) !important;
            caret-color: map-get($palette, $accentColor) !important;
        }

        // Alert's border color override
        .v-alert__border {
            // the base theme uses `!important`, so we have to use it too ;(
            border-color: map-get($palette, $accentColor) !important;;
        }

        // Alert's close button color override
        .v-btn__content > .v-icon {
            color: #000;
        }
    }

    .icc-alert {
        &.alert-success {
            @include icc-alert-color-override($green, 'darken-3', 'lighten-5');
        }

        &.alert-error {
            @include icc-alert-color-override($red, 'accent-4', 'lighten-5');
        }

        &.alert-warning {
            @include icc-alert-color-override($orange, 'darken-2', 'lighten-5');
        }

        &.alert-info {
            @include icc-alert-color-override($blue, 'darken-4', 'lighten-5');
        }

        .addon-content {
            text-align: left;
            padding-top: 16px;
        }
        .alert-message {
            white-space: normal;
            word-wrap: break-word;
            max-width: 100%;
        }

    }
</style>
