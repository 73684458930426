export default {
    methods : {
        setDocumentToSearch() {
            this.$session.set(
                'searchedDocument',
                this.$store.getters.getDocumentInfo
            );
        }
    }
}
