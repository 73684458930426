<template>
    <div>
        <div v-if="!activeCategory">
            <back-to-main-menu
                @backToMain="backToMain">
            </back-to-main-menu>
            <v-list-item class="mt-2">
                {{ browseContentsText }}
            </v-list-item>
            <link-list-icons
                hide-icon
                :links="links"
                @updateComponent="(data) => activeCategory = data">
            </link-list-icons>
        </div>
        <div v-else>
            <back-to-main-menu
                @backToMain="activeCategory = null">
                {{ browseContentsText }}
            </back-to-main-menu>
            <v-list-item
                v-if="!activeItem.hideBrowseAll"
                :href="`/codes/${activeItem.slug}`">
                <v-list-item-title
                    class="fs-16 font-weight-bold">
                    Browse All {{activeItem.title}}
                </v-list-item-title>
            </v-list-item>
            <v-treeview
                ref="treeview"
                activatable
                :items="activeItem.children"
                item-key="slug">
                <div
                    v-if="item.name"
                    slot="label"
                    slot-scope="{ item }"
                    @click.stop="redirectToCodes(item);">
                    <a class="accent--text" >
                        {{ item.name }}
                    </a>
                </div>
            </v-treeview>
        </div>
    </div>
</template>

<script>
    import BackToMainMenu from "./BackToMainMenu.vue";
    import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
    import LinkListIcons from "./LinkListIcons.vue";
    import Categories from "../inc/mixins/categories";

    export default {
        name: 'BrowseContentsPanel',
        components: {LinkListIcons, BackToMainMenu},
        mixins: [megaMenuMixin, Categories],
        data() {
            return {
                links: [],
                activeCategory: null,
                browseContentsText: 'Codes'
            }
        },
        computed: {
            activeItem() {
                if (this.activeCategory) {
                    const parent = this.links.find((obj) => {
                        return obj.slug === this.activeCategory
                    })
                    return parent
                }
            }
        },
        methods: {
            defineLinks() {
                const categories = this.$store.getters.getSearchNavigationCategories.Category;
                let links = categories.map(item => {
                    return {
                        title: item.name,
                        slug: item.slug,
                        link: item.children.length ? null : `/codes/${item.slug}`,
                        component: item.children.length ? item.slug : null,
                        children: item.children,
                        active: true
                    };
                })

                links.push({
                    title: 'Locations',
                    slug: 'locations',
                    component: 'locations',
                    children: this.$store.getters.getSearchNavigationCategories.Location,
                    active: true,
                    hideBrowseAll: true
                })

                this.links = this.sortedCategories(links, 'title')
            },
            redirectToCodes(item, openUrl = false){
                this.titleSearchCategory = openUrl ? this.titleUrl : item.slug;
                if (this.$store.getters.getIsCodesPage && !openUrl) {
                    EventBus.fire('update-search-data', {
                        query: {
                            searchTerm: '',
                            category: item.name
                        },
                        resetPage: true
                    });
                    this.defaultSearch = '';
                    EventBus.fire('show-nav-drawer', false);
                } else {
                    const codesUrlWithCategoryLocation = openUrl ? `/codes/${this.titleUrl}` : `/codes/${item.slug}`
                    window.location = (this.title === 'Locations' && openUrl) ? `/codes` : codesUrlWithCategoryLocation
                }
            },
        },
        created() {
            this.defineLinks()
        }
    }
</script>
