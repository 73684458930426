var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mx-2", attrs: { dense: "" } },
    [
      _c("v-col", [
        _c("h1", { staticClass: "pl-2" }, [
          _vm._v(_vm._s(_vm.getEnterpriseName)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }