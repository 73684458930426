<template>
    <div>
        <v-container class="grey lighten-3 px-6">
            <v-row align="center">
                <v-col>
                    <h3 class="font-weight-regular">
                        <a href="http://www.solar-rating.org/index_srcc.html" target="_blank">
                            Visit SRCC Website
                        </a>
                    </h3>
                </v-col>
                <v-col cols="auto">
                    <p class="caption mb-0 font-weight-bold">
                        ( <span class="error--text">*</span> sign indicates the report as featured. )
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="items-iterator">
            <template v-for="(reportTag, key) in data">
                <v-container
                    v-if="reportTag.tag.is_active"
                    class="px-6 item-row">
                    <v-row>
                        <v-col>
                            <p>
                                <a target="_blank" :href="reportTag.tag.url">
                                    {{reportTag.tag.file_report_no}}
                                </a>
                                <span v-if="reportTag.tag.is_featured" class="red--text">*</span>
                            </p>
                        </v-col>
                        <v-col>
                            <p>
                                <a target="_blank" :href="reportTag.tag.url">
                                    {{ reportTag.tag.company }}
                                </a>
                            </p>
                        </v-col>
                        <v-col>
                            <p>{{ reportTag.tag.product }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array
        }
    }
}
</script>
