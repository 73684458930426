export default {
    computed: {
        hasFavorites() {
            return _.size(this.favorites) > 0;
        },
        favorites: {
            get() {
                return this.$store.getters.getFavorites;
            },
            set(value) {
                this.$store.commit('setFavorites', value);
            }
        },
        favoritesPromise: {
            get() {
                return this.$store.getters.getFavoritesPromise;
            },
            set(value) {
                this.$store.commit('setFavoritesPromise', value);
            }
        },
        favoritesLoading: {
            get() {
                return this.$store.getters.getFavoritesLoading;
            },
            set(value) {
                this.$store.commit('setFavoritesLoading', value);
            }
        }
    },
    methods: {
        supplyFavoritesAndSort(response) {
            let favorites = response.data;
            let hasSortOrder = false;
            favorites.forEach(function(book){
                if (book.sort_order) {
                    hasSortOrder = true;
                }
            });

            if (!hasSortOrder) {
                favorites = _.sortBy(favorites, [function (book) {
                    return book.title.display_title;
                }]);
            }

            return favorites;
        },
        goToBookCheckPremium(book) {
            // XML type books
            if (1 === book.content_type.id || 4 === book.content_type.id || 9 === book.content_type.id) {
                if (book.document_slug) {
                    window.location.href = Routing.generate("content_document_slug", {
                        documentSlug: book.document_slug
                    });
                } else {
                    window.location.href = Routing.generate("content_document", {
                        documentId: book.document_id
                    });
                }
            } else {
                // PDF type books
                window.location.href = Routing.generate("content_book_details_toc", {
                    documentId: book.document_id
                });
            }
        },
        goToBook(book) {
            this.goToBookCheckPremium(book);
        },
    }
}
