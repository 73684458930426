<template>
    <div class="dummy-content-results">
        <v-row
            align="center">
            <v-col>
                <p class="fs-20 my-3">
                    <template>
                        {{ this.pagination.totalResults.toLocaleString() }} results for
                        <span class="font-weight-bold">
                           Means Egress
                        </span>
                    </template>
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                class="py-2"
                v-for="(result, key) in results"
                :key="key">
                <v-card outlined>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col class="mb-0 pb-0">
                                <div class="float-right content-search-action-icons">
                                    <v-icon class="waffle-container">apps</v-icon>
                                </div>
                                <div class="content-result-row">
                                    <item-header
                                        :title="result.highlight.title"
                                        :meta="result"
                                        :documentId="result.documentid"
                                        :breadcrumbs="result.breadcrumbs"
                                        :search="{}">
                                    </item-header>
                                    <item-content
                                        :title="result.highlight.title"
                                        :content="result.highlight.content[0]"
                                        :sectionId="result.contentid"
                                        :type="result.bookcontenttype"
                                        :breadcrumbs="result.breadcrumbs">
                                    </item-content>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="mt-0 pt-0">
                                <p class="mb-0">
                                    <item-breadcrumb
                                        :meta="result"
                                        :breadcrumbs="result.breadcrumbs"
                                        :documentId="result.documentid">
                                    </item-breadcrumb>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="pagination.totalResults">
            <v-col>
                <pagination-pages
                    :pagination="pagination">
                </pagination-pages>
            </v-col>
        </v-row>
        <v-overlay
            z-index="10"
            :value="showOverlay"
            absolute
            light
            class="dummy-content-results-overlay">
            <v-card class="white">
                <v-card-title
                    class="font-weight-bold oxygen fs-24 black--text"
                    :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`"
                    :class="{
                        'caption mobile-title': isMobilePort
                        }">
                        <v-icon
                            class="mr-2"
                            color="accent2">
                            mdi-creation
                        </v-icon>
                        Subscribe to Premium
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-0 pb-2">
                    <v-row class="grey lighten-4 ma-auto">
                        <v-col class="text-center pa-0">
                            <img
                                alt="Code Insight"
                                class="my-1"
                                :src="imageSrc"/>
                        </v-col>
                    </v-row>
                    <div>
                        <v-row>
                            <v-col>
                                <p class="text-center mb-0" style="max-width:450px">
                                   Search for code sections from any of your Premium titles with advanced search
                                   parameters and filters. Bookmark, print, copy and share any section of code
                                   directly from search results.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto" class="pb-0">
                                <v-btn
                                depressed
                                class="font-weight-bold px-4 black--text"
                                outlined
                                @click="goToRoute('premium_features')">
                                Learn More
                            </v-btn>
                            <v-btn
                                v-if="!isActiveUser || canStartTrial"
                                depressed
                                class="font-weight-bold px-4 black--text"
                                color="accent2"
                                @click="goToRoute('trial_signup')">
                                Start Free Trial
                            </v-btn>
                            <v-btn
                                v-else
                                depressed
                                class="font-weight-bold px-4 black--text"
                                color="accent2"
                                @click="goToPremiumPage()">
                                Subscribe to Premium
                            </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
    import SearchHeader from "./SearchHeader.vue";
    import ItemHeader from './result/item_header.vue';
    import ItemContent from './result/item_content.vue';
    import ItemBreadcrumb from './result/item_breadcrumb.vue';
    import PaginationPages from "../../pagination/PaginationPages.vue";
    import Mobile from "../../inc/mixins/mobile";
    import DummyContent from "../../../../json/dummyContent.json";

    export default {
        components: {
            SearchHeader,
            ItemHeader,
            ItemContent,
            ItemBreadcrumb,
            PaginationPages
        },
        mixins: [Mobile],
        data() {
            return {
                showOverlay: true,
                results: [],
                pagination: {
                    page: 1,
                    pages: 500,
                    totalResults: 1362121
                },
            }
        },
        computed: {
            imageSrc(){
                return this.asset(require('../../../images/content_search.png'), 'default')
            }
        },
        methods: {
            goToPremiumPage(){
                window.location = Routing.generate('premium_features') + '#priceBlock';
            }
        },
        mounted(){
            const {data} = DummyContent;
            this.results = data;
            EventBus.fire('show-tag-restricted-dialog');
        }
    }
</script>
<style>
.dummy-content-results {
    position: relative;
}
.dummy-content-results-overlay.v-overlay {
    align-items: start !important;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}
.dummy-content-results-overlay.v-overlay .v-overlay__content{
    margin-top: 70px;
}
.dummy-content-results .v-overlay__scrim{
    background-color: unset !important;
}
</style>
