<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent>
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                {{cardHeader}} Project Tag
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="mr-2 accent2--text"
                    @click="closeModal">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-expand-transition>
                        <v-row v-show="error">
                            <v-col>
                                <v-alert
                                    class="mb-0"
                                    type="error"
                                    dense>
                                    {{ error }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Name"
                                solo
                                hide-details
                                v-model="tag.name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="tag.color"
                                v-mask="mask"
                                hide-details
                                class="ma-0 pa-0"
                                solo>
                                <template v-slot:append>
                                    <v-menu
                                        v-model="menu"
                                        top
                                        nudge-bottom="105"
                                        nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="tag.color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    primary
                    text
                    @click="closeModal">
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveTag">
                    <span v-if="!saving">
                        Save
                    </span>
                    <v-progress-circular
                        v-else
                        :size="20"
                        indeterminate
                        color="white">
                    </v-progress-circular>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mask} from 'vue-the-mask'
    import Mobile from '../../../inc/mixins/mobile';
    import TagsMixins from '../../../titles/notesModal/mixins/tags';

    export default {
        data() {
            return {
                dialog: false,
                cardHeader: 'Add',
                error: '',
                menu: false,
                mask: '!#XXXXXX',
                saving: false,
                tag: this.getTagData()
            }
        },
        directives: {mask},
        mixins: [Mobile, TagsMixins],
        methods: {
            getTagData() {
                return {
                    id: 0,
                    name: '',
                    color: this.$getConst('defaultTagColor')
                }
            },
            saveTag() {
                if (!_.isEmpty(this.tag.name)) {
                    this.saving = true;
                    this.error = '';
                    this.$http.post(Routing.generate('add_user_tag'), this.tag)
                        .then(response => {
                            if (_.isInteger(response.data) && response.data !== 0) {
                                this.dialog = this.saving = false;
                                this.$nextTick(() => {
                                    EventBus.fire('tag-edit-modal-callback', response.data);
                                });
                            } else {
                                this.error = this.swr;
                                this.saving = false;
                            }
                        });
                } else {
                    this.error = "Name required"
                }
            },
            showModal(data) {
                if (_.isNull(data)) {
                    this.cardHeader = 'Add';
                    this.tag = this.getTagData();
                } else {
                    this.cardHeader = 'Edit';
                    this.tag = data;
                }
                this.dialog = true;
            },
            closeModal() {
                this.dialog = this.saving = false;
                this.error = '';
            },
        },
        mounted() {
            EventBus.listen('show-tag-edit-modal', (data) => this.showModal(data));
        }
    }
</script>
