export const AppParameters = {
    astm_remove_links: false,
    info_banner_enabled: false,
    info_banner_message: "",
    registration_closed: false,
    display_revit_link: false,
    my_icc_url: "",
    revit_download_url: "",
    new_premium_tag_categories_enabled: false,
    notice_start_date: '2022-12-01',
    notice_end_date: '2023-01-15',
    terms_effective_date: '2023-01-15',
    premium_convert_discount_enabled: true,
    discount_value_in_percent: 10,
    complete_monthly_price: 66,
    collection_monthly_price: 30,
    single_monthly_price: 9,
    realtime_online_users_enabled: false,
    premium_complete_titles_count: 1000, // Default
    code_insights_filter: false,
    enable_premium_answers_page: false,
    ai_navigator_enabled: false,
    compliance_calculators_enabled: false,
    billing_page_enabled: false,
    concurrent_idle_timeout_minutes: 20,
    shop_url: '',
    search_year_weight: 3,
    search_year_weight_diff: 0.3,
    title_topic_display_enabled: false,
    user_preferences_page_enabled: false,
    pricing_page_enabled: false,
    max_allowed_items_in_cart: 100,
    google_recaptcha_v3_key: "",
    graphics_search_enabled: false,
    billing_add_remove_licenses_enabled: false,
    billing_change_duration_enabled: false,
    quick_consult_enabled: false,
    quick_consult_sample_questions_pdf: '',
    quick_consult_sku: null,
    tidio_chat_enabled: false,
    pricing_page_excluded_ids: []
};
