<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="primary--text display-1">{{ categoryTitle }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p>{{ tagTitle }}</p>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col xl="8">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <pdf-viewer
                                    :youtubeid="youtubeId"
                                    :buylink="buylink">
                                </pdf-viewer>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import PdfViewer from '../titles/partials/pdf';
    export default {
        props: {
            pdfUrl: {
                type: String,
                required: true
            },
            categoryTitle: {
                type: String,
                required: true
            },
            tagTitle: {
                type: String,
                required: true
            },
            youtubeId: {
                type: String,
                required: true
            },
            buylink: {
                type: String,
                required: false
            }
        },
        methods: {
            getPdfInfo() {
                let pdfInfo = {
                    pdf_url_full: this.pdfUrl,
                    access: true,
                };
                EventBus.fire('update-pdf-url', pdfInfo);
            }
        },
        components: {
            PdfViewer
        },
        mounted() {
            this.getPdfInfo();
        }
    }
</script>
