var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.width,
        fullscreen: _vm.fullscreen,
        persistent: !_vm.canBeClosed,
      },
      on: { "click:outside": _vm.clickedOutside },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "flex-card",
          style: _vm.borderStyle
            ? `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`
            : "",
          attrs: { "max-height": "90vh", "min-height": _vm.minHeight },
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "text-h5 flex-nowrap pa-3",
              class: !_vm.borderStyle ? "primary white--text" : "",
            },
            [
              _vm._t("title"),
              _vm._v(" "),
              !_vm.hasTitleSlot
                ? _c("div", [
                    _vm._v(" " + _vm._s(this.title ? this.title : "Confirm")),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canBeClosed,
                      expression: "canBeClosed",
                    },
                  ],
                  attrs: {
                    icon: "",
                    color: _vm.borderStyle ? "accent" : "white",
                  },
                  on: { click: _vm.close },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showHeaderBorder ? _c("v-divider") : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "d-flex flex-column pa-8 align-center" },
                [
                  _c("p", { staticClass: "mb-2" }, [_vm._v("Loading Content")]),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    attrs: {
                      active: true,
                      color: "primary",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              )
            : [
                _c(
                  "v-card-text",
                  { class: _vm.bodyClass ?? "pt-5" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _vm.showBottomBar ? _c("v-divider") : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showBottomBar,
                        expression: "showBottomBar",
                      },
                    ],
                    class: { "grey lighten-4": !_vm.noGrayActionArea },
                  },
                  [
                    _vm._t("buttons"),
                    _vm._v(" "),
                    !_vm.hasButtonsSlot
                      ? [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary white--text",
                              on: { click: _vm.clickedOkay },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(this.okText ? this.okText : "OK") +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-btn", { on: { click: _vm.clickedCancel } }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  this.cancelText ? this.cancelText : "Cancel"
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }