var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restrictedText
    ? _c(
        "div",
        { class: { "text-right": _vm.displayRightAligned } },
        [
          _vm.displayIconOnly
            ? [
                _c(
                  "v-avatar",
                  {
                    attrs: {
                      color: _vm.badgeColor.backGroundColor,
                      size: "24",
                    },
                  },
                  [
                    _vm.contentRestrictions[_vm.restrictedText]
                      ? _c(
                          "v-icon",
                          { attrs: { small: "", color: _vm.badgeColor.color } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.contentRestrictions[_vm.restrictedText]
                                    .icon
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _c(
                          "v-icon",
                          { attrs: { small: "", color: _vm.badgeColor.color } },
                          [
                            _vm._v(
                              "\n                mdi-creation\n            "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            : [
                _vm.contentRestrictions[_vm.restrictedText]
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          small: "",
                          label: "",
                          color: _vm.badgeColor.backGroundColor,
                          elevation: "0",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: "",
                              left: "",
                              color: _vm.badgeColor.color,
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.contentRestrictions[_vm.restrictedText]
                                    .icon
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "caption font-weight-bold text-uppercase",
                            class: _vm.badgeColor.textColor,
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.contentRestrictions[_vm.restrictedText]
                                    .label
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "max-width": "200",
                          transition: "fade-transition",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: {
                                          small: "",
                                          label: "",
                                          color: "accent2Light",
                                          tabindex: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPremiumFeaturesDialog()
                                          },
                                          keydown: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.openPremiumFeaturesDialog()
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "space",
                                                  32,
                                                  $event.key,
                                                  [" ", "Spacebar"]
                                                )
                                              )
                                                return null
                                              return _vm.openPremiumFeaturesDialog()
                                            },
                                          ],
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            left: "",
                                            color: _vm.badgeColor.color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    mdi-creation\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { class: _vm.badgeColor.textColor },
                                        [
                                          _vm._v(
                                            "\n                    PREMIUM\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3486653812
                        ),
                        model: {
                          value: _vm.tooltipOpen,
                          callback: function ($$v) {
                            _vm.tooltipOpen = $$v
                          },
                          expression: "tooltipOpen",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "tooltip-content",
                            on: {
                              mouseenter: _vm.showTooltip,
                              mouseleave: _vm.hideTooltip,
                            },
                          },
                          [_vm._v("Learn about Premium")]
                        ),
                      ]
                    ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }