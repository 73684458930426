<template>
    <div class="white--text d-flex justify-center align-center"
         :class="{'primary': !error, 'error': error}"
         style="min-height: calc(32px + 2rem)">

        <v-progress-linear
                v-if="isLoading"
                indeterminate
                color="white"
                style="max-width: 16rem"
                height="6"
        ></v-progress-linear>

        <template v-else>

            <template v-if="error">
                Something went wrong! {{ error }}
            </template>

            <template v-else-if="isRatingSaved">
                Thanks for your feedback!
            </template>

            <template v-else>

                <div class="mr-2 pl-2">Was this Code Insight useful?</div>

                <v-rating
                        background-color="white"
                        color="warning"
                        hover
                        length="5"
                        size="32"
                        :value="value"
                        @input="ratingUpdated"
                ></v-rating>

            </template>

        </template>

    </div>
</template>

<script>
import {ApiService} from "../../api/ApiService";

export default {
    props: ['tag', 'rating'],
    emits: ['submit'],
    data() {
        return {
            isLoading: false,
            error: '',
            value: 0,
            isRatingSaved: false
        }
    },
    methods: {
        saveRating(rating) {
            const id = this.tag;
            return ApiService.rateTag(id, rating);
        },
        ratingUpdated(value) {

            this.isLoading = true;

            this.saveRating(value).then(() => {
                this.isRatingSaved = true;
                this.$emit('submit', value);
            }).catch((err) => {
                this.error = err;
            }).finally(() => {
                this.isLoading = false;
            });

        }
    }
}
</script>
