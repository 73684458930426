<template>
    <div>
        <v-bottom-navigation
            :value="true"
            app
            background-color="white"
            grow>
            <v-btn
                    class="hidden-md-and-up"
                    v-if="from_search"
                    block
                    onclick="history.go(-1);return false;">
                <span class="black--text">GO BACK</span>
                <v-icon class="primary--text">navigate_before</v-icon>
            </v-btn>
            <v-btn  class="hidden-md-and-up"
                    v-if="filters"
                    @click="openFilterModal"
                    text
                    id="someId">
                <span class="black--text">Search Filters</span>
                <v-icon class="primary--text">search</v-icon>
            </v-btn>
            <v-btn
                v-if="book_content"
                text
                @click="triggerSmartIndex">
                <span class="black--text">Contents</span>
                <v-icon class="primary--text">menu</v-icon>
            </v-btn>
            <v-btn
                text
                @click="showFeedbackModal"
                id="feedback_button">
                <span class="black--text">Feedback</span>
                <v-icon class="primary--text">feedback</v-icon>
            </v-btn>
            <v-btn
                text
                @click="initLiveChat"
                id="phplive_btn_1496167627">
                <span class="black--text">Live Chat</span>
                <v-icon class="primary--text">chat</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
    import FeedbackChat from '../../inc/mixins/feedback_chat';

    export default {
        mixins: [FeedbackChat],
        data() {
            return {
                filters: false,
                from_search: false,
                book_content: false
            }
        },
        methods:{
            openFilterModal(){
                EventBus.fire('open-filters-modal');
            },
             triggerSidebar() {
                EventBus.fire('trigger-pa-sidebar');
            },
            showFeedbackModal() {
                EventBus.fire('show-feedback-modal');
            },
            triggerSmartIndex() {
                EventBus.fire('toggle-mobile-legend', true);
            }
        },
        created() {
             EventBus.listen('active-search-page',()=> this.filters = true);
             EventBus.listen('from-search-page',()=> this.from_search = true);
             EventBus.listen('from-book-content',()=> this.book_content = true);
        },

    }
</script>
