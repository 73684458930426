export default {
    data: () => ({
        topBanner: true,
        category_list: [],
        book_list: [],
        tag_list: [],
        category: '',
        book: '',
        tag: '',
        error_message: '',
        error_status: false,
        report_title: '',
        document_sku: [],
        tag_ids: [],
        chapterNotes: [],
        load: false,
        default_filter_tag: false,
        report_load: false,
        show_process_received_modal: false,
        filteredResults: [],
        panel: [],
        selectedBookSections: [],
        selectAllNotes: [],
        selectedBookValues: {
            all: false,
            books: [],
            chapters: [],
            sections: []
        },
        barFixed: false,
        itemsPerPageOptions: [10, 15, 20],
        pagination: {
            rowsPerPage: 10
        },
        export: false,
        exported: false,
        fromBookId: 0,
        fromBookTitle: '',
        toBookTitle: '',
        migrateFromBook: {},
        migrateToBooks: [],
        bookNewPrintings: [],
        allMasterNewPrintingBooks: [],
        selectedMigrateTo: 0,
        anyBook: false,
        selectedDocument: null
    }),

    methods: {
        isPanelMultiple() {
            return this.filteredResults.length > 1 ? true: false
        },
        checkPanelEvent(data) {
            const panelIndex = this.filteredResults.indexOf(data.item) % this.pagination.rowsPerPage;
            if (this.panel === 0) {
                this.panel = [];
            } else if (typeof this.panel !== "number" && this.panel.indexOf(panelIndex) !== -1) {
                this.panel.splice(this.panel.indexOf(panelIndex), 1);
            } else {
                if (this.filteredResults.length > 1) {
                    this.panel.push(panelIndex);
                } else {
                    this.panel = 0;
                }
            }
        },
        countNotesInChapter(item){
            let result = 0;
            _.forEach(item, function (chapter, chapterId) {
                result += chapter.chapterNotesCount;
            });
            return result;
        },
        setBookInfo(bookId) {
            this.$http.get(Routing.generate('get_document_by_documentid', {'documentId': bookId}))
                .then(response => {
                    if(response.data) {
                        let masterBookId = response.data.master_document_id > 0 ? response.data.master_document_id : response.data.document_id;
                        this.fromBookId = response.data.document_id;
                        this.migrateFromBook = response.data;
                        this.setFromBookTitle();
                        this.getNewPrintings(masterBookId, this.fromBookId);
                        this.getAnnotationAndBookmarks(this.fromBookId);
                    }
                });
        },
        setFromBookTitle() {
            this.fromBookTitle = this.migrateFromBook.display_title;
            this.fromBookTitle += ' (' + this.printEdition(this.migrateFromBook.print_version_edition) + ' Version: ' + this.migrateFromBook.printing_text + ')';
        },
        setAllBooksList(bookId) {
            this.$http.get(Routing.generate('get_master_new_printing_books', {'userId': this.userId, 'excludeBookId': bookId}))
                .then(response => {
                    if (response.data) {
                        this.allMasterNewPrintingBooks = response.data;
                    }
                    this.loading.autoComplete = false;
                });
        },
        getNewPrintings(masterBookId, excludeBookId) {
            let _this = this;
            this.$http.get(Routing.generate('get_document_related_prints', {'masterBookId': masterBookId, 'excludeBookId': excludeBookId}))
                .then(response => {
                    if(response.data) {
                        _this.bookNewPrintings = response.data;
                        _this.migrateToBooks = _this.bookNewPrintings;
                        this.selectedDocument =  _.last(_this.migrateToBooks);
                        this.setMigrateToBook(this.selectedDocument.document_id);
                    }
                    this.loading.autoComplete = false;
                });
        },
        getAnnotationAndBookmarks: function (bookId = 0) {
            let filters = {'action': 'all_notes', 'userId': this.userId};
            if(bookId) {
                filters = {'bookId': bookId, 'userId': this.userId};
            }
            this.$http.get(Routing.generate('get_annotation_bookmarks', filters))
                .then(response => {
                    this.filterArray(response.data);
                    this.document_sku = response.data.documentSku;
                    this.tag_ids = response.data.tags;
                    this.chapterNotes = response.data.allData;
                    this.getCategories();
                    this.getCodes();
                    this.getTags();
                    this.load = true;
                    // Default filter by tag param in the URL querystring
                    if (!this.default_filter_tag) {
                        let searchParams = new URLSearchParams(window.location.search);
                        if (searchParams.has('export')) {
                            this.export = true;
                        }
                        if (searchParams.has('tag')) {
                            this.default_filter_tag = searchParams.get('tag');
                            this.filterResult(this.default_filter_tag, 'tag'); // Apply filters
                            this.tag = {id: parseInt(this.default_filter_tag)}; // Set selected
                        }
                    }
                })
        },
        filterResult: function (e, type) {
            var params = {};

            switch (type) {
                case 'category':
                    params = {category: e, tag: this.tag, book: this.book};
                    break;
                case 'tag':
                    params = {category: this.category, tag: e, book: this.book};
                    break;
                case 'book':
                    params = {category: this.category, tag: this.tag, book: e};
                    break;
                default:
                    break;
            }
            this.$http.get(
                Routing.generate('get_annotation_bookmarks', params))
                .then(response => {
                    this.filterArray(response.data);
                    this.chapterNotes = response.data.allData;
                    this.$nextTick(() => {
                        if (this.export && !this.exported) {
                            this.selectedBookValues.all = true;
                            this.selectAllChapters();
                        }
                    });
                })
        },
        selectAllChapters(){
            if(!this.selectedBookValues.all){
                this.cleanSelectedValues();
            }else {
                let data = this.filteredResults,
                    books = [],
                    chapters = [];

                data.forEach(function(item){
                    books.push(item.info.id);
                    Object.keys(item.chapter).forEach(function(chapterId){
                        chapters.push(chapterId);
                    });
                });

                this.selectedBookValues = {
                    all: true,
                    books: books,
                    chapters: chapters,
                    sections: []
                };

                for(let i = 0; i < chapters.length; i++){
                    if(!_.isArray(chapters[i])){
                        this.parentChecked(chapters[i]);
                    }
                }

                if (this.export && !this.exported) {
                    this.exported = true;
                    this.generateReport();
                }
            }
        },
        selectBookSections(item){
            let chapters = [];
            if(!_.includes(this.selectedBookValues.books, item.info.id)){
                let removeChapters = [];

                Object.keys(item.chapter).forEach(function(chapterId){
                    removeChapters.push(chapterId);
                });

                for(let i = 0; i < removeChapters.length; i++){
                    let chapter = this.selectedBookValues.chapters.indexOf(removeChapters[i]);
                    this.selectedBookValues.chapters.splice(chapter, 1);
                    this.parentChecked(removeChapters[i]);
                }
            }else{
                Object.keys(item.chapter).forEach(function(chapterId){
                    chapters.push(chapterId);
                });

                if(!_.includes(this.selectedBookValues.books, item.info.id)) {
                    this.selectedBookValues.books.push(item.info.id);
                }
                this.selectedBookValues.chapters = chapters;
                for(let i = 0; i < chapters.length; i++){
                    if(!_.isArray(chapters[i])){
                        this.parentChecked(chapters[i]);
                    }
                }
            }
        },
        filterArray(data){
            let items = _.map(data, function(item) {
                if (item.title !== undefined) {
                    return item;
                }
            });

            this.filteredResults = _.orderBy(_.without(items, undefined),
                ['info.year.year', 'title'], ['desc', 'asc']
            );
        },
        resetFilter: function () {
            this.category = '';
            this.tag = '';
            this.book = '';
            this.getAnnotationAndBookmarks();
            this.cleanSelectedValues();
            this.report_title = '';
        },
        getCategories: function () {
            if (this.document_sku != undefined) {
                this.$http.post(
                    Routing.generate('get_categories_for_annotation_bookmarks'),
                    {skus: this.document_sku},
                    {emulateJSON: true}
                )
                    .then(response => {
                        this.category_list = response.data;
                    })
            }
        },
        getCodes: function () {
            if (this.document_sku != undefined) {
                this.$http.post(Routing.generate('get_codes'),
                    {skus: this.document_sku},
                    {emulateJSON: true}
                )
                    .then(response => {
                        this.book_list = response.data;
                    })
            }
        },
        getTags: function () {
            if (this.tag_ids != undefined) {
                this.$http.get(Routing.generate('get_tags', {tagIds: this.tag_ids}))
                    .then(response => {
                        this.tag_list = response.data;
                    })
            }
        },
        generateReport: function () {
            if (this.selectedBookValues.sections.length == 0) {
                this.error_status = true;
                this.error_message = 'Please select at least one Annotation or Bookmark before generating a Report.';
                return false;
            }
            this.report_load = true;

            this.$http.post(Routing.generate('generate_cache_report', {'userId': this.userId}), {
                'selectedIds': this.selectedBookValues.sections,
                'reportTitle': (this.report_title === '') ? 'Annotations/Highlights & Bookmarks Report' : this.report_title
            }).then(response => {
                this.report_load = false;
                window.open(response.data.url);
            });
        },
        parentChecked: function (chapterId) {
            let unChecked = this.selectedBookValues.chapters.includes(chapterId);
            let parentArray = this.chapterNotes[chapterId];
            let i;
            for (i = 0; i < parentArray.length; i++) {
                if (unChecked == false) {
                    let index = this.selectedBookValues.sections.indexOf(parentArray[i]);
                    this.selectedBookValues.sections.splice(index, 1);
                } else {
                    if (!this.selectedBookValues.sections.includes(parentArray[i])) {
                        this.selectedBookValues.sections.push(parentArray[i]);
                    }
                }
            }

        },
        childChecked: function (chapterId, selectedContent) {
            let checked = this.selectedBookValues.sections.includes(selectedContent);
            let allChecked = true;
            let i;
            let parentArray = this.chapterNotes[chapterId];
            if (checked == false) {
                let index = this.selectedBookValues.chapters.indexOf(chapterId);
                if (index !== -1) {
                    this.selectedBookValues.chapters.splice(index, 1);
                }
            } else {
                for (i = 0; i < parentArray.length; i++) {
                    if (this.selectedBookValues.sections.includes(parentArray[i]) == false) {
                        allChecked = false;
                    }
                }
                if (allChecked == true) {
                    this.selectedBookValues.chapters.push(chapterId);
                }
            }
        },
        cleanSelectedValues(){
            this.selectedBookValues = {
                all: false,
                books: [],
                chapters: [],
                sections: []
            };
        },
        printEdition(data) {
            if (!_.isUndefined(data)) {
                return data.version;
            }
        },
        setTags() {
            this.$http.get(Routing.generate('get_my_tags'))
                .then(response => {
                    this.$store.commit('setTags', response.data);
                });
        },
        showModal() {
            EventBus.fire('show-modal-share-annotation-bookmark', this.selectedBookValues.sections);
        },

        expandCollapsePanels(){
            if((this.panel && this.panel.length > 0) || this.panel === 0) {
                this.panel = [];
            } else {
                if (this.filteredResults.length > 1) {
                    this.panel = [...Array(this.filteredResults.length).keys()].map((k, i) => i);
                } else {
                    this.panel = 0;
                }
            }
        },

        redirectToSection(documentId, chapterId, sectionId) {
            this.$http.post(Routing.generate('get_url_slug', {
                'documentId': documentId,
                'chapterId': chapterId
            })).then(response => {
                if (!response.data.success) {
                    window.open(response.data.slugURL + '#text-id-' + sectionId, '_blank');
                }
            });
        },

        bookLink(book){
            // No click action for premium complete book
            if (!_.isUndefined(book.content_type) && 'Premium Complete' === book.content_type.name) {
                return false;
            }

            if (book.document_slug) {
                window.open(Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug
                }), '_blank');
            } else {
                window.open(Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': 'toc'
                }), '_blank');
            }
        },
        getDescription(data) {
            switch(data.type) {
                case('note'):
                    return data.text;
                    break;
                case('annotation'):
                    return data.annotationHtml;
                    break;
                case('bookmark'):
                    return data.content;
                    break;
            }
        },
        getIcon(type) {
            return (type === 'note' || type === 'annotation' || type === 'highlight') ? 'border_color' : 'bookmark';
        },
        getTagColor(id) {
            if (id === 1) {
                return this.$getConst('defaultTagColor');
            }
            if (this.$store.getters.getTags) {
                let tag = _.find(this.$store.getters.getTags, {'id': _.toInteger(id)});
                if (tag && tag.color) {
                    return '#'+tag.color;
                }
            }
        },
        getTagName(id) {
            if (this.$store.getters.getTags) {
                let tag = _.find(this.$store.getters.getTags, {'id': _.toInteger(id)});
                return tag ? tag.tag : '';
            }
        },

        editNote(data){
            if(data.type === 'bookmark'){
                EventBus.fire('bookmark-modal', {'id': data.bookmarkId ? data.bookmarkId : ''});
            }else{
                EventBus.fire('note-modal', {id: data.id});
            }

        },
        showDeleteModal(data) {
            switch(data.type){
                case 'annotation':
                case 'highlight':
                    EventBus.fire('remove-note', {
                        'id': data.id,
                        'sectionID': null,
                        'tagID': data.tagId,
                        'annotation': data.annotationId
                    });
                    break;
                case 'bookmark':
                    EventBus.fire('remove-bookmark', {
                        'id' : data.bookmarkId,
                        'sectionId': null,
                    });
                    break;
            }
        },
        handleScroll(){
            let windowTop = window.pageYOffset,
                actionsBar = document.getElementById("notesActionBar"),
                userTopFixedBarHeight = 64;

            if ((windowTop && actionsBar)
                && (windowTop + userTopFixedBarHeight) >= actionsBar.offsetTop) {
                this.barFixed = true;
            } else {
                this.barFixed = false;
            }
        },
        setMigrateToBook(docId) {
            this.selectedMigrateTo = docId;
            let selectedBook = _.find(this.migrateToBooks, {'document_id': docId});
            this.toBookTitle = (!_.isUndefined(selectedBook)) ? selectedBook.display_title+' '+selectedBook.print_version+'' : '';
        },
    }
}
