/**
 * Disable right-click of mouse, F12 key, and save key combinations on page
 */
export default {
    methods: {
        bindKey(e, document, contentPremium, availableToSubscribe) {
            // "ctrl + p" key
            if (e.ctrlKey && e.keyCode == 80) {
                this.disabledEvent(e);
                
                if(availableToSubscribe && !contentPremium) {
                    EventBus.fire('show-printing-restricted-modal', {isPremiumComplete: false, documentInfo: document});
                } else {
                    EventBus.fire("protected-content-notificaton");
                }
            }
            // "command + p" key macOS
            if (e.keyCode == 80 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                this.disabledEvent(e);
                
                if(availableToSubscribe && !contentPremium) {
                    EventBus.fire('show-printing-restricted-modal', {isPremiumComplete: false, documentInfo: document});
                } else {
                    EventBus.fire("protected-content-notificaton");
                }
            }
            // "F12" key
            if (event.keyCode == 123) {
                this.disabledEvent(e);
            }
        },
        disabledEvent(e) {
            if (e.stopPropagation) {
                e.stopPropagation();
            } else if (window.event) {
                window.event.cancelBubble = true;
            }
            e.preventDefault();
            return false;
        },
        bindKeyPC(e) {
            // "ctrl + p" key
            if (e.ctrlKey && e.keyCode == 80) {
                this.disabledEvent(e);
                EventBus.fire('show-printing-restricted-modal', {isPremiumComplete: true});
            }
            // "command + p" key macOS
            if (e.keyCode == 80 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                this.disabledEvent(e);
                EventBus.fire('show-printing-restricted-modal', {isPremiumComplete: true});
            }
        }
    },
}
