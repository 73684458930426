<template>
    <div>
        <v-chip
            v-for="(item, key) in topics"
            :key="key"
            small
            color="cdpBlueLight"
            class="cdpBlue--text my-1"
            :class="!isMobilePort && 'ml-2'"
            @click="answerViewPage
                ? goToRoute('premium_answers', {topic: item.id})
                : $emit('topicClick', item.id)">
            {{item.title}}
        </v-chip>
    </div>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";

    export default {
        props: {
            topics: {
                required: false,
                type: Array
            },
            answerViewPage: {
                required: false,
                type: Boolean,
                default: false
            },
        },
        mixins: [
            Mobile
        ],
    }
</script>
