<template>
    <v-container class="content-container">
        <v-row>
            <v-col :class="isMobilePort ? 'text-center' : ''">
                <h1 class="primary--text font-weight-regular">
                    Sign Up
                </h1>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col :class="isMobilePort ? 'text-center' : ''">
                <p class="primary--text">
                    Already have an account? <a :href="getRoute('security_login')"><b>Sign In</b></a>
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="7" class="pt-0">
                <v-row>
                    <v-col :class="isMobilePort ? 'text-center' : ''">
                        <h2 class="primary--text oxygen">
                            Register for a free ICC Digital Codes account
                        </h2>
                    </v-col>
                </v-row>
                <registration-form :isTrialRegistration="isTrialRegistration">
                </registration-form>
            </v-col>
            <v-col cols="12" sm="5" class="pt-0">
                <up-sell-vertical
                    :image="{
                        src: 'illistrations/key_holder.webp'
                    }"
                    heading="With your free Digital Codes account you can:"
                    :description="buildUlList()"
                    :options="{color: 'grey lighten-3'}">
                </up-sell-vertical>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import UpSellVertical from "../partials/UpSellVertical";
    import RegistrationForm from "./RegistrationForm";
    import Mobile from './../inc/mixins/mobile';

    export default {
        data() {
            return {
                trial: false,
                descriptions: {
                    register: [
                        {
                            name: 'Access authenticated features across ICC web tools including:',
                            subList: [
                                {
                                    name: 'myICC'
                                },
                                {
                                    name: 'ICCSafe'
                                },
                                {
                                    name: 'ICC Shop'
                                },
                                {
                                    name: 'cdpACCESS'
                                },
                                {
                                    name: 'Learning Center'
                                },
                                {
                                    name: 'Assessment Center'
                                }
                            ]
                        },
                        {
                            name: 'Become an ICC member'
                        },
                        {
                            name: 'Build a favorites list for quick access to commonly referenced codes'
                        },
                        {
                            name: 'Enter a concurrent code to collaborate on a Premium subscription'
                        },
                        {
                            name: 'Redeem a Digital Codes <b>Premium Enterprise</b> invitation'
                        },
                        {
                            name: 'Register for Digital Codes webinars'
                        },
                        {
                            name: 'Start a free Digital Codes <b>Premium Complete</b> trial'
                        },
                    ],
                    trial: ''
                }
            }
        },
        props: {
            isTrialRegistration: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            UpSellVertical,
            RegistrationForm
        },
        mixins: [
            Mobile
        ],
        methods: {
            buildUlList() {
                let key = 'register';
                if (this.trial) {
                    key = 'trial';
                }

                let html = '<ul>';
                _.forEach(this.descriptions[key], function(li) {
                    html += '<li class="pa-1">'+li.name+'</li>';
                    if (li.subList) {
                        html += '<ul style="list-style-type: inherit;">';
                        _.forEach(li.subList, function(sub) {
                            html += '<li class="pa-1"><b>'+sub.name+'</b></li>';
                        });
                        html += '</ul>';
                    }

                });
                html += '</ul>';

                return html;
            }
        }
    }
</script>
