var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPartOfComplete
    ? _c(
        "v-card",
        { staticClass: "accent2-top-border", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c("v-col", [
                    _c("h3", [
                      _vm._v(
                        "\n                    This title is included in our Premium Complete collection\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-4 mb-0" }, [
                      _vm._v(
                        "\n                    Access a growing library of nearly " +
                          _vm._s(_vm.premiumCompleteTitlesCount()) +
                          "+ titles\n                "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "accent2" },
                          on: {
                            click: function ($event) {
                              return _vm.goToRoute("title_landing", {
                                documentSlug: "premium-complete",
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Learn More\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }