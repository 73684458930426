<template>
    <div>
        <sticky
            :documentInfo="activeDocumentInfo"
            show-commentary-button
            :isTrialActive="isTrialActive"
            :contentPremium="contentPremium"
            :contentProtection="contentProtection"
            :contentBasic="contentBasic"
            :bookAvailableToSubscribe="bookAvailableToSubscribe"
            :pdfurl="pdfurl"
            :show-exam="'' !== examId"
            request="content">
        </sticky>

        <SearchTextBar v-if="'' !== examId" />
        <v-container
            class="pt-0"
            fluid
            :class="{'px-0': isMobilePort}">
            <chapter-section-tags>
            </chapter-section-tags>
            <smart-index
                :documentInfo="activeDocumentInfo"
                :exam-id="examId">
            </smart-index>
            <v-row :class="{'ma-0': isMobilePort}">
                <v-col
                    xs12
                    id="contentParent"
                    :class="classObject">
                    <template v-if="!isXML">
                        <pdf
                            :youtubeid="youtubeid"
                            :buylink="buylink"
                            :contentProtection="contentProtection">
                        </pdf>
                    </template>
                    <template v-else>
                        <v-layout row v-if="notifications.length">
                            <v-flex xs12 text-center py-12 mt-6>
                                <alert v-for="(notification, index) in notifications"
                                       :key="index"
                                       :message="notification.message"
                                       :notificationType="notification.type"
                                       :dismissible="notification.dismissible"
                                       @dismiss="notification.dismissHandler(index)">
                                    <template v-if="notification.errorCodes.length">
                                        <template v-if="notification.errorCodes.length > 1">
                                            <div class="text-left">
                                                <ul>
                                                    <li v-for="errorCode in notification.errorCodes">
                                                        <span v-html="errorCode"></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span v-html="notification.errorCodes[0]"></span>
                                        </template>
                                    </template>

                                </alert>
                            </v-flex>
                        </v-layout>
                        <template v-if="content">
                            <div
                                :class="{
                                    'max-content-container': !isFullscreenContent
                                }"
                                class="pt-4 chapter_styling"
                                v-html="content">
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="!contentError">
                                <v-layout row>
                                    <v-flex xs12 text-center py-12 mt-6>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary">
                                        </v-progress-circular>
                                    </v-flex>
                                </v-layout>
                            </template>
                        </template>
                    </template>
                </v-col>
            </v-row>
            <template v-if="isLoggedIn">
                <share-section-modal :key="modalKey"></share-section-modal>
                <notes></notes>
            </template>
            <back-to-top></back-to-top>
        </v-container>
        <div v-if="'' === examId">
            <cdp>
            </cdp>
            <peak-ahead>
            </peak-ahead>
            <astm-modal>
            </astm-modal>
            <ac-scope-modal>
            </ac-scope-modal>
            <access-restricted></access-restricted>
        </div>

        <premium-tag-modal></premium-tag-modal>
        <premium-features-dialog>
        </premium-features-dialog>
        <premium-tools-access-restriction-modal>
        </premium-tools-access-restriction-modal>
    </div>
</template>

<script>
    // Components
    import ChapterSectionTags from './tagsModal/index';
    import SmartIndex from './smartIndex/index';
    import SmartIndexMobile from './smartIndex/indexMobile';
    import Sticky from './chapter-header/ChapterHeader.vue';
    import StickyMobile from './partials/stickyMobile';
    import BackToTop from '../partials/buttons/back_to_top';
    import ShareSectionModal from '../modals/ShareSection.vue';
    import Notes from './notesModal/index';
    import Pdf from './partials/pdf';
    import Mark from '../../../../node_modules/mark.js';
    import Cdp from '../tags/cdp/index';
    import PeakAhead from './peakAhead/index';
    import ConfirmDialog from '../ConfirmDialog';
    import SearchTextBar from './partials/SearchTextBar';
    import Alert from '../notifications/AlertBar';

    // Mixins and utilities
    import '../../scss/content/oldXML.scss';
    import '../../scss/content/newXML.scss';
    import '../../scss/content/default.scss';
    import Legend from './inc/mixins/legend';
    import Books from './inc/mixins/books';
    import Premium from '../inc/mixins/premium';
    import PreventActions from './inc/preventActions';
    import ContentUtils from './inc/contentUtils';
    import ContentActions from './inc/contentActions';
    import * as Xml2SCSS from '../../scss/content/XML2.scss';
    import Mobile from './../inc/mixins/mobile';
    import Vue from "vue";
    import Tag from "./partials/tag";
    import Seo from './inc/mixins/seo';
    import AstmModal from '../modals/astmModal';
    import AcScopeModal from '../modals/acScopeModal';
    import {AsyncUtils} from "../../util/AsyncUtils";
    import AccessRestricted from "../tags/AccessRestricted.vue";
    import DisplayTagCategories from "./partials/DisplayTagCategories";

    import store from '../../store/store';
    import PremiumTagModal from "../tags/PremiumTagModal.vue";
    import {DomUtils} from "../../util/DomUtils";
    import {UserBookProgressApi} from "../../api/UserBookProgressApi";
    import PremiumFeaturesDialog from "./landing/PremiumFeaturesDialog.vue";
    import PremiumToolsAccessRestrictionModal from '../modals/PremiumToolsAccessRestrictionModal';
    import axios from "axios";

    export default {
        data() {
            return {
                currentChapterId: null,
                tagRestrictedDialog: false,
                content: '',
                previousHighlightID: '',
                highlightedText: {},
                notes: {},
                modalKey: 0,
                chapterNodes: [],
                notifications: [],
                contentError: false,
            }
        },
        props: {
            documentid: {
                type: String,
                required: true
            },
            chapterid: {
                type: String,
                required: true
            },
            pdfurl: {
                type: String,
                required: true
            },
            isTrialActive: {
                type: String,
                required: true
            },
            isTrialBook: {
                type: String,
                required: true
            },
            csrfToken: {
                type: String,
                required: true
            },
            hasNoAccess: {
                type: String,
                required: true
            },
            examId: {
                type: String,
                required: false,
                default: ''
            },
            examTitle: {
                type: String,
                required: true
            },
            contentAccessLevelId: {
                type: String,
                required: true
            },
            documentslug: {
                type: String,
                required: false
            },
        },
        computed: {
            dynContent() {
                return this.content;
            },
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
            canCopyPastePrint() {
                if (this.activeDocumentInfo && this.activeDocumentInfo.contentAccess) {
                    return this.activeDocumentInfo.contentAccess.canCppPremium;
                }

                return false;
            }
        },
        mixins: [
            Legend,
            Books,
            Premium,
            PreventActions,
            ContentUtils,
            ContentActions,
            Mobile,
            Seo
        ],
        components: {
            PremiumFeaturesDialog,
            Alert,
            PremiumTagModal,
            AccessRestricted,
            ChapterSectionTags,
            SmartIndex,
            SmartIndexMobile,
            Sticky,
            StickyMobile,
            BackToTop,
            ShareSectionModal,
            Notes,
            Pdf,
            Xml2SCSS,
            Cdp,
            PeakAhead,
            AstmModal,
            AcScopeModal,
            ConfirmDialog,
            SearchTextBar,
            PremiumToolsAccessRestrictionModal
        },
        methods: {
            addNotification(type, message, errorCodes = [], dismissible = false, dismissHandler = (index) => {}) {
                // shorthand for a signle error code
                if (typeof errorCodes === 'string') {
                    errorCodes = [errorCodes];
                }

                this.notifications.push({
                    type: type,
                    message: message,
                    errorCodes: errorCodes,
                    dismissible: dismissible,
                    dismissHandler: dismissHandler
                });
            },
            refreshLibrary() {
                this.$http.get(Routing.generate('sync_shop_purchase'));
            },
            getContentLinkTargetId(link) {
                let targetId = null;
                let href = link.getAttribute('href');

                if(href && href.length > 1 && '#' == href.charAt(0)) {
                    targetId = href.replace(/#/, '');
                } else if(href && href.indexOf('/book/linkit/') != -1) {
                    targetId = href.replace(/\/book\/linkit\//, '');
                } else if(href && href.indexOf('/book/externallinkit/') != -1) {
                    let parts = href.split('/');
                    targetId = parts[parts.length-1];
                }

                return targetId;
            },
            async getContent(data) {
                let chapterId = data.chapterId ? data.chapterId : data;
                if (this.currentChapterId === chapterId) {
                    return;
                }
                this.currentChapterId = chapterId;
                let sectionId = data.sectionId ? data.sectionId : null;
                let documentId = _.toInteger(this.documentid);
                this.content = '';
                this.previousHighlightID = '';

                const bookErrorDesc = this.examId ?`[${this.examId} -> ${this.documentslug}]:` : null;

                if (this.type === 'xml') {

                    const routeUri = Routing.generate('get_chapter_xml', {
                        documentId: documentId,
                        chapterId: _.toInteger(chapterId)
                    });

                    const response = await axios.get(routeUri).catch(response => {
                        switch (response.status) {
                            case 403:
                                this.addNotification(
                                    'error',
                                    'You do not have access to this content.' + (this.examId ? ' Please contact support for assistance.' : ''),
                                    this.examId ? `<em>${bookErrorDesc} Not authorized to view content (403)</em>` : [],
                                );
                                this.contentError = true;

                                break;
                            default:
                                this.addNotification(
                                    'error',
                                    'An error occurred while retrieving the content.',
                                    this.examId ? `<em>${bookErrorDesc} ` : '<em>' + `${response.status}: ${response.statusText ?? 'General error'}</em>`,
                                );

                                this.contentError = true;

                                break;
                        }
                    });

                    if (this.contentError === true) {
                        return;
                    }

                    this.content = response.data;

                    // add toolbar and misc parsing starting here
                    this.$nextTick(() => {
                        let target = window.location.hash
                        if (!target.includes('text-id')) {
                            const hash = window.location.hash.replace('#', '')
                            target = document.getElementById(hash)
                            if (!target) {
                                // Cross chapter content scroll
                                let tagId = 'text-id-' + sectionId
                                let element = document.getElementById(tagId)
                                if (!element) {
                                    tagId = 'action-wrapper-' + sectionId // old xml
                                    element = document.getElementById(tagId)
                                }
                                if (element) {
                                    EventBus.fire('scroll-to-target', element)
                                }
                            }
                        }
                        EventBus.fire('scroll-to-target', target);
                        // Check to make sure we're not on an index page and the user is logged in.
                        if (window.location.href.indexOf('index') < 1 &&
                            '' === this.examId &&
                            (_.toNumber(this.contentPremium) ||
                                _.toNumber(this.contentBasic) ||
                                _.toNumber(this.isTrialActive)
                            )
                        ) {
                            this.wrapContent(chapterId, this.isTrialActive);

                            if(this.isLoggedIn && _.toNumber(this.contentPremium)) {
                                this.getChapterNotes(chapterId);
                            }
                        } else {
                            this.addSpaceAroundSections();
                        }

                        // Modify text to links for ASTM section under reference standard chapter
                        if('' === this.examId) {
                            let chapterTitleInfo = document.getElementsByClassName('chapter_title');
                            let chapterTitle = chapterTitleInfo.length ?
                                chapterTitleInfo[0].innerHTML.replace('&nbsp;', ' ') :
                                '';
                            if ((chapterTitle === 'REFERENCED STANDARDS'
                                    || chapterTitle === 'Referenced Standards')
                                    && this.hasPremiumComplete()
                                    && !_.toNumber(this.isTrialActive)
                                    && this.isAstmCompassEnabled()) {

                                let sections = document.getElementById('contentParent');
                                let promulgatorSections = sections.querySelectorAll('section.promulgator');
                                if (promulgatorSections.length > 0) {
                                    this.sectionAstmParse(promulgatorSections, documentId);
                                } else {
                                    let promulgatorDivs = sections.querySelectorAll('div.promulgator');
                                    if (promulgatorDivs.length > 0) {
                                        this.divAstmParse(promulgatorDivs, documentId);
                                    }
                                }
                            }
                        }

                        // Remove section links if non PA.
                        // Remove ASTM links if user is in active trial or purchased singles or collection
                        let _this = this;
                        if(this.$getConst('contentTypeIccEsXml') != this.activeDocumentInfo.content_type.name){
                            let links = document.getElementById('contentParent').getElementsByTagName('a');
                            links = Array.from(links);
                            _.forEach(links, function(link) {
                                let targetId = _this.getContentLinkTargetId(link);

                                if (!_.toNumber(_this.contentPremium) ||
                                    ((_.toNumber(_this.isTrialActive) || !_this.hasPremiumComplete())
                                        && targetId
                                        && (targetId.includes('_PromASTM_') || targetId.includes('_ASTM_'))
                                    ) ||
                                    (_this.hasPremiumComplete()
                                        && targetId
                                        && (targetId.includes('_PromASTM_') || targetId.includes('_ASTM_'))
                                        && !link.getAttribute('data-astm-compass')
                                    )
                                ) {

                                    if (!link.classList.contains('relocated_to') && !link.classList.contains('new_location')) {
                                        let linkText = document.createTextNode(link.text);
                                        link.parentNode.insertBefore(linkText, link);
                                    }
                                    if(link && link.innerHTML.includes('deletion_marker') && !_this.isLoggedIn) {
                                        link.innerHTML = '<span class="deletion_marker"></span>';
                                        link.removeAttribute("href");
                                    } else if (link) {
                                        link.text = '';
                                        link.removeAttribute("href");
                                    }
                                }
                            });
                        }

                        //remove ac scope linking if feature flag is diabled
                        if(this.$getConst('contentTypeIccEsXml') === this.activeDocumentInfo.content_type.name && !this.isEsAcScopeAcEnabled()){
                            let acLinks = document.getElementById('contentParent').getElementsByClassName("es_ac_scope_reference");
                            _.forEach(acLinks, function(link) {
                                if (link) {
                                    let linkText = document.createTextNode(link.text);
                                    link.parentNode.insertBefore(linkText, link);
                                    link.remove();
                                }
                            })
                        } else {
                            let allAcs = document.getElementsByClassName('es_ac_scope_reference');
                            _.forEach(allAcs, function(acs) {
                                acs.setAttribute("id", acs.textContent);
                            })
                        }

                        if (!this.isLoggedIn) {
                            this.checkCopyPasteAccess(this.activeDocumentInfo.cp_public);
                        }

                        if('' === this.examId) {
                            this.applyTags(documentId, chapterId);
                            this.bindContentLinks();
                            this.applyTitleAttributes();
                        }
                    });
                    this.setExpandedActive();
                }

                if('' === this.examId) {
                    EventBus.fire('get-notes');
                }
            },
            divAstmParse(promulgatorDivs, documentId) {
                _.forEach(promulgatorDivs, function(promulgatorDiv) {
                    let promulgatorAcronyms = promulgatorDiv.querySelectorAll('p.promulgator_acronym');
                    _.forEach(promulgatorAcronyms, function(promulgatorAcronym) {
                        let stdTitle = promulgatorAcronym.firstChild.innerHTML;
                        if ('ASTM' === stdTitle) {
                            let aRefNumberTags = promulgatorDiv.querySelectorAll('a.section_reference_standard');
                            if (aRefNumberTags) {
                                _.forEach(aRefNumberTags, function(aRefNumberTag) {
                                    if (!aRefNumberTag.getAttribute('title')) {
                                        let linkText = document.createTextNode(aRefNumberTag.text);
                                        aRefNumberTag.parentNode.insertBefore(linkText, aRefNumberTag);
                                        if (aRefNumberTag) {
                                            aRefNumberTag.remove();
                                        }
                                    }
                                });
                            }
                            let divRefStds = promulgatorDiv.querySelectorAll('div.referenced_standards');
                            _.forEach(divRefStds, function(divRefStd) {
                                let pRefStdNumbers = divRefStd.querySelectorAll('p.reference_standard_number');
                                _.forEach(pRefStdNumbers, function(pRefStdNumber) {
                                    if (pRefStdNumber.getAttribute('data-astm-compass')) {
                                        let refNumber = pRefStdNumber.innerHTML;
                                        let astmRedStdId = pRefStdNumber.getAttribute('data-astm-compass');
                                        pRefStdNumber.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                            +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                            +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refNumber+"</a>";

                                        let pRefStdTitles = divRefStd.querySelectorAll('p.reference_standard_title');
                                        _.forEach(pRefStdTitles, function(pRefStdTitle) {
                                            let refTitle = pRefStdTitle.innerHTML;
                                            pRefStdTitle.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refTitle+"</a>";
                                        });
                                    }
                                });
                            });
                        }
                    });
                });
            },
            sectionAstmParse(promulgatorSections, documentId) {
                _.forEach(promulgatorSections, function(promulgatorDiv) {
                    let promulgatorAcronyms = promulgatorDiv.querySelectorAll('p.promulgator_acronym');
                    _.forEach(promulgatorAcronyms, function(promulgatorAcronym) {
                        let stdTitle = promulgatorAcronym.firstChild.innerHTML;
                        if ('ASTM' === stdTitle.trim()) {
                            let aRefNumberTags = promulgatorDiv.querySelectorAll('a.section_reference_standard');
                            if (aRefNumberTags) {
                                _.forEach(aRefNumberTags, function(aRefNumberTag) {
                                    if (!aRefNumberTag.getAttribute('title')) {
                                        let linkText = document.createTextNode(aRefNumberTag.text);
                                        aRefNumberTag.parentNode.insertBefore(linkText, aRefNumberTag);
                                        if (aRefNumberTag) {
                                            aRefNumberTag.remove();
                                        }
                                    }
                                });
                            }
                            let divRefStds = promulgatorDiv.querySelectorAll('div.referenced_standards');
                            if (divRefStds) {
                                _.forEach(divRefStds, function(divRefStd) {
                                    let divReferenceStds = divRefStd.querySelectorAll('div.reference_standard');
                                    if (divReferenceStds) {
                                        _.forEach(divReferenceStds, function(divReferenceStd) {
                                            let pRefStdNumbers = divReferenceStd.querySelectorAll('p.reference_standard_number');
                                            _.forEach(pRefStdNumbers, function(pRefStdNumber) {
                                                if (pRefStdNumber.getAttribute('data-astm-compass')) {
                                                    let refNumber = pRefStdNumber.innerHTML;
                                                    let astmRedStdId = pRefStdNumber.getAttribute('data-astm-compass');
                                                    pRefStdNumber.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                        +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                        +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refNumber+"</a>";

                                                    let pRefStdTitles = divReferenceStd.querySelectorAll('p.reference_standard_title');
                                                    _.forEach(pRefStdTitles, function(pRefStdTitle) {
                                                        let refTitle = pRefStdTitle.innerHTML;
                                                        pRefStdTitle.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                            +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                            +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refTitle+"</a>";
                                                    });
                                                }
                                            });
                                        });
                                    } else {
                                        let pRefStdNumbers = divRefStd.querySelectorAll('p.reference_standard_number');
                                        _.forEach(pRefStdNumbers, function(pRefStdNumber) {
                                            if (pRefStdNumber.getAttribute('data-astm-compass')) {
                                                let refNumber = pRefStdNumber.innerHTML;
                                                let astmRedStdId = pRefStdNumber.getAttribute('data-astm-compass');
                                                pRefStdNumber.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                    +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                    +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refNumber+"</a>";

                                                let pRefStdTitles = divRefStd.querySelectorAll('p.reference_standard_title');
                                                _.forEach(pRefStdTitles, function(pRefStdTitle) {
                                                    let refTitle = pRefStdTitle.innerHTML;
                                                    pRefStdTitle.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                        +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                        +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refTitle+"</a>";
                                                });
                                            }
                                        });
                                    }
                                });
                            } else {
                                let pRefStdNumbers = promulgatorDiv.querySelectorAll('p.reference_standard_number');
                                _.forEach(pRefStdNumbers, function(pRefStdNumber) {
                                    if (pRefStdNumber.getAttribute('data-astm-compass')) {
                                        let refNumber = pRefStdNumber.innerHTML;
                                        let astmRedStdId = pRefStdNumber.getAttribute('data-astm-compass');
                                        pRefStdNumber.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                            +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                            +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refNumber+"</a>";

                                        let pRefStdTitles = promulgatorDiv.querySelectorAll('p.reference_standard_title');
                                        _.forEach(pRefStdTitles, function(pRefStdTitle) {
                                            let refTitle = pRefStdTitle.innerHTML;
                                            pRefStdTitle.innerHTML = "<a section-number='"+pRefStdNumber.getAttribute("section-number")
                                                +"' data-astm-compass='"+astmRedStdId+"' astm-modal='"+astmRedStdId
                                                +"' class='reference_standard_reference' href='/lookup/"+astmRedStdId+"/"+documentId+"?isAstm=1'>"+refTitle+"</a>";
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
            },
            getChapterNotes(chapterId, filterTags = []) {
                this.$http.get(
                    Routing.generate("get_annotation_bookmarks", {'chapter_id': chapterId, 'action': 'chapter_notes', 'tag': filterTags})
                ).then(result => {
                    this.$store.commit('setNotes', result.data);
                    this.notes = result.data;
                    this.$nextTick(() => {
                        this.applyNotes();
                        this.addNoteList(result.data);
                    })
                });
            },
            setTags() {
                this.$http.get(Routing.generate('get_my_tags'))
                    .then(response => {
                        this.$store.commit('setTags', response.data);
                    });
            },
            updateNotes(options) {
                switch(options.action){
                    case 'update':
                        EventBus.fire('update-note-list-'+options.sectionId, options.notes);
                        EventBus.fire('get-tags');
                        break;
                    case 'create':
                        let wrapper = document.getElementById('notes-expansion-panel-wrapper-'+options.sectionId)
                        if (wrapper) {
                            EventBus.fire('update-note-list-'+options.sectionId, options.notes)
                        } else {
                            // If creating a note then expand the section.
                            this.addNoteList(options.notes, true)
                        }
                        EventBus.fire('get-tags');
                        break;
                    case 'remove':
                        if(options.type === 'bookmark'){
                            let bookmark = _.find(this.notes.bookmarks, function(item) {
                                return _.toInteger(item.id) === _.toInteger(options.id);
                            });
                        }
                        EventBus.fire('remove-notes'+options.sectionId, options);
                        break;
                }
                EventBus.fire('get-notes');
            },
            // Todo: removeHighlight method is being called multiple times from index.  Need to break out into a remove highlight method that accepts an array.
            clearContentAppliedNotes() {
                let _this = this;
                _.forEach(this.notes.annotations, function(note) {
                    if (note.section_number) {
                        let data = {
                            sectionId: note.section_number,
                            note: note,
                            tag: {
                                id: note.tag,
                                color: _this.getTagColor(note.tag)
                            }
                        };
                        _this.removeHighlight(data);
                    }
                });
            },
            removeElementsByClass(className){
                var elements = document.getElementsByClassName(className);
                while(elements.length > 0){
                    elements[0].parentNode.removeChild(elements[0]);
                }
            },
            filterChapterNotes(filterTags) {
                if(this.chapterid) {
                    this.clearContentAppliedNotes();
                    this.removeElementsByClass('notes-expansion-panel-wrapper');
                    this.getChapterNotes(this.chapterid, filterTags);
                }
            },
            getBookDescription() {
                this.$http.get(
                    Routing.generate("get_book_description", {documentId: this.documentid})
                ).then(result => {
                    if (result.data && '' !== result.data.description) {
                        let metaTags = document.getElementsByTagName('meta');
                        _.forEach(metaTags, function (value) {
                            if (value.getAttribute('name') === 'description') {
                                value.setAttribute('content', result.data.description.replace(/<[^>]+>/g, ''));
                            }
                        });
                    }
                });
            },
            applyTags(documentId, chapterId) {
                let _this = this;
                let chapterIdParam = ('toc' == chapterId) ? 0 : chapterId;
                this.$http.get(Routing.generate('get_tag_information', {documentId: documentId, chapterId: _.toInteger(chapterIdParam)}))
                    .then(response => {
                        this.section_tags = response.data['tags'];
                        let contentTagTypes = response.data['tagTypes'];
                        if (this.section_tags)  {
                            var did = [];
                            let isNewPremiumTagCategoriesEnabled =  this.isNewPremiumTagCategoriesEnabled();
                            _.each(this.section_tags, function (value, key) {
                                var ComponentClass = Vue.extend(Tag);
                                let contentId = value.content_id;
                                if (did.indexOf(contentId + value.tag_type) === -1) {
                                    var instance = new ComponentClass({
                                        propsData: {
                                            tagtype: value.tag_type,
                                            contentid: contentId,
                                            show_on_public: value.show_on_public,
                                            show_on_premium: value.show_on_premium,
                                            tagTypeShow: value.tag_type_show,
                                        }
                                    });
                                    instance.$mount();
                                    let span = document.getElementById('text-id-' + contentId);
                                    if (span) {
                                        //group tags rendering
                                        let tagsWrapper = document.getElementById('tags-wrapper-' + contentId);
                                        if(!tagsWrapper) {
                                            let pElement = document.createElement('p'); // Create a wrapper <p> element
                                            pElement.id = 'tags-wrapper-' + contentId;
                                            pElement.style = 'display: inline; margin-left: 4px'; // display tags in same line with section title
                                            pElement.appendChild(instance.$el);
                                            span.appendChild(pElement);
                                        } else {
                                            tagsWrapper.appendChild(instance.$el);
                                        }
                                        //render premium tag types
                                        if (isNewPremiumTagCategoriesEnabled && value.tag_type === 'premiumcontent') {
                                            let baseElement = document.getElementById('action-wrapper-' + contentId)
                                            let parentElement = baseElement.parentElement; // add premium tag type list next to parent
                                            let categories = contentTagTypes[contentId];
                                            if(parentElement && categories){
                                                let activeTag = {
                                                    title: 'Premium Code Insights',
                                                    type: 'premium',
                                                    shortName: 'P'
                                                };
                                                //add tag categories
                                                let DisplayTagCategoriesClass = Vue.extend(DisplayTagCategories);
                                                let instanceTagCategories = new DisplayTagCategoriesClass({
                                                    propsData: {
                                                        contentId: contentId,
                                                        tagType: value.tag_type,
                                                        categories: categories,
                                                        activeTag: activeTag,
                                                        reportTypeShow: value.tag_type_show,
                                                        meta: {
                                                            title: _this.getBookTitle(_this.activeDocumentInfo),
                                                            sectionTitle: value.sectionTitle
                                                        },
                                                        isMobile: _this.isMobilePort
                                                    },
                                                    store: store
                                                    });
                                                instanceTagCategories.$mount();
                                                let lastChild = baseElement.lastChild
                                                // If we have notes then add code insights before that
                                                if (lastChild?.classList.contains('notes-expansion-panel-wrapper')) {
                                                    lastChild.before(instanceTagCategories.$el)
                                                } else {
                                                    baseElement.appendChild(instanceTagCategories.$el);
                                                }
                                            }
                                        }
                                    }
                                    did.push(contentId + value.tag_type);
                                }

                            });
                            const hash = window.location.hash.replace('#', '')
                            let element = document.getElementById(hash)
                            if (element) {
                                EventBus.fire('scroll-to-target', element)
                            }
                        }
                    });
            },
            isSubscriptionRequired() {
                let subscriptionRequired =  !_.toNumber(this.contentPremium) &&
                    _.toNumber(this.bookAvailableToSubscribe) &&
                    _.toNumber(this.contentProtection);

                return _.toNumber(subscriptionRequired);
            },
            setStoreData() {
                this.activeContentPremium = this.contentPremium;
            },
        },
        async created() {

            EventBus.listen('get-content', (data) => this.getContent(data));
            EventBus.listen('content-protected', () => this.applyContentProtection());
            EventBus.listen('force-rerender-share-sections', ()=> this.modalKey +=1);

            // PRA-8954 - Selecting a section in the sidebar should "select" the section in chapter view
            this.$watch(() => this.$store.getters.getSelectedContentId, (newValue, oldValue) => {

                if (newValue) {
                    this.$nextTick(() => {
                        this.toggleSectionSelectForContent(newValue);
                    });

                    this.$nextTick(() => {
                        this.showSectionActionButtonsForContentId(newValue);
                        this.wrapDisplayTitle(newValue);
                    });
                }

            }, {immediate: true});

            // Code to add/remove canonical Tag
            this.addCanonicalTag();
            this.$store.commit('setIsDocumentPage', true);
            //don't call shop API for access level 13 and 14
            if (!_.includes(this.$getConst('withCollectionLevels'), this.contentAccessLevelId)) {
                this.getBookDescription();
            }

            if (this.isTrialActive == '1') {
                this.refreshLibrary();
            }
            let currentChapterId = (null != this.chapterid && !_.isUndefined(this.chapterid) && !_.isEmpty(this.chapterid)) ? this.chapterid : 0;
            await this.init(currentChapterId); // books.js
            if (this.type === 'xml') {
                if (!_.isEmpty(this.chapterid)) {
                    this.getContent(this.chapterid);
                }
                EventBus.listen('update-notes', (options)=> this.updateNotes(options));
                this.setTags(); // Set our tags for the store.
                EventBus.listen('filter-chapter-notes', (data)=> this.filterChapterNotes(data));
            }
            this.setStoreData();
            this.handleBackBtnClick;
        },
        mounted() {
            if (this.$vuetify.breakpoint.smAndDown) {
                EventBus.fire('from-book-content');
            }
            var hasSectionScrolled = false;
            var maxTryLimit = 0;
            function handleVisibilityChange() {
                AsyncUtils.awaitUntil(() => {
                  return !hasSectionScrolled && !document.hidden &&  maxTryLimit <= 10
                }).then(() => {
                        let hash = _.replace(window.location.hash, '#', '');
                        let element = document.getElementById(hash);
                        maxTryLimit += 1;
                        if (element) {
                            EventBus.fire('scroll-to-target', element);
                            hasSectionScrolled = true;
                        }
                });
            }
            document.addEventListener("visibilitychange", handleVisibilityChange, false);

            this.$watch(() => this.$store.getters.getTocMini, (newValue) => {

                setTimeout(() => {

                    let hash = _.replace(window.location.hash, '#', '');
                    let element = document.getElementById(hash);

                    if (element) {
                        EventBus.fire('scroll-to-target', element);
                    }

                }, 1000);
            });

            let lastTopSection = null;

            const debounceApiSaveCall = _.debounce(() => {
                UserBookProgressApi.save(this.activeDocumentInfo.id, lastTopSection, window.scrollY);
            }, 2500);

            AsyncUtils.setIntervalOnce(() => {

                const topSection = DomUtils.findFirstVisibleElement('#contentParent section', 181);
                if (topSection) {
                    const sectionNumber = parseInt(topSection.getAttribute('section-number'));
                    if (!isNaN(sectionNumber) && sectionNumber !== lastTopSection) {
                        lastTopSection = sectionNumber;

                        this.$store.commit('setCurrentSectionFromTop', sectionNumber);

                        debounceApiSaveCall();
                    }
                }

            }, 300, "titles/index/save");
        },
        updated() {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            if (params.get("search")) {
                let searchWords = params.get("search").split(" ");
                let highlightedText = new Mark('.chapter_styling');
                highlightedText.mark(searchWords);
            }
            if('' !== this.examId) {
                document.getElementById('chat-n-feedback').style.display = "none";
            }
        },
        destroyed() {
            this.$store.commit('setIsDocumentPage', false);
        }
    }
</script>

<style lang="scss" scoped>
    #contentParent{
        section {
            p {
                margin-right: 10px;
            }
            .note-list, p, h1, td {
                -ms-user-select: none;
                user-select: none;
            }
        }
        .section-action-wrapper {
            overflow-x: auto;
            .v-icon:hover {
                cursor: pointer;
            }
        }
        .search_back {
            cursor: pointer;
        }
        .no_highlight {
            background: white !important;
        }
        @media (max-width: 1200px) {
            .section-action-wrapper h1:first-of-type {
                margin-top: 30px !important;
                overflow: hidden;
            }
        }
        .section-action-buttons {
            position: absolute;
            top: 10px;
            right: 5px;
            cursor: pointer;
        }
        .note-action-buttons {
            position: fixed;
            width: 56px;
            height: 40px;
        }
        .note-action-buttons i {
            font-size: 16px;
        }
        .highlighter:hover {
            cursor: pointer;
        }
        .material-icons {
            -ms-user-select: none;
            user-select: none;
        }
        p:hover, h1:hover {
            cursor: text;
        }
        ::selection {
            background: #FFFF00;
        }
        ::-moz-selection {
            background: #FFFF00;
        }
        .trigger-section-action {
            position: absolute;
            right: 0;
        }
        .v-expansion-panel {
            list-style-type: none!important;
            .v-expansion-panel-content__wrap {
                padding: 0;
            }
        }
        .section-action-wrapper {
            .v-icon {
                color: rgba(0,0,0,.65)!important;
            }
            .v-icon--disabled {
                color: rgba(0,0,0,.38)!important;
            }
        }
    }
    @media print {
        .restrict_print {
            display: none !important;
        }
    }
    body #app .newIccStyles .deletion_marker {
        width: auto;
        padding: 12px 0px 0px 15px;
    }
    .migrate-compare {
        section {
            .note-list, p, h1, td {
                -ms-user-select: text !important;
                user-select: text !important;
            }
        }
    }
    ins {
        display: inline-block;
    }
</style>

<style lang="scss">
.section-action-wrapper.v-card--flat:hover {
    border-color: #0B5940;
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(11,89,64,.5), 0 2px 2px 0 rgba(11,89,64,.5), 0 1px 5px 0 rgba(11,89,64,.5);
}
.freeViewStyles .section-action-wrapper.v-card--flat:hover {
    box-shadow: none;
}
.icon_bookmark_border:before {
    content: "bookmark_border";
  }
.icon_apps:before {
    content: "apps";
}
.icon_border_color:before {
    content: "border_color";
}
.icon_link:before {
    content: "link";
}
.icon_local_printshop:before {
    content: "local_printshop";
}
@media (max-width: 599px) {
    .section-action-buttons {
        display: none !important;
    }
}
@media (min-width: 600px) {
    .section-action-button-mobile {
        display: none !important;
    }
}

</style>
