<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="text-body-2 font-weight-bold">
                    Comments ({{ comments.length }})
                </h3>
            </v-col>
        </v-row>
        <v-row v-if="notificationMessage">
            <v-col>
                <alert
                    :message="notificationMessage"
                    :notificationType="notificationType"
                    dismissible
                    @dismiss="dismissAlert">
                </alert>
            </v-col>
        </v-row>
        <comment
            v-for="(comment, key) in comments"
            :key="key"
            :comment="comment">
        </comment>
        <template v-if="canComment">
            <template v-if="addComment">
                <v-row
                    dense>
                    <v-col cols="auto">
                        <v-avatar
                            size="32"
                            color="grey lighten-3">
                            {{ userNameInitials }}
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <v-textarea
                            v-model="userComment"
                            label="Add a comment"
                            outlined
                            hide-details
                            dense>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            :disabled="!userComment.length || submittingComment"
                            depressed
                            color="primary"
                            @click="postComment()"
                            :loading="submittingComment">
                            Submit
                        </v-btn>
                        <v-btn
                            outlined
                            @click="addComment = false">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <v-row v-else>
                <v-col>
                    <v-btn
                        @click="addComment = true"
                        depressed>
                        Add Comment
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import Comment from "./Comment.vue";
    import {QuickConsultApi} from "../../../api/QuickConsultApi";
    import Alert from "../../notifications/AlertBar.vue";

    export default {
        data() {
            return {
                addComment: false,
                userComment: '',
                submittingComment: false,
                notificationMessage: '',
                notificationType: ''
            }
        },
        computed: {
            userNameInitials() {
                if (this.userFullName) {
                    const names = this.userFullName.split(' ');
                    return names.length > 1 ? names[0].charAt(0) + names[1].charAt(0) : names[0].charAt(0);
                }
            }
        },
        components: {Alert, Comment},
        props: {
            questionId: {
                type: String,
                required: true
            },
            comments: {
                type: Array,
                required: true
            },
            canComment: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            postComment() {
                this.submittingComment = true;
                QuickConsultApi.postComment(this.questionId, {comment: this.userComment}).then(() => {
                    this.addComment = false;
                    this.comments.push({
                        name: this.userFullName,
                        body: `<p>${this.userComment}</p>`,
                        date: new Date()
                    });
                    this.userComment = '';
                    this.notificationType = 'success';
                    this.notificationMessage = 'Your comment has been added for the ICC team to review.';
                }).catch((error) => {
                    if (!error.response) {
                        this.notificationMessage = 'Network error: Something went wrong or you are currently offline.';
                    } else {
                        this.notificationMessage = error.response.data?.error || 'An unexpected error occurred.';
                    }
                    this.notificationType = 'error';
                }).finally(() => {
                    this.submittingComment = false;
                });
            },
            dismissAlert() {
                this.notificationMessage = '';
                this.notificationType = '';
            }
        }
    }
</script>