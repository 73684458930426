var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        scrollable: "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "primary mobile-sidebar-nav" },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "close mt-1",
                              attrs: { text: "", fab: "", small: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.dialog = !_vm.dialog
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "accent2--text" }, [
                                _vm._v("clear"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "ma-0" }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list",
                            { staticClass: "pt-0 primary" },
                            [
                              _vm._l(_vm.items, function (item) {
                                return item.href
                                  ? _c(
                                      "v-list-item",
                                      {
                                        key: item.title,
                                        staticClass: "pa-0",
                                        attrs: {
                                          href: item.href,
                                          title: item.title,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          {
                                            on: {
                                              mouseover: function ($event) {
                                                return _vm.toggle()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "white--text" },
                                              [_vm._v(_vm._s(item.icon))]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "white--text" },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-item",
                                      {
                                        key: item.title,
                                        staticClass: "pa-0",
                                        attrs: { title: item.title },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showModal()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "white--text" },
                                              [_vm._v(_vm._s(item.icon))]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "white--text" },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-list-item-action",
                                    {
                                      on: {
                                        mouseover: function ($event) {
                                          return _vm.toggle()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "accent2--text",
                                          style: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToRoute(
                                                "favorites_landing"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("favorite")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "white--text",
                                          style: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToRoute(
                                                "favorites_landing"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Favorites")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.favorites, function (book) {
                                return _c(
                                  "div",
                                  { key: book.id, staticClass: "pa-0" },
                                  [
                                    _c("v-list-item-content", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "white--text cp favorites_item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToBook(
                                                book.document_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.getBookTitle(
                                                  book.document_id
                                                )
                                              )
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "sidebar-printing-info",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getBookPrintingInfo(
                                                    book.document_id
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "white--text cp",
                                  attrs: { id: "sidebar-see-all" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToRoute("favorites_landing")
                                    },
                                  },
                                },
                                [_vm._v("See all favorites")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }