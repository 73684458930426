var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("back-to-main-menu", { on: { backToMain: _vm.backToMain } }),
      _vm._v(" "),
      _c(
        "v-list-item",
        { staticClass: "mt-2" },
        [
          _vm._v("\n        Favorites\n        "),
          !_vm.isActiveUser
            ? [
                _c("br"),
                _vm._v(
                  "\n            Sign in to view your favorites\n        "
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isActiveUser
        ? [
            _c("link-list-icons", {
              attrs: {
                links: [
                  {
                    icon: "mdi-heart-cog-outline",
                    title: "Manage Favorites",
                    link: this.getRoute("favorites_landing"),
                    active: true,
                  },
                ],
              },
            }),
            _vm._v(" "),
            _vm.favoritesLoading
              ? _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { staticClass: "my-2" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "text-center" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        size: "16",
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._l(_vm.favorites, function (book, key) {
                  return _c(
                    "v-tooltip",
                    {
                      key: key,
                      attrs: { right: "", "z-index": "203" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    { attrs: { href: book.title.link } },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(book.title.display_title) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", { staticClass: "tooltip-content" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(book.title.display_title) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              book.title.is_astm
                                ? book.title.subtitle
                                : book.title.print_version
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
          ]
        : _c(
            "v-list-item",
            { attrs: { href: _vm.getLoginUrl() } },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("\n                mdi-exit-to-app\n            "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-title",
                { staticClass: "fs-16 font-weight-bold" },
                [_vm._v("\n            Sign In\n        ")]
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }