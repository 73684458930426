<template>
    <div>
        <h3>Payment Method</h3>
        <p v-if="!invalidVisaCart">{{ visaCardNumber }}</p>
        <alert-bar
            class="mt-3"
            v-if="invalidVisaCart"
            id="display_http_messages"
            :message="`Payment method information not found. <a href='${getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                        Update your payment method</a> to continue.`"
            notificationType="warning">
        </alert-bar>
    </div>
</template>

<script>
    import AlertBar from "../notifications/AlertBar.vue";
    import SubscriptionChangeMixin from "./subscription-update/subcriptionChange.mixin";

    export default {
        mixins: [SubscriptionChangeMixin],
        components: {
            AlertBar
        },
        props: {
            showAlert: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>