var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "grey lighten-3 px-6" },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("h3", { staticClass: "font-weight-regular" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.icc-es.org/",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Visit ICC-ES Website\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("p", { staticClass: "caption mb-0 font-weight-bold" }, [
                  _vm._v("\n                    ( "),
                  _c("span", { staticClass: "error--text" }, [_vm._v("*")]),
                  _vm._v(
                    " sign indicates the report as featured. )\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "items-iterator" },
        [
          _vm._l(_vm.data, function (reportTag, key) {
            return [
              reportTag.tag.is_active
                ? _c(
                    "v-container",
                    { staticClass: "px-6 item-row" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: reportTag.tag.url,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(reportTag.tag.file_report_no) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              reportTag.tag.is_featured
                                ? _c("span", { staticClass: "red--text" }, [
                                    _vm._v("*"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("p", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: reportTag.tag.url,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(reportTag.tag.company) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("p", [_vm._v(_vm._s(reportTag.tag.product))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }