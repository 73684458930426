var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  label: "All Project Tags",
                  items: _vm.tag_list,
                  "item-value": "id",
                  "item-text": "label",
                  solo: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.filterResult($event, "tag")
                  },
                },
                model: {
                  value: _vm.tag,
                  callback: function ($$v) {
                    _vm.tag = $$v
                  },
                  expression: "tag",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  label: "All Categories",
                  items: _vm.category_list,
                  solo: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.filterResult($event, "category")
                  },
                },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  label: "All My Library Titles",
                  items: _vm.book_list,
                  "item-text": "book",
                  solo: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.filterResult($event, "book")
                  },
                },
                model: {
                  value: _vm.book,
                  callback: function ($$v) {
                    _vm.book = $$v
                  },
                  expression: "book",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: {
                    color: "primary",
                    large: "",
                    block: "",
                    title: "Reset All Filters",
                  },
                  on: { click: _vm.resetFilter },
                },
                [_vm._v("\n                Reset\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-0",
                  attrs: {
                    color: "primary",
                    block: _vm.$vuetify.breakpoint.smAndDown,
                  },
                  on: { click: _vm.showRejectSharedWithMeModal },
                },
                [_vm._v("Reject Notes\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-0",
                  attrs: {
                    color: "primary",
                    block: _vm.$vuetify.breakpoint.smAndDown,
                  },
                  on: { click: _vm.showAcceptSharedWithMeModal },
                },
                [_vm._v("Accept Notes\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "" } },
                [
                  _c(
                    "v-data-iterator",
                    {
                      staticClass: "sharing-data-iterator",
                      attrs: {
                        items: _vm.filteredResults,
                        "items-per-page": _vm.pagination.rowsPerPage,
                      },
                      on: {
                        "update:itemsPerPage": function ($event) {
                          return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                        },
                        "update:items-per-page": function ($event) {
                          return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c(
                                "v-expansion-panel",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function () {
                                          return [
                                            _vm.isPremiumSubscribed(
                                              data.item.info.sku
                                            )
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkPanelEvent(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("expand_more")]
                                                )
                                              : _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: {
                                                      small: "",
                                                      href: data.item.info
                                                        .document_url,
                                                      target: "_blank",
                                                      color: "amber darken-2",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    Get Premium Subscription\n                                "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-header",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            dense: _vm.isMobilePort,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "notes_book_link",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.bookLink(
                                                        data.item.info
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("graphic", {
                                                    attrs: {
                                                      title: data.item.info,
                                                      thumbnail: "",
                                                      width: "70px",
                                                      additionalStyles:
                                                        "max-width: 70px;",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "notes_book_link",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bookLink(
                                                      data.item.info
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("book-title", {
                                                  staticClass:
                                                    "data-notes-titles ml-2",
                                                  staticStyle: {
                                                    "min-width": "100%",
                                                  },
                                                  attrs: {
                                                    title: _vm.getBookTitle(
                                                      data.item.info
                                                    ),
                                                    print_version_edition:
                                                      _vm.printEdition(
                                                        data.item.info
                                                          .print_version_edition
                                                          .version
                                                      ),
                                                    printing_text:
                                                      data.item.info.print_info,
                                                    print_version:
                                                      data.item.info
                                                        .print_version,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _vm.isPremiumSubscribed(
                                                data.item.info.sku
                                              )
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "v-label theme--light mb-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.countNotesInChapter(
                                                              data.item.chapter
                                                            )
                                                          ) + " Notes"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-checkbox", {
                                                      key: data.item.info.id,
                                                      staticClass: "ma-0 pa-0",
                                                      attrs: {
                                                        "hide-details": "",
                                                        value:
                                                          data.item.info.id,
                                                        label:
                                                          "Select all notes",
                                                        checked: "false",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectBookSections(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedBookValues
                                                            .books,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedBookValues,
                                                            "books",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedBookValues.books",
                                                      },
                                                    }),
                                                  ]
                                                : [
                                                    _c("i", [
                                                      _vm._v(
                                                        "*You need to first purchase the premium subscription for this book to accept the shared content."
                                                      ),
                                                    ]),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticClass: "sharing-expand",
                                      attrs: { color: "grey lighten-4" },
                                    },
                                    _vm._l(
                                      data.item.chapter,
                                      function (chapters, chapterId) {
                                        return _c(
                                          "div",
                                          { key: chapterId },
                                          _vm._l(
                                            chapters,
                                            function (sections, sectionId) {
                                              return _c(
                                                "div",
                                                { key: sectionId },
                                                _vm._l(
                                                  sections,
                                                  function (result, noteId) {
                                                    return result instanceof
                                                      Object
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            key: noteId,
                                                            staticClass: "ma-0",
                                                            attrs: {
                                                              align: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      outline:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-title",
                                                                      [
                                                                        _c(
                                                                          "h3",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1 ml-1",
                                                                            class:
                                                                              {
                                                                                title:
                                                                                  !_vm.isMobilePort,
                                                                                "body-2":
                                                                                  _vm.isMobilePort,
                                                                              },
                                                                            staticStyle:
                                                                              {
                                                                                cursor:
                                                                                  "pointer",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.redirectToSection(
                                                                                    data
                                                                                      .item
                                                                                      .info
                                                                                      .document_id,
                                                                                    chapterId,
                                                                                    sectionId
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  result.section
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dense:
                                                                                  _vm.isMobilePort,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                result.type ==
                                                                                  "note" ||
                                                                                result.type ==
                                                                                  "annotation"
                                                                                  ? _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "note-list-quote font-italic pa-1 mb-0 mt-1",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              result.content
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm.getDescription(
                                                                                  result
                                                                                )
                                                                                  ? _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mt-2 ml-1",
                                                                                        domProps:
                                                                                          {
                                                                                            innerHTML:
                                                                                              _vm._s(
                                                                                                _vm.getDescription(
                                                                                                  result
                                                                                                )
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "auto",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        depressed:
                                                                                          "",
                                                                                        fab: "",
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              _vm.getTagColor(
                                                                                                result.tagId
                                                                                              ),
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                        " +
                                                                                            _vm._s(
                                                                                              _vm.getIcon(
                                                                                                result.type
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Project Tag: " +
                                                                                        _vm._s(
                                                                                          _vm.getTagName(
                                                                                            result.tagId
                                                                                          )
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Shared By: "
                                                                                    ),
                                                                                    _c(
                                                                                      "i",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            result.creatorName
                                                                                          ) +
                                                                                            " on " +
                                                                                            _vm._s(
                                                                                              result.request_date
                                                                                            )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                                attrs: {
                                                                  cols: "12",
                                                                  sm: "1",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-label",
                                                                  {
                                                                    staticClass:
                                                                      "grey darken-4 px-2",
                                                                    class: {
                                                                      caption:
                                                                        _vm.isMobilePort,
                                                                    },
                                                                    attrs: {
                                                                      for:
                                                                        "checkbox_" +
                                                                        result.id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    Select for Accept and/or Reject\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                result.type ==
                                                                  "annotation" ||
                                                                result.type ==
                                                                  "highlight"
                                                                  ? _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0 ma-0",
                                                                        class: {
                                                                          "body-2":
                                                                            _vm
                                                                              .$vuetify
                                                                              .breakpoint
                                                                              .smAndDown,
                                                                        },
                                                                        attrs: {
                                                                          id:
                                                                            "checkbox_" +
                                                                            result.id,
                                                                          "hide-details":
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          width:
                                                                            "auto",
                                                                          value:
                                                                            "annotation_" +
                                                                            result.id,
                                                                        },
                                                                        nativeOn:
                                                                          {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.childChecked(
                                                                                  chapterId,
                                                                                  "annotation_" +
                                                                                    result.id
                                                                                )
                                                                              },
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .selectedBookValues
                                                                              .sections,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.selectedBookValues,
                                                                                "sections",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "selectedBookValues.sections",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                result.type ==
                                                                "bookmark"
                                                                  ? _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0 ma-0",
                                                                        class: {
                                                                          "body-2":
                                                                            _vm
                                                                              .$vuetify
                                                                              .breakpoint
                                                                              .smAndDown,
                                                                        },
                                                                        attrs: {
                                                                          id:
                                                                            "checkbox_" +
                                                                            result.id,
                                                                          "hide-details":
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          width:
                                                                            "auto",
                                                                          value:
                                                                            "bookmark_" +
                                                                            result.bookmarkId,
                                                                        },
                                                                        nativeOn:
                                                                          {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.childChecked(
                                                                                  chapterId,
                                                                                  "bookmark_" +
                                                                                    result.bookmarkId
                                                                                )
                                                                              },
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .selectedBookValues
                                                                              .sections,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.selectedBookValues,
                                                                                "sections",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "selectedBookValues.sections",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "no-data" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "text-center" },
                                [
                                  _vm.filteredResults.length == 0 && _vm.load
                                    ? [_c("p", [_vm._v("No Results Found")])]
                                    : [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "primary",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-process-received-annotation-bookmarks"),
      _vm._v(" "),
      _c("back-to-top"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }