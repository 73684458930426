var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("p", { staticClass: "primary--text caption mb-0" }, [
              _vm._v(
                "\n                Personal Use licenses are those where the individual who purchases the premium subscription is the individual who is able to use the premium subscription.  Personal use licenses provide dedicated access to the individual as identified by the email address, but can be configured to be assigned to another individual or made concurrent.\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "" } },
        [
          _c("v-data-iterator", {
            staticClass: "elevation-0 no-margin parent-row",
            attrs: {
              items: _vm.subscriptions,
              "items-per-page": _vm.pagination.rowsPerPage,
              loading: _vm.loading,
              "single-expand": true,
              "footer-props": {
                itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (data) {
                  return [
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "", align: "center" } },
                              [
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.bookLink(
                                            data.item.document_info
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("graphic", {
                                        attrs: {
                                          title: data.item.document_info,
                                          thumbnail: "",
                                          width: "40px",
                                          additionalClasses: "mr-2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-col", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.bookLink(
                                            data.item.document_info
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("book-title", {
                                        attrs: {
                                          title: _vm.getBookTitle(
                                            data.item.document_info
                                          ),
                                          print_version_edition:
                                            _vm.printEdition(
                                              data.item.document_info
                                                .print_version_edition.version
                                            ),
                                          printing_text:
                                            data.item.document_info.print_info,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-expansion-panel-content",
                          { staticClass: "grey lighten-4" },
                          [
                            _c("v-data-table", {
                              staticClass: "no-margin details-row",
                              attrs: {
                                headers: _vm.license,
                                items: data.item.group_licenses,
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "green lighten-5 text-center",
                                          },
                                          [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-bold mb-0",
                                                        },
                                                        [_vm._v("Licenses")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            item
                                                              .license_configuration
                                                              .available
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Subscription Type"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.checkSubscriptionType(
                                                              item.subscription_type
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("v-col", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Subscription Status"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.checkStatus(
                                                              item
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Purchase Date"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              item.purchase_date,
                                                              "MMMM Do, YYYY"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("v-col", [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "caption font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Subscription Status"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm._f("moment")(
                                                              item.expiration_date,
                                                              "MMMM Do, YYYY"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.goToTheShop(
                                                                  _vm.shopEncodesUrl
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            item.subscription_type ===
                                                            "M"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "Manage"
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    "Renew"
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }