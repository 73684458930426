var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dummy-content-results" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c(
              "p",
              { staticClass: "fs-20 my-3" },
              [
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(this.pagination.totalResults.toLocaleString()) +
                      " results for\n                    "
                  ),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                       Means Egress\n                    "
                    ),
                  ]),
                ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        _vm._l(_vm.results, function (result, key) {
          return _c(
            "v-col",
            { key: key, staticClass: "py-2", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "mb-0 pb-0" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "float-right content-search-action-icons",
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "waffle-container" },
                                  [_vm._v("apps")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "content-result-row" },
                              [
                                _c("item-header", {
                                  attrs: {
                                    title: result.highlight.title,
                                    meta: result,
                                    documentId: result.documentid,
                                    breadcrumbs: result.breadcrumbs,
                                    search: {},
                                  },
                                }),
                                _vm._v(" "),
                                _c("item-content", {
                                  attrs: {
                                    title: result.highlight.title,
                                    content: result.highlight.content[0],
                                    sectionId: result.contentid,
                                    type: result.bookcontenttype,
                                    breadcrumbs: result.breadcrumbs,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "mt-0 pt-0" }, [
                            _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                _c("item-breadcrumb", {
                                  attrs: {
                                    meta: result,
                                    breadcrumbs: result.breadcrumbs,
                                    documentId: result.documentid,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.pagination.totalResults
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("pagination-pages", {
                    attrs: { pagination: _vm.pagination },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-overlay",
        {
          staticClass: "dummy-content-results-overlay",
          attrs: {
            "z-index": "10",
            value: _vm.showOverlay,
            absolute: "",
            light: "",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "white" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "font-weight-bold oxygen fs-24 black--text",
                  class: {
                    "caption mobile-title": _vm.isMobilePort,
                  },
                  style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "accent2" } },
                    [
                      _vm._v(
                        "\n                        mdi-creation\n                    "
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n                    Subscribe to Premium\n                "
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0 pb-2" },
                [
                  _c(
                    "v-row",
                    { staticClass: "grey lighten-4 ma-auto" },
                    [
                      _c("v-col", { staticClass: "text-center pa-0" }, [
                        _c("img", {
                          staticClass: "my-1",
                          attrs: { alt: "Code Insight", src: _vm.imageSrc },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              {
                                staticClass: "text-center mb-0",
                                staticStyle: { "max-width": "450px" },
                              },
                              [
                                _vm._v(
                                  "\n                               Search for code sections from any of your Premium titles with advanced search\n                               parameters and filters. Bookmark, print, copy and share any section of code\n                               directly from search results.\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold px-4 black--text",
                                  attrs: { depressed: "", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToRoute("premium_features")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Learn More\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isActiveUser || _vm.canStartTrial
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold px-4 black--text",
                                      attrs: {
                                        depressed: "",
                                        color: "accent2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToRoute("trial_signup")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Start Free Trial\n                        "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold px-4 black--text",
                                      attrs: {
                                        depressed: "",
                                        color: "accent2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPremiumPage()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Subscribe to Premium\n                        "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }