/**
 * @typedef UserBookProgress
 * @property {string} created_at
 * @property {int} document_id
 * @property {int} section_id
 * @property {string} section_title
 * @property {string} continue_url
 */
import axios from "axios";

export class UserBookProgressApi {

    /**
     *
     * @return {Promise<UserBookProgress>}
     */
    static fetch(documentId) {

        const routeWithParams = Routing.generate('get_book_progress', {
            'documentId': documentId
        });

        return axios.get(routeWithParams).then((res) => {
            /** @type {UserBookProgress} */
            const data = res.data?.progress;
            return data;
        })
    }

    static save(documentId, sectionId, scrollTop) {

        const routeWithParams = Routing.generate('save_book_progress', {
            'documentId': documentId
        });

        return axios.post(routeWithParams, {
            section: sectionId,
            top: scrollTop
        });
    }

    static clear(documentId) {

        const routeWithParams = Routing.generate('clear_book_progress', {
            'documentId': documentId
        });

        return axios.delete(routeWithParams);
    }
}
