var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "white rounded-xl", attrs: { flat: "", outlined: "" } },
        [
          _c("v-card-title", { staticClass: "py-2" }, [
            _c(
              "div",
              { staticClass: "flex-grow-1" },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("\n                    mdi-web\n                "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-h6 font-weight-bold" }, [
                  _vm._v("Activity Report"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column flex-md-row justify-end" },
              [
                _c("v-text-field", {
                  staticClass: "ma-2 rounded-xl shrink",
                  attrs: {
                    dense: "",
                    clearable: "",
                    "append-icon": "mdi-magnify",
                    label: "Search here",
                    outlined: "",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "rounded-xl ma-2",
                  staticStyle: { "max-width": "200px" },
                  attrs: {
                    dense: "",
                    outlined: "",
                    "hide-details": "",
                    items: _vm.dateRanges,
                    "item-text": "label",
                    "item-value": "value",
                    label: "Select Time Frame",
                    "return-object": "",
                  },
                  on: { change: _vm.checkCustom },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text rounded-xl ma-2",
                    attrs: { disabled: !_vm.activity.length, outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.getEnterpriseActivity(true)
                      },
                    },
                  },
                  [
                    _c("v-icon", [
                      _vm._v(
                        "\n                        mdi-file-download-outline\n                    "
                      ),
                    ]),
                    _vm._v(
                      "\n                    Export full PDF\n                "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.dateRangeMessage
            ? _c("p", { staticClass: "pa-2 caption" }, [
                _c("span", { staticClass: "custom-dates" }, [
                  _vm._v(_vm._s(_vm.dateRangeMessage)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activity.length
            ? _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  search: _vm.search,
                  items: _vm.activity,
                  "items-per-page": 5,
                  "hide-default-footer": _vm.activity.length < 5,
                },
              })
            : _c(
                "v-card-text",
                { staticClass: "text-center subtitle-1 title pa-4" },
                [
                  _vm.loading
                    ? [_vm._v("Loading...")]
                    : [_vm._v("No data available")],
                ],
                2
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("activity-date-range"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }