<template>
    <div class="grey lighten-5 container-height">
        <v-container :class="!isMobileAndTablet ? 'algin-center justify-center' : ''">
        <v-row align="center" justify="center">
            <v-col class="d-flex justify-center">
                <v-card flat :width="isMobileAndTablet ? 800 : 700" class="grey lighten-5">
                    <v-card-title class="justify-center">
                        <v-img
                            max-width="250px"
                            height="95px"
                            :src="asset(require('../../images/enterprise/ent-premium-blue-logo.png'), 'default')"
                        />
                    </v-card-title>
                    <v-card-title class="headline font-weight-bold mb-3" :class="isMobileAndTablet ? 'ml-4' : ''">
                        Create your Digital Code account
                    </v-card-title>
                    <v-card-subtitle class="subtitle-1 mb-0" :class="isMobileAndTablet ? 'ml-4' : ''">
                        <p class="black--text">
                            Welcome {{ inviteFirstname }}, you have been invited to join
                            <b>{{ inviteEnterprise }}</b>
                        </p>
                    </v-card-subtitle>
                    <v-card-text>
                        <registration-form
                            :isEnterprise="true"
                            :enterpriseData="enterpriseData">
                        </registration-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
    import RegistrationForm from "./RegistrationForm";
    import Mobile from './../inc/mixins/mobile';

    export default {
        data() {
            return {
                enterpriseData:
                {
                    inviteFirstname: this.inviteFirstname,
                    inviteLastname: this.inviteLastname,
                    inviteEmail: this.inviteEmail,
                    inviteEnterprise: this.inviteEnterprise
                }
            };
        },
        props: {
            inviteFirstname: {
                required: false,
                type: String,
                default: ''
            },
            inviteLastname: {
                required: false,
                type: String,
                default: ''
            },
            inviteEmail: {
                required: false,
                type: String,
                default: ''
            },
            inviteEnterprise: {
                required: false,
                type: String,
                default: ''
            }
        },
        components: {
            RegistrationForm
        },
        mixins: [
            Mobile
        ]
    }
</script>
