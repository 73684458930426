var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-dialog", {
    ref: "container",
    attrs: { "max-width": "700" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "v-btn",
              _vm._g(
                _vm._b(
                  { attrs: { text: "", icon: "", color: "blue lighten-2" } },
                  "v-btn",
                  attrs,
                  false
                ),
                on
              ),
              [_c("v-icon", [_vm._v("mdi-link-variant")])],
              1
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function (dialog) {
          return [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "primary white--text headline" },
                  [
                    _vm._v("\n        Enterprise Invite Link\n        "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2 accent2--text",
                        attrs: { small: "" },
                        on: { click: () => (dialog.value = false) },
                      },
                      [_vm._v("close")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticClass: "pt-0" },
                  [
                    _vm.alert_message
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      light: "",
                                      dismissible: "",
                                      value: _vm.alert_status,
                                      type: _vm.alert_type,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.alert_message) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-textarea", {
                              attrs: {
                                id: "inviteModel-" + _vm.token,
                                solo: "",
                                rows: "5",
                                "auto-grow": "",
                                "hide-details": "",
                                value: _vm.url,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          "data-qa": "share-section-modal-close",
                          color: "primary",
                          text: "",
                        },
                        on: { click: () => (dialog.value = false) },
                      },
                      [_vm._v("\n          Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.copyURL },
                      },
                      [_vm._v(" Copy URL ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }