var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "exam-feature-container",
      class: {
        "px-16": !_vm.isMobilePort,
        "py-10": !_vm.isMobilePort,
        "py-8": _vm.isMobilePort,
      },
    },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center px-20" }, [
                _c("h1", {
                  staticClass: "font-weight-regular mb-5 roboto",
                  domProps: { innerHTML: _vm._s(_vm.sectionData.title) },
                }),
                _vm._v(" "),
                _vm.sectionData.description
                  ? _c("h2", {
                      staticClass: "font-weight-regular",
                      domProps: {
                        innerHTML: _vm._s(_vm.sectionData.description),
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          (_vm.sectionData.features.length &&
            _vm.sectionData.displayType === "list") ||
          _vm.isMobilePort
            ? _c(
                "v-row",
                _vm._l(_vm.sectionData.features, function (feature) {
                  return _c(
                    "v-col",
                    {
                      key: feature.title,
                      attrs: { cols: !_vm.isMobilePort ? 6 : 12 },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("img", {
                              attrs: {
                                width: "50",
                                src: _vm.asset(
                                  require("../../../images/" + feature.src),
                                  "default"
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("h4", { staticClass: "mb-3 primary--text" }, [
                              _vm._v(
                                "\n                           " +
                                  _vm._s(feature.title) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(feature.description) +
                                  "\n                        "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.sectionData.features.length &&
          _vm.sectionData.displayType === "tile" &&
          !_vm.isMobilePort
            ? _c(
                "v-row",
                _vm._l(_vm.sectionData.features, function (feature) {
                  return _c(
                    "v-col",
                    { key: feature.title, attrs: { cols: "4" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "auto" } },
                            [
                              _c("img", {
                                attrs: {
                                  width: "50",
                                  src: _vm.asset(
                                    require("../../../images/" + feature.src),
                                    "default"
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c("v-col", { staticClass: "text-center" }, [
                            _c("h4", { staticClass: "mb-3 primary--text" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(feature.title) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(feature.description) +
                                  "\n                        "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }