var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticClass: "primary--text display-1" }, [
              _vm._v(_vm._s(_vm.categoryTitle)),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", [_c("v-col", [_c("p", [_vm._v(_vm._s(_vm.tagTitle))])])], 1),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "8" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("pdf-viewer", {
                                attrs: {
                                  youtubeid: _vm.youtubeId,
                                  buylink: _vm.buylink,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }