<template>
    <v-container fluid class="grey lighten-5" data-qa="enterprise-account-overview">
        <enterprise-name></enterprise-name>
        <v-row class="mx-2">
            <!-- <v-col
                v-if="isLargeAndUp"
                lg="auto">
                <enterprise-nav>
                </enterprise-nav>
            </v-col> -->
            <v-col>
                <enterprise-nav :handleTabClick="tabClicked" :tab="0">
                </enterprise-nav>
            </v-col>
        </v-row>
        <v-row class="mx-2">
            <v-col>
                <keep-alive>
                    <account-overview v-if="panel === 'Account Overview'"></account-overview>
                    <manage-users v-else-if="panel === 'Manage Users'"></manage-users>
                    <activity-report v-else :profile-id="profileId"></activity-report>
                </keep-alive>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import EnterpriseNav from './../partials/enterprise_nav';
    import AccountOverview from './partials/account_overview';
    import ManageUsers from './../users/partials/manage_users';
    import ActivityReport from './../activity/partials/activity_report';
    import EnterpriseName from './../partials/enterprise_name';

    export default {
        props: ["profileId"],
        data() {
            return {
                panel: 'Account Overview',
                enterpriseDetails: {}
            }
        },
        mixins: [Mobile],
        components: {
            EnterpriseNav,
            AccountOverview,
            ManageUsers,
            ActivityReport,
            EnterpriseName
        },
        methods: {
            tabClicked(activeTab){
                this.panel = activeTab;
            },
        },
        computed: {
            getActivePanel() {
                return this.panel;
            },
            getEnterpriseName(){
                return this.enterpriseDetails.enterpriseName;
            }
        },
    }
</script>
