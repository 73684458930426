<template>
    <div>
        <v-dialog
            v-model="dialog"
            width="650">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Subscription Required
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        class="accent2--text"
                        @click="dialog = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-container class="lighten-3 px-3">
                    <v-row>
                        <v-col cols="12" align="center" class='pt-0 pb-3'>
                            <div>
                                <img :src="asset(require('../../images/icc_digital_codes_premium.png'))"/>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class='pt-0 pb-2'>
                            <p align="center">
                                <template v-if="isPremiumComplete">
                                    This title is bundled exclusively with a Premium Complete subscription.
                                </template>
                                <template v-else-if="isPremiumBundled">
                                    This title is bundled exclusively with Premium Collections and Premium Complete.
                                </template>
                                <template v-else>
                                    The feature you are trying to access is part of a Digital Codes Premium subscription.
                                    Upgrade now to unlock dozens of advanced digital tools.
                                </template>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center" cols="12" class='pt-0 pb-2'>
                            <div>
                                <v-btn
                                    v-if="!trialActive
                                        && !isPremiumComplete
                                        && !isPremiumBundled"
                                    dark
                                    color="accent2"
                                    @click="goToRoute('trial_signup')"
                                    data-aq="start-premium-trial-button">
                                    Start Premium Trial
                                </v-btn>
                                <v-btn
                                    v-if="!isPremiumComplete
                                        && !isPremiumBundled
                                        && this.documentId"
                                    dark
                                    color="accent2"
                                    @click="openBuyModal()"
                                    data-aq="subscribe-book-button">
                                    Subscribe
                                </v-btn>
                                <v-btn
                                    v-else
                                    dark
                                    color="accent2"
                                    @click="goToRoute('codes')"
                                    data-aq="browse-titles-button">
                                    Browse Titles
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                dialog: false,
                documentId: '',
                isPremiumBundled: false,
                isPremiumComplete: false
            };
        },
        mixins: [Mobile],
        methods: {
            showDialog(data = null) {
                if (data) {
                    this.documentId = data.documentId;
                    this.isPremiumBundled = data.isPremiumBundled;
                    this.isPremiumComplete = data.isPremiumComplete;
                }
                this.dialog = true;
            },
            openBuyModal() {
                if (this.isPremiumComplete || this.isPremiumBundled) {
                    this.$http.get(Routing.generate('get_document_by_sku', {sku: this.$getConst('premiumCompleteSku')}))
                        .then(response => {
                            let data = {
                                document: response.data,
                                request: 'complete'
                            }
                            window.location.href = Routing.generate("content_document_slug", {
                                documentSlug: data.document.document_slug
                            });
                        });
                } else {
                    this.$http.get(Routing.generate('get_document_by_documentid', {documentId: this.documentId}))
                        .then(response => {
                            let document = response.data;
                            if (document) {
                                window.location.href = Routing.generate("content_document_slug", {
                                    documentSlug: document.document_slug
                                });
                            }
                        });
                }
                this.$nextTick(() => {
                    this.dialog = false;
                });
            },
        },
        created() {
            EventBus.listen("show-subscription-modal", (data) => this.showDialog(data));
        }
    };
</script>

<style>
    .image-div {
        position: relative;
    }
</style>
