var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modal
    ? _c(
        "v-sheet",
        {
          class: _vm.isMobilePort
            ? "premium-information-model-mobile"
            : "premium-information-model",
          attrs: { "hide-overlay": "", "max-width": "550px" },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "fill-height",
              class: { "rounded-0": _vm.isMobilePort },
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary py-1 px-4",
                  staticStyle: { "word-break": "unset" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-16 mb-0 font-weight-medium white--text",
                          },
                          [
                            _vm._v(
                              "\n                        How does Premium work in Digital Codes?\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", color: "white" },
                              on: { click: _vm.close },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "\n                            mdi-close\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pt-4 px-4 pb-2" },
                [
                  _c("p", [
                    _vm._v(
                      "\n                Look for these icons to understand your access level for each title.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.information, function (item, key) {
                    return _c(
                      "v-row",
                      { key: key, attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              { attrs: { large: "", color: item.iconColor } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.icon) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", [
                          _c("h3", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.title) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              class: {
                                "mb-0": _vm.information.length - 1 === key,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.description) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                color: "primary",
                                depressed: "",
                              },
                              on: { click: _vm.close },
                            },
                            [
                              _vm._v(
                                "\n                        Close\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }