var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sampleQuestionsPdfUrl !== ""
    ? _c(
        "v-btn",
        {
          attrs: {
            depressed: "",
            href: _vm.asset(_vm.sampleQuestionsPdfUrl),
            target: "_blank",
          },
        },
        [
          _c("v-icon", { staticClass: "mr-1" }, [
            _vm._v("\n        mdi-download-outline\n    "),
          ]),
          _vm._v("\n    Sample Answer\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }