<template>
    <v-dialog v-model="checkoutModal" max-width="400">
        <v-card v-if="Object.keys(product).length">
            <v-card-title class="primary headline" style="color:white">
                Item Added to Cart
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col sm="auto">
                        <div class="image-container">
                            <img
                                v-if="product.document_info.content_type.id === 2"
                                alt="Default Book Cover"
                                class="pdf"
                                :src="asset(require('../../../images/icons/pdf_book_cover.png'))"/>
                            <img
                                class="cover"
                                :alt="product.document_info.year.year+' '+product.document_info.title+' Book Cover'"
                                :src="getBookCover(product.document_info)" @error="loadDefaultBookCover"
                                width="100%"/>
                        </div>
                    </v-col>
                    <v-col>
                        <h5 class="body-2 font-weight-bold">
                            <template v-if="product.document_info.year">
                                {{product.document_info.year.year}} {{product.document_info.title}}
                            </template>
                            <template v-else>
                                {{product.document_info.title}}
                            </template>
                        </h5>
                        <p class="body-2 mb-0">Item #: {{product.document_info.sku}}</p>
                        <p class="body-2">
                            Quantity: {{product.quantity}} | <span class="font-weight-bold">${{totalPrice}}</span>
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    small
                    text
                    @click="checkoutModal = false">
                    Continue Browsing
                </v-btn>
                <v-spacer v-if="!isMobilePort"></v-spacer>
                <v-btn
                    color="primary"
                    small
                    class="white--text"
                    :href="getRoute('cart_page')">
                    View Cart
                </v-btn>
                <v-btn
                    color="accent2"
                    small
                    class="white--text"
                    @click="goToCheckout">
                    Checkout
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                checkoutModal: false,
                product: {},
                totalPrice: 0
            };
        },
        mixins: [Mobile],
        methods: {
            showModal(data) {
                this.product = data.product;
                this.totalPrice = data.totalPrice;
                this.checkoutModal = true;
            },
            goToCheckout() {
                EventBus.fire('go_to_checkout');
            }
        },
        mounted() {
            EventBus.listen('show-checkout-modal', (data) => this.showModal(data));
        }
    }
</script>
