var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("hero-banner", {
                    attrs: {
                      title:
                        "ICC Digital Codes Premium Revit<sup>®</sup> Add-In",
                      subtitle:
                        "Use building codes and standards directly within your BIM application",
                      button: {
                        link: _vm.revitDownloadUrl,
                        target: "_blank",
                        text: "Download and Install",
                        color: "primary",
                      },
                      image: {
                        src: "revit/app_docked.webp",
                        caption:
                          "Digital Codes Premium I-Code chapter referenced during foundation design in Revit<sup>®</sup>.",
                      },
                      "show-border": false,
                      gtm: {
                        category: "Product Discovery",
                        action: "Download",
                        label: "Revit Download Button Click",
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-4" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h2", { staticClass: "mb-4 mt-5 roboto" }, [
                      _vm._v(
                        "\n                        Design with Confidence\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Get your architectural drawings right the first time and reduce the need for rework. "
                      ),
                      _c("b", [
                        _vm._v("ICC Digital Codes Premium Revit"),
                        _c("sup", [_vm._v("®")]),
                        _vm._v(" Add-In"),
                      ]),
                      _vm._v(
                        " lets you validate construction plans with published building codes during the design process.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        ICC Digital Codes Premium is more than just codes. It’s an entire toolset that gives you deeper insights, greater efficiency, and stronger collaboration — all at your fingertips. Stay connected to the most trusted source of building codes and standards, at your desk or in the field.\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container py-8" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "mb-4 roboto" }, [
                  _vm._v("\n                    What You Get with the Revit"),
                  _c("sup", [_vm._v("®")]),
                  _vm._v(" Add-in\n                "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("\n                    Enjoy the benefits of "),
                  _c("b", [_vm._v("ICC Digital Codes Premium")]),
                  _vm._v(" without leaving Revit"),
                  _c("sup", [_vm._v("®")]),
                  _vm._v(".  Get access to more than 1000 "),
                  _c("b", [
                    _vm._v("I-Codes, Commentaries and Referenced Standards"),
                  ]),
                  _vm._v(".\n                "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("b", [
                    _vm._v(
                      "Use the full-featured version of ICC Digital Codes Premium:"
                    ),
                  ]),
                  _vm._v(" Simplify your Autodesk Revit"),
                  _c("sup", [_vm._v("®")]),
                  _vm._v(" workflows with the full suite of "),
                  _c(
                    "a",
                    { attrs: { href: _vm.getRoute("premium_features") } },
                    [_vm._v("Digital Codes Premium features")]
                  ),
                  _vm._v(".\n                "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            _vm._l(_vm.whatYouGetBlocks, function (item, key) {
              return _c(
                "v-col",
                { key: key, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-card",
                    {
                      class: key % 2 === 0 && "primary",
                      style:
                        key % 2 !== 0 &&
                        "border: 1px solid " +
                          _vm.$vuetify.theme.themes.light.primary,
                      attrs: {
                        flat: "",
                        "min-height": !_vm.isMobilePort && "210px",
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "text-center",
                          class:
                            key % 2 === 0 ? "white--text" : "primary--text",
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: key % 2 === 0 ? "white" : "primary",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.icon) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("h4", {
                            staticClass: "text-center my-2",
                            domProps: { innerHTML: _vm._s(item.title) },
                          }),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "mb-0 text-left",
                            domProps: { innerHTML: _vm._s(item.description) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("testimonials", { attrs: { testimonials: _vm.testimonials } }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("img", {
                  staticClass: "my-5",
                  attrs: {
                    src: _vm.asset(
                      require("../../images/icc_digital_codes_premium.png"),
                      "default"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("h2", [
                  _vm._v(
                    "\n                    Tools to Help Design Professionals Succeed\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "mt-5" },
                  _vm._l(_vm.helpTools, function (item, key) {
                    return _c("li", {
                      key: key,
                      domProps: { innerHTML: _vm._s(item) },
                    })
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4 white--text",
                      attrs: {
                        rounded: "",
                        dark: "",
                        "x-large": "",
                        color: "primary",
                        target: "_blank",
                        href: _vm.revitDownloadUrl,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.logGtm()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Download and Install\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                _c("img", {
                  staticClass: "mx",
                  attrs: {
                    src: _vm.asset(
                      require("../../images/revit/digital-codes-premium-for-revit-copy-paste.webp"),
                      "default"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    I-Code chapter referenced and copy/pasted directly into a Revit"
                  ),
                  _c("sup", [_vm._v("®")]),
                  _vm._v(" project.\n                "),
                ]),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                _c("img", {
                  staticClass: "mx",
                  attrs: {
                    src: _vm.asset(
                      require("../../images/revit/digital-codes-premium-for-revit-my-library.webp"),
                      "default"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    2021 I-Codes library opened within Revit"
                  ),
                  _c("sup", [_vm._v("®")]),
                  _vm._v(".\n                "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "white--text",
                              attrs: { color: "primary" },
                              scopedSlots: _vm._u([
                                {
                                  key: "actions",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            "\n                                    $expand\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(
                                "\n                            System Requirements\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "mt-3" },
                            _vm._l(
                              _vm.systemRequirements,
                              function (requirement, key) {
                                return _c(
                                  "v-row",
                                  {
                                    key: key,
                                    class: key % 2 == 0 ? "grey lighten-4" : "",
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _c("v-col", { staticClass: "py-6" }, [
                                      _c("p", {
                                        staticClass: "mb-0 font-weight-bold",
                                        domProps: {
                                          innerHTML: _vm._s(requirement.title),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-6",
                                        staticStyle: {
                                          "border-left": "1px solid white",
                                        },
                                      },
                                      [
                                        _c("p", {
                                          staticClass: "mb-0",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              requirement.description
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-4 mt-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h2", { staticClass: "mb-5" }, [
                      _vm._v(
                        "\n                        Support Information\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Our Customer Success Advocates are ready to assist you with any of your needs."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                        You can email us at any time at "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: { href: "mailto:customersuccess@iccsafe.org" },
                        },
                        [_vm._v("customersuccess@iccsafe.org")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Speak to us by calling at "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: { href: "tel:+18884227233" },
                        },
                        [_vm._v("1 888 ICC SAFE (888 422 7233)")]
                      ),
                      _vm._v(", option 0."),
                      _c("br"),
                      _vm._v(
                        "\n                        We are available Monday – Friday 7:00 AM – 6:00 PM Central.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("\n                        Autodesk"),
                      _c("sup", [_vm._v("®")]),
                      _vm._v(" and Revit"),
                      _c("sup", [_vm._v("®")]),
                      _vm._v(
                        " are registered trademarks of Autodesk, Inc., in the USA and other countries.\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("hero-banner", {
                    attrs: {
                      title:
                        "Download the ICC Digital Codes Premium Revit<sup>®</sup> Add-In",
                      button: {
                        link: _vm.revitDownloadUrl,
                        target: "_blank",
                        text: "Download and Install",
                        color: "primary",
                      },
                      image: {
                        src: "revit/digital-codes-premium-for-revit-code-title.webp",
                        caption:
                          "2021 International Residential Code opened within Revit<sup>®</sup>.",
                      },
                      "show-border": false,
                      gtm: {
                        category: "Product Discovery",
                        action: "Download",
                        label: "Revit Download Button Click",
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }