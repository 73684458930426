<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-snackbar
            v-model="snackbar.active"
            :timeout="snackbar.timeout"
            top>
            <template v-if="snackbar.status == 'success'">
                <v-icon class="primary--text mr-2">check_circle</v-icon>
            </template>
            <template v-else-if="snackbar.status == 'error'">
                <v-icon class="error--text mr-2">remove_circle</v-icon>
            </template>
            {{snackbar.message}}
            <v-btn
                color="error"
                text
                @click="snackbar.active = false">
                Close
            </v-btn>
        </v-snackbar>
        <breadcrumb title="Manually Process Notes"></breadcrumb>
        <v-container>
            <v-row justify-xl="center">
                <v-col xl="10">
                    <v-card>
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>
                                <v-icon>compare_arrows</v-icon> Manually Process Notes
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pa-0">
                            <v-container class="pt-2 pb-0">
                                <v-row>
                                    <v-col>
                                        <p>
                                            After processing your notes, the system found some notes that require you to manually highlight the section text for the new version. Please use the cursor to highlight the desired text in the new version section. Once highlighted, you may click the highlight icon to add your new note. Your note title, description, and tag category will be automatically applied.
                                        </p>
                                        <p>
                                            You can skip a note using the "Skip Note" button and you can also move between notes using the "Prev" and "Next" buttons. Click the "Cancel" button to return back to the Migrate Notes section.
                                        </p>
                                        <v-divider>
                                        </v-divider>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container class="white">
                                <v-row>
                                    <v-col
                                    cols="12"
                                        sm="10"
                                        :class="isMobilePort ? 'text-center' : 'text-left'">
                                        <p class="mb-0 font-weight-regular">
                                            You are in the process of manually moving notes. Notes were not moved over automatically because the book content does not match. Please apply the note to the correct text content.
                                        </p>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="2"
                                        :class="isMobilePort ? 'text-center' : 'text-right'">
                                        <h3
                                            class="font-weight-regular">
                                            {{currentNote + 1}} of {{totalNotes}} Notes
                                        </h3>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container class="grey lighten-3">
                                <v-row v-if="!loading.books">
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-row dense>
                                            <v-col>
                                                <p class="caption mb-0">Moving Note From</p>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            dense
                                            align="center">
                                            <v-col cols="auto">
                                                <img
                                                    v-if="fromBookData"
                                                    class="cover"
                                                    :src="getBookCover(fromBookData)"
                                                    @error="loadDefaultBookCover"
                                                    width="100%"
                                                    />
                                            </v-col>
                                            <v-col>
                                                <h3
                                                    class="primary--text font-weight-regular"
                                                    v-html="getBookTitle(fromBookData)">
                                                </h3>
                                                <p
                                                    class="mb-0 caption"
                                                    v-html="getBookPrintingInfo(fromBookData)">
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-row dense>
                                            <v-col>
                                                <p class="caption mb-0">Moving Note To</p>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            dense
                                            align="center">
                                            <v-col cols="auto">
                                                <img
                                                    v-if="toBookData"
                                                    class="cover"
                                                    :src="getBookCover(toBookData)" @error="loadDefaultBookCover"
                                                    width="100%"
                                                    />
                                            </v-col>
                                            <v-col>
                                                <h3
                                                    class="primary--text font-weight-regular"
                                                    v-html="getBookTitle(toBookData)">
                                                </h3>
                                                <p
                                                    class="mb-0 caption"
                                                    v-html="getBookPrintingInfo(toBookData)">
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col>
                                        <v-progress-linear :indeterminate="true"></v-progress-linear>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-card>
                                            <template v-if="!loading.note">
                                                <v-card-text class="py-2">
                                                    <v-row>
                                                        <v-col>
                                                            <h3
                                                                style="cursor: pointer"
                                                                class="mt-1 ml-1"
                                                                :class="{
                                                                'title': !isMobilePort,
                                                                'body-2': isMobilePort
                                                                }"
                                                                @click="redirectToSection(notesData.document_id, notesData.content_id, notesData.section_id)"
                                                                >{{notesData.chapter_title}}</h3>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <p
                                                                v-if="notesData.content"
                                                                class="note-list-quote font-italic pa-1 mb-0 mt-1">
                                                                {{notesData.content}}
                                                            </p>
                                                            <p
                                                                v-if="notesData.commentText"
                                                                class="mt-2 ml-1"
                                                                v-html="notesData.commentText">
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="auto">
                                                            <v-btn depressed fab small>
                                                                <v-icon :color="'#'+getTagColor(notesData.tagId)">
                                                                    {{getIcon(isBookmark() ? 'bookmark' : 'annotation') }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col>
                                                            <h5>Created By: {{ notesData.firstName }} {{ notesData.lastName }}</h5>
                                                            <h5>
                                                                Project Tag:
                                                                {{getTagName(notesData.tagId)}}
                                                            </h5>
                                                            <h5>
                                                                Timestamp: {{notesData.created_date |
                                                                moment("MMMM Do, YYYY")}}
                                                            </h5>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <p class="caption mb-0 mr-2">Status</p>
                                                    <v-chip
                                                        :color="getNoteStatus(fromNoteId).color"
                                                        text-color="white">
                                                        <v-avatar left>
                                                            <v-icon>{{getNoteStatus(fromNoteId).icon}}</v-icon>
                                                        </v-avatar>
                                                        {{getNoteStatus(fromNoteId).text}}
                                                    </v-chip>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        small
                                                        color="info"
                                                        :disabled="isMoved(fromNoteId) || isSkipped(fromNoteId)"
                                                        @click="skipNoteModal">
                                                        Skip Note
                                                    </v-btn>
                                                </v-card-actions>
                                            </template>
                                            <template v-else>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col class="text-center">
                                                            <v-progress-circular
                                                                class="my-12"
                                                                indeterminate
                                                                color="primary">
                                                            </v-progress-circular>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </template>
                                        </v-card>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-stepper v-model="step">
                                            <v-stepper-header>
                                                <v-stepper-step
                                                    :complete="step > 1"
                                                    step="1">
                                                    Select Chapter or Section
                                                </v-stepper-step>
                                                <v-divider></v-divider>
                                                <v-stepper-step
                                                    :complete="step > 2"
                                                    step="2">
                                                    <template v-if="isBookmark()">
                                                        Bookmark Content
                                                    </template>
                                                    <template v-else>
                                                        Highlight Content
                                                    </template>
                                                </v-stepper-step>
                                                <v-divider></v-divider>
                                                <v-stepper-step step="3">
                                                    Confirm & Apply Note
                                                </v-stepper-step>
                                            </v-stepper-header>
                                            <v-stepper-items>
                                                <v-stepper-content step="1">
                                                    <v-row dense v-if="isMobilePort">
                                                        <v-col>
                                                            <p class="mb-2 text-center caption">
                                                                Select Chapter or Section
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        dense
                                                        justify="end">
                                                        <v-col cols="auto">
                                                            <v-btn
                                                                small
                                                                :disabled="loading.items || !hasActiveSection"
                                                                @click="selectSection"
                                                                color="primary">
                                                                Continue
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense>
                                                        <v-col :class="{'text-center': loading.items}">
                                                            <v-progress-circular
                                                                v-if="loading.items"
                                                                class="my-12"
                                                                indeterminate
                                                                color="primary">
                                                            </v-progress-circular>
                                                            <v-card
                                                                v-else
                                                                flat
                                                                max-height="400px"
                                                                class="overflow-y-auto grey lighten-5"
                                                                transition="slide-y-transition">
                                                                <v-card-text class="py-sm-2">
                                                                    <v-treeview
                                                                        dense
                                                                        rounded
                                                                        hoverable
                                                                        activatable
                                                                        active-class="grey lighten-3"
                                                                        :search="search"
                                                                        :items="items"
                                                                        item-children="sub_sections"
                                                                        item-key="content_id"
                                                                        item-text="title">
                                                                        <template slot="label" slot-scope="{ item }">
                                                                            <a @click="setActive(item.content_id, item.title)">
                                                                                {{ item.title }}
                                                                            </a>
                                                                        </template>
                                                                    </v-treeview>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        dense
                                                        justify="end">
                                                        <v-col cols="auto">
                                                            <v-btn
                                                                small
                                                                :disabled="loading.items || !hasActiveSection"
                                                                @click="selectSection"
                                                                color="primary">
                                                                Continue
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-content>
                                                <v-stepper-content step="2">
                                                    <template v-if="!loading.chapterSection">
                                                        <v-row dense v-if="isMobilePort">
                                                            <v-col>
                                                                <p class="mb-2 text-center caption">
                                                                    <template v-if="isBookmark()">
                                                                        Bookmark Content
                                                                    </template>
                                                                    <template v-else>
                                                                        Highlight Content
                                                                    </template>
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row
                                                            dense
                                                            justify="end">
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    @click="step = 1"
                                                                    color="primary">
                                                                    Back
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    :disabled="!hasMadeSelection"
                                                                    @click="step = 3"
                                                                    color="primary">
                                                                    Continue
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <template>
                                                                    <div
                                                                        id="contentParent"
                                                                        class="pt-4 chapter_styling chapter-expand migrate-compare"
                                                                        v-html="chapterContent">
                                                                    </div>
                                                                </template>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row
                                                            dense
                                                            justify="end">
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    @click="step = 1"
                                                                    color="primary">
                                                                    Back
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    :disabled="!hasMadeSelection"
                                                                    @click="step = 3"
                                                                    color="primary">
                                                                    Continue
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    <template v-else>
                                                        <v-row>
                                                            <v-col class="text-center">
                                                                <v-progress-circular
                                                                    class="my-12"
                                                                    indeterminate
                                                                    color="primary">
                                                                </v-progress-circular>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-stepper-content>
                                                <v-stepper-content step="3">
                                                    <v-row dense v-if="isMobilePort">
                                                        <v-col>
                                                            <p class="mb-2 text-center caption">
                                                                Confirm & Apply Note
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <template v-if="editNoteData">
                                                        <v-row>
                                                            <v-col class="text-right">
                                                                <v-btn
                                                                    small
                                                                    @click="editNoteData = false"
                                                                    color="primary">
                                                                    Save
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row align="center">
                                                            <v-col>
                                                                <v-select
                                                                    hide-details
                                                                    solo
                                                                    dense
                                                                    label="Select Tag from the list"
                                                                    v-model="selectedTag"
                                                                    :items="tags"
                                                                    item-text="tag"
                                                                    item-value="id">
                                                                </v-select>
                                                            </v-col>
                                                            <v-col>
                                                                <v-btn
                                                                    small
                                                                    color="primary"
                                                                    @click="openTagModal">
                                                                    Add Tag
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <vue-editor v-model="editorData" :editor-toolbar="customToolbar"/>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    <template v-else>
                                                        <v-row
                                                            dense
                                                            justify="end">
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    @click="step = 2"
                                                                    color="primary">
                                                                    Back
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    :disabled="loading.noteProcessing"
                                                                    @click="onSave"
                                                                    color="primary">
                                                                    <span v-if="!loading.noteProcessing">Apply Note</span>
                                                                    <v-progress-circular
                                                                        v-else
                                                                        :size="20"
                                                                        indeterminate
                                                                        color="white">
                                                                    </v-progress-circular>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <h3
                                                                    style="cursor: pointer"
                                                                    class="mt-1 ml-1"
                                                                    :class="{
                                                                        'title': !isMobilePort,
                                                                        'body-2': isMobilePort
                                                                    }">
                                                                    {{chapterTitle}}
                                                                </h3>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <p
                                                                    v-if="highlightedText.text"
                                                                    class="note-list-quote font-italic pa-1 mb-0 mt-1">
                                                                    {{highlightedText.text}}
                                                                </p>
                                                                <p
                                                                    v-if="editorData"
                                                                    class="mt-2 ml-1"
                                                                    v-html="editorData">
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="auto">
                                                                <v-btn depressed fab small>
                                                                    <v-icon :color="'#'+getTagColor(selectedTag)">
                                                                        {{getIcon(isBookmark() ? 'bookmark' : 'annotation') }}
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col>
                                                                <h5>Created By: {{ notesData.firstName }} {{ notesData.lastName }}</h5>
                                                                <h5>
                                                                    Project Tag:
                                                                    {{getTagName(selectedTag)}}
                                                                </h5>
                                                                <h5>
                                                                    Timestamp: {{notesData.created_date |
                                                                    moment("MMMM Do, YYYY")}}
                                                                </h5>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn
                                                                    small
                                                                    color="grey lighten-3"
                                                                    @click="editNoteData = true">
                                                                    Edit Details
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-stepper-content>
                                            </v-stepper-items>
                                        </v-stepper>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-row
                                            dense
                                            justify="end">
                                            <v-col cols="auto">
                                                <v-btn
                                                    :small="isMobilePort"
                                                    @click="finish()"
                                                    color="error">
                                                    Cancel
                                                </v-btn>
                                            </v-col>
                                            <v-col
                                                v-if="currentNote > 0"
                                                cols="auto">
                                                <v-btn
                                                    :small="isMobilePort"
                                                    @click="--currentNote"
                                                    color="primary">
                                                    Prev Note
                                                </v-btn>
                                            </v-col>
                                            <v-col
                                                v-if="currentNote !== (totalNotes - 1)"
                                                cols="auto">
                                                <v-btn
                                                    :small="isMobilePort"
                                                    @click="nextNote()"
                                                    color="primary">
                                                    Next Note
                                                </v-btn>
                                            </v-col>
                                            <v-col
                                                v-if="currentNote === (totalNotes - 1)"
                                                cols="auto">
                                                <v-btn
                                                    :small="isMobilePort"
                                                    @click="finish()"
                                                    color="primary">
                                                    Finish
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <tag-edit-modal></tag-edit-modal>
        <v-dialog
            persistent
            v-model="dialog"
            width="500">
            <v-card>
                <v-card-title class="primary white--text">
                    Confirm
                </v-card-title>
                <v-card-text>
                    <p class="mt-8 text-center">
                        {{dialogMessage}}
                    </p>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn
                        @click="closeDialog"
                        color="error">
                        No
                    </v-btn>
                    <v-btn
                        @click="skipNote"
                        color="primary">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Breadcrumb from '../../content/partials/page_titles';
    import TagEditModal from '../tags/dialogs/editTag';
    import BookmarkSection from './partials/bookmarkSection';

    import EditorConfigs from '../../inc/editorConfigBar';
    import NoteMixins from "./mixins/note";
    import Mobile from "./../../inc/mixins/mobile";
    import ContentUtils from '../../titles/inc/contentUtils';

    export default {
        data() {
            return {
                step: 1,
                editNoteData: false,
                editorData: '',
                search: null,
                items: [],
                activeSection: 0,
                activeBookmark: 0,
                currentNote: 0,
                totalNotes: 0,
                notesData: [],
                tags: [],
                selectedTag: 0,
                processedNotes: {
                    skipped: [],
                    moved: []
                },
                fromBookData: [],
                toBookData: [],
                chapterContent: '',
                chapterTitle: '',
                noteType: '',
                dialog: false,
                dialogMessage: '',
                actionSkipNote: false,
                highlightedText: {},
                snackbar: {
                    'active': false,
                    'message': '',
                    'timeout': 5500,
                    'status': ''
                },
                loading: {
                    note: true,
                    items: true,
                    books: true,
                    chapterSection: true,
                    noteProcessing: false
                }
            }
        },
        computed: {
            hasActiveSection() {
                return this.activeSection !== 0;
            },
            hasMadeSelection() {
                return _.size(this.highlightedText) > 0 || this.activeBookmark !== 0;
            },
            fromNoteId() {
                return _.toInteger(JSON.parse(this.notes)[this.currentNote]);
            },
            skippedNotesCount() {
                return _.size(this.processedNotes.skipped);
            },
            movedNotesCount() {
                return _.size(this.processedNotes.moved);
            },
            processedNotesCount() {
                return this.skippedNotesCount + this.movedNotesCount;
            }
        },
        watch: {
            currentNote: function () {
                this.getCurrentNote();
            }
        },
        mixins: [
            EditorConfigs,
            NoteMixins,
            Mobile,
            ContentUtils
        ],
        props: {
            userId: {
                type: String,
                required: true
            },
            fromBook: {
                type: String,
                required: true
            },
            toBook: {
                type: String,
                required: true
            },
            action: {
                type: String,
                required: true
            },
            notes: {
                type: String,
                required: true
            },
            isOwnUser: {
                  type: Number,
                  required: true
            },
        },
        components: {
            Breadcrumb,
            TagEditModal
        },
        methods: {
            onSave() {
                this.loading.noteProcessing = true;
                let postData = {
                    'userId': this.userId,
                    'fromBook': this.fromBook,
                    'toBook': this.toBook,
                    'noteType': this.noteType,
                    'noteComment': this.editorData,
                    'action': this.action,
                    'tag': this.selectedTag,
                    'fromNoteId': this.fromNoteId,
                    'toChapterId': this.activeSection,
                    'toChapterTitle': this.chapterTitle,
                    'highlightedText': this.highlightedText
                };
                if (this.isBookmark()) {
                    this.$http.post(Routing.generate('migrate_bookmark'), postData)
                    .then(response => {
                        if (response.data) {
                            this.snackbar['message'] = 'Bookmark moved successfully!';
                            this.snackbar['status'] = 'success';
                            this.processNote('moved', _.toInteger(postData.fromNoteId));
                        } else {
                            this.snackbar['message'] = 'Oops! Something went wrong. Please try again later.';
                            this.snackbar['status'] = 'error';
                        }
                        this.moveNext();
                        this.$nextTick(() => {
                            this.snackbar['active'] = true;
                            this.loading.noteProcessing = false;
                        });
                    });
                } else {
                    if (!_.isUndefined(this.highlightedText.sectionId)) {
                        let object = _.assign(
                            postData,
                            {
                                'id': null,
                                'userId': this.userId,
                                'sectionId': this.highlightedText.sectionId,
                                'chapterTitle': this.chapterTitle,
                                'chapterId': this.activeSection,
                                'bookId': this.toBookData.id,
                                'documentId': this.toBook,
                                'bookSku': this.toBookData.sku,
                                'note': this.editorData,
                                'isNewXml': true // We migrate new XML notes only
                            }
                        );
                        this.$http.post(Routing.generate('migrate_annotation'), postData)
                        .then(response => {
                            if (response.data) {
                                this.snackbar['message'] = 'Annotation migrated successfully!';
                                this.snackbar['status'] = 'success';
                                this.processNote('moved', _.toInteger(postData.fromNoteId));
                            } else {
                                this.snackbar['message'] = 'Oops! Something went wrong. Please try again later.';
                                this.snackbar['status'] = 'error';
                            }
                            this.moveNext();
                            this.$nextTick(() => {
                                this.snackbar['active'] = true;
                                this.loading.noteProcessing = false;
                            });
                        });
                    } else {
                        this.snackbar['message'] = 'Please highlight some content before proceeding.';
                        this.snackbar['status'] = 'error';
                        this.snackbar['active'] = true;
                    }
                }
            },
            getBookSections() {
                this.$http.get(Routing.generate('get_book_chapters', {'documentId': this.toBook})).then(
                    response => {
                        if (response.data) {
                            _.eachDeep(response.data, function (value, key, parent) {
                                if (_.isNull(parent.sub_sections)) {
                                    delete parent['sub_sections'];
                                }
                                if (key === "link") {
                                    _.assign(parent, {'title': value.title});
                                }
                            });
                            this.items = response.data;
                            this.loading.items = false;
                        }
                    }
                );
            },
            setActive(contentId, contentTitle) {
                this.chapterTitle = contentTitle;
                this.activeSection = contentId;
            },
            setActiveBookmark(id) {
                this.activeBookmark = id;
                EventBus.fire('set-active-bookmark-callback', id);
            },
            selectSection() {
                this.loading.chapterSection = true;
                this.$http.get(Routing.generate('get_chapter_xml', {documentId: _.toInteger(this.toBook), chapterId: _.toInteger(this.activeSection)}))
                    .then(response => {
                        this.chapterContent = response.data;
                        this.loading.chapterSection = false;
                        this.$nextTick(() => {
                            if (this.isBookmark()) {
                                this.applyBookmarkIcons();
                            } else {
                                this.applyEventListeners();
                            }
                        });
                    });
                this.step = 2;
            },
            applyEventListeners() {
                let _this = this;
                // Add mouse up event for when text is highlighted.
                document.getElementById('contentParent').addEventListener('mouseup', function (event) {
                    _this.disableAddNotes();
                    _this.enableAddNote(true, event);
                });
                // Add mouse down to enable highlight for individual sections.
                document.getElementById('contentParent').addEventListener('mousedown', function (event) {
                    _this.enableHighlight(event);
                });
                // Enable highlight on table notes.
                let tableNotes = document.getElementsByClassName('note');
                _.forEach(tableNotes, function (value) {
                    value.setAttribute('data-enable-highlight', '1');
                });
            },
            applyBookmarkIcons() {
                let _this = this;
                let sections = document.getElementsByClassName('section-action-wrapper');
                let ComponentClass = Vue.extend(BookmarkSection);
                _.forEach(sections, function(value) {
                    let sectionId = _this.stripIds(value.id);
                    let instance = new ComponentClass({
                        propsData: {
                            html: value.innerHTML,
                            activeBookmark: _this.activeBookmark,
                            sectionId: _.toInteger(sectionId)
                        }
                    });

                    instance.$mount();
                    value.innerHTML = '';
                    value.appendChild(instance.$el);
                });
            },
            openTagModal() {
                EventBus.fire('show-tag-edit-modal', null);
            },
            moveNext() {
                this.step = 1;
                this.highlightedText = {};
                if (this.currentNote+1 < this.totalNotes) {
                    ++this.currentNote;
                }
                this.activeSection = 0;
            },
            skipNoteModal() {
                this.dialogMessage = 'Are you sure you want to skip this note?';
                this.actionSkipNote = true;
                this.dialog = true;
            },
            skipNote() {
                if(this.actionSkipNote) {
                    this.processNote('skipped', this.fromNoteId);
                    this.moveNext();
                    this.actionSkipNote = false;
                    this.dialog = false;
                }
                else {
                    if (0 == this.isOwnUser) {
                        this.goToRoute("admin_note_migrations", {userId: this.userId});
                    } else {
                        this.goToRoute('notes');
                    }
                }
            },
            goToTargetBook() {
                if (this.toBookData && this.toBookData.slug) {
                    window.location.href = Routing.generate("content_document_slug", {documentSlug: this.toBookData.slug});
                } else if (this.toBook) {
                    window.location = Routing.generate('content_document', {'documentId': this.toBook, 'chapterId': 'toc'});
                }
            },
            getCurrentNote() {
                this.fetchBookAndNotes(0,0,JSON.parse(this.notes)[this.currentNote]);
            },
            fetchBookAndNotes(fromBookId, toBookId, noteValue) {
                if (noteValue) {
                    this.loading.note = true;
                    this.$http
                        .get(
                            Routing.generate("get_book_and_notes", {
                                userId: this.userId,
                                fromBook: fromBookId,
                                toBook: toBookId,
                                noteId: noteValue
                            }))
                        .then(response => {
                            if (response.data) {
                                this.notesData = response.data.noteDetails;
                                this.$nextTick(() => {
                                    this.loading.note = false;
                                });
                                this.selectedTag = this.notesData.tagId;
                                this.editorData = this.notesData.commentText;
                                this.noteType = this.notesData.annotationId ? 'annotation' : 'bookmark';
                                if (fromBookId != 0 && toBookId != 0) {
                                    this.fromBookData = response.data.fromBookDetails;
                                    this.toBookData = response.data.toBookDetails;
                                    this.$nextTick(() => {
                                        this.loading.books = false;
                                    });
                                }
                            }
                        });
                }
            },
            getNoteStatus(id) {
                if (this.isSkipped(id)) {
                    return {
                        text: 'Skipped',
                        color: 'warning',
                        icon: 'remove_circle'
                    }
                } else if (this.isMoved(id)) {
                    return {
                        text: 'Moved',
                        color: 'primary',
                        icon: 'check_circle'
                    }
                } else {
                    return {
                        text: 'Unmoved',
                        color: 'error',
                        icon: 'info'
                    }
                }
            },
            getTags() {
                this.$http.get(Routing.generate('get_my_tags'))
                    .then(response => {
                        this.$store.commit('setTags', response.data);
                        this.tags = this.sortTagsByTitle(response.data);
                    });
            },
            processNote(method, id) {
                _.forEach(this.processedNotes, function(value, key) {
                    if (_.includes(value, id)) {
                        _.remove(value, function (id) {
                            return id === id;
                        });
                    }
                });
                this.$nextTick(() => {
                    this.processedNotes[method].push(id);
                });
            },
            nextNote() {
                if (this.isMoved(this.fromNoteId) || this.isSkipped(this.fromNoteId)) {
                    ++this.currentNote
                } else {
                    this.snackbar['message'] = 'Please either skip or move this note before proceeding.';
                    this.snackbar['status'] = 'error';
                    this.snackbar['active'] = true;
                }
            },
            isMoved(id) {
                return _.includes(this.processedNotes.moved, id);
            },
            isSkipped(id) {
                return _.includes(this.processedNotes.skipped, id);
            },
            isBookmark() {
                return this.noteType === 'bookmark';
            },
            finish() {
                let message = 'Are you sure you want to finish?  ';
                if (this.skippedNotesCount > 0) {
                    message += 'You have '+this.skippedNotesCount+' note(s) that you skipped.  ';
                }
                if (this.totalNotes !== this.processedNotesCount) {
                    message += 'You have '+(this.totalNotes - (this.movedNotesCount + this.skippedNotesCount))+' note(s) that have not been moved.  ';
                } else {
                    message += this.movedNotesCount + ' of ' + this.totalNotes + ' note(s) have successfully been moved.';
                }
                this.dialogMessage = message;
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.actionSkipNote = false;
            },
            updateTag(tagId) {
                this.getTags();
                this.$nextTick(() => {
                    this.selectedTag = tagId;
                });
            }
        },
        mounted() {
            this.totalNotes = JSON.parse(this.notes).length;
            this.fetchBookAndNotes(
                    this.fromBook,
                    this.toBook,
                    JSON.parse(this.notes)[0]
                    );
            this.getBookSections();
            this.getTags();
            EventBus.listen('tag-edit-modal-callback', (tagId) => this.updateTag(tagId));
            EventBus.listen('set-active-bookmark', (id) => this.setActiveBookmark(id));
        }
    }
</script>

<style lang="scss" scoped>
    .cover {
        max-width: 40px;
    }
    td.center {
        width: auto;
    }
    section {
        position: relative;
        clear: both;
        p {
            margin-right: 10px;
        }
    }
    .no_highlight {
        background: white !important;
    }
    .highlighter:hover {
        cursor: pointer;
    }
    p:hover,
    h1:hover {
        cursor: text;
    }
    ::selection {
        background: #ffff00;
    }
    ::-moz-selection {
        background: #ffff00;
    }
    .v-expansion-panel {
        list-style-type: none !important;
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
    }
    .chapter-expand {
        max-height: 400px;
        overflow-y: scroll;
    }
    .note-list-quote {
        user-select:none;
    }
    .v-stepper__label {
        display: inline!important;
    }
    .v-stepper__step {
        width: 33%;
    }
    #contentParent {
        overflow-x: hidden;
        .section-action-wrapper {
            .v-icon {
                color: unset!important;
            }
        }
    }
</style>
