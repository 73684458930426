var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: -1,
        value: true,
        color: "accent2Light",
        "content-class": "black--text fs-16",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    staticClass: "black--text font-weight-bold",
                    attrs: { color: "accent2", depressed: "" },
                    on: { click: _vm.openPremiumBundle },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v("\n        Open\n        ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.itemCount === 0
        ? [_vm._v("\n        Get started with your Premium bundle\n    ")]
        : [
            _vm._v(
              "\n        " +
                _vm._s(_vm.itemCount) +
                " titles in your Premium Bundle\n    "
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }