<template>
    <v-container id="container">

        <div id="inner_container" class="d-flex flex-column mx-auto py-md-8">

            <h2 class="oxygen accent2--text text-center px-md-8 mb-8">
                Sign up to receive updates on latest Premium features, contents and events.
            </h2>

            <div class="d-flex align-center" v-if="!isFormSent">

                <v-text-field
                        v-model="email"
                        :disabled="isLoading"
                        filled hide-details
                        background-color="white"
                        color="black"
                        label="Your Email:"
                        class="rounded-0">
                </v-text-field>

                <v-btn
                        :x-large="true"
                        dark
                        :loading="isLoading"
                        class="cdpBlue rounded-0"
                        @click="subscribeNow">
                    Subscribe Now
                </v-btn>
            </div>

            <div v-else>
                <h3 class="white--text text-center">Thank you!</h3>
            </div>

        </div>

    </v-container>
</template>

<script>
import {StringUtil} from "../../util/StringUtil";
import axios from "axios";

export default {
    data() {
        return {
            email: '',
            isLoading: false,
            isFormSent: false
        }
    },
    methods: {
        subscribeNow() {

            this.isLoading = true;

            if (!StringUtil.isEmailValid(this.email)) {
                alert('Invalid email!');
                this.isLoading = false;
                return;
            }

            const apiUrl = Routing.generate('email_subscribe_now');

            axios.post(apiUrl, {
                email: this.email
            }).then(() => {
                this.isFormSent = true;
            }).catch(() => {
                alert('Invalid email!');
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
}
</script>

<style scoped>
#container {
    background-color: #333333;
    border: 1px solid #979797;
}

#inner_container {
    max-width: 680px;
}
</style>
