var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v(
                    "\n                Subscription Required\n                "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "lighten-3 px-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-3",
                          attrs: { cols: "12", align: "center" },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: _vm.asset(
                                  require("../../images/icc_digital_codes_premium.png")
                                ),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            { attrs: { align: "center" } },
                            [
                              _vm.isPremiumComplete
                                ? [
                                    _vm._v(
                                      "\n                                This title is bundled exclusively with a Premium Complete subscription.\n                            "
                                    ),
                                  ]
                                : _vm.isPremiumBundled
                                ? [
                                    _vm._v(
                                      "\n                                This title is bundled exclusively with Premium Collections and Premium Complete.\n                            "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                                The feature you are trying to access is part of a Digital Codes Premium subscription.\n                                Upgrade now to unlock dozens of advanced digital tools.\n                            "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-2",
                          attrs: { align: "center", cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            [
                              !_vm.trialActive &&
                              !_vm.isPremiumComplete &&
                              !_vm.isPremiumBundled
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "accent2",
                                        "data-aq": "start-premium-trial-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToRoute("trial_signup")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Start Premium Trial\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isPremiumComplete &&
                              !_vm.isPremiumBundled &&
                              this.documentId
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "accent2",
                                        "data-aq": "subscribe-book-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openBuyModal()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Subscribe\n                            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "accent2",
                                        "data-aq": "browse-titles-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToRoute("codes")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Browse Titles\n                            "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }