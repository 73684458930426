var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "font-size": "14px" } }, [
      _c("h4", [_vm._v("USER NOTICE AND DISCLAIMER")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Please note, the ICC AI Navigator™ beta tool ("),
        _c("strong", [_vm._v('"ICC AI Navigator"')]),
        _vm._v(
          ') is currently in\n        development. ICC AI Navigator aims to provide high-quality information directly from ICC-hosted content, but\n        it may not function\n        with total accuracy or completeness. While ICC AI Navigator cites provisions from the International Code\n        Council, Inc. ("ICC") model codes and/or other publications found within ICC Digital Codes Premium, its\n        answers are not, and should not be interpreted as, the official text of any publication.'
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'ICC AI Navigator and its outputs are provided "as is." As such, the ICC, its affiliates, its subsidiaries,\n        and its third-party service providers (collectively, '
        ),
        _c("strong", [_vm._v('"ICC Group"')]),
        _vm._v(
          "), to the full extent\n        permitted by\n        applicable law, provide no warranties and/or guarantees, express and/or implied, statutory, or otherwise,\n        including (without limitation) any warranties of accuracy, completeness or reliability, title,\n        non-infringement, merchantability, or fitness for purpose. The ICC Group will not be held liable for any\n        damages or loss, whether direct, indirect, consequential, or punitive, that may arise through your use of\n        ICC AI Navigator."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'By acknowledging these terms ["Acknowledge" confirmed before your first use], you agree and understand that\n        your use of ICC AI Navigator’s features and outputs is at your own risk. You further confirm that you\n        understand and agree to all terms of this disclaimer and that your use of the ICC AI Navigator is in\n        accordance with ICC’s Website Terms of Use, accessible at:\n        '
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.iccsafe.org/about/terms-of-use/",
              target: "_blank",
            },
          },
          [_vm._v("https://www.iccsafe.org/about/terms-of-use/")]
        ),
        _vm._v(
          "\n\n        and OpenAI, LLC's Terms of Use, accessible at\n\n        "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://openai.com/policies/terms-of-use",
              target: "_blank",
            },
          },
          [_vm._v("https://openai.com/policies/terms-of-use")]
        ),
        _vm._v(".\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }