import { CartApi } from '../api/CartApi';
import { AppParameters } from "../AppParameters";
import Constants from '../constants/index';

const state = {
    selection: [],
    availableFrequencies: [],
    defaultAvailableFrequencies: ['B', 'C', 'M'],
    frequency: 'M',
    quantity: 1,
    options: '',
    loading: {
        single: false,
        collection: false,
        complete: false,
        addToCart: false,
    },
    isMember: false,
    isActiveTrial: false,
    trialDiscountEnabled: false,
    trialDiscount: 0,
    addedToCartItems: [],
    showCartQue: false,
    cartLimitReached: false
};

const getters = {
    billingPeriodText: (state) => {
        switch (state.frequency) {
            case 'M':
                return 'Monthly';
            case 'B':
                return 'Yearly';
            case 'C':
                return '3-Year';
        }
    },
    billingPeriodShortText: (state) => {
        switch (state.frequency) {
            case 'M':
                return 'mo';
            case 'B':
                return 'yr';
            case 'C':
                return '3-yr';
        }
    },
    getFrequency: (state) => {
        return state.frequency;
    },
    getQuantity: (state) => {
        return state.quantity;
    },
    getAvailableFrequencies: (state) => {
        return state.availableFrequencies.length > 0 ? state.availableFrequencies : state.defaultAvailableFrequencies;
    },
    activeTier: (state) => state.selection.find(item => item.sku === state.options),
    savingsText: (state) => {
        let savings = [];
        let addedKeys = {};  // Object to track which discount keys have been added

        state.addedToCartItems.forEach(item => {
            // Key for Premium Complete Trial Discount
            if (state.trialDiscountEnabled && item.sku === Constants.premiumCompleteSku && state.isActiveTrial) {
                const key = 'premium-trial-discount';
                if (!addedKeys[key]) {
                    savings.push({
                        discount: state.trialDiscount,
                        label: 'Premium Complete Trial Discount (converting before trial ends)',
                    });
                    addedKeys[key] = true;
                }
            }

            // Key for Membership Discount
            if (state.isMember) {
                const key = 'membership-discount';
                if (!addedKeys[key]) {
                    savings.push({
                        // Todo: Might need to make this dynamic.
                        discount: 25,
                        label: 'ICC Membership Discount',
                    });
                    addedKeys[key] = true;
                }
            }

            // Frequency Discount
            if (item.frequencySavingPercentage && item.frequencySavingPercentage > 0) {
                const key = 'frequency-discount';
                if (!addedKeys[key]) {
                    savings.push({
                        discount: item.frequencySavingPercentage,
                        label: 'Billing Frequency Discount (versus monthly)'
                    });
                    addedKeys[key] = true;
                }
            }

            // Key for Quantity Discount
            if (item.savingsPercentage && item.savingsPercentage > 0) {
                const key = 'quantity-discount';
                if (!addedKeys[key]) {
                    savings.push({
                        discount: item.savingsPercentage,
                        label: 'License Quantity Discount (versus single license)',
                    });
                    addedKeys[key] = true;
                }
            }
        });

        return savings;
    },
    getCartItems: state => state.addedToCartItems,
    cartItemPrices: (state) => {
        return state.addedToCartItems.map(item => {
            const memberPriceOptions = item.pricing.member[item.sku][state.frequency].sort((a, b) => a.qty - b.qty);
            const nonMemberPriceOptions = item.pricing.general[item.sku][state.frequency].sort((a, b) => a.qty - b.qty);

            const getApplicablePrice = (options) => {
                return options.reduce((best, current) => {
                    return state.quantity >= current.qty ? current : best;
                }, options[0]);  // Start with the smallest quantity tier
            };

            let applicableMemberPrice = getApplicablePrice(memberPriceOptions);
            let applicableNonMemberPrice = getApplicablePrice(nonMemberPriceOptions);

            let memberTotalCost = applicableMemberPrice.price * state.quantity;
            let nonMemberTotalCost = applicableNonMemberPrice.price * state.quantity;

            // Apply trial discount if applicable
            if (state.isActiveTrial && state.trialDiscountEnabled && item.sku === Constants.premiumCompleteSku) {
                memberTotalCost *= (1 - state.trialDiscount / 100);
                nonMemberTotalCost *= (1 - state.trialDiscount / 100);
            }

            return {
                id: item.id,
                title: item.title,
                memberTotalCost: memberTotalCost.toFixed(2),
                nonMemberTotalCost: nonMemberTotalCost.toFixed(2)
            };
        });
    },
    cartTotalPrice: (state, getters) => {
        if (state.isMember) {
            const totalMemberPrice = getters.cartItemPrices.reduce((total, item) => {
                return total + parseFloat(item.memberTotalCost);
            }, 0).toFixed(2);  // Convert string to float for addition and then format it
            return {
                memberTotal: totalMemberPrice
            };
        } else {
            const totalMemberPrice = getters.cartItemPrices.reduce((total, item) => {
                return total + parseFloat(item.memberTotalCost);
            }, 0).toFixed(2);

            const totalNonMemberPrice = getters.cartItemPrices.reduce((total, item) => {
                return total + parseFloat(item.nonMemberTotalCost);
            }, 0).toFixed(2);

            return {
                memberTotal: totalMemberPrice,
                nonMemberTotal: totalNonMemberPrice
            };
        }
    },

    getShowCartQue: state => state.showCartQue,
    getIsMember: state => state.isMember,
    displayPrice: (state, getters) => (title) => {
        const unavailablePrice = `Unavailable for ${getters.billingPeriodText} billing`;
        if (!title || !title.pricing || !title.sku) {
            return unavailablePrice;
        }

        const pricingData = state.isMember ? title.pricing.member : title.pricing.general;
        const priceOptions = pricingData?.[title.sku]?.[state.frequency];

        const applicablePrice = priceOptions?.find(option => state.quantity >= option.qty) || priceOptions?.[0];
        let finalPrice = applicablePrice ? applicablePrice.price - (applicablePrice.price * (applicablePrice.discount || 0) / 100) : 0;

        if (state.isActiveTrial && state.trialDiscountEnabled && title.sku === Constants.premiumCompleteSku) {
            finalPrice = finalPrice * (1 - state.trialDiscount / 100);
        }

        return finalPrice > 0 ? finalPrice.toFixed(2) : unavailablePrice;
    }

};

const mutations = {
    SET_SELECTION(state, selection) {
        state.selection = selection;
    },
    SET_LOADING(state, { type, loading }) {
        state.loading[type] = loading;
    },
    SET_PRICES(state, { sku, prices, type }) {
        const item = state.selection.find(item => item.sku === sku);
        if (item) {
            item.prices = prices;
            item.hasVolumeDiscounts = Object.values(prices).some(price => price.length > 1);
        }
    },
    SET_AVAILABLE_FREQUENCIES(state) {
        const allFrequencies = ['M', 'B', 'C'];
        const itemFrequenciesList = state.addedToCartItems.map(item => {
            const pricingData = state.isMember ? item.pricing.member : item.pricing.general;
            return Object.keys(pricingData[item.sku] || {});
        });

        // Find the intersection of all item frequencies
        const availableFrequencies = allFrequencies.filter(freq =>
            itemFrequenciesList.every(frequencies => frequencies.includes(freq))
        );

        state.availableFrequencies = availableFrequencies.length > 0 ? availableFrequencies : state.defaultAvailableFrequencies;
    },
    SET_FREQUENCY(state, frequency) {
        state.frequency = frequency;
    },
    SET_QUANTITY(state, quantity) {
        state.quantity = quantity;
    },
    UPDATE_PRICES(state) {
        const updateItemPrices = (items) => {
            return items.map(item => {
                const priceOptions = state.isMember ? item.pricing.member[item.sku][state.frequency] : item.pricing.general[item.sku][state.frequency];
                const monthlyPriceOptions = (state.isMember ? item.pricing.member[item.sku]['M'] : item.pricing.general[item.sku]['M']) || [];

                if (priceOptions) {
                    priceOptions.sort((a, b) => b.qty - a.qty);
                    const applicablePrice = priceOptions.find(option => state.quantity >= option.qty) || priceOptions[priceOptions.length - 1];

                    let totalCost = applicablePrice.price;
                    if (item.sku === Constants.premiumCompleteSku && state.isActiveTrial && state.trialDiscountEnabled) {
                        totalCost = totalCost * (1 - state.trialDiscount / 100);
                    }
                    const totalDisplayedPrice = (totalCost * state.quantity).toFixed(2);
                    const pricePerLicense = (totalDisplayedPrice / state.quantity).toFixed(2);

                    const basePrice = priceOptions[priceOptions.length - 1].price;
                    const savingsPercentage = ((basePrice - applicablePrice.price) / basePrice * 100).toFixed(0);

                    let frequencySavingsPercentage = 0;
                    if (monthlyPriceOptions.length > 0) {
                        monthlyPriceOptions.sort((a, b) => b.qty - a.qty);
                        let annualizedMonthlyPrice = monthlyPriceOptions.find(price => state.quantity >= price.qty) || monthlyPriceOptions[monthlyPriceOptions.length - 1];
                        annualizedMonthlyPrice = annualizedMonthlyPrice.price;
                        if (state.frequency === 'B') {
                            annualizedMonthlyPrice *= 12;  // Annualizing the monthly price
                        } else if (state.frequency === 'C') {
                            annualizedMonthlyPrice *= 36;  // Annualizing for 3 years
                        }

                        let durationPrice = priceOptions.find(price => state.quantity >= price.qty) || priceOptions[priceOptions.length - 1];
                        durationPrice = durationPrice.price;

                        if (annualizedMonthlyPrice > durationPrice) {
                            frequencySavingsPercentage = (((annualizedMonthlyPrice - durationPrice) / annualizedMonthlyPrice) * 100).toFixed(0);
                        }
                    }

                    item.displayedPrice = totalDisplayedPrice;
                    item.pricePerLicense = pricePerLicense;
                    item.frequencySavingPercentage = frequencySavingsPercentage;
                    item.savingsPercentage = savingsPercentage;
                } else {
                    item.displayedPrice = null;
                    item.pricePerLicense = null;
                    item.frequencySavingPercentage = null;
                    item.savingsPercentage = null;
                }
                return item;
            });
        };

        state.selection = updateItemPrices(state.selection);
        state.addedToCartItems = updateItemPrices(state.addedToCartItems);
    },
    ADD_TO_CART(state, title) {
        if (state.addedToCartItems.length === AppParameters.max_allowed_items_in_cart) {
            state.cartLimitReached = true;
            return false;
        }
        if (!state.addedToCartItems.some(item => item.id === title.id)) {
            state.addedToCartItems.push(title);
        }
    },
    REMOVE_FROM_CART(state, title) {
        const index = state.addedToCartItems.findIndex(item => item.id === title.id);
        if (index !== -1) {
            state.addedToCartItems.splice(index, 1);
        }
        if (state.addedToCartItems.length < AppParameters.max_allowed_items_in_cart) {
            state.cartLimitReached = false;
        }
    },
    SET_CART_ITEMS(state, items) {
        state.addedToCartItems = items;
    },
    SET_SHOW_CART_QUE(state, show) {
        state.showCartQue = show;
    },
    SET_MEMBER(state, isMember) {
        state.isMember = isMember;
    },
    SET_ACTIVE_TRIAL(state, hasTrial) {
        state.isActiveTrial = hasTrial;
    },
    MAKE_CART_EMPTY() {
        state.addedToCartItems = [];
    },
};

const actions = {
    async getProductPrice({ commit }, { sku, type }) {
        commit('SET_LOADING', { type, loading: true });
        try {
            const response = await CartApi.getProductPrice(sku);
            if (response && response.data) {
                const prices = response.data.is_member ? response.data.member[sku] : response.data.general[sku];
                const frequencies = Object.keys(prices);
                commit('SET_PRICES', { sku, prices, type });
                commit('SET_AVAILABLE_FREQUENCIES', frequencies);
                commit('UPDATE_PRICES');
            }
        } catch (error) {
            console.error(`Error fetching product price for SKU: ${sku}`, error);
        } finally {
            commit('SET_LOADING', { type, loading: false });
        }
    },
    updateSelection({ commit }, selection) {
        commit('SET_SELECTION', selection);
    },
    setFrequency({ commit }, frequency) {
        commit('SET_FREQUENCY', frequency);
        commit('UPDATE_PRICES');
    },
    setQuantity({ commit }, quantity) {
        commit('SET_QUANTITY', quantity);
        commit('UPDATE_PRICES');
    },
    addToCart({ commit }, item) {
        commit('ADD_TO_CART', item);
        commit('SET_AVAILABLE_FREQUENCIES');
        commit('UPDATE_PRICES');
    },
    removeFromCart({ commit }, item) {
        commit('REMOVE_FROM_CART', item);
        commit('SET_AVAILABLE_FREQUENCIES');
        commit('UPDATE_PRICES');
    },
    setCartItems({ commit }, items) {
        commit('SET_CART_ITEMS', items);
        commit('SET_AVAILABLE_FREQUENCIES');
        commit('UPDATE_PRICES');
    },
    setShowCartQue({ commit }) {
        commit('SET_SHOW_CART_QUE', true);
    },
    setHideCartQue({ commit }) {
        commit('SET_SHOW_CART_QUE', false);
    },
    setMember({ commit }, isMember) {
        commit('SET_MEMBER', isMember);
        commit('UPDATE_PRICES');
    },
    setActiveTrial({ commit }, activeTrial) {
        commit('SET_ACTIVE_TRIAL', activeTrial);
        commit('UPDATE_PRICES');
    },
    setTrialDiscountEnabled({ state }, enabled) {
        state.trialDiscountEnabled = enabled;
    },
    setTrialDiscount({ state }, discount) {
        state.trialDiscount = discount;
    },
    emptyCart({ commit }) {
        commit('MAKE_CART_EMPTY');
        commit('UPDATE_PRICES');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
