<template>
    <div>
        <v-row dense>
            <v-col>

                <h1 class="oxygen white--text font-weight-light text-center mb-8">
                    Flexible Premium Subscription Offerings to Fit Every Need and Budget
                </h1>

                <p class="roboto accent2--text text-center text-uppercase font-weight-bold mb-8">
                    Subscription Options
                </p>

            </v-col>
        </v-row>

        <v-row
            dense
            class="mb-8 d-flex align-center"
            justify="center">
            <v-col
                :class="{'mx-2': !isMobilePort}"
                cols="auto">
                <v-card>
                    <v-card-text class="text-center">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-img
                                    max-width="120"
                                    :src="asset(require('../../../images/icons/diamond_single.png'), 'default')">
                                </v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="mb-2 text-uppercase font-weight-bold">
                                    Premium Singles
                                </p>
                                <p class="mb-2">
                                    Individual Titles
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn
                                    :x-large="true"
                                    dark
                                    color="primary"
                                    @click="browseSinglesClicked">
                                    Browse Singles
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                    class="column-complete"
                    :class="{'mx-5': isMobilePort}"
                    cols="auto">
                <v-card class="accent2">
                    <v-card-text class="text-center white--text">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-img
                                    max-width="120"
                                    :src="asset(require('../../../images/icons/diamond_complete.png'), 'default')">
                                </v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="text-uppercase font-weight-bold mb-2">
                                 Premium Complete
                                </h2>
                                <p class="mb-2">
                                   Largest Collection of ICC Codes, Standards and More
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn
                                    :x-large="true"
                                    @click="premiumSubscribe">
                                    Subscribe Now
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                :class="{
                    'mx-2': !isMobilePort,
                }"
                cols="auto">
                <v-card>
                    <v-card-text class="text-center">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-img
                                    max-width="120"
                                    :src="asset(require('../../../images/icons/diamond_collection.png'), 'default')">
                                </v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="text-uppercase font-weight-bold mb-2">
                                    Premium Collections
                                </p>

                                <p class="mb-2">
                                    Bundled Group of Related Contents
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn
                                    :x-large="true"
                                    dark
                                    color="primary"
                                    @click="browseCollectionsClicked">
                                    Browse Collections
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row
            class="enterprise-text white--text roboto"
            justify="center">
            <v-col cols="auto">
                <p class="mb-0">
                    Contact Digital Codes Sales Specialist Phil Anthony
                    (<a href="mailto:panthony@iccsafe.org" class="accent2--text text-decoration-underline">panthony@iccsafe.org</a>) for Enterprise inquiries
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        mixins: [Mobile],
        methods: {
            premiumSubscribe() {
                this.getPremiumCompleteBook();
            },
            browseSinglesClicked() {
                this.goToRoute('codes');
            },
            browseCollectionsClicked() {
                this.goToRoute('codes_category', {
                    category: 'collections'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-card {
        width: 300px;
        border-radius: 20px!important;
    }
    .column-complete {
        z-index: 1;
    }
    .enterprise-text {
        position: relative
    }
</style>
