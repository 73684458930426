var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-3", attrs: { id: "breadcrumb" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: !_vm.isExtraLarge } },
        [
          _c(
            "v-row",
            { attrs: { dense: "", "no-gutters": "" } },
            [
              _c("v-col", [
                _c(
                  "h1",
                  { staticClass: "primary--text font-weight-regular title" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.title) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.request === "library"
                ? _c(
                    "v-cols",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-btn", { attrs: { dark: "", color: "accent2" } }, [
                        _vm._v(
                          "\n                    Sync Library\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-btn", { attrs: { dark: "", color: "accent2" } }, [
                        _vm._v(
                          "\n                    View Entire Library\n                "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }