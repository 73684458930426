<template>
    <v-card>
        <v-card-text>
            <v-row class="text-center">
                <v-col>
                    <p class="mb-0">
                        No results found
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                            dark
                            color="primary"
                            small
                            @click="searchAcrossAllTitlesClicked">
                        Search {{ defaultSearch }} across All Titles
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    methods: {
        searchAcrossAllTitlesClicked() {
            this.goToRoute('sections_search_page', {
                'section': 'titles',
                'searchTermAny': this.defaultSearch
            });
        }
    }
}
</script>
