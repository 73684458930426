var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "offset-y": "",
        "max-width": "100%",
        attach: "#filterMenu",
        absolute: "",
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { outlined: "", depressed: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v("\n            Filter\n        ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function ($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                        Filter your Premium content for more precise results.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          class: { "mb-2": _vm.isMobilePort },
                          attrs: {
                            block: _vm.isMobilePort,
                            small: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clearAll()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Clear All\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: _vm.isMobilePort,
                            small: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.closeMenu()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Close\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          clearable: "",
                          label: "Search Year, State, Title, Topic, Term",
                          "hide-details": "",
                          "prepend-inner-icon": "mdi-magnify",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.closeMenu.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.searchFilter,
                          callback: function ($$v) {
                            _vm.searchFilter = $$v
                          },
                          expression: "searchFilter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _vm._l(_vm.filterData, function (items, key) {
                    return [
                      _c(
                        "v-col",
                        { key: key, staticClass: "py-0", attrs: { sm: "3" } },
                        [
                          _c(
                            "v-menu",
                            {
                              key: key,
                              attrs: {
                                "offset-y": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  block: "",
                                                  height: "40",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(_vm.upperFirst(key)) +
                                                "\n                                    "
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { right: "" } },
                                              [
                                                _vm._v(
                                                  "\n                                        mdi-chevron-down\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menus[key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.menus, key, $$v)
                                },
                                expression: "menus[key]",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("filter-dropdown", {
                                attrs: {
                                  label: _vm.upperFirst(key),
                                  "filter-key": key,
                                  filters: items,
                                },
                                on: {
                                  updateActive: _vm.activateFilter,
                                  closeMenu: function ($event) {
                                    _vm.menus[key] = false
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.mergedFilters
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._l(_vm.mergedFilters, function (filters, key) {
                            return _vm._l(filters, function (item, keyb) {
                              return _c(
                                "v-chip",
                                {
                                  key: keyb,
                                  staticClass: "mr-2 mb-2",
                                  attrs: { close: "" },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.activateFilter(item, key, true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.name) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }