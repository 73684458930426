<template>
    <div>
        <div v-show="expand">
            <div
                v-for="(value, index) in visibleTitleScopes"
                :key="index"
                class="pb-2">
                <v-chip
                    :outlined="isActiveTitleGroup !== value.key"
                    :text-color="isActiveTitleGroup === value.key ? 'primary' : 'black'"
                    @click="activeScope(value)">
                    {{ getGroupName(value) }}
                </v-chip>
            </div>
            <div class="text-right mt-3"
                 v-if="activeTitleGroup">
                <v-btn
                    text
                    small
                    color="gray darken-2"
                    @click="removeTitleScope()">
                    Reset
                </v-btn>
            </div>
        </div>
        <div v-show="!expand">
            <v-chip
                v-if="activeTitleGroup"
                x-small
                class="pr-1 mt-1 text-wrap"
                color="primaryLight"
                text-color="primary"
                style="height: auto;">
                {{ this.activeTitleGroup.title }}
                <v-icon @click="removeTitleScope()"
                        size="19">close
                </v-icon>
            </v-chip>
        </div>
    </div>
</template>

<script>
import { EventNames } from "../../../../classes/EventNames";
import TabbedSearch from "../../mixins/TabbedSearch";

export default {
    data() {
        return {
            activeTitleGroup: null
        }
    },
    watch: {
        filterGroups: {
            handler(value) {
                const activeGroup = value[this.$getConst('titleSearchType')];
                this.activeTitleGroup = activeGroup && activeGroup.key ? activeGroup : null;
            },
            deep: true
        }
    },
    computed: {
        activeTitleGroups() {
            return this.filterGroups[this.$getConst('titleSearchType')] || [];
        },
        isActiveTitleGroup() {
            return this.activeTitleGroups ? this.activeTitleGroups.key : null;
        },
        visibleTitleScopes() {
            const hasSubscription = !this.isActiveUser || !this.hasPremiumSubscription;
            return (this.titleGroups).filter((scope) => {

                if (scope.key === 'recently_viewed' || scope.key === 'mine') {
                    return !hasSubscription;
                }

                return true;
            });
        }
    },
    mixins: [TabbedSearch],
    props: {
        expand: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        activeScope(scope) {
            this.activeTitleGroup = scope;
            this.$store.commit('setTabbedGroups', {
                tab: this.$getConst('titleSearchType'),
                group: scope
            });
        },
        getGroupName(item) {
            return this.titleGroups.find((group) => group.key === item.key).title;
        },
        removeTitleScope() {
            this.$store.commit('setTabbedGroups', {
                tab: this.$getConst('titleSearchType'),
                group: {}
            });
            this.activeTitleGroup = null;
        }
    },
    mounted(){
        const activeGroup = this.filterGroups[this.$getConst('titleSearchType')];
        this.activeTitleGroup = activeGroup && activeGroup.key ? activeGroup : null;
        EventBus.listen(EventNames.REMOVE_TITLE_GROUP, () => this.removeTitleScope());
    }
}
</script>