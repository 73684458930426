var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "accent--text system-banner-text",
      attrs: { align: "center", dense: "" },
    },
    [
      _c("v-col", { staticClass: "text-center" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "accent", icon: "", small: "", text: "" },
              on: {
                click: function ($event) {
                  return _vm.dismissBanner()
                },
              },
            },
            [
              _c("v-icon", [
                _vm._v("\n                mdi-close\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }