<template>
    <v-row dense>
        <v-col
            v-for="(category, key) in categoryList"
            :key="key"
            cols="12"
            sm="3">
            <template v-if="hasSubCategory(category.children) && isMediumAndUp">
                <v-menu open-on-click offset-y :nudge-left="calculateNudgeLeft(category)" :transition="false" max-width="90%">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            block
                            :x-large="xLargeButtons"
                            color="primary"
                            v-on="on">
                            {{ category.name }} <v-icon>arrow_drop_down</v-icon>
                        </v-btn>
                    </template>
                    <v-list :style="calculateColumnCountStyle(category)" class="list_with_cols">

                        <v-list-item :href="getCategoryUrlFromSlug(category.slug)">
                            <v-list-item-title>Browse All {{ category.name }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-for="(subcategory, index) in category.children"
                            :key="index"
                            :href="getCategoryUrlFromSlug(subcategory.slug)">
                            <v-list-item-title>{{ subcategory.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-else>
                <v-btn
                    block
                    :x-large="xLargeButtons"
                    color="primary"
                    @click="visitCategory(category.key)">
                    {{ category.name }}
                </v-btn>
            </template>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from "../../inc/mixins/mobile";
    import Categories from "../../inc/mixins/categories";

    export default {
        mixins: [Mobile, Categories],
        data () {
            return {
                categoryList: []
            }
        },
        props: {
            xLargeButtons: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            itemFlexBasis() {

                let totalButtons = this.categoryList.length;
                let maxButtonsPerRow = 6;

                if (this.isExtraLarge) {
                    // >1904
                    maxButtonsPerRow = 6;
                } else if (this.isLargeAndUp) {
                    // >1264
                    maxButtonsPerRow = 5;
                } else if (this.isMediumAndUp) {
                    // >960
                    maxButtonsPerRow = 4;
                }

                if (this.isMediumAndUp) {
                    let rowCount = Math.ceil(totalButtons / maxButtonsPerRow);
                    let temp = Math.ceil(totalButtons / rowCount);
                    return `calc(100%/${temp})`;
                }

                return 'auto';
            }
        },
        methods: {
            calculateNudgeLeft(collection) {
                let cols = Math.ceil(collection.children.length / 10);
                if (cols < 2) return 0;
                return Math.round(cols / 2) * 120;
            },
            calculateColumnCountStyle(collection) {
                // 10 items per column MAX
                let cols = Math.ceil(collection.children.length / 10);
                return `column-count: ${cols};`;
            },
            getCategoryUrlFromSlug(slug) {
                return this.getRoute('codes_category', {category: slug});
            },
            visitCategory(slug) {
                this.goToRoute('codes_category', {category: slug});
            },
            populateCategories() {

                /** @type {{Category: Array, Location: Array}} */
                const data = this.$store.getters.getSearchNavigationCategories;

                this.categoryList.push({
                    name: 'Locations',
                    children: data.Location
                });

                data.Category.forEach((category) => {

                    if (this.categoriesToInclude.includes(category.name)) {
                        this.categoryList.push(category);
                    }
                });

                this.categoryList = this.sortedCategories(this.categoryList)
            },
            hasSubCategory(val) {
                return _.size(val) > 0;
            }
        },
        mounted() {
            this.populateCategories();
        }
    }
</script>

<style scoped>
.list_with_cols > a {
    /* for Chrome and Edge */
    break-inside: avoid-column;
}
</style>
