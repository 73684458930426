<template>
    <div
        id="breadcrumb"
        class="grey lighten-3">
        <v-container :fluid="!isExtraLarge">
            <v-row
                dense
                no-gutters>
                <v-col>
                    <h1 class="primary--text font-weight-regular title">
                        {{title}}
                    </h1>
                </v-col>
                <v-cols
                    v-if="request === 'library'"
                    cols="auto">
                    <v-btn
                        dark
                        color="accent2">
                        Sync Library
                    </v-btn>
                    <v-btn
                        dark
                        color="accent2">
                        View Entire Library
                    </v-btn>
                </v-cols>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        props: {
            title: {
                required: true,
                type: String
            },
            request: {
                required: false,
                type: String
            }
        },
        mixins: [Mobile]
    }
</script>
