<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h2 class="primary--text headline">
                        <span class="licenses-step-3">Assigned By Me</span>
                    </h2>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="primary licenses-step-3_1"
                        @click="openAssignModal()">
                        Assign Subscription
                    </v-btn>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="ml-1 mr-2 primary"
                        @click="refreshSubscriptions('AssignedByMe')">
                        <span v-if="!isRefreshing">Refresh Licenses</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="caption mb-0">
                        Assigned by Me licenses are those where the individual who purchases the premium subscription is different than the individual who is able to use the premium subscription.  Assigned licenses are dedicated access to the individual as identified by the email address.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-expansion-panels accordion>
                        <v-data-iterator
                            ref="assigned_by_me_table"
                            :items="subscriptions"
                            :items-per-page="pagination.itemsPerPage"
                            :loading="loading"
                            :single-expand="true"
                            :options.sync="pagination"
                            :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                            class="elevation-0 no-margin parent-row"
                            :server-items-length="pagination.totalItems">
                            <template v-slot:header>
                                <table class="subscription-table assigned-by-me" aria-label="Assigned By Me Subscription Details">
                                    <thead>
                                        <tr>
                                            <th
                                                v-for="(header, index) in headers"
                                                :key="index"
                                                :class="{
                                                    'pl-3 text-left title-section': index === 0,
                                                    'pr-3 pl-2 text-right': index === 1
                                                }"
                                                class="mr-2 caption font-weight-bold">
                                                {{header.text}}
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </template>
                            <template v-slot:default="data">
                                <table class="subscription-table assigned-by-me" aria-label="Assigned By Me Subscription Details">
                                    <tbody>
                                        <template v-for="assignment in data.items">
                                            <tr>
                                                <td colspan="5">
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header @click="checkPanelEvent(assignment)">
                                                            <table class="subscription-sub-table assigned-to-me">
                                                                <tbody>
                                                                    <tr :key="assignment.id">
                                                                        <td class="title-section">
                                                                            <a @click="bookLink(assignment.document_info)" class="book-titles mr-1">
                                                                                <graphic
                                                                                    :title="assignment.document_info"
                                                                                    thumbnail
                                                                                    width="50px"
                                                                                    >
                                                                                </graphic>
                                                                            </a>
                                                                            <a @click="bookLink(assignment.document_info)" class="book-titles">
                                                                                <book-title
                                                                                    :title="getBookTitle(assignment.document_info)"
                                                                                    :print_version_edition="printEdition(assignment.document_info.print_version_edition.version)"
                                                                                    :printing_text="assignment.document_info.print_info">
                                                                                </book-title>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="grey lighten-4">
                                                            <v-data-table
                                                                :headers="assigned"
                                                                :items="assignment.assigned_by_me_licenses"
                                                                :loading="detailsLoading"
                                                                :items-per-page="-1"
                                                                hide-default-footer
                                                                class="no-margin details-row">
                                                                <template v-slot:item="{ item }">
                                                                    <tr class="lighten-5" :class="getStatusClass(item)">
                                                                        <td class="subtitle-2"></td>
                                                                        <td v-if="item.assigned_to" class="subtitle-2">{{item.assigned_to.email}}</td>
                                                                        <td v-else class="subtitle-2">{{item.pending_user_email}}
                                                                            <v-tooltip bottom>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-icon v-on="on" color="red">info</v-icon>
                                                                                </template>
                                                                                <span class="tooltip-content">This user cannot see this subscription because there is no account associated to this email address</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                        <td class="subtitle-2">{{item.created_at | moment("MMMM Do, YYYY")}}</td>
                                                                        <td class="subtitle-2">{{getExpirationDate(assignment, item) | moment("MMMM Do, YYYY")}}</td>
                                                                        <td class="subtitle-2">{{checkSubscriptionType(item.subscription_type)}}</td>
                                                                        <td class="subtitle-2">{{checkStatus(getExpirationDate(assignment, item), item.status)}}</td>
                                                                        <td class="text-center subtitle-2">
                                                                            <template v-if="checkStatus(getExpirationDate(assignment, item), item.status) === 'Active'">
                                                                                <v-btn small color="primary" dark @click="editItem(item)">Reassign</v-btn>
                                                                                <v-btn small color="error" dark @click="deleteItem(item)">Revoke</v-btn>
                                                                            </template>
                                                                            <template v-else-if="checkStatus(getExpirationDate(assignment, item), item.status) !== 'Revoked'">
                                                                                <v-btn small color="primary" dark @click="goToTheShop(shopEncodesUrl)">Renew</v-btn>
                                                                            </template>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </v-data-table>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </template>
                            <template slot="no-data">
                                <div
                                    v-if="subscriptions.length == 0 && !loading"
                                    row
                                    class="pt-12 text-md-center">
                                    <p>No data available!</p>
                                </div>
                                <div
                                    v-else-if="subscriptions.length == 0"
                                    class="text-center pt-6">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </div>
                            </template>
                        </v-data-iterator>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Re-assign this subscription license</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-alert v-if="errorMessage.length" :value="true" outline color="error" icon="warning">
                                        {{errorMessage}}
                                    </v-alert>
                                    <v-text-field
                                        data-qa="dashboard-licenses-reassign-email"
                                        v-if="editedItem.assigned_to"
                                        v-model="editedItem.assigned_to.email"
                                        label="Enter new email address">
                                    </v-text-field>
                                    <v-text-field
                                        data-qa="dashboard-licenses-reassign-email"
                                        v-else
                                        v-model="editedItem.pending_user_email"
                                        label="Enter new email address">
                                    </v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close" v-if="!reassign_loading">Cancel</v-btn>
                        <v-btn color="primary" @click="save" :disabled="reassign_loading">
                            <span v-if="!reassign_loading">Save</span>
                            <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <AssignModal :user-id="userId"></AssignModal>
        </v-container>
    </div>
</template>
<script>
    import InfoBanner from '../../banners/info_banner';
    import Licenses from '../../inc/mixins/licenses.js';
    import AssignedByMe from './mixins/assigned_by_me.js';
    import Graphic from "../../titles/cover/Graphic";
    import Constants from "../../../constants/index";

    export default {
        mixins: [Licenses, AssignedByMe],
        components: {
            InfoBanner,
            Graphic
        },
        data() {
            return {
                headers: [
                    {text: 'Book Title', align: 'left', value: 'di.title'},
                    // {text: 'Assigned licenses', align: 'right', value: 'license_configuration.assigned'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false}
                ],
                assigned: [
                    {text: '', sortable: false},
                    {text: 'Assigned To', align: 'left', value: 'assigned_to.email'},
                    {text: 'Assigned Date', value: 'created_at'},
                    {text: 'Expiration Date', value: 'expiration_date'},
                    {text: 'Subscription Type', align: 'left', value: 'subscription_type'},
                    {text: 'Subscription Status', align: 'left', value: 'status'},
                    {text: 'Actions', value: 'name', align: 'center', sortable: false}
                ],
                panel: [],
                shopEncodesUrl: Constants.shopEncodesUrl
            };
        },
        created() {
            this.getAssignedByMeSubscriptions();
        }
    }

</script>
