<template>
    <v-card outlined>
        <v-card-text>
            <v-form ref="form" @submit.prevent="submitFormData">
                <h5 class="mb-2 text-h5 font-weight-medium">Ask New Question</h5>
                <p class="text-body-1 mb-8">
                    Enter your question in the form below.  Please note:
                    <ul>
                        <li>
                            Code opinions are not a full plan review.  While a drawing may be submitted for our information,
                            the ICC does not provide any plan review as part of this service.
                        </li>
                        <li>
                            The Quick Consult service provides answers to single questions.
                            A submission that requests an opinion on multiple code sections or that asks multiple
                            questions that require multiple answers may be rejected.
                        </li>
                        <li>
                            * Indicates a required field
                        </li>
                    </ul>
                </p>
                <div class="my-5">
                    <h6 class="text-h6 font-weight-medium">Code Title and Section</h6>
                    <p class="text-body-2 text--secondary mb-2">Select the International Codes (I-Code) that your question applies to.</p>
                    <v-row>
                        <v-col :cols="isMobilePort ? 12 : 6">
                            <v-autocomplete
                                v-model="codeTitle"
                                :items="titles"
                                :loading="titlesLoading"
                                :search-input.sync="titleSearch"
                                no-filter
                                outlined
                                hide-no-data
                                clearable
                                hide-details
                                append-icon=""
                                item-text="display_title"
                                item-value="display_title"
                                label="Code Title"
                                @keydown="debouncedGetResults"
                                :menu-props="{
                                    maxWidth: '410',
                                    maxHeight: '330',
                                }"
                                >
                                <template v-slot:item="{ item }">
                                    <title-list
                                        style="width: 100%"
                                        :title="item"
                                        type="list"
                                        :showLinkIcon="false"
                                        :listOutline="false"
                                        :preventLink="true"
                                        :hover="false"
                                        background="transparent">
                                    </title-list>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col :cols="isMobilePort ? 12 : 6">
                            <v-text-field
                                v-model="sectionNumber"
                                hide-details
                                clearable
                                outlined
                                label="Section"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <div class="my-5">
                    <h6 class="text-h6 font-weight-medium">Question</h6>
                    <p class="text-body-2 text--secondary mb-2">
                        Include all the information the ICC team would need to answer your question.
                    </p>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="subject"
                                :rules="[rules.required, rules.maxLength(255)]"
                                label="Question Subject*"
                                outlined
                                hint="Provide a short summary of your question"
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="questionDetails"
                                :rules="[rules.required]"
                                label="Question Details*"
                                hint="Provide a detailed description of your question"
                                persistent-hint
                                outlined>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-file-input
                                v-model="attachments"
                                outlined
                                show-size
                                multiple
                                label="Attachments"
                                hint="Attachment size should not exceed 5 MB"
                                persistent-hint
                                :rules="[fileSizeRule, fileTypeRule]"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </div>
                <div class="my-5">
                    <h6 class="text-h6 font-weight-medium">Contact Information</h6>
                    <p class="text-body-2 text--secondary mb-2">
                        The ICC team may contact you directly to clarify your question.
                    </p>
                    <v-row>
                        <v-col>
                            <v-text-field
                                    v-model="email"
                                    readonly
                                    label="Email (Read-only)"
                                    outlined
                                ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="phone"
                                    label="Phone (Optional)"
                                    outlined
                                    :rules="[rules.phone]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0">
                            <v-checkbox
                                class="pt-0 mt-0"
                                hide-details
                                :rules="[v => !!v || 'You must accept the terms and conditions']"
                                v-model="termsAccepted">
                                <template v-slot:label>
                                    <p class=" mb-0">
                                        I accept the <a
                                            href=""
                                            @click.prevent.stop="clickedTermsOfService"
                                            class="text-decoration-underline">Terms of Service</a>
                                    </p>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <p class="mb-0 py-2">Submitting your question will use 1 credit. You will have {{ remainingCredits }} credits remaining.</p>
                    <v-row>
                        <v-col>
                            <div class="d-flex">
                                <v-btn
                                    depressed
                                    type="submit"
                                    :loading="isProcessing"
                                    color="primary">
                                    Submit Question
                                </v-btn>
                                <div class="mx-1"></div>
                                <v-btn
                                    outlined
                                    color="primary"
                                    :href="this.getRoute('quick_consult')">
                                    Cancel
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-form>

            <ask-question-terms-of-service-dialog
                    :visible="termsOfServiceDialogVisible"
                    @closed="termsOfServiceDialogVisible = false">
            </ask-question-terms-of-service-dialog>

        </v-card-text>
    </v-card>
</template>

<script>
import TitleList from '../../../components/titles/cover/Index.vue';
import mobile from '../../inc/mixins/mobile';
import { QuickConsultApi } from '../../../api/QuickConsultApi';
import { mapActions, mapGetters } from 'vuex';
import AskQuestionTermsOfServiceDialog from "./AskQuestionTermsOfServiceDialog.vue";
import ConfirmTermsDialog from "../../navigator/ConfirmTermsDialog.vue";

export default {
    data() {
        return {
            termsAccepted: false,
            isProcessing: false,
            codeTitle: '',
            codeYear: '',
            chapter: '',
            sectionNumber: '',
            subject: '',
            questionDetails: '',
            attachments: [],
            titlesLoading: false,
            titleSearch: '',
            email: '',
            phone: '',
            rules: {
                required: value => !!value || 'This field is required.',
                phone: value => !value || /^[+]{0,1}[\d]{0,12}$/.test(value),
                maxLength: (max) => (value) => value.length <= max || `Field must be ${max} characters or less.`,
            },
            titles: [],
            allowedFileTypes: [
                'image/jpeg',
                'image/png',
                'image/gif',
                'application/pdf',
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
            ],
            termsOfServiceDialogVisible: false
        }
    },
    components: {ConfirmTermsDialog, AskQuestionTermsOfServiceDialog, TitleList},
    props: {
        creditBalance : {
            type: Number,
            required: false
        }
    },
    mixins: [mobile],
    computed: {
        ...mapGetters('NotificationModule', [
            'notificationType',
            'notificationMessage'
        ]),
        remainingCredits() {
            return this.creditBalance > 0 ? this.creditBalance - 1 : 0
        }
    },
    methods: {
        ...mapActions('NotificationModule', [
            'setNotificationMessage',
            'dismissAlert'
        ]),
        async submitFormData(){
            if (this.$refs.form.validate()) {
                this.isProcessing = true;
                const formData = new FormData();
                formData.append('code_title', this.codeTitle);
                formData.append('code_section', this.sectionNumber);
                formData.append('title', this.subject);
                formData.append('description', this.questionDetails);
                formData.append('phone', this.phone);

                this.attachments.forEach((file, index) => {
                    formData.append(`attachments[${index}]`, file);
                });
                QuickConsultApi.postQuestion(formData).then((response) => {
                    this.goToRoute('quick_consult', {'successMessage': true});
                }).catch((error)=>{
                    let notifyObj  = {
                        success: false,
                        message: ''
                    }
                    if (!error.response) {
                        notifyObj.message = 'Network error: Something went wrong or you are currently offline.';
                    } else {
                        notifyObj.message = error.response.data?.error || 'An unexpected error occurred.';
                    }
                    this.setNotificationMessage(notifyObj);

                    setTimeout(() => this.$scrollTo(
                        document.getElementById('display_http_messages'),
                        {offset: -140}
                    ), 500);
                })
                .finally(() => {
                    this.isProcessing = false;
                });

            } else {
                console.log('Validation error');
            }

        },
        loadTitles(limit = 5){
            this.titles = [];
            this.titlesLoading = true;
            let postData = {
                search : this.titleSearch,
                forQuickAccess: 0,
                category: ['I-Codes', 'ICC Standards'],
                limit
            };
            QuickConsultApi.titleSearchAutoComplete(postData).then((response) => {
                this.titles = response;
            })
            .finally(()=>{
                this.titlesLoading = false;
            })
        },
        fileSizeRule(value) {
            const maxSize = 5 * 1024 * 1024; // 5MB in bytes

            // Check if any file exceeds the size limit
            const oversizedFile = value?.some(file => file.size > maxSize);
            if (oversizedFile) {
                return 'Each file must be smaller than 5MB.';
            }
            return true; // Validation passes
        },
        fileTypeRule(value) {
            // Check if any file has an unsupported type
            const unsupportedFile = value?.some(file => !this.allowedFileTypes.includes(file.type));
            if (unsupportedFile) {
                return `Only the following file types are allowed: JPEG, PNG, GIF, PDF, DOC, DOCX.`;
            }
            return true; // Validation passes
        },
        clickedTermsOfService() {
            this.termsOfServiceDialogVisible = true;
            return false;
        }
    },
    created() {
        this.loadTitles(20);
        this.debouncedGetResults = _.debounce(() => this.loadTitles(), 500);
        this.$store.watch(() => this.$store.getters.getUser, (user) => {
            if (user) {
                this.email = user.email
            }
        }, {
            immediate: true
        });
    }
}
</script>
