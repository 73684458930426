import rangy from 'rangy';

export class SelectionUtil {

    /**
     *
     * @param {HTMLElement} element
     * @returns {Range}
     */
    static getSelectionRangeWithin(element) {

        if (!element) {
            return null;
        }

        const sel = rangy.getSelection();
        const elRange = rangy.createRange();
        elRange.selectNodeContents(element);

        let result = null;

        for (let i = 0; i < sel.rangeCount; i++) {
            let temp = sel.getRangeAt(i).intersection(elRange);

            if (temp) {
                result = temp;
                break;
            }
        }

        elRange.detach();

        return result;
    }

    /**
     *
     * @return {HTMLDivElement}
     */
    static selectionAsElement() {

        let selection = window.getSelection();
        let container = document.createElement("div");
        for (let i = 0, len = selection.rangeCount; i < len; ++i) {
            container.appendChild(selection.getRangeAt(i).cloneContents());
        }

        return container;
    }

    /**
     *
     * @param {Range} range
     * @return {Element}
     */
    static rangeToElement(range) {

        let container = document.createElement("div");
        container.appendChild(range.cloneContents());
        return container;
    }

    /**
     * Get the caret offset of the start and stop nodes in the highlighted text.
     *
     * @param {Element} element Element used to identify offset.
     * @param {Range} range
     * @param position Used to determine to return start or end offset.
     * @return {int} Return the offset.
     */
    static getOffsetsFromRange(element, range, position) {

        let preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);

        if (position === 'start') {
            preCaretRange.setEnd(range.startContainer, range.startOffset);
            return this.trimWhitespace(preCaretRange.toString()).length;
        }

        if (position === 'end') {
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            return this.trimWhitespace(preCaretRange.toString()).length;
        }
    }
    static trimWhitespace(text) {
        // Replace multiple spaces with a single space and trim leading spaces
        // Removed the trim left AND right because we need the whitespaces in the end to correctly find the offset
        // otherwise it highlights fewer chars
        return text.replace(/\s+/g, ' ').trimLeft();
    }
}
