var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: _vm.isMobilePort ? 12 : 4 } },
        [
          _c(
            "v-sheet",
            {
              staticClass: "fill-height rounded-lg",
              staticStyle: { border: "1px solid #E0E0E0" },
              attrs: { rounded: "" },
            },
            [
              _c("v-card-text", { staticClass: "pa-6" }, [
                _c(
                  "h2",
                  { staticClass: "fs-24 font-weight-bold" },
                  [
                    _c("v-icon", { attrs: { color: "primaryMedium" } }, [
                      _vm._v(
                        "\n                        mdi-lock-open-variant-outline\n                    "
                      ),
                    ]),
                    _vm._v("\n                    Basic\n                "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fs-16 font-weight-medium my-6" }, [
                  _vm._v(
                    "\n                    Access a limited selection of read-only titles for free.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          outlined: "",
                          target: "_blank",
                          href: !_vm.isActiveUser
                            ? this.getRoute("signup")
                            : this.getRoute("sections_search_page"),
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              !_vm.isActiveUser ? "Sign Up" : "View all titles"
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: _vm.isMobilePort ? 12 : 4 } },
        [
          _c(
            "v-sheet",
            {
              staticClass: "fill-height rounded-lg",
              staticStyle: { border: "1px solid #E0E0E0" },
              attrs: { rounded: "" },
            },
            [
              _c("v-card-text", { staticClass: "pa-6" }, [
                _c(
                  "h2",
                  { staticClass: "fs-24 font-weight-bold" },
                  [
                    _c("v-icon", { attrs: { color: "cdpBlue" } }, [
                      _vm._v(
                        "\n                        mdi-account-group-outline\n                    "
                      ),
                    ]),
                    _vm._v("\n                    Teams\n                "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fs-16 font-weight-medium my-6" }, [
                  _vm._v(
                    "\n                    Empower collaboration within your organization.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          outlined: "",
                          href: `mailto:${this.$getConst(
                            "sales_email_address"
                          )}`,
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Contact Sales\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          text: "",
                          target: "_blank",
                          href: this.getRoute("concurrent-access"),
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Learn More\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }