<template>
    <v-row class="accent2Light">
        <v-col class="text-center">
            <p class="font-weight-bold mb-0">
                <v-icon color="accent2">
                    mdi-creation
                </v-icon>
                Try Premium Complete FREE for 14 days
                <start-free-trial
                    color="accent2"
                    class="font-weight-bold fs-14 ml-1"
                    :class="isMobilePort ? 'mt-2' : ''"
                    :large="false">
                </start-free-trial>
            </p>
        </v-col>
    </v-row>
</template>
<script>
import mobile from '../inc/mixins/mobile';
import StartFreeTrial from '../buttons/StartFreeTrial';

export default {
    mixins: [mobile],
    components: {StartFreeTrial}
}
</script>