<template>
    <div>
        <p class="font-weight-bold mt-5">
            Search Tips:
        </p>

        <ol>
            <li>Check the spelling of your search term.</li>
            <li>Widen your search by using fewer or more general terms.</li>
            <li>Reset some or all of your search filters</li>
        </ol>

        <p class="font-weight-bold mt-5">Still can’t find what you are looking for?</p>
        <ul>
            <li>
                <a @click="initLiveChat()">Chat with us</a>
            </li>

            <li>
                <a href="mailto:customersuccess@iccsafe.org">Email us</a>
            </li>

            <li>
                <a @click="showFeedbackModal()">Submit Feedback</a>
            </li>

            <li>
                Call <a href="tel:1-888-422-7233">888-ICC-SAFE</a> (<a href="tel:1-888-422-7233">888-422-7233</a>).
            </li>
        </ul>
    </div>
</template>

<script>
import FeedbackChat from "../../inc/mixins/feedback_chat";

export default {
    mixins: [
        FeedbackChat
    ]
}
</script>
