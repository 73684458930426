<template>
    <div>
        <v-row
            v-if="message"
            justify="center">
            <v-col cols="auto">
                <v-card>
                    <v-card-text>
                        <p class="mb-0">
                            {{message}}
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                v-if="loading"
                class="text-center my-12">
                <v-progress-circular
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </v-col>
            <template v-else>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(subject, key) in subjects"
                    :key="key">
                    <v-card
                        dark
                        flat
                        :color="subject.color"
                        @click="showAstmTitles(subject)"
                        min-height="100%">
                        <v-card-text>
                            <v-row align="center">
                                <v-col
                                    v-if="subject.icon"
                                    cols="auto">
                                    <v-icon
                                        x-large>
                                        {{subject.icon}}
                                    </v-icon>
                                </v-col>
                                <v-col>
                                    <h2 class="font-weight-regular white--text">
                                        {{subject.name}}
                                    </h2>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>        
    </div>
</template>

<script>
    import Results from './mixins/results';

    export default {
        data() {
            return {
                loading: true,
                message: ''
            }
        },
        mixins: [
            Results
        ]
    }
</script>
