var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_vm.isMobilePort ? [_c("index-mobile")] : [_c("index-desktop")]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }