<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="isMobilePort"
        persistent
        scrollable
        :max-width="340">
        <v-card>
            <v-card-text>
                <div class="pa-3">
                    Please select the date rage.
                    <v-icon medium class="float-right" @click="closeModal()">close</v-icon>
                </div>
                <v-row>
                    <v-col class="pt-1">
                        <v-date-picker
                            v-model="dates"
                            :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                            range>
                        </v-date-picker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-1">
                        <v-btn
                            class="primary white--text mr-2"
                            @click="closeModal()">
                            Cancel
                        </v-btn>
                        <v-btn
                            :disabled="dates.length !== 2"
                            class="primary white--text"
                            @click="applyDateRange()">
                            Apply Date Range
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../../inc/mixins/mobile';

    export default {
        data() {
            return {
                dialog: false,
                dates: []
            }
        },
        mixins: [Mobile],
        methods: {
            renderModal(data) {
                this.dates = data;
                this.dialog = true;
            },
            applyDateRange() {
                EventBus.fire('apply-date-ragne', this.dates);
                this.closeModal();
            },
            closeModal() {
                this.dialog = false;
            }
        },
        created() {
            EventBus.listen('display-date-ragne-modal', (data) => this.renderModal(data));
        }
    }
</script>
