<template>
    <v-container class="content-container py-0">
        <v-row>
            <v-col cols="12">
                <v-row align="center">
                    <v-col cols="auto">
                        <img
                            :src="asset(require('../../../images/logo_dc.png'), 'default')"
                        />
                    </v-col>
                    <v-col>
                        <h1 class="primary--text font-weight-regular">
                            Welcome back to<br/>ICC Digital Codes!
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="isMobilePort ? 12 : 6" class="pt-0">
                <v-row>
                    <v-col>
                        <h4 class="primary--text font-weight-regular">
                            If you don't have an account?
                            <a class="text-decoration-underline font-weight-bold" :href="this.getRoute('signup')">Sign Up</a>
                        </h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h2 class="primary--text font-weight-regular">
                            The most trusted and authentic source of building codes, standards and more
                        </h2>
                    </v-col>
                </v-row>
                <login-form
                    :formAction="formAction"
                    :referer="referer"
                    :token="token">
                </login-form>
            </v-col>
            <v-col :cols="isMobilePort ? 12 : 6" class="pl-16">
                <h3 class="font-weight-regular pb-5">
                    Your credentials will allow you access to all ICC properties including:
                </h3>
                <v-row>
                    <v-col
                        class="pa-0"
                        :cols="isMobilePort ? 12 : 6"
                        v-for="(item, key) in properties"
                        :key="key">
                        <v-row dense>
                            <v-col
                                class="py-0"
                                cols="auto">
                                <v-icon
                                    medium
                                    color="primary">
                                    mdi-circle-small
                                </v-icon>
                            </v-col>
                            <v-col class="py-0">
                                <a
                                    class="text-decoration-underline"
                                    :href="item.link"
                                    target="_blank">
                                    <h4 class="mb-0">
                                        {{ item.text }}
                                    </h4>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-if="!isMobileAndTablet"
                        cols="6">
                        <img
                            max-width="100%"
                            :src="asset(require('../../../images/illistrations/loud_speaker.webp'))"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import Forms from './../mixins/forms';
    import LoginForm from './LoginForm.vue';

    export default {
        data() {
            return {
                properties: [
                    {
                        text: 'Quick Start',
                        link: 'https://support.iccsafe.org/?ht_kb_category=quick-start'
                    },
                    {
                        text: 'ICC Safe',
                        link: 'https://www.iccsafe.org/'
                    },
                    {
                        text: 'MyICC',
                        link: 'https://my.iccsafe.org/'
                    },
                    {
                        text: 'Learning Center',
                        link: 'https://learn.iccsafe.org/'
                    },
                    {
                        text: 'ICC Shop',
                        link: 'https://shop.iccsafe.org/'
                    },
                    {
                        text: 'Assessment Center',
                        link: 'https://www.iccsafe.org/professional-development/certifications-and-testing/assessment-center/'
                    },
                    {
                        text: 'cdpACCESS',
                        link: 'https://www.cdpaccess.com/'
                    },
                ]
            }
        },
        props: {
            formAction: {
                required: false,
                type: String
            },
            referer: {
                required: false,
                type: String
            },
            token: {
                required: true,
                type: String
            },
            loginFailed: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            Mobile,
            Forms
        ],
        components: {
            LoginForm
        },
    }
</script>
