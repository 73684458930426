<template>
    <div>
        <chat-message
                type="system"
                class="mb-1">

            <div v-if="response">

                <text-typed
                        :text="responseText"
                        @start="onServerTypingStart"
                        @done="onServerTypingDone"
                        :stopped="clickedStop">
                </text-typed>

                <div v-show="!isTyping && !clickedStop && responseSections">

                    <div class="font-weight-bold text-sm mt-3 mb-1">Referenced sections</div>

                    <v-chip-group v-model="sectionSelected" column>
                        <v-chip
                                v-for="(chip, index) in responseSections"
                                :key="index"
                                :value="chip.meta_xmlId"
                                :text="displayChipInfo(chip)"
                                small
                                :color="chip.meta_xmlId === sectionSelected ? 'primary' : 'primaryLight'"
                                :class="chip.meta_xmlId === sectionSelected ? 'text--white' : 'primary--text'"
                            >
                            <span v-html="displayChipInfo(chip)" class="ai-reference-section"></span>
                        </v-chip>
                    </v-chip-group>

                    <section-content-proxy
                            :section-meta="selectedChip"
                    ></section-content-proxy>

                </div>
            </div>

            <div v-else-if="clickedStop">
                Response generation was stopped, try entering a new question.
            </div>

            <div v-else-if="error" class="red--text">
                {{ error }}
            </div>

            <text-typed
                    v-else-if="isWaitingServerResponse"
                    :text="randomThinkingText"
                    :stopped="clickedStop"
                    :speed="5">
            </text-typed>
        </chat-message>

        <div class="server-message-stop" v-if="isWaitingServerResponse || isTyping">

            <v-btn
                    text
                    small
                    @click="stopGeneratingClicked"
            >
                <v-icon left>mdi-close</v-icon>
                Stop generating response
            </v-btn>
        </div>

        <div class="server-message-rating text-sm d-flex align-center" v-else-if="responseText && !clickedStop">
            <strong>Rate this answer</strong>

            <v-btn icon @click="rateAnswerClicked(true)">
                <v-icon color="primary">{{ userFeedback === 1 ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
            </v-btn>

            <v-btn icon @click="rateAnswerClicked(false)">
                <v-icon color="primary">{{ userFeedback === -1 ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon>
            </v-btn>
        </div>

    </div>
</template>

<script>
import AssetImage from "../../AssetImage.vue";
import {ApiService} from "../../../api/ApiService";
import ChatMessage from "./ChatMessage.vue";
import {ArrayUtils} from "../../../util/ArrayUtils";
import SectionContent from '../../dashboard/quick_access/section_content.vue';
import SectionContentProxy from "../../SectionContentProxy.vue";
import TextTyped from "../../TextTyped.vue";

export default {
    components: {SectionContentProxy, ChatMessage, AssetImage, TextTyped, SectionContent},
    emits: ['done', 'countdown'],
    props: {
        prompt: {
            type: Object,
            required: true
        },
        questionId: {
            type: Number,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            serverRequest: null,
            thinkingText: [
                'Researching your question, this may take a moment.',
                'Please allow a moment while finding the best answer to your inquiry.',
                'Being accurate is more important than being first.  I am working to compose the best answer based on your inputs.',
                'Please be patient while I analyze all the requirements for your situation.',
                'Considering all the possibilities for your situation while crafting your response.',
            ],
            isWaitingServerResponse: false,
            response: null,
            error: '',
            clickedStop: false,
            isTyping: false,
            sectionSelected: null,
            userFeedback: null,
        }
    },
    computed: {
        randomThinkingText() {
            return ArrayUtils.rand(this.thinkingText);
        },
        responseText() {
            return this.response?.text || "";
        },
        responseSections() {
            return this.response?.sources;
        },
        selectedChip() {

            if (this.sectionSelected) {
                return (this.responseSections || []).find((chip) => {
                    return chip.meta_xmlId === this.sectionSelected;
                })
            }

            return null;
        }
    },
    methods: {
        displayChipInfo(chip){
            var ordinalNumber = '';
            if (_.isEmpty(chip.meta_ordinal)) { // if ordinal number is empty then extract section number through regex
                const xmlId = chip.meta_xmlId;
                const regex = /Sec([A-Za-z0-9]+)_Def/; //for definition
                const match = xmlId.match(regex);
                if (match) {
                    ordinalNumber = match[1];
                }
            } else {
                ordinalNumber = chip.meta_ordinal;
            }

            return `${chip.bookShortCode} ${chip.bookYear} (${ordinalNumber})`;
        },
        stopGeneratingClicked() {
            this.clickedStop = true;
            this.serverRequest.cancel();
            this.notifyParent();
        },
        rateAnswerClicked(rating) {
            const feedback = rating ? 1 : -1;
            this.userFeedback = feedback;
            ApiService.sendFeedbackGpt(this.response.messageId, feedback).catch(() => {
                // alert('Failed to send feedback');
            });
        },
        onServerTypingStart() {
            this.isTyping = true;
        },
        onServerTypingDone() {
            this.isTyping = false;
            this.notifyParent();
        },
        notifyParent(status = true) {
            this.$emit('done');
        }
    },
    mounted() {
        this.isWaitingServerResponse = true;

      const question = this.prompt.text || "";

      // need to store this too
      const location = this.$store.getters.getCodeCycleLocation || "";
      const year = this.$store.getters.getCodeCycleYear || "";
      const bookIds = this.$store.getters.getCodeCycleBookIds || [];

      const request = ApiService.postQuestionGPT(question, location, year, bookIds, this.questionId);

      request.promise
          .then((response) => {
                const data = {
                    remaining: response.remaining,
                    reset: response.reset
                };
                this.$emit('countdown', data);
                this.response = response;
                // does it have any text? Assume typing has begun...
            })
            .catch((err) => {

                const msg = err?.message || "";

                // was it cancelled??
                if (msg.includes("Network Error")) {
                    this.error = "Uh-oh! We're experiencing some network issues right now. Please check your connection and try again.";
                } else {
                    this.error = err?.response?.data?.error || err;
                }

                this.notifyParent();
            })
            .finally(() => {
                this.isWaitingServerResponse = false;
            });

        this.serverRequest = request;
    },
    beforeDestroy() {
        this.serverRequest.cancel();
        this.notifyParent();
    }
}
</script>
<style lang="scss">
    span.ai-reference-section ins{
        text-decoration: none !important;
    }
</style>
