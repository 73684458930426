<template>
    <div>
        <h2 class="mb-4">
            {{ title }}
        </h2>
        <v-divider>
        </v-divider>
        <v-expansion-panels
            accordion
            flat
            class="faqs-expand">
            <v-expansion-panel
                v-for="(item, key) in faqs"
                :key="key">
                <v-expansion-panel-header
                    class="px-0 py-3"
                    >
                    <h3 class="fs-20 font-weight-medium">
                        {{item.title}}
                    </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-html="item.description">
                    </div>
                </v-expansion-panel-content>
                <v-divider>
                </v-divider>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        name: 'FAQs',
        props: {
            faqs: {
                required: true,
                type: Array
            },
            title: {
                required: false,
                type: String,
                default: 'Frequently asked questions'
            }
        }
    }
</script>

<style lang="scss">
    .faqs-expand {
        .v-expansion-panel {
            background-color: rgba(0,0,0,0)!important;
        }
    }
</style>
