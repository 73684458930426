<template>
    <v-container
            :style="{background: $vuetify.theme.themes.light.primaryLight, height: `calc(100% - ${chapterWrapHeaderHeight}px)`}"
            class="overflow-auto"
    >
        <trial-action
                v-if="freeView"
            title="Bookmark and add notes to code sections"
            content="Organize thoughts by bookmarking sections and adding notes that you can refer to anytime.">
        </trial-action>
        <template v-else-if="!freeView && (loading_tags || loading_notes)">
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row dense>
                <v-col>
                    <v-select
                        outlined
                        dense
                        v-model="tag_value"
                        :items="tag_categories"
                        item-text="tag"
                        item-value="id"
                        :clearable="true"
                        :hide-details="true"
                        attach
                        chips
                        small-chips
                        deletable-chips
                        class="filter-by-tag white mb-3"
                        label="Filter By Project Tags:"
                        multiple
                        :menu-props="{ bottom: true, offsetY: true }">
                    </v-select>
                    <div class="text-center">
                        <v-btn
                            :class="freeView ? 'disable-btn': ''"
                            @click="!freeView ? goToRoute('notes') : null"
                            color="primary"
                            depressed
                            class="white--text"
                            title="Click the Manage Notes button to share, print and manage your notes"
                            small>
                            Manage Notes
                        </v-btn>
                        <v-btn
                            outlined
                            :class="freeView ? 'disable-btn': ''"
                            @click="!freeView ? migrateNotes() : null"
                            color="primary"
                            class="white--text"
                            title="A new print version is out! Click the Migrate Notes button to move or clone your notes"
                            small>
                            Move Notes
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="notesCheck">
                <v-col>
                    <template v-for="(note, index) in chapter_notes">
                        <v-row
                            :class="{
                                'lightgreen accent2--text': activeNote(note.section_number)
                            }"
                            :key="index">
                            <v-col class="py-1">
                                <p class="mb-0">
                                    <a
                                        class="toc-chapter-item-focused"
                                        tabindex="0"
                                        @click="showContent(note, true)"
                                        v-html="chaptersTitle(note)"
                                        @keydown.enter="showContent(note, true)">
                                    </a>
                                </p>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <p class="caption text-center">You currently do not have any annotations or bookmarks for this book.</p>
                </v-col>
            </v-row>
        </template>
        <v-dialog
            persistent
            v-model="dialog"
            width="700">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    primary-title>
                    Move Notes
                </v-card-title>
                <v-card-text>
                    <p class="text-center pt-8">
                        You currently do not have any notes to be moved right now.
                    </p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import Legend from '../inc/mixins/legend';
    import TrialAction from './TrialAction.vue';
    import {AsyncUtils} from "../../../util/AsyncUtils";

    export default {
        components: { TrialAction },
        data() {
            return {
                chapter_notes: [],
                tag_categories: [],
                tag_value: [],
                loading_tags: false,
                loading_notes: false,
                dialog: false,
                subscribeModal: false,
                chapterWrapHeaderHeight: 0,
            }
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            freeView: {
                type: Boolean,
                required: false
            }
        },
        mixins: [Legend],
        computed: {
            tagText() {
                let tag_size = _.size(this.tag_value);
                if (tag_size > 0) {
                    let _this = this;
                    let text = [];
                    let s = '';
                    if (tag_size > 1) {
                        s = 's';
                    }
                    _.forEach(this.tag_value, function(value) {
                        let tag_obj = _.find(_this.tag_categories, {id: value});
                        if (!_.isUndefined(tag_obj)) {
                            text.push(tag_obj.tag);
                        }
                    });
                    return 'Sections with the "'+_.join(text, ', ')+'" tag'+s;
                } else {
                    return 'Showing all tags';
                }
            },
            notesCheck() {
                if (_.size(this.chapter_notes) > 0) {
                    return true;
                }
                return false;
            }
        },
        watch: {
            tag_value: function(value) {
                if (this.isXML && this.$store.getters.getLoggedIn == 1) {
                    EventBus.fire('filter-chapter-notes',value);
                    this.getNotes();
                }
            }
        },
        methods: {
            getTags() {
                this.loading_tags = true;
                this.$http.get(Routing.generate('get_legend_tags', {'documentId': this.documentInfo.document_id})).then(
                    response => {
                        if (response.data) {
                            this.tag_categories = _.sortBy(this.toArray(response.data), ['tag']);
                        }
                        this.loading_tags = false;
                    }
                )
            },
            getNotes() {
                this.loading_notes = true;
                let data = {
                    'sku': [this.documentInfo.sku],
                    'action': 'legend_notes',
                    'tag': this.tag_value
                };

                this.$http.get(Routing.generate('get_annotation_bookmarks', data)).then(
                    response => {
                        if (response.data) {
                            this.subscribeModal = response.data.subscribeModal;
                            let chapters = [];

                            _.each(response.data, function(item, key) {

                                if(!isNaN(key) && item !== undefined){
                                    if(item.isFigure === '1'){
                                        let data = {
                                            'content_id': _.parseInt(item.section_number),
                                            'link': {
                                                'id': item.figureId,
                                                'title': item.section_title || item.chapter_title
                                            },
                                            'parentContentID': item.content_id,
                                            'sortorder': item.bookmarkId,
                                            'section_number': item.section_number,
                                        };
                                        chapters.push(data);
                                    } else {
                                        let data = {
                                            'content_id': _.parseInt(item.section_number),
                                            'link': {
                                                'id': item.section_number,
                                                'title': item.section_title || item.chapter_title
                                            },
                                            'parentContentID': item.content_id,
                                            'sortorder': 0,
                                            'section_number': item.section_number,
                                        };
                                        chapters.push(data);
                                    }
                                }
                            });

                            this.chapter_notes = _.uniqBy(chapters, function (item) {
                                return item.content_id;
                            });
                        }
                        this.loading_notes = false;
                    }
                )

            },
            redirectToUserNotes() {
                window.location = Routing.generate('notes');
            },
            activeNote(section){
                return section === this.$store.getters.getActiveSection;
            },
            migrateNotes() {
                if(_.size(this.chapter_notes)) {
                    window.location = Routing.generate('migrate_notes', {'documentId': this.documentInfo.document_id});
                } else {
                    this.dialog = true;
                }
            },
        },
        created() {
            if (!this.freeView) {
                EventBus.listen('get-tags', () => this.getTags());
                EventBus.listen('get-notes', () => this.getNotes());
            }
        },
        mounted() {
            if (!this.freeView) {
                this.getTags();
                this.getNotes();
            }

            AsyncUtils.setIntervalOnce(() => {
                this.chapterWrapHeaderHeight = document.querySelector('#chapter-wrap-header').offsetHeight;
            }, 100, "chapter-wrap-header:height")
        }
    }
</script>
<style scoped>
    .filter-by-tag .v-list {
        background: #fff;
    }
    .disable-btn{
        cursor: default;
    }
</style>
