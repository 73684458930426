import {ExpirableStorage} from "../../../classes/ExpirableStorage";
import {DateUtil} from "../../../util/DateUtil";

export default {
    methods: {
        clearAllCache() {
            ExpirableStorage.invalidateAll(false);
            this.$session.destroy();
        },
        clearUserSession() {
            this.$session.remove('eligibleForTrial');
            this.$session.remove('cart_items_count');
            this.$session.remove('toggleUserChoice');
            this.$session.remove('showAstmModal');
            this.$session.remove('lastSyncDate');
            this.$session.remove('enterpriseId');
            this.$session.remove('isEnterpriseAdmin');

            this.clearConcurrent();
            this.clearTrial();
            this.clearPremiumSubscriptions();
            this.clearProfiles();
        },
        clearTrial() {
            this.trialStarted = false;
            this.trialActive = false;
        },
        clearConcurrent() {
            this.$session.remove('favorites');
            this.$session.remove('favoritesLoading');
            this.$session.remove('favoritesPromise');
            this.$session.remove('active-concurrent');
            this.$session.remove('subscriptionType');
            this.$session.remove('premiumSubscriptions');
            this.$session.remove('premiumBundledTitles');
            this.$session.remove('premiumSubscriptionSet');
            this.$session.remove('premiumBundleSet');
            this.$session.remove('searchTerms');
            this.$session.remove('contentSearchContentSources');
            this.activeConcurrentCode = null;
        },
        /**
         *
         * @param key
         * @returns Array
         */
        getSessionValueAsArray(key) {
            const val = this.$session.get(key);
            return Array.isArray(val) ? val : [];
        },
        exitConcurrentAccess() {
            EventBus.fire('full-page-loader', true);
            return this.setUserProfile({type: 'personal'})
        },
        clearProfiles() {
            localStorage.removeItem('userProfiles')
        }
    }
}
