var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.questions.length && _vm.isLoggedIn()
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "font-weight-regular" }, [
                  _vm._v(
                    "\n                Recently viewed questions\n            "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.questions, function (item, key) {
            return _c(
              "div",
              { key: key },
              [
                _c("v-divider"),
                _vm._v(" "),
                _c("p", { staticClass: "subtitle-1 my-3 font-weight-bold" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getRoute("premium_answers_view", {
                          answerId: item.tagId,
                          slug: item.slug,
                        }),
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.question) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }