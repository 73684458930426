<template>
    <div
        style="min-height: 100vh;">
        <smart-index
            :documentInfo="activeDocumentInfo"
            :exam-id="examId">
        </smart-index>
        <chapter-header
            v-if="!isCollectionOrComplete"
            :document-info="documentInfo"
            :show-exam="'' !== examId">
        </chapter-header>

        <v-container
            class="ma-auto"
            :class="!isCollectionOrComplete && !isFullscreenContent ? 'max-content-container' : ''">
            <v-row>
                <v-col
                    :id="!isCollectionOrComplete ? 'contentOverview' : null"
                    class="pt-0" :style="isMobileAndTablet ? 'width: 100%;' : 'width: auto;'">
                    
                    <v-container
                        class="pt-0"
                        fluid>
                        <description
                            class="pt-4"
                            :documentInfo="documentInfo"
                            :isSingle="isSingle"
                            :examId="examId">
                        </description>
                    </v-container>
                    <related-titles
                        v-if="isSingle && '' === examId"
                        :documentId="documentId">
                    </related-titles>
                    <included-titles-complete
                        v-if="documentInfo
                            && documentInfo.content_type
                            && documentInfo.content_type.name === 'Premium Complete'
                            && '' === examId"
                        :documentId="documentId">
                    </included-titles-complete>
                    <included-titles
                        v-if="documentInfo
                            && documentInfo.content_type
                            && documentInfo.content_type.name === 'Collection'
                            && '' === examId"
                        :documentId="documentId"
                        :documentInfo="documentInfo">
                    </included-titles>
                    <categories
                        v-if="documentInfo
                            && documentInfo.categories
                            && '' === examId"
                        :categories="documentInfo.categories">
                    </categories>
                </v-col>
                <!-- Keep this section for premium collection/complete for now until decide layout for these -->
                <v-col v-if="isCollectionOrComplete" :style="!isMobileAndTablet ? 'max-width: 430px;' : 'min-width: 100%;'">
                    <information
                        v-if="'' === examId"
                        :documentInfo="documentInfo"
                        :isSingle="isSingle"
                        :examId="examId">
                    </information>
                    <vertical-upsell v-if="'' === examId">
                    </vertical-upsell>
                    <template v-if="isSingle && documentInfo && '' === examId">
                        <title-included-complete
                            class="my-5"
                            v-if="documentInfo.sku"
                            :sku="documentInfo.sku"
                            :part-of-complete="documentInfo.part_of_complete">
                        </title-included-complete>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <premium-features-dialog>
        </premium-features-dialog>
    </div>
</template>

<script>
    import SmartIndex from './../smartIndex/index';
    import SmartIndexMobile from './../smartIndex/indexMobile';
    import Description from './Description';
    import RelatedTitles from './related_titles';
    import IncludedTitles from './included_titles';
    import Categories from './categories';
    import IncludedTitlesComplete from './included_titles_complete';
    import TitleIncludedComplete from './../partials/title_included_complete';
    import Information from './SidebarInformation';
    import VerticalUpsell from './../../content/partials/vertical_upsell';
    import FreeTrialButton from './free_trial_button';
    import Mobile from '../../inc/mixins/mobile';
    import ChapterHeader from "../chapter-header/ChapterHeader.vue";
    import ReturnLastPositionMessage from "../../ReturnLastPositionMessage.vue";
    import PremiumFeaturesDialog from './PremiumFeaturesDialog.vue';
    import {TitlesApi} from "../../../api/Titles";

    export default {
        props: {
            documentId: {
                required: true,
                type: Number
            },
            examId: {
                type: String,
                required: false,
                default: ''
            },
        },
        components: {
            ReturnLastPositionMessage,
            ChapterHeader,
            SmartIndex,
            SmartIndexMobile,
            Description,
            RelatedTitles,
            IncludedTitles,
            Categories,
            IncludedTitlesComplete,
            TitleIncludedComplete,
            Information,
            VerticalUpsell,
            FreeTrialButton,
            PremiumFeaturesDialog
        },
        mixins: [
            Mobile
        ],
        data() {
            return {
                documentInfo: {}
            }
        },
        computed: {
            isSingle() {
                if (!_.isEmpty(this.documentInfo)) {
                    return this.isSingleTitle(this.documentInfo);
                }
            },
            isPartOfComplete() {
                if (!_.isEmpty(this.documentInfo)) {
                    return _.includes(this.$getConst('premiumCompleteLevels'), this.documentInfo.content_type.id) &&
                        !this.isPremiumSubscribed(this.documentInfo.sku);
                }
            },
            isCollectionOrComplete() {
                if (this.documentInfo 
                    && this.documentInfo.content_type 
                    && (this.documentInfo.content_type.name === 'Premium Complete' || 
                        this.documentInfo.content_type.name === 'Collection')) {
                            return true;
                    }

                return false;
            }
        },
        methods: {
            async getDocumentInfo() {
                const response = await TitlesApi.getTitleInfo(this.documentId);
                this.$set(this, 'documentInfo', response.data);
                this.$set(this, 'activeDocumentInfo', response.data);
                let contentType = response.data.content_type.name;
                if('New DB schema XML' == contentType ||
                    'ICC XML' == contentType || 'ICC XML2.0' == contentType
                ) {
                    this.isXML = true;
                } else {
                    this.isXML = false;
                }
            }
        },
        created() {
            this.isTitleLanding = true;
            this.handleBackBtnClick;
        },
        mounted() {
            this.getDocumentInfo();
            if (this.isMobilePort) {
                EventBus.fire('from-book-content');
            }
            EventBus.fire('update-chapter-title', 'Overview');
        }
    }
</script>

<style scoped>
.expand-icon-height{
    height: 40px !important;
    transform: scaleX(-1);
    float: left;
}
</style>
