var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          on: {
            click: function ($event) {
              return _vm.$emit("backToMain")
            },
          },
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "mr-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("\n                mdi-arrow-left\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-title",
            { staticClass: "fs-16 font-weight-medium" },
            [
              _vm._t("default", function () {
                return [_vm._v("\n                Main Menu\n            ")]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }