<template>
    <v-snackbar
        class="info-banner"
        v-model="notification"
        :absolute="true"
        color="primary"
        :top="true"
        :timeout=0>
        <div v-html="message"></div>
        <v-btn
            flat
            @click="notification = false">
            <v-icon color="lightgreen">done</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        props: ['message'],
        data() {
            return {
                notification: true,
            }
        },
    }
</script>
