import {AppParameters} from "../../../AppParameters";
import {ProfileApi} from "../../../api/ProfileApi";

export default {
    computed: {
        isAuthenticated() {
            return '' !== this.$store.getters.getLoggedIn;
        },
        profiles() {
            return this.$store.getters['profile/Profiles'];
        },
        activeConcurrentProfile() {
            if (this.profiles) {
                const active = _.find(this.profiles, { is_active: true });
                if (active) {
                    return active.type === 'concurrent';
                }
            }
            return false;
        },
        activeProfile() {
            if (this.profiles && '' !== this.$store.getters.getLoggedIn) {
                return _.find(this.profiles, {'is_active': true});
            }
        },
        activeEnterpriseProfile() {
            return this.activeProfile && this.activeProfile.type === 'enterprise'
        },
        activeProfileName() {
            if(this.activeProfile) {
                return this.activeProfile.name;
            }
            return false;
        },
        personalProfileName() {
            if(this.activeProfile) {
                return this.profiles[0].name;
            }
            return false;
        },
        handleBackBtnClick: function() {
            window.addEventListener("pageshow", function(event) {
                if (event.persisted ||
                    ( "undefined" !== typeof window.performance  &&
                      "back_forward" === performance.getEntriesByType("navigation")[0].type)
                ) {
                   return window.location.reload(true);
                }
            });
        }
    },
    methods: {
        async setUserProfile(data) {
            EventBus.fire('full-page-loader', true);
            try {
                await ProfileApi.setProfile(data);
                await this.clearUserSession();
                window.location = Routing.generate('dashboard_landing');
            } catch ({response}) {
                if (response) {
                    EventBus.fire('full-page-loader', false);
                    EventBus.fire('showErrorModal', {
                        title: data.type === 'concurrent' ?
                            'No Concurrent Licenses Available' :
                            'Enterprise Profile is Not Accessible',
                        message: response.data.error
                    })
                }
            }
        },
    }
}
