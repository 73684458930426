<template>
    <div
            v-if="switchUserActive || isExiting"
            class="d-flex-not-important justify-center align-center pa-2 text-center blue darken-3 white--text">

        <div v-visible="switchUserActive">
            You are currently simulating this as: <strong>{{ switchUserName }} ({{ switchUserEmail }})</strong>
        </div>

        <v-btn
                depressed
                small
                color="error"
                class="ml-5"
                :loading="isExiting"
                :title="returnUrl"
                @click="exitClicked">Return to Admin Dashboard
        </v-btn>

    </div>
</template>

<script>
export default {
    data() {
        return {
            isExiting: false
        }
    },
    computed: {
        switchUserActive() {
            return this.$store.getters.getSwitchUser !== null;
        },
        switchUserName() {
            return this.$store.getters.getSwitchUser ? this.$store.getters.getSwitchUser.name : "";
        },
        switchUserEmail() {
            return this.$store.getters.getSwitchUser ? this.$store.getters.getSwitchUser.email : "";
        },
        returnUrl() {
            return this.$store.getters.getSwitchUserReturnUrl;
        }
    },
    methods: {
        exitClicked() {
            this.isExiting = true;

            this.$store.commit('leaveSwitchUser');

            this.$nextTick(() => {
                window.location = this.returnUrl;
            });
        }
    },
    mounted() {

        let oldSwitchUser = null;

        Mousetrap.bind('left left left', () => {

            if (this.switchUserActive) {
                oldSwitchUser = this.$store.getters.getSwitchUser;
                this.$store.commit('leaveSwitchUser');
            }

        });

        Mousetrap.bind('right right right', () => {

            if (oldSwitchUser) {
                this.$store.commit('setSwitchUser', oldSwitchUser);
            }

        });
    }
}
</script>
