<template>
    <v-row
        id="exam_resources"
        :class="{'py-12': !isMobilePort}"
        no-gutters>
        <v-col>
            <v-card outlined>
                <v-card-title class="primary d-flex justify-center">
                    <h4 id="exams_header" class="white--text">
                        PRONTO Exam Resources
                    </h4>
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <v-sheet
                        v-if="persona === 3 || persona === 4"
                        class="ma-3 px-3"
                        outlined
                        rounded>
                        <v-row>
                            <v-col>
                                <p class="mb-0">
                                    <b>Important: Not all Exam References are available as Digital References.</b></br>
                                    Verify all references through MyICC on the <a :href="myExamLink" target="_blank">My Exams</a> or <a :href="myCatalogLink" target="_blank">Exam Catalog</a> tab. Click on Exam Info next to the appropriate exam and review any non digital exam references. For those non-digital exam references, the candidate will be responsible for providing their own instance of the resource title.
                                </p>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-sheet
                        class="d-none d-md-block ma-3 pa-3 blue lighten-5"
                        outlined
                        rounded>
                        <v-row no-gutters>
                            <v-col>
                                <h3>Exam Name</h3>
                            </v-col>
                            <v-col>
                                <h3>Digital References</h3>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-sheet>
                    <template v-if="exams">
                        <v-sheet
                            v-for="exam in exams" :key="exam.examId"
                            class="exam-background ma-3 pa-3"
                            outlined
                            rounded>
                            <v-row no-gutters>
                                <v-col cols="12" lg="4" class="d-flex align-center">
                                    <h3 class="mb-0">{{ exam.examTitle }}</h3>
                                </v-col>
                                <v-col cols="12" lg="4">
                                    <p class="pt-1 text-decoration-underline font-weight-bold">References</p>
                                    <div v-for="(reference, key) in exam.references" :key="key">
                                        <p class="mb-3 font-italic caption">
                                            {{ reference.title }}
                                        </p>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="4" class="d-flex flex-column justify-space-evenly">
                                    <v-btn
                                        :height="isMobilePort ? '50px' : ''"
                                        @click="goToExamPreview(exam)"
                                        color="primary"
                                        class="button-text-wrap">
                                        Simulate Exam Experience With Digital Codes
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="mt-2">
                                        <a class="white--text" :href="examLink">Schedule Exam In MyICC</a>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </template>
                    <v-sheet
                        class="exam-background ma-3 pa-3"
                        outlined
                        rounded>
                        <v-row no-gutters>
                            <v-col cols="12" lg="4" class="d-flex align-center">
                                <h3 class="mb-0">{{ sampleExam.examTitle }}</h3>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <p class="pt-1 text-decoration-underline font-weight-bold">References</p>
                                <div v-for="reference in sampleExam.references">
                                    <p class="mb-3 font-italic caption">
                                        {{ reference.titleName }}
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="4" class="d-flex flex-column justify-space-evenly">
                                <v-btn
                                    :height="isMobilePort ? '50px' : ''"
                                    @click="goToExamPreview(sampleExam)"
                                    color="primary"
                                    class="button-text-wrap">
                                    Simulate Exam Experience With Digital Codes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from "./../inc/mixins/mobile";
    import {AppParameters} from "../../AppParameters";

    export default {
        data() {
            return {
                loading: false,
                subscriptionTypeMsg: {
                    subscribed: 'Included in Premium Subscription',
                    notIncluded: 'Not Included in Digital References. Bound book can be used'
                },
                sampleExam: {
                    examId: 'sample-exam',
                    examTitle: 'Sample Exam',
                    references: [
                        {titleName: '2021 International Building Code'},
                        {titleName: '2021 International Residential Code'}
                    ],
                    referenceSlug: 'IBC2021P1'
                },
                examLink: '',
                myExamLink: this.$getConst('links').userExams,
                myCatalogLink: this.$getConst('links').userExamCatalog
            }
        },
        mixins: [Mobile],
        props: {
            exams: {
                required: true,
                type: Array
            },
            persona: {
                required: false,
                type: Number
            }
        },
        methods: {
            goToExamPreview(exam) {
                let postdata = {
                    examId: exam.examId,
                    examTitle: exam.examTitle,
                    referrer: 'DC Simulation'
                };
                window.location = Routing.generate("exam_preview", {examId: exam.examId});
            }
        },
        mounted() {
            this.examLink = `${AppParameters.my_icc_url}/credentials/exams`;
        }
    }
</script>

<style scoped>
.justify-space-evenly {
    justify-content: space-evenly;
}
.exam-background {
    background-color: rgb(254, 245, 228);
    border-color: rgb(254, 245, 228);
}
@media screen and (max-width: 600px) {
    #exams_header {
        font-size: 1rem;
    }
}
</style>
