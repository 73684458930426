var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("back-to-main-menu", { on: { backToMain: _vm.backToMain } }),
      _vm._v(" "),
      _c("v-list-item", { staticClass: "my-2" }, [
        _vm._v("\n        Enterprise Admin Tools\n    "),
      ]),
      _vm._v(" "),
      _c("link-list-icons", { attrs: { links: _vm.links } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }