var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.message
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.message) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _vm.loading
                ? _c(
                    "v-col",
                    { staticClass: "text-center my-12" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  )
                : _vm._l(_vm.subjects, function (subject, key) {
                    return _c(
                      "v-col",
                      { key: key, attrs: { cols: "12", sm: "6", md: "4" } },
                      [
                        _c(
                          "v-card",
                          {
                            attrs: {
                              dark: "",
                              flat: "",
                              color: subject.color,
                              "min-height": "100%",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showAstmTitles(subject)
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    subject.icon
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "auto" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(subject.icon) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("v-col", [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "font-weight-regular white--text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(subject.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }