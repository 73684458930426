<template>    
    <v-card
        flat
        class="accent2-top-border mb-5">
        <v-container>
            <template v-if="title.is_astm">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto" class="pt-0 pr-0">
                        <img
                            class="float-left mr-5"
                            width="80px"
                            :src="asset(require('../../../images/astm-logo.png'), 'default')"/>
                    </v-col>
                    <v-col class="pl-0 pb-0">
                        <h4 class="float-left">
                            ASTM Standards are available exclusively as part of
                        </h4>
                    </v-col>
                </v-row>
                <v-card-text class="pb-0">
                    <v-row dense class="pb-5">
                        <v-col cols="auto" class="pt-2 pr-0">
                            <img
                                class="float-left ml-0"
                                width="50%"
                                :src="asset(require('../../../images/icons/diamond_complete.png'), 'default')"/>
                        </v-col>
                        <v-col class="pl-0 pb-0">
                            <h4 class="float-left">Premium Complete Subscription</h4>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="trialActive"
                        dense
                        class="yellow lighten-5 text-center mb-2">
                        <v-col>
                            <p class="caption mb-0">ASTM Standards are not active during trial period</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
            <v-row
                v-else
                dense
                align="center">
                <v-col>
                    <p class="mb-0 caption">
                        This title is not included in the Premium Complete trial
                    </p>
                </v-col>
            </v-row>
            <v-row
                v-if="title.part_of_complete"
                dense
                class="text-center">
                <v-col>
                    <v-btn
                        block
                        dark
                        color="accent2"
                        @click="getPremiumCompleteBook()"
                        class="caption">
                        View Premium Complete Subscription Options
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>    
</template>

<script>
    export default {
        props: {
            title: {
                required: false,
                type: Object
            }
        }
    }
</script>
