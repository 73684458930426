<template>
    <v-btn
        v-if="'1' !== isProntoReferer"
        :small="small"
        class="primary white--text"
        :href="getRoute('user_exams')">
        Return to Exam Resources
    </v-btn>
</template>

<script>
    export default {
        props: {
            isProntoReferer: {
                required: false,
                type: String,
                default: false
            },
            small: {
                required: false,
                type: Boolean,
                default: false
            }
        }
    }
</script>
