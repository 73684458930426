<template>
    <div
        class="text-center"
        :class="containerPadding">
        <p
            v-if="showMessage"
            class="mb-2">
            Loading Content ...
        </p>
        <v-progress-linear
            :active="true"
            color="primary"
            indeterminate>
        </v-progress-linear>
    </div>
</template>

<script>
    export default {
        name: 'LoadingLinear',
        props: {
            showMessage: {
                required: false,
                type: Boolean,
                default: true
            },
            containerPadding: {
                required: false,
                type: String,
                default: 'pa-6'
            }
        }
    }
</script>
