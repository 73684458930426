<template>
    <div :class="!isMobilePort ? 'pa-4' : ''">
        <h4 v-if="!isMobileAndTablet">Graphic Filters</h4>

        <div class="pa-1">
            <v-btn
                small
                block
                dark
                outlined
                depressed
                color="black"
                @click="resetFilters">
                Reset Filters
            </v-btn>
        </div>

        <div class="filters">
            <graphics-filter-expander
                class="mt-3"
                title="Categories / Locations"
                component-name="CategoriesPanel">
            </graphics-filter-expander>

            <title-filter-expander
                class="mt-3"
                title="Years"
                component-name="YearsPanel">
            </title-filter-expander>
            <title-filter-expander
                class="mt-3"
                title="Titles"
                component-name="TitlesPanel">
            </title-filter-expander>
        </div>
    </div>
</template>

<script>
    import Mobile from "../../../inc/mixins/mobile";
    import GraphicsFilterExpander from "./GraphicsFilterExpander.vue";
    import {EventNames} from "../../../../classes/EventNames";
    import TitleFilterExpander from "../../titles/filters/TitleFilterExpander.vue";

    export default {
        name: 'GraphicsSearchFilters',
        mixins: [
            Mobile
        ],
        components: {
            TitleFilterExpander,
            GraphicsFilterExpander
        },
        methods: {
            resetFilters() {
                EventBus.fire(EventNames.RESET_GRAPHICS_SEARCH)
            }
        },
    }
</script>