<template>
    <v-row
        align="center"
        dense
        class="accent--text system-banner-text">
        <v-col class="text-center">
            <div v-html="message"></div>
        </v-col>
        <v-col cols="auto">
            <v-btn
                color="accent"
                icon
                small
                text
                @click="dismissBanner()">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {ApiService} from "../../api/ApiService";

export default {
    props: {
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        dismissBanner() {
            this.hideBanner = true;
            if (this.isActiveUser) {
                ApiService.closeGlobalBanner();
            }
        }
    },
}
</script>

<style lang="scss">
    @import "../../scss/variables.scss";
    .system-banner-text {
        a {
            color: $accent-color!important;
            font-weight: 600;
            text-decoration: underline;
        }
        a:hover {
            text-decoration: underline!important;
        }
    }
</style>
