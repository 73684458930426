<template>
    <confirm-dialog
            :can-be-closed="canBeClosed"
            :width="800"
            :is-open="dialogVisible"
            @closed="emitClosed">

        <template v-slot:title>

            <div class="d-flex align-center">
                <asset-image
                        :src="require('../../images/ai_navigator/ai_navigator_icon_white.svg')"
                        width="40"
                        class="mr-2"
                ></asset-image>
                ICC AI Navigator
            </div>

        </template>

        <div class="mt-5">
            <ai-navigator-disclaimer></ai-navigator-disclaimer>
        </div>

        <template v-slot:buttons>

            <div class="w-full d-flex justify-center">

                <template v-if="canBeClosed">

                    <v-btn class="mr-4" @click="clickedClose">
                        Close
                    </v-btn>

                </template>

                <template v-else>

                    <v-btn class="mr-4" @click="clickedBack">
                        Back
                    </v-btn>

                    <v-btn class="primary white--text" @click="clickedAcknowledge" :loading="isLoading">
                        Acknowledge
                    </v-btn>

                </template>

            </div>

        </template>

    </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../ConfirmDialog.vue";
import AiNavigatorDisclaimer from "./AiNavigatorDisclaimer.vue";
import {ApiService} from "../../api/ApiService";
import AssetImage from "../AssetImage.vue";

export default {
    components: {AssetImage, AiNavigatorDisclaimer, ConfirmDialog},
    emits: ['closed', 'confirmed'],
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        canBeClosed: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            isLoading: false
        }
    },
    methods: {
        clickedBack() {
            window.location = Routing.generate('features_ai_navigator');
        },
        clickedAcknowledge() {

            this.isLoading = true;

            ApiService.confirmTermsGPT().finally(() => {
                this.isLoading = false;

                // cool. Let the parent know to NOT ask about this ever again
                this.$emit('confirmed');
            });
        },
        emitClosed() {
            this.$emit('closed');
        },
        clickedClose() {
            this.emitClosed();
        }
    },
    mounted() {

        this.$watch(() => this.visible, (newValue) => {
            this.dialogVisible = !!newValue;
        }, {
            immediate: true
        });
    }
}
</script>
