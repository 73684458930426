var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "white" },
    [
      _vm.documentCollections.length
        ? _c(
            "v-row",
            { staticClass: "d-flex", attrs: { align: "start" } },
            [
              _c(
                "v-icon",
                { staticClass: "accent2--text w-40", attrs: { large: "" } },
                [_vm._v("\n            mdi-package-variant-closed\n        ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-2" }, [
                _c("h3", [_vm._v("Bundle and Save!")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                This title is also available as part of:\n            "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.documentCollections, function (item, index) {
        return [
          _c(
            "v-row",
            {
              staticClass: "d-flex flex-nowrap py-2",
              attrs: { align: "start" },
            },
            [
              _c("v-icon", { staticClass: "accent2--text w-40" }, [
                _vm._v(
                  "\n                mdi-check-circle-outline\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-nowrap justify-space-between w-full",
                },
                [
                  _c("div", { staticClass: "px-2" }, [
                    _c("p", { staticClass: "font-weight-bold mb-0" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _vm._v(" "),
                    item.title === "Premium Complete"
                      ? _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "Unlock access to nearly " +
                              _vm._s(_vm.premiumCompleteTitlesCount()) +
                              "+ titles and more"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "caption primary--text text-decoration-underline font-weight-bold text-no-wrap",
                      attrs: { href: _vm.getLandingPageUrl(item) },
                    },
                    [_vm._v("Learn More")]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "d-flex flex-nowrap justify-space-between" },
        [
          _c("div", { staticClass: "w-40" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticClass: "px-2" }, [
            _c(
              "a",
              {
                staticClass: "cdpBlue--text font-weight-bold",
                attrs: {
                  href: _vm.getViewOtherFormatsLink(),
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n                View Other Available Print & Digital Formats\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("v-icon", { staticClass: "cdpBlue--text", attrs: { large: "" } }, [
            _vm._v("\n            mdi-arrow-right\n        "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }