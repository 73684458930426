<template>
    <v-container id="map-wrapper-container">
        <v-row
            justify="center"
            align="center">
            <v-col
                    cols="12"
                    lg="6">
                <h2 class="text-center primary--text font-weight-regular">
                    Find Codes by State
                </h2>

                <div class="d-flex align-center mb-5 mt-5">

                    <state-codes-dropdown class="mr-3" @changed="stateChangedCallback"></state-codes-dropdown>

                    <v-btn
                            color="primary"
                            large
                            @click="goClicked">
                        Go
                    </v-btn>
                </div>

                <div id="map"></div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col
                cols="12"
                lg="6">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-avatar
                            color="#04A9A5"
                            size="20">
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <p class="mb-0">Statewide adoption of one or more ICC Codes (also includes locally adopted codes)</p>
                    </v-col>
                </v-row>
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-avatar
                            color="#0B5940"
                            size="20">
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <p class="mb-0">States where ICC Codes are adopted at the local level by jurisdictions</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="my-2">
                            *ICC makes every effort to provide current, accurate code adoption information. Not all
                            jurisdictions notify ICC of code adoptions. To obtain more detailed information on
                            amendments and changes to adopted codes, please contact the jurisdiction. To submit code
                            adoption information:<br>
                            <a href="https://www.iccsafe.org/adoptions" target="_blank" class="accent2--text">www.iccsafe.org/adoptions</a>
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import {FindCodesByStateMap} from "../../../FindCodesByStateMap";
    import {isLocalEnvironment} from "../../../global";
    import StateCodesDropdown from "../../StateCodesDropdown.vue";

    export default {
        components: {StateCodesDropdown},
        mixins: [Mobile],
        data() {
            return {
                selectedState: ''
            }
        },
        methods: {
            stateChangedCallback(state) {
                this.selectedState = state;
            },
            goClicked() {

                if (this.selectedState) {
                    this.goToStatePage(this.selectedState);
                }
            },
            goToStatePage(stateName) {

                const page = Routing.generate('codes_category', {
                    'category': (stateName || "").replace(/\s+/g, '-').toLowerCase()
                });

                if (!isLocalEnvironment() || confirm(`Go to ${page}?`)) {
                    window.location = page;
                }
            }
        },
        mounted() {

            FindCodesByStateMap("map", (state) => {
                this.goToStatePage(state.name);
            }).then(() => {
                console.log('states map loaded');
            });
        }
    }
</script>

<style lang="scss" scoped>
    path:hover {
        cursor: pointer;
    }

    .default {
      width: 20px;
      height: 20px;
      background: #38835e;
    }

    #map {
      min-height: 400px;
    }
</style>

<style>
#map select {
    border: 2px solid rgba(0,0,0,.38);
    border-radius: .25rem;
    color: rgba(0,0,0,.87);
    height: calc(2.25rem + 4px) !important;
    padding: .2rem .75rem;
}
</style>
