<template>
    <divider-expand
        :open-by-default="showRelevantTitles">
        <p class="fs-16 mb-0">
            Relevant Titles
        </p>
        <template v-slot:expand>
            <title-list
                v-if="!loading.books && books.length"
                :titles="books"
                :loading="loading.books"
                :itemsPerPage="itemsPerPage">
            </title-list>
            <v-row v-else-if="loading.books">
                <v-col class="text-center px-8">
                    <p class="mb-2">
                        Loading Relevant Titles ...
                    </p>
                    <v-progress-linear
                        indeterminate
                        color="primary">
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-row
                dense
                v-else>
                <v-col align="center">
                    <p class="caption primary--text mb-2">
                        No relevant titles present.
                    </p>
                </v-col>
            </v-row>
            <v-row
                dense
                v-if="itemsPerPage < books.length"
                justify="center">
                <v-col cols="auto">
                    <v-btn
                        text
                        color="primary"
                        @click="activateTitlesTab">
                        Show More
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </divider-expand>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import {SuperAxios} from "../../../api/SuperAxios";

    import TitleCover from "../../titles/cover/Index.vue";
    import TitleList from '../../content/title/TitleList.vue';
    import DividerExpand from "../../expands/DividerExpand.vue";

    export default {
        data() {
            return {
                books: [],
                loading: {
                    books: true
                },
                itemsPerPage: 4,
                limit: 10,
                showRelevantTitles: true
            }
        },
        mixins: [Mobile],
        components: {
            DividerExpand,
            TitleCover,
            TitleList
        },
        methods: {
            getTitleResults(search) {
                const query = search ? search : this.defaultSearch;
                this.loading.books = true;
                const apiUrl = Routing.generate('search_titles', {
                    query: query,
                    limit: this.limit
                });

                SuperAxios.getOnce(apiUrl).then(response => {
                    this.books = response.data.data;
                    this.$set(this.loading, 'books', false);
                }).catch(this.handleError);
            },
            toggle() {
                this.showRelevantTitles = !this.showRelevantTitles;
            },
            resetData(){
                this.books = [];
                this.loading.books = true;
                this.itemsPerPage = 6;
            },
            activateTitlesTab() {
                EventBus.fire('activateSearchTab', this.$getConst('titleSearchType'));
            }
        },
        mounted() {
            this.getTitleResults();
            EventBus.listen('get-relevant-titles-results', (search = '') => {
                this.resetData();
                this.getTitleResults(search);
            });
        }
    }
</script>
