var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expand,
            expression: "expand",
          },
        ],
      },
      [
        _c(
          "v-card",
          {
            staticClass: "mt-2",
            staticStyle: { "overflow-y": "scroll" },
            attrs: { flat: "", "max-height": "200px" },
          },
          [
            _c(
              "v-card-text",
              { staticClass: "py-0" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c("v-col", [
                      _c("p", { staticClass: "mb-0 caption" }, [
                        _vm._v(
                          "\n                            Search In\n                        "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-treeview", {
                  attrs: {
                    dense: "",
                    selectable: "",
                    multiple: true,
                    "return-object": "",
                    "selected-color": "accent2",
                    items: _vm.items.source,
                    "item-key": "id",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function ({ item }) {
                        return [
                          _c("p", { staticClass: "mb-0 caption" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.searchSource,
                    callback: function ($$v) {
                      _vm.searchSource = $$v
                    },
                    expression: "searchSource",
                  },
                }),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c("v-col", [
                      _c("p", { staticClass: "mb-0 caption" }, [
                        _vm._v(
                          "\n                            Search For\n                        "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-treeview", {
                  attrs: {
                    dense: "",
                    selectable: "",
                    multiple: true,
                    "return-object": "",
                    "selected-color": "accent2",
                    items: _vm.items.type,
                    "item-key": "id",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function ({ item }) {
                        return [
                          _c("p", { staticClass: "mb-0 caption" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.searchTypes,
                    callback: function ($$v) {
                      _vm.searchTypes = $$v
                    },
                    expression: "searchTypes",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("filter-buttons", {
          staticClass: "mt-2",
          attrs: {
            filter: {
              key: "types",
              value: { sources: _vm.searchSource, types: _vm.searchTypes },
            },
          },
          on: {
            clearSearch: function ($event) {
              return _vm.removeAll(true)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.expand,
            expression: "!expand",
          },
        ],
      },
      [
        _vm.hasContentType
          ? [
              _vm._l(_vm.activeSearchSources, function (item) {
                return _c(
                  "v-chip",
                  {
                    key: item.id,
                    staticClass: "pr-1 mt-1",
                    attrs: {
                      small: "",
                      color: "primaryLight",
                      "text-color": "primary",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.upperFirst(item.name)) +
                        "\n                "
                    ),
                    _c(
                      "v-icon",
                      {
                        attrs: { size: "19" },
                        on: {
                          click: function ($event) {
                            return _vm.removeSource(item.id)
                          },
                        },
                      },
                      [_vm._v("close")]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.activeSearchTypes, function (item) {
                return _c(
                  "v-chip",
                  {
                    key: item.id,
                    staticClass: "pr-1 mt-1",
                    attrs: {
                      small: "",
                      color: "primaryLight",
                      "text-color": "primary",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.upperFirst(item.name)) +
                        "\n                "
                    ),
                    _c(
                      "v-icon",
                      {
                        attrs: { size: "19" },
                        on: {
                          click: function ($event) {
                            return _vm.removeType(item.id)
                          },
                        },
                      },
                      [_vm._v("close")]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right mt-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: "",
                        color: "gray darken-2",
                        disabled: !_vm.hasContentType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeAll(true)
                        },
                      },
                    },
                    [_vm._v("\n                    Reset\n                ")]
                  ),
                ],
                1
              ),
            ]
          : _c("p", { staticClass: "mb-0" }, [
              _vm._v("\n            Searching all content types\n        "),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }