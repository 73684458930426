var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "py-0", attrs: { dense: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { href: _vm.getAiHref() } },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "mr-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(
                  "\n                    mdi-navigation-outline\n                "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "black--text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getLabelText()) +
                    "\n                "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }