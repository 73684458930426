var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "primary--text headline" }, [
                  _c("span", { staticClass: "licenses-step-2" }, [
                    _vm._v("Personal Use"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 mr-2 primary",
                      on: {
                        click: function ($event) {
                          return _vm.refreshSubscriptions("Purchases")
                        },
                      },
                    },
                    [
                      !_vm.isRefreshing
                        ? _c("span", [_vm._v("Refresh Licenses")])
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "white",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    Personal Use licenses are those where the individual who purchases the premium subscription is the individual who is able to use the premium subscription.  Personal use licenses provide dedicated access to the individual as identified by the email address, but can be configured to be assigned to another individual or made concurrent.\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-data-iterator",
                        {
                          staticClass: "elevation-0 no-margin parent-row",
                          attrs: {
                            items: _vm.subscriptions,
                            "items-per-page": _vm.pagination.itemsPerPage,
                            loading: _vm.loading,
                            "single-expand": true,
                            options: _vm.pagination,
                            "server-items-length": _vm.pagination.totalItems,
                            "footer-props": {
                              itemsPerPageOptions:
                                _vm.pagination.rowsPerPageItems,
                            },
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.pagination = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "subscription-table personal-use",
                                      attrs: {
                                        "aria-label":
                                          "Personal Use Subscription Details",
                                      },
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          _vm._l(
                                            _vm.headers,
                                            function (header, index) {
                                              return _c(
                                                "th",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "caption font-weight-bold",
                                                  class: {
                                                    "pl-3 text-left title-section":
                                                      index === 0,
                                                    "pr-3 pl-2 text-right":
                                                      index === 1,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(header.text) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (data) {
                                return [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "subscription-table personal-use",
                                      attrs: {
                                        "aria-label":
                                          "Personal Use Subscription Details",
                                      },
                                    },
                                    [
                                      _c(
                                        "tbody",
                                        [
                                          _vm._l(data.items, function (item) {
                                            return [
                                              _c("tr", [
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "5" } },
                                                  [
                                                    _c(
                                                      "v-expansion-panel",
                                                      [
                                                        _c(
                                                          "v-expansion-panel-header",
                                                          {
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "actions",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.checkPanelEvent(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "expand_more"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c(
                                                              "table",
                                                              {
                                                                staticClass:
                                                                  "subscription-sub-table personal-use",
                                                              },
                                                              [
                                                                _c("tbody", [
                                                                  _c(
                                                                    "tr",
                                                                    {
                                                                      key: item.id,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "title-section",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "book-titles mr-1",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.bookLink(
                                                                                      item.document_info
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "graphic",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      title:
                                                                                        item.document_info,
                                                                                      thumbnail:
                                                                                        "",
                                                                                      width:
                                                                                        "50px",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "book-titles",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.bookLink(
                                                                                      item.document_info
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "book-title",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      title:
                                                                                        _vm.getBookTitle(
                                                                                          item.document_info
                                                                                        ),
                                                                                      print_version_edition:
                                                                                        _vm.printEdition(
                                                                                          item
                                                                                            .document_info
                                                                                            .print_version_edition
                                                                                            .version
                                                                                        ),
                                                                                      printing_text:
                                                                                        item
                                                                                          .document_info
                                                                                          .print_info,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-expansion-panel-content",
                                                          {
                                                            staticClass:
                                                              "grey lighten-4",
                                                          },
                                                          [
                                                            _c("v-data-table", {
                                                              staticClass:
                                                                "no-margin details-row",
                                                              attrs: {
                                                                headers:
                                                                  _vm.license,
                                                                items:
                                                                  item.group_licenses,
                                                                "hide-default-footer":
                                                                  "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "item",
                                                                      fn: function ({
                                                                        item,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "tr",
                                                                            {
                                                                              staticClass:
                                                                                "lighten-5",
                                                                              class:
                                                                                _vm.getStatusClass(
                                                                                  item
                                                                                ),
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item
                                                                                        .license_configuration
                                                                                        .available
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.checkSubscriptionType(
                                                                                        item.subscription_type
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "moment"
                                                                                      )(
                                                                                        item.purchase_date,
                                                                                        "MMMM Do, YYYY"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "moment"
                                                                                      )(
                                                                                        item.expiration_date,
                                                                                        "MMMM Do, YYYY"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.getSubscriptionStatus(
                                                                                        item
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      align:
                                                                                        "center",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.goToTheShop(
                                                                                              _vm.shopEncodesUrl
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      item.subscription_type ===
                                                                                      "M"
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Manage"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Renew"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ]
                                          }),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("template", { slot: "no-data" }, [
                            _vm.subscriptions.length == 0 && !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pt-12 text-md-center",
                                    attrs: { row: "" },
                                  },
                                  [_c("p", [_vm._v("No results found!")])]
                                )
                              : _vm.subscriptions.length == 0
                              ? _c(
                                  "div",
                                  { staticClass: "text-center pt-6" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }