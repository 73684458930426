<template>
    <div
        id="breadcrumb"
        class="grey lighten-3">
        <v-container :fluid="!isExtraLarge">
            <v-row
                no-gutters
                align="center"
                fill-height
                row
                wrap
                :class="{
                    'py-3': !isMobilePort
                }">
                <v-col cols="auto">
                    <v-tooltip 
                        right
                        max-width="300"
                        transition="fade-transition"
                        v-model="tooltipOpen">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                class="mr-5"
                                role="button"
                                aria-label="Edit"
                                @click="toggleTOC()"
                                v-on="on">
                                <v-icon>
                                    mdi-format-list-bulleted
                                </v-icon>
                            </v-btn>
                        </template>
                        <span class="tooltip-content"
                            @mouseenter="showTooltip"
                            @mouseleave="hideTooltip">
                            Browse by Category
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col>
                    <v-breadcrumbs
                        class="pa-0"
                        :class="{
                            'mb-4': !isMobilePort
                        }"
                        :items="data.breadcrumb">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                    <div>
                        <h1
                            class="primary--text display-1"
                            :class="{'title': isMobilePort}">
                            {{ pageTitle }}
                            <v-btn
                                v-if="data.chapterSearch && data.chapterSearch === true"
                                @click="backToContent"
                                class="primary btn-radius"
                                :small="isMobilePort">
                                Return to Content
                            </v-btn>
                        </h1>
                    </div>
                    <div
                        v-if="data.description"
                        v-html="data.description">
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        props: {
            data: {
                required: true,
                type: Object
            },
            titleSearchTerm: {
                required: false,
                type: String
            }
        },
        mixins: [Mobile],
        computed: {
            pageTitle() {
                if (this.titleSearchTerm) {
                    return `Title Results for "${this.titleSearchTerm}"`;
                }

                return this.data.title;
            }
        },
        methods: {
            backToContent() {
                window.location = this.$session.get('back-to-content');
            },
            toggleTOC() {
                EventBus.fire('trigger-category-legend');
            }
        }
    }
</script>
