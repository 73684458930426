var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "rounded-lg", attrs: { outlined: "" } },
    [
      _c("v-card-title", [_vm._t("title")], 2),
      _vm._v(" "),
      _vm.showDivider ? _c("v-divider") : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card-text",
        { class: { "pa-0": _vm.noPadding } },
        [_vm._t("content")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }