var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              !_vm.activeConcurrent
                ? [
                    _c("p", [
                      _c(
                        "a",
                        { attrs: { href: _vm.supportLink, target: "_blank" } },
                        [_vm._v("Concurrent access")]
                      ),
                      _vm._v(
                        " allows one or more users to access code book content and collaborate through a common code. The number of users able to connect to a concurrent code at the same time, as well as the code book titles available through a code, is configured by the user who creates the code.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                    To join a concurrent connection, please enter the access code in the below text box. Once connected, you will be able to see all notes entered from any other users who connect from the same code. The yellow banner at the top of your screen will indicate your concurrent connection.\n                "
                      ),
                    ]),
                  ]
                : [
                    _c("p", { staticClass: "mb-0" }, [
                      _c(
                        "a",
                        { attrs: { href: _vm.supportLink, target: "_blank" } },
                        [_vm._v("Concurrent access")]
                      ),
                      _vm._v(
                        " allows one or more users to access code book content and collaborate through a common code. The number of users able to connect to a concurrent code at the same time, as well as the code book titles available through a code, is configured by the user who creates the code.\n                "
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "grey lighten-5", attrs: { flat: "" } },
        [
          !_vm.isLoggedIn()
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "auto" } }, [
                        _vm._v(
                          "\n                    Please Sign In before entering your Concurrent Access Code\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "primary",
                                "x-large": "",
                                href: _vm.getLoginUrl(),
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Sign In\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : !_vm.activeConcurrent
            ? _c(
                "v-card-text",
                [
                  _vm.hasError
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    outlined: "",
                                    dismissible: "",
                                    type: "error",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.concurrentStatus.message) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              color: "primary",
                              label: "Enter Concurrent Access Code",
                            },
                            model: {
                              value: _vm.accessCode,
                              callback: function ($$v) {
                                _vm.accessCode = $$v
                              },
                              expression: "accessCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", "x-large": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.concurrentAccessValidation()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Submit\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isPreviousCodesBusy
                    ? [
                        _c("v-progress-linear", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ]
                    : _c(
                        "v-row",
                        _vm._l(_vm.previousCodes, function (code, key) {
                          return _c(
                            "v-col",
                            { key: key, attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "",
                                                      outlined: "",
                                                      color: _vm.isExpired(code)
                                                        ? "red"
                                                        : "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        !_vm.isExpired(code) &&
                                                          _vm.setUserProfile({
                                                            type: "concurrent",
                                                            metadata:
                                                              code.accessCode,
                                                          })
                                                      },
                                                    },
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 caption primary--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          code.accessCode
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                    _vm.isExpired(code)
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                    EXPIRED\n                                "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      right: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.showConfirmation(
                                                          code
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    mdi-close\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "tooltip-content" },
                                    [
                                      _vm._v(
                                        "\n                        Last Used, " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              code.lastUsedTime,
                                              "MMMM Do, YYYY"
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                ],
                2
              )
            : _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "auto" } }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                        You are now using concurrent access for Access Code "
                          ),
                          _c("b", [_vm._v(_vm._s(_vm.activeConcurrentCode))]),
                          _vm._v(".\n                    "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", "x-large": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.exitConcurrentAccess()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Exit Concurrent Access\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n                Please Confirm\n            "),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "\n                    Are you sure you want to remove this code from the list?\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", light: "" },
                      on: { click: _vm.cancelRemoveCode },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      on: { click: _vm.confirmRemoveCode },
                    },
                    [_vm._v("\n                    Remove\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }