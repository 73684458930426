<template>
    <v-sheet
        v-if="modal"
        :class="isMobilePort ? 'premium-information-model-mobile' : 'premium-information-model'"
        hide-overlay
        max-width="550px">
        <v-card
            class="fill-height"
            :class="{'rounded-0': isMobilePort}">
            <v-card-title
                class="primary py-1 px-4"
                style="word-break: unset">
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <p class="fs-16 mb-0 font-weight-medium white--text">
                            How does Premium work in Digital Codes?
                        </p>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            small
                            icon
                            color="white"
                            @click="close">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pt-4 px-4 pb-2">
                <p>
                    Look for these icons to understand your access level for each title.
                </p>
                <v-row
                    dense
                    v-for="(item, key) in information"
                    :key="key">
                    <v-col cols="auto">
                        <v-icon
                            large
                            :color="item.iconColor">
                            {{ item.icon }}
                        </v-icon>
                    </v-col>
                    <v-col>
                        <h3>
                            {{ item.title }}
                        </h3>
                        <p :class="{'mb-0': information.length-1 === key}">
                            {{ item.description }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-row
                    dense
                    justify="center">
                    <v-col cols="auto">
                        <v-btn
                            large
                            color="primary"
                            depressed
                            @click="close">
                            Close
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-sheet>
</template>

<script>
    import Mobile from "../../inc/mixins/mobile";

    export default {
        name: 'PremiumInformationModal',
        mixins: [Mobile],
        data() {
            return {
                modal: false,
                information: [
                    {
                        icon: 'mdi-lock-open-variant-outline',
                        iconColor: 'primaryMedium',
                        title: 'Basic',
                        description: 'You have read-only access to this title.',
                    },
                    {
                        icon: 'mdi-lock-outline',
                        iconColor: 'primaryMedium',
                        title: 'Premium Required',
                        description: 'You must subscribe to Premium to access this title.',
                    },
                    {
                        icon: 'mdi-creation',
                        iconColor: 'accent2',
                        title: 'Premium Active',
                        description: 'You have full access to this title with all copy, paste, print and bookmark abilies plus code insight data.',
                    },
                ]
            }
        },
        methods: {
            close() {
                this.$cookies.set('hide-premium-information-modal', 'true');
                this.modal = false;
            }
        },
        mounted() {
            if (!this.$cookies.get('hide-premium-information-modal')) {
                // this.modal = true;
            }
        }
    }
</script>

<style>
    .premium-information-model {
        position: absolute;
        right: 10px;
        top: 44px;
    }

    .premium-information-model-mobile {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: 100%;
        z-index: 100;
        margin-top: 400px;
    }
</style>
