<template>
    <v-container class="content-container">
        <v-row>
            <v-col>

                <img :src="asset(require('../../images/premium_large.png'))" alt="Digital Codes Premium"
                     style="width: 180px; margin-bottom: 1em; "/>

                <h1 class="primary--text oxygen mb-4">Start your Free 14-Day Trial</h1>

                <p class="primary--text mb-0">
                    Already have an account? <a :href="getRoute('security_login')" class="font-weight-bold">Sign In</a>
                </p>

            </v-col>
        </v-row>

        <v-row>
            <v-col lg="7">

                <div class="">

                    <div class="primary--text title oxygen">
                        The ICC sign in credentials also grant you access to the
                        following products and services:
                    </div>

                    <div class="d-flex px-2 py-5 primary--text" id="product_list">

                        <ul>
                            <li>myICC</li>
                            <li>ICC Shop</li>
                            <li>cdpACCESS</li>
                        </ul>

                        <ul>
                            <li>ICC Safe</li>
                            <li>Learning Center</li>
                            <li>Assessment Center</li>
                        </ul>

                    </div>

                </div>

                <registration-form :is-trial-registration="true"></registration-form>
            </v-col>

            <v-col lg="5">

                <up-sell-vertical
                        :image="upsellImage"
                        heading="Experience how ICC Digital Codes <b>Premium Complete</b> can help you:"
                        :options="{color: 'grey lighten-3'}"
                >
                    <template v-slot:description>
                        <ul>
                            <li class="pa-1">Search over {{ titleCount }} of the latest codes and referenced standards</li>
                            <li class="pa-1">Instantly identify compliance requirements using Advanced Search</li>
                            <li class="pa-1">Understand code requirement updates with Significant Changes, Revision History and
                                Errata
                            </li>
                            <li class="pa-1">Download and enjoy offline content using the premium mobile app</li>
                            <li class="pa-1">Build code expertise with Commentaries, Significant Changes and Code Essentials</li>
                            <li class="pa-1">Capture project specific requirements with inline Notes and Annotations</li>
                            <li class="pa-1">Collaborate and share files, notes and bookmarks effortlessly</li>
                        </ul>
                    </template>

                </up-sell-vertical>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import RegistrationForm from "./RegistrationForm";
import UpSellVertical from "../partials/UpSellVertical";

export default {
    components: {UpSellVertical, RegistrationForm},
    computed: {
        upsellImage() {
            return {
                src: 'icc-welcome.png'
            }
        },
        titleCount() {
            return this.$getConst('titlesCount');
        }
    }
}
</script>

<style scoped>
#product_list ul {
    width: 50%;
}

#product_list li {
    font-weight: bold;
}

#img_welcome {
    max-width: 100%;
    margin-bottom: 1em;
}

</style>
