<template>
    <v-row align="center">
        <v-col>
            <search-bar/>
        </v-col>
    </v-row>
</template>

<script>
    import SearchBar from './SearchBar.vue';

    export default {
        components: {
            SearchBar
        }
    }
</script>
