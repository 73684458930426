import {AppParameters} from "../../../AppParameters";

export default {
    data() {
        return {
            prices: [],
            isMember: false,
            loaded: false
        }
    },
    methods: {
        //Cart total price
        getTotalPrice() {
            let price = 0,
                _this = this;
            this.items.forEach(function (item) {
                let itemPrice = parseFloat(_this.getProductTotalPrice(item, _this.isMember));
                price += itemPrice;
            });
            return price.toFixed(2);
        },
        getDiscountedPrice(item, pricePerItem) {
            if (AppParameters.premium_convert_discount_enabled 
                    && this.trialActive 
                    && item.document_info.sku === this.$getConst('premiumCompleteSku')) {
                let discountPrice = (AppParameters.discount_value_in_percent / 100) * pricePerItem;
                let afterDiscountPrice = pricePerItem - discountPrice;
                
                return afterDiscountPrice;              
            }
            
            return pricePerItem;
        },
        getWithoutDiscountPrice(item, isMember, subscriptionType = ''){
            let priceForMember = !!isMember,
                pricePerItem = subscriptionType !== '' 
                    ? this.getPricePerItem(item, priceForMember, subscriptionType) 
                    : this.pricePerItem(item, priceForMember, subscriptionType);
            item.price = pricePerItem;

            return (pricePerItem * _.toInteger(item.quantity)).toFixed(2);
        },
        //Product total price (depends on quantity)
        getProductTotalPrice(item, isMember, subscriptionType = '') {
            let priceForMember = !!isMember,
                pricePerItem = subscriptionType !== '' 
                    ? this.getPricePerItem(item, priceForMember, subscriptionType) 
                    : this.pricePerItem(item, priceForMember, subscriptionType);
            item.price = this.getDiscountedPrice(item, pricePerItem);

            return (this.getDiscountedPrice(item, pricePerItem) * _.toInteger(item.quantity)).toFixed(2);
        },

        //Find the closest smaller value from prices array
        pricePerItem(item, isMember) {
            if (this.prices.general) {
                let priceForMember = !!isMember;
                let userTypePrices = (priceForMember) ? this.prices.member : this.prices.general,
                    quantity = item.quantity,
                    subscriptionType = userTypePrices[item.document_info.sku]['PDF'] !== undefined ? 'PDF' : item.subscription_type,
                    items = userTypePrices[item.document_info.sku][subscriptionType];

                if(subscriptionType === 'PDF'){
                    item.isPdf = true;
                }
                let i = 0,
                    minDiff = 1000,
                    result = 0;
                for (i in items) {
                    let difference = Math.abs(quantity - items[i].qty);

                    if (difference <= minDiff) {
                        minDiff = difference;
                        result = (items[i].qty > quantity)
                            ? items[i - 1].price
                            : items[i].price;
                    }
                }

                return result.toFixed(2);
            }
        },
        //Member savings
        getMemberSavings(item, isMember){
            let nonMemberPrice = this.getProductTotalPrice(item, isMember),
                memberPrice = this.getProductTotalPrice(item, true);

            return (nonMemberPrice - memberPrice).toFixed(2);
        },
        getPricePerItem(item, isMember, subscriptionType) {
            if (this.prices.general) {
                let priceForMember = !!isMember;
                let userTypePrices = (priceForMember) ? this.prices.member : this.prices.general,
                    quantity = item.quantity,
                    items = userTypePrices[item.document_info.sku][subscriptionType];
                
                let i = 0,
                    minDiff = 1000,
                    result = 0;
                for (i in items) {
                    let difference = Math.abs(quantity - items[i].qty);

                    if (difference <= minDiff) {
                        minDiff = difference;
                        result = (items[i].qty > quantity)
                            ? items[i - 1].price
                            : items[i].price;
                    }
                }

                return result.toFixed(2);
            }
        },
        getPurchaseSavings(item, isMember, subscriptionType) {
            let year = subscriptionType === 'C' ? 36 : 12,
                actualPrice = this.getPricePerItem(item, isMember, 'M') * item.quantity,
                discountPrice = this.getProductTotalPrice(item, isMember, subscriptionType),
                discount =  (discountPrice / year).toFixed(2),
                amountDifference = actualPrice - discount,
                savingPercent = ((amountDifference * 100) / actualPrice);

            return savingPercent.toFixed();
        }
    }
}
