var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title
    ? _c(
        "div",
        [
          _vm.type === "tile"
            ? _c("tile-image", {
                attrs: { title: _vm.title, showTitle: _vm.showTitle },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "list"
            ? _c("list-image", {
                attrs: {
                  title: _vm.title,
                  showVersion: _vm.showVersion,
                  showFavorite: _vm.showFavorite,
                  showLinkIcon: _vm.showLinkIcon,
                  listOutline: _vm.listOutline,
                  preventLink: _vm.preventLink,
                  hover: _vm.hover,
                  background: _vm.background,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }