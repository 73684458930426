<template>
    <v-card
        outlined
        :style="`background: ${$vuetify.theme.themes.light.accent2Light}`">
        <v-card-title class="font-weight-bold pa-2" style="font-size: 14px;">
            <v-icon color="accent2" class="pr-1">
                mdi-creation
            </v-icon>
            {{ title }}
        </v-card-title>
        <v-card-text class="font-weight-medium text-wrap px-3 fs-14">
            <p class="mb-0">{{ content }}</p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                depressed
                class="font-weight-bold px-4"
                outlined
                @click="goToRoute('premium_features')">
                Learn More        
            </v-btn>
            <v-btn
                v-if="!isActiveUser || canStartTrial"
                depressed
                class="font-weight-bold px-4"
                color="accent2"
                @click="goToRoute('trial_signup')">
                Start Free Trial
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
     export default {
        props: {
            title: {
                required: true,
                type: String
            },
            content: {
                required: true,
                type: String
            }
        }
     }
</script>
