<template>
    <v-dialog
        content-class="cdp-dialog"
        v-model="dialog"
        :max-width="1200"
        :fullscreen="isMobilePort">
        <v-row
            ref="cdpHeader"
            align="center"
            class="custom-row">
            <div
                class="top-background"
                :style="styles.background">
            </div>
            <v-col
                dark
                ref="topLeft"
                class="primary z-fix">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <h1 class="cdpBlue--text mx-5 mb-0 oswald cdp-logo font-weight-regular">
                            cdpACCESS
                        </h1>
                    </v-col>
                    <v-col>
                        <p
                            class="heading-5 white--text mb-0 mt-2 font-weight-bold">
                            Proposals Under Consideration
                        </p>
                        <p
                            v-if="!isMobilePort"
                            class="caption white--text mb-0">
                            Proposals listed are under active consideration within the code development process for integration to the next cycle’s version of the model code.  If a proposal is no longer being considered (i.e. withdrawn), it will be removed from the list.
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="auto"
                class="pa-0 z-fix triangle"
                :style="styles.topLeft">
            </v-col>
            <v-col
                v-if="!isMobilePort"
                cols="auto"
                class="z-fix py-0">
                <v-row
                    class="ml-n12"
                    align="center"
                    dense>
                    <v-col
                        cols="auto"
                        class="cp"
                        @click="gotToLink('https://www.iccsafe.org/products-and-services/i-codes/code-development/')">
                        <v-row dense>
                            <v-col class="pa-0">
                                <p class="white--text mb-0 oswald">
                                    WHAT IS THE
                                </p>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="pa-0">
                                <p class="white--text mb-0 ml-5 oswald font-weight-bold">
                                    CODE DEVELOPMENT PROCESS
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <p class="white--text mb-0 oswald font-40">
                            ?
                        </p>
                    </v-col>
                </v-row>
                <v-row
                    justify="space-around"
                    dense>
                    <v-col>
                        <p
                            class="caption white--text mb-0 cp"
                            @click="gotToLink('https://www.iccsafe.org/products-and-services/i-codes/code-development/')">
                            Click to learn more
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                align-self="start"
                class="pa-0"
                cols="auto">
                <v-btn
                    class="rounded-close"
                    small
                    fab
                    color="black"
                    @click="dialog = false">
                    <v-icon
                        class="accent2--text">
                        close
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pa-0">
                <v-card
                    ref="cdpBody"
                    class="rounded-card">
                    <v-card-text class="pa-0">
                        <template v-if="loading">
                            <v-container class="text-center py-12">
                                <v-progress-circular
                                    class="py-6"
                                    indeterminate
                                    color="primary">
                                </v-progress-circular>
                            </v-container>
                        </template>
                        <template v-else-if="!loading && !data.length">
                            <v-container class="text-center py-12">
                                <p>No results found</p>
                            </v-container>
                        </template>
                        <template v-else>
                            <v-btn
                                small
                                ref="expandButton"
                                color="grey darken-3"
                                class="expand-button"
                                fab
                                :style="styles.expandButton"
                                @click="showHideTabs()">
                                <v-icon color="white">
                                    {{ showTabs ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                                </v-icon>
                            </v-btn>
                            <v-tabs
                                v-model="tab"
                                vertical
                                background-color="grey lighten-3"
                                active-class="primary--text grey lighten-4"
                                :style="styles.contentHeight">
                                <span
                                    ref="tabs"
                                    v-show="showTabs">
                                    <v-tab
                                        v-for="(item, key) in data"
                                        :key="key">
                                        {{ item.tag.proposal_name }}
                                    </v-tab>
                                </span>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item
                                        v-for="(item, key) in data"
                                        :key="key">
                                        <vue-friendly-iframe
                                            :style="styles.contentHeight"
                                            :src="item.tag.url">
                                        </vue-friendly-iframe>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            ref="cdpFooter"
            class="custom-row"
            align="center">
            <div
                class="bottom-background"
                :style="styles.background">
            </div>
            <v-col
                cols="auto"
                class="z-fix">
                <v-row
                    dense
                    align="center"
                    class="cp"
                    @click="gotToLink('https://www.cdpaccess.com/')">
                    <v-col>
                        <p class="white--text mb-0 mt-2 ml-5">
                            Go to
                        </p>
                    </v-col>
                    <v-col>
                        <h2 class="white--text oswald">
                            cdpACCESS
                        </h2>
                    </v-col>
                </v-row>
            </v-col>
            <template v-if="!isMobilePort">
                <v-col
                    cols="auto"
                    class="pa-0 z-fix triangle"
                    :style="styles.bottomRight">
                </v-col>
                <v-col
                    class="primary z-fix"
                    ref="bottomRight">
                    <p class="white--text mb-0 caption">
                        The International Code Council develops construction and public safety codes through a
                        governmental consensus process. This system of code development has provided the
                        highest level of safety in the world for more than 90 years.
                    </p>
                </v-col>
            </template>
        </v-row>
    </v-dialog>
</template>

<script>
import Mobile from '../../inc/mixins/mobile';

export default {
    data: function() {
        return {
            loading: true,
            dialog: false,
            styles: {
                topLeft: '',
                bottomRight: '',
                background: 'background-color: '+this.$vuetify.theme.themes.light.cdpBlue,
                expandButton: '',
                contentHeight: ''
            },
            tab: null,
            data: [],
            showTabs: true
        };
    },
    mixins: [Mobile],
    methods: {
        showModal(proposalModal) {
            let showSubscription = '' == proposalModal.reportTypeShow
            this.loading = true;
            if (!showSubscription) {
                this.dialog = true;
            }
            this.$http.get(Routing.generate('get_report_tags', {
                contentId: proposalModal.contentId,
                chapterId: proposalModal.contentId,
                reportType: 'cdp'
            })).then(response => {
                if (response.data) {
                    if (showSubscription) {
                        EventBus.fire('show-subscription-modal', this.getSubscriptionModalData(response.data));
                    } else {
                        this.data = response.data.reportTags;
                    }
                }
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
            this.$ga.event({
                hitType: 'event',
                eventCategory: 'premiumTagViewCDP',
                eventAction: 'click',
                eventLabel: 'cdpACCESS content tag view'
            })
        },
        setStyles() {
            this.setTriangleStyle();
            this.setTriangleStyle(true);
            this.setExpandStyle();
            this.setContentHeight();
        },
        setTriangleStyle(top = false) {
            let height, style;
            if (top) {
                if (this.$refs.topLeft) {
                    style = 'border-color: transparent transparent transparent '+this.$vuetify.theme.themes.light.primary+';';
                    height = this.$refs.topLeft.clientHeight;
                    style += 'border-width: '+height+'px 0 0 '+height+'px;';
                    this.$set(this.styles, 'topLeft', style);
                }
            } else {
                if (this.$refs.bottomRight) {
                    style = 'border-color: transparent '+this.$vuetify.theme.themes.light.primary+' transparent transparent;';
                    height = this.$refs.bottomRight.clientHeight;
                    style += 'border-width: 0 '+height+'px '+height+'px 0;';
                    this.$set(this.styles, 'bottomRight', style);
                }
            }
        },
        setExpandStyle() {
            let style = 'left: 10px;';
            if (this.showTabs) {
                if (this.$refs.tabs && this.$refs.expandButton) {
                    style = 'left: '+(this.$refs.tabs.clientWidth - this.$refs.expandButton.$el.clientWidth - 10)+'px;';
                }
            }
            this.$set(this.styles, 'expandButton', style);
        },
        setContentHeight() {
            let offset = 80;
            if (this.$refs.cdpHeader) {
                offset = offset + this.$refs.cdpHeader.clientHeight;
            }
            if (this.$refs.cdpFooter) {
                offset = offset + this.$refs.cdpFooter.clientHeight;
            }
            this.$set(this.styles, 'contentHeight', 'height: ' + (window.innerHeight - offset) + 'px;');
        },
        showHideTabs() {
            this.showTabs = !this.showTabs;
            this.setExpandStyle();
        },
        gotToLink(url) {
            window.open(url, '_blank');
        }
    },
    created() { 
        EventBus.listen('showCDPModal', (proposalModal) => this.showModal(proposalModal));
        window.addEventListener("resize", this.setStyles);
    },
    destroyed() {
        window.removeEventListener("resize", this.setStyles);
    },
    updated() {
        this.$nextTick(function () {
            this.setStyles();
        })
    }
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
    .cdp-dialog {
        box-shadow: none;
        border-radius: 0;
        .oswald {
            font-family: "Oswald", Arial;
        }
        .z-fix {
            z-index: 203;
        }
        .font-40 {
            font-size: 40px;
        }
        .v-tab {
            justify-content: left;
        }
        .custom-row {
            position: relative;
            .triangle {
                border-style: solid;
                width: 0;
                height: 0;
            }
            .top-background, .bottom-background {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 90%;
                width: 98%;
            }
            .bottom-background {
                bottom: unset;
                top: 0;
                left: unset;
                right: 0;
                border-radius: 0 0 0 4px;
            }
        }
        .rounded-close {
            border-radius: 4px;
        }
        .rounded-card {
            border-radius: 0 4px!important;
        }
        .v-slide-group__wrapper {
            height: 100%;
            overflow-y: scroll;
            display: block;
        }
        .expand-button {
            position: absolute;
            z-index: 1;
            bottom: 10px;
        }
    }
    .vue-friendly-iframe {
        iframe {
            width: 100%;
            height: 100%;
        }
    }
</style>
