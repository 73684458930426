var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.applyBackground
        ? _c(
            "v-chip",
            {
              staticClass: "px-1",
              attrs: { label: "", color: _vm.getProfileColor(_vm.profile) },
            },
            [
              _c("v-icon", [
                _vm._v("\n            " + _vm._s(_vm.icon) + "\n        "),
              ]),
            ],
            1
          )
        : _c("v-icon", { attrs: { color: _vm.getIconColor() } }, [
            _vm._v("\n        " + _vm._s(_vm.icon) + "\n    "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }