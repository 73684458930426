var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _vm.activeUsers > _vm.enterpriseTotalSeats
              ? _c(
                  "p",
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          color: "error",
                          type: "warning",
                          dismissible: "",
                        },
                      },
                      [
                        _c("h4", [
                          _vm._v(
                            "The Active User count for your Enterprise profile has exceeded the number of available seats.  Please contact your ICC Specialist to add additional seats or deactivate users from the Manage Users tab."
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "flex-grow-1", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-xl primary",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "white--text pb-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm._v(
                                "\n                            Active Users\n                            "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", color: "black" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    small: "",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                        info\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "tooltip-content" },
                                    [
                                      _c("p", { staticClass: "caption mb-0" }, [
                                        _vm._v(
                                          "\n                                        Active Users are the individuals who have accepted the invite"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        and signed into the enterprise account at least once"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        and are currently consuming one of the available"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        licenses for Digital Codes Premium.\n                                    "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.activeUsers) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "flex-grow-1", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "primary rounded-xl",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "white--text pb-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm._v(
                                "\n                            Available Enterprise Seats\n                            "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", color: "black" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    small: "",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                        info\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "tooltip-content" },
                                    [
                                      _c("p", { staticClass: "caption mb-0" }, [
                                        _vm._v(
                                          "\n                                        The total number of purchased enterprise seats from the"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        active purchase orders. When Active Users exceeds"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        Available Seats, the client will need to either add seats"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        to match active users or deactivate users to be at"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        or below the available seats number.\n                                    "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.enterpriseTotalSeats) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "flex-grow-1", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "primary rounded-xl",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "white--text pb-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm._v(
                                "\n                            Pending Users\n                            "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", color: "black" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    small: "",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                        info\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "tooltip-content" },
                                    [
                                      _c("p", { staticClass: "caption mb-0" }, [
                                        _vm._v(
                                          "\n                                        Pending Users are individuals who have been invited to the"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        enterprise account but have not yet accepted the invite"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        to join into enterprise . Once a user accepts the invite and signs in,"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        they will be moved from Pending User to an Active User.\n                                    "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.pendingUsers) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "flex-grow-1", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "primary rounded-xl",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "white--text pb-0 pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm._v(
                                "\n                            Total Users\n                            "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", color: "black" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    small: "",
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                        info\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "tooltip-content" },
                                    [
                                      _c("p", { staticClass: "caption mb-0" }, [
                                        _vm._v(
                                          "\n                                        Total Users represent all individuals listed in the Manage Users"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        table who were added (either individually, through domain"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        matching or CSV import) regardless of whether they"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        are Active, Deactivated or Pending status.\n                                    "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.totalUsers) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }