var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: { href: _vm.link, target: "_blank" },
      on: {
        click: function ($event) {
          return _vm.registerClick(_vm.breadcrumbs, _vm.documentId)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }