<template>
    <v-container class="content-container">
        <v-row
            class="mt-12"
            v-if="loading.section || loading.document"
            justify="center">
            <v-col
                cols="12"
                md="6">
                <v-card>
                    <v-card-text>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <p class="caption">
                                    Loading Section
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-progress-circular
                                    indeterminate
                                    color="primary">
                                </v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <template v-else>
            <v-row>
                <v-col>
                    <div
                        class="newIccStyles"
                        v-html="sectionContent">
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        large
                        color="primary"
                        @click="goToChapter()">
                        Jump to Chapter
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="my-6">
                <v-col>
                    <v-divider>
                    </v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="auto">
                    <img
                        class="book-cover my-3 rounded-lg"
                        :src="getBookCover(documentInfo)"
                        @error="loadDefaultBookCover"/>
                </v-col>
                <v-col>
                    <v-row
                        dense
                        align="center">
                        <v-col>
                            <h2>
                                {{ getBookTitle(documentInfo) }}
                            </h2>
                        </v-col>
                        <v-col>
                            <v-row
                                dense
                                align="center">
                                <v-col cols="auto">
                                    <p class="mb-0 primary--text caption">
                                        Categories:
                                    </p>
                                </v-col>
                                <v-col>
                                    <v-chip
                                        class="ma-1"
                                        v-for="(item, key) in documentInfo.categories"
                                        :key="key"
                                        small
                                        @click="goToCategory(item.slug)">
                                        {{ item.label }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 class="mb-2 mt-3 primary--text font-weight-regular fs-22">
                                About this Title
                            </h3>
                            <div
                                class="my-4"
                                v-html="documentInfo.description">
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="my-6">
                <v-col>
                    <v-img
                        class="cp"
                        @click="premiumSubscribe"
                        :src="asset(require('../../images/banners/unlock_premium_complete.webp'))">
                    </v-img>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                loading: {
                    section: true,
                    document: true
                },
                documentInfo: [],
                sectionContent: ''
            }
        },
        props: {
            documentId: {
                required: true,
                type: Number
            },
            chapterId: {
                required: true,
                type: Number
            },
            sectionHash: {
                required: true,
                type: String
            }
        },
        methods: {
            premiumSubscribe() {
                this.getPremiumCompleteBook();
            },
            getTitleInfo() {
                this.$http.get(Routing.generate('get_book_info', {
                    'documentId': this.documentId
                })).then(
                    response => {
                        if (response.data) {
                            this.documentInfo = response.data;
                        }
                        this.$set(this.loading, 'document', false);
                    }
                )
            },
            getSectionContent() {
                let data = {
                    hash: this.sectionHash,
                    document: this.documentId
                }
                this.$http.get(Routing.generate('get_snippet',
                    data
                )).then(
                    response => {
                        if (response.data) {
                            let content = response.data.content;
                            let meta = response.data.meta;

                            this.sectionContent = content.content;
                            document.title = content.documentTitle+' - '+meta.chapterTitle+' - '+meta.sectionTitle;

                            // Reset meta description and keywords to allow Google to select best match.
                            document.querySelector('meta[name="description"]').setAttribute("content", '');
                            document.querySelector('meta[name="keywords"]').setAttribute("content", '');
                        }

                        this.$set(this.loading, 'section', false);
                    }
                )
            },
            goToCategory(categorySlug) {
                this.goToRoute('codes_category', { 'category': categorySlug });
            },
            goToChapter() {
                this.goToRoute('content_document', { 'documentId': this.documentId, 'chapterId': this.chapterId });
            }
        },
        mounted() {
            this.getTitleInfo();
            this.getSectionContent();
        }
    }
</script>
