var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "py-0", attrs: { role: "menu" } },
    [
      _vm.activeProfile
        ? _c(
            "v-list-item",
            { staticClass: "py-2 grey lighten-4" },
            [
              _c(
                "v-list-item-title",
                { staticClass: "white--text" },
                [
                  [
                    _c(
                      "h4",
                      { staticClass: "pb-1 primary--text txt text-capitalize" },
                      [_vm._v(_vm._s(_vm.fullName))]
                    ),
                    _vm._v(" "),
                    _c(
                      "h5",
                      {
                        staticClass: "font-weight-regular primary--text",
                        staticStyle: { "font-size": "12px" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.email) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "v-list-item",
            {
              staticClass: "py-2",
              on: {
                click: function ($event) {
                  return _vm.goToLogInPage()
                },
              },
            },
            [
              _c(
                "v-list-item-title",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "mt-n1",
                              attrs: {
                                rounded: "",
                                size: "30",
                                color: "primary",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v(
                                  "\n                            mdi-account\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("h3", [
                          _vm._v(
                            "\n                        Sign In\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }