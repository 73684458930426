var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expand-transition",
    [
      _vm.premiumInfoActive
        ? _c(
            "v-sheet",
            {
              staticClass: "content-container",
              attrs: { color: "accent2Light" },
            },
            [
              _c(
                "v-container",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-5 pb-0" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { color: "accent2" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                mdi-creation\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "h2",
                                    { class: _vm.isMobilePort && "title" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.hasPremiumSubscription
                                              ? "Explore Premium tools and features"
                                              : "Subscribe to Digital Codes Premium"
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "font-weight-regular mt-2",
                                      class: _vm.isMobilePort && "caption",
                                    },
                                    [
                                      _vm._t("subText", function () {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.hasPremiumSubscription
                                                  ? "You have an active premium subscription to Digital Codes"
                                                  : "Get access to full questions and answers with your subscription"
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("inline-icons", {
                                    attrs: { type: _vm.type },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.hasPremium
                        ? _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "auto",
                                order: _vm.isMobilePort ? "12" : "",
                              },
                            },
                            [
                              _c("premium-learn-more", {
                                attrs: {
                                  outlined: "",
                                  color: "accent",
                                  block: _vm.isMobilePort,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "auto",
                            order: _vm.isMobilePort ? "12" : "",
                          },
                        },
                        [
                          _c("start-free-trial", {
                            attrs: { block: _vm.isMobilePort },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.premiumInfoActive = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v(
                                  "\n                            mdi-close\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }