import Favorite from '../../../content/favorite';
import ContentSearch from '../../../search/mixins/TabbedSearch';

export default {
    data: function () {
        return {
            chapter_title: '',
            search_text: '',
            from_search: false,
            back_to_search_url: false,
            commentary_btn_label: 'Hide Commentary',
            show_commentary: true,
            show_highlight: false,
        };
    },
    props: {
        pdfurl: {
            type: String,
            required: true
        },
        contentPremium: {
            type: String,
            required: true
        },
        contentBasic: {
            type: String,
            required: true
        },
        contentProtection: {
            type: String,
            required: true
        },
        bookAvailableToSubscribe: {
            type: String,
            required: true
        },
        examId: {
            type: String,
            required: true
        }
    },
    components: {
        Favorite
    },
    mixins: [
        ContentSearch
    ],
    computed: {
        getColor() {
            if (this.getLoggedIn) {
                return 'accent2';
            }
            return 'primary';
        },
        getLoggedIn() {
            return this.$store.getters.getLoggedIn !== '';
        },
        getChapterTitle() {
            if (this.chapter_title) {
                return this.chapter_title;
            }
        },
        getDocumentTitle() {
            let pageTitle = this.getBookTitle(this.activeDocumentInfo) + ' | ICC Digital Codes';
            document.title = '' !== this.chapter_title ? this.chapter_title + ', ' + pageTitle : pageTitle;
            return this.getBookTitle(this.activeDocumentInfo);
        },        
        checkCommentary: function() {
            return this.activeDocumentInfo.book_categories.indexOf('Commentaries') > -1;
        }
    },
    methods: {
        buyNow() {
            window.open(this.activeDocumentInfo.shop_url, '_blank');
        },
        goToPdf() {
            window.open(this.pdfurl);
        },
        pdfEvent(id) {
            EventBus.fire('pdf-event', id);
        },
        updateChapterTitle(chapter_title) {
            this.chapter_title = chapter_title;
        },
        dismissSearch() {
            window.location = window.location.origin + window.location.pathname;
        },
        toggleCommentary: function() {
            if (this.show_commentary) {
                this.show_commentary = false;
                this.commentary_btn_label = 'Show Commentary';

                // Hide commentary content
                var commentaryElements = document.getElementsByClassName('commentary');
                for (var i = 0; i < commentaryElements.length; i++) {
                    commentaryElements[i].style.display = 'none';
                }
                // Misspelled class in XML
                var commentaryElements = document.getElementsByClassName('commentry');
                for (var i = 0; i < commentaryElements.length; i++) {
                    commentaryElements[i].style.display = 'none';
                }

                var moreCommentaryElements = document.getElementsByClassName('commentary_general');
                for (var i = 0; i < moreCommentaryElements.length; i++) {
                    moreCommentaryElements[i].style.display = 'none';
                }

            } else {
                this.show_commentary = true;
                this.commentary_btn_label = 'Hide Commentary';

                // Show commentary content
                var elements = document.getElementsByClassName('commentary');
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'block';
                }
                // Misspelled class in XML
                var elements = document.getElementsByClassName('commentry');
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'block';
                }

                var moreElements = document.getElementsByClassName('commentary_general');
                for (var i = 0; i < moreElements.length; i++) {
                    moreElements[i].style.display = 'block';
                }
            }
        },
        showSearch(sku) {
            return (
                this.isPremiumSubscribed(sku) ||
                this.$store.getters.isAdmin ||
                _.toNumber(this.contentPremium)
            );
        },
        removeHighlight(){
            var element = document.querySelector('.content_highlight');
            element.classList.remove('content_highlight');
            element.classList.add('no_highlight');
            this.show_highlight = false;
        },
        checkHighlightExist() {
            if(document.querySelector('.content_highlight')) {
                this.show_highlight = true;
            }
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let searchString = params.get("search");
        if (searchString && searchString !== "" && searchString !== "null") {
            this.from_search = true;
            EventBus.fire('from-search-page');
            this.search_text = params.get("search");
            this.back_to_search_url = document.referrer;
        }
        setTimeout(() => {
            EventBus.fire('apply-search-highlights', this.search_text);
            this.checkHighlightExist();
        }, 3500);
    },
    created() {
        EventBus.listen('update-chapter-title', (chapter_title) => this.updateChapterTitle(chapter_title));
    }
}
