<template>
    <div>
        <breadcrumb title="Quick Access">
        </breadcrumb>
        <v-container
            class="content-container">
            <v-row>
                <v-col>
                    <h1 class="font-weight-regular primary--text">
                        Quick Access to Section Specific Requirements
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    lg="8">
                    <p class="font-weight mb-0">
                        View full section specific contents from your Digital Codes Premium titles
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h3 class="font-weight-regular primary--text">
                        Step 1: Enter year and title for desired contents (ex. “2021 IBC”)
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    sm="6">
                    <v-autocomplete
                        v-model="selected"
                        :items="titles"
                        :loading="titlesLoading"
                        :search-input.sync="titleSearch"
                        no-filter
                        outlined
                        hide-no-data
                        clearable
                        hide-details
                        append-icon=""
                        item-text="display_title"
                        item-value="document_id"
                        placeholder="Start typing to Search"
                        return-object
                        @keydown="debouncedGetResults"
                        @change="getVersions"
                        @focus="focusGained">
                        <template v-slot:item="{ item }">
                            <title-list
                                style="width: 100%"
                                :title="item"
                                type="list"
                                :showLinkIcon="false"
                                :listOutline="false"
                                :preventLink="true"
                                :hover="false"
                                background="transparent">
                            </title-list>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col
                    v-if="selected"
                    cols="12"
                    sm="6">
                    <v-select
                        v-model="version"
                        style="max-width: 300px;"
                        :items="printings"
                        outlined
                        hide-details
                        return-object
                        item-value="document_id"
                        :loading="printingsLoading"
                        :menu-props="{ bottom: true, offsetY: true }">
                        <template v-slot:selection="{ item }">
                            {{getBookPrintingInfo(item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{getBookPrintingInfo(item)}}
                        </template>
                    </v-select>
                    <p class="mb-0">
                        The most recent version of the selected title will be accessed.<br/>
                        Please select any previous version above, if desired.
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col>
                    <h3 class="font-weight-regular primary--text">
                        Step 2: Enter the specific section number (ex. “510.1”)
                    </h3>
                </v-col>
            </v-row>
            <v-row id="sectionNo">
                <v-col lg="6">
                    <v-autocomplete
                        v-model="selectedSection"
                        :items="sections"
                        :search-input.sync="sectionSearch"
                        outlined
                        clearable
                        hide-details
                        hide-no-data
                        hide-selected
                        append-icon=""
                        item-text="sectiontitle"
                        :loading="sectionsLoading"
                        :disabled="!version || !selected"
                        placeholder="Start typing to Search"
                        return-object
                        @keypress="debouncedGetSections"
                        @change="getSectionContent"
                        @click:clear="clearSections">
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.sectionappendix}}{{item.sectiontitle}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <notes></notes>
            <section-content v-show="selectedSection">
            </section-content>
            <template v-if="recentlyAccessed && recentlyAccessed.length">
                <v-row
                    :class="{
                        'mt-1': isMobilePort,
                        'mt-2': !isMobilePort
                    }">
                    <v-col>
                        <h3 class="font-weight-regular primary--text">
                            Recently Quick Accessed Sections
                        </h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        lg="8">
                        <p class="font-weight-regular">
                            Click to view full section on page
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-for="(item, key) in recentlyAccessed"
                        :key="key"
                        cols="12"
                        sm="6">
                        <v-card
                            outlined
                            class="fill-height recentlyAccess"
                            @click="getSections(item)"
                            :style="'border: 1px solid '+$vuetify.theme.themes.light.primary">
                            <v-card-text class="primary--text">
                                <v-row
                                    align="center"
                                    >
                                    <v-col>
                                        <h3>
                                            {{item.sectionTitle}}
                                        </h3>
                                        <p class="mb-0">
                                            {{getBookTitle(item.document)}}
                                        </p>
                                    </v-col>
                                    <v-col
                                        cols="auto"
                                        class="pa-0">
                                        <v-icon x-large class="pr-5">
                                            mdi-chevron-right
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>
<script>
    import Breadcrumb from '../content/partials/page_titles';
    import TitleList from "../titles/cover/Index";
    import Testimonials from "../content/partials/Testimonials";
    import SectionContent from '../dashboard/quick_access/section_content';
    import Notes from '../titles/notesModal/index';
    import {QuickAccessApi} from "../../api/QuickAccessApi";
    import Mobile from '../inc/mixins/mobile';

    export default {
        data() {
            return {
                debouncedGetSections: null,
                debouncedGetResults: null,
                selected: null,
                titleSearch: null,
                version: null,
                sectionSearch: null,
                selectedSection: null,
                sections: null,
                sectionsLoading: false,
                printings: [],
                printingsLoading: false
            };
        },
        computed: {
            recentlyAccessed() {
                return this.$store.getters['quickAccess/RecentlyAccessed'];
            },
            titles() {
                if (this.$store.getters['quickAccess/Titles'].length) {
                    return this.$store.getters['quickAccess/Titles'];
                }
            },
            titlesLoading() {
                return this.$store.getters['quickAccess/TitlesLoading'];
            }
        },
        components: {
            Testimonials,
            Breadcrumb,
            TitleList,
            SectionContent,
            Notes
        },
        mixins: [
            Mobile
        ],
        methods: {
            async getVersions(event) {
                this.printingsLoading = true;
                let response = await QuickAccessApi.getVersions(event.document_id);
                let data = _.filter(response.data.result_data, function(item) {
                    return item.print_version_edition.version === 'First'
                        || item.document_type.name === 'New Printing';
                });

                this.printings = _.sortBy(data, [function(item) {
                    return item.associate_sort_order;
                }]);
                this.version = this.printings[0];
                this.printingsLoading = false;
            },
            async getSections(quickAccessSection) {
                let searchText = quickAccessSection ? '' : this.sectionSearch;
                let searchDocumentId = quickAccessSection ? quickAccessSection.document.document_id : this.version.document_id;
                let sectionId = quickAccessSection ? quickAccessSection.sectionId : 0;

                this.clearSections();
                this.sectionsLoading = true;
                let response = await QuickAccessApi.getSections(searchText, searchDocumentId, sectionId);
                let data = [];
                if (response.data.length) {
                    response.data.forEach(function (item) {
                        if (item !== undefined) {
                            data.push(item['_source']);
                        }
                    });

                    this.sections = _.sortBy(data, item => item.sectiontitle);

                    // Load recently accessed section on the same page and show that title/section selected
                    if(quickAccessSection) {
                        this.selected = quickAccessSection.document;
                        this.getVersions(this.selected);
                        this.selectedSection = this.sections[0];
                        this.getSectionContent(this.selectedSection, this.selected);
                    }
                }

                this.sectionsLoading = false;
            },
            getSectionContent(event, selectedDocument = null) {
                let selectedSection = {
                    'document': selectedDocument ? selectedDocument : this.version,
                    'sectionId': event.contentid,
                    'sectionContent' : event.content,
                    'chapterId' : event.chapterId,
                    'sectionTitle': event.sectiontitle,
                };
                EventBus.fire('section-content', selectedSection);
                // Focus to the content area
                this.$scrollTo(document.getElementById('sectionNo'));
            },
            clearTitles() {
                this.$store.commit('quickAccess/SetTitles', []);
                this.printings = [];
                this.clearSections();
            },
            clearSections() {
                this.sections = [];
            },
            focusGained() {
                if(!this.selected && !this.titleSearch) {
                    this.$store.commit('quickAccess/SetTitles', []);
                }
            }
        },
        mounted() {
            this.$store.dispatch('quickAccess/GetRecentlyAccessed');
        },
        created() {
            this.debouncedGetResults = _.debounce(() => this.$store.dispatch('quickAccess/GetTitles', this.titleSearch), 500);
            this.debouncedGetSections = _.debounce(() => this.getSections(), 500);
        }
    }
</script>
<style lang="scss">
    .recentlyAccess:hover {
        background: #FAFAFA !important;
    }
    .quick-access-section-content {
        font-size: 1rem;
    }
</style>
