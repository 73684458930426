var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          model: {
            value: _vm.protectedContent,
            callback: function ($$v) {
              _vm.protectedContent = $$v
            },
            expression: "protectedContent",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v("\n                Read Only\n                "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.modalClose()
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "lighten-3 px-3" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-3",
                          attrs: { cols: "12", align: "center" },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: _vm.asset(
                                  require("../../images/icc_digital_codes_premium.png")
                                ),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 pb-2", attrs: { cols: "12" } },
                        [
                          _c("p", { attrs: { align: "center" } }, [
                            _vm._v(
                              "\n                            The book content is read only.\n                        "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }