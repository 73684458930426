var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("actionable-alert", {
    attrs: { visible: _vm.isVisible, "action-text": "Migrate Notes Now" },
    on: { onAction: _vm.migrateNotes, onDismiss: _vm.dismissSnackbar },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c(
              "v-icon",
              { staticClass: "mr-1", attrs: { color: "blue darken-4" } },
              [_vm._v("mdi-information-outline")]
            ),
            _vm._v(
              "\n        You have notes created on a prior version of this title. Would you like to migrate your notes to the current version?\n    "
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }