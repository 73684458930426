<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    Assigned to Me licenses are those where another user has purchased a premium subscription and assigned dedicated usage access to me.  Usage of the premium licenses assigned to me are restricted to my individual account but are subject to the assignee revoking access if they choose to.
                </p>
            </v-col>
        </v-row>
        <v-data-table
            :headers="title_details"
            :items="subscriptions"
            :expand="expand"
            :items-per-page="pagination.rowsPerPage"
            :loading="loading"
            must-sort
            class="elevation-0 no-margin"
            :footer-props="{
                itemsPerPageOptions: pagination.rowsPerPageItems
            }">
            <template v-slot:item="{ item }">
                <td class="body-2 px-2">
                    <v-row
                        dense
                        align="center">
                        <v-col shrink align-self-center>
                            <a @click="bookLink(data.item.subscription.document_info)">
                                <graphic
                                    :title="data.item.subscription.document_info"
                                    thumbnail
                                    width="40px"
                                    additionalClasses="mr-2">
                                </graphic>
                            </a>
                        </v-col>
                        <v-col>
                            <a @click="bookLink(data.item.subscription.document_info)">
                                <book-title
                                    :title="getBookTitle(data.item.subscription.document_info)"
                                    :print_version_edition="printEdition(data.item.subscription.document_info.print_version_edition.version)"
                                    :printing_text="data.item.subscription.document_info.print_info"></book-title>
                            </a>
                        </v-col>
                    </v-row>
                </td>
            </template>
            <template v-slot:expand="props">
                <v-card
                    flat
                    class="green lighten-5 text-center">
                    <v-row>
                        <v-col xs12>
                            <p class="caption font-weight-bold mb-0">Assigned By</p>
                            {{props.item.assigned_by.email}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption font-weight-bold mb-0">Assigned Date</p>
                            {{props.item.created_at | moment("MMMM Do, YYYY")}}
                        </v-col>
                        <v-col>
                            <p class="caption font-weight-bold mb-0">Expiration Date</p>
                            {{props.item.subscription.expiration_date | moment("MMMM Do, YYYY")}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption font-weight-bold mb-0">Subscription Type</p>
                            {{checkSubscriptionType(props.item.subscription.subscription_type)}}
                        </v-col>
                        <v-col>
                            <p class="caption font-weight-bold mb-0">Subscription Status</p>
                            {{checkStatus(props.item)}}
                            <p
                                class="caption mb-0"
                                v-if="checkStatus(props.item) === 'Revoked'">
                                {{props.item.updated_at | moment("MMMM Do, YYYY")}}
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </template>
            <template slot="no-data">
                <v-card v-if="!subscriptions_loaded">
                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                </v-card>
                <p
                    class="mt-4"
                    v-if="subscriptions_loaded">You do not have any Premium subscriptions.</p>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import AssignedToMe from '../mixins/assigned_to_me.js';
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [Licenses, AssignedToMe],
        components: {
            Graphic
        },
        data() {
            return {
                expand: false,
                title_details: [
                    {text: 'Book Title', align: 'left', value: 'di.title', expand: true},
                ]
            }
        },
        mounted() {
            this.getAssignedToMeSubscriptions();
        }
    }

</script>
