<template>
    <v-chip
        :color="statusRender.color">
        <v-icon
            :color="statusRender.iconColor"
            left>
            {{ statusRender.icon }}
        </v-icon>
        {{ statusRender.text }}
    </v-chip>
</template>

<script>
    export default {
        props: {
            status: {
                type: Object,
                required: true
            }
        },
        computed: {
            statusRender() {
                switch (this.status.key) {
                    case 'Submitted':
                        return {
                            text: 'Submitted',
                            color: 'grey lighten-3',
                            iconColor: 'black',
                            icon: 'mdi-dots-horizontal-circle-outline'
                        };
                    case 'InProgress':
                        return {
                            text: 'In Progress',
                            color: 'orange lighten-5',
                            iconColor: 'orange darken-2',
                            icon: 'mdi-account-supervisor-circle-outline'
                        };
                    case 'Answered':
                        return {
                            text: 'Answered',
                            color: 'green lighten-5',
                            iconColor: 'green darken-3',
                            icon: 'mdi-check-circle-outline'
                        };
                }
            }
        }
    }
</script>