var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("search-header", {
        attrs: {
          "total-results": _vm.pagination.totalResults,
          "show-results-text": _vm.results.length > 0 && !_vm.loading,
          search: _vm.activeSearch,
          sort: _vm.sort,
          filters: _vm.filters,
          loading: _vm.loading,
        },
        on: {
          clearSearch: (key) => _vm.clearSearch(key),
          sortChange: (data) => _vm.updateSort(data),
          showModal: () => (_vm.dialog = true),
        },
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.loading
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [_c("v-card-text", [_c("loading-linear")], 1)],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.hasInput
                ? _c("content-search-results", {
                    attrs: {
                      "search-results": _vm.results,
                      loading: _vm.loading,
                      search: _vm.activeSearch,
                      "did-you-mean": _vm.didYouMean,
                    },
                  })
                : _c("missing-search-terms"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.pagination.totalResults && !_vm.loading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("pagination-pages", {
                    attrs: { pagination: _vm.pagination },
                    on: { changed: (value) => _vm.updatePage(value) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("share-section-modal", { key: _vm.shareModalKey }),
      _vm._v(" "),
      _c("notes-modal"),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("h3", [_vm._v("Content Filters")]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [
                  _c("content-search-filters", {
                    attrs: { filters: _vm.filters },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Close\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }