<template>
    <actionable-alert
            :visible="isVisible && !isIgnoring"
            action-text="Yes"
            @onAction="visitMostRecentVersion"
            dismiss-text="No, Dismiss"
            @onDismiss="dismissSnackbar"
    >

        <template v-slot:text>
            <v-icon color="blue darken-4" class="mr-1">mdi-information-outline</v-icon>
            You are viewing a Historical version of this title. Do you want to view the most recent version?
        </template>

    </actionable-alert>
</template>

<script>
import ActionableAlert from "../ActionableAlert.vue";
import {UrlUtil} from "../../util/UrlUtil";

const HIDE_NOTIFICATION_SESSION_KEY = 'hideVersionNotificationForDocuments';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        masterDocumentId: {
            type: Number,
            required: false
        }
    },
    components: {ActionableAlert},
    data() {
        return {
            isVisible: false
        }
    },
    computed: {
        isIgnoring() {

            if (this.masterDocumentId) {

                // any version of this master book will not show this warning
                const documentIgnoreList = this.getSessionValueAsArray(HIDE_NOTIFICATION_SESSION_KEY);
                return documentIgnoreList.includes(this.masterDocumentId);
            }

            return false;
        }
    },
    methods: {
        visitMostRecentVersion() {

            const associatedBooks = this.$store.getters.getAssociatedBooks || [];

            if (associatedBooks.length) {
                const slugFromFirst = associatedBooks[0].document_slug;
                const newUrl = UrlUtil.newDocumentUrlFromCurrentUrl(slugFromFirst);

                if (newUrl) {
                    window.location = newUrl;
                }
            }
        },
        dismissSnackbar() {
            this.isVisible = false;

            const bookId = this.masterDocumentId;

            // remember this decision!
            const list = this.getSessionValueAsArray(HIDE_NOTIFICATION_SESSION_KEY);
            list.push(bookId);
            this.$session.set(HIDE_NOTIFICATION_SESSION_KEY, list);

            // remember it forever if logged in
            if (this.$store.getters.getLoggedIn) {

                this.$http.post(Routing.generate('add_book_to_version_check_ignore_list', {
                    bookId: bookId
                })).then(() => {
                    // do nothing
                });
            }
        }
    },
    mounted() {

        this.$watch(() => this.visible, (newValue) => {
            this.isVisible = !!newValue;
        }, {immediate: true});
    }
}
</script>
