<template ref="login">
    <v-container>
        <v-row class="login">
            <v-col
                order="2"
                order-sm="1"
                cols="12"
                sm="6"
                v-if="content">
                <div class="login-content" v-html="content"></div>
            </v-col>
            <v-col
                order="1"
                order-sm="2"
                cols="12"
                sm="6">
                <v-card>
                    <v-card-title>
                        <h2 class="primary--text title">Sign In</h2>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="errors.length > 0" class="error-message mb-4">
                            <span v-for="(error, key) in errors">
                                <br>
                                <v-chip
                                color="red"
                                text-color="white"
                                small>
                                {{ error }}
                            </v-chip>
                            </span>
                        </div>
                        <div
                            v-if="showResendMessage"
                            class="error-message mb-4 red--text"
                            small>
                            Your email account is not verified.
                            <v-btn
                                dark
                                :small="isDense"
                                color="accent2"
                                @click="resendEmail()"
                                class="light">
                                Resend Email
                            </v-btn>
                        </div>
                        <v-progress-circular
                            v-if="textLoading"
                            class="mb-3 ml-3"
                            :size="20"
                            indeterminate
                            color="accent2">
                        </v-progress-circular>
                        <div
                            v-if="emailResponse"
                            class="error-message mb-4 green--text font-weight-bold"
                            small>
                            {{emailResponse}}
                        </div>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            id="login_form"
                            method="post"
                            :action=formaction
                            @submit.prevent="loginSubmit"
                            :class="{ 'mx-4': $vuetify.breakpoint.xsOnly }">
                            <input v-if="referer != ''" type="hidden" name="_target_path" :value="referer">
                            <v-row dense>
                                <v-col>
                                    <v-text-field
                                        solo
                                        hide-details
                                        id="emailAddress"
                                        name="email"
                                        maxlength="160"
                                        v-model="email_address"
                                        :class="{ 'input-group--dirty': dirtyEmail }"
                                        placeholder="Email address"
                                        class="border"
                                        v-on:keyup="keyMonitor"
                                        required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <v-text-field
                                        solo
                                        hide-details
                                        id="password"
                                        name="password"
                                        maxlength="50"
                                        type="password"
                                        v-model="password"
                                        :class="{ 'input-group--dirty': dirtyPwd }"
                                        placeholder="Password"
                                        class="mt-4 border"
                                        v-on:keyup="keyMonitor"
                                        required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <input class="mr-1" id="rememberMe" type="checkbox" name="rememberMe">
                                    <label for="rememberMe">Keep me logged in for 30 days</label>
                                </v-col>
                            </v-row>
                            <input type="hidden" v-model="csrfToken" name="csrfToken">
                        </v-form>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                    large
                                    type="submit"
                                    color="primary"
                                    class="ma-1"
                                    @click="loginSubmit">
                                    Login
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="text-center">
                                <p class="mb-0">
                                    <a class="text-decoration-none" href="https://www.iccsafe.org/myicc/?change-password=true" target="_blank">Forgot Your Password?</a>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: ['formaction', 'referer', 'content'],
        data: () => ({
            textLoading: false,
            showResendMessage: false,
            emailResponse: '',
            valid: true,
            dirtyEmail: null,
            dirtyPwd: null,
            email_address: '',
            password: '',
            errors:[],
            csrfToken: document.getElementById('app').attributes['data-csrf-token'].value
        }),
        methods: {
            loginSubmit() {
                this.errors = [];
                if (!this.email_address) {
                    this.errors.push('Email Required');
                    document.getElementById("emailAddress").parentNode.classList.add('validate');
                } else if (!this.validEmail(this.email_address)) {
                    this.errors.push('Not a valid Email Address');
                    document.getElementById("emailAddress").parentNode.classList.add('validate');
                } else {
                    document.getElementById("emailAddress").parentNode.classList.remove('validate');
                }

                if (!this.password) {
                    this.errors.push('Password Field Required');
                    document.getElementById("password").parentNode.classList.add('validate');
                } else {
                    document.getElementById("password").parentNode.classList.remove('validate');
                }

                if(this.errors.length === 0) {
                    this.checkUserEmailVerification();
                }
            },
            validEmail:function(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            keyMonitor(event) {
                if (event.key == "Enter") {
                    this.loginSubmit();
                }
            },
            resendEmail() {
                this.showResendMessage = false;
                this.textLoading = true;
                this.$http.get(Routing.generate('resend_activation_email', {email: this.email_address}))
                .then(response => {
                    this.emailResponse = response.data.emailSent ? 'A verification link has been sent to your email account' : 'Invalid Email';
                    this.textLoading = false;
                });
            },
            checkUserEmailVerification() {
                this.$http.get(Routing.generate('is_user_verified', {email: this.email_address}))
                .then(response => {
                    if (response.data.status == '2') {
                        this.showResendMessage = true;
                    } else {
                        document.getElementById("login_form").submit();
                    }
                });
            },
        },

        mounted() {
            let times = 0;
            const interval = setInterval(() => {
                times += 1;
                if ((this.dirtyEmail && this.dirtyPwd) || times === 20) {
                    clearInterval(interval);
                }
                this.dirtyEmail = document.querySelector('input[type="email_address"]:-webkit-autofill');
                this.dirtyPwd = document.querySelector('input[type="password"]:-webkit-autofill');
            }, 100);
        }
    }
</script>
<style>
    .login-content img {
        max-width: 100%;
    }
    .text-pointer {
        cursor: pointer;
    }
</style>
