<template>
    <v-dialog
            v-model="visible"
            :max-width="width"
            :fullscreen="fullscreen"
            :persistent="!canBeClosed"
            @click:outside="clickedOutside"
    >
        <v-card
            max-height="90vh"
            class="flex-card"
            :min-height="minHeight"
            :style="borderStyle ? `border-top: 4px solid ${$vuetify.theme.themes.light.accent2}` : ''">

            <v-card-title
                class="text-h5 flex-nowrap pa-3"
                :class="!borderStyle ? 'primary white--text' : ''">

                <slot name="title"></slot>
                <div v-if="!hasTitleSlot"> {{ this.title ? this.title : "Confirm" }}</div>

                <v-spacer></v-spacer>

                <v-btn
                        v-show="canBeClosed"
                        icon
                        :color="borderStyle ? 'accent' : 'white'"
                        @click="close"
                        >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

            </v-card-title>
            <v-divider v-if="showHeaderBorder"></v-divider>
            <div v-if="isLoading" class="d-flex flex-column pa-8 align-center">

                <p class="mb-2">Loading Content</p>

                <v-progress-linear
                        :active="true"
                        color="primary"
                        indeterminate
                ></v-progress-linear>
            </div>

            <template v-else>

                <v-card-text :class="bodyClass ?? 'pt-5'">
                    <slot></slot>
                </v-card-text>

                <v-divider v-if="showBottomBar"></v-divider>

                <v-card-actions v-show="showBottomBar" :class="{'grey lighten-4' : !noGrayActionArea}">

                    <slot name="buttons"></slot>
                    <template v-if="!hasButtonsSlot">

                        <v-spacer></v-spacer>

                        <v-btn class="primary white--text" @click="clickedOkay">
                            {{ this.okText ? this.okText : 'OK' }}
                        </v-btn>

                        <v-btn class="" @click="clickedCancel">
                            {{ this.cancelText ? this.cancelText : 'Cancel' }}
                        </v-btn>

                    </template>

                </v-card-actions>
            </template>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false
        },
        okText: {
            type: String,
            required: false
        },
        cancelText: {
            type: String,
            required: false
        },
        buttons: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: 500
        },
        bodyClass: {
            type: String,
            required: false,
            default: ""
        },
        fullscreen: {
            type: Boolean,
            required: false,
            default: false
        },
        minHeight: {
            type: String,
            required: false
        },
        canBeClosed: {
            type: Boolean,
            required: false,
            default: true
        },
        borderStyle: {
            type: Boolean,
            required: false,
            default: false
        },
        showHeaderBorder: {
            type: Boolean,
            required: false,
            default: false
        },
        noGrayActionArea: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            visible: false
        }
    },
    computed: {
        hasTitleSlot() {
            return !!this.$slots['title'];
        },
        hasButtonsSlot() {
            return !!this.$slots['buttons'];
        },
        showBottomBar() {
            return this.hasButtonsSlot || this.buttons;
        }
    },
    methods: {
        clickedOkay() {
            this.$emit('ok');
            this.$emit('closed');
        },
        clickedCancel() {
            this.$emit('cancel');
            this.$emit('closed');
        },
        close() {
            this.$emit('closed');
        },
        clickedOutside() {
            if (this.canBeClosed) {
                this.$emit('closed');
            }
        }
    },
    mounted() {

        this.$watch(() => this.isOpen, (newValue) => {
            this.visible = !!newValue;
        }, {
            immediate: true
        });
    }
}
</script>

<style lang="scss">
.flex-card {
  display: flex;
  flex-direction: column;
}

.flex-card > .v-card__text {
  overflow-y: auto;
}

.flex-card .v-toolbar {
  flex: 0;
}
</style>
