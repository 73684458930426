var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "favoriteIcon" },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    "max-width": "300",
                    transition: "fade-transition",
                    right: "",
                    "z-index": "200",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticClass: "favorite primary--text",
                                attrs: {
                                  role: "button",
                                  "aria-label": _vm.is_favorite
                                    ? "Remove from Favorite"
                                    : "Add to Favorite",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setFavorite()
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.is_favorite
                                      ? "favorite"
                                      : "favorite_border"
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tooltipOpen,
                    callback: function ($$v) {
                      _vm.tooltipOpen = $$v
                    },
                    expression: "tooltipOpen",
                  },
                },
                [
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "tooltip-content",
                      on: {
                        mouseenter: _vm.showTooltip,
                        mouseleave: _vm.hideTooltip,
                      },
                    },
                    [
                      _vm.is_favorite
                        ? [
                            _vm._v(
                              "\n                        Remove from Favorites\n                    "
                            ),
                          ]
                        : [
                            _vm._v(
                              "\n                        Add to Favorites\n                    "
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showMessage
            ? _c("v-col", [
                _c("p", { staticClass: "ml-2 mb-0" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.is_favorite
                          ? "Remove from Favorites"
                          : "Add to Favorites"
                      ) +
                      "\n            "
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.subscription_prompt,
            callback: function ($$v) {
              _vm.subscription_prompt = $$v
            },
            expression: "subscription_prompt",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v(
                    "\n                Account Required for Favorites\n                "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: { click: _vm.dialogClose },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "my-4 primary--text caption",
                          attrs: { xs12: "", "text-center": "" },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              "Flagging codes and standards titles as favorites allows you quick access\n                            to the books you use the most. In order to begin building your list\n                            of favorites, you will need an ICC Account. Once flagged, your favorite\n                            titles will become available in your green sidebar."
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              "Please\n                            click below to Create an ICC account or sign in with your existing\n                            account to flag this title as a favorite."
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                href: this.getRoute("signup"),
                                color: "primary",
                              },
                            },
                            [
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v("Create an Account"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", href: _vm.login_url },
                            },
                            [
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v("Sign In"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }