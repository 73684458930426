import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ExpirableStorage} from "../classes/ExpirableStorage";
import {UrlUtil} from "../util/UrlUtil";

const requestCancelTokenSourceMap = {};

const cacheQueryParamsIgnore = ['query'];

const CACHE_ENABLED = true;

/**
 *
 * @param anything
 * @return {AxiosRequestConfig}
 */
const toRequestConfig = function (anything) {
    return (typeof anything === 'object') ? anything : {};
}

export class SuperAxios {

    /**
     * Treat as same:
     * /search?query=dragons&limit=10
     * /search?query=wizards&limit=10
     *
     * @param {string} url
     * @return {string}
     */
    static getUrlCacheKey(url) {

        const urlObject = UrlUtil.toUrl(url);

        if (!urlObject) {
            return url;
        }

        cacheQueryParamsIgnore.forEach((val) => {
            urlObject.searchParams.delete(val);
        });

        return urlObject.toString();
    }

    /**
     *
     * @param {string} url
     * @param {AxiosRequestConfig} config
     * @return {Promise<AxiosResponse<any>>}
     */
    static requestOnce(url, config = {}) {

        const cacheKey = this.getUrlCacheKey(url);

        // already such request in progress?? Cancel it
        if (cacheKey in requestCancelTokenSourceMap) {
            requestCancelTokenSourceMap[cacheKey].cancel();
            delete requestCancelTokenSourceMap[cacheKey];
        }

        config = toRequestConfig(config);
        config.url = url;

        const source = axios.CancelToken.source();
        config.cancelToken = source.token;

        requestCancelTokenSourceMap[cacheKey] = source;

        return axios.request(config);
    }

    /**
     *
     * @param {string} url
     * @param {AxiosRequestConfig} config
     * @return {Promise<AxiosResponse<any>>}
     */
    static getOnce(url, config = {}) {

        config = toRequestConfig(config);
        config.method = 'GET';

        return this.requestOnce(url, config);
    }

    /**
     *
     * @param {string} url
     * @param {Object} data
     * @param {AxiosRequestConfig} config
     * @return {Promise<AxiosResponse<any>>}
     */
    static postOnce(url, data = {}, config = {}) {

        config = toRequestConfig(config);
        config.method = 'POST';
        config.data = data;

        return this.requestOnce(url, config);
    }

    /**
     *
     * @param {string} url
     * @param {AxiosRequestConfig} config
     * @param {number} expiresIn
     * @return {Promise<any>}
     */
    static async getCached(url, config = {}, expiresIn = 3600) {

        const cacheKey = `SuperAxios:cache:${url}`;

        const cached = ExpirableStorage.get(cacheKey);

        if (cached && CACHE_ENABLED) {
            return cached;
        }

        config = (typeof config === 'object') ? config : {};

        const response = await axios.get(url, config);

        if (response.status === 200 && CACHE_ENABLED) {
            ExpirableStorage.set(cacheKey, response.data, expiresIn);
        }

        return response.data;
    }
}
