<template>
    <v-container class="pa-0">
        <v-row
            v-if="!isMobileAndTablet"
            align="center">
            <v-col>
                <h4>
                    Content Filters
                </h4>
            </v-col>
        </v-row>
        <v-row
            align="start"
            justify="center"
            no-gutters>
            <v-col cols="6" class="pr-2">
                <v-btn
                    :disabled="!hasPremiumSubscription"
                    small
                    :dark="hasPremiumSubscription"
                    block
                    outlined
                    depressed
                    color="primary"
                    @click="openSaveSearchModal()">
                    Save Search
                </v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
                <v-btn
                    :disabled="!hasPremiumSubscription"
                    small
                    block
                    :dark="hasPremiumSubscription"
                    outlined
                    depressed
                    color="black"
                    @click="clearSearch()">
                    Reset Filters
                </v-btn>
            </v-col>
        </v-row>
        <filter-expander
            class="mt-3"
            title="Advanced Term Search"
            component-name="AdvancedSearch">
        </filter-expander>
        <filter-expander
            class="mt-3"
            title="My Premium Titles"
            component-name="TitlesPanel">
        </filter-expander>
        <filter-expander
            class="mt-3"
            title="Categories"
            component-name="CategoriesPanel">
        </filter-expander>
        <filter-expander
            class="mt-3"
            title="Years"
            component-name="YearsPanel">
        </filter-expander>
        <filter-expander
            class="mt-3"
            title="Content Type"
            component-name="ContentTypePanel">
        </filter-expander>
        <saved-searches class="mt-3">
        </saved-searches>
        <add-search>
        </add-search>
    </v-container>
</template>

<script>
    import SavedSearches from './SavedSearch.vue';
    import AddSearch from '../../dialogs/add_search.vue';
    import Mobile from '../../../inc/mixins/mobile';
    import FilterExpander from "./FilterExpander.vue";

    export default {
        components: {
            FilterExpander,
            SavedSearches,
            AddSearch
        },
        mixins: [
            Mobile
        ],
        methods: {
            openSaveSearchModal() {
                EventBus.fire('open-add-search');
            },
            clearSearch() {
                EventBus.fire('reset-content-type-filter');
                EventBus.fire('reset-year-filter');
                EventBus.fire('reset-category-filter');
                EventBus.fire('clear-advanced-search')
                EventBus.fire('reset-content-search-terms');
                EventBus.fire('reset-title-filter');
                history.pushState({}, null, this.getRoute('sections_search_page'))
            }
        }
    }
</script>
