var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "confirm-dialog",
    {
      attrs: {
        "can-be-closed": _vm.canBeClosed,
        width: 800,
        "is-open": _vm.dialogVisible,
      },
      on: { closed: _vm.emitClosed },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("asset-image", {
                    staticClass: "mr-2",
                    attrs: {
                      src: require("../../images/ai_navigator/ai_navigator_icon_white.svg"),
                      width: "40",
                    },
                  }),
                  _vm._v("\n            ICC AI Navigator\n        "),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "buttons",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-full d-flex justify-center" },
                [
                  _vm.canBeClosed
                    ? [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            on: { click: _vm.clickedClose },
                          },
                          [
                            _vm._v(
                              "\n                    Close\n                "
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            on: { click: _vm.clickedBack },
                          },
                          [
                            _vm._v(
                              "\n                    Back\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary white--text",
                            attrs: { loading: _vm.isLoading },
                            on: { click: _vm.clickedAcknowledge },
                          },
                          [
                            _vm._v(
                              "\n                    Acknowledge\n                "
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "mt-5" }, [_c("ai-navigator-disclaimer")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }