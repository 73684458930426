<template class="pb-5">
    <div>
        <template v-if="documentInfo && this.isBundledPremiumOnlyBook(documentInfo)">
            <v-container class="pb-0 pt-5">
                <access-level-message
                    :access-level="documentInfo.content_access_level.id">
                </access-level-message>
            </v-container>
        </template>
        <template v-else>
            <v-container class="py-0">
                <v-row class="ml-0">
                    <p class="mb-0">Choose a subscription that suits you</p>
                    <p class="caption">* Member pricing applied automatically when you sign in.</p>
                </v-row>
                <v-radio-group
                    v-if="priceSet"
                    class="pt-0 mt-0"
                    v-model="duration"
                    dense
                    hide-details>
                    <v-row
                        v-for="(item, key) in durations"
                        :key="key"
                        align="center"
                        :class="{'yellow lighten-5': item.id === duration}"
                        @click="changeDuration(item.id)">
                        <v-col
                            cols="auto"
                            class="pr-0">
                            <v-radio
                                color="accent2"
                                class="mr-0"
                                :value="item.id">
                            </v-radio>
                        </v-col>
                        <v-col>
                            <p class="mb-0">
                                {{item.text}}
                            </p>
                            <template v-if="item.id !== 'M' && monthPrice">
                                <p class="caption text-decoration-line-through font-weight-bold mb-0">
                                    (${{ (getPricePerItem(product, isMember, 'M') * product.quantity).toFixed(2) }}/Month)
                                </p>
                                <p class="caption primary--text font-weight-bold mb-0">
                                    (${{ (getProductTotalPrice(product, isMember, item.id) / (item.id === 'C' ? 36 : 12)).toFixed(2) }}/Month)
                                </p>
                            </template>
                        </v-col>
                        <v-col
                            cols="auto"
                            class="text-right">
                            <template>
                                <p class="mb-0">
                                    ${{ getProductTotalPrice(product, isMember, item.id) }}
                                </p>
                                <p
                                    v-if="item.id !== 'M' && monthPrice"
                                    class="caption font-weight-bold mb-0 primary--text">
                                    {{ (getPurchaseSavings(product, isMember, item.id)) }}% savings
                                </p>
                            </template>
                        </v-col>
                    </v-row>
                </v-radio-group>
                <v-card
                    v-else
                    flat
                    :height="220">
                    <v-card-text class="fill-height">
                        <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-col class="text-center">
                                <v-progress-circular
                                    v-if="priceLoading && isSinglePurchasableBook"
                                    indeterminate
                                    color="primary">
                                </v-progress-circular>
                                <p v-else-if="isPriceNotAvailable || !isSinglePurchasableBook" class="mb-0">
                                    <b>This title is not available as Premium Singles offering.
                                    Please review available Premium Collections below within which this title is included.</b>
                                </p>
                                <p v-else class="mb-0">
                                    <b>The pricing information is not available for this title. Please contact ICC customer support.</b>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
            <v-container class="grey lighten-4 pt-3" v-if="!isPriceNotAvailable && isSinglePurchasableBook">
                <v-row dense>
                    <v-col
                        class="pt-3"
                        style="cursor: pointer"
                        @click="decrementQuantity"
                        @keydown.enter="decrementQuantity"
                        @keydown.space="decrementQuantity"
                        tabindex="0"
                        role="button"
                        aria-label="Decrement quantity"
                        cols="auto">
                        <v-icon
                            class="justify-center"
                            color="accent2">
                                mdi-minus-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            class="centered-input"
                            dense
                            hide-details
                            v-model="product.quantity"
                            type="tel"
                            :min="1"
                            solo>
                        </v-text-field>
                    </v-col>
                    <v-col
                        class="pt-3"
                        style="cursor: pointer"
                        @click="incrementQuantity"
                        @keydown.enter="incrementQuantity"
                        @keydown.space="incrementQuantity"
                        tabindex="0"
                        role="button"
                        aria-label="Increment quantity"
                        cols="auto">
                        <v-icon
                            class="justify-center"
                            color="accent2">
                                mdi-plus-circle-outline</v-icon>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn
                            :id="product.document_info.document_id"
                            dark
                            color="accent2"
                            class="white--text"
                            :loading="addToCartLoading"
                            :disabled="!priceSet"
                            @click="addItem">
                                <v-icon left small>shopping_cart</v-icon> Add to cart
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-3" v-if="product.quantity > 1">
                    <v-col>
                        <p class="caption primary--text mt-3 mb-0">
                            <v-icon left small class="primary--text">mdi-brightness-percent</v-icon>
                            Congrats! You've unlocked additional volume discounts.
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <template>
                            <v-menu
                                allow-overflow
                                :close-on-content-click="false"
                                offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        block
                                        rounded
                                        dense
                                        outlined
                                        class="grey--text volume-button"
                                        v-on="on">
                                        <span>
                                            <v-icon small>
                                                info
                                            </v-icon> Additional Volume Savings
                                        </span>
                                        <v-icon right>
                                            {{menu ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text class="pb-0">
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Volume
                                                    </th>
                                                    <th
                                                        v-if="documentInfo
                                                            && documentInfo.content_type
                                                            && documentInfo.content_type.name === 'Premium Complete'"
                                                        class="text-left">
                                                        Discounts (Monthly and Annual)
                                                    </th>
                                                    <th
                                                        v-else
                                                        class="text-left">
                                                        Discounts
                                                    </th>
                                                    <th
                                                        v-if="documentInfo
                                                            && documentInfo.content_type
                                                            && documentInfo.content_type.name === 'Premium Complete'"
                                                        class="text-left">
                                                        Discounts (3-Year)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="item in discounts"
                                                :key="item.volume"
                                                >
                                                    <td>{{ item.volume }}</td>
                                                    <td>{{ item.discount }}</td>
                                                    <td
                                                        v-if="documentInfo
                                                            && documentInfo.content_type
                                                            && documentInfo.content_type.name === 'Premium Complete'">
                                                            {{ item.discount3Years }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <p class="caption mb-0">Have a discount code? You can enter it during checkout.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="caption mb-0">
                            For orders without a credit card or for Enterprise and Custom Solutions, contact our sales team  <a :href="`mailto:${this.$getConst('sales_email_address')}`">({{ this.$getConst('sales_email_address') }})</a>
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <v-container class="pt-3" v-if="isSingle">
            <book-collections :document-info="documentInfo"></book-collections>
        </v-container>

    </div>
</template>
<script>
    import Mobile from '../../inc/mixins/mobile';
    import Prices from '../../inc/mixins/pricesHelper';
    import CategoryConfig from '../mixins/categoryesWithPdf';
    import Checkout from '../partials/checkout_modals';
    import CartItem from './cartItemNew';
    import CheckoutConfirmation from '../partials/checkoutConfirmModal';
    import BookCollections from "./bookCollections";
    import AccessLevelMessage from "./AccessLevelMessage";

    export default {
        data() {
            return {
                duration: 'B',
                durations: [
                    {
                        id: 'M',
                        text: 'Billed Monthly'
                    },
                    {
                        id: 'B',
                        text: 'Billed Annually'
                    },
                    {
                        id: 'C',
                        text: 'Billed Every 3 Years'
                    }
                ],
                product: {
                    price: 0,
                    quantity: 1,
                    subscription_type: 'B',
                    document_info: {},
                },
                menu: false,
                discounts: [
                    {volume:'1', discount:'-', discount3Years:'-'},
                    {volume:'2', discount:'15%', discount3Years:'5%'},
                    {volume:'3-4', discount:'20%', discount3Years:'10%'},
                    {volume:'5-9', discount:'30%', discount3Years:'15%'},
                    {volume:'10-19', discount:'35%', discount3Years:'25%'},
                    {volume:'20+', discount:'40%', discount3Years:'30%'}
                ],
                addToCartLoading: false,
                priceLoading: true,
                priceError: false,
                priceNotFound: false,
                monthPrice: true,
                showTrialButton: true
            }
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            isSingle: {
                required: false,
                type: Boolean
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
            memberText() {
                if (this.isMember) {
                    return '<span class="accent2--text">*</span> Member pricing is being applied';
                } else if (!this.isLoggedIn) {
                    return '<span class="error--text">*</span> Sign in to see member pricing';
                }
            },
            priceSet() {
                if (_.size(this.prices) > 0) {
                    if (!this.prices.general) {
                        if (this.documentInfo.is_collection || _.size(this.documentInfo.collections) === 0) {
                            this.priceError = true;
                        } else {
                            this.priceNotFound = true;
                        }
                    } else {
                        if (_.isUndefined(this.prices.general[this.documentInfo.sku]['M'])) {
                            this.monthPrice = false;
                            this.durations.shift();
                        }
                        return true;
                    }
                }
            },
            isPriceNotAvailable() {
                return _.size(this.documentInfo.collections) > 0 && this.priceNotFound && !this.priceError;
            },
            isSinglePurchasableBook(){
                const accessLevelId =  this.documentInfo.content_access_level?.id;
                return !_.includes(this.$getConst('withCollectionLevels'), accessLevelId);
            }
        },
        components: {
            BookCollections,
            Checkout,
            CartItem,
            CheckoutConfirmation,
            AccessLevelMessage
        },
        mixins: [Prices, Mobile, CategoryConfig],
        methods: {
            changeDuration(id) {
                this.duration = id;
                this.product.subscription_type = id;
                this.product.quantity = _.toInteger(this.product.quantity);
            },
            goToCheckout() {
                this.checkoutModal = false;
                EventBus.fire('go_to_checkout');
            },
            incrementQuantity() {
                this.product.quantity = _.toInteger(this.product.quantity) + 1;
            },
            decrementQuantity() {
                if (this.product.quantity > 1) {
                    this.product.quantity = _.toInteger(this.product.quantity) - 1;
                }
            },
            addItem() {
                if (this.$store.getters.getLoggedIn !== '') {
                    this.addToCartLoading = true;
                    let data = {
                        'quantity': this.product.quantity,
                        'sku': this.product.document_info.sku,
                        'subscription_type': this.product.subscription_type
                    };
                    this.$http.get(Routing.generate('create_cart_product', {product: data}))
                        .then(response => {
                            EventBus.fire('set-cart-items-count', {
                                type: 'increment',
                                qty: data.quantity
                            });
                            EventBus.fire(
                                'show-checkout-modal',
                                {
                                    product: this.product,
                                    totalPrice: this.getProductTotalPrice(this.product, this.isMember, this.product.subscription_type)
                                }
                            );
                            this.addToCartLoading = false;
                        }).catch(error => {
                        this.error = true;
                        this.addToCartLoading = false;
                    });
                } else {
                    this.$session.set('cartItem', {
                        'quantity': this.product.quantity,
                        'sku': this.product.document_info.sku,
                        'subscription_type': this.product.subscription_type
                    });
                    EventBus.fire('openLoginModal', {
                        message: 'Login to add this item to your cart.',
                        fromCart: true
                    });
                }
            },
            setProductPrices() {
                this.prices = [];
                this.$http.get(Routing.generate('get_product_price', {documentSku: this.documentInfo.sku}))
                    .then(response => {
                        this.prices = response.data;
                        if (!this.prices.general) {
                            if (this.documentInfo.is_collection || _.size(this.documentInfo.collections) === 0) {
                                this.priceError = true;
                            } else {
                                this.priceNotFound = true;
                            }
                        } else if(this.documentInfo.is_collection) {
                            EventBus.fire('is-member-benefit-collection', response.data.is_saleable);
                        }
                        this.isMember = response.data.is_member;
                        EventBus.fire('updateCartMember', this.isMember);

                        this.priceLoading = false;
                        this.showTrialButton = !this.trialStarted
                            && this.$session.get('subscriptionType') !== 'complete' ? true : false;
                    })
                    .catch(error => {
                        this.priceLoading = false;
                        this.priceError = true;
                    })
            },
        },
        mounted(){
            if (this.documentInfo && this.isSinglePurchasableBook) {
                this.product.document_info = this.documentInfo;
                this.setProductPrices();
            }
        }
    }

</script>
<style scoped>
.centered-input >>> input {
    text-align: center
}
.volume-button {
    background-color: white;
    border: thin solid #ffa201;
}
table th {
    border-top:1px solid #dddddd;
    border-left:1px solid #dddddd;
    border-right:1px solid #dddddd;
    background-color: #fafafa;
}
table td {
    border-left:1px solid #dddddd;
    border-right:1px solid #dddddd;
}
table tr {
    border-right:1px solid #dddddd;
    border-left:1px solid #dddddd;
    border-bottom:1px solid #dddddd;
}
button.v-btn[disabled] {
    color: inherit!important;
    background-color: inherit!important;
}
</style>
