<template>
    <v-container>
        <v-row
            align="center"
            justify="center">
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All Project Tags"
                    v-model="tag"
                    :items="tag_list"
                    item-value="id"
                    item-text="label"
                    v-on:change="filterResult($event,'tag')"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All Categories"
                    v-model="category"
                    :items="category_list"
                    v-on:change="filterResult($event,'category')"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="3">
                <v-select
                    hide-details
                    label="All My Library Titles"
                    v-model="book"
                    :items="book_list"
                    v-on:change="filterResult($event,'book')"
                    item-text="book"
                    solo>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="auto">
                <v-btn
                    color="primary"
                    large
                    class="ma-0"
                    @click="resetFilter"
                    block
                    title="Reset All Filters">
                    Reset
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels accordion>
                    <v-data-iterator
                        class="sharing-data-iterator"
                        :items="filteredResults"
                        :items-per-page.sync="pagination.rowsPerPage"
                        expand>
                        <template v-slot:item="data">
                            <v-expansion-panel>
                                <template v-slot:actions>
                                    <v-icon @click="checkPanelEvent(data)" color="primary">expand_more</v-icon>
                                </template>
                                <v-expansion-panel-header>
                                    <v-row
                                        align="center"
                                        :dense="isMobilePort">
                                        <v-col cols="auto">
                                            <a class="notes_book_link" @click="bookLink(data.item.info)">
                                                <graphic
                                                    :title="data.item.info"
                                                    thumbnail
                                                    width="70px"
                                                    additionalStyles="max-width: 70px;"
                                                    >
                                                </graphic>
                                            </a>
                                        </v-col>
                                        <v-col>
                                            <a class="notes_book_link" @click="bookLink(data.item.info)">
                                                <book-title
                                                    class="data-notes-titles ml-2"
                                                    :title="getBookTitle(data.item.info)"
                                                    :print_version_edition="printEdition(data.item.info.print_version_edition.version)"
                                                    :printing_text="data.item.info.print_info"
                                                    :print_version="data.item.info.print_version">
                                                </book-title>
                                            </a>
                                        </v-col>
                                        <v-col cols="auto">
                                            <p class="v-label theme--light mb-0">{{countNotesInChapter(data.item.chapter)}} Notes</p>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content
                                    color="grey lighten-4"
                                    class="sharing-expand">
                                    <v-row
                                        class="ma-0"
                                        v-for="(chapters, chapterId) in data.item.chapter"
                                        :key="chapterId"
                                        :dense="isMobilePort">
                                        <v-col>
                                            <div
                                                v-for="(sections, sectionId) in chapters"
                                                :key="sectionId">
                                                <v-card
                                                    outline
                                                    class="my-2"
                                                    v-for="(result, noteId) in sections"
                                                    :key="noteId"
                                                    v-if="result instanceof Object">
                                                    <v-card-title>
                                                        <h3
                                                            style="cursor: pointer"
                                                            class="mt-1 ml-1"
                                                            @click="redirectToSection(data.item.info.document_id, chapterId, sectionId)"
                                                            :class="{
                                                                'title': !isMobilePort,
                                                                'body-2': isMobilePort
                                                            }">
                                                            {{result.section}}
                                                        </h3>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col>
                                                                <p
                                                                    v-if="result.type == 'note' || result.type == 'annotation'"
                                                                    class="note-list-quote font-italic pa-1 mb-0 mt-1">
                                                                    {{result.content }}
                                                                </p>
                                                                <p
                                                                    v-if="getDescription(result)"
                                                                    class="mt-2 ml-1"
                                                                    v-html="getDescription(result)">
                                                                </p>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row align="center">
                                                            <v-col cols="auto">
                                                                <v-btn depressed fab small>
                                                                    <v-icon :color="getTagColor(result.tagId)">
                                                                        {{getIcon(result.type) }}
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col>
                                                                <h5>Created By: {{ result.creatorName }}</h5>
                                                                <h5>Project Tag: {{getTagName(result.tagId) }}</h5>
                                                                <h5>Timestamp: {{result.created_at | moment("MMMM Do, YYYY")}}</h5>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <template slot="no-data">
                            <v-row>
                                <v-col class="text-center">
                                    <template v-if="filteredResults.length == 0 && load">
                                        <p>No Results Found</p>
                                    </template>
                                    <template v-else>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary">
                                        </v-progress-circular>
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BackToTop from '../../../partials/buttons/back_to_top';
    import ShareMixins from './../mixins/sharing';
    import BookTitle from "./../../licenses/parts/content_title";
    import Moment from 'moment';
    import Mobile from '../../../inc/mixins/mobile';
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [ShareMixins, Mobile],

        components: {
            BackToTop,
            BookTitle,
            Moment,
            Graphic
        },
        created() {
            this.page = 'SharedHistory';
            this.setRoute();
            window.addEventListener('scroll', this.handleScroll, {passive: true});
        }
    }
</script>
