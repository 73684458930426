export default {
    data(){
      return {
          selectedTag: [],
          loadingTags: true,
          tags: [],
      }
    },
    methods: {
        createNewTagModal(isUpdate, selectedTag){
            EventBus.fire('create-new-tag', {
                'isUpdate': isUpdate,
                'tag': selectedTag
            });
        },

        getUserTags(tagID) {
            this.$http.get(Routing.generate('get_my_tags'))
                .then(response => {
                    this.$store.commit('setTags', response.data);
                    this.tags = this.sortTagsByTitle(response.data);
                    let userDefaultTag = 1;

                    if(1 == tagID) {
                        let userDefaultTag = _.find(this.tags, {'tag': 'Default'});
                        this.selectedTag = userDefaultTag ? userDefaultTag.id : 1;
                    } else {
                        let oldTag = _.find(this.tags, {'id': _.toInteger(tagID)});
                        this.selectedTag = oldTag ? oldTag.id : 1;
                    }
                    this.loadingTags = false;
                });
        }
    },
    computed: {
        swatchStyle() {
            const { tag, menu } = this;
            return {
                backgroundColor: tag.color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        }
    },
    created(){
        EventBus.listen('update-note-tags', (id)=> this.getUserTags(id));
    }
}
