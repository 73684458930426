<template ref="password_reset">
    <v-container>
        <v-row class="password_reset">
            <v-col
                    order="2"
                    order-sm="1"
                    cols="12"
                    sm="6"
                    v-if="content">
                <div class="login-content" v-html="content"></div>
            </v-col>
            <v-col
                    order="1"
                    order-sm="2"
                    cols="12"
                    sm="6">
                <v-card>
                    <v-card-title>
                        <h2 class="primary--text title">Password Reset</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-card
                            flat
                            class="primary mb-5 text-left">
                            <v-card-text>
                                <v-row dense>
                                    <v-col>
                                        <p class="caption white--text                                                                                                                                                                                                                                                       --text mb-0">
                                            For security reasons, we need you to reset your password. We have sent an 
                                            email to your registered email address. Please check your inbox or spam 
                                            folder for further instructions.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div v-if="errors.length > 0" class="error-message mb-4">
                            <span v-for="(error, key) in errors">
                                <br>
                                <v-chip
                                        color="red"
                                        text-color="white"
                                        small>
                                {{ error }}
                            </v-chip>
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: ['content'],
        data: () => ({
            errors: [],
        })
    }
</script>
<style>
    .login-content img {
        max-width: 100%;
    }
</style>
