<template>
    <v-row
        align="center"
        dense>
        <v-col cols="auto">
                <template v-for="(item, index) in getBreadcrumbItems">
                    <a
                        :href="item.href"
                        :key="index"
                        class="caption font-weight-medium">
                        {{ item.text }}
                    </a>
                    <span class="caption font-weight-medium"> / </span>
                </template>
        </v-col>
        <v-col cols="auto">
            <v-menu
                v-if="documentInfo.display_title"
                offset-y
                min-width="500px"
                max-height="400px">
                <template v-slot:activator="{ on, attrs }">
                    <div
                        class="caption font-weight-medium"
                        style="margin-top: 2px;"
                        v-bind="attrs"
                        v-on="on"
                        @click="getTitles()">
                        {{ getBookTitle(documentInfo) }}
                        <v-icon
                            class="mt-n1 ml-n1"
                            style="margin-bottom: -1px;">
                            mdi-chevron-down
                        </v-icon>
                    </div>
                </template>
                <v-card>
                    <v-card-text v-if="loading">
                        <v-progress-linear
                            color="primary"
                            indeterminate>
                        </v-progress-linear>
                    </v-card-text>
                    <v-list
                        v-else
                        class="py-0">
                        <v-list-item
                            v-for="(item, index) in categoryTitles"
                            :key="index"
                            :href="item.link">
                            <v-list-item-content class="caption">
                                {{ getBookTitle(item) }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <a :href="getRoute('codes_category', {category: documentCategory.slug})">
                        <v-container
                            class="py-0">
                            <v-row class="grey lighten-4">
                                <v-col>
                                    <p class="mb-0 caption">
                                        {{ getCodesCategoryText }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </a>
                </v-card>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
    import {TitlesApi} from "../../../api/Titles";

    export default {
        name: 'TitlesBreadcrumb',
        data() {
            return {
                categoryTitles: [],
                loading: true
            }
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            }
        },
        computed: {
            documentCategory() {
                const categories = this.documentInfo.categorySlug;
                if (categories) {
                    const sessionCategory = this.$session.get('categoryBreadcrumb');
                    const sessionCategorySlug = this.$session.get('categoryBreadcrumbSlug');
                    if (categories.includes(sessionCategorySlug)) {
                        return {label: sessionCategory, slug: sessionCategorySlug};
                    } else {
                        const iCodesCategory = _.filter(this.documentInfo.categories, function(obj) {
                            return 'I-Codes' === obj.label;
                        });
                        if (iCodesCategory.length) {
                            return iCodesCategory[0];
                        } else {
                            return this.documentInfo.categories[0];
                        }
                    }
                }
            },
            getBreadcrumbItems() {
                if (this.documentCategory) {
                    return [
                        {text: 'Codes', href: Routing.generate('codes')},
                        {text: this.documentCategory.label, href: Routing.generate('codes_category', { 'category': this.documentCategory.slug })}
                    ];
                }
            },
            getCodesCategoryText() {
                if (this.documentCategory) {
                    let label = this.documentCategory.label;
                    return `View all titles in ${label} category`;
                } else {
                    return `View all titles in this category`;
                }
            }
        },
        methods: {
            async getTitles() {
                if (!this.categoryTitles.length) {
                    try {
                        const data = {
                            category: this.documentCategory.label,
                            year: this.documentInfo.year.year
                        }
                        const response = await TitlesApi.getTitlesByCategory(data);
                        this.categoryTitles = response.data.data
                    } catch (error) {
                    } finally {
                        this.loading = false;
                    }
                }
            }
        }
    }
</script>
