var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { id: "premiumAnswersFilter", outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("h2", { staticClass: "mb-5 text-h5 font-weight-medium" }, [
                _vm._v("Quick Consult"),
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "mb-4 rounded-lg",
                  style: `border: 2px solid ${_vm.$vuetify.theme.themes.light.primaryMedium}`,
                  attrs: { outlined: "" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-6 py-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("h6", { staticClass: "text-h6 mb-4" }, [
                                _vm._v(
                                  "\n                                Your Fast Track to Expert Code Opinions\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "text-body-2 mb-2" }, [
                                _vm._v(
                                  "\n                                Need answers to your code questions? Quick Consult offers expert opinions, personalized responses, and timely results.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.marketingText, function (item, key) {
                                return [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "primaryMedium" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            mdi-check-circle-outline\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-col", [
                                        _c("p", {
                                          key: key,
                                          staticClass: "text-body-2 mb-2",
                                          domProps: { innerHTML: _vm._s(item) },
                                        }),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "text-body-2" }, [
                                _vm._v(
                                  "\n                                Don't let code uncertainty slow you down. Start your consultation today!\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("sample-answer-button"),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("img", {
                              attrs: {
                                alt: "Quick Consult Marketing Image",
                                width: "231px",
                                height: "167px",
                                src: _vm.asset(
                                  require("../../../images/illistrations/qc_marketing_image.png")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                Ask code questions on any of the International Codes (I-Codes and Standards). Our ICC experts will provide a professional opinion of how the codes apply to your specific circumstances within 2 business days.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                This is a paid service and requires you to buy credits to ask questions regardless of your Premium subscription status.  For this beta launch we are offering introductory pricing.\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.isAuthenticated
                ? _c("p", [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.creditsAvailable) +
                          " credits\n                    "
                      ),
                      _vm._v(" "),
                      _vm.hasExpiryDate
                        ? _c("span")
                        : _c("span", [
                            _vm._v(" - buy credits to ask a question."),
                          ]),
                    ]),
                  ])
                : _c("p", [
                    _c("b", [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: { href: this.getRoute("security_login") },
                        },
                        [_vm._v("Sign in\n                    ")]
                      ),
                      _vm._v(" or\n                    "),
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-underline",
                          attrs: { href: this.getRoute("signup") },
                        },
                        [
                          _vm._v(
                            "\n                        create an account\n                    "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                    to buy credits to ask a question.\n                "
                      ),
                    ]),
                  ]),
              _vm._v(" "),
              _vm._l(_vm.successfulReturnedCredits, function (item, key) {
                return _c("alert", {
                  key: key,
                  attrs: {
                    notificationType: "info",
                    message:
                      "Question [" +
                      item.key +
                      "] has been declined by the ICC team and 1 credit has been refunded back to your account. Open the full question details to see why your question was declined. Thank you.",
                  },
                })
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.canAskQuestions,
                        color: "primary",
                        href: this.getRoute("ask_question"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Ask New Question\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mx-1" }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.isAuthenticated,
                        outlined: "",
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Purchase Credits\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialog
        ? _c("confirm-dialog", {
            attrs: {
              "is-open": _vm.dialog,
              borderStyle: "",
              showHeaderBorder: "",
              noGrayActionArea: "",
              fullscreen: _vm.isMobilePort,
              "body-class": "pa-4 pt-2",
            },
            on: {
              closed: function ($event) {
                return _vm.closeModal()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n            Buy Quick Consult Credits\n        "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _vm.step === 1
                        ? [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c("v-col", [
                                  _c("h4", { staticClass: "fs-16" }, [
                                    _vm._v(
                                      "\n                            Number of Credits\n                        "
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.creditOptions, function (item, key) {
                              return !_vm.loading.creditOptions
                                ? [
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "mt-0",
                                        attrs: { "hide-details": "" },
                                        model: {
                                          value: _vm.creditSelect,
                                          callback: function ($$v) {
                                            _vm.creditSelect = $$v
                                          },
                                          expression: "creditSelect",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            key: key,
                                            staticClass: "mb-2 pa-2",
                                            class: {
                                              primaryLight:
                                                item.qty === _vm.creditSelect,
                                            },
                                            style:
                                              item.qty === _vm.creditSelect
                                                ? `border: 2px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`
                                                : "",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.creditSelect = item.qty
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "py-1 px-2" },
                                              [
                                                _c(
                                                  "v-row",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value: item.qty,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-col", [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass: "fs-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.getCreditTitle(
                                                                  item.qty
                                                                )
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                        attrs: { cols: "auto" },
                                                      },
                                                      [
                                                        [
                                                          item.total
                                                            ? [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticClass:
                                                                      "fs-16",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    $" +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "formatPrice"
                                                                          )(
                                                                            item.total
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    item.savingsPercentage
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "(Save " +
                                                                                _vm._s(
                                                                                  item.savingsPercentage.toFixed(
                                                                                    0
                                                                                  )
                                                                                ) +
                                                                                "%)"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "fs-16",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                Price Unavailable\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e()
                            }),
                          ]
                        : [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c("v-col", [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                            Number of Credits\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.getCreditTitle(_vm.creditSelect)
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c("v-col", [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                            Credit Expiration Date\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.DateUtil.timeToMdy(
                                            _vm.getCreditsExpiry
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.hasExpiryDate
                                      ? _c("span", [
                                          _vm._v(
                                            "Existing credit expiration date will update to this date."
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [_c("v-col", [_c("payment-method-display")], 1)],
                              1
                            ),
                          ],
                      _vm._v(" "),
                      _c("html-block", {
                        class: { "mt-2": _vm.step === 1 },
                        attrs: { slug: "qc-terms-of-service" },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "v-container",
                        { staticClass: "py-0 px-2" },
                        [
                          _c("alert", {
                            attrs: {
                              id: "display_http_messages",
                              message: _vm.message,
                              notificationType: _vm.type,
                              dismissible: "",
                            },
                            on: { dismiss: _vm.dismissAlert },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", [
                                _c("h2", [
                                  _vm._v(
                                    "\n                            Total Payment\n                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                !_vm.loading.creditOptions
                                  ? _c("h2", [
                                      _vm._v(
                                        "\n                            $" +
                                          _vm._s(
                                            _vm._f("formatPrice")(
                                              _vm.creditOptions.find(
                                                (option) =>
                                                  option.qty ===
                                                  _vm.creditSelect
                                              ).total
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.invalidVisaCart
                            ? _c(
                                "v-row",
                                { attrs: { justify: "end", dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1 font-weight-bold",
                                          attrs: { outlined: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.closeModal()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Cancel\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "font-weight-bold",
                                          attrs: {
                                            color: "accent2",
                                            depressed: "",
                                            loading:
                                              _vm.loading.paymentProfile ||
                                              _vm.loading.proceedToCheckout,
                                            disabled:
                                              _vm.loading.paymentProfile,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.buyCredits(false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Proceed to Checkout\n                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("action-buttons", {
                                attrs: {
                                  step: _vm.step,
                                  submitText: _vm.getSubmitBtnText(),
                                  isDisableReviewBtn: false,
                                  "loading-submit": _vm.loading.checkout,
                                },
                                on: {
                                  "go-back": function ($event) {
                                    return _vm.backModal()
                                  },
                                  close: function ($event) {
                                    return _vm.closeModal()
                                  },
                                  "review-changes": function ($event) {
                                    return _vm.reviewModal()
                                  },
                                  "submit-and-pay": function ($event) {
                                    return _vm.buyCredits(true)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4254838613
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("checkout-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }