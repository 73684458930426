<template ref="activityReport">
    <div>
        <v-card
            flat
            outlined
            class="white rounded-xl">
            <v-card-title class="py-2">

                <div class="flex-grow-1">
                    <v-icon
                            left>
                        mdi-web
                    </v-icon>
                    <span class="text-h6 font-weight-bold">Activity Report</span>
                </div>

                <div class="d-flex flex-column flex-md-row justify-end">
                    <v-text-field
                        dense
                        clearable
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search here"
                        outlined
                        hide-details
                        class="ma-2 rounded-xl shrink">
                    </v-text-field>
                    <v-select
                        dense
                        outlined
                        hide-details
                        class="rounded-xl ma-2"
                        v-model="dateRange"
                        :items="dateRanges"
                        item-text="label"
                        item-value="value"
                        label="Select Time Frame"
                        @change="checkCustom"
                        return-object
                        style="max-width: 200px;">
                    </v-select>
                    <v-btn
                        :disabled="!activity.length"
                        class="primary--text rounded-xl ma-2"
                        outlined
                        @click="getEnterpriseActivity(true)">
                        <v-icon>
                            mdi-file-download-outline
                        </v-icon>
                        Export full PDF
                    </v-btn>
                </div>

            </v-card-title>
            <p class="pa-2 caption" v-if="dateRangeMessage">
                <span class="custom-dates">{{ dateRangeMessage }}</span>
            </p>
            <v-data-table
                v-if="activity.length"
                :headers="headers"
                :search="search"
                :items="activity"
                :items-per-page="5"
                :hide-default-footer="activity.length < 5">
            </v-data-table>
            <v-card-text v-else class="text-center subtitle-1 title pa-4">
                <template v-if="loading">Loading...</template>
                <template v-else>No data available</template>
            </v-card-text>
        </v-card>
        <activity-date-range>
        </activity-date-range>
    </div>
</template>

<script>
    import Moment from 'moment';
    import Mobile from '../../../inc/mixins/mobile';
    import ActivityDateRange from '../dialogs/activity_report_date_range';

    export default {
        props: ["profileId"],
        data: () => ({
            loading: false,
            startMenu: false,
            endMenu: false,
            startDate: new Date().toISOString().substr(0, 10),
            endDate: new Date().toISOString().substr(0, 10),
            enterpriseName: "",
            activity: [],
            headers: [
                {
                    text: 'Title',
                    align: 'left',
                    sortable: true,
                    value: 'bookTitle',
                },
                {
                    text: 'User Visited',
                    align: 'left',
                    sortable: true,
                    value: 'userCount'
                },
                {
                    text: 'Total Visits',
                    align: 'left',
                    sortable: true,
                    value: 'totalCount'
                },
                /** Need to un comment after multiple enterprise functionality in place */
                /*{
                    text: 'Times Printed',
                    align: 'center',
                    sortable: true,
                    value: 'printCount'
                },
                {
                    text: 'Times Shared',
                    align: 'center',
                    sortable: true,
                    value: 'shareCount'
                },
                {
                    text: 'Times Highlighted',
                    align: 'center',
                    sortable: true,
                    value: 'highlightCount'
                },
                {
                    text: 'Times Bookmarked',
                    align: 'center',
                    sortable: true,
                    value: 'bookmarkCount'
                }*/
            ],
            dates: [],
            search: '',
            dateRange: { label: '30 days', value: '30d' },
            dateRangeMessage: '',
            dateRanges: [
                { label: 'Custom', value: 'custom' },
                { label: '30 days', value: '30d' },
                { label: '60 days', value: '60d' },
                { label: '6 months', value: '6m' },
                { label: '1 year', value: '1y' },
                { label: '2 years', value: '2y' }
            ]
        }),
        mixins: [Mobile],
        components: {
            ActivityDateRange
        },
        methods: {
            getEnterpriseActivity(report = false) {
                // Need to fetch enterprise activity report
                this.loading = true;
                let enterpriseId = this.profileId ? this.profileId : this.$store.getters.enterpriseId;
                let data = {
                    enterpriseId: enterpriseId,
                    dateRange: this.dateRange.value,
                    startDate: this.startDate,
                    custom: _.toInteger(this.dateRangeMessage !== ''),
                    endDate: this.endDate,
                    report: report
                };
                if (!report) {
                    this.$http.post(Routing.generate('get_enterprise_activity'), data)
                        .then(response => {
                            if (response.data && response.data.code == 200) {
                                this.activity = response.data.activity;
                                this.enterpriseName = response.data.enterpriseName;
                                this.loading = false;
                                EventBus.fire('get-enterprise-details', {
                                    enterpriseName: this.enterpriseName
                                });
                            }
                        });
                } else {
                    this.exportPdf(Routing.generate('get_enterprise_activity', data), 'user_activity_log.pdf');
                }
            },
            checkCustom(selected) {
                if('custom' === selected.value) {
                    EventBus.fire('display-date-ragne-modal', this.dates);
                } else {
                    //this.dates = []; //Ref: to refresh the selected dates
                    this.dateRangeMessage = '';
                    this.getEnterpriseActivity();
                }
            },
            setDateRange(data) {
                this.dates = data;
                let date1 = new Date(data[0]);
                let date2 = new Date(data[1]);

                if(date1.getTime() < date2.getTime()) {
                    this.startDate = data[0];
                    this.endDate = data[1];
                } else {
                    this.startDate = data[1];
                    this.endDate = data[0];
                }
                this.dateRangeMessage = Moment(this.startDate).format('LL') + ' - ' + Moment(this.endDate).format('LL');
                this.getEnterpriseActivity();
            }
        },
        mounted() {
            this.getEnterpriseActivity();
        },
        created() {
            EventBus.listen('apply-date-ragne', (data) => this.setDateRange(data));
        }
    }
</script>
<style lang="scss" scoped>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
    .custom-dates {
        float: right;
        padding-right: 195px;
    }
</style>
