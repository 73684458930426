<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search titles"
                        outlined
                        hide-details
                        clearable>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-treeview
                    v-model="selectedTitles"
                    open-all
                    dense
                    selectable
                    :multiple="true"
                    return-object
                    :search="query"
                    item-key="id"
                    selected-color="accent2"
                    :items="titles">
                    <template v-slot:label="{ item }">
                        <p class="my-1 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-apply-button
                class="mt-2"
                :filter="{
                    key: 'titles',
                    value: selectedTitles
                }">
            </filter-apply-button>
        </div>
        <div v-show="!expand">
            <template v-if="activeTitles.length > 0">
                <v-card
                    flat
                    :max-height="activeTitles.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeTitles.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeTitles"
                        :key="key"
                        small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary"
                        style="height: auto;">
                        {{ item.name }}
                        <v-icon @click="removeTitle(item.id)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeTitles.length > 0"
                        @click="removeAll()">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all titles
            </p>
        </div>
    </div>
</template>

<script>
    import FilterApplyButton from "./FilterApplyButton.vue";
    import { SearchApi } from "../../../api/Search";
    import { EventNames } from "../../../classes/EventNames";
    import TabbedSearch from "../mixins/TabbedSearch";
    import Search from "../../inc/mixins/search"

    export default {
        components: {
            FilterApplyButton
        },
        data() {
            return {
                query: null,
                titles: [],
                selectedTitles: [],
            }
        },
        computed: {
            activeTitles() {
                const documentIds = this.filterTitleIds[this.searchTab] || [];
                const extractValidDocumentIds = (array) => array.filter(item => typeof item === 'number');
                const titleIds = extractValidDocumentIds(documentIds);
                const titles = this.titles.filter(title => titleIds.includes(title.id));
                if (titles) {
                    return titles;
                }

                return [];
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            TabbedSearch,
            Search
        ],
        methods: {
            getAllTitles() {
                SearchApi.getAllTitles().then(response => {
                    const titles = response.data.map(title => {
                        return {
                            id: title.documentInfoId,
                            name: `${title.name}`
                        }
                    });
                    this.titles = titles;

                    if (this.activeTitles.length > 0) {
                        this.selectedTitles = this.activeTitles;
                        this.$store.commit('setTabbedTitles', {
                            tab: this.searchTab,
                            titles: this.selectedTitles
                        });
                    }

                }).catch(this.handleError);
            },
            removeTitle(title) {
                this.selectedTitles = this.selectedTitles.filter(item => item.id !== title);
                const documentInfoIds = this.selectedTitles.map((title) => title.id);
                this.$store.commit('setTabbedTitleIds', {
                    tab: this.searchTab,
                    titles: documentInfoIds
                });
                this.$store.commit('setTabbedTitles', {
                    tab: this.searchTab,
                    titles: this.selectedTitles
                });
            },
            removeAll() {
                this.selectedTitles = [];
                this.$store.commit('setTabbedTitleIds', {
                    tab: this.searchTab,
                    titles: []
                });
                this.$store.commit('setTabbedTitles', {
                    tab: this.searchTab,
                    titles: this.selectedTitles
                });
            }

        },
        created() {
            EventBus.listen(EventNames.REMOVE_ACTIVE_TITLE, (title) => this.removeTitle(title));
            EventBus.listen(EventNames.REMOVE_ALL_ACTIVE_TITLES, () => this.removeAll());
        },
        mounted(){
            this.getAllTitles();
        }
    }
</script>
