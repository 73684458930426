var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c("v-card-title", { staticClass: "pb-0" }, [
        _c("h5", [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]),
      ]),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "pt-0 pb-0" }, [
        _vm._v("\n        " + _vm._s(_vm.subTitle) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                dark: "",
                text: "",
                role: "link",
                href: _vm.link,
                target: "_blank",
              },
            },
            [_vm._v("\n            Learn More\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }