var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "autocomplete-results",
      attrs: { flat: "", id: "autocomplete-results" },
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _vm._l(_vm.results, function (item) {
                return [
                  _c("title-list", {
                    attrs: {
                      title: item,
                      type: "list",
                      showVersion: true,
                      showFavorite: true,
                      showLinkIcon: false,
                      preventLink: true,
                      listOutline: false,
                    },
                  }),
                  _vm._v(" "),
                  _vm.results.length ? _c("v-divider") : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "xs12 px-4" },
                [_c("v-progress-linear", { attrs: { indeterminate: true } })],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }