<template>
    <v-snackbar
        :timeout="-1"
        :value="true"
        color="accent2Light"
        content-class="black--text fs-16">
        <template v-if="itemCount === 0">
            Get started with your Premium bundle
        </template>
        <template v-else>
            {{ itemCount }} titles in your Premium Bundle
        </template>
        <template v-slot:action="{ attrs }">
            <v-btn
            color="accent2"
            depressed
            v-bind="attrs"
            @click="openPremiumBundle"
            class="black--text font-weight-bold"
            >
            Open
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    emits: ['openPremiumBundle'],

    props:{
        itemCount: {
            required: true,
            type: Number
        }
    },
    methods: {
        openPremiumBundle(){
            this.$emit('openPremiumBundle');
        }
    }
}
</script>