var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "white elevation-1",
      style: _vm.appBarStyle,
      attrs: {
        app: "",
        "clipped-left": "",
        text: "",
        fixed: "",
        dense: "",
        "hide-on-scroll": "",
        id: "navigation-app-mobile",
      },
      scopedSlots: _vm._u([
        {
          key: "extension",
          fn: function () {
            return [_c("navigation-search")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-btn",
            {
              staticClass: "primary--text",
              attrs: { text: "", small: "" },
              on: {
                click: function ($event) {
                  return _vm.showNavDrawer()
                },
              },
            },
            [[_c("v-icon", { attrs: { color: "black" } }, [_vm._v("menu")])]],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-btn",
            {
              staticClass: "primary--text logo px-1",
              attrs: { text: "", href: _vm.getRoute("index_landing") },
            },
            [
              !_vm.hasPremiumSubscription
                ? _c("img", {
                    attrs: {
                      src: _vm.asset(
                        require("../../../images/logo_dc.png"),
                        "default"
                      ),
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: _vm.asset(
                        require("../../../images/icc_digital_codes_premium_100.png"),
                        "default"
                      ),
                    },
                  }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      [
        _c(
          "v-toolbar-items",
          [
            _c("cart-updates"),
            _vm._v(" "),
            _c("logo-menu"),
            _vm._v(" "),
            _c("navigation-help-menu"),
            _vm._v(" "),
            _c("profiles-menu"),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }