<template>
    <div>
        <v-dialog
            v-model="dialog"
            width="670">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Access Levels
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        class="accent2--text"
                        @click="dialog = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row
                            v-for="(label, index) in labels"
                            :key="index"
                            align="center">
                            <v-col
                                cols="4"
                                sm="3"
                                class="text-center">
                                <v-chip
                                    dark
                                    :color="label.color"
                                    small
                                    label
                                    class="px-1">
                                    {{ label.label }}
                                </v-chip>
                            </v-col>
                            <v-col>
                                <p class="mb-0">
                                    {{ label.text }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                dialog: false,
                labels: [
                    {
                        color: 'primary',
                        label: this.$getConst('accessLevels').basic,
                        text: 'Basic provides read-only access to this content'
                    },
                    {
                        color: 'primary',
                        label: this.$getConst('accessLevels').basicPlus,
                        text: 'Basic Plus allows controls to copy, paste and print this content'
                    },
                    {
                        color: 'accent2',
                        label: this.$getConst('accessLevels').premiumLite,
                        text: 'Premium Lite provides access to features (Notes, Search, Sharing) for this content, but does NOT provide access to features (Copy, Paste, and Print) for this content'
                    },
                    {
                        color: 'accent2',
                        label: this.$getConst('accessLevels').premium,
                        text: 'Premium provides access to features (Notes, Search, Sharing, Copy, Paste & Print ) for this content'
                    }
                ]
            };
        },
        mixins: [Mobile],
        created() {
            EventBus.listen("access-level-permission-prompt", () => this.dialog = true);
        }
    };
</script>
