<template>
    <v-btn
        small
        text
        :disabled="isDisabled"
        @click="toggleFullScreen">
        <v-icon>
            {{ isFullscreenContent ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
        </v-icon>
        {{ isFullscreenContent ? 'Exit Fullscreen' : 'Fullscreen' }}
    </v-btn>
</template>

<script>
    export default {
        name: 'FullScreenButton',
        data() {
            return {
                isDisabled: false,
            }
        },
        methods: {
            scrollToSection(sectionNumber) {

                const stickyChapterInfo = document.querySelector('#sticky-chapter-info');
                const currentSectionElement = document.querySelector('section[section-number="' + sectionNumber + '"]');

                if (stickyChapterInfo && currentSectionElement) {
                    const newTop = currentSectionElement.getBoundingClientRect().top + window.pageYOffset - stickyChapterInfo.getBoundingClientRect().bottom;

                    window.scrollTo({
                        top: newTop,
                        behavior: "smooth",
                    });
                }
            },
            toggleFullScreen() {

                const currentSection = this.$store.getters.getCurrentSectionFromTop;

                this.isDisabled = true;
                this.isFullscreenContent = !this.isFullscreenContent;

                this.$nextTick(() => {
                    if (currentSection) {
                        this.scrollToSection(currentSection);
                    }
                    this.isDisabled = false;
                });
            }
        }
    };
</script>
