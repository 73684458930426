<template>
    <div>
        <h3 class="roboto mb-5">No matches found</h3>
        <p>
            Your search for '<span class="font-weight-bold">{{ allSearchParams }}</span>'
            did not return any matches from your Premium library.
        </p>
        <v-divider></v-divider>

        <div v-if="didYouMean && didYouMean.length > 0">
            <p>Did you mean: </p>
            <ul>
                <li
                        v-for="(item, index) in didYouMean"
                        class="font-weight-bold ml-3"
                >
                    <a @click.prevent="doSearch(item)" class="text-capitalize">{{ item.suggest }}</a>
                </li>
            </ul>
            <br/>
        </div>

        <p class="font-weight-bold mt-5">
            Search Tips:
        </p>

        <ol>
            <li>Try Searching by Search Type</li>
            <li>Check the spelling of your search term</li>
            <li>Broaden your search by using fewer or more general terms.</li>
            <li>Expand your search filters to ensure your search term is being applied across the appropriate
                contents.
            </li>
        </ol>

        <p class="font-weight-bold mt-5">You Might Try:</p>

        <ul>
            <li v-for="(item, index) in mostPopularSearchTerms">
                <a href="" @click.prevent="doSearch(item)">{{ item }}</a>
            </li>
        </ul>

        <p class="font-weight-bold mt-5">Still can’t find what you are looking for?</p>

        <ul>
            <li>
                <a @click="initLiveChat()">Chat with us</a>
            </li>

            <li>
                <a href="mailto:customersuccess@iccsafe.org">Email us</a>
            </li>

            <li>
                <a @click="showFeedbackModal()">Submit Feedback</a>
            </li>

            <li>
                Call <a href="tel:1-888-422-7233">888-ICC-SAFE</a> (<a href="tel:1-888-422-7233">888-422-7233</a>).
            </li>
        </ul>
    </div>
</template>

<script>
import searchStatisticsMixin from "../../inc/mixins/searchStatisticsMixin";
import Mobile from "../../inc/mixins/mobile";
import FeedbackChat from "../../inc/mixins/feedback_chat";

export default {
    props: {
        didYouMean: {
            required: true,
            type: Object|Array,
        },
        allSearchParams: {
            required: true,
            type: String
        },
        searchTerm: {
            required: false,
            type: String
        }
    },
    mixins: [
        searchStatisticsMixin,
        Mobile,
        FeedbackChat
    ],
    methods: {
        doSearch(term) {
            EventBus.fire('update-content-search-results', term);
        },
        showFeedbackModal() {
            EventBus.fire('show-feedback-modal');
        }
    }
}
</script>
