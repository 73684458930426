export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        documentid: {
            type: String,
            required: true,
        },
        documentslug: {
            type: String,
            required: false,
        },
        youtubeid: {
            type: String,
            required: false,
        },
        buylink: {
            type: String,
            required: false,
        },
        contentBasic: {
            type: String,
            required: true
        },
        contentPremium: {
            type: String,
            required: true
        },
        contentProtection: {
            type: String,
            required: true
        },
        bookAvailableToSubscribe: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            classObject: {
                newIccStyles : false,
                newIccStylesCategorySpecific: false,
                freeViewStyles: false,
                purchasedViewStyles: false
            }
        }
    },
    methods: {
        async init(chapterId = 0) {
            let currentChapterId = chapterId;
            this.isXML = (this.type === 'xml');
            await this.$http.get(Routing.generate('get_book_info', { 'documentId': this.documentid })).then(
                response => {
                    if (response.data) {
                        this.activeDocumentInfo = response.data;
                        let contentType = response.data.content_type.name;
                        if(this.$getConst('contentTypeNewDbSchemaXml') == contentType ||
                        this.$getConst('contentTypeIccXml') == contentType || this.$getConst('contentTypeIccEsXml') == contentType || this.$getConst('contentTypeIccXml2') == contentType
                        ) {
                            this.isXML = true;
                        } else {
                            this.isXML = false;
                        }
                        this.getChapters(currentChapterId);
                        this.componentStyles();
                        EventBus.fire('content-protected');
                        EventBus.fire('set-content-search-label', response.data.book_year + ' ' + response.data.title);
                    }
                }
            );
        },
        getChapters(chapterId = 0) {
            let currentChapterId = chapterId;
            let route = this.isXML ? 'get_book_chapters' : 'get_pdf_chapters';

            this.$http.get(Routing.generate(route, { 'documentId': this.documentid })).then(
                response => {
                    if (response.data) {
                        this.$store.commit('setChapters', response.data);
                        this.$store.commit('setChapterSections', _.find(response.data, {content_id: _.toNumber(currentChapterId)}));
                        this.getChapterID();
                        this.setExpandedActive();
                    }
                }
            );
        },
        getChapterIndex(array, identifier) {
            let chapterIndex = _.find(array, {'content_id': _.toNumber(identifier)});
            if (chapterIndex == undefined) {
                _.forEach(array, function(value) {
                    if (_.size(value.sub_sections) > 0) {
                        this.getChapterIndex(value.sub_sections, identifier);
                    }
                });
            }
            return chapterIndex;
        },
        getChapterID() {
            let chapterId;
            let slugBefore = 'chapter';
            if (this.isXML) {
                slugBefore = this.documentslug;
            }
            let url = window.location.pathname.split('/');
            let i;
            for (i = 0; i < url.length; i++) {
                if(!this.isXML && 'content' == url[i]) {
                    let chapterSlug = url[i+2];

                    if(chapterSlug && 'chapter' != url[i+1]) {
                        let pdfChapter = _.find(this.chapterArray, {'pdf_chapter_slug': _.toString(chapterSlug)});

                        if(pdfChapter) {
                            EventBus.fire('show-book-content', pdfChapter);
                        }
                    }
                } else if (url[i] == slugBefore) {
                    if (!this.isXML) {
                        chapterId = url[i+1];
                        let chapter = _.find(this.chapterArray, {'id': _.toNumber(chapterId)});
                        EventBus.fire('show-book-content', chapter);
                    } else {
                        let chapter = _.find(this.chapterArray, {'chapter_slug': url[i+1]});
                        if (chapter) {
                            EventBus.fire('update-chapter-title', chapter.title);
                            chapterId = chapter.content_id;
                        }
                    }
                    if (_.isInteger(chapterId)) {
                        this.activeChapter = chapterId;
                    }
                } else if (i === 3) {  // Check for non slug XML books.
                    chapterId = url[i+1];
                    let chapter = _.find(this.chapterArray, {'content_id': _.toNumber(chapterId)});

                    if(chapter) {
                        EventBus.fire('update-chapter-title', chapter.title);
                        this.activeChapter = chapterId;
                    }
                }
            }
            if (window.location.hash) {
                this.$store.commit('setActiveHash', window.location.hash.substr(1));
            }
        },
        getExpandParent(obj) {
            let _this = this;
            let activeHash = _.replace(_this.$store.getters.getActiveHash, new RegExp('text-id-','g'), '');
            _.forEach(obj, function(value) {
                // Check to see if our item contains hash.
                let filter = _.filterDeep(value, function(value, key) {
                        if (key === 'id' && value === activeHash || key === 'content_id' && value === _.toNumber(activeHash)) return true
                    });
                if (_.size(filter) > 0) {
                    _this.$store.commit('addExpandedActive', {content_id: value.content_id, active: true});
                    if (!_.isUndefined(value.sub_sections)) {
                        _this.getExpandParent(value.sub_sections);
                    }
                }
            });
        },
        setExpandedActive() {
            let chapter =  _.find(this.$store.getters.getChapters, {content_id: _.toNumber(this.activeChapter)});
            if (chapter !== undefined) {
                this.$store.commit('setExpandedActive', [{content_id: chapter.content_id, active: true}]);
                // Add our parent list of sections.
                if (window.location.hash) {
                    this.getExpandParent(chapter.sub_sections);
                }
            }
        },

        componentStyles(){
            // Trigger to add custom class to content wrapper for CA books.
            let isCalifornia = this.activeDocumentInfo.book_categories.includes('California');

            this.classObject.newIccStyles = true;
            if(this.activeDocumentInfo.new_iccxml && isCalifornia){
                this.classObject.newIccStylesCategorySpecific = true;
            }
            if(_.toNumber(this.contentBasic) &&
                (
                    !_.toNumber(this.contentPremium) ||
                    !this.isLoggedIn
                )
            ){
                this.classObject.freeViewStyles = true;
            }else if(!_.toNumber(this.contentPremium) &&
                this.isLoggedIn
            ){
                this.classObject.purchasedViewStyles = true;
            }
        }
    }
}
