<template>
    <div>
        <v-skeleton-loader
            v-if="loading"
            transition="scale-transition"
            type="list-item">
        </v-skeleton-loader>
        <template v-else>
            <v-btn-toggle
                v-model="durationToggle"
                v-if="templateStyle && templateStyle === 'button'"
                dense>
                <v-btn
                    v-if="button.active"
                    v-for="(button, index) in duration"
                    :key="index"
                    @click="changeDuration(button)">
                    {{button.text}}
                </v-btn>
            </v-btn-toggle>
            <v-container
                v-else
                class="select_duration pa-0">
                <v-row dense>
                    <v-col>
                        <label class="caption primary--text">Billing Frequency</label>
                        <br/>

                        <v-radio-group v-model="item.subscription_type" row>
                            <v-radio v-for="(chip, index) in duration"
                                     :key="index"
                                     :value="chip.value"
                                     color="warning"
                                     @click="changeDuration(chip)"
                            >
                                <template v-slot:label>
                                    <div class="primary--text">{{ chip.text }}</div>
                                </template>
                            </v-radio>
                        </v-radio-group>

                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                durationToggle: 1,
                duration: [
                    {
                        text: this.templateStyle === 'button' ? '1 Month' : 'Monthly',
                        value: 'M',
                        active: true
                    },
                    {
                        text: 'Annually',
                        value: 'B',
                        active: true
                    },
                    {
                        text: '3 Year',
                        value: 'C',
                        active: true
                    }
                ],
                loading: true
            }
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            request: {
                required: false,
                type: String
            },
            templateStyle: {
                required: false,
                type: String
            },
            type: {
                required: false,
                type: String
            }
        },
        methods: {
            changeDuration(duration) {
                if (this.type) {
                    EventBus.fire('change-duration-'+this.type, {duration: duration, item: this.item});
                } else {
                    EventBus.fire('change-duration', {duration: duration, item: this.item});
                }
            },
            getDurations() {
                let selectedSubscriptionType = this.item.sku.charAt(this.item.sku.length - 1);
                this.$http.get(Routing.generate('get_product_subscription_types', {documentSku: this.item.document_info.sku}))
                    .then(response => {
                        for (let key in response.data) {
                            if ('M' == key) {
                                this.duration[0].active = response.data[key];
                                if(!this.duration[0].active) {
                                    this.changeDuration(this.duration[1]);
                                }
                            }
                            if ('B' == key) {
                                this.duration[1].active = response.data[key];
                                if(!this.duration[1].active) {
                                    this.changeDuration(this.duration[2]);
                                }
                            }
                            if ('C' == key) {
                                this.duration[2].active = response.data[key];
                            }
                        }
                        this.setDuration(selectedSubscriptionType);
                    }, response => {
                        // Keeps the loader from getting hung up.  Issue between DC and Shop for when shop does not return durations from a SKU.
                        if (response.status == 500) {
                            this.setDuration('B'); // Default
                        }
                    });
            },
            setDuration(type) {
                let durations = _.filter(this.duration, {active: true});
                let index = _.findIndex(durations, {value: type});
                index = _.isNumber(index) ? index : _.findIndex(durations, {value: 'B'}); // Default
                if (_.isNumber(index)) {
                    // if the selected duration is not available then only change to the available duration.
                    if (this.item?.subscription_type !== durations[index].value) {
                        this.changeDuration(durations[index]);
                    }
                    this.durationToggle = index;
                }
                this.loading = false;
            }
        },
        created() {
            const accessLevelId =  this.item.document_info.content_access_level?.id;
            //don't call shop API for access level 13 and 14
            if (!_.includes(this.$getConst('withCollectionLevels'), accessLevelId)) {
                this.getDurations();
            }
        }
    }
</script>

<style lang="scss">
    .select_duration {
        .v-chip {
            .v-avatar {
                margin-right: 0;
            }
            .v-chip__content{
                cursor: pointer;
            }
        }
    }
</style>
