<template>
    <!--Create new tag Modal-->
    <v-dialog
        persistent
        v-model="modal"
        max-width="400">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                {{modalTitle}}
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="closeModal">close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="formModel">
                    <v-row>
                        <v-col>
                            <v-text-field
                                hide-detials
                                ref="tagName"
                                class="mx-2 mt-4"
                                v-model="tag.name"
                                :rules="rules"
                                placeholder="Tag Name"
                                solo>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="tag.color"
                                v-mask="mask"
                                hide-details
                                class="mx-2 mt-n5"
                                solo>
                                <template v-slot:append>
                                    <v-menu
                                        v-model="menu"
                                        top
                                        nudge-bottom="105"
                                        nudge-left="16"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle" v-on="on" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="tag.color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="errorMessage">
                        <v-col class="error--text ml-2 pb-0">{{errorMessage}}</v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeModal">Cancel</v-btn>
                <v-btn color="primary" @click="saveTag">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mask} from 'vue-the-mask';
    import Mobile from '../../inc/mixins/mobile';
    import TagsMixins from './mixins/tags';
    import "vue-swatches/dist/vue-swatches.min.css"

    export default {
        data() {
            return {
                modal: false,
                isUpdate: false,
                rules: [val => (val || '').length > 0 || 'This field is required'],
                modalTitle: 'New Project Tag',
                errorMessage: '',
                mask: '!#XXXXXX',
                menu: false,
                tag: this.getTagData(),
                formModel: true
            }
        },
        mixins: [Mobile, TagsMixins],
        directives: {
            mask
        },
        methods: {
            getTagData() {
                return {
                    id: 0,
                    name: '',
                    color: this.$getConst('defaultTagColor'),
                }
            },
            saveTag() {
                let route = this.isUpdate ? 'update_tag' : 'create_new_tag';
                this.errorMessage = '';
                if (this.tag.name.length > 0) {
                    this.$http.get(Routing.generate(route, {tag: this.tag}))
                        .then(response => {
                            if (response.data.success === false) {
                                this.errorMessage = response.data.errorMessage;
                            } else {
                                EventBus.fire('update-note-tags', response.data.id);
                                this.closeModal();
                            }
                        });
                } else {
                    this.$refs.tagName.focus();
                }
            },
            openTagModal(data){
                this.errorMessage = '';
                this.modalTitle = 'New Project Tag';
                this.isUpdate = false;
                if(data.isUpdate) {
                    this.modalTitle = 'Edit Project Tag';
                    this.isUpdate = data.isUpdate;
                    let tag = _.find(this.$store.getters.getTags, {'id': data.tag});
                    if(tag){
                        this.tag.id = tag.id;
                        this.tag.name = tag.tag;
                        this.tag.color = '#'+tag.color;
                    }
                }
                this.modal = true;
            },
            closeModal(){
                this.tag = {
                    name: '',
                    color: this.$getConst('defaultTagColor'),
                };
                this.modal = false;
                this.errorMessage = '';
                this.$refs.form.resetValidation();
            }
        },
        created(){
             EventBus.listen('create-new-tag', (data) => this.openTagModal(data));
        }
    }
</script>
