<template>
    <v-navigation-drawer
        temporary
        absolute
        right
        :width="isMediumAndUp ? '50%' : '100%'"
        v-model="dialog">
        <v-card flat>
            <v-card-text>
                <status-alert :alert="statusAlert"></status-alert>
                <v-row>
                    <v-col cols="auto">
                        <h3 class="primary--text">
                            <v-icon class="mr-2 primary--text">
                                mdi-file-import
                            </v-icon> Import
                        </h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-icon
                            small
                            class="accent2--text"
                            @click="closeModal()">
                            close
                        </v-icon>
                    </v-col>           
                </v-row>
                <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy">
                    <v-row>
                        <v-col class="pb-0">
                            <v-label>
                                Import Users
                            </v-label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn 
                                large
                                rounded
                                :loading="isSelecting" 
                                @click="handleFileImport">
                                <v-icon class="mr-2">
                                    mdi-cloud-upload
                                </v-icon> Upload your CSV File
                            </v-btn>
                            <p v-if="chosenFile" class="mt-4" align="center">{{ chosenFile.name }}</p>
                            <!-- Create a File Input that will be hidden but triggered with JavaScript -->
                            <input 
                                ref="uploader" 
                                class="d-none" 
                                type="file" 
                                @change="onFileChanged">
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col>
                            <v-btn
                                block
                                small
                                dark
                                color="accent2"
                                @click="importUser()">
                                Import
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                block
                                small
                                dark
                                color="primary"
                                @click="closeModal()">
                                Close
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col class="pb-0">
                        <p class="mb-0">Download the CSV document template below:</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn 
                            class="ma-2" 
                            outlined 
                            :href="sampleFile"
                            download>
                            Download Template
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import axios from "axios";
    import StatusAlert from '../admin/enterprise/partials/status_alert';
    import Enterprise from '../inc/mixins/enterprise';
    import Mobile from "../inc/mixins/mobile";

    export default {
        props: ['selectedUsers'],
        data() {
            return {
                entId: null,
                dialog: false,
                valid: true,
                lazy: false,
                statusAlert: {
                    type: 'success',
                    message: ''
                },
                chosenFile: null,
                isSelecting: false,
                sampleFile: ''
            }
        },
        mixins: [Enterprise, Mobile],
        components: {
            StatusAlert
        },
        methods: {
            handleFileImport() {
                this.$refs.uploader.value=null;
                this.isSelecting = true;

                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });
                
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                this.chosenFile = e.target.files[0];
            },
            showModal(enterpriseId) {
                this.clearRequest();
                this.dialog = true;
                this.entId = enterpriseId;
            },
            closeModal() {
                this.clearRequest();
                this.dialog = false;
            },
            importUser() {
                if (this.chosenFile) {
                    this.loading = true;
                    let formData = new FormData();
                    formData.append("files", this.chosenFile, this.chosenFile.name);
                    formData.append("enterpriseId", this.entId);
                    axios.post(Routing.generate('import_enterprise_users'), formData)
                    .then((response) => {
                        if (response.data && response.data.code == 200) {
                            this.clearRequest();
                            this.handleSuccess('Enterprise user successfully added: '
                                +response.data.message.success+'. User already associated to enterperise: '
                                +response.data.message.alreadyPresent+'. Email missing in uploaded CSV File: '
                                +response.data.message.errorInCsv);
                        }
                        EventBus.fire('getUsers', this.entId);
                    }).catch(this.handleError);
                } else {
                    this.handleError('Please Upload File To Parse');
                }
            },
            handleError(message = 'Sorry, something went wrong') {
                this.statusAlert.message = message;
                this.statusAlert.type = 'error';
            },
            clearRequest() {
                this.statusAlert.message = '';
                this.chosenFile = '';
            },
            handleSuccess(message = 'Enterprise user was successfully added') {
                this.statusAlert.message = message;
                this.statusAlert.type = 'success';
            },
            getSampleCsvFile() {
                this.sampleFile = this.enterpriseSampleUser();
            }
        },
        created() {
            this.getSampleCsvFile();
            EventBus.listen('userImport', (enterpriseId) => this.showModal(enterpriseId));
        }
    }
</script>
