<template>
    <p class="subtitle-1 mb-0 font-weight-bold" @click="headerLinkClicked">
        <item-link
            :meta="meta"
            :documentId="documentId"
            :breadcrumbs="breadcrumbs ? breadcrumbs : {}">
            <span v-html="heading"></span>
        </item-link>
    </p>
</template>

<script>
    import ItemLink from './item_link';
    import Breadcrumbs from '../../mixins/breadcrumbs';
    import ContentSearch from '../../mixins/TabbedSearch';

    export default {
        props: ['title', 'meta', 'breadcrumbs', 'documentId', 'search'],
        components: {
            ItemLink
        },
        computed: {
            heading() {
                if (this.title) {
                    return this.highlightHeading(this.title);
                }
                if (!_.isEmpty(this.breadcrumbs)) {
                    const breadcrumbs = this.breadcrumbs;
                    if (this.isSubsection(breadcrumbs)) {
                        let sectionTop = breadcrumbs.sectiontop;
                        if (sectionTop.startsWith(breadcrumbs.subSection)){
                            return this.highlightHeading(sectionTop);
                        }
                        return this.highlightHeading(breadcrumbs.subSection);
                    } else if (this.isSection(breadcrumbs)) {
                        return this.highlightHeading(breadcrumbs.section);
                    } else if (this.isChapter(breadcrumbs)) {
                        return this.highlightHeading(breadcrumbs.chapter);
                    }
                    return this.highlightHeading(breadcrumbs.year + ' ' + breadcrumbs.title);
                }
                const {sectiontitle, title, year} = this.meta;

                if (sectiontitle) {
                    return this.highlightHeading(sectiontitle);
                } else if (year && title) {
                    return year + ' ' + title;
                } else {
                    return title;
                }
            }
        },
        mixins: [Breadcrumbs, ContentSearch],
        methods: {
            highlightHeading(heading){
                let userInput = this.search.exact || this.search.any || this.search.all;
                let escapedUserInput = this.escapeRegExp(userInput ? userInput.trim() : '');
                let searchRegEx = new RegExp(escapedUserInput, "ig");
                let cleanedHeading = heading.replace(/\s+/g, ' ').trim(); // remove unwanted extra spaces
                let highLightText = cleanedHeading.replace(searchRegEx, '<span style="background-color: yellow;">$&</span>');
                
                return highLightText;
            },
            escapeRegExp(userInput){
                return userInput.replace(/[*+?^${}()|[\]\\]/g, '\\$&');
            },
            headerLinkClicked() {
                this.gtmEvent('Content Search', 'Result Click', 'Header Link');
            }
        }
    }
</script>
