var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "disable-selection tag-icons" },
    [
      _vm.tag === "es"
        ? _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "green lighten-4",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("ES"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2068155754
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [
                _vm._v("Evaluation Service"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "errata"
        ? _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "red accent-1",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("E"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2206802591
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [
                _vm._v("Errata"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "premium"
        ? _c(
            "v-tooltip",
            {
              attrs: { color: "black", "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp text--primary",
                              attrs: {
                                "x-small": "",
                                color: "accent2Light",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("INSIGHTS"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1233541789
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [
                _vm._v(
                  "Premium Code Insights available at the end of the section"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "cdp"
        ? _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "blue lighten-4",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("CDP"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1946234574
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [
                _vm._v("Code Development Proposal"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "srcc"
        ? _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "purple lighten-3",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("S"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2307012189
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [
                _vm._v("Solar Rating and Certification Corporation"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "video"
        ? _c(
            "v-tooltip",
            {
              attrs: { "open-delay": "750", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "deep-orange lighten-2",
                                label: "",
                              },
                            },
                            on
                          ),
                          [
                            _c("span", { staticClass: "text--primary" }, [
                              _vm._v("V"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3565594398
              ),
            },
            [
              _vm._v(" "),
              _c("span", { staticClass: "tooltip-content" }, [_vm._v("Video")]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }