import Vue from 'vue';
import VueSession from 'vue-session';

Vue.use(VueSession, {persist: true});

export const CodesModule = {
    state: {
        codesLoading: true,
        codesPageTitle: 'Find Codes',
        codesPagination: {
            page: 1,
            pages: 0,
            disabled: true,
            totalResults: 0
        },
        codesYearSelected: null,
        astmSubjects: {},
        currentAstmSubject: null,
        isAstmStandard: false,
        isAstmSearch: Vue.prototype.$session.has('isAstmSearch') ? Vue.prototype.$session.get('isAstmSearch') : false
    },
    getters: {
        getCodesLoading: (state) => state.codesLoading,
        getCodesPageTitle: (state) => state.codesPageTitle,
        getCodesPagination: (state) => state.codesPagination,
        getAstmSubjects: (state) => state.astmSubjects,
        getCurrentAstmSubject: (state) => state.currentAstmSubject,
        getAstmStandard: (state) => state.isAstmStandard,
        getAstmSearch: (state) => state.isAstmSearch,
        getCodesYearSelected: (state) => state.codesYearSelected
    },
    mutations: {
        setCodesLoading (state, loading) {
            state.codesLoading = loading;
        },
        setCodesPageTitle (state, title) {
            state.codesPageTitle = title;
        },
        setCodesPagination (state, data) {
            state.codesPagination = data;
        },
        setAstmSubjects (state, data) {
            state.astmSubjects = data;
        },
        setCurrentAstmSubject(state, data){
            state.currentAstmSubject = data;
        },
        setAstmStandard (state, data) {
            state.isAstmStandard = data;
        },
        setAstmSearch (state, data) {
            Vue.prototype.$session.set('isAstmSearch', data);
            state.isAstmSearch = data;
        },
        setCodesYearSelected(state, data) {
            state.codesYearSelected = data;
        }
    }
};
