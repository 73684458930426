var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "cdp-dialog",
        "max-width": 1200,
        fullscreen: _vm.isMobilePort,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-row",
        {
          ref: "cdpHeader",
          staticClass: "custom-row",
          attrs: { align: "center" },
        },
        [
          _c("div", {
            staticClass: "top-background",
            style: _vm.styles.background,
          }),
          _vm._v(" "),
          _c(
            "v-col",
            {
              ref: "topLeft",
              staticClass: "primary z-fix",
              attrs: { dark: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "cdpBlue--text mx-5 mb-0 oswald cdp-logo font-weight-regular",
                      },
                      [
                        _vm._v(
                          "\n                        cdpACCESS\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "heading-5 white--text mb-0 mt-2 font-weight-bold",
                      },
                      [
                        _vm._v(
                          "\n                        Proposals Under Consideration\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isMobilePort
                      ? _c("p", { staticClass: "caption white--text mb-0" }, [
                          _vm._v(
                            "\n                        Proposals listed are under active consideration within the code development process for integration to the next cycle’s version of the model code.  If a proposal is no longer being considered (i.e. withdrawn), it will be removed from the list.\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", {
            staticClass: "pa-0 z-fix triangle",
            style: _vm.styles.topLeft,
            attrs: { cols: "auto" },
          }),
          _vm._v(" "),
          !_vm.isMobilePort
            ? _c(
                "v-col",
                { staticClass: "z-fix py-0", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "ml-n12",
                      attrs: { align: "center", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "cp",
                          attrs: { cols: "auto" },
                          on: {
                            click: function ($event) {
                              return _vm.gotToLink(
                                "https://www.iccsafe.org/products-and-services/i-codes/code-development/"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "pa-0" }, [
                                _c(
                                  "p",
                                  { staticClass: "white--text mb-0 oswald" },
                                  [
                                    _vm._v(
                                      "\n                                WHAT IS THE\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "pa-0" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "white--text mb-0 ml-5 oswald font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                CODE DEVELOPMENT PROCESS\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "p",
                          { staticClass: "white--text mb-0 oswald font-40" },
                          [
                            _vm._v(
                              "\n                        ?\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { justify: "space-around", dense: "" } },
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          {
                            staticClass: "caption white--text mb-0 cp",
                            on: {
                              click: function ($event) {
                                return _vm.gotToLink(
                                  "https://www.iccsafe.org/products-and-services/i-codes/code-development/"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Click to learn more\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "pa-0",
              attrs: { "align-self": "start", cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "rounded-close",
                  attrs: { small: "", fab: "", color: "black" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "accent2--text" }, [
                    _vm._v("\n                    close\n                "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                { ref: "cdpBody", staticClass: "rounded-card" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _vm.loading
                        ? [
                            _c(
                              "v-container",
                              { staticClass: "text-center py-12" },
                              [
                                _c("v-progress-circular", {
                                  staticClass: "py-6",
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : !_vm.loading && !_vm.data.length
                        ? [
                            _c(
                              "v-container",
                              { staticClass: "text-center py-12" },
                              [_c("p", [_vm._v("No results found")])]
                            ),
                          ]
                        : [
                            _c(
                              "v-btn",
                              {
                                ref: "expandButton",
                                staticClass: "expand-button",
                                style: _vm.styles.expandButton,
                                attrs: {
                                  small: "",
                                  color: "grey darken-3",
                                  fab: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showHideTabs()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.showTabs
                                          ? "mdi-chevron-left"
                                          : "mdi-chevron-right"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tabs",
                              {
                                style: _vm.styles.contentHeight,
                                attrs: {
                                  vertical: "",
                                  "background-color": "grey lighten-3",
                                  "active-class":
                                    "primary--text grey lighten-4",
                                },
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showTabs,
                                        expression: "showTabs",
                                      },
                                    ],
                                    ref: "tabs",
                                  },
                                  _vm._l(_vm.data, function (item, key) {
                                    return _c("v-tab", { key: key }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.tag.proposal_name) +
                                          "\n                                "
                                      ),
                                    ])
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tabs-items",
                                  {
                                    model: {
                                      value: _vm.tab,
                                      callback: function ($$v) {
                                        _vm.tab = $$v
                                      },
                                      expression: "tab",
                                    },
                                  },
                                  _vm._l(_vm.data, function (item, key) {
                                    return _c(
                                      "v-tab-item",
                                      { key: key },
                                      [
                                        _c("vue-friendly-iframe", {
                                          style: _vm.styles.contentHeight,
                                          attrs: { src: item.tag.url },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          ref: "cdpFooter",
          staticClass: "custom-row",
          attrs: { align: "center" },
        },
        [
          _c("div", {
            staticClass: "bottom-background",
            style: _vm.styles.background,
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "z-fix", attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "cp",
                  attrs: { dense: "", align: "center" },
                  on: {
                    click: function ($event) {
                      return _vm.gotToLink("https://www.cdpaccess.com/")
                    },
                  },
                },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "white--text mb-0 mt-2 ml-5" }, [
                      _vm._v(
                        "\n                        Go to\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("h2", { staticClass: "white--text oswald" }, [
                      _vm._v(
                        "\n                        cdpACCESS\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMobilePort
            ? [
                _c("v-col", {
                  staticClass: "pa-0 z-fix triangle",
                  style: _vm.styles.bottomRight,
                  attrs: { cols: "auto" },
                }),
                _vm._v(" "),
                _c(
                  "v-col",
                  { ref: "bottomRight", staticClass: "primary z-fix" },
                  [
                    _c("p", { staticClass: "white--text mb-0 caption" }, [
                      _vm._v(
                        "\n                    The International Code Council develops construction and public safety codes through a\n                    governmental consensus process. This system of code development has provided the\n                    highest level of safety in the world for more than 90 years.\n                "
                      ),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }