<template>
    <div id="environment_banner" class="fixed-banner-outer">
        <div class="fixed-banner-inner" :style="{ backgroundColor: bannerbackgroundcolor, color: bannertextcolor }">
            <div class="top-banner-message"> {{bannermessage}} *** Version: {{appversion}} </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['bannerbackgroundcolor','bannertextcolor','bannermessage','appversion'],
    }
</script>
