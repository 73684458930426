<template>
    <div class="pa-5 fill-height">
        <div
            v-if="provider === 'youtube' && contentId"
            class="video-container">
            <iframe
                class="video"
                :src="'https://www.youtube.com/embed/' + contentId"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
            </iframe>
        </div>

        <template v-else>

            <p>
                Video failed to embed: <a :href="tagContent" target="_blank">{{ tagContent }}</a>
            </p>

        </template>

    </div>
</template>

<script>

export default {
    props: {
        tagData: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            provider: null,
            contentId: null
        }
    },
    computed: {
        tagTitle() {
            return this.tagData?.tag?.title;
        },
        tagContent() {
            return this.tagData?.tag?.content;
        }
    },
    mounted() {

        // const content = "https://www.youtube.com/watch?v=L3GvptZuR88";
        const content = this.tagData?.tag?.content;

        if (content.includes("youtube.com")) {
            this.provider = "youtube";
        }

        if (this.provider === 'youtube') {
            const matches = content.match(/(?:\/|%3D|v=|vi=)([a-z0-9_-]{11})(?:[%#?&]|$)/i);
            this.contentId = matches ? matches[1] : null;
        }
    }
}
</script>

<style lang="scss" scoped>
.video-container {
    position: relative;
    width: 100%;
    height: 95%;
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.tag-video-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0.5em;
}
</style>
