var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "cart_checkout_card",
      class: { "ml-2": _vm.$vuetify.breakpoint.mdAndUp },
      attrs: { flat: "" },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", flat: "", dark: "" } },
        [
          _c("v-toolbar-title", { staticClass: "title font-weight-thin" }, [
            _vm._v("Summary"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "primary--text" }, [
                    _vm._v("\n                    Subtotal ("),
                    _c("span", [_vm._v(_vm._s(_vm.itemCount))]),
                    _vm._v(
                      " " + _vm._s(_vm.getItemText) + ")\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("$" + _vm._s(_vm.totalPrice)),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "black--text" }, [
                    _c("p", [
                      _vm._v(
                        "Have a Discount Code? You can enter it when you checkout."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", color: "accent2" },
                          on: { click: _vm.goToCheckout },
                        },
                        [
                          _vm._v(
                            "\n                        Proceed to Checkout\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }