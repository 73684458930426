<template>
    <div>
        <template v-for="reportTag in data">
            <v-row align="center">
                <v-col>
                    <p v-if="reportTag.tag.url.length">
                        <iframe :src="reportTag.tag.url" width="98%" height="300" allowfullscreen></iframe>
                    </p>
                    <p v-else class="red--text">
                        Video not available.
                    </p>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array
        }
    }
}
</script>
