<template>
    <v-card outlined>
        <v-card-title class="pb-0">
            <h5>
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-text class="pt-0 pb-0">
            {{ subTitle }}
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="primary"
                dark 
                text
                role="link"
                :href="link"
                target="_blank">
                Learn More
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
 export default {
    name: 'DidYouKnow',
    data() {
        return {
           title: 'Did you know?',
           subTitle: 'Premium Answers serves as a complement to ICC membership benefits including code opinion and interpretation requests.',
           link: this.$getConst('links').technicalOpinions,
        }
    }
}
</script>
