var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("expandable-panel", {
    staticClass: "tags-expansion-panel-wrapper",
    attrs: { id: `tag-category-${_vm.contentId}`, expand: _vm.expand },
    on: { changeExpansion: _vm.changeExpansion },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("span", { staticClass: "text--secondary font-weight-bold" }, [
              _vm._v("INSIGHTS (" + _vm._s(_vm.categories.length) + ") "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.showPremiumInsights
              ? _c(
                  "div",
                  { staticClass: "premium-tag-category-item" },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", align: "center" } },
                      _vm._l(_vm.categories, function (item, key) {
                        return item.active
                          ? _c(
                              "v-col",
                              { key: key, attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "open-delay": "400",
                                      "z-index": "199",
                                      "max-width": "300",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "grey lighten-3",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.selectCategory(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-chip",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  item?.icon
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "mr-1 text--secondary",
                                                          attrs: { size: "20" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        mdi-" +
                                                              _vm._s(
                                                                item.icon
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "black--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(item.name) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tooltip-codeinsights-text",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.trimmedText(item.description)
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }