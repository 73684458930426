var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("user-summary", {
        attrs: {
          activeUsers: _vm.activeUsers,
          pendingUsers: _vm.pendingUsers,
          enterpriseTotalSeats: _vm.enterpriseTotalSeats,
          totalUsers: _vm.totalUsers,
        },
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "white rounded-xl", attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      { staticClass: "flex-grow-1 body-3 primary--text" },
                      [
                        _c("v-icon", [
                          _vm._v(
                            "\n                            person\n                        "
                          ),
                        ]),
                        _vm._v(" Manage Users\n                    "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-5 mt-md-0" },
                      [
                        _c("v-text-field", {
                          staticClass:
                            "d-none d-md-block mr-2 rounded-xl shrink",
                          attrs: {
                            dense: "",
                            clearable: "",
                            "append-icon": "mdi-magnify",
                            label: "Search by keywords",
                            outlined: "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text mr-2 rounded-xl shrink",
                            attrs: {
                              height: "40",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showModal()
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n                            mdi-account-plus\n                        "
                              ),
                            ]),
                            _vm._v(" Add User\n                    "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary--text rounded-xl shrink",
                            attrs: {
                              height: "40",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showImportModal()
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n                            mdi-file-import\n                        "
                              ),
                            ]),
                            _vm._v(" Import\n                    "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.users.length
                    ? _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              "must-sort": "",
                              search: _vm.search,
                              headers: _vm.headers,
                              items: _vm.users,
                              loading: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          class: {
                                            active:
                                              (_vm.users.indexOf(item) + 1) %
                                                2 ==
                                              0,
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "no-border" },
                                            [_vm._v(_vm._s(item.first_name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "no-border" },
                                            [_vm._v(_vm._s(item.last_name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "no-border" },
                                            [_vm._v(_vm._s(item.email))]
                                          ),
                                          _vm._v(" "),
                                          item.last_login
                                            ? _c(
                                                "td",
                                                { staticClass: "no-border" },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm._f("moment")(
                                                          item.last_login.date,
                                                          "MMMM DD, YYYY"
                                                        )
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "td",
                                                { staticClass: "no-border" },
                                                [
                                                  _vm._v(
                                                    "\n                                    Login Pending\n                                "
                                                  ),
                                                ]
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "no-border" },
                                            [
                                              _vm.loggedInUser &&
                                              _vm.loggedInUser.email !==
                                                item.email
                                                ? [
                                                    item.role === 0 ||
                                                    item.role === 1
                                                      ? _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "close-on-content-click": false,
                                                              "offset-y": "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            class:
                                                                              {
                                                                                "pl-1":
                                                                                  item.role ===
                                                                                  1,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                "x-small":
                                                                                  _vm.isMobileAndTablet,
                                                                                text: "",
                                                                              },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          item.role ===
                                                                          0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Regular"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : item.role ===
                                                                              1
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                          color:
                                                                                            "accent2",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                            mdi-crown\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "Admin\n                                                    "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  right:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        " +
                                                                                  _vm._s(
                                                                                    _vm.menu
                                                                                      ? "mdi-chevron-up"
                                                                                      : "mdi-chevron-down"
                                                                                  ) +
                                                                                  "\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-card",
                                                              [
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "py-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center",
                                                                          dense:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "auto",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "mt-0",
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateUser(
                                                                                        item,
                                                                                        1,
                                                                                        null
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                Admin\n                                                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          align:
                                                                            "center",
                                                                          dense:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "auto",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "mt-0",
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateUser(
                                                                                        item,
                                                                                        0,
                                                                                        null
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                Regular\n                                                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text lighten-3",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Pending User"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                  ]
                                                : [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text lighten-3",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mr-2 ml-1",
                                                            attrs: {
                                                              small: "",
                                                              color: "accent2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                mdi-crown\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v("Admin"),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "no-border",
                                              class: {
                                                "green--text":
                                                  item.response_status ===
                                                  _vm.enterpriseAccepted,
                                                "red--text":
                                                  item.response_status ===
                                                  _vm.enterpriseDeclined,
                                                "blue--text":
                                                  item.response_status ===
                                                  _vm.enterprisePending,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.response_status) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center no-border",
                                            },
                                            [
                                              item.token
                                                ? _c("Invitation", {
                                                    attrs: {
                                                      token: item.token,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "no-border" },
                                            [
                                              _vm.loggedInUser &&
                                              _vm.loggedInUser.email !==
                                                item.email &&
                                              item.response_status ===
                                                _vm.enterpriseAccepted &&
                                              item.last_login
                                                ? [
                                                    item.status
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateUser(
                                                                  item,
                                                                  null,
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            Deactivate\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : !item.status
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateUser(
                                                                  item,
                                                                  null,
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            Activate\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : [
                                                    _vm.loggedInUser &&
                                                    _vm.loggedInUser.email !==
                                                      item.email
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.confirmRemove(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            Remove\n                                        "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2731466621
                            ),
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-card-text",
                        { staticClass: "text-center subtitle-1 title pa-4" },
                        [
                          _vm.loading
                            ? [_vm._v("Loading...")]
                            : [_vm._v("No users found")],
                        ],
                        2
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n                Confirm Removal\n            "),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                            Are you sure you want to delete this enterprise user?\n                        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: {
                                click: function ($event) {
                                  return _vm.denyRemove()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            No\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "green",
                              attrs: { dark: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeUser()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Yes\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-user"),
      _vm._v(" "),
      _c("import-user"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }