var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasProduct
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "mt-6" },
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    id: "related_subscriptions",
                    color: "white",
                    flat: "",
                  },
                },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "title font-weight-thin" },
                    [_vm._v("Related Subscriptions")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { xs3: "" } }, [
                        _c("img", {
                          staticClass: "my-2",
                          staticStyle: { "max-width": "100%" },
                          attrs: {
                            src: _vm.getBookCover(_vm.related_product),
                            alt: "related_product.display_title",
                          },
                          on: { error: _vm.loadDefaultBookCover },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs9: "", "pt-2": "", "pl-2": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("p", { staticClass: "font-weight-bold" }, [
                                  _vm._v(
                                    _vm._s(_vm.related_product.display_title)
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary right",
                                      attrs: { depressed: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkAction(
                                            _vm.related_product
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                View Premium Options\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }