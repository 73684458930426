var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-pagination", {
    staticClass: "custom-pagination",
    attrs: {
      length: _vm.pagination.pages,
      "total-visible": _vm.isMobilePort ? 3 : 7,
    },
    on: { input: _vm.makePaginationChanged },
    model: {
      value: _vm.pagination.page,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "page", $$v)
      },
      expression: "pagination.page",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }