var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { staticClass: "mt-md-8", attrs: { elevation: "2" } },
    [
      _c(
        "v-tabs",
        {
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "accent2" } }),
          _vm._v(" "),
          _vm._l(_vm.items, function (item) {
            return _c(
              "v-tab",
              {
                key: item.id,
                staticClass: "text-capitalize no-border",
                on: {
                  click: function ($event) {
                    return _vm.tabAction(item.text)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(item.text) + "\n        ")]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }