var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c(
              "p",
              { staticClass: "fs-20 my-3" },
              [
                _vm.searchTerm
                  ? [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.totalResults.toLocaleString()) +
                          " " +
                          _vm._s(_vm.titleLabel) +
                          " results for\n                    "
                      ),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold" },
                        [
                          [
                            _vm._v(
                              '\n                            "' +
                                _vm._s(_vm.searchTerm) +
                                '"\n                        '
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "body-1" },
                        [_c("share-feedback")],
                        1
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n                    Enter search term or refine search\n                "
                      ),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _vm._l(_vm.activeFilters, function (item, key) {
                return item.data
                  ? [
                      item.data.length === 1
                        ? _c(
                            "v-chip",
                            {
                              key: item.data.key,
                              staticClass: "primary--text mr-2",
                              attrs: { color: "primaryLight" },
                            },
                            [
                              _c("span", { staticClass: "fs-16" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.data[0]?.name ?? item.data[0]) +
                                    "\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                {
                                  attrs: { right: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearFilter(
                                        item.key,
                                        item.data[0]?.id ?? item.data[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        mdi-close\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.data.length > 1
                        ? _c(
                            "v-menu",
                            {
                              attrs: { absolute: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "primary--text mr-2 mb-1",
                                                attrs: {
                                                  color: "primaryLight",
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fs-16" },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      `${item.subLabel} (${item.data.length})`
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { right: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clearFilter(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                mdi-close\n                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                _vm._l(item.data, function (subItem, index) {
                                  return _c(
                                    "v-list-item",
                                    { key: index },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "primary--text mr-2",
                                              attrs: { color: "primaryLight" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fs-16" },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        subItem?.name ?? subItem
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { right: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearFilter(
                                                        item.key,
                                                        subItem?.id ?? subItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        mdi-close\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e()
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMediumAndDown
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("showModal")
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(
                          "\n                    mdi-tune\n                "
                        ),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.buttonName()) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }