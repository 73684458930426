<template>
    <div
        :class="{'text-right': displayRightAligned}"
        v-if="restrictedText">
        <template v-if="displayIconOnly">
            <v-avatar :color="badgeColor.backGroundColor" size="24">
                <v-icon
                    v-if="contentRestrictions[restrictedText]"
                    small
                    :color="badgeColor.color">
                    {{ contentRestrictions[restrictedText].icon }}
                </v-icon>
                <v-icon
                    v-else
                    small
                    :color="badgeColor.color">
                    mdi-creation
                </v-icon>
            </v-avatar>
        </template>
        <template v-else>
            <v-chip
                v-if="contentRestrictions[restrictedText]"
                small
                label
                class="font-weight-bold"
                :color="badgeColor.backGroundColor"
                elevation="0">
                <v-icon
                    small
                    left
                    :color="badgeColor.color"
                    >
                    {{ contentRestrictions[restrictedText].icon }}
                </v-icon>
                <span
                    class="caption font-weight-bold text-uppercase"
                    :class="badgeColor.textColor">
                    {{ contentRestrictions[restrictedText].label }}
                </span>
            </v-chip>
            <v-tooltip
                v-else
                bottom
                max-width="200"
                transition="fade-transition"
                v-model="tooltipOpen">
                <template v-slot:activator="{ on }">
                <v-chip
                    small
                    label
                    class="font-weight-bold"
                    color="accent2Light"
                    v-on="on"
                    @click="openPremiumFeaturesDialog()"
                    @keydown.enter="openPremiumFeaturesDialog()"
                    @keydown.space="openPremiumFeaturesDialog()"
                    tabindex="0">
                    <v-icon
                        small
                        left
                        :color="badgeColor.color"
                        >
                        mdi-creation
                    </v-icon>
                    <span :class="badgeColor.textColor">
                        PREMIUM
                    </span>
                </v-chip>
                </template>
                <span class="tooltip-content"
                @mouseenter="showTooltip"
                @mouseleave="hideTooltip">Learn about Premium</span>
            </v-tooltip>
        </template>
    </div>
</template>

<script>
    import Mobile from "../../inc/mixins/mobile";

    export default {
        name: 'PremiumStatus',
        data() {
            return {
                contentRestrictions: {
                    basic: {
                        icon: 'mdi-lock-open-variant-outline',
                        label: this.$getConst('accessLevels').basicReadOnly,
                    },
                    basicPlus: {
                        icon: 'mdi-lock-open-variant-outline',
                        label: this.$getConst('accessLevels').basicPlus,
                    },
                    basicReadOnly: {
                        icon: 'mdi-lock-open-variant-outline',
                        label: this.$getConst('accessLevels').basicReadOnly,
                    },
                    premiumRequired: {
                        icon: 'mdi-lock-outline',
                        label: this.$getConst('accessLevels').premiumRequired,
                    },
                    premiumLite: {
                        icon: 'mdi-creation',
                        label: this.$getConst('accessLevels').premiumLite,
                    }
                }
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            documentInfo: {
                type: Object,
                required: true
            },
            contentPremium: {
                type: Boolean|String,
                required: true
            },
            contentProtection: {
                type: Boolean|String,
                required: true
            },
            contentBasic: {
                type: Boolean|String,
                required: true
            },
            displayIconOnly: {
                type: Boolean,
                required: false
            },
            displayRightAligned: {
                type: Boolean,
                required: false,
            }
        },
        computed: {
            restrictedText() {
                const { contentProtection, contentPremium, contentBasic, documentInfo } = this;

                if (documentInfo) {
                    const { contentProtection: docProtection, contentPremium: docPremium, contentBasic: docBasic } = documentInfo;

                    if ('1' === contentPremium || docPremium) {
                        return docProtection === '1' ? 'premiumLite' : 'premium';
                    }

                    if (!this.isContentRestricted(documentInfo)) {
                        if (('1' === contentBasic && '1' === contentProtection) || (docBasic && docProtection)) {
                            return 'basicReadOnly';
                        } else {
                            return 'basicPlus';
                        }
                    } else {
                        return 'premiumRequired';
                    }
                }
            },
            badgeColor() {
                if (this.restrictedText === 'premiumRequired') {
                    return {
                        color: 'grey darken-4',
                        textColor: 'grey--text text--darken-4',
                        backGroundColor: 'grey lighten-2'
                    };
                }

                if (this.restrictedText === 'premium' || this.restrictedText === 'premiumLite') {
                    return {
                        color: 'grey darken-4',
                        textColor: 'grey--text text--darken-4',
                        backGroundColor: 'accent2Light'
                    };
                }

                //basic read only
                return {
                    color: 'primary',
                    textColor: 'primary--text',
                    backGroundColor: 'primaryLight'
                };
            }
        },
        methods: {
            openPremiumFeaturesDialog(){
                EventBus.fire('show-premium-features-dialog');
            },
        }
    }
</script>
