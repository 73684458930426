var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "section-content-body",
      attrs: { id: "section-content_" + _vm.sectionId },
    },
    [
      !_vm.isXMLType()
        ? _c(
            "div",
            _vm._l(_vm.content, function (result, index) {
              return _c("div", { key: index }, [
                _c("p", { domProps: { innerHTML: _vm._s(result) } }),
              ])
            }),
            0
          )
        : _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.parseText(_vm.content, Boolean(_vm.cleanTitle(_vm.title)))
              ),
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }