var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "pdfContent",
    attrs: {
      src: "/static/secure.html?file=" + encodeURIComponent(this.pdfUrl),
      width: "100%",
      height: "500",
    },
    on: {
      load: function ($event) {
        return _vm.updateFrameHeight(_vm.$refs.pdfContent)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }