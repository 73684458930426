var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: _vm.isMobileAndTablet, "max-width": "500px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "font-weight-bold oxygen fs-24",
              class: {
                "caption mobile-title": _vm.isMobilePort,
              },
              style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
            },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "accent2" } },
                [_vm._v("\n                    mdi-creation\n                ")]
              ),
              _vm._v("\n                Premium Code Insights\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-0 pb-2" },
            [
              _c(
                "v-row",
                { staticClass: "grey lighten-4 ma-auto" },
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("img", {
                      staticClass: "my-1",
                      staticStyle: { width: "75%" },
                      attrs: { alt: "Code Insight", src: _vm.imageSrc },
                    }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "h2",
                              {
                                staticClass: "text-center my-2 mx-6",
                                staticStyle: { "line-height": "32px" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.category.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "overflow-hidden text-center mb-0 pa-4 py-0 tag-description",
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.category.description) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-bold",
                                  class: _vm.isTrailAvailable ? "mr-4" : "",
                                  attrs: {
                                    outlined: _vm.isTrailAvailable,
                                    depressed: "",
                                    color: _vm.isTrailAvailable
                                      ? "primary"
                                      : "accent2",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSubscribeToPremiumDialog()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Subscribe To Premium\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isLoggedIn || _vm.canStartTrial
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "px-5 fs-16 font-weight-bold",
                                      attrs: {
                                        large: "",
                                        color: "accent2",
                                        "max-height": "35",
                                        depressed: "",
                                        href: _vm.getRoute("trial_signup"),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Start Free Trial\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm.category?.learnMoreUrl &&
                              _vm.category?.learnMoreLabel
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-5 fs-16 mt-2",
                                      attrs: {
                                        large: "",
                                        text: "",
                                        href: _vm.category.learnMoreUrl,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.category.learnMoreLabel) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("v-progress-linear", { attrs: { indeterminate: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }