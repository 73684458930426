<template>
    <div class="disable-selection tag-icons">

        <v-tooltip
                v-if="tag === 'es'"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp"
                        v-on="on"
                        color="green lighten-4"
                        label>
                    <span class="text--primary">ES</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Evaluation Service</span>
        </v-tooltip>

        <v-tooltip
                v-if="tag === 'errata'"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp"
                        v-on="on"
                        color="red accent-1"
                        label>
                    <span class="text--primary">E</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Errata</span>
        </v-tooltip>

        <v-tooltip
                v-if="tag === 'premium'"
                color="black"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp text--primary"
                        v-on="on"
                        color="accent2Light"
                        label>
                    <span class="text--primary">INSIGHTS</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Premium Code Insights available at the end of the section</span>
        </v-tooltip>

        <v-tooltip
                v-if="tag === 'cdp'"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp"
                        v-on="on"
                        color="blue lighten-4"
                        label>
                    <span class="text--primary">CDP</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Code Development Proposal</span>
        </v-tooltip>

        <v-tooltip
                v-if="tag === 'srcc'"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp"
                        v-on="on"
                        color="purple lighten-3"
                        label>
                    <span class="text--primary">S</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Solar Rating and Certification Corporation</span>
        </v-tooltip>

        <v-tooltip
                v-if="tag === 'video'"
                open-delay="750"
                bottom>
            <template #activator="{ on }">
                <v-chip
                        x-small
                        class="px-1 cp"
                        v-on="on"
                        color="deep-orange lighten-2"
                        label>
                    <span class="text--primary">V</span>
                </v-chip>
            </template>
            <span class="tooltip-content">Video</span>
        </v-tooltip>

    </div>
</template>

<script>
    export default {
        props: {
            tag: {
                required: true,
                type: String
            }
        }
    }
</script>
