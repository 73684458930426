export class ObjectUtils {

    static nonReactive(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static dottedGet(obj, path, defaultValue = null) {

        let parts = path.split('.');

        const first = parts[0];

        if (typeof obj === 'object' && first in obj) {
            // more to go?
            if (parts.length > 1) {
                return this.dottedGet(obj[first], parts.slice(1).join('.'), defaultValue);
            }

            return obj[first];
        }

        return defaultValue;
    }
}
