<template>
    <v-row dense>
        <v-col cols="auto">
            <v-avatar
                size="32"
                color="grey lighten-3">
                {{ userNameInitials }}
            </v-avatar>
        </v-col>
        <v-col>
            <p class="caption font-weight-bold mb-0">
                {{ comment.name }} <span class="font-weight-regular text--secondary">{{ getFormattedDate(comment.date) }}</span>
            </p>
            <div
                class="text-body-2"
                v-html="comment.body">
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {DateUtil} from "../../../util/DateUtil";

    export default {
        props: {
            comment: {
                type: Object,
                required: true
            }
        },
        computed: {
            userNameInitials() {
                if (this.comment.name === 'ICC Team') {
                    return 'ICC';
                } else {
                    return this.comment.name.split(' ').map((n) => n.charAt(0)).join('');
                }
            }
        },
        methods: {
            getFormattedDate(d) {
                return DateUtil.timeToMdy(d);
            }
        }
    }
</script>