var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { height: _vm.isMobilePort ? "auto" : "270", outlined: "" } },
    [
      _c("h3", { staticClass: "pl-4 pt-3 title font-weight-bold" }, [
        _vm._v("\n        Your Managed Subscriptions\n    "),
      ]),
      _vm._v(" "),
      _vm.showActiveLicenses
        ? _c(
            "div",
            [
              _c(
                "v-card-title",
                { staticClass: "font-weight-bold subtitle-1" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(this.activeLicenses.totalActivelicenses) +
                      " active license(s) on " +
                      _vm._s(this.activeLicenses.totalActiveSubscriptions) +
                      " subscription(s)\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm.activeLicenses.licensesToRenew
                  ? _c(
                      "div",
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v(" mdi-autorenew "),
                        ]),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.activeLicenses.licensesToRenew) +
                            " license(s) set to auto-renew\n            "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeLicenses.licensesToExpire
                  ? _c(
                      "div",
                      { staticClass: "pt-3" },
                      [
                        _c("v-icon", { attrs: { color: "accent2" } }, [
                          _vm._v("mdi-autorenew-off"),
                        ]),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.activeLicenses.licensesToExpire) +
                            " license(s) set to expire\n            "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { class: [_vm.isActiveLicensesClass] },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pl-3 font-weight-bold",
                      attrs: { align: "start", "no-gutters": "" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-decoration-none",
                            attrs: {
                              href: _vm.getLicenseConfigUrl(),
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Configure Licenses\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-2 pb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none",
                              on: {
                                click: function ($event) {
                                  _vm.goToTheShop(
                                    _vm.getShopManagePaymentsPage(),
                                    true
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Manage Payment Options\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-card-text",
                { staticClass: "font-weight-medium subtitle-1" },
                [
                  _c("p", [
                    _vm._v("You don't have any active managed subscriptions."),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { class: [!_vm.isMobilePort ? ["mt-16", "pt-8"] : ""] },
                [
                  _vm.showStartTrialAdmin
                    ? _c("start-free-trial", {
                        staticClass: "mx-2",
                        attrs: {
                          disabled: _vm.remoteProfile,
                          large: false,
                          directStartTrial: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      class: _vm.isMobilePort && "mb-3",
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.goToRoute("premium_features")
                        },
                      },
                    },
                    [_vm._v("\n                Explore Premium\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }