var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _vm.isLoading
        ? _c("loading", { staticClass: "my-12" })
        : [
            _c(
              "v-card-text",
              { staticClass: "py-0" },
              [
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c("v-col", [
                      _c(
                        "a",
                        {
                          staticClass: "fs-16",
                          attrs: { href: _vm.getRoute("quick_consult") },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-n1",
                              attrs: { color: "primary", left: "" },
                            },
                            [
                              _vm._v(
                                "\n                            mdi-arrow-left\n                        "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                        Back to Quick Consult\n                    "
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.question?.status
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("status-chips", {
                              attrs: { status: _vm.question.status },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider"),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "", align: "center" } },
                          [
                            _c("v-col", [
                              _c("h2", [_vm._v(_vm._s(_vm.question?.title))]),
                            ]),
                            _vm._v(" "),
                            _vm.isAnswered
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.isDownloading,
                                          color: "primary",
                                        },
                                        on: { click: _vm.downloadAnswerPdf },
                                      },
                                      [
                                        _c("v-icon", { staticClass: "pr-1" }, [
                                          _vm._v(
                                            "\n                                    mdi-download-outline\n                                "
                                          ),
                                        ]),
                                        _vm._v(
                                          "\n                                Download Answer [PDF]\n                            "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text-caption text--secondary" },
                          [
                            _vm._v(
                              "Submitted " +
                                _vm._s(
                                  _vm.formatDate(_vm.question?.createdAt)
                                ) +
                                " | " +
                                _vm._s(_vm.question?.key)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "fs-16",
                          domProps: {
                            innerHTML: _vm._s(_vm.question?.description),
                          },
                        }),
                        _vm._v(" "),
                        _vm.question?.attachments.length
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v("Attachments"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.question.attachments,
                                  function (attachment) {
                                    return _c(
                                      "a",
                                      {
                                        key: attachment.id,
                                        staticClass:
                                          "text-decoration-none mr-3",
                                        attrs: {
                                          href: _vm.formatAttachmentUrl(
                                            attachment.id
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(attachment.name) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.question?.codeTitle
                          ? _c(
                              "div",
                              { class: _vm.question.codeSection ? "mb-4" : "" },
                              [
                                _c("h3", { staticClass: "mb-2" }, [
                                  _vm._v("Code Title"),
                                ]),
                                _vm._v(" "),
                                _c("p", {
                                  staticClass: "mb-1",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.question.codeTitle),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.question?.codeSection
                          ? _c("div", [
                              _c("h3", { staticClass: "mb-2" }, [
                                _vm._v("Code Section"),
                              ]),
                              _vm._v(" "),
                              _c("p", {
                                staticClass: "mb-1",
                                domProps: {
                                  innerHTML: _vm._s(_vm.question.codeSection),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAnswered
                          ? _c(
                              "div",
                              {
                                staticClass: "w-full mt-5 px-3",
                                staticStyle: {
                                  "border-left": "4px solid #ffa201",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-3",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "mb-0 mr-3 fs-20" },
                                      [_vm._v("Answer from ICC Team")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", {
                                    staticClass: "fs-16",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.question.answer),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.questionWebLinks.length !== 0
                                    ? _c(
                                        "div",
                                        { staticClass: "my-4" },
                                        [
                                          _c("h3", { staticClass: "pb-2" }, [
                                            _vm._v("References"),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.questionWebLinks,
                                            function (item) {
                                              return _c(
                                                "div",
                                                { key: item.id },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: item.object.url,
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            item.object.title
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "disclaimer caption text--secondary",
                                      staticStyle: { "line-height": "18px" },
                                    },
                                    [
                                      _c("p", { staticClass: "mb-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Disclaimer")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                    Code opinions issued by International Code Council (“ICC”) staff as part of its Quick Consult Service or otherwise are based on ICC-published codes and do not include or consider local, state or federal codes, policies or amendments. This opinion is based on the information which you have provided to ICC. We have made no independent effort to verify the accuracy of this information nor have we conducted a review beyond the scope of your question. This opinion does not imply approval of an equivalency, specific product, specific design, or specific installation and cannot be published in any form implying such approval by ICC. As this opinion is only advisory, the final decision is the responsibility of the designated authority charged with the administration and enforcement of the applicable code.\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                    ICC will make reasonable efforts to provide accurate information as part of any code opinion.  However, ICC makes no guarantees or warranties, express or implied, as to the accuracy of any information provided, including, without limitation, any warranties of merchantability or fitness for a particular purpose.  ICC will not be held liable for any damages or loss, whether direct, indirect, consequential, or punitive, that may arise through your use of any code opinion.\n                                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mt-5" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            loading: _vm.isDownloading,
                                            color: "primary",
                                          },
                                          on: { click: _vm.downloadAnswerPdf },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "pr-1" },
                                            [
                                              _vm._v(
                                                "\n                                        mdi-download-outline\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                                    Download Answer [PDF]\n                                "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.question
                  ? _c("comments-wrapper", {
                      attrs: {
                        "question-id": _vm.question.id,
                        comments: _vm.question.comments,
                        "can-comment": _vm.question.canComment,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }