<template>
    <div class="grey lighten-5 pt-6">
        <v-container
            class="content-container">
            <section-heading
                heading="Digital Codes Premium subscription options"
                subHeading="Subscribe to Premium and accelerate your code compliance workflow.">
            </section-heading>
            <v-row>
                <v-col class="text-center pt-0">
                    <p>
                        <a
                            class="pr-10 fs-14 font-weight-bold"
                            target="_blank"
                            :href="this.getRoute('premium_features')">Learn about Premium</a>
                        <a
                            class="fs-14 font-weight-bold"
                            :href="`mailto:${this.$getConst('sales_email_address')}`">Talk to Sales</a>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(item, key) in priceBlocks"
                    :key="key">
                    <v-sheet
                        class="fill-height rounded-lg"
                        rounded
                        elevation="4"
                        :style="item.complete ?
                            `border: 4px solid ${$vuetify.theme.themes.light.accent2}` :
                            `border: 1px solid ${$vuetify.theme.themes.light.primaryMedium}`">
                        <v-card-text class="pa-6" :class="item.complete ? 'py-4' : ''">
                            <div class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon color="accent2">
                                        mdi-creation
                                    </v-icon>
                                    <span class="fs-24 font-weight-bold">{{ item.title }}</span>
                                </div>
                                <v-chip
                                    class="font-weight-bold"
                                    v-if="item.complete"
                                    color="accent2Light">
                                    Most Popular
                                </v-chip>
                            </div>
                            <p class="fs-16 font-weight-medium my-6">
                                {{item.description}}
                            </p>
                            <p class="fs-16 grey--text text--darken-1">
                                Starting at
                            </p>
                            <p>
                                <span class="fs-40 font-weight-bold">${{item.price}}</span> / month
                            </p>
                            <ul class="list-none pl-0">
                                <li v-for="(item, key) in item.list"
                                :key="key">
                                    <v-row dense>
                                        <v-col cols="auto">
                                            <v-icon
                                            color="teal">
                                            mdi-check-circle-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col class="px-2">
                                            <p class="mb-0 fs-14 font-weight-medium">
                                            {{item}}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </li>
                            </ul>
                            <div class="text-center">
                                <v-btn
                                    class="mt-10 font-weight-bold"
                                    :outlined="!item.complete"
                                    block
                                    v-if="item.button"
                                    :color="item.complete ? 'accent2' : 'primary'"
                                    large
                                    @click="redirectPricingTable(item.title)">
                                    {{item.button.text}}
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-sheet>
                </v-col>
            </v-row>
            <basic-teams-block></basic-teams-block>
        </v-container>
        <trial-block v-if="!isActiveUser || canStartTrial" class="my-8"></trial-block>
    </div>
</template>
<script>
import SectionHeading from '../SectionHeading';
import BasicTeamsBlock from './BasicTeamsBlock';
import TrialBlock from './TrialBlock';
import { AppParameters } from '../../AppParameters';
import mobile from '../inc/mixins/mobile';

export default {
    components: {SectionHeading, BasicTeamsBlock, TrialBlock},
    mixins: [mobile],
    data(){
        return {
            priceBlocks: [
                {
                    title: 'Singles',
                    description: 'Subscribe only to the individual title or titles needed most',
                    price: AppParameters.single_monthly_price,
                    list: [
                        'All premium features included with subscribed title',
                        'Billed monthly, annually or every 3-years',
                        'Bill frequency, license quantity and membership discounts available'
                    ],
                    button: {
                        text: 'Select Titles',
                        link: this.getRoute('codes')
                    }
                },
                {
                    title: 'Collections',
                    description: 'Subscribe to a bundled set of related titles for a given topic or cycle',
                    price: AppParameters.collection_monthly_price,
                    list: [
                        'All premium features included with subscribed collection',
                        'Billed monthly, annually or every 3-years',
                        'Bill frequency, license quantity and membership discounts available'
                    ],
                    button: {
                        text: 'Select Collection',
                        link: this.getRoute('codes_category', {category: 'collections'})
                    }
                },
                {
                    title: 'Complete',
                    description: `Unlock Premium on ${this.premiumCompleteTitlesCount()}+ titles and features all in one subscription`,
                    price: AppParameters.complete_monthly_price,
                    list: [
                        'All premium features included with Complete subscription',
                        'Billed monthly, annually or every 3-years',
                        'Bill frequency, license quantity and membership discounts available'
                    ],
                    button: {
                        text: 'Subscribe Now',
                        link: 'collections/premium-complete'
                    },
                    complete: true
                }
            ]
        }
    },
    methods: {
        redirectPricingTable(title){
            EventBus.fire('redirect-activate-pricing-table', title);
            EventBus.fire('open-premium-bundle');
        }
    }

}
</script>