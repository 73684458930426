<template>
    <v-navigation-drawer
        temporary
        absolute
        right
        :width="isMediumAndUp ? '50%' : '100%'"
        v-model="snackbar">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="auto">
                        <h3 class="primary--text">
                            <v-icon class="mr-2 primary--text">
                                mdi-account-plus
                            </v-icon> Add User
                        </h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <v-icon
                            small
                            class="accent2--text"
                            @click="closeModal()">
                            close
                        </v-icon>
                    </v-col>           
                </v-row>
                <v-row>
                    <v-col>
                        <status-alert :alert="statusAlert"></status-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <v-label>
                            First Name
                        </v-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            class="rounded-xl lighten-3"
                            dense
                            outlined
                            hide-details
                            v-model="user.firstName"
                            label="Enter first name">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <v-label>
                            Last Name
                        </v-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            class="rounded-xl"
                            dense
                            outlined
                            hide-details
                            v-model="user.lastName"
                            label="Enter last name">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <v-label>
                            Email Address
                        </v-label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            class="rounded-xl"
                            dense
                            outlined
                            hide-details
                            v-model="user.email"
                            label="Enter email address">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <v-btn
                            block
                            small
                            dark
                            color="accent2"
                            @click="assignUser()">
                            Add
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            block
                            small
                            dark
                            color="primary"
                            @click="closeModal()">
                            Close
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import axios from "axios";
    import StatusAlert from '../../../admin/enterprise/partials/status_alert';
    import Mobile from "../../../inc/mixins/mobile";

    export default {
        mixins: [Mobile],
        data() {
            return {
                snackbar: false,
                manualAdd: true,
                request: null,
                loading: false,
                entId: 1, // Adding this static value till we have enterprise id
                filters: {
                    firstName: '',
                    lastName: '',
                    email: ''
                },
                headers: [
                    {text: 'ID', align: 'left', value: 'id'},
                    {text: 'First Name', align: 'left', value: 'first_name'},
                    {text: 'Last Name', align: 'left', value: 'last_name'},
                    {text: 'Email', align: 'left', value: 'email'},
                    {text: 'Verified', align: 'left', value: 'email_verified'},
                    {text: 'Action', align: 'center', sortable: false, value: null}
                ],
                user: {
                    firstName: '',
                    lastName: '',
                    email: ''
                },
                users: [],
                totalUsers: 0,
                statusAlert: {
                    type: 'success',
                    message: ''
                },
            }
        },
        components: {
            StatusAlert
        },
        methods: {
            showModal(enterpriseId) {
                this.snackbar = true;
                this.entId = enterpriseId;
            },
            closeModal() {
                this.clearRequest();
                this.snackbar = false;
            },
            handleError(message = 'Sorry, something went wrong') {
                this.statusAlert.message = message;
                this.statusAlert.type = 'error';
            },
            handleSuccess(message = 'Successfully sent an enterprise invite to the user.') {
                this.statusAlert.message = message;
                this.statusAlert.type = 'success';
            },
            clearRequest() {
                if (this.request) {
                    this.request.cancel();
                    this.request = null;
                }
            },
            assignUser() {
                if ('' !== this.user.email
                    && '' !== this.user.firstName
                    && '' !== this.user.lastName) {
                    this.clearRequest();
                    this.loading = true;
                    let axiosSource = axios.CancelToken.source();
                    this.request = { cancel: axiosSource.cancel};
                    axios.put(Routing.generate('enterprise_user_invite'), {
                        user: this.user,
                        enterpriseId: this.entId,
                        cancelToken: axiosSource.token
                    }).then((response) => {
                        this.statusAlert.message = '';
                        if (response.data && response.data.invitationId) {
                            this.user = _.mapKeys(response.data.user,
                                function(value, key) {
                                    return  _.snakeCase(key);
                                }
                            );
                            EventBus.fire('getUsers', this.entId);
                            this.loading = false;
                            this.handleSuccess();
                        } else {
                            this.handleError(response.data.message);
                        }
                    }).catch(this.handleError);
                } else {
                    this.handleError('Please enter firstname, lastname and email to add user');
                }                
            },
        },
        created() {
            EventBus.listen('userModal', (enterpriseId) => this.showModal(enterpriseId));
        }
    }
</script>
