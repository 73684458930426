<template>
    <v-card
        flat
        class="accent2-top-border">
        <img
            width="100%"
            alt=""
            :src="asset(require('../../../images/pc_page/pc_computer.png'))"/>
        <v-card-text>
            <ul class="list-none pl-0">
                <li v-for="(item, key) in info"
                :key="key">
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-icon color="accent2">
                                {{item.icon}}
                            </v-icon>
                        </v-col>
                        <v-col>
                            <p class="mb-0 primary--text font-weight-bold">
                            {{item.text}}
                        </p>
                </v-col>
            </v-row>
                </li>
            </ul>
            <v-row>
                <v-col>
                    <v-btn
                        block
                        dark
                        color="accent2"
                        @click="goToRoute('premium_features')">
                        Learn More
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                info: [
                    {
                        icon: 'mdi-magnify',
                        text: 'Advanced Search across Premium Codes, Standards and More'
                    },
                    {
                        icon: 'mdi-database-search',
                        text: 'Offline View (Mobile App Only)'
                    },
                    {
                        icon: 'mdi-checkbox-marked-circle-outline',
                        text: 'Code Interpretations'
                    },
                    {
                        icon: 'mdi-table-check',
                        text: 'Revision History Tagging'
                    },
                    {
                        icon: 'mdi-restore-alert',
                        text: 'Code Change Proposal (cdpACCESS)'
                    },
                    {
                        icon: 'mdi-clipboard-check-outline',
                        text: 'Organize Notes'
                    },
                ]
            }
        }
    }
</script>
