var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-bottom-navigation",
        {
          attrs: {
            value: true,
            app: "",
            "background-color": "white",
            grow: "",
          },
        },
        [
          _vm.from_search
            ? _c(
                "v-btn",
                {
                  staticClass: "hidden-md-and-up",
                  attrs: { block: "", onclick: "history.go(-1);return false;" },
                },
                [
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v("GO BACK"),
                  ]),
                  _vm._v(" "),
                  _c("v-icon", { staticClass: "primary--text" }, [
                    _vm._v("navigate_before"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filters
            ? _c(
                "v-btn",
                {
                  staticClass: "hidden-md-and-up",
                  attrs: { text: "", id: "someId" },
                  on: { click: _vm.openFilterModal },
                },
                [
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v("Search Filters"),
                  ]),
                  _vm._v(" "),
                  _c("v-icon", { staticClass: "primary--text" }, [
                    _vm._v("search"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.book_content
            ? _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.triggerSmartIndex } },
                [
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v("Contents"),
                  ]),
                  _vm._v(" "),
                  _c("v-icon", { staticClass: "primary--text" }, [
                    _vm._v("menu"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", id: "feedback_button" },
              on: { click: _vm.showFeedbackModal },
            },
            [
              _c("span", { staticClass: "black--text" }, [_vm._v("Feedback")]),
              _vm._v(" "),
              _c("v-icon", { staticClass: "primary--text" }, [
                _vm._v("feedback"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", id: "phplive_btn_1496167627" },
              on: { click: _vm.initLiveChat },
            },
            [
              _c("span", { staticClass: "black--text" }, [_vm._v("Live Chat")]),
              _vm._v(" "),
              _c("v-icon", { staticClass: "primary--text" }, [_vm._v("chat")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }