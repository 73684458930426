var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chip",
    { attrs: { color: _vm.statusRender.color } },
    [
      _c("v-icon", { attrs: { color: _vm.statusRender.iconColor, left: "" } }, [
        _vm._v("\n        " + _vm._s(_vm.statusRender.icon) + "\n    "),
      ]),
      _vm._v("\n    " + _vm._s(_vm.statusRender.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }