<template>
    <v-row
        class="exam-feature-container"
        :class="{
            'px-16': !isMobilePort,
            'py-10': !isMobilePort,
            'py-8': isMobilePort
        }"
        >
        <v-col>
            <v-row>
                <v-col
                    class="text-center px-20"
                    >
                    <h1
                        class="font-weight-regular mb-5 roboto"
                        v-html="sectionData.title">
                    </h1>
                    <h2
                        v-if="sectionData.description"
                        class="font-weight-regular"
                        v-html="sectionData.description">
                    </h2>
                    
                </v-col>
            </v-row>
            <v-row v-if="sectionData.features.length && sectionData.displayType === 'list' || isMobilePort">
                <v-col 
                    :cols="!isMobilePort ? 6 : 12"
                    v-for="feature in sectionData.features" 
                    :key="feature.title">
                    <v-row align="center">
                        <v-col cols="auto">
                            <img
                                width="50"
                                :src="asset(require('../../../images/' + feature.src), 'default')"/>
                        </v-col>
                        <v-col>
                            <h4 class="mb-3 primary--text">
                               {{ feature.title }}
                            </h4>
                            <p class="mb-0">
                                {{ feature.description }}
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>        
            <v-row v-if="sectionData.features.length && sectionData.displayType === 'tile' && !isMobilePort">
                <v-col 
                    cols="4" 
                    v-for="feature in sectionData.features" 
                    :key="feature.title">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <img
                                width="50"
                                :src="asset(require('../../../images/' + feature.src), 'default')"/>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col class="text-center">
                            <h4 class="mb-3 primary--text">
                                {{ feature.title }}
                            </h4>
                            <p class="mb-0">
                                {{ feature.description }}
                            </p>    
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>    
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        props: {
            sectionData: {
                required: true,
                type: Object
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style
    lang="scss"
    scoped>
    .exam-feature-container {
        padding-bottom: 0px !important;
        img {
            max-width: 100%;
        }
        .oxygen {
            font-family: Oxygen;
        }
        @media (min-width: 641px) {
            h1 {
                font-size: 48px;
                line-height: 56px;
            }
            h2 {
                font-size: 24px;
                line-height: 39px;
            }
        }
    }
</style>
