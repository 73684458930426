import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    data: function() {
        return {
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                ["blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["image", "video", "formula"],
            ]
        }
    }
}
