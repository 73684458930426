var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "oxygen mb-2 fs-14 font-weight-bold" }, [
      _vm._v("User Acknowledgement and Disclaimer"),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "fs-14 mb-0" }, [
      _vm._v(
        '\n        By acknowledging these terms ["Acknowledge" confirmed before your first use], you agree and understand\n        that your use of ICC AI Navigator’s features and outputs is at your own risk. You further confirm that\n        you understand and agree to all terms of '
      ),
      _c(
        "a",
        {
          staticClass:
            "primary--text font-weight-bold text-decoration-underline",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.showDisclaimer.apply(null, arguments)
            },
          },
        },
        [_vm._v("this\n        disclaimer")]
      ),
      _vm._v(".\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }