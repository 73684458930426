var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", [
        _c("h1", { staticClass: "text-center oxygen" }, [
          _vm._v(_vm._s(_vm.heading)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-center pt-2" }, [
          _vm._v(_vm._s(_vm.subHeading)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }