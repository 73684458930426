<template>
    <div class="grey lighten-4">
        <v-container class="content-container">
            <v-row>
                <v-col>
                    <h2 class="mb-4 roboto">
                        Testimonials
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(item, key) in testimonials"
                    :key="key">
                    <div class="mx-15">
                        <blockquote class="quoted">
                            {{item.text}}
                        </blockquote>
                        <p class="font-weight-bold mb-0 mt-3 caption">
                            {{item.name}}
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        props: {
            testimonials: {
                required: true,
                type: Array
            }
        }
    }
</script>
