<template>
    <v-row dense>
        <v-col>
            <v-text-field
                clearable
                solo
                hide-details
                @click="onChange"
                @input="onChange"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter="onEnter"
                v-model="search"
                label="Search Code Titles">
            </v-text-field>
            <SuggestedTitles
                v-show="isOpen"
                :results="results"
                :isLoading="isLoading"
                :search="search"
                :fromFavorites="true"
                position="header">
            </SuggestedTitles>
        </v-col>
    </v-row>
</template>

<script>
    import Autocomplete from '../inc/mixins/autocomplete.js';
    import SuggestedTitles from '../autocomplete/parts/suggestedTitles';

    export default {
        components:{
            SuggestedTitles
        },
        mixins: [Autocomplete],
        created() {
            this.search = '';
            EventBus.listen("update-favorites", () => { this.search = ''; this.isOpen = false; });
        }
    }
</script>
