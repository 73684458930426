var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: "12", lg: "6" },
            },
            [
              _c("div", [
                _c(
                  "h1",
                  { staticClass: "white--text oxygen font-weight-light mb-8" },
                  [
                    _vm._v(
                      "\n                    The Most Trusted and Authentic Source of Building Codes, Standards and More\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  { staticClass: "white--text oxygen font-weight-light mb-8" },
                  [
                    _vm._v("\n                    Go Beyond the "),
                    _c("strong", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "Codes with Digital Codes Premium\n                    Complete"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-uppercase accent2--text font-weight-bold roboto",
                  },
                  [
                    _vm._v(
                      "\n                    Unlock all contents and features to enhance code compliance research with the most\n                    comprehensive subscription platform.\n                "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "text-center d-none d-lg-block",
              attrs: { cols: "6" },
            },
            [
              _c("img", {
                attrs: {
                  src: this.asset(require("../../images/category_search.png")),
                  height: "500",
                  width: "500",
                  alt: "Graphic depicting two business people performing a search.",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "justify-lg": "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              !_vm.hasCompleteSubscription || _vm.trialActive
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-5 roboto",
                      attrs: {
                        dark: "",
                        color: "accent2",
                        "x-large": _vm.isLargeAndUp,
                      },
                      on: { click: _vm.premiumSubscribe },
                    },
                    [
                      _vm._v(
                        "\n                Subscribe to Premium Complete\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.trialStarted && !_vm.hasCompleteSubscription
                ? _c(
                    "v-btn",
                    {
                      staticClass: "cdpBlue mr-5 roboto",
                      attrs: {
                        "data-qa": "landing-begin-trial",
                        dark: "",
                        "x-large": _vm.isLargeAndUp,
                      },
                      on: { click: _vm.trialClick },
                    },
                    [
                      _vm._v(
                        "\n                Start Free 14-day Premium Complete Trial\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "roboto",
                  attrs: { "x-large": _vm.isLargeAndUp },
                  on: { click: _vm.learnMoreClick },
                },
                [_vm._v("\n                Learn More\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }