var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("breadcrumb", { attrs: { title: "Quick Access" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "font-weight-regular primary--text" }, [
                  _vm._v(
                    "\n                    Quick Access to Section Specific Requirements\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { lg: "8" } }, [
                _c("p", { staticClass: "font-weight mb-0" }, [
                  _vm._v(
                    "\n                    View full section specific contents from your Digital Codes Premium titles\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h3", { staticClass: "font-weight-regular primary--text" }, [
                  _vm._v(
                    "\n                    Step 1: Enter year and title for desired contents (ex. “2021 IBC”)\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.titles,
                      loading: _vm.titlesLoading,
                      "search-input": _vm.titleSearch,
                      "no-filter": "",
                      outlined: "",
                      "hide-no-data": "",
                      clearable: "",
                      "hide-details": "",
                      "append-icon": "",
                      "item-text": "display_title",
                      "item-value": "document_id",
                      placeholder: "Start typing to Search",
                      "return-object": "",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.titleSearch = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.titleSearch = $event
                      },
                      keydown: _vm.debouncedGetResults,
                      change: _vm.getVersions,
                      focus: _vm.focusGained,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c("title-list", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                title: item,
                                type: "list",
                                showLinkIcon: false,
                                listOutline: false,
                                preventLink: true,
                                hover: false,
                                background: "transparent",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.selected
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-select", {
                        staticStyle: { "max-width": "300px" },
                        attrs: {
                          items: _vm.printings,
                          outlined: "",
                          "hide-details": "",
                          "return-object": "",
                          "item-value": "document_id",
                          loading: _vm.printingsLoading,
                          "menu-props": { bottom: true, offsetY: true },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.getBookPrintingInfo(item)) +
                                      "\n                    "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.getBookPrintingInfo(item)) +
                                      "\n                    "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1871262812
                        ),
                        model: {
                          value: _vm.version,
                          callback: function ($$v) {
                            _vm.version = $$v
                          },
                          expression: "version",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                    The most recent version of the selected title will be accessed."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Please select any previous version above, if desired.\n                "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c("v-col", [
                _c("h3", { staticClass: "font-weight-regular primary--text" }, [
                  _vm._v(
                    "\n                    Step 2: Enter the specific section number (ex. “510.1”)\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { id: "sectionNo" } },
            [
              _c(
                "v-col",
                { attrs: { lg: "6" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.sections,
                      "search-input": _vm.sectionSearch,
                      outlined: "",
                      clearable: "",
                      "hide-details": "",
                      "hide-no-data": "",
                      "hide-selected": "",
                      "append-icon": "",
                      "item-text": "sectiontitle",
                      loading: _vm.sectionsLoading,
                      disabled: !_vm.version || !_vm.selected,
                      placeholder: "Start typing to Search",
                      "return-object": "",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.sectionSearch = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.sectionSearch = $event
                      },
                      keypress: _vm.debouncedGetSections,
                      change: _vm.getSectionContent,
                      "click:clear": _vm.clearSections,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.sectionappendix) +
                                      _vm._s(item.sectiontitle) +
                                      "\n                            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedSection,
                      callback: function ($$v) {
                        _vm.selectedSection = $$v
                      },
                      expression: "selectedSection",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("notes"),
          _vm._v(" "),
          _c("section-content", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedSection,
                expression: "selectedSection",
              },
            ],
          }),
          _vm._v(" "),
          _vm.recentlyAccessed && _vm.recentlyAccessed.length
            ? [
                _c(
                  "v-row",
                  {
                    class: {
                      "mt-1": _vm.isMobilePort,
                      "mt-2": !_vm.isMobilePort,
                    },
                  },
                  [
                    _c("v-col", [
                      _c(
                        "h3",
                        { staticClass: "font-weight-regular primary--text" },
                        [
                          _vm._v(
                            "\n                        Recently Quick Accessed Sections\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { lg: "8" } }, [
                      _c("p", { staticClass: "font-weight-regular" }, [
                        _vm._v(
                          "\n                        Click to view full section on page\n                    "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  _vm._l(_vm.recentlyAccessed, function (item, key) {
                    return _c(
                      "v-col",
                      { key: key, attrs: { cols: "12", sm: "6" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "fill-height recentlyAccess",
                            style:
                              "border: 1px solid " +
                              _vm.$vuetify.theme.themes.light.primary,
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.getSections(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "primary--text" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("v-col", [
                                      _c("h3", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.sectionTitle) +
                                            "\n                                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.getBookTitle(item.document)
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "pr-5",
                                            attrs: { "x-large": "" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        mdi-chevron-right\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }