var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hideBanner
    ? _c(
        "v-system-bar",
        {
          ref: "systemBar",
          staticClass: "pa-0",
          attrs: {
            id: "app-system-bar",
            app: "",
            fixed: "",
            window: "",
            color: "accent2Light",
            height: "auto",
          },
        },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("switch-user-banner"),
              _vm._v(" "),
              _c("system-notification-banner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.systemMessageEnabled,
                    expression: "systemMessageEnabled",
                  },
                ],
                attrs: { message: _vm.systemMessage },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }