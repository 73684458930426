export const NavigationModule = {
    state: {
        showSuggestedTitles: false,
        suggestedTitles: {}
    },
    getters: {
        getShowSuggestedTitles: (state) => state.showSuggestedTitles,
        getSuggestedTitles: (state) => state.suggestedTitles,
    },
    mutations: {
        setShowSuggestedTitles (state, show) {
            state.showSuggestedTitles = show;
        },
        setSuggestedTitles (state, suggestedTitles) {
            state.suggestedTitles = suggestedTitles;
        },
    }
}
