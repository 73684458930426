const routes = require("../../js/fos_js_routes.json");
import Routing from "../../../public/bundles/fosjsrouting/js/router.min.js";
Routing.setRoutingData(routes);

export const LegendModule = {
    state: {
        activeChapter: 0,
        isXML: true,
        tocMini: false,

        // Old
        chapters: {},
        chapter_sections: {},
        active_chapter: 0,
        active_section: 0,
        selected_content_id: 0,
        active_hash: null,
        expanded_active: [],
        tags: {},
        notes: {},
        isLanding: false,
        focusedTocSection: null
    },
    getters: {
        getActiveChapter: (state) => state.activeChapter,
        getIsXML: (state) => state.isXML,
        getTocMini: (state) => state.tocMini,

        // Old
        getChapters     : (state) => state.chapters,
        getChapterSections: (state) => state.chapter_sections,
        getActiveSection: (state) => state.active_section,
        getSelectedContentId: (state) => state.selected_content_id,
        getActiveHash   : (state) => state.active_hash,
        getExpandedActive: (state) => state.expanded_active,
        getTags         : (state) => state.tags,
        getNotes        : (state) => state.notes,
        getIsLanding    : (state) => state.isLanding,
        getFocusedTocSection    : (state) => state.focusedTocSection,
    },
    mutations: {
        setActiveChapter(state, id) {
            state.activeChapter = id;
        },
        setIsXML(state, bool) {
            state.isXML = bool
        },
        setTocMini(state, bool) {
            state.tocMini = bool;
        },

        // Old
        setChapters (state, chapters) {
            state.chapters = chapters;
        },
        setChapterSections (state, chapter_sections) {
            if(typeof chapter_sections !== "undefined")
            {
                state.chapter_sections = chapter_sections;
            }
        },
        setActiveSection (state, id) {
            state.active_section = id;
        },
        setSelectedContentId (state, id) {
            state.selected_content_id = id;
        },
        setActiveHash (state, hash) {
            state.active_hash = hash;
        },
        setExpandedActive (state, info) {
            state.expanded_active = info;
        },
        addExpandedActive (state, info) {
            state.expanded_active.push(info);
        },
        editExpandedActive (state, id) {
            let expand_active_item = _.find(state.expanded_active, {content_id: _.toNumber(id)});
            if (expand_active_item) {
                expand_active_item.active = !expand_active_item.active;
            }
        },
        unexpandAll (state) {
            state.expanded_active.forEach(function(item) {
                item.active = false;
            });
        },
        setTags (state, info) {
            state.tags = info;
        },
        setNotes (state, info) {
            state.notes = info;
        },
        setIsLanding (state, info) {
            state.isLanding = info;
        },
        setFocusedSection(state, contentId){
            state.focusedTocSection = contentId;
        }
    }
};
