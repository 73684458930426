var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("actionable-alert", {
    attrs: {
      visible: _vm.isVisible && !_vm.isIgnoring,
      "action-text": "Yes",
      "dismiss-text": "No, Dismiss",
    },
    on: {
      onAction: _vm.visitMostRecentVersion,
      onDismiss: _vm.dismissSnackbar,
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c(
              "v-icon",
              { staticClass: "mr-1", attrs: { color: "blue darken-4" } },
              [_vm._v("mdi-information-outline")]
            ),
            _vm._v(
              "\n        You are viewing a Historical version of this title. Do you want to view the most recent version?\n    "
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }