var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.activeCategory
      ? _c(
          "div",
          [
            _c("back-to-main-menu", { on: { backToMain: _vm.backToMain } }),
            _vm._v(" "),
            _c("v-list-item", { staticClass: "mt-2" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.browseContentsText) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("link-list-icons", {
              attrs: { "hide-icon": "", links: _vm.links },
              on: { updateComponent: (data) => (_vm.activeCategory = data) },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "back-to-main-menu",
              {
                on: {
                  backToMain: function ($event) {
                    _vm.activeCategory = null
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.browseContentsText) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.activeItem.hideBrowseAll
              ? _c(
                  "v-list-item",
                  { attrs: { href: `/codes/${_vm.activeItem.slug}` } },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "fs-16 font-weight-bold" },
                      [
                        _vm._v(
                          "\n                Browse All " +
                            _vm._s(_vm.activeItem.title) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("v-treeview", {
              ref: "treeview",
              attrs: {
                activatable: "",
                items: _vm.activeItem.children,
                "item-key": "slug",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function ({ item }) {
                      return item.name
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.redirectToCodes(item)
                                },
                              },
                            },
                            [
                              _c("a", { staticClass: "accent--text" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.name) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }