var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.documentInfo?.base_code_info
        ? _c(
            "a",
            {
              staticClass: "grey--text text--darken-3",
              on: {
                click: function ($event) {
                  return _vm.redirectToBaseTitle(
                    _vm.documentInfo?.base_code_info.document_slug
                  )
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.documentInfo?.base_code_info.year) +
                  " " +
                  _vm._s(_vm.documentInfo?.base_code_info.short_title) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.documentInfo.custom_code_profile ||
      (_vm.documentInfo.adoption_notes &&
        _vm.documentInfo.adoption_notes !== "") ||
      _vm.documentInfo.custom_code_profile
        ? _c(
            "p",
            { staticClass: "mb-1 fs-14 d-inline-block" },
            [
              _vm.documentInfo.custom_code_profile &&
              _vm.documentInfo.custom_code_profile.value ===
                "amended_not_integrated"
                ? [
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-underline",
                        attrs: { href: _vm.documentInfo.amendment_url },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.documentInfo.custom_code_profile.label)
                        ),
                      ]
                    ),
                  ]
                : _vm.documentInfo.custom_code_profile
                ? [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        _vm._s(_vm.documentInfo.custom_code_profile.label)
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.documentInfo.adoption_notes &&
              _vm.documentInfo.adoption_notes !== ""
                ? _c(
                    "v-icon",
                    {
                      staticClass: "pl-1",
                      attrs: { color: "primary", small: "" },
                      on: { click: _vm.dislayAdoptionNote },
                    },
                    [_vm._v("mdi-help-circle-outline\n        ")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.documentInfo.effective_date
        ? _c("p", { staticClass: "mb-0 fs-14 grey--text text--darken-3" }, [
            _vm._v(
              "Effective Date: " + _vm._s(_vm.documentInfo.effective_date)
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "confirm-dialog",
        {
          attrs: {
            "is-open": _vm.showCancelConfirmDialog,
            title: "Adoption Note",
            "can-be-closed": true,
            width: 1250,
          },
          on: {
            closed: function ($event) {
              _vm.showCancelConfirmDialog = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-full d-flex justify-center" },
                    [
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.showCancelConfirmDialog = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Close\n                    "
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", {
            staticClass: "mt-4",
            domProps: { innerHTML: _vm._s(_vm.documentInfo.adoption_notes) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }