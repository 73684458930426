<template>
    <v-dialog
        v-model="data.show"
        width="600">
        <v-card v-if="data.astmError">
            <v-card-title
                class="accent2 white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                <v-spacer></v-spacer>
                <v-icon medium class="mr-2 white--text" @click="closeModal">close</v-icon>
            </v-card-title>
            <v-card-text 
                v-if="data.astmError"
                class="pb-0">
                <v-row>
                    <v-col class="text-center">                    
                        <img 
                            class="astm-image mt-n9"
                            :src="asset(require('../../images/astm-logo.png'), 'default')"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <h3 class="mb-0">Error: {{ data.errorMessage }}</h3>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title
                class="accent2 white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                <v-spacer></v-spacer>
                <v-icon medium class="mr-2 white--text" @click="closeModal">close</v-icon>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col class="text-center">                    
                        <img 
                            class="astm-image mt-n9"
                            :src="asset(require('../../images/astm-logo.png'), 'default')"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <h3 class="mb-0">We're taking you to your content now. You will be redirected to the ASTM International website.</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <p class="mb-0">By clicking OK below, you may receive email information from ASTM International.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            class="primary white--text"
                            text
                            @click="astmAccessHits">
                            OK
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-row>
                    <v-col cols="6" class="text-left">
                        <v-btn
                            class="grey--text font-weight-bold"
                            text
                            @click="closeModal">
                            Cancel
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn
                            class="grey--text font-weight-bold"
                            text>
                            <v-checkbox
                                color="accent2"
                                v-model="checkbox"
                            ></v-checkbox>Do not show this again
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                checkbox: false,
                data: {
                    show: false,
                    astmError: false
                }
            };
        },
        mixins: [Mobile],
        methods: {
            showAstmModal(data) {
                this.data = data;               
            },
            closeModal() {
                this.data = {show: false, astmError: false};
            },
            astmAccessHits() {
                if (this.checkbox) {
                    this.checkbox = this.checkbox ? 0 : 1;
                    this.$http.put(Routing.generate('put_astm_modal_pref', {
                        'preference': this.checkbox
                    })).then();
                    this.$session.set('showAstmModal', this.checkbox);
                }
                this.getAstmToken(this.data.astmRefStd, this.data.sectionNumber);
                this.data = {show: false, astmError: false};
            },
            showError(data) {
                this.data.errorMessage = data.errorMessage;
                this.data.astmError = true;
                this.data.show = true;
            }
        },
        created() {
            EventBus.listen("astmModal", (data) => this.showAstmModal(data));
            EventBus.listen("onAstmTokenError", (data) => this.showError(data));
        }
    };

</script>
<style>
.astm-image {
    border-radius: 50% 50% 0 0;
}
</style>
