var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" } },
    [
      _c("smart-index", {
        attrs: { documentInfo: _vm.activeDocumentInfo, "exam-id": _vm.examId },
      }),
      _vm._v(" "),
      !_vm.isCollectionOrComplete
        ? _c("chapter-header", {
            attrs: {
              "document-info": _vm.documentInfo,
              "show-exam": "" !== _vm.examId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        {
          staticClass: "ma-auto",
          class:
            !_vm.isCollectionOrComplete && !_vm.isFullscreenContent
              ? "max-content-container"
              : "",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0",
                  style: _vm.isMobileAndTablet
                    ? "width: 100%;"
                    : "width: auto;",
                  attrs: {
                    id: !_vm.isCollectionOrComplete ? "contentOverview" : null,
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pt-0", attrs: { fluid: "" } },
                    [
                      _c("description", {
                        staticClass: "pt-4",
                        attrs: {
                          documentInfo: _vm.documentInfo,
                          isSingle: _vm.isSingle,
                          examId: _vm.examId,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isSingle && "" === _vm.examId
                    ? _c("related-titles", {
                        attrs: { documentId: _vm.documentId },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.documentInfo &&
                  _vm.documentInfo.content_type &&
                  _vm.documentInfo.content_type.name === "Premium Complete" &&
                  "" === _vm.examId
                    ? _c("included-titles-complete", {
                        attrs: { documentId: _vm.documentId },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.documentInfo &&
                  _vm.documentInfo.content_type &&
                  _vm.documentInfo.content_type.name === "Collection" &&
                  "" === _vm.examId
                    ? _c("included-titles", {
                        attrs: {
                          documentId: _vm.documentId,
                          documentInfo: _vm.documentInfo,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.documentInfo &&
                  _vm.documentInfo.categories &&
                  "" === _vm.examId
                    ? _c("categories", {
                        attrs: { categories: _vm.documentInfo.categories },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isCollectionOrComplete
                ? _c(
                    "v-col",
                    {
                      style: !_vm.isMobileAndTablet
                        ? "max-width: 430px;"
                        : "min-width: 100%;",
                    },
                    [
                      "" === _vm.examId
                        ? _c("information", {
                            attrs: {
                              documentInfo: _vm.documentInfo,
                              isSingle: _vm.isSingle,
                              examId: _vm.examId,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      "" === _vm.examId ? _c("vertical-upsell") : _vm._e(),
                      _vm._v(" "),
                      _vm.isSingle && _vm.documentInfo && "" === _vm.examId
                        ? [
                            _vm.documentInfo.sku
                              ? _c("title-included-complete", {
                                  staticClass: "my-5",
                                  attrs: {
                                    sku: _vm.documentInfo.sku,
                                    "part-of-complete":
                                      _vm.documentInfo.part_of_complete,
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("premium-features-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }