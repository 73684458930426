var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container" },
    [
      _vm.loading.section || _vm.loading.document
        ? _c(
            "v-row",
            { staticClass: "mt-12", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c("p", { staticClass: "caption" }, [
                                  _vm._v(
                                    "\n                                Loading Section\n                            "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : [
            _c(
              "v-row",
              [
                _c("v-col", [
                  _c("div", {
                    staticClass: "newIccStyles",
                    domProps: { innerHTML: _vm._s(_vm.sectionContent) },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { large: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.goToChapter()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Jump to Chapter\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "my-6" },
              [_c("v-col", [_c("v-divider")], 1)],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "auto" } }, [
                  _c("img", {
                    staticClass: "book-cover my-3 rounded-lg",
                    attrs: { src: _vm.getBookCover(_vm.documentInfo) },
                    on: { error: _vm.loadDefaultBookCover },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", align: "center" } },
                      [
                        _c("v-col", [
                          _c("h2", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.getBookTitle(_vm.documentInfo)) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "", align: "center" } },
                              [
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0 primary--text caption",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Categories:\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  _vm._l(
                                    _vm.documentInfo.categories,
                                    function (item, key) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: key,
                                          staticClass: "ma-1",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToCategory(item.slug)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.label) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "mb-2 mt-3 primary--text font-weight-regular fs-22",
                            },
                            [
                              _vm._v(
                                "\n                            About this Title\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "my-4",
                            domProps: {
                              innerHTML: _vm._s(_vm.documentInfo.description),
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "my-6" },
              [
                _c(
                  "v-col",
                  [
                    _c("v-img", {
                      staticClass: "cp",
                      attrs: {
                        src: _vm.asset(
                          require("../../images/banners/unlock_premium_complete.webp")
                        ),
                      },
                      on: { click: _vm.premiumSubscribe },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }