<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition">
        <v-card class="primary mobile-sidebar-nav">
            <v-card-text class="pa-0">
                <v-container>
                <v-row>
                    <v-col class="text-right">
                        <v-btn
                            text
                            fab
                            small
                            @click.stop="dialog = !dialog"
                            class="close mt-1">
                            <v-icon class="accent2--text">clear</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="ma-0"></v-divider>
                <v-row>
                    <v-col>
                        <v-list class="pt-0 primary">
                            <v-list-item
                                v-for="item in items"
                                v-if="item.href"
                                :key="item.title"
                                :href="item.href"
                                :title="item.title"
                                class="pa-0">
                                <v-list-item-action @mouseover="toggle()">
                                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-else
                                :key="item.title"
                                :title="item.title"
                                @click="showModal()"
                                class="pa-0">
                                <v-list-item-action>
                                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pa-0">
                                <v-list-item-action @mouseover="toggle()">
                                    <v-icon class="accent2--text" @click="goToRoute('favorites_landing')" :style="{ cursor: 'pointer'}">favorite</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="white--text" @click="goToRoute('favorites_landing')" :style="{ cursor: 'pointer'}">Favorites</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <div
                                v-for="book in favorites"
                                :key="book.id"
                                class="pa-0">
                                <v-list-item-content>
                                    <div
                                        class="white--text cp favorites_item"
                                        @click="goToBook(book.document_id)">
                                        {{ getBookTitle(book.document_id) }}<br />
                                        <span class="sidebar-printing-info">{{ getBookPrintingInfo(book.document_id) }}</span>
                                    </div>
                                </v-list-item-content>
                            </div>
                            <div id="sidebar-see-all" class="white--text cp" @click="goToRoute('favorites_landing')">See all favorites</div>
                        </v-list>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                windowHeight: document.documentElement.clientHeight+'px',
                items: [
                    { title: 'My Library', icon: 'book', href: Routing.generate('dashboard_landing') },
                    { title: 'Content Search', icon: 'search', href: Routing.generate('sections_search_page') },
                    { title: 'My Project Tags', icon: 'comment', href: Routing.generate('dashboard_my_tags') },
                    { title: 'My Notes', icon: 'bookmark', href: Routing.generate('notes') },
                    { title: 'Sharing History', icon: 'share', href: Routing.generate('dashboard_sharing') },
                    { title: 'License Configuration', icon: 'description', href: Routing.generate('license_configuration') },
                    { title: 'Concurrent Access', icon: 'supervised_user_circle', href: "" },
                    { title: 'Help', icon: 'help', href: this.$getConst('links').help, target: '_blank' },
                ],
            }
        },
        methods: {
            showModal() {
                EventBus.fire('show-modal-concurrent-access');
                this.setDialog();
            },
            setDialog() {
                this.dialog = !this.dialog;
            },
            getFavoriteBooks() {
                if (this.$store.getters.getLoggedIn !== '') {
                    this.$http.get(Routing.generate('get_favorite_books'))
                        .then(response => {
                            this.favorites = this.supplyFavoritesAndSort(response);
                            this.$session.set('favorites', this.favorites);
                            EventBus.fire("update-favorites-after", this.favorites);
                        });
                }
            }
        },
        created() {
            EventBus.listen('trigger-pa-sidebar', this.setDialog);
        }


    }
</script>
