<template>
    <v-card
        outlined
        class="grey lighten-4">
        <v-card-text class="py-1 px-3 pb-3">
            <v-row
                align="center"
                dense>
                <v-col>
                    <p class="font-weight-bold mb-0">
                        {{ title }}
                    </p>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        icon
                        dark
                        small
                        color="black">
                        <v-icon
                            @click="expand = !expand">
                            {{ expand ? 'mdi-minus' : 'mdi-plus'}}
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <component
                :is="componentName"
                :expand="expand"
                @closeExpand="() => expand = false"
                @updateAdvancedSearch="(value) => this.updateAdvancedSearch(value)"/>
        </v-card-text>
    </v-card>
</template>

<script>
    import CategoriesPanel from "../../filters/CategoriesPanel.vue";

    export default {
        name: 'GraphicsFilterExpander',
        data() {
            return {
                expand: false
            }
        },
        components: {
            CategoriesPanel
        },
        props: {
            title: {
                required: true,
                type: String
            },
            componentName: {
                required: true,
                type: String
            }
        }
    }
</script>
