<template>
    <confirm-dialog
            :is-open="dialogVisible"
            :width="800"
            @closed="emitClosed"
            :is-loading="isLoading"
    >

        <template v-slot:title>
            {{ title }}
        </template>

        <div style="font-size: 14px;" class="pt-5" v-html="html"></div>

        <template v-slot:buttons>

            <div class="w-full d-flex justify-end">
                <v-btn class="primary white--text" @click="clickedClose">
                    Close
                </v-btn>
            </div>

        </template>

    </confirm-dialog>
</template>

<script>
import ConfirmDialog from "../../ConfirmDialog.vue";
import {ApiService} from "../../../api/ApiService";

export default {
    components: {ConfirmDialog},
    emits: ['closed'],
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            isLoading: false,
            title: null,
            html: null
        }
    },
    methods: {
        emitClosed() {
            this.$emit('closed');
        },
        clickedClose() {
            this.emitClosed();
        }
    },
    mounted() {

        this.isLoading = true;

        ApiService.getBlockBySlug('quick-consult-tos').then((res) => {
            this.title = res.title;
            this.html = res.html;
        }).finally(() => {
            this.isLoading = false;
        })

        this.$watch(() => this.visible, (newValue) => {
            this.dialogVisible = !!newValue;
        }, {
            immediate: true
        });
    }
}
</script>
