var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("breadcrumb", {
        attrs: {
          data: _vm.breadcrumbData,
          "title-search-term": _vm.searchData.searchTerm,
        },
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "grey lighten-5" },
        [
          _vm.isAstmSubjectPage && _vm.isAstmCompassEnabled()
            ? _c("v-row", [_c("v-col", [_c("astm-subjects")], 1)], 1)
            : [
                _c("codes-result-navigation-controls", {
                  attrs: {
                    category: _vm.searchData.category,
                    "show-years": _vm.showYearFilter,
                    loading: _vm.resultsLoading,
                  },
                }),
                _vm._v(" "),
                _vm.resultsLoading
                  ? [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c("v-progress-circular", {
                                staticClass: "py-6",
                                attrs: { indeterminate: "", color: "primary" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _vm.pagination.totalResults > 0
                        ? _c("title-list", {
                            attrs: {
                              titles: _vm.results,
                              loading: _vm.resultsLoading,
                              itemsPerPage: _vm.itemsPerPage,
                              displayBorder: "",
                              displayUserTypeLabel: "",
                              displayInCols: _vm.isMobilePort ? 12 : 6,
                            },
                          })
                        : _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [_c("no-results-found")],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
              ],
          _vm._v(" "),
          _vm.page < _vm.pagination.maxPerPage && !_vm.resultsLoading
            ? _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            text: "",
                            color: "primary",
                            loading: _vm.showMoreLoading,
                          },
                          on: { click: _vm.showMore },
                        },
                        [
                          _vm._v(
                            "\n                    Show More\n                    "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v(
                              "\n                        mdi-chevron-down\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchData.category,
                  expression: "searchData.category",
                },
              ],
            },
            [
              _c(
                "v-col",
                [
                  _c("category-tabs", {
                    attrs: {
                      category: _vm.searchData.category,
                      "is-astm-standard-page": _vm.isAstmStandardPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", scrollable: "" },
          model: {
            value: _vm.categoryLegend,
            callback: function ($$v) {
              _vm.categoryLegend = $$v
            },
            expression: "categoryLegend",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "primary white--text caption mobile-title" },
                [
                  _vm._v("\n                Categories\n                "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.categoryLegend = false
                        },
                      },
                    },
                    [_vm._v("\n                    close\n                ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "px-0" }, [_c("navigation")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }