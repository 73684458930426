var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-bottom-sheet",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.menuVisible,
        callback: function ($$v) {
          _vm.menuVisible = $$v
        },
        expression: "menuVisible",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-subheader",
            {
              staticClass: "d-flex justify-space-between align-center",
              staticStyle: {
                "border-bottom": "1px solid gray",
                "margin-bottom": "8px",
              },
            },
            [
              _c("div", { staticClass: "text-truncate text--primary ml-3" }, [
                _vm._v(
                  "\n                " + _vm._s(_vm.title) + "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticStyle: { cursor: "pointer", "margin-right": "8px" },
                  on: { click: _vm.closeMenu },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.typeToEventMap, function (item, key) {
            return _c(
              "v-list-item",
              {
                key: key,
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.event, item.type)
                  },
                },
              },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-list-item-icon",
                          {
                            staticStyle: {
                              width: "40px",
                              margin: "8px 8px 8px 0 !important",
                            },
                          },
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                        _vm._v(" "),
                        !_vm.hasContentPremium && item.type === "premium"
                          ? _c(
                              "v-list-item-icon",
                              { staticClass: "ml-2" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-lock-outline"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }