<template>
    <div>
        <v-row
            align="center">
            <v-col>
                <p class="fs-20 my-3">
                    <template v-if="showResultsText">
                        {{ totalResults.toLocaleString() }} results for
                        <span class="font-weight-bold">
                            <template v-if="!isAdvanceSearch && searchTerm">
                                "{{ searchTerm }}"
                            </template>
                            <template v-else>
                                (Advanced Term Search)
                            </template>
                        </span>
                    </template>
                    <template v-else-if="loading">
                        Loading Results...
                    </template>
                    <template v-else>
                        Enter search to find content in your Premium Titles
                    </template>
                </p>
            </v-col>
            <v-col
                v-if="!isMobilePort && showResultsText"
                cols="auto">
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <v-select
                            dense
                            hide-details
                            outlined
                            :items="sortItems"
                            v-model="sort"
                            label="Sort by:"
                            :menu-props="{ bottom: true, offsetY: true }"
                            v-on:change="sortChange()"
                            style="max-width: 150px">
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row
            dense>
            <v-col>
                <v-chip
                    v-for="(item, key) in search"
                    v-if="item.data && item.key !== 'any'"
                    class="primary--text mr-2 mb-1"
                    color="primaryLight"
                    :key="key">
                    <span class="fs-16">
                        {{item.label}} {{item.data}}
                    </span>
                    <v-icon
                        right
                        @click="clearSearch(item.key)">
                        mdi-close
                    </v-icon>
                </v-chip>
                <template
                    v-for="(item, key) in activeFilters"
                    v-if="item.data">
                    <v-chip
                        class="primary--text mr-2"
                        color="primaryLight"
                        :key="item.data.key"
                        v-if="item.data.length === 1">
                        <span class="fs-16">
                            {{item.data[0].name}}
                        </span>
                        <v-icon
                            right
                            @click="clearFilter(item.key, item.data[0].id)">
                            mdi-close
                        </v-icon>
                    </v-chip>
                    <v-menu
                        absolute
                        v-if="item.data.length > 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                class="primary--text mr-2 mb-1"
                                color="primaryLight"
                                v-bind="attrs"
                                v-on="on">
                                <span class="fs-16">
                                    {{ `${item.subLabel} (${item.data.length})` }}
                                </span>
                                <v-icon
                                    right
                                    @click="clearFilter(item.key)">
                                    mdi-close
                                </v-icon>
                            </v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(subItem, index) in item.data"
                                :key="index">
                                <v-list-item-title>
                                    <v-chip
                                        class="primary--text mr-2"
                                        color="primaryLight">
                                        <span class="fs-16">
                                            {{ subItem.name }}
                                        </span>
                                        <v-icon
                                            right
                                            @click="clearFilter(item.key, subItem.id)">
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-col>
        </v-row>
        <template>
            <v-row
                v-if="isMobileAndTablet"
                align="center">
                <v-col>
                    <v-btn
                        color="primary"
                        block
                        @click="$emit('showModal')">
                        <v-icon left>
                            mdi-tune
                        </v-icon>
                        Content Filters
                    </v-btn>
                </v-col>
                <v-col>
                    <v-select
                        dense
                        hide-details
                        outlined
                        :items="sortItems"
                        v-model="sort"
                        label="Sort by:"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-on:change="sortChange()">
                    </v-select>
                </v-col>
            </v-row>
            <!-- <v-row
                v-if="isMobilePort"
                dense
                align="center">
                <v-col>
                    <v-select
                        dense
                        hide-details
                        outlined
                        :items="sortItems"
                        v-model="sort"
                        label="Sort by:"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-on:change="sortChange()">
                    </v-select>
                </v-col>
            </v-row> -->
        </template>
        <relevant-titles
            v-if="defaultSearch"
            class="mt-3">
        </relevant-titles>
    </div>
</template>

<script>
    import Premium from '../../inc/mixins/premium.js';
    import Mobile from '../../inc/mixins/mobile';
    import RelevantTitles from './RevelantTitles.vue';
    import ContentSearch from '../mixins/TabbedSearch';

    const FilterTitles = 'titles'
    const FilterCategories = 'categories'
    const FilterYears = 'years'
    const FilterContentSource = 'sources'
    const FilterContentType = 'types'

    export default {
        name: 'SearchHeader',
        emits: ['changed'],
        data() {
            return {
                book_title: false,
                displayItems:[
                    {text: '10', value: 10},
                    {text: '20', value: 20},
                    {text: '30', value: 30},
                    {text: '50', value: 50},
                    {text: '100', value: 100},
                ],
                sort: 'relevance',
                sortItems:[
                    {text: 'Relevance', value: 'relevance'},
                    {text: 'Sequential', value: 'sequence'},
                ],
                isAdvanceHidden: false,
                search: this.getDefaultSearch()
            }
        },
        props: {
            totalResults: {
                required: false,
                type: Number,
                default: 0
            },
            showResultsText: {
                required: false,
                type: Boolean,
                default: true
            },
            filters: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        [FilterTitles]: [],
                        [FilterCategories]: [],
                        [FilterYears]: [],
                        [FilterContentSource]: [],
                        [FilterContentType]: []
                    }
                }
            },
            loading: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            isAdvanceSearch() {
                const filteredArray = this.search.filter(obj => obj.data)
                return filteredArray.length > 1 || (filteredArray.length === 1 && filteredArray[0].key !== 'any')
            },
            searchTerm() {
                return this.search.find(item => item.key === 'any').data;
            },
            activeFilters() {
                return [
                    {
                        key: FilterTitles,
                        subLabel: 'My Premium Titles',
                        data: this.filters[FilterTitles]
                    },
                    {
                        key: FilterCategories,
                        subLabel: 'Categories',
                        data: this.filters[FilterCategories]
                    },
                    {
                        key: FilterYears,
                        subLabel: 'Years',
                        data: this.filters[FilterYears]
                    },
                    {
                        key: FilterContentSource,
                        subLabel: 'Search In',
                        data: this.filters[FilterContentSource]
                    },
                    {
                        key: FilterContentType,
                        subLabel: 'Search For',
                        data: this.filters[FilterContentType]
                    }
                ];
            }
        },
        mixins: [
            Premium,
            Mobile,
            ContentSearch
        ],
        components: {
            RelevantTitles
        },
        methods: {
            clearSearch(key = null) {
                if (key) {
                    let item = this.search.find(item => item.key === key);
                    if (item) {
                        item.data = '';
                    }
                    this.$emit('clearSearch', key);
                } else {
                    this.search = this.getDefaultSearch()
                }
            },
            getDefaultSearch() {
                return [
                    {
                        key: 'exact',
                        label: 'This exact phrase:',
                        data: ''
                    },
                    {
                        key: 'all',
                        label: 'All of these words:',
                        data: ''
                    },
                    {
                        key: 'any',
                        label: 'Any of these words:',
                        data: ''
                    },
                    {
                        key: 'none',
                        label: 'None of these words:',
                        data: ''
                    },
                    {
                        key: 'nearSearch',
                        label: 'Near search:',
                        data: ''
                    }
                ]
            },
            clearFilter(key, id = null) {
                if (id) {
                    switch (key) {
                        case FilterTitles:
                            this.filters[FilterTitles] = this.filters[FilterTitles].filter(item => item.id !== id)
                            EventBus.fire('remove-single-title', id)
                            break
                        case FilterCategories:
                            this.filters[FilterCategories] = this.filters[FilterCategories].filter(item => item.id !== id)
                            EventBus.fire('remove-single-category', id)
                            break
                        case FilterYears:
                            this.filters[FilterYears] = this.filters[FilterYears].filter(item => item.id !== id)
                            EventBus.fire('remove-single-year', id)
                            break
                        case FilterContentSource:
                            this.filters[FilterContentSource] = this.filters[FilterContentSource].filter(item => item.id !== id)
                            EventBus.fire('remove-single-content-source', id)
                            break
                        case FilterContentType:
                            this.filters[FilterContentType] = this.filters[FilterContentType].filter(item => item.id !== id)
                            EventBus.fire('remove-single-content-type', id)
                            break
                    }
                } else {
                    if (key === FilterTitles) {
                        EventBus.fire('remove-all-titles')
                    }
                    this[key] = []
                }
            },
            sortChange() {
                this.$emit('sortChange', this.sort);
            },
            setSearch(data) {
                Object.entries(data).forEach(([key, value]) => {
                    let item = this.search.find(item => item.key === key);
                    if (item) {
                        item.data = value;
                    }
                });
            }
        },
        created() {
            EventBus.listen('hide-advanced-search', () => this.isAdvanceHidden = true);
            EventBus.listen('set-advanced-search', (searchTerms) => this.setSearch(searchTerms))
            EventBus.listen('clear-advanced-search', () => this.clearSearch())
        }
    }
</script>
<style type="scss" scoped>
    .rows-limit >>>.v-input__slot {
        width: 85px;
    }
</style>
