<template>
    <div v-if="hasProduct">
        <v-card
            outlined
            class="py-4 mt-6 ml-2">
            <div class="text-center">
                <h3 class="title font-weight-regular mb-2">Related Subscriptions</h3>
                <img class="my-2"
                    :src="getBookCover(related_product)"
                    @error="loadDefaultBookCover"
                    alt="related_product.display_title"
                    style="max-width: 120px"/>
                <p class="font-weight-bold">{{ related_product.display_title }}</p>
                <v-btn class="primary" @click="linkAction(related_product)">
                    View Premium Options
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import RelatedProduct from '../../inc/mixins/relatedProduct';
    import BookCover from './../../inc/mixins/bookCover';
    import TitleGraphic from "../../titles/cover/Graphic.vue";
    export default {
        components: {TitleGraphic},
        mixins: [
            RelatedProduct,
            BookCover
        ],
    }
</script>
