<template>
    <span>{{ remainingDisplay }}</span>
</template>

<script>
export default {
    props: {
        seconds: {
            type: Number,
            required: true
        },
        stopped: {
            type: Boolean,
            required: false,
            default: false
        },
        format: {
            type: String,
            required: false
        }
    },
    emits: ['done'],
    data() {
        return {
            msLeft: 0,
            isDone: false
        };
    },
    computed: {
        remainingDisplay() {

            if (this.format === 'ms') {
                return this.msLeft;
            } else if (this.format === 'rounded') {
                return (this.msLeft / 1000).toFixed(1);
            } else if (this.format === 'rounded_2') {
                return (this.msLeft / 1000).toFixed(2);
            } else {
                return Math.floor(this.msLeft / 1000);
            }
        }
    },
    mounted() {

        this.msLeft = this.seconds * 1000;

        setInterval(() => {

            if (this.msLeft > 0 && !this.stopped) {
                this.msLeft -= 25;
            }

            if (this.msLeft <= 0 && !this.isDone) {
                this.$emit('done');
            }

        }, 25);
    }
}
</script>
