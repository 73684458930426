var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "open-on-hover": "",
        "offset-y": "",
        right: "",
        "allow-overflow": "",
        "z-index": "1005",
        "content-class": "user-profile-menu",
        "nudge-width": "180",
        "max-width": _vm.activeProfile ? "300px" : "240px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { text: "" },
                      on: {
                        keydown: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openMenu.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openMenu.apply(null, arguments)
                          },
                        ],
                        click: function ($event) {
                          !_vm.isMobilePort && !_vm.activeProfile
                            ? _vm.goToRoute("security_login")
                            : ""
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("profile-icon", {
                            attrs: {
                              profile: _vm.activeProfile
                                ? _vm.activeProfile
                                : { type: "personal" },
                              "apply-background": "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _vm.activeProfile
                            ? [
                                _c("h4", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.trimmedText(
                                          _vm.activeProfile.name,
                                          18
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-regular text-uppercase text-left",
                                    staticStyle: { "font-size": "10px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.activeProfile.type) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c("h4", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                            Sign In\n                        "
                                  ),
                                ]),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm._v(" "),
      _c("active-profile"),
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "py-0 grey lighten-4", attrs: { role: "menu" } },
        [
          _c(
            "v-list-item",
            {
              staticClass: "grey lighten-2",
              staticStyle: { "min-height": "20px" },
            },
            [
              _c("v-list-item-title", { staticClass: "text-center" }, [
                _c(
                  "h6",
                  [
                    _vm.activeProfile
                      ? [
                          _vm._v(
                            "\n                        select from available profiles\n                    "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                        Not a Member of ICC Digital Codes?\n                    "
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("profiles-list"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }