<template>
    <a
        :href="link"
        @click="registerClick(breadcrumbs, documentId)"
        target="_blank">
        <slot>
        </slot>
    </a>
</template>

<script>
    import Breadcrumbs from '../../mixins/breadcrumbs';

    export default {
        props: {
            breadcrumbs: {
                type: Object,
                required: true
            },
            documentId: {
                type: Number,
                required: true
            },
            meta: {
                type: Object,
                required: true
            }
        },
        mixins: [Breadcrumbs],
        computed: {
            link() {
                return Routing.generate('content_id_redirect', {
                    'contentId': this.meta['contentid']
                });
            }
        },
        methods: {
            getLink() {
                const breadcrumbs = this.breadcrumbs;
                if (this.isSubsection(breadcrumbs)) {
                    return this.getSubSectionUrl(breadcrumbs, this.meta.contentid);
                } else if (this.isSection(breadcrumbs)) {
                    return this.getSectionUrl(breadcrumbs);
                } else if (this.isChapter(breadcrumbs)) {
                    return this.generateBaseSlug(breadcrumbs);
                } else {
                    return this.getBookUrl(breadcrumbs, this.meta.documentid);
                }
            }
        }
    }
</script>
