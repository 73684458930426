var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 pt-6" },
    [
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c("section-heading", {
            attrs: {
              heading: "Digital Codes Premium subscription options",
              subHeading:
                "Subscribe to Premium and accelerate your code compliance workflow.",
            },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center pt-0" }, [
                _c("p", [
                  _c(
                    "a",
                    {
                      staticClass: "pr-10 fs-14 font-weight-bold",
                      attrs: {
                        target: "_blank",
                        href: this.getRoute("premium_features"),
                      },
                    },
                    [_vm._v("Learn about Premium")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "fs-14 font-weight-bold",
                      attrs: {
                        href: `mailto:${this.$getConst("sales_email_address")}`,
                      },
                    },
                    [_vm._v("Talk to Sales")]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            _vm._l(_vm.priceBlocks, function (item, key) {
              return _c(
                "v-col",
                { key: key },
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "fill-height rounded-lg",
                      style: item.complete
                        ? `border: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`
                        : `border: 1px solid ${_vm.$vuetify.theme.themes.light.primaryMedium}`,
                      attrs: { rounded: "", elevation: "4" },
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pa-6",
                          class: item.complete ? "py-4" : "",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "accent2" } },
                                    [
                                      _vm._v(
                                        "\n                                    mdi-creation\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "fs-24 font-weight-bold" },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              item.complete
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: { color: "accent2Light" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Most Popular\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "fs-16 font-weight-medium my-6" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.description) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "fs-16 grey--text text--darken-1" },
                            [
                              _vm._v(
                                "\n                            Starting at\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _c(
                              "span",
                              { staticClass: "fs-40 font-weight-bold" },
                              [_vm._v("$" + _vm._s(item.price))]
                            ),
                            _vm._v(" / month\n                        "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "list-none pl-0" },
                            _vm._l(item.list, function (item, key) {
                              return _c(
                                "li",
                                { key: key },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "teal" } },
                                            [
                                              _vm._v(
                                                "\n                                        mdi-check-circle-outline\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-col", { staticClass: "px-2" }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-0 fs-14 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              item.button
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-10 font-weight-bold",
                                      attrs: {
                                        outlined: !item.complete,
                                        block: "",
                                        color: item.complete
                                          ? "accent2"
                                          : "primary",
                                        large: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.redirectPricingTable(
                                            item.title
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.button.text) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("basic-teams-block"),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isActiveUser || _vm.canStartTrial
        ? _c("trial-block", { staticClass: "my-8" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }