var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c("confirm-dialog", {
        attrs: {
          "is-open": _vm.dialog,
          borderStyle: "",
          showHeaderBorder: "",
          noGrayActionArea: "",
          fullscreen: _vm.isMobilePort,
        },
        on: {
          closed: function ($event) {
            return _vm.emitClosed("quantity")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " + _vm._s(_vm.dialogTitle) + "\n        "
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _vm.hasLicenseRemovalRequest && !_vm.invalidVisaCart
                    ? _c(
                        "alert-bar",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            id: "display_license_remove_info",
                            message: `Subscription has pending change. Submitting a new change will overwrite the previously pending changes. <br />Pending change to be overwritten:
&emsp; <ul><li> Licenses quantity changes to ${_vm.getNextRenewalLicense()} licenses. Auto-renews on ${_vm.formatAutoRenewDate()} for $${_vm.getNextRenewalCost()}.</li></ul>`,
                            notificationType: "warning",
                          },
                        },
                        [
                          _vm.isCancellingRequest
                            ? _c(
                                "span",
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      size: 25,
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "a",
                                {
                                  staticClass:
                                    "text-decoration-underline black--text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelLicenceChange()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Cancel previous license quantity change\n                "
                                  ),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "pt-4" }, [
                    _c("h3", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.displayDocumentTitle)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.step === 1
                    ? _c("license-quantity", {
                        attrs: {
                          displayBigger: "",
                          actionDisabled: _vm.invalidVisaCart,
                        },
                      })
                    : _c("div", { staticClass: "mt-2" }, [
                        _c("h3", [_vm._v("License Quantity")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.quantity) +
                              " licenses\n                "
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  _vm.quantity !== _vm.item.licences
                    ? _c("p", { staticClass: "green--text darken-3" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.licenseMessage) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.savingsText.length
                    ? _c("discount-text", {
                        attrs: { savingsText: _vm.savingsText },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h3", [_vm._v("Billing Frequency")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.displayBillingType())),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                    Auto-renews " +
                          _vm._s(_vm.formatAutoRenewDate()) +
                          " for\n                    "
                      ),
                      _vm.loadingPrice
                        ? _c(
                            "span",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  size: 25,
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              "$" + _vm._s(_vm._f("formatPrice")(_vm.getPrice))
                            ),
                          ]),
                    ]),
                    _vm._v(" "),
                    _vm.quantity !== _vm.item.licences
                      ? _c("p", { staticClass: "green--text darken-3 mb-0" }, [
                          _vm._v(
                            "\n                    Changed from $" +
                              _vm._s(_vm._f("formatPrice")(_vm.initialPrice)) +
                              " / " +
                              _vm._s(_vm.billingPeriodShortText) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("payment-method-display", { attrs: { "show-alert": "" } }),
                  _vm._v(" "),
                  _vm.step === 2 &&
                  _vm.quantity > _vm.item.licences &&
                  !_vm.invalidVisaCart &&
                  _vm.getProRatePrice
                    ? _c("p", [
                        _vm._v(
                          "\n                You will be charged a prorated price of $" +
                            _vm._s(_vm._f("formatPrice")(_vm.getProRatePrice)) +
                            " today.\n                Your subscription will auto-renew with these changes for the full price of\n                $" +
                            _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                            " " +
                            _vm._s(_vm.formatAutoRenewDate()) +
                            ". Includes applicable volume discounts.\n            "
                        ),
                      ])
                    : _vm.step === 2 &&
                      _vm.quantity > _vm.item.licences &&
                      !_vm.invalidVisaCart &&
                      _vm.isFreeTransaction
                    ? _c("p", [
                        _vm._v(
                          "\n                There is no immediate charge required for the additional license(s) being added.\n                Your subscription will auto-renew for the increased quantity of licenses at the price of $" +
                            _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                            " on " +
                            _vm._s(_vm.formatAutoRenewDate()) +
                            ".\n                Includes applicable volume discounts.\n            "
                        ),
                      ])
                    : _vm.step === 2 &&
                      _vm.quantity < _vm.item.licences &&
                      !_vm.invalidVisaCart
                    ? _c("p", [
                        _vm._v(
                          "\n                No payment required. This change will take effect when your subscription auto-renews.\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("contact-link-info"),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("alert-bar", {
                                attrs: {
                                  dismissible: "",
                                  id: "display_http_messages",
                                  message: _vm.message,
                                  notificationType: _vm.notifyType,
                                },
                                on: { dismiss: _vm.dismissAlert },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.step === 2 && _vm.quantity > _vm.item.licences
                        ? _c(
                            "v-row",
                            { staticClass: "px-2", attrs: { dense: "" } },
                            [
                              _c("v-col", [
                                _c("h3", { staticClass: "fs-20" }, [
                                  _vm._v("Total Price Today"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c("h3", { staticClass: "fs-20" }, [
                                  _vm.loadingProratePrice
                                    ? _c(
                                        "span",
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              size: 25,
                                              color: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.getProRatePrice
                                    ? _c("span", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                _vm.getProRatePrice
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm.isFreeTransaction
                                    ? _c("span", [_vm._v("$0.00")])
                                    : _vm._e(),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("action-buttons", {
                        attrs: {
                          step: _vm.step,
                          isDisableReviewBtn: _vm.isDisableReviewBtn,
                          loadingSubmit: _vm.loadingSubmit,
                          submitText: `Submit ${
                            _vm.addedLicenseCount > 0 ? "and Pay" : ""
                          }`,
                        },
                        on: {
                          "go-back": _vm.handleGoBack,
                          close: function ($event) {
                            return _vm.emitClosed("quantity")
                          },
                          "review-changes": _vm.reviewChanges,
                          "submit-and-pay": _vm.submitAndPay,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2022011506
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }