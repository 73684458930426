var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection
    ? _c(
        "v-row",
        { attrs: { id: "sectionContent_" + _vm.sectionId } },
        [
          _vm.displayTitleSection
            ? _c(
                "v-col",
                {
                  staticClass: "text-center",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c("tile", {
                    attrs: { title: _vm.document, "show-title": true },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "newIccStyles purchasedViewStyles wrap-section-content pt-5",
                      attrs: { "primary-title": "" },
                    },
                    [
                      !_vm.isDefinitionSection &&
                      _vm.isPremiumSubscribed(_vm.documentSku)
                        ? _c(
                            "div",
                            {
                              class: {
                                "section-action-buttons":
                                  this.$vuetify.breakpoint.mdAndUp,
                                "section-action-buttons-mobile":
                                  this.$vuetify.breakpoint.smAndDown,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "second-event",
                                                          rawName:
                                                            "v-second-event",
                                                          value: {
                                                            method:
                                                              "trackClickedAction",
                                                            value:
                                                              "Jump to Section",
                                                          },
                                                          expression:
                                                            "{ method: 'trackClickedAction', value: 'Jump to Section' }",
                                                        },
                                                      ],
                                                      staticClass: "right mr-2",
                                                      on: {
                                                        click:
                                                          _vm.sectionJumpAction,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("input")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        779010781
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "tooltip-content" },
                                        [_vm._v("Jump to this Section")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "second-event",
                                                          rawName:
                                                            "v-second-event",
                                                          value: {
                                                            method:
                                                              "trackClickedAction",
                                                            value:
                                                              "Copy to Clipboard",
                                                          },
                                                          expression:
                                                            "{ method: 'trackClickedAction', value: 'Copy to Clipboard' }",
                                                        },
                                                      ],
                                                      staticClass: "right mr-2",
                                                      on: {
                                                        click:
                                                          _vm.sectionCopyAction,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("file_copy")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        455974864
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "tooltip-content" },
                                        [
                                          _vm._v(
                                            "Copy this Section to Clipboard"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "second-event",
                                                          rawName:
                                                            "v-second-event",
                                                          value: {
                                                            method:
                                                              "trackClickedAction",
                                                            value:
                                                              "Print Section",
                                                          },
                                                          expression:
                                                            "{ method: 'trackClickedAction', value: 'Print Section' }",
                                                        },
                                                      ],
                                                      staticClass: "right mr-2",
                                                      on: {
                                                        click:
                                                          _vm.sectionPrintAction,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("local_printshop")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1580805823
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "tooltip-content" },
                                        [_vm._v("Print this Section")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "second-event",
                                                          rawName:
                                                            "v-second-event",
                                                          value: {
                                                            method:
                                                              "trackClickedAction",
                                                            value:
                                                              "Share Section",
                                                          },
                                                          expression:
                                                            "{ method: 'trackClickedAction', value: 'Share Section' }",
                                                        },
                                                      ],
                                                      staticClass: "right mr-2",
                                                      on: {
                                                        click:
                                                          _vm.sectionShareAction,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("link")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4004112132
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "tooltip-content" },
                                        [_vm._v("Share this Section")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "second-event",
                                                          rawName:
                                                            "v-second-event",
                                                          value: {
                                                            method:
                                                              "trackClickedAction",
                                                            value:
                                                              "Bookmark Section",
                                                          },
                                                          expression:
                                                            "{ method: 'trackClickedAction', value: 'Bookmark Section' }",
                                                        },
                                                      ],
                                                      staticClass: "right",
                                                      on: {
                                                        click:
                                                          _vm.sectionBookmarkAction,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.bookmarkIcon)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3697154543
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "tooltip-content" },
                                        [_vm._v("Bookmark this Section")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "quick-access-section-content" },
                        [
                          _vm.selectedSectionData
                            ? _c("div", {
                                attrs: {
                                  id:
                                    "section-content_" +
                                    _vm.selectedSectionData.sectionId,
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.selectedSectionData.sectionContent +
                                      "</section>"
                                  ),
                                },
                              })
                            : _c("div", {
                                attrs: {
                                  id: "section-content_" + _vm.sectionId,
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.sectionContent + "</section>"
                                  ),
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.loaded && _vm.hasBookmarksOrAnnotations
                    ? _c("note-list", { attrs: { data: _vm.note } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "second-event",
                      rawName: "v-second-event",
                      value: {
                        method: "trackClickedAction",
                        value: "Jump to Section",
                      },
                      expression:
                        "{ method: 'trackClickedAction', value: 'Jump to Section' }",
                    },
                  ],
                  staticClass: "mt-5",
                  attrs: { "x-large": "", color: "primary" },
                  on: { click: _vm.jumpToSection },
                },
                [
                  _vm._v(
                    "\n            Jump to Section within Chapter\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("share-section-modal", { key: _vm.shareModalKey }),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: {
                top: "",
                right: "",
                color: "success",
                timeout: _vm.timeout,
              },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v("\n        Content was copied to buffer\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }