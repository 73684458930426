<template>
    <div id="block" class="d-flex-not-important">

        <div v-if="isLoading" class="d-flex justify-center align-center w-full">

            <v-progress-circular
                    v-if="isLoading"
                    color="primary"
                    indeterminate
            ></v-progress-circular>

        </div>

        <div v-else-if="contents" v-html="contents"></div>

        <slot v-else></slot>

    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            contents: '',
            error: ''
        }
    },
    methods: {
        loadContents() {

            const apiGetBlock = Routing.generate('blocks_show', {
                slug: this.slug
            });

            this.contents = '';
            this.error = '';
            this.isLoading = true;

            axios.get(apiGetBlock).then((res) => {
                const data = res.data;
                this.contents = data?.html || "";
            }).catch((error) => {
                this.error = error;
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        this.loadContents();

        EventBus.listen('blocks:reload', () => {
            this.loadContents();
        });
    }
}
</script>

<style scoped>
#block {
    min-height: 5em;
}
</style>
