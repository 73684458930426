<template>
    <v-row>
        <v-col>
            <v-sheet
                class="rounded-lg pa-4"
                style="border: 1px solid #eeeeee;">
                <premium-status
                    v-if="!documentInfo.is_astm"
                    :documentInfo="documentInfo"
                    :contentPremium="contentPremium"
                    :contentProtection="contentProtection"
                    :contentBasic="contentBasic">
                </premium-status>
                <p class="text-body-1 mb-0 py-2 font-weight-medium">{{ displayMessage }}</p>
                <template v-if="!isEnterpriseOrConcurrentActive">
                    <v-btn
                        depressed
                        color="accent2"
                        class="font-weight-bold"
                        @click="openSubscribeToPremiumDialog()">
                        Subscribe to This Title
                    </v-btn>
                    <StartFreeTrial
                        :large="false"
                        outlined
                        color="primary"
                        class="ma-1"
                        :maxHeight="35"/>
                </template>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import StartFreeTrial from '../../buttons/StartFreeTrial.vue';
    import PremiumStatus from '../chapter-header/PremiumStatus.vue';

    export default {
        data(){
            return {
                basicMessage: 'You have Basic read-only access to this title. Select chapter from Table of Contents to view codes. Subscribe to Premium to unlock more code features.',
                basicPlusMessage: 'You have Basic Plus access to this title (which allows copy/paste). Select chapter from Table of Contents to view codes. Subscribe to Premium to unlock more code features.',
                premiumRequiredMessage: 'You need a Premium subscription to access this title. Subscribe to Premium to unlock this title and more code features.',
                premiumLiteMessage: 'You have Premium Lite access to this title (which prevents copy/paste controls).',
                basicAllowedInActiveSubscriptionMessage: 'This title is not a part of your {profile} Premium subscription but has Basic read-only access.',
                noBasicInActiveSubscriptionMessage: 'This title is not a part of your {profile} Premium subscription.',
            }
        },
        components: {
            PremiumStatus,
            StartFreeTrial
        },
        mixins: [
            Mobile
        ],
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            contentPremium: {
                required: false,
                type: String
            },
            contentProtection: {
                required: false,
                type: String
            },
            contentBasic: {
                required: false,
                type: String
            },
        },
        computed:{
            accessLevel(){
                const { contentProtection, contentPremium, contentBasic, documentInfo } = this;
                if (documentInfo) {
                    const { contentProtection: docProtection, contentPremium: docPremium, contentBasic: docBasic } = documentInfo;
                    if ('1' === contentPremium || docPremium) {
                        return docProtection === '1' ? 'premiumLite' : 'premium';
                    }
                    const contentBasicAndProtection =  '1' === contentBasic && '1' === contentProtection;
                    if (!this.isContentRestricted(documentInfo)) {
                        if (contentBasicAndProtection || (docBasic && docProtection)) {
                            return 'basicReadOnly';
                        } else {
                            return 'basicPlus';
                        }
                    } else {
                        return 'premiumRequired';
                    }
                }
            },
            profile(){
                let profile = 'active';
                if (this.activeEnterpriseProfile){
                    profile = 'Enterprise';
                } else if(this.activeConcurrentProfile) {
                    profile = 'Concurrent';
                }
                return profile;
            },
            displayMessage(){
                const isPremiumSubscribed = this.isPremiumSubscribed(this.documentInfo.sku);
                if (this.accessLevel === 'basicReadOnly'){
                    if (this.hasPremiumSubscription && !isPremiumSubscribed) {
                        return this.basicAllowedInActiveSubscriptionMessage.replace('{profile}', this.profile);
                    }
                    return this.basicMessage;
                } else if (this.accessLevel === 'basicPlus') {
                    return this.basicPlusMessage;
                } else if(this.accessLevel === 'premiumLite') {
                    return this.premiumLiteMessage;
                } else if(this.accessLevel === 'premiumRequired') {
                    if (this.hasPremiumSubscription && !isPremiumSubscribed) {
                        return this.noBasicInActiveSubscriptionMessage.replace('{profile}', this.profile);
                    }
                    return this.premiumRequiredMessage;
                }
            }
        },
        methods: {
            openSubscribeToPremiumDialog() {
                EventBus.fire('show-premium-subscribe-dialog', this.documentInfo);
            }
        }
    }
</script>
