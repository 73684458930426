<template>
    <div>
        <v-list-item @click="$emit('backToMain')">
            <v-list-item-icon class="mr-2">
                <v-icon color="primary">
                    mdi-arrow-left
                </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="fs-16 font-weight-medium">
                <slot>
                    Main Menu
                </slot>
            </v-list-item-title>
        </v-list-item>
        <v-divider>
        </v-divider>
    </div>
</template>

<script>
    export default {
        name: 'BackToMainMenu'
    }
</script>
