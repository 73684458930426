<template>
<div>
<v-tooltip
    bottom
    max-width="300"
    transition="fade-transition"
    v-model="tooltipOpen">
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="isLoggedIn && !concurrent"
                icon
                text
                role="button"
                aria-label="Cart"
                class="primary--text"
                :href="getRoute('cart_page')"
                v-on="on">
                <template v-if="getItemsCount !== 0">
                    <v-badge color="accent2" class="mt-1 pt-1" right>
                        <template v-slot:badge>
                            <span class="caption" v-html="getItemsCount"></span>
                        </template>
                        <v-icon color="black">mdi-cart-outline</v-icon>
                    </v-badge>
                </template>
                <template v-else>
                    <v-icon
                    v-if="isLoggedIn && !concurrent"
                    color="black">
                        mdi-cart-outline
                    </v-icon>
                </template>
            </v-btn>
        </template>
        <span class="tooltip-content"
            @mouseenter="showTooltip"
            @mouseleave="hideTooltip">Cart</span>
</v-tooltip>

<v-tooltip
    bottom
    max-width="300"
    transition="fade-transition"
    v-model="tooltipNext">
        <template v-slot:activator="{ on }">
            <v-btn
            icon
            text
            role="button"
            aria-label="Recent Updates"
            class="primary--text"
            @click="handleClick({
            event: 'fire',
            value: 'show-notification-modal'
            })"
            v-on="on">
                <v-icon
                    color="black"
                    class="ma-0">
                    mdi-bell-outline
                </v-icon>
            </v-btn>
        </template>
        <span class="tooltip-content"
            @mouseenter="showTooltipNext"
            @mouseleave="hideTooltipNext">
            Recent Updates
        </span>
</v-tooltip>
</div>
</template>

<script>
    import NavigationMixin from './mixins/navigation';
    
    export default {
        data: function () {
            return {
                tooltipNext: false
            };
        },
        mixins: [NavigationMixin],
        methods: {
            handleClick(action) {
                if (_.isObject(action)) {
                    if (action.event) {
                        if (action.event === 'fire') {
                            EventBus.fire(action.value)
                        } else if (action.event === 'external-link') {
                            window.open(action.value, '_blank');
                        }
                    }
                } else {
                    this.goToRoute(action);
                }
                this.drawer = false;
            },
            showTooltipNext() {
                this.tooltipNext = true;
            },
            hideTooltipNext() {
                this.tooltipNext = false;
            }
        },
         computed: {
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
            navItems() {
                // Todo: This needs to be cleaned up after profile switching is live.
                return [
                    {
                        title: 'Recent Updates',
                        icon: 'mdi-bullhorn',
                        action: {
                            event: 'fire',
                            value: 'show-notification-modal'
                        },
                        avatar: {
                            color: 'error',
                            value: 1
                        }
                    }
                ]
            }
        }
    }
</script>