var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: !_vm.isExtraLarge } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v(
                          "\n                        fa-book\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("h3", [
                      _vm._v(
                        "\n                        All Included Titles\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", xl: "4", sm: "6" } },
            [
              _c("v-text-field", {
                staticClass: "white",
                attrs: {
                  dense: "",
                  clearable: "",
                  outlined: "",
                  rounded: "",
                  "hide-details": "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search across included titles",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("title-list", {
        attrs: {
          titles: _vm.titles,
          loading: _vm.loading,
          search: _vm.search,
          itemsPerPage: _vm.itemsPerPage,
        },
      }),
      _vm._v(" "),
      _vm.itemsPerPage < _vm.titles.length
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.itemsPerPage = _vm.itemsPerPage + 10
                        },
                      },
                    },
                    [
                      _vm._v("\n                Show More\n                "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          "\n                    mdi-chevron-down\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }