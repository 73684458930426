<template>
    <div>
        <template v-if="isMobilePort">
            <index-mobile>
            </index-mobile>
        </template>
        <template v-else>
            <index-desktop>
            </index-desktop>
        </template>
    </div>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';
    import IndexDesktop from './IndexDesktop';
    import IndexMobile from './IndexMobile';

    export default {
        mixins: [Mobile],
        components: {
            IndexDesktop,
            IndexMobile
        }
    }
</script>
