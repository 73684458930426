import {DateUtil} from "../util/DateUtil";

const EXPIRATION_KEY_PREFIX = '__expires:';

const getExpirationTimeKey = function (key) {
    return EXPIRATION_KEY_PREFIX + key;
}

const storage = window.localStorage;

export class ExpirableStorage {

    static has(key) {
        const value = storage.getItem(key);
        return value !== null;
    }

    static get(key, defaultValue = null) {

        // that piece of data should be expired by now? Lazy expiration mode...
        // TODO: active expiration mode when accessing via localStorage directly.
        if (this.getTTL(key) === 0) {
            ExpirableStorage.invalidate(key);
            return defaultValue;
        }

        let valueFromStorage = storage.getItem(key);

        if (valueFromStorage === null) {
            return defaultValue;
        }

        try {
            return JSON.parse(valueFromStorage);
        } catch (ex) {
            // must not be valid JSON then...
        }

        return valueFromStorage;
    }

    /**
     *
     * @param {string} key
     * @param {*} value
     * @param {number} ttl in seconds
     */
    static set(key, value, ttl = undefined) {

        if (value === undefined) {
            return false;
        }

        if (typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
        }

        storage.setItem(key, value);

        // We want this key to expire at some point?
        if (typeof ttl === 'number') {
            let expiresAt = DateUtil.timestampInSeconds() + ttl;
            storage.setItem(getExpirationTimeKey(key), expiresAt.toString());
        }
    }

    /**
     *
     * @param {string} key
     */
    static remove(key) {
        storage.removeItem(key);
    }

    /**
     *
     * Returns -2 if the key does not exist.
     * Returns -1 if the key does not exist or if the key exist but has no associated expire.
     *
     * @param {string} key
     * @return {number}
     */
    static getTTL(key) {

        if (this.has(key) === false) {
            return -2;
        }

        // returns either timestamp or NULL if nothing found
        let value = storage.getItem(getExpirationTimeKey(key));

        if (value === null) {
            return -1;
        }

        let diff = value - DateUtil.timestampInSeconds();

        return Math.max(diff, 0);
    }

    static invalidateAll(onlyExpired = true) {

        for (const key of Object.keys(storage)) {

            if (key.startsWith(EXPIRATION_KEY_PREFIX)) {
                let valueKey = key.replace(EXPIRATION_KEY_PREFIX, '');

                if (!onlyExpired || this.getTTL(valueKey) === 0) {
                    this.invalidate(valueKey);
                }
            }
        }
    }

    static invalidate(key) {
        storage.removeItem(key);
        storage.removeItem(getExpirationTimeKey(key));
    }
}
