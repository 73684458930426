const normalizeKey = (key) => {

    if (typeof key === 'number') {
        return key.toString();
    }

    return key;
}

// TODO: map of sets
export class MapOfArrays {

    /** @type {Map} */
    _map;

    constructor() {
        this._map = new Map();
    }

    /**
     * @param {string|number} key
     * @return {Set|null}
     */
    get(key) {

        key = normalizeKey(key);

        if (this._map.get(key)) {
            return this._map.get(key);
        }

        return null;
    }

    /**
     *
     * @param key
     * @returns {Array}
     */
    getArray(key) {
        const result = this.get(key);
        return result ? Array.from(result) : [];
    }

    /**
     *
     * @return {String[]}
     */
    keysToArray() {
        return Array.from(this._map.keys());
    }

    push(key, value) {

        let existing = this.get(key);

        if (existing) {

            if (value instanceof Array) {

                value.forEach((item) => {
                    existing.add(item);
                });

            } else {
                existing.add(value);
            }

        } else {
            value = Array.isArray(value) ? value : [value];
            this._map.set(normalizeKey(key), new Set(value));
        }
    }
}
