<template>
    <div>
        <div style="font-size: 14px;">

            <div class="d-flex align-start mb-2">
                <v-icon class="primary--text pr-2" small>mdi-lightbulb-outline</v-icon>
                Questions should be 500 characters or less. Shorter questions are best.
            </div>

            <div class="d-flex align-start mb-2">
                <v-icon class="primary--text pr-2" small>mdi-lightbulb-outline</v-icon>

                You do not have to specify a codebook, but if you are sure of the codebook you want
                an answer from, say "In the IBC" or something similar.
            </div>

            <div class="d-flex align-start mb-2">
                <v-icon class="primary--text pr-2" small>mdi-lightbulb-outline</v-icon>
                Ask AI Navigator just one question per submission. Multi-part questions get better
                answers when asked as multiple questions.
            </div>

        </div>

        <p class="mb-0">
            <a href="/features/ai-navigator" target="_blank" class="font-weight-bold">
                Learn more
            </a>
        </p>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>
