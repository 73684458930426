<template>
    <div class="section--new">

        <div class="container-md mx-auto">
            <v-container>

                <v-row justify-lg="center">
                    <v-col cols="12">

                        <slot></slot>

                    </v-col>
                </v-row>
            </v-container>
        </div>

    </div>
</template>

<script>

export default {
    name: 'HeroSection'
}
</script>

<style scoped>
.section--new {
    width: 100%;
}

.container-md {
    max-width: 1600px;
}
</style>
