<template>
    <v-container
        :fluid="!isExtraLarge"
        v-if="titles.length"
        >
        <v-row align="center" dense>
            <v-col>
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <h3 class="text-h6 font-weight-bold">
                            Related Titles
                        </h3>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <title-list
                    :titles="titles"
                    :loading="loading"
                    :itemsPerPage="itemsPerPage">
                </title-list>
            </v-col>
        </v-row>
        <v-row
            v-if="itemsPerPage < titles.length"
            justify="center">
            <v-col cols="auto">
                <v-btn
                    text
                    color="primary"
                    @click="itemsPerPage = itemsPerPage+10">
                    Show More
                    <v-icon right>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    import TitleList from '../../content/title/TitleList.vue';

    export default {
        mixins: [
            Mobile
        ],
        components: {
            TitleList
        },
        props: {
            documentId: {
                required: true,
                type: Number
            }
        },
        data() {
            return {
                loading: true,
                itemsPerPage: 10,
                titles: []
            }
        },
        methods: {
            getRelatedTitles() {
                this.$http.get(
                    Routing.generate('get_latest_version', {'documentId': this.documentId})
                ).then(response => {
                    if (response.data) {
                        this.titles = response.data;
                    }
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.getRelatedTitles();
        }
    }
</script>
