<template>
    <div>
        <v-navigation-drawer
            v-model="categoryLegend"
            app
            clipped
            height="100vh"
            class="grey lighten-5">
            <search-navigation>
            </search-navigation>
        </v-navigation-drawer>
        <breadcrumb :data="breadcrumbData" :title-search-term="searchData.searchTerm"></breadcrumb>
        <v-container :fluid="!isExtraLarge">
            <v-row>
                <v-col>
                    <astm-subjects v-if="isAstmSubjectPage && isAstmCompassEnabled()">
                    </astm-subjects>
                    <template v-else>
                        <codes-result-navigation-controls
                            :category="searchData.category"
                            :show-years="showYearFilter"
                            :loading="resultsLoading">
                        </codes-result-navigation-controls>
                        <template v-if="resultsLoading">
                           <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        class="py-6"
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            <title-list
                                v-if="pagination.totalResults > 0"
                                :titles="results"
                                :loading="resultsLoading"
                                :itemsPerPage="itemsPerPage"
                                displayBorder
                                displayUserTypeLabel
                                :displayInCols="isMobilePort ? 12 : 6">
                            </title-list>
                            <v-row
                                justify="center"
                                v-else>
                                <v-col cols="auto">
                                    <no-results-found></no-results-found>
                                </v-col>
                            </v-row>
                        </template>
                        <v-row
                            v-if="page < pagination.maxPerPage && !resultsLoading"
                            dense
                            justify="center">
                            <v-col cols="auto">
                                <v-btn
                                    text
                                    color="primary"
                                    class="mb-4"
                                    :loading="showMoreLoading"
                                    @click="showMore">
                                    Show More
                                    <v-icon right>
                                        mdi-chevron-down
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
                <v-col
                    v-show="searchData.category"
                    cols="2"
                    class="px-3">
                    <category-tabs
                        :category="searchData.category"
                        :is-astm-standard-page="isAstmStandardPage">
                    </category-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Codes from './mixins/codes';
    import CategoryTabs from './partials/categoryTabs';
    import AstmSubjects from './astm/Subjects';
    import CodesResultNavigationControls from "./CodesResultNavigationControls";
    import NoResultsFound from "./NoResultsFound";
    import TitleList from '../content/title/TitleList.vue';

    export default {
        mixins: [
            Codes
        ],
        components: {
            NoResultsFound,
            CodesResultNavigationControls,
            CategoryTabs,
            AstmSubjects,
            TitleList
        }
    }
</script>

<style lang="scss" scoped>
    #navigation-container {
        max-width: 320px;
    }
    .v-tab {
        min-width: unset;
        text-transform: unset;
        font-size: 12px;
        padding: 0 10px;
    }
</style>
