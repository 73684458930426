var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        large: _vm.large,
        block: _vm.block,
        color: "primary",
        target: "_blank",
        href: "https://www.iccsafe.org/products-and-services/technical-opinions/request-for-code-opinion/",
      },
    },
    [_vm._v("\n    Ask your question\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }