<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search your titles"
                        outlined
                        hide-details
                        clearable
                        background-color="white">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2 overflow-y-auto"
                max-height="200px">
                <v-treeview
                    v-model="searchTitles"
                    dense
                    selectable
                    :multiple="true"
                    return-object
                    :search="query"
                    selected-color="accent2"
                    :items="titlesArray"
                    item-key="id">
                    <template v-slot:label="{ item }">
                        <p class="my-1 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-buttons
                class="mt-2"
                :filter="{
                    key: 'titles',
                    value: searchTitles
                }"
                @clearSearch="removeAll(true)">
            </filter-buttons>
        </div>
        <div v-show="!expand">
            <template v-if="activeTitles.length > 0">
                <v-card
                    flat
                    :max-height="activeTitles.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeTitles.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeTitles"
                        :key="key"
                        x-small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary"
                        style="height: auto;">
                        {{ item.name }}
                        <v-icon @click="removeTitle(item.id)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeTitles.length > 0"
                        @click="removeAll(true)">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all Premium Titles
            </p>
        </div>
    </div>
</template>

<script>
    import ContentSearch from '../../../mixins/TabbedSearch';
    import FilterButtons from "../FilterButtons.vue";

    export default {
        components: {FilterButtons},
        data() {
            return {
                query: null,
                activeTitles: []
            }
        },
        props: {
            expand: {
                type: Boolean,
                default: false
            }
        },
        mixins: [
            ContentSearch
        ],
        computed: {
            titlesArray() {

                // [2015, 2018...]
                const yearsOnly = (this.searchYears || []).map((item) => {
                    return item.name;
                });

                // ["I-Codes", "Collections", ...]
                const categoryNamesOnly = (this.searchCategories || []).map((item) => {
                    return item.name;
                });

                const premiumTitles = this.$store.getters.getUserTitles.filter((title) => {

                    if (this.query && title.name.toLowerCase().includes(this.query.toLowerCase()) === false) {
                        return false;
                    }

                    if (yearsOnly.length && yearsOnly.includes(title.year) === false) {
                        return false;
                    }

                    if (categoryNamesOnly.length) {

                        if (categoryNamesOnly.filter((name) => {
                            return title.category.includes(name);
                        }).length === 0) {
                            return false;
                        }
                    }

                    return true;

                })
                //Sort mixed alpha/numeric array
                let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'accent'});
                return premiumTitles.sort((x,y) => collator.compare(x,y));
            }
        },
        methods: {
            removeTitle(id) {
                const storeIndex = this.activeTitles.findIndex((title) => {
                    return title.id === id
                })
                if (storeIndex > -1) {
                    this.activeTitles.splice(storeIndex, 1);
                    this.searchTitles = this.activeTitles;
                    EventBus.fire('update-content-search-filters', {key: 'titles', value: [...this.searchTitles]})
                    EventBus.fire('update-content-search-results')
                }
            },
            removeAll(performSearch = false) {
                this.$set(this, 'searchTitles', []);
                this.activeTitles = [];
                EventBus.fire('update-content-search-filters', {key: 'titles', value: []});
                this.$emit('closeExpand');
                if (performSearch) {
                    EventBus.fire('update-content-search-results');
                }
            }
        },
        created() {
            EventBus.listen('remove-all-titles', () => this.removeAll());
            EventBus.listen('remove-single-title', (id) => this.removeTitle(id));
            EventBus.listen('reset-title-filter', () => this.removeAll());
            EventBus.listen('set-title-filter', (titles) => {
                this.searchTitles = titles;
                this.activeTitles = titles;
            });
        }
    }
</script>
