<template>
    <div>
        <p class="license-book-title ma-0" v-bind:class="{ cp: cursorPointer, 'body-2': isMobilePort}">
            {{ title }}
        </p>
        <p class="ma-0" v-if="'Premium Complete' !== title" v-bind:class="{ cp: cursorPointer }">
            <small v-if="print_version"> {{ print_version }} </small> 
            <small v-else> ({{ print_version_edition }} Version: {{ printing_text }}) </small>
        </p>
    </div>
</template>

<script>
    import Mobile from '../../../inc/mixins/mobile';
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            print_version_edition: {
                type: String,
                required: false
            },
            printing_text: {
                type: String,
                required: false
            },
            print_version: {
                type: String,
                required: false
            },
            cursorPointer: {
                type: Boolean,
                required: false
            }
        },
        mixins: [Mobile]
    }
</script>
