<template>
    <div class="d-flex flex-column justify-space-between h-full">

        <div class="h-full d-flex flex-column align-center">

            <chat-message
                    type="system"
                    class="mb-1">

                <p>
                    Hello, I am ICC’s AI Navigator. I am trained to answer simple code questions. Please ask me a question and I’ll do my best. I am still learning, so your feedback on my answer helps me get better.
                </p>

              <p class="font-weight-bold mb-2">
                <template v-if="isRecentAskedQuestionsAvailable">
                  Your most recently asked questions:
                </template>
                <template v-else>
                  Not sure where to start? Try one of these questions:
                </template>
              </p>

              <v-progress-linear
                        v-if="loadingQuestion"
                        :active="true"
                        color="primary"
                        indeterminate
                        style="min-width: 10em;"
                ></v-progress-linear>
                <p
                    v-else
                    v-for="(question, key) in questions" class="mb-2"
                    :key="key">
                    <a href="#" @click.prevent="questionClicked(question)">
                        {{ isRecentAskedQuestionsAvailable ? question.question : question}}
                    </a>
                </p>

            </chat-message>

        </div>

        <div v-if="canSendMessages" class="d-none d-md-flex">

            <div class="primaryLight rounded pa-3">
                <v-icon color="black">mdi-arrow-down</v-icon>
                Start with your question here
            </div>
        </div>

    </div>
</template>

<script>
import AssetImage from "../AssetImage.vue";
import ChatMessage from "./chat/ChatMessage.vue";
import { ApiService } from "../../api/ApiService";

export default {
    components: {ChatMessage, AssetImage},
    emits: ['question-clicked'],
    props: {
        canSendMessages: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            loadingQuestion: true,
            questions: [
                'Is there a minimum temperature that a home heating system must be able to provide heat for?',
                'Are drinking fountains required in restaurants?',
                'In a school, how much artwork is permitted on the walls of corridors and classrooms?'
            ],
            isRecentAskedQuestionsAvailable: false,
        }
    },
    methods: {
        questionClicked(question) {
            if (question) {
                this.$emit('question-clicked', question);
            }
        }
    },
    mounted(){
        ApiService.getRecentAskedQuestions()
            .then((data) => {
                if (data && data.length > 0) {
                    this.isRecentAskedQuestionsAvailable = true;
                    this.questions = data;
                }
            })
            .finally(()=>{
                this.loadingQuestion = false
            });
    }
}
</script>
