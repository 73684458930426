var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "divider-expand",
    {
      attrs: { "open-by-default": _vm.showRelevantTitles },
      scopedSlots: _vm._u([
        {
          key: "expand",
          fn: function () {
            return [
              !_vm.loading.books && _vm.books.length
                ? _c("title-list", {
                    attrs: {
                      titles: _vm.books,
                      loading: _vm.loading.books,
                      itemsPerPage: _vm.itemsPerPage,
                    },
                  })
                : _vm.loading.books
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center px-8" },
                        [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              "\n                    Loading Relevant Titles ...\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _c("p", { staticClass: "caption primary--text mb-2" }, [
                          _vm._v(
                            "\n                    No relevant titles present.\n                "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.itemsPerPage < _vm.books.length
                ? _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.activateTitlesTab },
                            },
                            [
                              _vm._v(
                                "\n                    Show More\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "fs-16 mb-0" }, [
        _vm._v("\n        Relevant Titles\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }