var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5", attrs: { "data-qa": "content-search" } },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { app: "", clipped: "", width: "360px" } },
        [
          _c("content-search-filters", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isContentSearch,
                expression: "isContentSearch",
              },
            ],
            staticClass: "px-4 mb-4",
          }),
          _vm._v(" "),
          _c("title-search-filters", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTitleSearch,
                expression: "isTitleSearch",
              },
            ],
          }),
          _vm._v(" "),
          _c("graphics-search-filters", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isGraphicsSearch,
                expression: "isGraphicsSearch",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          staticClass: "white px-4",
          attrs: { "align-tabs": "left" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "v-tab",
            {
              staticClass: "font-weight-bold",
              class: _vm.activeTab === 0 ? "white--text" : "grey--darken-1",
            },
            [
              _c("v-icon", { staticClass: "pr-1" }, [
                _vm._v(
                  "\n                mdi-text-box-multiple-outline\n            "
                ),
              ]),
              _vm._v("\n            TITLES\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            {
              staticClass: "font-weight-bold px-4",
              class: _vm.activeTab === 1 ? "white--text" : "grey--darken-1",
            },
            [
              _c("v-icon", { staticClass: "pr-1" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.isContentSearchTabEnabled
                        ? "mdi-text-box-search-outline"
                        : "mdi-lock-outline"
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v("\n            CONTENT\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _vm.graphicsSearchEnabled
            ? _c(
                "v-tab",
                {
                  staticClass: "font-weight-bold px-4",
                  class: _vm.activeTab === 2 ? "white--text" : "grey--darken-1",
                },
                [
                  _c("v-icon", { staticClass: "pr-1" }, [
                    _vm._v("\n                mdi-image-outline\n            "),
                  ]),
                  _vm._v("\n            GRAPHICS\n        "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "grey lighten-5 px-4 content-container",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c("content-search-results", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isContentSearch && _vm.isContentSearchTabEnabled,
                expression: "isContentSearch && isContentSearchTabEnabled",
              },
            ],
          }),
          _vm._v(" "),
          _c("titles-result", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTitleSearch,
                expression: "isTitleSearch",
              },
            ],
          }),
          _vm._v(" "),
          _vm.isContentSearch && !_vm.isContentSearchTabEnabled
            ? _c("dummy-content-results")
            : _vm._e(),
          _vm._v(" "),
          _c("graphics-search-results", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isGraphicsSearch,
                expression: "isGraphicsSearch",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }