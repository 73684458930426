<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    My Purchases lists all Premium subscription licenses purchased through the ICC shop.
                    Individual licenses can be configured for personal use, assigned to a specific individual or made concurrent for shared access.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels accordion>
                    <v-data-iterator
                        :items="subscriptions"
                        :items-per-page="pagination.rowsPerPage"
                        :loading="loading"
                        :single-expand="true"
                        :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                        :server-items-length="pagination.totalItems"
                        class="elevation-0 no-margin parent-row">
                         <template v-slot:header>
                                <v-row class="header-row">
                                    <v-col
                                        v-for="(header, index) in headers"
                                        :key="index"
                                        :class="{
                                            'col-6 ml-2': index === 0,
                                            'col-4': index === 1
                                        }"
                                        class="caption font-weight-bold">
                                        {{header.text}}
                                    </v-col>
                                </v-row>
                            </template>
                        <template v-slot:default="data">
                            <v-expansion-panel v-for="item in data.items" :key="item.id">
                                <v-expansion-panel-header>
                                        <template v-slot:actions>
                                            <v-icon @click="checkPanelEvent(item)" color="primary">expand_more</v-icon>
                                        </template>
                                        <v-container pa-0>
                                            <v-row align="center" class="container-row">
                                                <v-col :cols="6">
                                                    <v-row align="center">
                                                        <v-col sm="auto">
                                                            <a @click="bookLink(item.document_info)">
                                                                <graphic
                                                                    :title="item.document_info"
                                                                    thumbnail
                                                                    width="40px"
                                                                    additionalClasses="mr-2">
                                                                </graphic>
                                                            </a>
                                                        </v-col>
                                                        <v-col>
                                                            <a @click="bookLink(item.document_info)">
                                                                <book-title
                                                                    :title="getBookTitle(item.document_info)"
                                                                    :print_version_edition="printEdition(item.document_info.print_version_edition.version)"
                                                                    :printing_text="item.document_info.print_info">
                                                                </book-title>
                                                            </a>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col :cols="4" class="licenses-head">
                                                    <v-row>
                                                        <v-col>
                                                            <v-chip
                                                            label
                                                            color="grey lighten-4"
                                                            class="elevation-2"
                                                            @click="changeTabTo('PersonalUse')">
                                                            <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'available')}}</v-avatar>
                                                            Personal
                                                            </v-chip>
                                                        </v-col>
                                                        <v-col>
                                                            <v-chip
                                                            label
                                                            color="grey lighten-4"
                                                            class="elevation-2 mx-2"
                                                            @click="changeTabTo('AssignedByMe')">
                                                            <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'assigned')}}</v-avatar>
                                                            Assigned
                                                            </v-chip>
                                                        </v-col>
                                                        <v-col>
                                                            <v-chip
                                                            label
                                                            color="grey lighten-4"
                                                            class="elevation-2"
                                                            @click="changeTabTo('Concurrent')">
                                                            <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'concurrent')}}</v-avatar>
                                                            Concurrent
                                                            </v-chip>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                                <v-col class="sku-head">
                                                    {{item.book_sku}}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-header>
                                <v-expansion-panel-content class="grey lighten-4">
                                    <v-data-table
                                        :headers="subscription_details"
                                        :items="item.group_licenses"
                                        hide-default-footer
                                        class="no-margin details-row">
                                        <template v-slot:item="{ item }">
                                            <tr class="green lighten-5 text-center">
                                                <td>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Licenses</p>
                                                            {{item.licenses}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Transaction Id</p>
                                                            {{item.purchase_id}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Type</p>
                                                            {{checkSubscriptionType(item.subscription_type)}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                            {{item.status}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Purchase Date</p>
                                                            {{item.purchase_date | moment("MMMM Do, YYYY")}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                            {{item.expiration_date | moment("MMMM Do, YYYY")}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <v-btn
                                                                small
                                                                mt-2
                                                                @click="gotToMyeCodes">
                                                                {{getSubscriptionActionLabel(item.subscription_type)}}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <template slot="no-data">
                            <v-card v-if="!subscriptions_loaded">
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </v-card>
                            <p
                                class="mt-4"
                                v-if="subscriptions_loaded">You do not have any Premium subscriptions.</p>
                        </template>
                    </v-data-iterator>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import Purchases from '../mixins/purchases.js'
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [Licenses, Purchases],
        components: {
            Graphic
        },
        data() {
            return {
                headers: [
                    {text: 'Book Title', align: 'left', value: 'di.title', expand: true},
                    {text: 'Licenses', align: 'center', value: 's.licenses'},
                    {text: 'SKU', align: 'left', value: 'di.sku'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false},
                ],
                panel: []
            };
        },
        methods: {
            checkPanelEvent(panelKey){
                if(!this.panel.length){
                    this.panel = [...Array(this.subscriptions.length).keys()].map(_ => false);
                }
                let array = [];
                this.panel.forEach(function(item, key){
                    array[key] = (key === panelKey.index) ? !item : item;
                });

                this.panel = array;
            },
        },
        mounted() {
            this.getUserAllSubscriptions();
        }
    }

</script>

<style>
    .parent-row, .details-row {
       width:100%;
    }

    .header-row, .container-row {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
    }

    .header-row .caption.col-4, .licenses-head{
        max-width: 200px;
    }

    .header-row .caption.col:not(.col-6,.col-4,:last-child), .sku-head {
        max-width: 100px;
    }
</style>