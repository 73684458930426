<template>
    <v-row justify="end">
        <v-col cols="auto">
            <v-btn
                v-if="step === 2"
                class="mr-2 font-weight-bold"
                text
                @click="goBack">
                Back
            </v-btn>
            <v-btn
                class="mr-2 font-weight-bold"
                outlined
                @click="emitClosed()">
                Cancel
            </v-btn>
            <v-btn
                v-if="step == 1"
                class="font-weight-bold"
                color="accent2"
                :disabled="isDisableReviewBtn"
                @click="reviewChanges"
                depressed>Review
            </v-btn>
            <v-btn
                v-else
                @click="submitAndPay"
                color="accent2"
                depressed
                class="font-weight-bold"
                :disabled="loadingSubmit"
                :loading="loadingSubmit">
                {{ submitText }}
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>

export default {
    props: {
        step: {
            required: true,
            type: Number
        },
        isDisableReviewBtn: {
            type: Boolean,
            required: false
        },
        loadingSubmit: {
            type: Boolean,
            required: false
        },
        submitText: {
            type: String,
            required: false,
            default: 'Submit'
        }
    },
    methods: {
        goBack() {
            this.$emit('go-back');
        },
        emitClosed() {
            this.$emit('close');
        },
        reviewChanges() {
            this.$emit('review-changes');
        },
        submitAndPay() {
            this.$emit('submit-and-pay');
        }
    }
}
</script>
