<template>
    <v-dialog
        v-model="dialog"
        max-width="500px">
        <v-card>
            <v-card-title
                class="font-weight-bold oxygen fs-24"
                :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`"
                :class="{
                    'caption mobile-title': isMobilePort
                    }">
                    <v-icon
                        class="mr-2"
                        color="accent2">
                        mdi-creation
                    </v-icon>
                    Premium Code Tools
                <v-spacer></v-spacer>
                <v-icon
                    class="black--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0 pb-2">
                <v-row class="grey lighten-4 pa-0 ma-0">
                    <v-col class="text-center pa-0">
                        <img
                            class="section-img"
                            alt="Premium Tools"
                            :src="asset(require('../../images/premium_tools/codes-code-tools.png'), 'default')"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="overflow-hidden text-center pa-3 body-1">
                            Copy codes, print code sections, bookmark and more with a Premium subscription.
                        </p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="center">
                    <v-col cols="auto">
                        <start-free-trial
                            v-if="!isAuthenticated || canStartTrial"
                            :large="false"
                            :directStartTrial="canStartTrial"
                            class="mx-1">
                        </start-free-trial>
                        <v-btn
                            :outlined="isTrialAvailable"
                            depressed
                            :color="isTrialAvailable ? 'primary' : 'accent2'"
                            class="font-weight-bold"
                            :class="isTrialAvailable ? 'mx-1' : ''"
                            @click="openSubscribeToPremiumDialog()">
                            Subscribe To This Title
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import StartFreeTrial from "../buttons/StartFreeTrial.vue";
    import Mobile from '../inc/mixins/mobile'

    export default {
        data() {
            return {
                dialog: false
            }
        },
        components: {StartFreeTrial},
        mixins: [
            Mobile
        ],
        computed: {
            documentInfo() {
                return this.$store.getters.getDocumentInfo;
            },
            isTrialAvailable() {
                return (!this.isActiveUser || this.canStartTrial);
            }
        },
        methods: {
            openSubscribeToPremiumDialog(){
                this.dialog = !this.dialog;
                EventBus.fire('show-premium-subscribe-dialog', this.documentInfo);
            }
        },
        created(){
            EventBus.listen('subscription-prompt', () => {this.dialog = true;});
        }
    }
</script>
<style lang="scss" scoped>
    .section-img {
        width: 100%;
        margin-bottom: -16px;
    }
</style>