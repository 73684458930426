<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="isMobilePort"
        scrollable
        max-width="700px">
        <v-card>
            <v-card-title
                class="font-weight-bold oxygen fs-20"
                :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`">
                    <v-icon
                        class="mr-2"
                        color="accent2">
                        mdi-creation
                    </v-icon>
                    Get Started with Premium
                <v-spacer></v-spacer>
                <v-icon
                    class="black--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <p class="my-4 fs-16 font-weight-medium">
                    Premium gives you code tools that go beyond Basic read-only access:
                </p>
                <v-row
                    v-for="(item, key) in premiumTools"
                    :key="key"
                    dense>
                    <v-col cols="auto">
                        <v-chip
                            v-if="!item.icon"
                            x-small
                            class="px-1 font-weight-bold mx-1"
                            color="accent2"
                            title=""
                            label
                            text-color="white">
                            P
                        </v-chip>
                        <v-icon
                            v-else
                            color="accent2">
                            {{item.icon}}
                        </v-icon>
                    </v-col>
                    <v-col>
                        <p class="font-weight-medium mb-1 fs-16">
                            <b>{{item.title}}</b>
                        </p>
                        <p style="line-height: 24px;" class="fs-16 mb-2 roboto">
                            {{item.description}} 
                            <a :href="item.learnMorelink" class="text-decoration-underline" target="_blank">Learn More</a>
                        </p>
                    </v-col>
                </v-row>
                <p class="fs-16 mt-4 mb-0 font-weight-medium">
                    Do more with your Premium subscription:
                </p>
                <v-row>
                    <v-col>
                        <v-row dense>
                            <v-col>
                                <b class="fs-16">Code Intelligence Tools</b>
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, key) in leftButtons" :key="key">
                            <v-col class="py-2">
                                <v-btn
                                    outlined
                                    color="primary"
                                    :href="item.link"
                                    target="_blank"
                                    block
                                    >
                                    <v-icon color="primary" class="pr-1">
                                    {{ item.icon }}
                                    </v-icon>
                                    {{ item.title }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row dense>
                            <v-col>
                                <b class="fs-16">Onboarding Resources</b>
                            </v-col>
                        </v-row>
                        <v-row v-for="(item, key) in rightButtons" :key="key">
                            <v-col class="py-2">
                                <v-btn
                                    outlined
                                    color="primary"
                                    :href="item.link"
                                    target="_blank"
                                    block
                                    >
                                    <v-icon color="primary" class="pr-1">
                                    {{ item.icon }}
                                    </v-icon>
                                    {{ item.title }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    dense
                    align="center">
                    <v-col :cols="isMobilePort ? '12' : ''">
                        <p class="mb-0">
                            <b>Need support? <a :href="this.$getConst('links')['contactSupport']" class="text-decoration-underline" target="_blank">Contact Us</a></b>
                        </p>
                    </v-col>
                    <v-col :class="!isMobilePort ? 'text-right' : 'text-center pt-2'">
                        <v-btn color="primary" @click="dialog = false">
                            Close
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        name: 'PremiumFeaturesDialog',
        data() {
            return {
                dialog: false,
                category: {},
                loading: false,
                premiumTools: [
                    {
                        icon: 'mdi-dots-vertical',
                        title: 'Toolbar',
                        learnMorelink: this.$getConst('links')['toolbarSupportPage'],
                        description: 'Each code section has a toolbar in the top right. Access the toolbar to copy, paste, print and share code sections.'
                    },
                    {
                        icon: 'mdi-bookmark-outline',
                        learnMorelink: this.$getConst('links')['notesSupportPage'],
                        title: 'Notes',
                        description: 'Create Notes by bookmarking a section or highlighting text. Add optional comments and tag it to a project to organize. Find your Notes quickly under the Notes tab in the Table of Contents.'
                    },
                    {
                        title: 'Insights',
                        learnMorelink: this.$getConst('links')['insightsSupportPage'],
                        description: 'Utilize code Insights such as commentaries, change details and hearing videos. Insights are linked under relevant codes and you can find more under the Insights tab in the Table of Contents.'
                    },
                    {
                        icon: 'mdi-magnify',
                        title: 'Search',
                        learnMorelink: this.$getConst('links')['searchSupportPage'],
                        description: 'Search keywords in a title individually or search all of your Premium titles. Refine your search with filters by title, year, category and more.'
                    },
                ],
                leftButtons: [
                    {
                        icon: 'mdi-navigation-outline',
                        title: 'AI Navigator',
                        link: this.getRoute('features_ai_navigator'),
                    },
                    {
                        icon: 'mdi-forum-outline',
                        title: 'Premium Answers',
                        link: this.getRoute('premium_answers'),
                    },
                    {
                        icon: 'mdi-calculator-variant-outline',
                        title: 'Compliance Calculators',
                        link: this.getRoute('compliance_calculators'),
                    },
                ],
                rightButtons: [
                    {
                        icon: 'mdi-video-outline',
                        title: 'Videos',
                        link: this.$getConst('links')['welcomeVideo'],
                    },
                    {
                        icon: 'mdi-lightning-bolt-outline',
                        title: 'Quick Start Guide',
                        link: this.$getConst('links')['quickStart'],
                    },
                    {
                        icon: 'mdi-monitor-account',
                        title: 'Webinars',
                        link: this.getRoute('webinars'),
                    },
                ]
            }
        },
        mixins: [
            Mobile
        ],
        computed: {
            
        },
        methods: {
            
            
        },
        created(){
            EventBus.listen('show-premium-features-dialog', () => {
                this.dialog = true
            });
        }
    }
</script>
