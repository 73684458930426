<template>
    <v-dialog
        v-model="dialog"
        width="1200"
        scrollable
        :fullscreen="isMobileAndTablet"
        style='z-index:20001;'>
        <v-card
            min-height="80vh">
            <div class="primary white--text">
                <v-row>
                    <v-col
                        sm="5">
                        <v-row>
                            <v-col
                                cols="auto"
                                class="pr-0">
                                <v-icon
                                    size="40px"
                                    class="ml-3"
                                    color="white">
                                    mdi-{{ category?.icon  }}
                                </v-icon>
                            </v-col>
                            <v-col >
                                <h3 class="word-break">
                                    {{ category?.name }}
                                    <v-icon
                                        v-if="isMobilePort && category?.description"
                                        small
                                        color="white"
                                        class="ml-1"
                                        @click="expand = !expand">
                                        mdi-information
                                    </v-icon>
                                </h3>
                                <p class="mb-0 caption word-break font-weight-bold">
                                    {{title}}
                                </p>
                                <p
                                    v-if="sectionTitle"
                                    class="mb-0 caption word-break">
                                    {{sectionTitle}}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <template v-if="!isMobilePort">
                        <v-col
                            v-if="category?.description"
                            :cols="isMobilePort && '12'"
                            :order="isMobilePort ? '2' : undefined"
                            class="pt-0"
                            align-self="center">
                            <p
                                class="mb-0 mr-4 caption word-break mt-3"
                                :class="isMobilePort && 'mx-2'">
                                {{ category?.description }}
                                <a
                                    v-if="category?.learnMoreUrl"
                                    class="white--text text-decoration-underline pl-1"
                                    style="font-size: 14px;"
                                    :href="category.learnMoreUrl"
                                    target="_blank">
                                    <b>{{category?.learnMoreLabel}}</b>
                                </a>
                            </p>
                        </v-col>
                        <v-spacer v-else>
                        </v-spacer>
                    </template>
                    <v-col cols="auto">
                        <v-icon
                            class="white--text mr-4"
                            @click="close()">
                            close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-expand-transition v-if="isMobilePort && category?.description">
                    <div v-show="expand">
                        <v-divider>
                        </v-divider>
                        <p
                            class="ma-2 caption">
                            {{ category?.description }}
                            <a
                                v-if="category?.learnMoreUrl"
                                class="white--text text-decoration-underline pl-1"
                                style="font-size: 14px;"
                                :href="category.learnMoreUrl"
                                target="_blank">
                                <b>{{category?.learnMoreLabel}}</b>
                            </a>
                        </p>
                    </div>
                </v-expand-transition>
            </div>

            <loading-linear v-if="isLoading">
            </loading-linear>
            <template v-else>
                <template v-if="tabs.length">
                    <div
                        v-if="category?.dropdown"
                        class="grey lighten-3 pa-3">
                        <v-select
                            v-model="tab"
                            outlined
                            dense
                            hide-details
                            :items="tabs"
                            item-value="key"
                            item-text="title"
                            background-color="white"
                            :menu-props="{ bottom: true, offsetY: true }">
                        </v-select>
                    </div>
                    <v-tabs
                        v-else
                        v-model="tab"
                        show-arrows
                        color="primary"
                        background-color="grey lighten-3"
                        active-class="tags-history-tab"
                        style="flex: unset;">

                        <v-tab
                            v-for="item in tabs"
                            :key="item.id">
                            {{ item.title }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items
                        class="fill-height overflow-auto"
                        v-model="tab">
                        <v-tab-item
                            class="fill-height"
                            v-for="(item, key) in tags"
                            :key="key">
                            <v-card flat height="100%">
                                <v-card-text
                                    class="fill-height"
                                    :class="getInsightType(item) === 'PDF' || getInsightType(item) === 'compliance calculators' ? 'pa-0' : 'pa-5'">
                                    <premium-answers-content
                                        v-if="getInsightType(item) === 'premium answers'"
                                        :tag="item">
                                    </premium-answers-content>
                                    <i-frame-content
                                        v-if="getInsightType(item) === 'compliance calculators'"
                                        :tag="item">
                                    </i-frame-content>
                                    <p-d-f-content
                                        v-if="getInsightType(item) === 'PDF'"
                                        :tag="item">
                                    </p-d-f-content>
                                    <link-content
                                        v-if="getInsightType(item) === 'Link'"
                                        :tagData="item">
                                    </link-content>
                                    <video-content
                                        v-if="getInsightType(item) === 'Video'"
                                        class="text-center"
                                        :tagData="item">
                                    </video-content>
                                    <title-content
                                        v-if="getInsightType(item) === 'Text'"
                                        :tagData="item">
                                    </title-content>
                                    <x-m-l-id-content
                                        v-if="getInsightType(item) === 'xml'"
                                        :tagData="item">
                                    </x-m-l-id-content>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    <clear-calcs-footer v-if="displayClearCalcFooter"></clear-calcs-footer>
                    <premium-tag-rating
                            v-if="!hasTagRating"
                            :tag="tagId"
                            @submit="onRatingSubmit"
                    ></premium-tag-rating>

                </template>

                <div v-else
                     class="pa-5">
                    <h2>No results found...</h2>
                </div>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
import TitleContent from "./modal-tags-content/TitleContent.vue";
import PDFContent from "./modal-tags-content/PDFContent.vue";
import IFrameContent from "./modal-tags-content/IFrameContent.vue";
import VideoContent from "./modal-tags-content/VideoContent.vue";
import LinkContent from "./modal-tags-content/LinkContent.vue";
import LoadingLinear from "../loaders/LoadingLinear.vue";

import Mobile from "../inc/mixins/mobile";
import axios from "axios";
import {ObjectUtils} from "../../util/ObjectUtils";
import PremiumTagRating from "./PremiumTagRating.vue";
import ClearCalcsFooter from "./ClearCalcsFooter.vue";
import {ApiService} from "../../api/ApiService";
import XMLIdContent from "./modal-tags-content/XMLIdContent.vue";
import PremiumAnswersContent from "./modal-tags-content/PremiumAnswersContent.vue";

export default {
    components: {
        PremiumAnswersContent,
        XMLIdContent,
        PremiumTagRating,
        TitleContent,
        PDFContent,
        IFrameContent,
        VideoContent,
        LinkContent,
        LoadingLinear,
        ClearCalcsFooter
    },
    mixins: [Mobile],
    data() {
        return {
            expand: false,
            dialog: false,
            category: null,
            contentId: null,
            title: null,
            sectionTitle: null,
            isLoading: true,
            tab: 0,
            // category specific tags returned from "get_report_tags"
            tags: [],
            displayClearCalcFooter: false
        }
    },
    computed: {
        selectedCategoryId() {
            return this.category ? this.category.id : null;
        },
        tabs() {
            const tags = this.tags || [];
            return tags.map((value, key) => {
                return {
                    key: key,
                    id: value.id,
                    title: value.tag.title
                }
            });
        },
        tagContent(){
            return this.tags[this.tab];
        },
        tagId() {
            return ObjectUtils.dottedGet(this.tagContent, 'tag.id');
        },
        hasTagRating() {
            return this.tagContent?.user_rating !== null;
        }
    },
    methods: {
        getInsightType(item) {
            return item.tag.content_type.name
        },
        onRatingSubmit(rating) {
            setTimeout(() => {
                this.tags[this.tab]['user_rating'] = rating;
            }, 2000);
        },
        logTagClick(isDefault) {
            const id = this.tagId;
            ApiService.logPremiumTagClick(id, isDefault);
        },
        loadTagsForCategory() {

            this.isLoading = true;

            // load tags from ALL categories at first...
            axios.get(Routing.generate('get_report_tags', {
                contentId: this.contentId,
                chapterId: this.contentId,
                reportType: 'premiumcontent',
                reportTypeShow: 'premiumContentShow',
                codeInsightId: this.category?.id
            })).then(response => {
                const data = response.data;

                const rct = data.reportCategoryTags;

                // only interested in tags from SPECIFIC category
                if (this.selectedCategoryId && this.selectedCategoryId in rct) {
                    this.tags = data.reportCategoryTags[this.selectedCategoryId].category;
                    let categoryName = data.reportCategoryTags[this.selectedCategoryId]?.categoryName;
                    if (categoryName && categoryName.toLowerCase() === 'compliance calculators') {
                        this.displayClearCalcFooter = true;
                    } else {
                        this.displayClearCalcFooter = false;
                    }
                    this.logTagClick(true);
                }

            }).finally(() => {
                this.isLoading = false;
            });
        },
        close() {
            this.dialog = false;
        }
    },
    created() {

        EventBus.listen('show-premium-tag-modal', (data) => {
            this.category = data.category;
            this.contentId = data.contentId;
            this.title = data.meta.title;
            this.sectionTitle = data.meta.sectionTitle;
            this.dialog = true;

            this.loadTagsForCategory();
        });

        // user clicked to go on another tab - does not trigger initially
        this.$watch(() => this.tab, (newValue) => {
            this.logTagClick(false);
        });
    }
}
</script>

<style lang="scss">
.tags-history-tab {
    background: unset!important;
    color: unset!important;
}
.word-break {
    word-break: break-word !important;
}
</style>
