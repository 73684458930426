var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { class: _vm.isMobilePort ? "text-center" : "" }, [
            _c("h1", { staticClass: "primary--text font-weight-regular" }, [
              _vm._v("\n                Sign Up\n            "),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { class: _vm.isMobilePort ? "text-center" : "" }, [
            _c("p", { staticClass: "primary--text" }, [
              _vm._v("\n                Already have an account? "),
              _c("a", { attrs: { href: _vm.getRoute("security_login") } }, [
                _c("b", [_vm._v("Sign In")]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { class: _vm.isMobilePort ? "text-center" : "" },
                    [
                      _c("h2", { staticClass: "primary--text oxygen" }, [
                        _vm._v(
                          "\n                        Register for a free ICC Digital Codes account\n                    "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("registration-form", {
                attrs: { isTrialRegistration: _vm.isTrialRegistration },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "5" } },
            [
              _c("up-sell-vertical", {
                attrs: {
                  image: {
                    src: "illistrations/key_holder.webp",
                  },
                  heading: "With your free Digital Codes account you can:",
                  description: _vm.buildUlList(),
                  options: { color: "grey lighten-3" },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }