var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400" },
      model: {
        value: _vm.checkoutModal,
        callback: function ($$v) {
          _vm.checkoutModal = $$v
        },
        expression: "checkoutModal",
      },
    },
    [
      Object.keys(_vm.product).length
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary headline",
                  staticStyle: { color: "white" },
                },
                [_vm._v("\n            Item Added to Cart\n        ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { sm: "auto" } }, [
                        _c("div", { staticClass: "image-container" }, [
                          _vm.product.document_info.content_type.id === 2
                            ? _c("img", {
                                staticClass: "pdf",
                                attrs: {
                                  alt: "Default Book Cover",
                                  src: _vm.asset(
                                    require("../../../images/icons/pdf_book_cover.png")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "cover",
                            attrs: {
                              alt:
                                _vm.product.document_info.year.year +
                                " " +
                                _vm.product.document_info.title +
                                " Book Cover",
                              src: _vm.getBookCover(_vm.product.document_info),
                              width: "100%",
                            },
                            on: { error: _vm.loadDefaultBookCover },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c(
                          "h5",
                          { staticClass: "body-2 font-weight-bold" },
                          [
                            _vm.product.document_info.year
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.product.document_info.year.year
                                      ) +
                                      " " +
                                      _vm._s(_vm.product.document_info.title) +
                                      "\n                        "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.product.document_info.title) +
                                      "\n                        "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "body-2 mb-0" }, [
                          _vm._v(
                            "Item #: " + _vm._s(_vm.product.document_info.sku)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "body-2" }, [
                          _vm._v(
                            "\n                        Quantity: " +
                              _vm._s(_vm.product.quantity) +
                              " | "
                          ),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("$" + _vm._s(_vm.totalPrice)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.checkoutModal = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Continue Browsing\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isMobilePort ? _c("v-spacer") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: "primary",
                        small: "",
                        href: _vm.getRoute("cart_page"),
                      },
                    },
                    [_vm._v("\n                View Cart\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "accent2", small: "" },
                      on: { click: _vm.goToCheckout },
                    },
                    [_vm._v("\n                Checkout\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }