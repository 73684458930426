<template>
    <div class="mb-2 note-list-item">
        <v-row
            dense>
            <v-col cols="auto">
                <v-avatar
                    size="32"
                    class="default-color"
                    color="grey lighten-3">
                    {{ data.user.first_name[0] }}{{ data.user.last_name[0] }}
                </v-avatar>
            </v-col>
            <v-col>
                <p>
                    <span class="font-weight-bold default-color">
                        {{ data.user.first_name }} {{ data.user.last_name }}
                        <span v-show="createdByCurrentUser()" class="ml-1 default-color">(You)</span>
                    </span>
                    <span class="ml-2 text--secondary default-color" style="color: rgba(0,0,0,.6)!important;">
                        {{ getFormattedDate(data.created_date) }}
                        <span
                            v-show="data.modified_date && data.created_date !== data.modified_date"
                            class="ml-1 default-color"
                        >
                            (Edited {{ getFormattedDate(data.modified_date) }})
                        </span>
                    </span>

                </p>
                <p
                    v-if="type === 'note'"
                    class="note-list-quote font-italic pa-1 mb-0 mt-1 left default-color"
                >
                    "{{ data.annotation.quote }}"
                </p>
                <div
                    v-if="getDescription()"
                    v-html="getDescription()" class="highlight-text">
                </div>
                <v-chip
                    class="mt-1"
                    small
                    :color="getTagColor(data.tag)">
                    <span class="default-color">
                        {{ getTagName(data.tag) }}
                    </span>
                </v-chip>
            </v-col>
            <v-col cols="auto">
                <v-menu
                    left
                    offset-y
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            v-on:click="on.click"
                        >
                            <v-icon style="color: rgba(0,0,0,.54) !important;">
                                mdi-dots-vertical
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list dense style="max-width: 120px;">
                        <v-list-item-group>
                            <v-list-item @click="showShareModal(data.id)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-share-variant-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Share</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showEditModal(data)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showDeleteModal(data)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small class="red--text">mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="red--text">Delete</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {DateUtil} from "../../../util/DateUtil";
    export default {
        props: {
            type: {
                required: true,
                type: String
            },
            data: {
                required: true,
                type: Object
            }
        },
        computed: {
            tags() {
                return window.vm.$store.getters.getTags;
            }
        },
        created() {
            /**
             * WARNING:: This component does not appear to be built correctly.
             * The <v-menu> component will not open without this.
             */
            this.$vuetify.theme = window.vm.$vuetify.theme;
        },
        methods: {
            getFormattedDate(d) {
                return DateUtil.timeToMdy(d);
            },
            createdByCurrentUser() {
                return String(window.vm.$store.getters.getUserId) === String(this.data.created_by.id);
            },
            getDescription() {
                switch(this.type) {
                    case('note'):
                        return this.data.annotation.text;
                        break;
                    case('bookmark'):
                        return this.data.text;
                        break;
                }
            },
            getTagColor(id) {
                let tags = _.isUndefined(this.data.isUpdate) ? this.tags : this.getTagFromStore(id);
                let tag = _.find(tags, {'id': _.toInteger(id)});
                if (tag && tag.color) {
                    return '#'+tag.color;
                }else{
                    let storeTag = this.getTagFromStore(id);
                    return (!_.isUndefined(storeTag) && !_.isUndefined(storeTag.color)) ? '#'+storeTag.color : this.$getConst('defaultTagColor');
                }
            },
            getTagName(id) {
                let tags = _.isUndefined(this.data.isUpdate) ? this.tags : this.getTagFromStore(id);
                let tag = _.find(tags, {'id': _.toInteger(id)});
                if(tag){
                    return tag.tag;
                }else{
                    let storeTag = this.getTagFromStore(id);
                    return storeTag ? storeTag.tag : 'Default';
                }
            },
            getTagFromStore(id){
                return _.find(window.vm.$store.getters.getTags, {'id': _.toInteger(id)});
            },
            showShareModal(id) {
                switch(this.type) {
                    case('note'):
                        EventBus.fire('show-modal-share-annotation-bookmark', 'annotation_'+id);
                        break;
                    case('bookmark'):
                        EventBus.fire('show-modal-share-annotation-bookmark', 'bookmark_'+id);
                        break;
                }
            },
            showEditModal(data) {
                switch(this.type){
                    case 'note':
                        EventBus.fire('note-modal', {id : data.id});
                        break;
                    case 'bookmark':
                        EventBus.fire('bookmark-modal', {id : data.id});
                        break;
                }
            },
            showDeleteModal(data) {
                switch(this.type){
                    case 'note':
                        EventBus.fire('remove-note', {
                            'id': data.id,
                            'sectionID': data.section_number,
                            'tagID': data.tag,
                            'annotation': data.annotation
                        });
                        break;
                    case 'bookmark':
                        EventBus.fire('remove-bookmark', {
                            'id' : data.id,
                            'sectionId': data.section_id
                        });
                        break;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .note-list-quote {
        background: #e0e0e0;
        border-radius: 5px;
    }
</style>