/**
 *
 * @returns {string}
 */
const locationString = function () {
    return window.location.toString();
}

export class UrlUtil {

    static nameFromUrl(url) {
        const u = this.toUrl(url);
        return u ? u.pathname.split('/').pop() : "";
    }

    static getFromQueryOrDefault(name, defaultValue) {
        return (new URL(window.document.location)).searchParams.get(name) || defaultValue;
    }

    static updateQueryParam(url, name, value) {

        const urlObject = this.toUrl(url);

        if (urlObject) {
            urlObject.searchParams.set(name, value);
            return urlObject.toString();
        }

        return url;
    }

    /**
     *
     * @param {string} url
     * @return {URL|null}
     */
    static toUrl(url) {

        try {

            if (url.indexOf('/') === 0) {
                return new URL(url, document.location);
            }

            return new URL(url);

        } catch (err) {
            // do nothing
        }

        return null;
    }

    /**
     *
     * @returns {null|string}
     */
    static getDocumentSlugFromCurrentUrl() {

        /** @type Array */
        const matchArray = locationString().match(/content\/([a-z0-9-]+)/i);

        if (matchArray && matchArray.length === 2) {
            return matchArray[1];
        }

        return null;
    }

    /**
     *
     * @param newDocumentSlug
     * @returns {null|string}
     */
    static newDocumentUrlFromCurrentUrl(newDocumentSlug) {

        const documentSlug = UrlUtil.getDocumentSlugFromCurrentUrl();

        if (documentSlug) {

            // https://codes.iccsafe.org/content/{REPLACE_THIS}/chapter-10-means-of-egress#IBC2018P2_Ch10_Sec1001.1
            let newUrl = locationString().replace(/content\/([a-z0-9-]+)/i, 'content/' + newDocumentSlug);

            const hashReplaceRegExp = new RegExp('#' + documentSlug + '_');

            // https://codes.iccsafe.org/content/IBC2018P2/chapter-10-means-of-egress#{REPLACE_THIS}_Ch10_Sec1001.1
            newUrl = newUrl.replace(hashReplaceRegExp, '#' + newDocumentSlug + '_');

            return newUrl;
        }

        return null;
    }
}
