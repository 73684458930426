var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.data, function (reportTag) {
        return [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                reportTag.tag.url.length
                  ? _c("p", [
                      _c("iframe", {
                        attrs: {
                          src: reportTag.tag.url,
                          width: "98%",
                          height: "300",
                          allowfullscreen: "",
                        },
                      }),
                    ])
                  : _c("p", { staticClass: "red--text" }, [
                      _vm._v(
                        "\n                    Video not available.\n                "
                      ),
                    ]),
              ]),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }