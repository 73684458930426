<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    Assigned by Me licenses are those where the individual who purchases the premium subscription is different than the individual who is able to use the premium subscription.  Assigned licenses are dedicated access to the individual as identified by the email address.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    block
                    class="primary licenses-step-3_1"
                    @click="openAssignModal()">
                    Assign Subscription
                </v-btn>
            </v-col>
        </v-row>
        <v-expansion-panels accordion>
            <v-data-iterator
                ref="assigned_by_me_table"
                :items="subscriptions"
                :items-per-page="pagination.rowsPerPage"
                :loading="loading"
                :single-expand="true"
                :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                class="elevation-0 no-margin parent-row"
                :server-items-length="pagination.totalItems">
                <template v-slot:item="data">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <template v-slot:actions>
                                <v-icon @click="checkPanelEvent(data)" color="primary">expand_more</v-icon>
                            </template>
                            <v-row
                                dense
                                align="center">
                                <v-col cols="auto">
                                    <a @click="bookLink(data.item.document_info)">
                                        <graphic
                                            :title="data.item.document_info"
                                            thumbnail
                                            width="40px"
                                            additionalClasses="mr-2">
                                        </graphic>
                                    </a>
                                </v-col>
                                <v-col>
                                    <a @click="bookLink(data.item.document_info)">
                                        <book-title
                                            :title="getBookTitle(data.item.document_info)"
                                            :print_version_edition="printEdition(data.item.document_info.print_version_edition.version)"
                                            :printing_text="data.item.document_info.print_info"></book-title>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="grey lighten-4">
                            <v-data-table
                                :headers="assigned"
                                :items="data.item.assigned_by_me_licenses"
                                :loading="detailsLoading"
                                :items-per-page="-1"
                                hide-default-footer
                                class="no-margin details-row">
                                <template v-slot:item="{ item }">
                                    <tr class="green lighten-5 text-center">
                                        <td>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Assigned Licences</p>
                                                </v-col>
                                                <v-col>
                                                    <p  class="caption font-weight-bold mb-0">Assigned To</p>
                                                    <span v-if="item.assigned_to">{{item.assigned_to.email}}</span>
                                                    <span v-else> {{item.pending_user_email}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Assigned Date</p>
                                                    {{item.created_at | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Expiration Date</p>
                                                    {{getExpirationDate(data, item) | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Type</p>
                                                    {{checkSubscriptionType(data.item.subscription_type)}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                    {{checkStatus(getExpirationDate(data, item), item.status)}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    :class="{
                                                        'xs12': checkStatus(getExpirationDate(data, item), item.status) === 'Active',
                                                        'xs6': checkStatus(getExpirationDate(data, item), item.status) !== 'Active'
                                                    }">
                                                    <v-btn
                                                        small
                                                        @click="getLicenseUsageReport(data.item.document_info.sku)">
                                                        Usage Report
                                                    </v-btn>
                                                </v-col>
                                                <template v-if="checkStatus(getExpirationDate(data, item), item.status) !== 'Active'">
                                                    <v-col>
                                                        <v-btn small color="primary" dark @click="goToTheShop(shopEncodesUrl)">Renew</v-btn>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                            <v-row v-if="checkStatus(getExpirationDate(data, item), item.status) === 'Active'">
                                                <v-col>
                                                    <v-btn small color="primary" dark @click="editItem(data, item)">Reassign</v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-btn small color="error" dark @click="deleteItem(data, item)">Revoke</v-btn>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </template>
                <template slot="no-data">
                    <v-card v-if="!loading">
                        <v-progress-linear :indeterminate="true"></v-progress-linear>
                    </v-card>
                    <p
                        class="mt-4"
                        v-if="loading">You do not have any Premium subscriptions.</p>
                </template>
            </v-data-iterator>
        </v-expansion-panels>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Re-assign this subscription license</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-alert v-if="errorMessage.length" :value="true" outline color="error" icon="warning">
                                    {{errorMessage}}
                                </v-alert>
                                <v-text-field
                                    data-qa="dashboard-licenses-reassign-email"
                                    v-if="editedItem.assigned_to"
                                    v-model="editedItem.assigned_to.email"
                                    label="Enter new email address">
                                </v-text-field>
                                <v-text-field
                                    data-qa="dashboard-licenses-reassign-email"
                                    v-else
                                    v-model="editedItem.pending_user_email"
                                    label="Enter new email address">
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="close" v-if="!reassign_loading">Cancel</v-btn>
                    <v-btn color="primary" @click="save" :disabled="reassign_loading">
                        <span v-if="!reassign_loading">Save</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AssignModal :user-id="userId"></AssignModal>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import AssignedByMe from '../mixins/assigned_by_me.js';
    import Graphic from "../../../titles/cover/Graphic";
    import Constants from "../../../../constants/index";

    export default {
        mixins: [Licenses, AssignedByMe],
        components: {
            Graphic
        },
        data() {
            return {
                title_details: [
                    {text: 'Book Title', align: 'left', value: 'di.title'},
                ],
                shopEncodesUrl: Constants.shopEncodesUrl
            }
        },
        mounted() {
            this.getAssignedByMeSubscriptions();
        }
    }

</script>
