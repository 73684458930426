<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search title years"
                        outlined
                        hide-details
                        clearable>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-treeview
                    v-model="searchYears"
                    open-all
                    dense
                    selectable
                    :multiple="true"
                    return-object
                    :search="query"
                    item-key="id"
                    selected-color="accent2"
                    :items="yearArray">
                    <template v-slot:label="{ item }">
                        <p class="my-1 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-buttons
                class="mt-2"
                :filter="{
                    key: 'years',
                    value: searchYears
                }"
                @clearSearch="removeAll(true)">
            </filter-buttons>
        </div>
        <div v-show="!expand">
            <template v-if="activeYears.length > 0">
                <v-card
                    v-if="activeYears.length"
                    flat
                    :max-height="activeYears.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeYears.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeYears"
                        :key="key"
                        small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary">
                        {{ item.name }}
                        <v-icon @click="removeYear(item.id)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeYears.length > 0"
                        @click="removeAll(true)">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all years
            </p>
        </div>
    </div>
</template>

<script>
    import ContentSearch from "../../../mixins/TabbedSearch";
    import FilterButtons from "../FilterButtons.vue";

    export default {
        components: {FilterButtons},
        data() {
            return {
                query: null,
                activeYears: []
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            ContentSearch
        ],
        computed: {
            yearArray() {
                let _this = this;
                let yearArray = [];
                let titlesArray = this.$store.getters.getUserTitles;

                if (this.searchCategories.length) {
                    titlesArray = _.map(titlesArray, function(o) {
                        let match = false;
                        _.forEach(o.category, function(value) {
                            if (_.find(_this.searchCategories, {name: value})) {
                                match = true;
                            }
                        });
                        if (match) {
                            return o;
                        }
                    });
                    titlesArray = _.without(titlesArray, undefined);
                }

                _.map(titlesArray, (obj) => {
                    if (obj.year && !_.find(yearArray, {id: obj.year})) {
                        yearArray.push({id: obj.year, name: obj.year});
                    }
                })

                return yearArray;
            }

        },
        methods: {
            removeYear(id) {
                this.activeYears = _.filter(this.activeYears, function(o) {
                    return o.id !== id;
                });
                this.searchYears = this.activeYears;
                EventBus.fire('update-content-search-filters', {key: 'years', value: [...this.searchYears]})
                EventBus.fire('update-content-search-results');
            },
            removeAll(performSearch = false) {
                this.searchYears = [];
                this.activeYears = [];
                EventBus.fire('update-content-search-filters', {key: 'years', value: []})
                this.$emit('closeExpand');
                if (performSearch) {
                    EventBus.fire('update-content-search-results')
                }
            }
        },
        created() {
            EventBus.listen('reset-year-filter', () => this.removeAll());
            EventBus.listen('remove-single-year', (id) => this.removeYear(id))
            EventBus.listen('set-year-filter', (years) => {
                this.searchYears = years;
                this.activeYears = years;
            })
        }
    }
</script>
