import axios from "axios";
import {SessionStore} from "../classes/SessionStore";
import {AsyncUtils} from "../util/AsyncUtils";
import {ArrayUtils} from "../util/ArrayUtils";
import answers from "../../json/ai_answers.json";
import { SuperAxios } from "./SuperAxios";

/**
 * TODO: ResponseFactory
 *
 * @typedef SuccessResponse
 * @property {boolean} success
 * @property {string} error
 * @property {Object} data
 */


/**
 * @typedef StartTrialResponse
 * @property {boolean} status
 * @property {number} lastInsertedId
 */

export class ApiService {

    static cancelTokenSources = {};

    static getPreference(name) {
        // TODO
    }

    /**
     * Updates user's preferences
     *
     * @param {string|object }   name  Either pass preference name (for single value update) or an object with key-value pairs
     * @param {string|undefined} value Either pass preference value (for single value update) or leave undefined
     *
     * @returns {any}
     */
    static async updatePreference(name, value = undefined) {

        const endpointUrl = Routing.generate('update_preferences');

        let payload = {};

        if (typeof name === 'object' && value === undefined) {
            payload = name;
        } else {
            // Todo: We need to investigate this. This could be troublesome.
            payload = {
                name: name,
                value: value
            };
        }

        const response = await axios.patch(endpointUrl, payload);

        return response.data;
    }

    static async deletePreferences() {
        const endpointUrl = Routing.generate('delete_preferences');
        await axios.delete(endpointUrl);
    }

    /**
     *
     * @returns {Promise<StartTrialResponse>}
     */
    static async startTrial() {

        const uri = Routing.generate('trial_start');

        return axios.post(uri).then((response) => {
            return response.data;
        });
    }

    static async cancelTrial() {
        const uri = Routing.generate('cancel_trial');
        await axios.post(uri);
    }

    static async updateTrialConversion(url) {
        const uri = Routing.generate('trial_conversion');

        return axios.post(uri, {url: url}).then((res) => {
            return res.data;
        });
    }

    /**
     *
     * @param {"subscribe_now"|"test"} tag
     * @param {string|null} value
     * @return {Promise<number>}
     */
    static async logUserClick(tag, value = null) {
        const uri = Routing.generate('api_log_click');

        if (value === null) {
            value = window.location.href;
        }

        return axios.post(uri, {
            tag: tag,
            value: value
        }).then((res) => {
            return res.status;
        });
    }

    static async sessionPing() {
        const pingRoute = Routing.generate('api_ping');

        return axios.post(pingRoute).then((res) => {
            return res.data;
        });
    }

    static async logoutConcurrent() {
        const logoutRoute = Routing.generate('concurrent_disconnect');

        return axios.post(logoutRoute).then((res) => {
            return res.data;
        })
    }

    static async rateTag(tagId, rating) {
        const url = Routing.generate('tag_rate', {
            'tagId': tagId
        });

        return axios.post(url, {
            rating: rating
        }).then((res) => {
            return res.data;
        });
    }

    static async logPremiumCategoryClick(categoryId, sectionId) {

        const url = Routing.generate('api_premium_category_click');
        const sid = SessionStore.getId();

        return axios.post(url, {
            category: categoryId,
            section: sectionId,
            sid: sid
        }).then((res) => {
            return res.data;
        });
    }

    static async logPremiumTagClick(tagId, isDefault = false) {

        const url = Routing.generate('api_premium_tag_click');
        const sid = SessionStore.getId();

        return axios.post(url, {
            tag: tagId,
            default: !!isDefault,
            sid: sid
        }).then((res) => {
            return res.data;
        });
    }

    static async needToMigrateNotes(priorVersionDocumentId, latestDocumentId) {
        const url = Routing.generate("previous_version_notes_migration_alert",
            {
                priorDocumentId: priorVersionDocumentId,
                latestDocumentId: latestDocumentId
            });
        return axios.get(url).then((res) => {
            return res.data;
        });

    }

    static postQuestionFakeGPT(question, namespace = "", triggerRateLimit = false) {
        /** @type {Array} */
        const answers = require('../../json/ai_answers.json');

        let response = ArrayUtils.rand(answers);

        if (triggerRateLimit) {
            response = answers.find((item) => {
                return item?.remaining === 0;
            });
        }

        const promise = AsyncUtils.newNetworkRequestLikePromiseThatResolvesTo(response, 1600)
        return AsyncUtils.toCancellablePromise(promise);
    }

    static postQuestionGPT(question, location, year, bookIds = [], questionId = "") {

        const url = Routing.generate('ask_ai_question');
        const cancelTokenSource = axios.CancelToken.source();

        const promise = axios.post(url, {
            question: question,
            location: location,
            year: year,
            books: bookIds,
            questionId: questionId
        }, {
            cancelToken: cancelTokenSource.token,
        }).then((res) => {
            return res.data;
        });

        return AsyncUtils.toCancellablePromise(promise);
    }

    static async confirmTermsGPT() {
        const url = Routing.generate('ai_confirm_terms');
        return axios.post(url);
    }

    static async getAvailableCodeCycles() {
        const uri = Routing.generate('ai_cycles');
        return axios.get(uri).then((res) => {
            return res.data;
        })
    }

    static async getJurisdictionsCached() {
        const uri = Routing.generate('ai_jurisdictions');
        return SuperAxios.getCached(uri);
    }

    static async getYearsForJurisdictionCached(jurisdiction) {
        const uri = Routing.generate("ai_years", {
            jurisdiction: jurisdiction
        });
        return SuperAxios.getCached(uri);
    }

    static async sendFeedbackGpt(messageId, feedback) {

        return axios.post(Routing.generate('log_ai_feedback'), {
            messageId: messageId,
            rating: feedback
        }).then(res => {
            return res.data;
        });
    }

    static getReferenceSection(xmlId) {
        const route = 'get_xml_content_by_xml_id';

        if (ApiService.cancelTokenSources[route]) {
            ApiService.cancelTokenSources[route].cancel('cancelled');
        }
        ApiService.cancelTokenSources[route] = axios.CancelToken.source();

        const url = Routing.generate(route, { "xmlId": xmlId});

        return axios.get(url, {cancelToken: ApiService.cancelTokenSources[route].token});
    }

    static async closeGlobalBanner() {
        const url = Routing.generate('close_global_banner');
        return axios.post(url);
    }

    static async getLimits() {

        return axios.get(Routing.generate('ai_remaining_questions'))
            .then(res => {
                return res.data;
            });
    }

    static async getDocumentBySku(sku) {

        const route = Routing.generate('get_document_by_sku', {
            sku: sku
        });

        return axios.get(route).then((res) => {
            return res.data;
        })
    }

    static async getRecentAskedQuestions() {

        const route = Routing.generate('ai_recently_asked_questions');

        return axios.get(route).then((res) => {
            return res.data;
        })
    }
    static async shopSync() {

        return axios.get(Routing.generate('sync_shop_purchase'));
    }

    static async getUpdates(){
        return axios.get(Routing.generate('get_updates'));
    }

    /**
     *
     * @param slug
     * @return {Promise<{error: string, title: string, html: string}>}
     */
    static async getBlockBySlug(slug) {

        const routeUri = Routing.generate('blocks_show', {
            slug: slug
        });

        // noinspection JSValidateTypes
        return axios.get(routeUri).then((res) => {
            return res.data;
        });
    }
}
