export default {
    methods: {
        setUrlFromSearchFilters() {
            this.updateHistory(this.$store.getters.getSearchPageUrl);
        },
        updateHistory(url) {
            history.pushState({id: 'search'}, 'Search', url);
        }
    },
    created() {
        window.onpopstate = function() {
            location.reload()
        };
        EventBus.listen('url-filter-change', () => this.setUrlFromSearchFilters());
        EventBus.listen('url-update-history', (url) => this.updateHistory(url));
    }
}
