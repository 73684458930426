<template>
    <div>
        <pricing-blocks></pricing-blocks>
        <pricing></pricing>
        <premium-features-block></premium-features-block>
        <f-a-qs-block></f-a-qs-block>
    </div>
</template>
<script>
    import PricingBlocks from '../../components/pricing/PricingBlocks';
    import Pricing from '../../components/pricing/Pricing';
    import PremiumFeaturesBlock from '../../components/pricing/PremiumFeaturesBlock';
    import FAQsBlock from '../../components/pricing/FAQsBlock';

    export default {
        components: {
            Pricing,
            PricingBlocks,
            PremiumFeaturesBlock,
            FAQsBlock
        }
    }
</script>