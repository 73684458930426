<template>
    <trial-header>
        <template v-slot:header>
            Your FREE 14-Day Trial has expired
        </template>
        <template v-slot:description>

            <div class="d-flex"
                 style="min-width: 950px">

                <div id="left">

                    <p class="oxygen primary--text text-2xl">Thank you for taking your <strong>Premium Complete</strong>
                        trial.
                    </p>
                    <p>
                        Unfortunately, the trial period has expired, but we encourage you to search across our
                        wide library of titles and also purchase a
                        <strong class="text-decoration-underline primary--text">Premium Complete</strong> subscription.
                        All of the content you entered during your trial period is still waiting for you and
                        will be unlocked once you upgrade to a premium subscription on your title.
                    </p>
                    <v-row>
                        <v-col>
                            <v-btn
                                    x-large
                                    color="primary"
                                    @click="getPremiumCompleteBook"
                                    block>
                                Get Premium Complete
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                    x-large
                                    outlined
                                    color="primary"
                                    :href="getRoute('dashboard_landing')"
                                    block>
                                Go to My Library
                            </v-btn>
                        </v-col>
                    </v-row>

                </div>

                <div class="pl-10">
                    <asset-image
                            :src="require('../../images/workers-crane-building-background.png')"
                            alt=""
                            width="350"
                    ></asset-image>
                </div>

            </div>

        </template>
    </trial-header>
</template>

<script>
import TrialHeader from "./TrialHeader.vue";
import AssetImage from "../AssetImage.vue";

export default {
    components: {AssetImage, TrialHeader}

}
</script>

<style lang="scss" scoped>
#left {
  width: 676px;
  max-width: 676px;
}
</style>
