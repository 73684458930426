var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("trial-header", {
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _vm._v("\n        Did you explore all "),
              _c("b", [_vm._v("Premium tools")]),
              _vm._v(" offered by your FREE 14-Day Trial?\n    "),
            ]
          },
          proxy: true,
        },
        {
          key: "subHeading",
          fn: function () {
            return [
              _vm._v(
                "\n        Make sure you get all you need while on your FREE Trial and reach out with any questions our support team.\n    "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "description",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mb-8",
                  attrs: {
                    "x-large": "",
                    color: "primary",
                    href: _vm.getRoute("dashboard_landing"),
                    block: _vm.isMobilePort,
                  },
                },
                [_vm._v("\n            Go to my Premium Library\n        ")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "subtitle-1" }, [
                _vm._v("\n            If you are enjoying your FREE 14-Day "),
                _c("b", [_vm._v("Premium Complete")]),
                _vm._v(
                  " Trial and its Premium Tools, you may\n            consider signing up for our conveniently priced "
                ),
                _c("b", [_vm._v("Premium Complete")]),
                _vm._v(" subscribing and proceed to use\n            its "),
                _c("b", [_vm._v("Premium Tools")]),
                _vm._v(".\n        "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "subtitle-1 mb-0" }, [
                _vm._v(
                  "\n            Or if you don’t need all code titles and standards that are part of our "
                ),
                _c("b", [_vm._v("Premium Complete")]),
                _vm._v(
                  "\n            subscription, you can look at our low priced "
                ),
                _c("b", [_vm._v("Premium Collections")]),
                _vm._v(
                  " subscription, or you can just\n            purchase single code titles or standards, which is our "
                ),
                _c("b", [_vm._v("Premium Single")]),
                _vm._v(" transcription and you can\n            use all the "),
                _c("b", [_vm._v("Premium Tools")]),
                _vm._v(
                  ", but only navigating through a single code title or standard.\n        "
                ),
              ]),
            ]
          },
          proxy: true,
        },
        !_vm.isMobilePort
          ? {
              key: "image",
              fn: function () {
                return [
                  _c("asset-image", {
                    staticStyle: { "max-width": "400px" },
                    attrs: {
                      src: require("../../images/illistrations/loud_speaker.webp"),
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }