<template>
    <div>
        <div id="chat-n-feedback" class="feedback-help-buttons">
            <span @click="showFeedbackModal" class="feedback-image-button" id="feedback_button">
                <img 
                    width="30"
                    height="102"
                    :src="asset(require('../../images/icon_feedback.png'))" 
                    alt="FEEDBACK" 
                    title="Feedback">
            </span>
                <span @click="initLiveChat" class="livechat-image-button" id="phplive_btn_1496167627">
                <img 
                    width="30"
                    height="102"
                    :src="asset(require('../../images/icon_live_chat.png'))" 
                    alt="LIVE CHAT" 
                    title="Live Chat">
            </span>
        </div>
        <feedback-form></feedback-form>
    </div>
</template>

<script>
    import FeedbackChat from '../inc/mixins/feedback_chat';
    import FeedbackForm from '../modals/feedbackForm';

    export default {
        mixins: [FeedbackChat],
        components: {FeedbackForm},
        methods: {
            showFeedbackModal() {
                EventBus.fire('show-feedback-modal');
            }
        }
    }
</script>

<style lang="scss" scoped>
@media (max-width: 1500px) {
  .feedback-help-buttons {
    display: none;
  }
}
</style>
