var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      style: `background: ${_vm.$vuetify.theme.themes.light.accent2Light}`,
      attrs: { outlined: "" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "font-weight-bold pa-2",
          staticStyle: { "font-size": "14px" },
        },
        [
          _c("v-icon", { staticClass: "pr-1", attrs: { color: "accent2" } }, [
            _vm._v("\n            mdi-creation\n        "),
          ]),
          _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "font-weight-medium text-wrap px-3 fs-14" },
        [_c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.content))])]
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "font-weight-bold px-4",
              attrs: { depressed: "", outlined: "" },
              on: {
                click: function ($event) {
                  return _vm.goToRoute("premium_features")
                },
              },
            },
            [_vm._v("\n            Learn More        \n        ")]
          ),
          _vm._v(" "),
          !_vm.isActiveUser || _vm.canStartTrial
            ? _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold px-4",
                  attrs: { depressed: "", color: "accent2" },
                  on: {
                    click: function ($event) {
                      return _vm.goToRoute("trial_signup")
                    },
                  },
                },
                [_vm._v("\n            Start Free Trial\n        ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }