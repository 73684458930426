var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      class: _vm.elclass,
      attrs: {
        id: _vm.getID,
        value: _vm.isVisible,
        type: _vm.type,
        color: _vm.color,
        dismissible: _vm.dismissible,
        icon: _vm.icon,
      },
    },
    [_vm._v("\n    " + _vm._s(_vm.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }