<template>
    <div v-if="title">
        <tile-image
            v-if="type === 'tile'"
            :title="title"
            :showTitle="showTitle">
        </tile-image>
        <list-image
            v-if="type === 'list'"
            :title="title"
            :showVersion="showVersion"
            :showFavorite="showFavorite"
            :showLinkIcon="showLinkIcon"
            :listOutline="listOutline"
            :preventLink="preventLink"
            :hover="hover"
            :background="background">
        </list-image>
    </div>
</template>

<script>
    import TileImage from './Tile';
    import ListImage from './List';

    export default {
        props: {
            title: {
                required: true,
                type: Object
            },
            type: {
                required: false,
                type: String,
                default: 'tile'
            },
            showTitle: {
                required: false,
                type: Boolean,
                default: true
            },
            showVersion: {
                required: false,
                type: Boolean,
                default: false
            },
            showFavorite: {
                required: false,
                type: Boolean,
                default: false
            },
            showLinkIcon: {
                required: false,
                type: Boolean,
                default: true
            },
            listOutline: {
                required: false,
                type: Boolean,
                default: true
            },
            preventLink: {
                required: false,
                type: Boolean,
                default: false
            },
            hover: {
                required: false,
                type: Boolean,
                default: true
            },
            background: {
                required: false,
                type: String
            }
        },
        components: {
            TileImage,
            ListImage
        }
    }
</script>
