<template>
    <v-list role="menu" class="py-0">
        <v-list-item
            v-if="activeProfile"
            class="py-2 grey lighten-4">
            <v-list-item-title class="white--text">
                <template>
                    <h4 class="pb-1 primary--text txt text-capitalize">{{ fullName }}</h4>
                    <h5
                        class="font-weight-regular primary--text"
                        style="font-size:12px">
                        {{ email }}
                    </h5>
                </template>
            </v-list-item-title>
        </v-list-item>
        <v-list-item
            v-else
            class="py-2"
            @click="goToLogInPage()">
            <v-list-item-title>
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-avatar
                            class="mt-n1"
                            rounded
                            size="30"
                            color="primary">
                            <v-icon dark>
                                mdi-account
                            </v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col>
                        <h3>
                            Sign In
                        </h3>
                    </v-col>
                </v-row>
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
    import NavigationMixin from './mixins/navigation';

    export default {
        mixins: [NavigationMixin],
        computed: {
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
        },
    }
</script>
