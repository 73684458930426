<template>
    <v-container class="content-container">
        <v-row align="center">
            <v-col>
                <v-row>
                    <v-col>
                        <h1 class="primary--text font-weight-regular mb-5 oxygen">
                            <slot name="header">
                            </slot>
                        </h1>
                        <h2 class="primary--text font-weight-regular">
                            <slot name="subHeading">
                            </slot>
                        </h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <slot name="description">
                        </slot>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="hasImageSlot"
                class="text-center"
                cols="5">
                <slot name="image">
                </slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    computed: {
        hasImageSlot() {
            return !!this.$slots['image'];
        }
    }
}
</script>
