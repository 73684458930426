<template>
    <v-tooltip top :max-width="550">
        <template v-slot:activator="{ on, attrs }">
            <h3
                v-if="element === 'h3'"
                v-bind="attrs"
                v-on="showTooltip ? on : null"
                :class="additionalClass"
                v-html="truncatedText">
            </h3>
            <p
                v-else
                v-bind="attrs"
                v-on="showTooltip ? on : null"
                :class="additionalClass"
                v-html="truncatedText">
            </p>
        </template>
        <span v-html="text" class="tooltip-content">
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        element: {
            type: String,
            required: false
        },
        additionalClass: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: true
        },
        maxLength: {
            type: Number,
            required: false,
            default: 75
        }
    },
    computed: {
        showTooltip() {
            return this.text.length > this.maxLength;
        },
        truncatedText() {

            if (this.showTooltip) {
                return this.text.slice(0, this.maxLength) + '...';
            }

            return this.text;
        }
    }
}
</script>
