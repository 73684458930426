<template>
    <v-row>
        <v-col>
            <h1 class="text-center oxygen">{{ heading }}</h1>
            <p class="text-center pt-2">{{ subHeading }}</p>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props:{
        heading: {
            required: true,
            type: String
        },
        subHeading: {
            required: true,
            type: String
        }
    }
}
</script>