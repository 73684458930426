var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "py-0", attrs: { dense: "" } },
    [
      _vm.title
        ? _c(
            "v-list-item",
            [
              _c("v-list-item-content", [
                _c("p", { staticClass: "caption mb-0 font-weight-medium" }, [
                  _vm._v(
                    "\n                " + _vm._s(_vm.title) + "\n            "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-progress-linear", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : [
            _vm.search
              ? _c(
                  "v-list-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.getSearchAction()
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "mr-2" },
                      [
                        _c("v-icon", { attrs: { color: "black" } }, [
                          _vm._v(
                            "\n                    mdi-magnify\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "black--text" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.getSearchText()) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.results, function (item, key) {
              return _c(
                "v-list-item",
                {
                  key: key,
                  attrs: { href: _vm.getHref(item) },
                  on: {
                    click: function ($event) {
                      return _vm.getClick(item)
                    },
                  },
                },
                [
                  _vm.showTitleTopics && item.title_topic
                    ? _c(
                        "v-list-item-avatar",
                        { staticClass: "title-topic-icon mr-2" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.asset(item.title_topic.icon),
                              alt: item.title_topic?.name || "Title Topic",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "v-list-item-icon",
                        { staticClass: "mr-2" },
                        [
                          _c("v-icon", { attrs: { color: "black" } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.icon) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        staticClass: "black--text",
                        domProps: { innerHTML: _vm._s(_vm.getItemText(item)) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
      _vm._v(" "),
      (_vm.canStartTrial || !_vm.isActiveUser) && _vm.showUpsell
        ? [
            _c("v-divider"),
            _vm._v(" "),
            _c(
              "v-list-item",
              { attrs: { href: _vm.getRoute("trial_signup") } },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "mr-2" },
                  [
                    _c("v-icon", { attrs: { color: "accent2" } }, [
                      _vm._v(
                        "\n                    mdi-creation\n                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", { staticClass: "black--text" }, [
                      _vm._v(
                        "\n                    Start Free Premium Trial for advanced content search\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }