var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.searchResults.length > 0
        ? [
            _c(
              "v-row",
              _vm._l(_vm.searchResults, function (result, key) {
                return _c(
                  "v-col",
                  { key: key, staticClass: "py-2", attrs: { cols: "12" } },
                  [
                    _c("content-results-item", {
                      staticClass: "item-result",
                      attrs: {
                        result: result,
                        meta: result._source,
                        scrollKey: key,
                        search: _vm.search,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                [
                  !_vm.hasPremiumSubscription
                    ? _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                You currently do not have any Premium titles in your library.\n                To take advantage of this feature, please purchase a Digital Codes Premium\n                subscription through the\n                "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://shop.iccsafe.org/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("ICC shop")]
                        ),
                      ])
                    : _c("no-results", {
                        attrs: {
                          "all-search-params": _vm.allSearchParams(),
                          "did-you-mean": _vm.didYouMean,
                          "search-term": _vm.search.exact,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }