import {AsyncUtils} from "./util/AsyncUtils";

export const FindCodesByStateMap = async (containerElementId, onStateClickCallback = null) => {

    await AsyncUtils.loadScriptAsync("/static/simplemaps/mapdata.js");
    simplemaps_usmap_mapdata.main_settings.auto_load = 'no';
    await AsyncUtils.loadScriptAsync("/static/simplemaps/usmap.js");

    // override some defaults
    if (typeof simplemaps_usmap !== 'undefined') {

        simplemaps_usmap.mapdata.main_settings.div = containerElementId;

        simplemaps_usmap.mapdata.main_settings.keyboard_navigation = 'auto';
        simplemaps_usmap.mapdata.main_settings.keyboard_omit = 'regions';

        simplemaps_usmap.mapdata.main_settings.manual_zoom = 'no';
        simplemaps_usmap.mapdata.main_settings.zoom = 'no';

        // styling
        simplemaps_usmap.mapdata.main_settings.border_color = '#eaf6f6';

        // Disable zooming for all states
        simplemaps_usmap.mapdata.main_settings.all_states_zoomable = 'no';

        const adopted = ['AZ', 'KS', 'MO', 'MS'];

        for (const state of adopted) {
            simplemaps_usmap.mapdata.state_specific[state].color = '#0b5940';
        }

        simplemaps_usmap.hooks.click_state = function (id) {

            if (typeof onStateClickCallback === 'function') {

                const obj = {
                    id: id,
                    name: (simplemaps_usmap.mapdata.state_specific[id].name || "")
                };

                onStateClickCallback(obj);
            }
        }

        simplemaps_usmap.load();
    }
}