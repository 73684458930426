<template>
    <v-btn
        light
        large
        class="font-weight-bold"
        color="accent2"
        elevation="0"
        :href="getRoute('ai_navigator')">
        Use AI Navigator
    </v-btn>
</template>

<script>
    export default {
        name: 'UseAiNavigation',
    }
</script>
