/**
 * @typedef {Object} Category
 * @property {number} id
 * @property {string} key
 * @property {string} name
 * @property {string} slug
 * @property {Category[]} children
 */

/**
 * @typedef {Object} SearchNavigationResponse
 * @property {Category[]} Category
 * @property {Category[]} Location
 */

/**
 * @typedef {Object} CollectionDocument
 * @property {number} documentId
 * @property {string} title
 */

export const CategoryModule = {
    state: {
        /** @type {SearchNavigationResponse} */
        searchNavigationCategories: {
            Category: [],
            Location: []
        },
        /** @type {CollectionDocument[]} */
        collections: []
    },
    getters: {
        getSearchNavigationCategories: (state) => state.searchNavigationCategories,
        getSearchNavigationCategoryNames(state) {

            const names = [];

            state.searchNavigationCategories.Category.forEach((category) => {
                names.push(category.name);

                if (category.children) {
                    category.children.forEach((subcategory) => {
                        names.push(subcategory.name);
                    })
                }

            });

            return names;
        },
        getCollections(state) {
            return state.collections;
        }
    },
    mutations: {
        // from: /api/search/search-navigation
        setSearchNavigationCategories(state, categories) {
            state.searchNavigationCategories = categories;
        },
        // from: get_collections_with_counts
        setCollections(state, collections) {
            state.collections = collections;
        }
    }
}
