var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", { staticClass: "primary--text headline" }, [
                  _c("span", { staticClass: "licenses-step-3" }, [
                    _vm._v("Assigned By Me"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary licenses-step-3_1",
                      on: {
                        click: function ($event) {
                          return _vm.openAssignModal()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Assign Subscription\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { md: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 mr-2 primary",
                      on: {
                        click: function ($event) {
                          return _vm.refreshSubscriptions("AssignedByMe")
                        },
                      },
                    },
                    [
                      !_vm.isRefreshing
                        ? _c("span", [_vm._v("Refresh Licenses")])
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "white",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", { staticClass: "caption mb-0" }, [
                  _vm._v(
                    "\n                    Assigned by Me licenses are those where the individual who purchases the premium subscription is different than the individual who is able to use the premium subscription.  Assigned licenses are dedicated access to the individual as identified by the email address.\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-data-iterator",
                        {
                          ref: "assigned_by_me_table",
                          staticClass: "elevation-0 no-margin parent-row",
                          attrs: {
                            items: _vm.subscriptions,
                            "items-per-page": _vm.pagination.itemsPerPage,
                            loading: _vm.loading,
                            "single-expand": true,
                            options: _vm.pagination,
                            "footer-props": {
                              itemsPerPageOptions:
                                _vm.pagination.rowsPerPageItems,
                            },
                            "server-items-length": _vm.pagination.totalItems,
                          },
                          on: {
                            "update:options": function ($event) {
                              _vm.pagination = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "subscription-table assigned-by-me",
                                      attrs: {
                                        "aria-label":
                                          "Assigned By Me Subscription Details",
                                      },
                                    },
                                    [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          _vm._l(
                                            _vm.headers,
                                            function (header, index) {
                                              return _c(
                                                "th",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "mr-2 caption font-weight-bold",
                                                  class: {
                                                    "pl-3 text-left title-section":
                                                      index === 0,
                                                    "pr-3 pl-2 text-right":
                                                      index === 1,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(header.text) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (data) {
                                return [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "subscription-table assigned-by-me",
                                      attrs: {
                                        "aria-label":
                                          "Assigned By Me Subscription Details",
                                      },
                                    },
                                    [
                                      _c(
                                        "tbody",
                                        [
                                          _vm._l(
                                            data.items,
                                            function (assignment) {
                                              return [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "5" } },
                                                    [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkPanelEvent(
                                                                      assignment
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "table",
                                                                {
                                                                  staticClass:
                                                                    "subscription-sub-table assigned-to-me",
                                                                },
                                                                [
                                                                  _c("tbody", [
                                                                    _c(
                                                                      "tr",
                                                                      {
                                                                        key: assignment.id,
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "title-section",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "book-titles mr-1",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.bookLink(
                                                                                        assignment.document_info
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "graphic",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        title:
                                                                                          assignment.document_info,
                                                                                        thumbnail:
                                                                                          "",
                                                                                        width:
                                                                                          "50px",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "book-titles",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.bookLink(
                                                                                        assignment.document_info
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "book-title",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        title:
                                                                                          _vm.getBookTitle(
                                                                                            assignment.document_info
                                                                                          ),
                                                                                        print_version_edition:
                                                                                          _vm.printEdition(
                                                                                            assignment
                                                                                              .document_info
                                                                                              .print_version_edition
                                                                                              .version
                                                                                          ),
                                                                                        printing_text:
                                                                                          assignment
                                                                                            .document_info
                                                                                            .print_info,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            {
                                                              staticClass:
                                                                "grey lighten-4",
                                                            },
                                                            [
                                                              _c(
                                                                "v-data-table",
                                                                {
                                                                  staticClass:
                                                                    "no-margin details-row",
                                                                  attrs: {
                                                                    headers:
                                                                      _vm.assigned,
                                                                    items:
                                                                      assignment.assigned_by_me_licenses,
                                                                    loading:
                                                                      _vm.detailsLoading,
                                                                    "items-per-page":
                                                                      -1,
                                                                    "hide-default-footer":
                                                                      "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "item",
                                                                          fn: function ({
                                                                            item,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "tr",
                                                                                {
                                                                                  staticClass:
                                                                                    "lighten-5",
                                                                                  class:
                                                                                    _vm.getStatusClass(
                                                                                      item
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  item.assigned_to
                                                                                    ? _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item
                                                                                                .assigned_to
                                                                                                .email
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.pending_user_email
                                                                                            ) +
                                                                                              "\n                                                                        "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  bottom:
                                                                                                    "",
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "activator",
                                                                                                      fn: function ({
                                                                                                        on,
                                                                                                      }) {
                                                                                                        return [
                                                                                                          _c(
                                                                                                            "v-icon",
                                                                                                            _vm._g(
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    color:
                                                                                                                      "red",
                                                                                                                  },
                                                                                                              },
                                                                                                              on
                                                                                                            ),
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "info"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                ),
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "tooltip-content",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "This user cannot see this subscription because there is no account associated to this email address"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "moment"
                                                                                          )(
                                                                                            item.created_at,
                                                                                            "MMMM Do, YYYY"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "moment"
                                                                                          )(
                                                                                            _vm.getExpirationDate(
                                                                                              assignment,
                                                                                              item
                                                                                            ),
                                                                                            "MMMM Do, YYYY"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.checkSubscriptionType(
                                                                                            item.subscription_type
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.checkStatus(
                                                                                            _vm.getExpirationDate(
                                                                                              assignment,
                                                                                              item
                                                                                            ),
                                                                                            item.status
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-center subtitle-2",
                                                                                    },
                                                                                    [
                                                                                      _vm.checkStatus(
                                                                                        _vm.getExpirationDate(
                                                                                          assignment,
                                                                                          item
                                                                                        ),
                                                                                        item.status
                                                                                      ) ===
                                                                                      "Active"
                                                                                        ? [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                    dark: "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.editItem(
                                                                                                        item
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Reassign"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "error",
                                                                                                    dark: "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.deleteItem(
                                                                                                        item
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Revoke"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        : _vm.checkStatus(
                                                                                            _vm.getExpirationDate(
                                                                                              assignment,
                                                                                              item
                                                                                            ),
                                                                                            item.status
                                                                                          ) !==
                                                                                          "Revoked"
                                                                                        ? [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                    dark: "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.goToTheShop(
                                                                                                        _vm.shopEncodesUrl
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Renew"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    2
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("template", { slot: "no-data" }, [
                            _vm.subscriptions.length == 0 && !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pt-12 text-md-center",
                                    attrs: { row: "" },
                                  },
                                  [_c("p", [_vm._v("No data available!")])]
                                )
                              : _vm.subscriptions.length == 0
                              ? _c(
                                  "div",
                                  { staticClass: "text-center pt-6" },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Re-assign this subscription license"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _vm.errorMessage.length
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            value: true,
                                            outline: "",
                                            color: "error",
                                            icon: "warning",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.errorMessage) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.editedItem.assigned_to
                                    ? _c("v-text-field", {
                                        attrs: {
                                          "data-qa":
                                            "dashboard-licenses-reassign-email",
                                          label: "Enter new email address",
                                        },
                                        model: {
                                          value:
                                            _vm.editedItem.assigned_to.email,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem.assigned_to,
                                              "email",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.assigned_to.email",
                                        },
                                      })
                                    : _c("v-text-field", {
                                        attrs: {
                                          "data-qa":
                                            "dashboard-licenses-reassign-email",
                                          label: "Enter new email address",
                                        },
                                        model: {
                                          value:
                                            _vm.editedItem.pending_user_email,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "pending_user_email",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.pending_user_email",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      !_vm.reassign_loading
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: { click: _vm.close },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.reassign_loading,
                          },
                          on: { click: _vm.save },
                        },
                        [
                          !_vm.reassign_loading
                            ? _c("span", [_vm._v("Save")])
                            : _c("v-progress-circular", {
                                attrs: {
                                  size: 20,
                                  indeterminate: "",
                                  color: "white",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("AssignModal", { attrs: { "user-id": _vm.userId } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }