<template>
    <v-pagination
        class="custom-pagination"
        v-model="pagination.page"
        :length="pagination.pages"
        @input="makePaginationChanged"
        :total-visible="isMobilePort ? 3 : 7">
    </v-pagination>
</template>
<script>
import Mobile from '../inc/mixins/mobile';

export default {
    name: 'PaginationPages',
    props:{
        pagination: {
            required: true,
            type: Object,
        }
    },
    emits: ['changed'],
    mixins: [
        Mobile,
    ],
    methods: {
        makePaginationChanged(value) {
            this.$emit('changed', value);
        }
    },
}
</script>

<style lang="scss">
    .custom-pagination {
        .v-pagination__item, .v-pagination__navigation {
            box-shadow: none!important;
            background: #EEEEEE!important;
            font-weight: 500;
        }
    }
</style>
