<template>
    <div id="sticky-chapter-info">
        <v-row
            dense
            v-if="documentInfo"
            class="grey lighten-3 px-2">
            <v-col>
                <v-row
                    align="center"
                    dense>
                    <v-col cols="auto">
                        <v-btn
                            v-if="showFullScreen"
                            fab
                            small
                            class="ma-0 my-2"
                            :color="getColor"
                            slot="activator"
                            @click="pdfEvent('fullscreen')">
                            <v-icon class="white--text">fullscreen</v-icon>
                        </v-btn>
                        <v-btn
                                fab
                                x-small
                                color="primary"
                                @click="toggleToc(true)">
                            <v-icon>
                                mdi-menu-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col>
                        <h1 class="primary--text caption">
                            {{ getBookTitle(documentInfo) }}
                            <span v-if="'' === examId">
                                <v-chip
                                    v-if="'1' == contentPremium"
                                    dark
                                    color="accent2"
                                    x-small
                                    label
                                    class="px-1">
                                    <a
                                        class='white--text'
                                        v-if="'1' == contentProtection"
                                        @click="openAccessLevelPermissionModal('Premium Lite')">
                                            PREMIUM LITE
                                    </a>
                                    <a class='white--text' v-else @click="openAccessLevelPermissionModal('Premium')">
                                        PREMIUM
                                    </a>
                                </v-chip>
                                <v-chip
                                    v-else
                                    dark
                                    color="primary"
                                    x-small
                                    label
                                    class="px-1">
                                    <a
                                        class='white--text'
                                        v-if="'1' == contentBasic && '1' == contentProtection"
                                        @click="openAccessLevelPermissionModal('Basic')">
                                            BASIC
                                    </a>
                                    <a class='white--text' v-else @click="openAccessLevelPermissionModal('Basic Plus')">
                                        BASIC PLUS
                                    </a>
                                </v-chip>
                            </span>
                        </h1>
                        <p class="mb-0 caption text-left">{{ getChapterTitle }}</p>
                        <p class="caption text-left mb-0">{{ documentInfo.print_info }}</p>
                    </v-col>
                    <v-col
                        cols="auto"
                        class="text-center">
                        <v-btn
                            v-if="pdfurl != ''"
                            icon
                            class="ml-0 mr-1"
                            slot="activator"
                            @click="goToPdf()">
                            <img :src="asset(require('../../../images/icons/pdficon.png'))" width="30">
                        </v-btn>
                        <span v-if="'' === examId">
                            <favorite :document_id="documentInfo.document_id" :sku="documentInfo.sku"></favorite>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Legend from './../inc/mixins/legend';
    import Sticky from './../inc/mixins/sticky';
    import Url from '../../inc/mixins/url.js';

    export default {
        data(){
          return {
              shop_home: 'https://shop.iccsafe.org'
          }
        },
        props: [
            'request',
            'documentInfo'
        ],
        mixins: [Legend, Sticky, Url],
        methods: {
            openAccessLevelPermissionModal(accessLevel) {
                EventBus.fire('access-level-permission-prompt', accessLevel);
            },
            toggleToc(isVisible) {
                EventBus.fire('toggle-mobile-legend', !!isVisible);
            }
        },
        computed: {
            showFullScreen() {
                let ios = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                if (!this.isXML && !ios) {
                    return true;
                }
                return false;
            }
        },
    }
</script>
