<template>
    <v-btn
        depressed
        v-if="sampleQuestionsPdfUrl !== ''"
        :href="asset(sampleQuestionsPdfUrl)"
        target="_blank">
        <v-icon class="mr-1">
            mdi-download-outline
        </v-icon>
        Sample Answer
    </v-btn>
</template>

<script>
    import {AppParameters} from "../../../AppParameters";

    export default {
        data() {
            return {
                sampleQuestionsPdfUrl: AppParameters.quick_consult_sample_questions_pdf
            }
        }
    }
</script>