var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "exam-timeline-container",
      class: {
        "pt-12": !_vm.isMobilePort,
        "pa-1": _vm.isMobilePort,
      },
    },
    [
      _c(
        "v-col",
        {
          style: !_vm.isMobilePort ? "position: relative;" : "",
          attrs: { cols: _vm.isMobilePort ? 12 : 6 },
        },
        [
          _c("img", {
            attrs: {
              src: _vm.asset(
                require("../../../images/exam_timeline.webp"),
                "default"
              ),
            },
          }),
          _vm._v(" "),
          !_vm.isMobilePort
            ? _c(
                "div",
                { staticClass: "get-started-button" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4 white--text",
                      attrs: {
                        rounded: "",
                        dark: "",
                        "x-large": "",
                        color: "accent2",
                        target: "_blank",
                        href: _vm.link,
                      },
                    },
                    [_vm._v("\n                Get Started\n            ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      3 === _vm.persona || 4 === _vm.persona
        ? _c(
            "v-col",
            { attrs: { cols: _vm.isMobilePort ? 12 : 6 } },
            [
              _c("h1", { staticClass: "pb-5 pl-3 roboto" }, [
                _vm._v(
                  "\n            Schedule and Access your exam with these easy steps\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-timeline",
                { staticClass: "pt-0", attrs: { dense: "" } },
                _vm._l(_vm.steps, function (step) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: step.step,
                      staticClass: "pb-3",
                      attrs: { color: "accent2", small: "" },
                    },
                    [
                      _c("h6", { staticClass: "pb-1 oxygen" }, [
                        _vm._v(_vm._s(step.step)),
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "subtitle-1",
                        domProps: { innerHTML: _vm._s(step.description) },
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "caption",
                        domProps: { innerHTML: _vm._s(step.subDescription) },
                      }),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.isMobilePort
                ? _c(
                    "div",
                    { staticClass: "text-center pt-8" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4 white--text",
                          attrs: {
                            rounded: "",
                            dark: "",
                            "x-large": "",
                            color: "accent2",
                            target: "_blank",
                            href: _vm.link,
                          },
                        },
                        [_vm._v("\n                Get Started\n            ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      2 === _vm.persona
        ? _c("v-col", { attrs: { cols: _vm.isMobilePort ? 12 : 6 } }, [
            _c(
              "h1",
              { staticClass: "pb-5 pl-3 roboto", attrs: { align: "center" } },
              [_vm._v("\n            Checkout the Exams\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "pb-5 pl-3 roboto", attrs: { align: "center" } },
              [_vm._v("Easily find the exam you need in the exam catalog.")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { attrs: { align: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4 white--text",
                    attrs: {
                      rounded: "",
                      dark: "",
                      "x-large": "",
                      color: "accent2",
                      target: "_blank",
                      href: _vm.persona2Link,
                    },
                  },
                  [
                    _vm._v(
                      "\n                Purchase Your Exam Today\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }