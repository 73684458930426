<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search title categories"
                        outlined
                        hide-details
                        clearable
                        background-color="white">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-treeview
                    v-model="searchCategories"
                    open-all
                    dense
                    selectable
                    selection-type="independent"
                    return-object
                    :search="query"
                    selected-color="accent2"
                    :items="items"
                    item-key="id">
                    <template v-slot:label="{ item }">
                        <p class="mb-0 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-buttons
                class="mt-2"
                :filter="{
                    key: 'categories',
                    value: searchCategories
                }"
                @clearSearch="removeAll(true)">
            </filter-buttons>
        </div>
        <div v-show="!expand">
            <template v-if="activeSearchCategories.length > 0">
                <v-card
                    flat
                    :max-height="activeSearchCategories.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeSearchCategories.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeSearchCategories"
                        :key="key"
                        small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary">
                        {{ item.name }}
                        <v-icon @click="removeCategory(item.id)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeSearchCategories.length > 0"
                        @click="removeAll(true)">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all categories
            </p>
        </div>
    </div>
</template>

<script>
    import ContentSearch from "../../../mixins/TabbedSearch";
    import FilterButtons from "../FilterButtons.vue";
    import {SearchApi} from "../../../../../api/Search";
    import {EventNames} from "../../../../../classes/EventNames";
    import Mobile from "../../../../inc/mixins/mobile";

    export default {
        components: {FilterButtons},
        data() {
            return {
                query: null,
                items: [],
                activeSearchCategories: []
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            ContentSearch,
            Mobile
        ],
        methods: {
            getCategories() {
                const data = {
                    mine: true,
                    searchable: true
                };

                SearchApi.getCategories(data).then(response => {
                    this.items = response.data.data;

                    // Sort the category list
                    this.items.sort(function(a,b){
                        let category1 = a.name.toUpperCase();
                        let category2 = b.name.toUpperCase();
                        return (category1 < category2) ? -1 : (category1 > category2) ? 1 : 0;
                    });
                }).catch(this.handleError);
            },
            removeCategory(id) {
                this.activeSearchCategories = _.filter(this.activeSearchCategories, function(o) {
                    return o.id !== id;
                });
                this.searchCategories = this.activeSearchCategories;
                EventBus.fire('update-content-search-filters', {key: 'categories', value: [...this.searchCategories]})
                EventBus.fire('update-content-search-results');
            },
            removeAll(preformSearch = false) {
                this.searchCategories = [];
                this.activeSearchCategories = [];
                EventBus.fire('update-content-search-filters', {key: 'categories', value: []});
                this.$emit('closeExpand');
                if (preformSearch) {
                    EventBus.fire('update-content-search-results');
                }
            }
        },
        created() {
            EventBus.listen(EventNames.INIT_CONTENT_SEARCH, () => {
                this.getCategories()
            });
            EventBus.listen('reset-category-filter', () => this.removeAll());
            EventBus.listen('remove-single-category', (id) => this.removeCategory(id));
            EventBus.listen('set-category-filter', (categories) => {
                this.searchCategories = categories;
                this.activeSearchCategories = categories;
            })
        },
        mounted(){
            //for mobile view, component render in modal, which requires data
            if (this.isMobilePort) {
                this.getCategories();
            }
        }
    }
</script>
