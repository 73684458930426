export default {
    props: {
        schema: {
            type: String,
            required: false,
            default: 'http'
        }
    },
    methods: {
        initLiveChat() {
            this.loadLiveChatScript();
            // setTimeout(function(){ phplive_launch_chat_0(0) ; }, 1000);
        },
        loadLiveChatScript() {
            let phplive_e_1496167627 = document.createElement("script");
            phplive_e_1496167627.type = "text/javascript";
            phplive_e_1496167627.async = false;
            phplive_e_1496167627.src = "//livechat.iccsafe.org//js/phplive_v2.js.php?v=0|1440692297|1|";
            document.body.appendChild(phplive_e_1496167627);
        },
        loadFeedbackScript() {
            let _this = this;
            let jiraFeedback = document.createElement("script");
            jiraFeedback.type = "text/javascript";
            if (jiraFeedback.readyState) {  // only required for IE <9
                jiraFeedback.onreadystatechange = function() {
                    if (jiraFeedback.readyState === "loaded" || jiraFeedback.readyState === "complete") {
                        jiraFeedback.onreadystatechange = null;
                        _this.initFeedback();
                    }
                };
            } else {
                jiraFeedback.onload = function() {
                    _this.initFeedback();
                };
            }
            jiraFeedback.src = this.schema+"://jira.iccsafe.org/s/f19593a0046626d1066214369ad389ec-T/-1w9u7w/77001/6317b0ea11b996d8473c508ff042aa1f/2.0.24/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b65c51fa";
            document.getElementById("feedback_button").appendChild(jiraFeedback);
        },
        initFeedback() {
            window.ATL_JQ_PAGE_PROPS = {
                "triggerFunction": function (showCollectorDialog) {
                    setTimeout(function() {
                        if(showCollectorDialog !== undefined)
                            showCollectorDialog();
                    }, 100);
                }
            };
        }
    }
}
