<template>
    <div>
        <v-row
            align="center">
            <v-col>
                <p class="fs-20 my-3">
                    <template v-if="searchTerm">
                        {{ totalResults.toLocaleString() }} {{titleLabel}} results for
                        <span class="font-weight-bold">
                            <template>
                                "{{ searchTerm }}"
                            </template>
                        </span>
                        <p class="body-1">
                        <share-feedback></share-feedback>
                        </p>
                    </template>
                    <template v-else>
                        Enter search term or refine search
                    </template>
                </p>
            </v-col>
        </v-row>
        <v-row
            dense>
            <v-col>
                <template
                    v-for="(item, key) in activeFilters"
                    v-if="item.data">
                    <v-chip
                        class="primary--text mr-2"
                        color="primaryLight"
                        :key="item.data.key"
                        v-if="item.data.length === 1">
                        <span class="fs-16">
                            {{ item.data[0]?.name ?? item.data[0] }}
                        </span>
                        <v-icon
                            right
                            @click="clearFilter(item.key, item.data[0]?.id ?? item.data[0])">
                            mdi-close
                        </v-icon>
                    </v-chip>
                    <v-menu
                        absolute
                        v-if="item.data.length > 1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                class="primary--text mr-2 mb-1"
                                color="primaryLight"
                                v-bind="attrs"
                                v-on="on">
                                <span class="fs-16">
                                    {{ `${item.subLabel} (${item.data.length})` }}
                                </span>
                                <v-icon
                                    right
                                    @click="clearFilter(item)">
                                    mdi-close
                                </v-icon>
                            </v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(subItem, index) in item.data"
                                :key="index">
                                <v-list-item-title>
                                    <v-chip
                                        class="primary--text mr-2"
                                        color="primaryLight">
                                        <span class="fs-16">
                                            {{ subItem?.name ?? subItem }}
                                        </span>
                                        <v-icon
                                            right
                                            @click="clearFilter(item.key, subItem?.id ?? subItem)">
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-col>
        </v-row>
        <v-row
            v-if="isMediumAndDown"
            align="center">
            <v-col>
                <v-btn
                    color="primary"
                    block
                    @click="$emit('showModal')">
                    <v-icon left>
                        mdi-tune
                    </v-icon>
                    {{ buttonName() }}
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import { EventNames } from '../../../classes/EventNames';
    import TabbedSearch from "../mixins/TabbedSearch";
    import ShareFeedback from "../../links/ShareFeedback";

    const FilterCategories = 'categories'
    const FilterYears = 'years'
    const FilterTitles = 'Titles'
    const TitleScope = 'titleScope';

    export default {
        name: 'SearchHeader',
        emits: ['showModal'],
        data() {
            return {

            }
        },
        components: {ShareFeedback},
        props: {
            totalResults: {
                required: true,
                type: Number,
                default: 0
            },
            searchTerm: {
                required: true,
                type: String,
                default: ''
            },
            filters: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        titleScope: [],
                        categories: [],
                        years: [],
                        allTitles: []
                    }
                }
            },
            titleLabel: {
                required: false,
                type: String,
                default: 'title'
            }
        },
        computed: {
            activeFilters() {
                const allActiveFilters =  [
                    {
                        key: FilterCategories,
                        subLabel: 'Categories',
                        data: this.filterCategories[this.searchTab] || []
                    },
                    {
                        key: FilterYears,
                        subLabel: 'Years',
                        data: this.filterYears[this.searchTab] || []
                    },
                    {
                        key: FilterTitles,
                        subLabel: 'Titles',
                        data: this.filterTitles[this.searchTab] || []
                    }
                ];
                const activeGroup = this.filterGroups[this.searchTab] || [];
                if (activeGroup && activeGroup.title) {
                    const groupObj =  {
                        key: TitleScope,
                        subLabel: 'Title Scope',
                        data:  [activeGroup.title]
                    };
                    allActiveFilters.push(groupObj);
                }

                return allActiveFilters;
            }
        },
        mixins: [
            Mobile,
            TabbedSearch
        ],
        methods: {
            // Todo: Need to update this to clear out store values.  Might move EventBus to the watch method when that is done, not sure yet.
            clearFilter(key, id = null) {
                if (id) {
                    switch (key) {
                        case TitleScope:
                            this.$store.commit('setTabbedGroups', {
                                tab: this.searchTab,
                                group: {}
                            });
                            break
                        case FilterCategories:
                            EventBus.fire(EventNames.REMOVE_ACTIVE_CATEGORY, id);
                            break
                        case FilterYears:
                            EventBus.fire(EventNames.REMOVE_ACTIVE_YEAR, id);
                            break
                        case FilterTitles:
                            EventBus.fire(EventNames.REMOVE_ACTIVE_TITLE, id);
                            break
                    }
                } else {
                    this[key] = [];
                }
            },
            buttonName() {
                switch (this.titleLabel) {
                    case 'title':
                        return 'Title Filters';
                    case 'graphic':
                        return 'Graphics Filters';
                    default:
                        return 'Filters';
                }
            }
        }
    }
</script>
