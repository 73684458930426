var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pb-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "auto" } },
            [
              _c("title-graphic", {
                staticClass: "mr-1",
                attrs: {
                  title: _vm.item.document_info,
                  thumbnail: "",
                  width: "120px",
                  additionalStyles: "max-width: 120px;",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "primary--text" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { sm: "6" } }, [
                    _c(
                      "h4",
                      { staticClass: "subtitle-1 font-weight-bold" },
                      [
                        _vm.item.document_info.year
                          ? [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.item.document_info.year.year) +
                                  " " +
                                  _vm._s(_vm.item.document_info.title) +
                                  "\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.item.document_info.title) +
                                  "\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "caption mb-0 text--grey" }, [
                      _vm._v("Item #: " + _vm._s(_vm.item.document_info.sku)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "text-right" }, [
                    _c("p", { staticClass: "body-2 mb-0 mt-1" }, [
                      _vm._v("Subscription Price"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "subheading font-weight-bold" }, [
                      _vm._v("$" + _vm._s(_vm.itemPrice)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "text-right" }, [
                    _c("p", { staticClass: "body-2 mb-0 mt-1" }, [
                      _vm._v("Total Item Price"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "subheading font-weight-bold" }, [
                      _vm._v("$" + _vm._s(_vm.itemTotal)),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("quantity", { attrs: { item: _vm.item } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [_c("duration", { attrs: { item: _vm.item } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-5",
                      attrs: { sm: "auto", "align-self": "end" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "red accent-4", small: "" },
                          on: { click: _vm.openRemoveModal },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-delete-outline")]),
                          _vm._v(
                            "\n                        Remove\n                    "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [_c("v-col", [_c("v-divider")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }