export class ClipboardUtils {

    /**
     * Deprecated. May throw exception
     * Also, this invokes 'copy' event
     *
     * @param {String} text
     */
    static copyTextToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    /**
     * Copies content as both HTML and plain text
     * @param {HTMLElement} element
     * @return {Promise<void>}
     */
    static async copyUsingNativeApi(element) {
        const contentHtml = element.innerHTML;
        const contentText = element.innerText; // Extract plain text for basic editor

        const blobHtml = new Blob([contentHtml], { type: 'text/html' });
        const blobText = new Blob([contentText], { type: 'text/plain' });

        const clipboardItems = {
            'text/html': blobHtml,
            'text/plain': blobText
        };

        const clipboardItemInput = new ClipboardItem(clipboardItems);

        try {
            await navigator.clipboard.write([clipboardItemInput]);
            return Promise.resolve("Text copied to clipboard");
        } catch (err) {
            return Promise.reject("Unable to copy to the clipboard");
        }
    }

    /**
     *
     * @param {HTMLElement} element
     */
    static copyRichTextToClipboard(element) {
        let range = document.createRange();
        range.selectNodeContents(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    /**
     *
     * Use best available API
     *
     * @param {HTMLElement} element
     */
    static copyToClipboard(element) {

        try {

            this.copyUsingNativeApi(element).catch((err) => {

                try {
                    this.copyRichTextToClipboard(element);
                } catch (e) {
                    this.copyTextToClipboard(element.textContent);
                }

            });

        } catch (e) {

            try {
                this.copyRichTextToClipboard(element);
            } catch (e) {
                this.copyTextToClipboard(element.textContent);
            }
        }
    }
}
