var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "feedback-help-buttons",
          attrs: { id: "chat-n-feedback" },
        },
        [
          _c(
            "span",
            {
              staticClass: "feedback-image-button",
              attrs: { id: "feedback_button" },
              on: { click: _vm.showFeedbackModal },
            },
            [
              _c("img", {
                attrs: {
                  width: "30",
                  height: "102",
                  src: _vm.asset(require("../../images/icon_feedback.png")),
                  alt: "FEEDBACK",
                  title: "Feedback",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "livechat-image-button",
              attrs: { id: "phplive_btn_1496167627" },
              on: { click: _vm.initLiveChat },
            },
            [
              _c("img", {
                attrs: {
                  width: "30",
                  height: "102",
                  src: _vm.asset(require("../../images/icon_live_chat.png")),
                  alt: "LIVE CHAT",
                  title: "Live Chat",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("feedback-form"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }