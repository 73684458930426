export default {
    props: {
        documentInfo: {
            required: true,
            type: Object
        },
        examId: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        showTabs() {
            return !this.isExam &&
                this.documentInfo.is_xml;
        },
        freeView() {
            if (this.documentInfo) {
                return !this.isPremiumSubscribed(this.documentInfo.sku);
            }
        }
    },
    methods: {
        showUserNotes() {
            if (this.freeView) {
                this.gtmEvent('Notes View', 'click', 'Note Tab Click');
            }
        }
    }
}
