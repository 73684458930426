<template>
    <v-card
        outlined
        class="rounded-lg">
        <v-card-title>
            <slot name="title">
            </slot>
        </v-card-title>
        <v-divider v-if="showDivider">
        </v-divider>
        <v-card-text :class="{'pa-0': noPadding}">
            <slot name="content">
            </slot>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'OutlinedCard',
        props: {
            showDivider: {
                type: Boolean,
                default: true
            },
            noPadding: {
                type: Boolean,
                default: false
            },
        }
    }
</script>