<template>
    <trial-header>
        <template v-slot:header>
            Did you explore all <b>Premium tools</b> offered by your FREE 14-Day Trial?
        </template>
        <template v-slot:subHeading>
            Make sure you get all you need while on your FREE Trial and reach out with any questions our support team.
        </template>
        <template v-slot:description>
            <v-btn
                    x-large
                    color="primary"
                    :href="getRoute('dashboard_landing')"
                    :block="isMobilePort"
                    class="mb-8">
                Go to my Premium Library
            </v-btn>
            <p class="subtitle-1">
                If you are enjoying your FREE 14-Day <b>Premium Complete</b> Trial and its Premium Tools, you may
                consider signing up for our conveniently priced <b>Premium Complete</b> subscribing and proceed to use
                its <b>Premium Tools</b>.
            </p>
            <p class="subtitle-1 mb-0">
                Or if you don’t need all code titles and standards that are part of our <b>Premium Complete</b>
                subscription, you can look at our low priced <b>Premium Collections</b> subscription, or you can just
                purchase single code titles or standards, which is our <b>Premium Single</b> transcription and you can
                use all the <b>Premium Tools</b>, but only navigating through a single code title or standard.
            </p>
        </template>
        <template
                v-if="!isMobilePort"
                v-slot:image>
            <asset-image
                    :src="require('../../images/illistrations/loud_speaker.webp')"
                    style="max-width: 400px"
            ></asset-image>
        </template>
    </trial-header>
</template>

<script>
import TrialHeader from "./TrialHeader.vue";
import Mobile from "../inc/mixins/mobile";
import AssetImage from "../AssetImage.vue";

export default {
    components: {AssetImage, TrialHeader},
    mixins: [Mobile],
}
</script>
