import {SessionStore} from "./classes/SessionStore";
import {onUserActivity} from "./PageActivity";
import {DateUtil} from "./util/DateUtil";
import {AsyncUtils} from "./util/AsyncUtils";
import {ApiService} from "./api/ApiService";

export const onUserIdle = (callback, idleTimeout) => {

    // data inside localStorage persist across multiple tabs
    const updateActivity = () => {
        localStorage.setItem('lastActivity', DateUtil.timestampInMilliseconds().toString());
    }

    // calling this counts as "activity" too
    updateActivity();

    onUserActivity(() => {
        updateActivity();
    });

    setInterval(() => {

        const last = parseInt(localStorage.getItem('lastActivity')) || 0;
        const now = DateUtil.timestampInMilliseconds();

        const secs = Math.floor((now - last) / 1000);
        if (secs % 5 === 0) {
            console.debug(`Seconds inactive: ${secs}`);
        }

        if (last !== 0 && now >= (last + idleTimeout)) {
            callback();
        }

    }, 1000);
}

export const bootSession = () => {

    SessionStore.start();

    const pingEveryMs = (30 * 1000);

    AsyncUtils.setIntervalWithImmediate(() => {
        ApiService.sessionPing();
    }, pingEveryMs, 2000);
}
