var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("outlined-card", {
    attrs: { showDivider: false },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("h2", { staticClass: "text-h6 font-weight-bold" }, [
              _vm._v("\n            Digital Codes Updates\n        "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.loadingData
              ? [
                  _c("v-progress-linear", {
                    staticClass: "mt-3",
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ]
              : _c(
                  "div",
                  [
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: { accordion: "", flat: "" },
                        model: {
                          value: _vm.activePanel,
                          callback: function ($$v) {
                            _vm.activePanel = $$v
                          },
                          expression: "activePanel",
                        },
                      },
                      _vm._l(_vm.updates, function (update, key) {
                        return _c(
                          "v-expansion-panel",
                          { key: key },
                          [
                            key === 0 ? _c("v-divider") : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-expansion-panel-header",
                              { staticClass: "px-0 py-3" },
                              [
                                _c(
                                  "h3",
                                  { staticClass: "fs-16 font-weight-medium" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(update.title) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-expansion-panel-content",
                              {
                                staticClass: "recent-update-wrapper link-color",
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(update.description),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-divider"),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }