<template>
    <div
        class="mb-4"
        v-if="subjects">
        <v-row justify="center">
            <v-col cols="auto">
                <p class="mb-0 caption font-weight-bold">
                    Standards Categories
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-card
                flat
                width="100%"
                class="mb-1"
                v-for="(subject, index) in subjects"
                :key="index"
                :color="subject.color"
                @click="showAstmTitles(subject)">
                <v-card-text class="px-2 py-0 text-left">
                    <v-row
                        align="center"
                        dense>
                        <v-col cols="auto">
                            <v-icon
                                dark
                                small>
                                {{ subject.icon }}
                            </v-icon>
                        </v-col>
                        <v-col>
                            <p class="caption white--text mb-0">
                                {{ subject.name }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </div>
</template>

<script>
    import Results from './mixins/results';

    export default {
        mixins: [
            Results
        ]
    }
</script>
