import {ProfileApi} from "../../../api/ProfileApi";
import {UserDefaultPreferences} from "../../../UserDefaultPreferences";

export default {
    data() {
        return {
            userPreferencesData: UserDefaultPreferences,
            isUserPreferencesSavedInDb: false,
        }
    },
    methods: {
        getActiveProfileKey(){
            let profileKey = this.activeProfile.type === 'personal' 
                            ? this.activeProfile.type : 
                            this.activeProfile.type + '_' + this.activeProfile.metadata;
            return profileKey;
        },
        getUserProfilePreferences(){
            let profilePreferencesJson = this.$store.getters.getUser.preferences.pages_activity_states;
            let profilePreferences = !_.isEmpty(profilePreferencesJson) ? 
                                JSON.parse(profilePreferencesJson): {};
            return profilePreferences;
        },
        loadProfilePreferences(){
            let savedPreferences = this.getUserProfilePreferences();
            let profileKey = this.getActiveProfileKey();
            if (savedPreferences[profileKey]) {
                this.isUserPreferencesSavedInDb = true;
                this.userPreferencesData = _.merge(this.userPreferencesData, savedPreferences[profileKey]);
            }  else {
                if (this.hasActiveTrial || this.hasPremium) {
                    this.userPreferencesData.libraryPage.titlesRegion.activeSecondaryFilterTab = 0;
                    this.userPreferencesData.libraryPage.titlesRegion.titlesAcrossValue = 'my_premium';
                }
            }
        },
        saveUserProfilePreference(){
            let profileKey = this.getActiveProfileKey();
            let profilePreferences = this.getUserProfilePreferences();
            
            profilePreferences[profileKey] = this.userPreferencesData;                            
            ProfileApi.setProfilePreferences(profilePreferences);
        }
    },    
}
