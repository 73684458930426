<template>
    <v-app-bar
        app
        clipped-left
        text
        fixed
        dense
        hide-on-scroll
        id="navigation-app-mobile"
        class="white elevation-1"
        :style="appBarStyle">
        <v-toolbar-items>
            <v-btn
                text
                class="primary--text"
                small
                @click="showNavDrawer()">
                <template>
                    <v-icon color="black">menu</v-icon>
                </template>
            </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
            <v-btn
                text
                class="primary--text logo px-1"
                :href="getRoute('index_landing')">
                <img
                        v-if="!hasPremiumSubscription"
                        :src="asset(require('../../../images/logo_dc.png'), 'default')"/>
                <img
                        v-else
                        :src="asset(require('../../../images/icc_digital_codes_premium_100.png'), 'default')"/>
            </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <template>
            <v-toolbar-items>
                <cart-updates></cart-updates>
                <logo-menu></logo-menu>
                <navigation-help-menu></navigation-help-menu>
                <profiles-menu>
                </profiles-menu>
            </v-toolbar-items>
        </template>
        <template v-slot:extension>
            <navigation-search>
            </navigation-search>
        </template>
    </v-app-bar>
</template>

<script>
    import NavigationMixin from './mixins/navigation';
    import NavigationSearch from './../../search/searchbar/index';
    import SubscriptionIdentifier from './SubscriptionIdentifier';
    import ProfilesMenu from './ProfilesMenu';
    import NavigationHelpMenu from "./NavigationHelpMenu.vue";
    import CartUpdates from "./CartUpdates.vue";
    import LogoMenu from "./LogoMenu.vue";

    export default {
        data: function() {
            return {
                navItems: this.getNavItems()
            }
        },
        mixins: [NavigationMixin],
        components: {
            NavigationHelpMenu,
            CartUpdates,
            LogoMenu,
            NavigationSearch,
            SubscriptionIdentifier,
            ProfilesMenu
        },
        computed: {
            appBarStyle() {
                this.$emit('navigation-app-updated');
            },
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
        },
        methods: {
            getNavItems(){
                const otherNavItems = [   
                    {
                        title: 'Recent Updates',
                        icon: 'mdi-bullhorn',
                        action: {
                            event: 'fire',
                            value: 'show-notification-modal'
                        },
                        avatar: {
                            color: 'error',
                            value: 1
                        }
                    },
                    {
                        title: 'Digital Codes Help',
                        icon: 'help',
                        action: {
                            event: 'external-link',
                            value: 'https://support.iccsafe.org/?ht_kb_category=digital-codes'
                        }
                    }
                ];
                return otherNavItems;
            },
            handleClick(action) {
            if (_.isObject(action)) {
                if (action.event) {
                    if (action.event === 'fire') {
                        EventBus.fire(action.value)
                    } else if (action.event === 'external-link') {
                        window.open(action.value, '_blank');
                    }
                }
            } else {
                this.goToRoute(action);
            }
            this.drawer = false;
        },
            showNavDrawer(){
                EventBus.fire('show-nav-drawer', true);
            }
        }
    }
</script>

<style lang="scss">
    #navigation-app-mobile {
        .v-toolbar__content {
            padding: 0 8px;
        }
        .v-toolbar__extension {
            padding: 0 4px;
        }
        .logo {
            img {
                height: 40px;
                padding: 5px;
            }
        }
    }
</style>
