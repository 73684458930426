// related to billing page

export const BillingModule = {
    namespaced: true,
    state: {
        payment_profile: {},
        billingUpdateMessage: ''
    },
    getters: {
        getPaymentProfile: (state) => state.payment_profile,
        getBillingUpdateMessage: (state) => state.billingUpdateMessage,
    },
    mutations: {
        SET_PAYMENT_PROFILE(state, value) {
            state.payment_profile = value;
        },
        SET_BILLING_UPDATE_MESSAGE(state, value) {
            state.billingUpdateMessage = value;
        }
    },
    actions: {
        setPaymentProfile({ commit }, payment_profile) {
            commit('SET_PAYMENT_PROFILE', payment_profile);
        },
        setBillingUpdateMessage({ commit }, message) {
            commit('SET_BILLING_UPDATE_MESSAGE', message);
        }
    }
}
