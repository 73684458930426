<template>
    <div>
        <breadcrumb
            :data="breadcrumbData"
            :title-search-term="searchData.searchTerm">
        </breadcrumb>
        <v-container class="grey lighten-5">
            <v-row v-if="isAstmSubjectPage && isAstmCompassEnabled()">
                <v-col>
                    <astm-subjects>
                    </astm-subjects>
                </v-col>
            </v-row>
            <template v-else>
                <codes-result-navigation-controls
                    :category="searchData.category"
                    :show-years="showYearFilter"
                    :loading="resultsLoading">
                </codes-result-navigation-controls>
                <template v-if="resultsLoading">
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                class="py-6"
                                indeterminate
                                color="primary">
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <title-list
                        v-if="pagination.totalResults > 0"
                        :titles="results"
                        :loading="resultsLoading"
                        :itemsPerPage="itemsPerPage"
                        displayBorder
                        displayUserTypeLabel
                        :displayInCols="isMobilePort ? 12 : 6">
                    </title-list>
                    <v-row
                        justify="center"
                        v-else>
                        <v-col cols="auto">
                            <no-results-found></no-results-found>
                        </v-col>
                    </v-row>
                </template>
            </template>
            <v-row
                v-if="page < pagination.maxPerPage && !resultsLoading"
                dense
                justify="center">
                <v-col cols="auto">
                    <v-btn
                        text
                        color="primary"
                        class="mb-4"
                        :loading="showMoreLoading"
                        @click="showMore">
                        Show More
                        <v-icon right>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-show="searchData.category">
                <v-col>
                    <category-tabs
                        :category="searchData.category"
                        :is-astm-standard-page="isAstmStandardPage">
                    </category-tabs>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="categoryLegend"
            fullscreen
            scrollable>
            <v-card>
                <v-card-title class="primary white--text caption mobile-title">
                    Categories
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        class="accent2--text"
                        @click="categoryLegend = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text class="px-0">
                    <navigation>
                    </navigation>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Codes from './mixins/codes';
    import Navigation from './partials/navigation';
    import AstmSubjects from './astm/Subjects';
    import CategoryTabs from './partials/categoryTabs';
    import CodesResultNavigationControls from "./CodesResultNavigationControls.vue";
    import TitleList from '../content/title/TitleList.vue';

    export default {
        mixins: [
            Codes
        ],
        components: {
            Navigation,
            AstmSubjects,
            CategoryTabs,
            CodesResultNavigationControls,
            TitleList
        },
        mounted() {
            EventBus.listen('toggle-category-navigation', this.categoryLegend = false)
        }
    }
</script>
