var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "hero-container",
      class: {
        "py-16": !_vm.isMobilePort,
        "pb-8": _vm.isMobilePort,
      },
      style: _vm.showBorder
        ? "border-bottom: 3px solid " + _vm.$vuetify.theme.themes.light.accent2
        : "",
      attrs: { align: "center", justify: "center" },
    },
    [
      _c(
        "v-col",
        {
          class: { "text-center": _vm.isMobilePort },
          attrs: { cols: "12", sm: "6", order: !_vm.isMobilePort ? 1 : 2 },
        },
        [
          _c("h1", {
            staticClass: "font-weight-light mb-4 oxygen",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subtitle
            ? _c("h2", {
                staticClass: "font-weight-regular",
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.button
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-4 white--text",
                  attrs: {
                    rounded: "",
                    dark: "",
                    "x-large": "",
                    color: _vm.button.color ? _vm.button.color : "accent2",
                    target: _vm.button.target ? _vm.button.target : "_self",
                    href: _vm.button.link,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.logGtm()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.button.text) + "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { attrs: { order: !_vm.isMobilePort ? 2 : 1 } }, [
        _c("img", {
          class: _vm.image.class,
          attrs: {
            width: _vm.image.width,
            src: _vm.asset(require("../../images/" + _vm.image.src), "default"),
          },
        }),
        _vm._v(" "),
        _vm.image.caption
          ? _c("p", {
              staticClass: "mb-0 caption",
              domProps: { innerHTML: _vm._s(_vm.image.caption) },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }