var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        app: "",
        top: "",
        right: "",
        dark: "",
        timeout: _vm.timeout,
        color: "red",
      },
      model: {
        value: _vm.showError,
        callback: function ($$v) {
          _vm.showError = $$v
        },
        expression: "showError",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { id: "error_text", dense: "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { "x-large": "" } }, [
                _vm._v(
                  "\n                mdi-alert-rhombus-outline\n            "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", [
            _c("p", { staticClass: "mb-0 font-weight-regular" }, [
              _vm._v(_vm._s(_vm.errorText)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }