<template>
    <v-dialog
        scrollable
        v-model="modal"
        :fullscreen="isMobileAndTablet"
        max-width="700px">
        <v-card v-if="type">
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                    }">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <tag-label :tag="type.tag">
                        </tag-label>
                    </v-col>
                    <v-col>
                        {{ type.title }}
                    </v-col>
                    <v-col cols="auto">
                        <v-icon
                            small
                            class="accent2--text"
                            @click="modal = false">
                            close
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
                <template v-if="loading">
                    <v-container class="text-center py-12">
                        <v-progress-circular
                            class="py-6"
                            indeterminate
                            color="primary">
                        </v-progress-circular>
                    </v-container>
                </template>
                <template v-else>
                    <premium-content
                        v-if="this.tagType === 'premiumcontent'"
                        :data="data">
                    </premium-content>
                    <e-s-content
                        v-else-if="this.tagType === 'es'"
                        :data="data">
                    </e-s-content>
                    <errata-content
                        v-else-if="this.tagType === 'errata'"
                        :data="data">
                    </errata-content>
                    <s-r-c-c-content
                        v-else-if="this.tagType === 'srcc'"
                        :data="data">
                    </s-r-c-c-content>
                    <video-content
                        v-else-if="this.tagType === 'video'"
                        :data="data">
                    </video-content>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    import PremiumContent from './partials/premiumContent';
    import ESContent from './partials/esContent';
    import ErrataContent from './partials/errataContent';
    import SRCCContent from './partials/srccContent';
    import VideoContent from './partials/videoContent';
    import TagLabel from '../../tags/partials/tag_label';

    export default {
        data() {
            return {
                modal: false,
                tagType: '',
                reportTypeShow: '',
                data: [],
                loading: true
            };
        },
        computed: {
            type() {
                switch (this.tagType) {
                    case 'premiumcontent':
                        return {
                            title: 'Additional Premium Material',
                            tag: 'premium'
                        };
                    case 'es':
                        return {
                            title: 'ICC-ES Reports',
                            tag: 'es'
                        };
                    case 'errata':
                        return {
                            title: 'Errata Reports',
                            tag: 'errata'
                        };
                    case 'srcc':
                        return {
                            title: 'ICC-SRCC Reports',
                            tag: 'srcc'
                        };
                    case 'video':
                        return {
                            title: 'Video Tag',
                            tag: 'video'
                        };
                }
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            PremiumContent,
            ESContent,
            ErrataContent,
            SRCCContent,
            VideoContent,
            TagLabel
        },
        methods: {
            loadModal(item) {
                let showSubscription = '' == item.report_type_show && ('premiumcontent' == item.report_type || 'errata' == item.report_type);
                this.data = [];
                this.loading = true;
                this.reportTypeShow = item.report_type_show;
                this.tagType = item.report_type;
                if (!showSubscription) {
                    this.modal = true;
                }
                this.$http.get(Routing.generate('get_report_tags', {contentId: item.contentid, chapterId: item.contentid, reportType: item.report_type, reportTypeShow: item.report_type_show}))
                    .then(response => {
                        if (response.data) {
                            if (showSubscription) {
                                EventBus.fire('show-subscription-modal', this.getSubscriptionModalData(response.data));
                                this.trackPremiumTagViewEvent();
                            } else {
                                this.data = ('premiumContentShow' == this.reportTypeShow) ? response.data.reportCategoryTags : response.data.reportTags;
                                this.loading = false;
                            }
                        }
                    });
            },
            trackPremiumTagViewEvent() {
                this.$ga.event({
                    hitType: 'event',
                    eventCategory: 'premiumTagView',
                    eventAction: 'click',
                    eventLabel: 'Premium content tag view attempt from basic content view'
                })
            },
        },
        created() {
            EventBus.listen('show-bonus-modal', (item) => this.loadModal(item));
        }
    }
</script>
