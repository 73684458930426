<template>
    <div>
        <div v-show="expand">
            <v-row dense>
                <v-col>
                    <v-text-field
                        dense
                        v-model="query"
                        label="Search title years"
                        outlined
                        hide-details
                        clearable>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-treeview
                    v-model="selectedYears"
                    open-all
                    dense
                    selectable
                    :multiple="true"
                    return-object
                    :search="query"
                    item-key="id"
                    selected-color="accent2"
                    :items="years">
                    <template v-slot:label="{ item }">
                        <p class="my-1 caption text-wrap">
                            {{ item.name }}
                        </p>
                    </template>
                </v-treeview>
            </v-card>
            <filter-apply-button
                class="mt-2"
                :filter="{
                    key: 'years',
                    value: selectedYears
                }">
            </filter-apply-button>
        </div>
        <div v-show="!expand">
            <template v-if="activeYears.length > 0">
                <v-card
                    flat
                    :max-height="activeYears.length > 6 ? '200px' : 'unset'"
                    class="grey lighten-4"
                    :style="activeYears.length > 6 ? 'overflow-y: scroll;' : ''">
                    <v-chip
                        v-for="(item, key) in activeYears"
                        :key="key"
                        small
                        class="pr-1 mt-1 text-wrap"
                        color="primaryLight"
                        text-color="primary">
                        {{ item }}
                        <v-icon @click="removeYear(item)" size="19">close</v-icon>
                    </v-chip>
                </v-card>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!activeYears.length > 0"
                        @click="removeAll()">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all years
            </p>
        </div>
    </div>
</template>

<script>
    import FilterApplyButton from "./FilterApplyButton.vue";
    import { SearchApi } from "../../../api/Search";
    import { EventNames } from "../../../classes/EventNames";
    import TabbedSearch from "../mixins/TabbedSearch";
    import Search from "../../inc/mixins/search"

    export default {
        components: {
            FilterApplyButton
        },
        data() {
            return {
                query: null,
                years: [],
                selectedYears: [],
            }
        },
        computed: {
            activeYears() {
                const years = this.filterYears[this.searchTab];
                if (years) {
                    return years;
                }
                return [];
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            TabbedSearch,
            Search
        ],
        methods: {
            getAllYears() {
                SearchApi.getAllYears().then(response => {
                    /**
                     * @XXX: This is a workaround for Vuetify's treeview parsing of what it considers to be a 'label'
                     *       Despite the fact it uses a string specific method on the extracted value it never takes any
                     *       precaution to check if it actually parsed that value as a Number. No configuration options are visible
                     *       so the workaround is to add a space to the beginning of the name in hope it will forece the parced
                     *       value to be treated as string. Doh
                     */
                    const yearItems = response.data.map(year => {
                        return {
                            id: year.id,
                            name: `${year.name}`
                        }
                    });

                    if (this.activeYears.length > 0) {
                        this.selectedYears = yearItems.filter(item => this.activeYears.includes(item.name));
                    }

                    this.years = yearItems;
                }).catch(this.handleError);
            },
            removeYear(year) {
                this.$store.commit('removeTabbedYear', {
                    tab: this.searchTab,
                    year
                });
                this.selectedYears = this.selectedYears.filter(item => item.name !== year);
            },
            removeAll() {
                this.$store.commit('removeTabbedYear', {
                    tab: this.searchTab,
                    removeAll: true
                });
                this.selectedYears = [];
            }

        },
        created() {
            EventBus.listen(EventNames.REMOVE_ACTIVE_YEAR, (year) => this.removeYear(year));
            EventBus.listen(EventNames.REMOVE_ALL_ACTIVE_YEARS, () => this.removeAll());
        },
        mounted(){
            this.getAllYears();
        }
    }
</script>
