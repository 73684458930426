var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "white elevation-1",
      attrs: {
        app: !_vm.isMobilePort,
        "clipped-left": "",
        flat: "",
        width: "100%",
        dense: "",
        height: _vm.isMobilePort ? "120px" : "64px",
        id: "digital-reference-navigation",
      },
    },
    [
      _c(
        "v-row",
        {
          class: { "mt-n3": _vm.isMobileAndTablet },
          attrs: { align: "center", dense: "" },
        },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("img", {
              style: _vm.isMediumAndDown
                ? "max-height: 26px"
                : "max-height: 36px",
              attrs: {
                src: this.asset(
                  require("../../images/exams/assessment_center.png")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _vm.isMobilePort ? _c("v-spacer") : _vm._e(),
          _vm._v(" "),
          _vm.isMobilePort
            ? _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "auto" } },
                [
                  _c("exam-back-button", {
                    attrs: {
                      "is-pronto-referer": _vm.isProntoReferer,
                      small: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "z-index": "205" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { color: "primary" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                                info\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", { staticClass: "tooltip-content" }, [
                            _c(
                              "p",
                              { staticClass: "mb-0 font-italic caption" },
                              [
                                _vm._v(
                                  "\n                                Full title contents available for viewing with browser search (Ctrl + F)."
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                                Access all available titles from Digital References dropdown.\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass: "font-weight-bold mb-0 subtitle-1",
                        class: { caption: _vm.isSmallOnly },
                        staticStyle: { "line-height": "18px" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.userExamTitle) +
                            " Digital References "
                        ),
                        _vm.isSmallOnly ? _c("br") : _vm._e(),
                        _vm._v(
                          "for online proctored ICC exams\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMobilePort
            ? _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c("exam-back-button", {
                    attrs: { "is-pronto-referer": _vm.isProntoReferer },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }