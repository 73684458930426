var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.isMobilePort,
        persistent: "",
        scrollable: "",
        "max-width": 500,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v("\n            Login\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeAndResetModal()
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.message
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "pt-3" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.message) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("login", {
                attrs: {
                  "show-logo": false,
                  isDense: true,
                  isModal: true,
                  "from-cart": _vm.fromCart,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }