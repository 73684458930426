<template>
    <div>
        <template v-if="pageReference === 'sign-in'">
            <enterprise-sign-in v-if="'' !== inviteFirstname"
                :form-action="formAction"
                :referer="referer"
                :token="token"
                :invite-firstname="inviteFirstname"
                :invite-email="inviteEmail"
                :invite-enterprise="inviteEnterprise">
            </enterprise-sign-in>
            <sign-in-form v-else
                :form-action="formAction"
                :referer="referer"
                :token="token">
            </sign-in-form>
        </template>
        <template v-else-if="pageReference === 'confirm'">
            <confirm-register-form
                :token="token"
                :user-email="userEmail"
                :error="error">
            </confirm-register-form>
        </template>
    </div>
</template>

<script>
    import SignInForm from './partials/sign_in_form';
    import ConfirmRegisterForm from '../registration/ConfirmRegistration.vue';
    import Mobile from '../inc/mixins/mobile'
    import Forms from './mixins/forms';
    import EnterpriseSignIn from './partials/enterpriseSignIn';

    export default {
        props: {
            pageReference: {
                required: false,
                default: 'sign-in',
                type: String
            },
            formAction: {
                required: false,
                type: String
            },
            referer: {
                required: false,
                type: String
            },
            token: {
                required: true,
                type: String
            },
            userEmail: {
                required: false,
                type: String
            },
            error: {
                required: false,
                type: String
            },
            loginFailed: {
                required: false,
                type: String
            },
            inviteFirstname: {
                required: '',
                type: String,
                default: ''
            },
            inviteEmail: {
                required: false,
                type: String,
                default: ''
            },
            inviteEnterprise: {
                required: false,
                type: String,
                default: ''
            }
        },
        mixins: [
            Mobile,
            Forms
        ],
        components: {
            SignInForm,
            ConfirmRegisterForm,
            EnterpriseSignIn
        },
        methods: {
            premiumSubscribe() {
                this.getPremiumCompleteBook();
            }
        },
        mounted() {
            if (this.loginFailed) {
                this.loginFailedCount = this.loginFailedCount + 1;
                if (this.loginFailedCount > 3) {
                    this.showMathCaptcha = true;
                }
            }
        }
    }
</script>
