import axios from 'axios'

export const PremiumAnswersApi = {
    http: axios.create(),
    getPremiumAnswers(filter) {
        return this.http.get(Routing.generate('get_premium_answers', filter))
    },
    getFilterTopics() {
        return this.http.get(Routing.generate('get_topics'))
    },
    getFilterTitles() {
        return this.http.get(Routing.generate('get_titles'))
    },
    getRecentlyViewedAnswers(id) {
        return this.http.get(Routing.generate('get_recent_questions', {excludeId: id}))
    },
    getPremiumAnswer(id) {
        return this.http.get(Routing.generate('get_answer_details', {questionId: id}))
    }
}
