<template>
    <div v-if="isSingle">
        <index-desktop
            v-if="!isMobilePort"
            :document-info="documentInfo"
            :exam-id="examId">
        </index-desktop>
        <index-mobile
            v-else
            :document-info="documentInfo"
            :exam-id="examId">
        </index-mobile>
    </div>
</template>

<script>
    import Mobile from './../../inc/mixins/mobile';
    import IndexDesktop from './IndexDesktop';
    import IndexMobile from './indexMobile';

    export default {
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            examId: {
                type: String,
                required: false,
                default: ''
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            IndexDesktop,
            IndexMobile
        },
        computed: {
            isSingle() {
                if (!_.isEmpty(this.documentInfo)) {
                    return this.isSingleTitle(this.documentInfo);
                }
            },
        },
        created() {
            this.$store.commit('setIsDocumentPage', true);
        },
        mounted() {
            if (this.isMobilePort) {
                EventBus.fire('from-book-content');
            }
        }
    }
</script>
