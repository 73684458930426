<template>
    <div>
        <div v-show="expand">
            <v-card
                flat
                class="mt-2"
                max-height="200px"
                style="overflow-y: scroll;">
                <v-card-text class="py-0">
                    <v-row dense>
                        <v-col>
                            <p class="mb-0 caption">
                                Search In
                            </p>
                        </v-col>
                    </v-row>
                    <v-treeview
                        v-model="searchSource"
                        dense
                        selectable
                        :multiple="true"
                        return-object
                        selected-color="accent2"
                        :items="items.source"
                        item-key="id">
                        <template v-slot:label="{ item }">
                            <p class="mb-0 caption">
                                {{ item.name }}
                            </p>
                        </template>
                    </v-treeview>
                    <v-row dense>
                        <v-col>
                            <p class="mb-0 caption">
                                Search For
                            </p>
                        </v-col>
                    </v-row>
                    <v-treeview
                        v-model="searchTypes"
                        dense
                        selectable
                        :multiple="true"
                        return-object
                        selected-color="accent2"
                        :items="items.type"
                        item-key="id">
                        <template v-slot:label="{ item }">
                            <p class="mb-0 caption">
                                {{ item.name }}
                            </p>
                        </template>
                    </v-treeview>
                </v-card-text>
            </v-card>
            <filter-buttons
                class="mt-2"
                :filter="{
                    key: 'types',
                    value: {sources: searchSource, types: searchTypes}
                }"
                @clearSearch="removeAll(true)">
            </filter-buttons>
        </div>
        <div v-show="!expand">
            <template v-if="hasContentType">
                <v-chip
                    v-for="(item) in activeSearchSources"
                    :key="item.id"
                    small
                    class="pr-1 mt-1"
                    color="primaryLight"
                    text-color="primary">
                    {{ upperFirst(item.name) }}
                    <v-icon @click="removeSource(item.id)" size="19">close</v-icon>
                </v-chip>
                <v-chip
                    v-for="(item) in activeSearchTypes"
                    :key="item.id"
                    small
                    class="pr-1 mt-1"
                    color="primaryLight"
                    text-color="primary">
                    {{ upperFirst(item.name) }}
                    <v-icon @click="removeType(item.id)" size="19">close</v-icon>
                </v-chip>
                <div class="text-right mt-3">
                    <v-btn
                        text
                        small
                        color="gray darken-2"
                        :disabled="!hasContentType"
                        @click="removeAll(true)">
                        Reset
                    </v-btn>
                </div>
            </template>
            <p
                v-else
                class="mb-0">
                Searching all content types
            </p>
        </div>
    </div>
</template>

<script>
    import ContentSearch from "../../../mixins/TabbedSearch";
    import FilterButtons from "../FilterButtons.vue";

    export default {
        components: {FilterButtons},
        data() {
            return {
                items: this.getItems(),
                activeSearchSources: [],
                activeSearchTypes: []
            }
        },
        props: {
            expand: {
                required: false,
                type: Boolean
            }
        },
        mixins: [
            ContentSearch
        ],
        computed: {
            hasContentType() {
                return !_.isEmpty(this.activeSearchTypes) || !_.isEmpty(this.activeSearchSources);
            }
        },
        methods: {
            getItems() {
                return {
                    source: [
                        {
                            id: 'chapter',
                            name: 'Chapters'
                        },
                        {
                            id: 'appendix',
                            name: 'Appendices'
                        }
                    ],
                    type: [
                        {
                            id: 'section',
                            name: 'Sections'
                        },
                        {
                            id: 'figure',
                            name: 'Figures'
                        },
                        {
                            id: 'table',
                            name: 'Tables'
                        },
                        {
                            id: 'equation',
                            name: 'Equations'
                        }
                    ]
                }
            },
            updateFilters() {
                EventBus.fire('update-content-search-filters', {key: 'types', value: {sources: [...this.searchSource], types: [...this.searchTypes]}});
            },
            removeSource(id) {
                this.activeSearchSources = _.filter(this.activeSearchSources, function(o) {
                    return o.id !== id;
                });
                this.searchSource = this.activeSearchSources;
                this.updateFilters();
                EventBus.fire('update-content-search-results')
            },
            removeType(id) {
                this.activeSearchTypes = _.filter(this.activeSearchTypes, function(o) {
                    return o.id !== id;
                });
                this.searchTypes = this.activeSearchTypes;
                this.updateFilters();
                EventBus.fire('update-content-search-results');
            },
            removeAll(performSearch = false) {
                this.searchTypes = [];
                this.searchSource = [];
                this.activeSearchSources = [];
                this.activeSearchTypes = [];
                EventBus.fire('update-content-search-filters', {key: 'types', value: {sources: [], types: []}})
                this.$emit('closeExpand');
                if (performSearch) {
                    EventBus.fire('update-content-search-results')
                }
            },
            upperFirst(word) {
                return _.upperFirst(word);
            }
        },
        created() {
            EventBus.listen('reset-content-type-filter', () => this.removeAll());
            EventBus.listen('remove-single-content-source', (id) => this.removeSource(id))
            EventBus.listen('remove-single-content-type', (id) => this.removeType(id))
            EventBus.listen('set-content-type-filter', (types) => {
                this.searchTypes = types.types;
                this.searchSource = types.sources;
                this.activeSearchTypes = types.types;
                this.activeSearchSources = types.sources;
            })
        }
    }
</script>
