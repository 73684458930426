<template>
    <div v-bind:id="getPrintMenuId(sectionId)" class="print-menu-content" title="">
        <a class="print-one-section" @click="printSelected(sectionId)">Print just this section</a>
        <a class="print-all-sections" @click="printSelected(sectionId, 1)">Print with sub sections</a>
    </div>
</template>
<script>
    export default {
        props: {
            documentId: {
                required: true,
                type: Number
            },
            chapterId: {
                required: true,
                type: Number
            },
            sectionId: {
                required: true,
                type: Number
            }
        },
        methods: {
            getPrintMenuId(id) {
                return 'print-menu-' + id;
            },
            printSelected(id, printAllSections = 0) {
                let element = document.getElementById('print-menu-'+id);
                element.style.display = 'none';
                window.open(Routing.generate('print_content', {
                    'documentId': btoa(this.documentId),
                    'chapterId': btoa(this.chapterId),
                    'sectionId': btoa(id),
                    'printAll': printAllSections
                }), '_blank');
            }
        }
    }
</script>
<style lang="scss">
    .print-menu-content {
        position: absolute;
        border: 1px solid lightgrey;
        background-color: #f9f9f9;
        min-width: 182px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        display: none;
        top:34px;
        right: 0;
        z-index: 10;
        box-shadow: inset -1px 3px 8px 5px #c4d2c312, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
        -webkit-box-shadow: inset -1px 3px 8px 5px #c4d2c312, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
    }
    .print-menu-content a:hover {
        background-color: #c9c9c9;
        color: black !important;
    }
    .print-menu-content a {
        color: black !important;
        padding: 8px;
        text-decoration: none !important;
        display: block;
    }
</style>
