var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "yellow lighten-5" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                    _vm._v(
                      "\n                    mdi-check-circle\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "pb-0" }, [
                _c("p", { staticClass: "mb-0 primary--text" }, [
                  _vm._v(
                    "\n                    You have an active premium subscription\n                    to access this " +
                      _vm._s(
                        _vm.isCurrentDocumentCollection ? "collection" : "title"
                      ) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                    Your subscription allows you to access all of the digital library\n                    content with exclusive features like My Library, Advanced search tool,\n                    Notes tool and much more\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "my-2",
              attrs: {
                target: "_blank",
                href: "https://support.iccsafe.org/?ht_kb_category=digital-codes",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "primary--text", attrs: { small: "" } },
                        [
                          _vm._v(
                            "\n                        mdi-book-variant-multiple\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pb-0" }, [
                    _c(
                      "span",
                      { staticClass: "primary--text font-weight-bold" },
                      [
                        _vm._v(
                          "\n                        Quick Start Guide\n                    "
                        ),
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "caption black--text" }, [
                      _vm._v(" Quick Start Guide for Digital Codes"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { small: "", color: "accent2" } }, [
                        _vm._v(
                          "\n                            mdi-arrow-right\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "my-2", on: { click: _vm.openRecentUpdatesModal } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "primary--text", attrs: { small: "" } },
                        [
                          _vm._v(
                            "\n                        mdi-bullhorn\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "span",
                        { staticClass: "primary--text font-weight-bold" },
                        [
                          _vm._v(
                            "\n                        What's New in Premium\n                    "
                          ),
                        ]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(_vm.updates, function (item, key) {
                        return _c(
                          "span",
                          { key: key, staticClass: "caption black--text" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.title) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      _c("br"),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { small: "", color: "accent2" } }, [
                        _vm._v(
                          "\n                            mdi-arrow-right\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "my-2",
              attrs: { href: this.getRoute("webinars"), target: "_blank" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "primary--text", attrs: { small: "" } },
                        [
                          _vm._v(
                            "\n                        mdi-laptop\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pb-0" }, [
                    _c(
                      "span",
                      { staticClass: "primary--text font-weight-bold" },
                      [
                        _vm._v(
                          "\n                        Premium Webinar\n                    "
                        ),
                      ]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "caption black--text" }, [
                      _vm._v(" Click to Register Now"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { small: "", color: "accent2" } }, [
                        _vm._v(
                          "\n                            mdi-arrow-right\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0" }, [
                _c(
                  "a",
                  { on: { click: _vm.showPriceComponent } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pb-0 cdpBlue--text font-weight-bold",
                            attrs: { cols: "auto" },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                                Additional Licenses\n                            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pb-0", attrs: { cols: "auto" } },
                          [
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.menu
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }