<template>
    <v-container>

        <v-row>

            <v-col cols="12" lg="6">

                <h1 class="oxygen white--text font-weight-light mb-8">Who We Are</h1>

                <p class="roboto white--text">
                    The International Code Council develops construction and public safety codes through a governmental
                    consensus process. The International Codes, or I-Codes, are the most trusted and widely adopted
                    source of
                    codes across the world. Decades of knowledge and experience guide our code development process to
                    create
                    safe, sustainable and affordable communities at a level playing field for builders, developers and
                    manufacturers. As industry experts, we provide the codes and standards that our members turn to,
                    building
                    safety professionals rely on and manufacturers trust.
                </p>

                <p class="roboto white--text mb-8">

                    State and local governments partner with ICC to adopt the International Codes either as published or
                    with
                    modifications to create a custom code. ICC’s team of experts provide project-based consulting to
                    develop
                    custom codes and make implementing modern building codes and standards seamless and straightforward.
                </p>

                <v-btn
                        :x-large="isLargeAndUp"
                        href="https://www.iccsafe.org/about/who-we-are/">
                    Learn More
                </v-btn>

            </v-col>

            <v-col cols="6" class="text-center d-none d-lg-block">
                <img :src="this.asset(require('../../images/homepage/who-we-are.png'))" width="460" height="459" alt="Graphic depicting a construction worker on the job site discussing plans with architect.">
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import Mobile from './../inc/mixins/mobile';

export default {
    name: 'WhoWeAre',
    mixins: [Mobile]
}
</script>

<style scoped>
p {
    line-height: 1.8em;
}
</style>
