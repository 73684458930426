var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c(
          "a",
          {
            attrs: { href: _vm.getNoteSectionLink(_vm.item), target: "_blank" },
          },
          [_vm._v(_vm._s(_vm.item.section_title || _vm.item.chapter_title))]
        ),
      ]),
      _vm._v(" "),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "mb-5" },
          [
            _vm.item.type === "bookmark"
              ? [
                  _vm.item.text
                    ? _c("p", {
                        staticClass: "mt-2 ml-1",
                        domProps: { innerHTML: _vm._s(_vm.item.text) },
                      })
                    : _vm._e(),
                ]
              : [
                  _vm.item.text || _vm.item.content
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "note-list-quote font-italic pa-1 mb-0 mt-1",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.item.content || _vm.item.text) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.annotation
                    ? _c("p", {
                        staticClass: "mt-2 ml-1",
                        domProps: { innerHTML: _vm._s(_vm.item.annotation) },
                      })
                    : _vm._e(),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-space-between" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              { staticClass: "px-2" },
              [
                _c(
                  "v-btn",
                  { attrs: { depressed: "", fab: "", small: "" } },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: _vm.getTagColor(_vm.item.tag) } },
                      [
                        _vm._v(
                          "\n                            bookmark\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("h5", [
                _vm._v(
                  "Created By: " +
                    _vm._s(_vm.item.firstName + " " + _vm.item.lastName)
                ),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Project Tag: " + _vm._s(_vm.item.tag))]),
              _vm._v(" "),
              _c("h5", [
                _vm._v("Timestamp: " + _vm._s(_vm.noteDateFormatted(_vm.item))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pa-5" },
            [
              _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", disabled: !_vm.item.canEditNote },
                      on: {
                        click: function ($event) {
                          return _vm.editNote(_vm.item)
                        },
                      },
                    },
                    [_vm._v("\n                    Edit\n                ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", disabled: !_vm.item.canDeleteNote },
                      on: {
                        click: function ($event) {
                          return _vm.showDeleteModal(_vm.item)
                        },
                      },
                    },
                    [_vm._v("\n                    Remove\n                ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }