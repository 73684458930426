<template>
    <div>
        <v-row>
            <v-col>
                <p v-if="activeUsers > enterpriseTotalSeats">
                    <v-alert
                        class="mb-0"
                        color="error"
                        type="warning"
                        dismissible>
                        <h4>The Active User count for your Enterprise profile has exceeded the number of available seats.  Please contact your ICC Specialist to add additional seats or deactivate users from the Manage Users tab.</h4>
                    </v-alert>
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="auto" class="flex-grow-1">
                <v-card height="100%" class="rounded-xl primary">
                    <v-card-subtitle class="white--text pb-0 pt-0">
                        <v-row>
                            <v-col cols="auto">
                                Active Users
                                <v-tooltip
                                    top
                                    color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            info
                                        </v-icon>
                                    </template>
                                    <span class="tooltip-content">
                                        <p class="caption mb-0">
                                            Active Users are the individuals who have accepted the invite<br/>
                                            and signed into the enterprise account at least once<br/>
                                            and are currently consuming one of the available<br/>
                                            licenses for Digital Codes Premium.
                                        </p>
                                    </span>
                                </v-tooltip>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="text-right">
                                {{ activeUsers }}
                            </v-col>
                        </v-row>                  
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="auto" class="flex-grow-1">
                <v-card height="100%" class="primary rounded-xl">
                    <v-card-subtitle class="white--text pb-0 pt-0">
                        <v-row>
                            <v-col cols="auto">
                                Available Enterprise Seats
                                <v-tooltip
                                    top
                                    color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            info
                                        </v-icon>
                                    </template>
                                    <span class="tooltip-content">
                                        <p class="caption mb-0">
                                            The total number of purchased enterprise seats from the<br/>
                                            active purchase orders. When Active Users exceeds<br/>
                                            Available Seats, the client will need to either add seats<br/>
                                            to match active users or deactivate users to be at<br/>
                                            or below the available seats number.
                                        </p>
                                    </span>
                                </v-tooltip>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="text-right">
                                {{ enterpriseTotalSeats }}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="auto" class="flex-grow-1">
                <v-card height="100%" class="primary rounded-xl">
                    <v-card-subtitle class="white--text pb-0 pt-0">
                        <v-row>
                            <v-col cols="auto">
                                Pending Users
                                <v-tooltip
                                    top
                                    color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            info
                                        </v-icon>
                                    </template>
                                    <span class="tooltip-content">
                                        <p class="caption mb-0">
                                            Pending Users are individuals who have been invited to the<br/>
                                            enterprise account but have not yet accepted the invite<br/>
                                            to join into enterprise . Once a user accepts the invite and signs in,<br/>
                                            they will be moved from Pending User to an Active User.
                                        </p>
                                    </span>
                                </v-tooltip>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="text-right">
                                {{ pendingUsers }}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" md="auto" class="flex-grow-1">
                <v-card height="100%" class="primary rounded-xl">
                    <v-card-subtitle class="white--text pb-0 pt-0">
                        <v-row>
                            <v-col cols="auto">
                                Total Users
                                <v-tooltip
                                    top
                                    color="black">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            info
                                        </v-icon>
                                    </template>
                                    <span class="tooltip-content">
                                        <p class="caption mb-0">
                                            Total Users represent all individuals listed in the Manage Users<br/>
                                            table who were added (either individually, through domain<br/>
                                            matching or CSV import) regardless of whether they<br/>
                                            are Active, Deactivated or Pending status.
                                        </p>
                                    </span>
                                </v-tooltip>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="text-right">
                                {{ totalUsers }}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: [
        'activeUsers',
        'pendingUsers',
        'enterpriseTotalSeats',
        'totalUsers'
    ],
}
</script>
