var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section--new" }, [
    _c(
      "div",
      { staticClass: "container-md mx-auto" },
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              { attrs: { "justify-lg": "center" } },
              [_c("v-col", { attrs: { cols: "12" } }, [_vm._t("default")], 2)],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }