<template>
    <div>
        <premium-answers-header>
        </premium-answers-header>
        <v-divider>
        </v-divider>
        <div class="grey lighten-5">
            <v-container class="content-container pt-0">
                <account-access-expand>
                </account-access-expand>
                <v-row class="pt-4">
                    <v-col>
                        <v-card outlined>
                            <v-row v-if="loading">
                                <v-col>
                                    <loading class="my-12">
                                    </loading>
                                </v-col>
                            </v-row>
                            <template v-else>
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col>
                                            <a
                                                class="fs-16"
                                                :href="getRoute('premium_answers')">
                                                <v-icon
                                                    color="primary"
                                                    class="mt-n1"
                                                    left>
                                                    mdi-arrow-left
                                                </v-icon>
                                                All questions
                                            </a>
                                        </v-col>
<!--                                        <v-col cols="auto">-->
<!--                                            <v-icon color="primary">-->
<!--                                                mdi-share-variant-outline-->
<!--                                            </v-icon>-->
<!--                                        </v-col>-->
                                    </v-row>
                                </v-card-text>
                                <v-divider>
                                </v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <h2
                                                class="mb-6"
                                                v-html="premiumAnswer.question">
                                            </h2>
                                            <div
                                                v-html="premiumAnswer.questionInDetail"
                                                class="question-details">
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            <h2>
                                                Answer from ICC Team
                                            </h2>
                                        </v-col>
                                        <v-col cols="auto">
                                            <access-icon
                                                :accessLevel="hasPremiumSubscription ? 'premiumActive' : 'premium'">
                                            </access-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text
                                    :class="!premiumAnswer.access && 'accent2Light text-center'"
                                    :style="`border-left: 5px solid ${$vuetify.theme.themes.light.accent2}!important;`">
                                    <template v-if="premiumAnswer.access">
                                        <v-row>
                                            <v-col>
                                                <div
                                                    v-html="premiumAnswer.answer"
                                                    class="question-details">
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="premiumAnswer.books">
                                                <h3
                                                    class="gray--darken-1 font-weight-medium view-page-sub-header">
                                                    Relevant Code Sections
                                                </h3>
                                                <p
                                                    v-for="(item, key) in premiumAnswer.books"
                                                    :key="key"
                                                    class="my-1">
                                                    <a
                                                        :href="item.targetUrl">
                                                        {{item.bookName}}
                                                    </a>
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <premium-answer-disclaimer :viewPage="true">
                                                </premium-answer-disclaimer>
                                            </v-col>
                                        </v-row>
                                        <v-expand-transition v-if="$store.getters.getLoggedIn !== ''">
                                            <v-row
                                                v-show="premiumAnswer.showRatings"
                                                dense
                                                align="center">
                                                <v-col cols="auto">
                                                    <p class="fs-16 mb-0 font-weight-bold">
                                                        Was this answer helpful?
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <v-rating
                                                        background-color="primary"
                                                        color="warning"
                                                        hover
                                                        length="5"
                                                        size="32"
                                                        :value="ratingValue"
                                                        @input="ratingUpdated"
                                                    ></v-rating>
                                                </v-col>
                                            </v-row>
                                        </v-expand-transition>
                                    </template>
                                    <div
                                        v-else
                                        class="accent2Light">
                                        <v-row>
                                            <v-col>
                                                <v-icon
                                                    size="34px"
                                                    class="accent--text">
                                                    mdi-lock-outline
                                                </v-icon>
                                                <h2 class="mt-4">
                                                    Subscribe to Digital Codes Premium to view full answer
                                                </h2>
                                                <p class="mt-3 fs-16 mb-3">
                                                    This feature requires a Digital Codes Premium subscription
                                                </p>
                                                <ask-search-share center>
                                                </ask-search-share>
                                                <start-free-trial>
                                                </start-free-trial>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-divider>
                                </v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="auto">
                                            <h3 class="gray--darken-1 font-weight-medium view-page-sub-header mb-1">
                                                Titles
                                            </h3>
                                            <show-titles
                                                :booksInfo="premiumAnswer.booksInfo"
                                                :answerViewPage="true">
                                            </show-titles>
                                        </v-col>
                                        <v-col :class="!isMobilePort && 'text-right'">
                                            <h3 class="gray--darken-1 font-weight-medium view-page-sub-header mb-1">
                                                Topics
                                            </h3>
                                            <show-topics
                                                :topics="premiumAnswer.topics"
                                                :answerViewPage="true">
                                            </show-topics>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </template>
                        </v-card>
                        <template v-if="relatedPremiumAnswers.length">
                            <v-row>
                                <v-col>
                                    <h2 class="font-weight-regular mt-6">
                                        Related questions
                                    </h2>
                                </v-col>
                            </v-row>
                            <answer-list
                                v-if="relatedPremiumAnswers.length"
                                :loading="relatedAnswersLoading"
                                :premium-answers="relatedPremiumAnswers"
                                :show-more="false"
                                :show-scroll-to-top="false"
                                @titleClick="(data) => goToRoute('premium_answers', {document: data})"
                                @topicClick="(data) => goToRoute('premium_answers', {topic: data})">
                            </answer-list>
                        </template>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3">
                        <what-is-premium-answers>
                        </what-is-premium-answers>
                        <recently-viewed-questions
                            v-if="getId()"
                            class="mt-3"
                            :id="getId()">
                        </recently-viewed-questions>
                        <DidYouKnow />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
    import PremiumAnswersHeader from "../../components/premium-answers/PremiumAnswersHeader.vue";
    import WhatIsPremiumAnswers from "../../components/premium-answers/WhatIsPremiumAnswers.vue";
    import SearchQuestions from "../../components/premium-answers/SearchQuestions.vue";
    import RecentlyViewedQuestions from "../../components/premium-answers/RecentlyViewedQuestions.vue";
    import AnswerList from "../../components/premium-answers/AnswerList.vue";
    import AccountAccessExpand from "../../components/expands/AccountAccessExpand.vue";
    import {PremiumAnswersApi} from "../../api/PremiumAnswers";
    import AccessIcon from "../../components/icons/AccessIcon.vue";
    import Loading from "../../components/content/partials/loading.vue";
    import AskSearchShare from "../../components/icons/InlineIcons.vue";
    import StartFreeTrial from "../../components/buttons/StartFreeTrial.vue";
    import {ApiService} from "../../api/ApiService";
    import Mobile from "../../components/inc/mixins/mobile";
    import ShowTitles from "../../components/premium-answers/ShowTitles.vue";
    import ShowTopics from "../../components/premium-answers/ShowTopics.vue";
    import DidYouKnow from "../../components/premium-answers/DidYouKnow.vue";
    import PremiumAnswerDisclaimer from "../../components/premium-answers/PremiumAnswerDisclaimer.vue";

    export default {
        name: 'PremiumAnswersView',
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                premiumInfoActive: false,
                loading: true,
                premiumAnswer: {},
                relatedPremiumAnswers: [],
                relatedAnswersLoading: true,
                ratingValue: 0
            }
        },
        components: {
            StartFreeTrial,
            AskSearchShare,
            Loading,
            AccessIcon,
            AccountAccessExpand,
            AnswerList,
            RecentlyViewedQuestions,
            SearchQuestions,
            WhatIsPremiumAnswers,
            PremiumAnswersHeader,
            ShowTitles,
            ShowTopics,
            DidYouKnow,
            PremiumAnswerDisclaimer
        },
        mixins: [
            Mobile
        ],
        methods: {
            async getPremiumAnswer() {
                this.loading = true;
                const results = await PremiumAnswersApi.getPremiumAnswer(this.getId);
                this.premiumAnswer = results.data;
                this.loading = false;

                return ApiService.logPremiumTagClick(this.premiumAnswer.id);
            },
            async getRelatedAnswers() {
                this.relatedAnswersLoading = true;
                const filters = {relatedQuestionId: this.getId};
                const results = await PremiumAnswersApi.getPremiumAnswers(filters);
                this.relatedPremiumAnswers = (results.data.results || []).slice(0, 3);
                this.relatedAnswersLoading = false;
            },
            getId() {
                return this.id;
            },
            ratingUpdated(ratingValue) {
                this.premiumAnswer.showRatings = false
                ApiService.rateTag(this.getId(), ratingValue)
            }
        },
        mounted() {
            this.getPremiumAnswer()
            this.getRelatedAnswers()
        }
    }
</script>

<style lang="scss" scoped>
.view-page-sub-header {
    font-size: 14px !important;
    font-weight: bold !important;
}
</style>
