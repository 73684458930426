export default {
    data(){
        return {
            href: ''
        }
    },
    methods: {
        addCanonicalTag() {
            // Check hash in url and based on that add canonical Tag or remove it.
            if (window.location.hash) {
                if (!document.getElementById('canonicalTag')) {
                    this.createCanonicalTag();
                } else if (this.href !== window.location.href.replace(window.location.hash,"")
                    && document.getElementById('canonicalTag')) {
                        this.removeCanonicalTag();
                        this.createCanonicalTag();
                }         
            } else {
                if (document.getElementById('canonicalTag')) {
                    this.removeCanonicalTag();
                }
            }
        },
        removeCanonicalTag() {
            let canonicalTag = document.getElementById('canonicalTag');
            document.head.removeChild(canonicalTag);
        },
        createCanonicalTag() {
            let link = document.createElement('link');
            this.href = window.location.href.replace(window.location.hash,"");
            link.id = "canonicalTag";
            link.rel = "canonical";
            link.href = this.href;
            document.head.appendChild(link);
        }
    }
}
