<template>
    <v-overlay
        :value="loading"
        z-index="300">
        <v-progress-circular
            indeterminate
            size="64">
        </v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            EventBus.listen('full-page-loader', (data) => this.loading = data);
        }
    }
</script>
