<template>
    <v-dialog
        v-model="dialog_share_annotation_bookmark"
        width="500"
        persistent>
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Share Notes
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="closeModal">close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row v-if="content_shared">
                    <v-col>
                        <v-alert
                            v-model="content_shared"
                            :value="true"
                            type="success">
                            {{ share_success_message }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="error_message">
                    <v-col>
                        <v-alert
                            :value="error_status"
                            color="error"
                            icon="warning"
                            outline>
                            {{ error_message }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row
                    align="center"
                    v-for="(email, index) in emails"
                    :key="index">
                    <v-col>
                        <v-text-field
                            hide-details
                            solo
                            :class="{ 'mb-1': $vuetify.breakpoint.smAndUp }"
                            label="Enter email address"
                            v-model="email.email"
                            :key="index">
                        </v-text-field>
                    </v-col>
                    <v-col sm="auto">
                        <v-btn
                            @click="addNewEmail()"
                            v-if="index === 0">
                            Add
                        </v-btn>
                        <v-btn
                            small
                            @click="removeEmail(index)"
                            v-else>
                            Remove
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            solo
                            label="Enter your message/notes to recipient here..."
                            rows="5"
                            auto-grow
                            counter=1000
                            v-model="message">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeModal">
                    Close
                </v-btn>
                <v-btn
                    v-if="!content_shared"
                    color="primary"
                    :loading="shareLoading"
                    :disabled="shareLoading"
                    @click="inputFiledValidation">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                dialog_share_annotation_bookmark: false,
                emails: [{
                    'email': ''
                }],
                message : '',
                error_status: false,
                error_message: '',
                content_shared: false,
                share_success_message: 'Content shared successfully!',
                checked_annotations_bookmarks : [],
                multiple_email : [],
                share_loading: false
            };
        },
        mixins: [Mobile],
        computed: {
            shareLoading: function() {
                return this.share_loading;
            }
        },
        methods: {
            showModal(param) {
                this.email = '';
                this.message = '';
                this.error_status = false;
                this.error_message =  '';
                this.dialog_share_annotation_bookmark = true;
                this.checked_annotations_bookmarks = param;
            },
            addNewEmail(){
              this.emails.push({email: ''});
            },
            removeEmail(index) {
                this.emails.splice(index, 1);
            },
            inputFiledValidation() {
                if (this.checked_annotations_bookmarks.length == 0) {
                    this.error_status = true;
                    this.error_message = 'Please select at least one Bookmark/Annotation to share!';
                } else if(!this.checkIfIsEmpty(this.emails)) {
                    this.error_status = true;
                    this.error_message = 'Please enter email.';
                } else if(!this.validateEmail(this.emails)) {
                    this.error_status = true;
                    this.error_message = 'Please enter valid Email.';
                } else {
                    this.share_loading = true;

                    const postData = {
                        'email': this.emails,
                        'message': this.message,
                        'annotationsBookmarks': this.checked_annotations_bookmarks
                    };

                    this.$http.post(Routing.generate('share_annotation_bookmarks'), postData)
                        .then(response => {
                            if (response.data.success) {
                                this.error_status = false;
                                // Display success message
                                this.content_shared = true;
                                setTimeout(function(){
                                    this.dialog_share_annotation_bookmark = false;
                                    EventBus.fire('show-shared-by-me-tab');
                                }, 2000);

                            } else {
                                this.error_status = true;
                                this.error_message = response.data.message;
                            }
                            this.share_loading = false;
                        });
                }
            },
            checkIfIsEmpty(emails){
                for(let i = 0; i < emails.length; i++) {
                    if (emails[i].email.length === 0) {
                        return false;
                    }
                }
                return true;
            },
            validateEmail(emails) {
                let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                for(let i = 0; i < emails.length; i++) {
                        if (re.test(emails[i].email) === false) {
                            return false;
                        }
                    }
                return true;
            },
            closeModal(){
                EventBus.fire('force-rerender-share-note');
                this.dialog_share_annotation_bookmark = false;
            }
        },
        created() {
            EventBus.listen('show-modal-share-annotation-bookmark', (param) => this.showModal(param));
        }
    }
</script>
