import {SuperAxios} from "./SuperAxios";
import {ExpirableStorage} from "../classes/ExpirableStorage";
import axios from "axios";
import Fuse from "fuse.js";
import {StringUtil} from "../util/StringUtil";

const TITLE_CACHE_KEY = 'search:all_titles';
const TITLE_CACHE_TTL = 60 * 60 * 3;

/**
 *
 * @return {Array}
 */
const cacheGet = function () {
    return ExpirableStorage.get(TITLE_CACHE_KEY, []);
}

const cachePut = function (data) {
    ExpirableStorage.set(TITLE_CACHE_KEY, data, TITLE_CACHE_TTL);
}

let fuse = null;
let isFastSearchEnabled = false;

export class TitleSearch {

    static enableFastSearch(bool) {
        isFastSearchEnabled = !!bool;

        if (isFastSearchEnabled) {
            this.loadAll();
        }
    }

    static isFastSearchEnabled() {
        return isFastSearchEnabled;
    }

    static hasData() {
        const result = cacheGet();
        return Array.isArray(result) && result.length > 0;
    }

    static fuseClient() {

        if (fuse === null) {
            fuse = new Fuse(cacheGet(), {
                keys: ['title']
            });
        }

        return fuse;
    }

    static async search(query) {

        if (isFastSearchEnabled) {

            if (!this.hasData()) {
                this.loadAll(true);
            }

            return new Promise(((resolve, reject) => {

                const result = this.fuseClient().search(query, {
                    limit: 5
                });

                const items = result.map((element) => {

                    const item = element.item;

                    // gotta do the highlights of our own...
                    item['highlighted'] = {
                        "display_title": StringUtil.highlightSearchString(query, item['title'])
                    }

                    return item;
                });

                resolve(items);

            }));
        }

        return this.slowSearch(query);
    }

    static slowSearch(query) {

        const apiUrl = Routing.generate('search_titles', {
            query: query,
            limit: 5
        });

        return new Promise(((resolve, reject) => {

            SuperAxios.getOnce(apiUrl).then(response => {
                const data = response.data.data;
                resolve(data);
            }).catch(() => {
                resolve([]);
            });

        }));
    }

    // TODO: make it async
    static loadAll(force = false) {

        if (force || !this.hasData()) {
            axios.get('/json/search_titles.json').then((res) => {
                cachePut(res.data);
            });
        }
    }
}
