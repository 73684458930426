var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.switchUserActive || _vm.isExiting
    ? _c(
        "div",
        {
          staticClass:
            "d-flex-not-important justify-center align-center pa-2 text-center blue darken-3 white--text",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "visible",
                  rawName: "v-visible",
                  value: _vm.switchUserActive,
                  expression: "switchUserActive",
                },
              ],
            },
            [
              _vm._v("\n        You are currently simulating this as: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.switchUserName) +
                    " (" +
                    _vm._s(_vm.switchUserEmail) +
                    ")"
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "ml-5",
              attrs: {
                depressed: "",
                small: "",
                color: "error",
                loading: _vm.isExiting,
                title: _vm.returnUrl,
              },
              on: { click: _vm.exitClicked },
            },
            [_vm._v("Return to Admin Dashboard\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }