var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("breadcrumb", { attrs: { title: "Licenses" } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._l(_vm.alerts_role, function (alert, index) {
                  return _vm.getActiveRole(alert.id)
                    ? _c("alert-component", {
                        key: index,
                        attrs: {
                          elid: "alert-notes-new",
                          elclass: "mb-2",
                          color: "primary",
                          type: "info",
                          title: alert.text,
                          dismissible: true,
                          cookieid: "notes-license",
                        },
                      })
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isConcurrent
            ? [
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pr-0", attrs: { cols: "auto" } },
                      [
                        _c("p", { staticClass: "primary--text caption mb-0" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$tours["licensesTour"].start()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Licenses Help\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-col", { staticClass: "pl-0" }, [
                      _c(
                        "span",
                        { staticClass: "licenses-step-0" },
                        [
                          !_vm.userId
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$tours["licensesTour"].start()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("help")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.$vuetify.breakpoint.smAndDown
                  ? [
                      _c(
                        "v-card",
                        { staticClass: "mb-8" },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                "hide-slider": "",
                                "show-arrows": "",
                                "background-color": "primary",
                                dark: "",
                              },
                              model: {
                                value: _vm.active_tab,
                                callback: function ($$v) {
                                  _vm.active_tab = $$v
                                },
                                expression: "active_tab",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  ref: "allSubscriptions",
                                  staticClass: "text-capitalize tab-class",
                                  on: {
                                    click: function ($event) {
                                      return _vm.panelAction("Purchases")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Purchases/Overview\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                {
                                  ref: "personalSubscription",
                                  staticClass: "text-capitalize tab-class",
                                  on: {
                                    click: function ($event) {
                                      return _vm.panelAction("PersonalUse")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Personal Use\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                {
                                  ref: "sharedByMeTab",
                                  staticClass: "text-capitalize tab-class",
                                  on: {
                                    click: function ($event) {
                                      return _vm.panelAction("AssignedByMe")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Assigned By Me\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                {
                                  ref: "sharedToMeTab",
                                  staticClass: "text-capitalize tab-class",
                                  on: {
                                    click: function ($event) {
                                      return _vm.panelAction("AssignedToMe")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Assigned to Me\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                {
                                  ref: "concurrentSubscriptions",
                                  staticClass: "text-capitalize tab-class pr-1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.panelAction("Concurrent")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Concurrent\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c(
                                    "keep-alive",
                                    [
                                      _vm.getPanel === "Purchases"
                                        ? [
                                            _c(_vm.getPanel, {
                                              tag: "component",
                                              attrs: { "user-id": _vm.user },
                                            }),
                                          ]
                                        : _vm.getPanel === "PersonalUse"
                                        ? [
                                            _c(_vm.getPanel, {
                                              tag: "component",
                                              attrs: { "user-id": _vm.user },
                                            }),
                                          ]
                                        : _vm.getPanel === "AssignedByMe"
                                        ? [
                                            _c(_vm.getPanel, {
                                              tag: "component",
                                              attrs: { "user-id": _vm.user },
                                            }),
                                          ]
                                        : _vm.getPanel === "AssignedToMe"
                                        ? [
                                            _c(_vm.getPanel, {
                                              tag: "component",
                                              attrs: { "user-id": _vm.user },
                                            }),
                                          ]
                                        : _vm.getPanel === "Concurrent"
                                        ? [
                                            _c(_vm.getPanel, {
                                              tag: "component",
                                              attrs: { "user-id": _vm.user },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  solo: "",
                                  "hide-details": "",
                                  items: _vm.panel_items,
                                },
                                model: {
                                  value: _vm.panel_mobile,
                                  callback: function ($$v) {
                                    _vm.panel_mobile = $$v
                                  },
                                  expression: "panel_mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "keep-alive",
                        [
                          _vm.getMobilePanel === "purchase-overview"
                            ? [
                                _c("purchases-mobile", {
                                  attrs: { "user-id": _vm.user },
                                }),
                              ]
                            : _vm.getMobilePanel === "personal-use"
                            ? [
                                _c("personal-use-mobile", {
                                  attrs: { "user-id": _vm.user },
                                }),
                              ]
                            : _vm.getMobilePanel === "assigned-by-me"
                            ? [
                                _c("assigned-by-me-mobile", {
                                  attrs: { "user-id": _vm.user },
                                }),
                              ]
                            : _vm.getMobilePanel === "assigned-to-me"
                            ? [
                                _c("assigned-to-me-mobile", {
                                  attrs: { "user-id": _vm.user },
                                }),
                              ]
                            : _vm.getMobilePanel === "concurrent"
                            ? [
                                _c("concurrent-mobile", {
                                  attrs: { "user-id": _vm.user },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                _vm._v(" "),
                _c("v-tour", {
                  attrs: {
                    name: "licensesTour",
                    steps: _vm.steps,
                    callbacks: _vm.licensesTourCallback,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }