<template>
    <div>

        <p class="oxygen mb-2 fs-14 font-weight-bold">User Acknowledgement and Disclaimer</p>

        <p class="fs-14 mb-0">
            By acknowledging these terms ["Acknowledge" confirmed before your first use], you agree and understand
            that your use of ICC AI Navigator’s features and outputs is at your own risk. You further confirm that
            you understand and agree to all terms of <a href="#" @click.prevent="showDisclaimer" class="primary--text font-weight-bold text-decoration-underline">this
            disclaimer</a>.
        </p>

    </div>
</template>

<script>
export default {
    methods: {
        showDisclaimer() {
            EventBus.fire('ai_navigator_show_disclaimer');
        }
    }
}
</script>