import axios from 'axios'
const client = axios.create();

export const SearchApi = {
    getCategories(data) {
        return client.get(Routing.generate('get_categories', data));
    },
    getAllYears() {
        return client.get(Routing.generate('get_all_years'));
    },
    getAllTitles() {
        return client.get(Routing.generate('get_all_titles'));
    },
}
