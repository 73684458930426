import {ClipboardUtils} from "../../../../util/ClipboardUtils";

export default {
    data(){
        return {
            snackbar: false,
            timeout: 2000,
        }
    },
    methods: {
        sectionBookmarkAction(scrollTarget = null) {
            if (scrollTarget) {
                this.$store.commit('setScrollTarget', scrollTarget);
            }

            EventBus.fire('bookmark-modal', {
                sectionTitle: this.breadcrumbs.sectiontop,
                sectionId: this.sectionId,
                chapterId: this.chapterId,
                document_id: this.documentId,
                id: this.note.bookmarks[0] ? this.note.bookmarks[0].id : ''
            });
        },

        sectionShareAction() {
            let parentElement = document.getElementById('section-content_'+this.sectionId).getElementsByTagName('section'),
                sectionId = parentElement[0] ? parentElement[0].getAttribute('id') : 'action-wrapper-'+this.sectionId;

            EventBus.fire('show-modal-share-sections', {
                'content_id': this.sectionId,
                'document_id': this.documentId,
                'chapter_id': this.chapterId,
                'book_name': this.breadcrumbs.title,
                'section_title': this.breadcrumbs.sectiontop,
                'section_id':sectionId,
            });
        },

        sectionPrintAction() {
            let element = document.getElementById('section-content_'+this.sectionId);
            let figcaption = element ? element.getElementsByTagName('figcaption') : null;
            let tableId = figcaption.length ? figcaption[0].getAttribute('id') : null;
            let figureTitle = figcaption.length ? figcaption[0].getElementsByClassName('figure_title') : '';

            if (figureTitle.length || (this.hasTable && !tableId)) {
                let tableTitle = figcaption.length ? figcaption[0].getElementsByClassName('table_title') : '';

                let json = {};
                json.html = element.innerHTML;
                json.title = figureTitle.length ?
                             figureTitle[0].innerText :
                             (tableTitle.length ? tableTitle[0].innerText : '');
                if(!json.title.length) {
                    json.title = figureTitle.length ? 'Figure' : 'Table';
                }
                this.$http.post(
                    Routing.generate("print_figure"), {data: JSON.stringify(json)}, {emulateJSON: true}
                ).then(result => {
                    if(result.data.length) {
                        window.open(result.data, '_blank');
                    }
                });
            } else {
                let tableWidth = 998; // default
                if(tableId) {
                    let tbl = element.getElementsByTagName("table");
                    tableWidth = tbl.length ? tbl[0].offsetWidth : tableWidth;
                }

                window.open(Routing.generate('print_content', {
                    'documentId': btoa(this.documentId),
                    'chapterId': btoa(this.chapterId),
                    'sectionId': btoa(this.sectionId),
                    'tableWidth': tableWidth,
                    'printAll': 0,
                    'tableId': tableId
                }), '_blank');
            }
        },

        sectionJumpAction() {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let search = params.get("params[any]");
            let sectionID = document.getElementById('section-content_'+this.sectionId).getElementsByTagName('section');
            let parameters = 'action-wrapper-'+this.sectionId;
            if (!sectionID[0]) {
                sectionID = document.getElementById('section-content_'+this.sectionId).getElementsByTagName('figcaption');
            }
            if (sectionID[0] && sectionID[0].getAttribute('id')) {
                parameters = sectionID[0].getAttribute('id');
            }

            this.$http.post(Routing.generate('get_url_slug', {
                'documentId': this.documentId,
                'chapterId': this.chapterId
            })).then(response => {
                if (!response.data.success) {
                    window.open(response.data.slugURL + '?search=' + search + '#' + parameters, '_blank');
                }
            });
        },

        sectionCopyAction(includeFormatting = false) {

            /** @type {HTMLElement} */
            const contentItemResultElement = this.$el;

            let elementToCopyFrom = document.getElementById('section-content_' + this.sectionId);
            if (contentItemResultElement) {
                let temp = contentItemResultElement.querySelector('#section-content_' + this.sectionId);
                if (temp) {
                    elementToCopyFrom = temp;
                }
            }

            if (includeFormatting) {
                ClipboardUtils.copyToClipboard(elementToCopyFrom);
            } else {
                ClipboardUtils.copyTextToClipboard(elementToCopyFrom.textContent);
            }

            this.snackbar = true;
        },

        getNotes() {
            this.note.bookmarks = [];
            this.note.annotations = [];
            this.$http.get(Routing.generate('get_bookmark_by_parameters',
                {
                    'documentId': this.documentId,
                    'sectionId': this.sectionId,
                    'chapterId': this.chapterId
                })).then(response => {
                let data = response.data;
                if (data.id) {
                    this.note.bookmarks.push(data);
                    this.bookmarkIcon = 'bookmark';
                } else {
                    this.bookmarkIcon = 'bookmark_border';
                }

            });
            //TODO: PRA-3605
            if(!this.isContentSearch) {
                this.$http.get(Routing.generate('get_section_notes', {
                     'documentId': _.toInteger(this.documentId),
                     'sectionId': this.sectionId,
                })).then(response => {
                    let data = response.data;
                    for(let i = 0;  i <= data.length; i++){
                        if(data[i] !== undefined){
                            this.note.annotations.push(data[i]);
                        }
                    }
                    this.loaded = true;
                    this.loadingNotes = false;
                });
            }
            this.loaded = true;
            this.loadingNotes = false;
        },
    }

}
