export default {
    data () {
        return {
            categoriesToInclude: [
                "I-Codes", "Standards", "Locations", "Commentaries",
                "Resources", "Topics", "Publisher", "Collections"
            ]
        }
    },
    methods: {
        sortedCategories(categories, key = 'name') {
            const categorySortOrder = _.invert(this.categoriesToInclude)
            return categories.sort((a, b) => {
                let ai = categorySortOrder[a[key]];
                let bi = categorySortOrder[b[key]];
                return ai - bi;
            });
        }
    }
}
