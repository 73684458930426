<template>
    <v-chip
        v-if="isPremiumSubscribed(documentInfo.sku) 
            && ('1' === contentPremium || documentInfo.contentPremium)"
        dark
        color="accent2"
        x-small
        label
        class="mx-2 px-1">
        <a
            class='white--text'
            v-if="('1' == contentProtection || documentInfo.contentProtection)"
            @click="openAccessLevelPermissionModal('Premium Lite')">
            {{$getConst('accessLevels').premiumLite}}
        </a>
        <a
            class='white--text'
            v-else
            @click="openAccessLevelPermissionModal('Premium')">
            {{$getConst('accessLevels').premium}}
        </a>
    </v-chip>
    <v-chip
        v-else-if="!isContentRestricted(documentInfo)"
        dark
        color="primary"
        x-small
        label
        class="mx-2 px-1">
        <a
            class='white--text'
            v-if="('1' == contentBasic && '1' == contentProtection)
                || (documentInfo.contentBasic && documentInfo.contentProtection)"
            @click="openAccessLevelPermissionModal('Basic')">
            {{$getConst('accessLevels').basic}}
        </a>
        <a
            class='white--text'
            v-else
            @click="openAccessLevelPermissionModal('Basic Plus')">
            {{$getConst('accessLevels').basicPlus}}
        </a>
    </v-chip>
</template>

<script>
    export default {
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            contentPremium: {
                required: false,
                type: String
            },
            contentProtection: {
                required: false,
                type: String
            },
            contentBasic: {
                required: false,
                type: String
            },
        },
        methods: {
            openAccessLevelPermissionModal(accessLevel) {
                EventBus.fire('access-level-permission-prompt', accessLevel);
            },
        }
    }
</script>
