<template>
    <div>

        <hero-section class="py-8">
            <h3 class="roboto text-center text-uppercase mb-5">Browse Available Contents By:</h3>
            <category-collection-buttons x-large-buttons>
            </category-collection-buttons>
        </hero-section>

        <carousel-banner
            additional-classes="my-10">
        </carousel-banner>

        <hero-section class="elevation-2 primary">
            <premium-upsell></premium-upsell>
        </hero-section>

        <hero-section class="py-8 grey lighten-4">
            <upsell-mobile></upsell-mobile>
        </hero-section>

        <hero-section class="py-5">
            <popular-publishers></popular-publishers>
        </hero-section>

        <hero-section class="grey lighten-4">
            <customer-testimonials></customer-testimonials>
        </hero-section>

        <div class="primary py-10 elevation-2">
            <premium-compare></premium-compare>
        </div>

        <v-container>
            <category-map></category-map>
        </v-container>

        <hero-section class="py-2">
            <popular-code-collections></popular-code-collections>
        </hero-section>

        <hero-section class="grey lighten-4 py-5">
            <div class="roboto">
                <h3 class="primary--text text-uppercase text-center mb-5">Contents by Industry Topic</h3>

                <v-row>
                    <v-col cols="12" lg="4"
                           v-for="(item,i) in contentsByIndustryTopic"
                           :key="i">

                        <v-avatar
                                color="#FFA201"
                                size="5"
                                rounded="0"
                                class="mr-2">
                        </v-avatar>

                        <a :href="getTopicUrlFromName(item)">{{ item }}</a>
                    </v-col>
                </v-row>
            </div>
        </hero-section>

        <hero-section>
            <stay-connected></stay-connected>
        </hero-section>

        <hero-section class="py-8 elevation-2 primary">
            <who-we-are></who-we-are>
        </hero-section>

        <hero-section class="py-8">
            <frequently-asked-questions></frequently-asked-questions>
        </hero-section>

        <hero-section class="py-5">
            <subscribe-now></subscribe-now>
        </hero-section>

    </div>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    import {AppParameters} from "./../../AppParameters";

    import CategoryMap from './partials/categoryMap';
    import Webinar from './partials/webinar';
    import CategoryCollectionButtons from './partials/categoryCollectionsButtons';
    import PremiumCompare from './partials/premiumCompare';
    import PopularPublishers from "../homepage/PopularPublishers";
    import WhoWeAre from "../homepage/WhoWeAre";
    import FrequentlyAskedQuestions from "../homepage/FrequentlyAskedQuestions";
    import HeroSection from "../homepage/HeroSection";
    import UpsellMobile from "../homepage/UpsellMobile";
    import StayConnected from "../homepage/StayConnected";
    import PopularCodeCollections from "../homepage/PopularCodeCollections";
    import PremiumUpsell from "../homepage/PremiumUpsell";
    import SubscribeNow from "../homepage/SubscribeNow";
    import CustomerTestimonials from "../homepage/CustomerTestimonials";
    import CarouselBanner from "../banners/CarouselBanner.vue";

    export default {
        components: {
            CarouselBanner,
            CustomerTestimonials,
            SubscribeNow,
            PremiumUpsell,
            PopularCodeCollections,
            StayConnected,
            UpsellMobile,
            HeroSection,
            FrequentlyAskedQuestions,
            WhoWeAre,
            PopularPublishers,
            CategoryMap,
            Webinar,
            CategoryCollectionButtons,
            PremiumCompare
        },
        mixins: [Mobile],
        data() {
            return {
                contentsByIndustryTopic: [
                    "Administration",
                    "ANCR",
                    "Electrical",
                    "Energy",
                    "Fire",
                    "Green and Sustainability",
                    "Inspection",
                    "Interior Design",
                    "Off-Site Construction",
                    "Structural Design"
                ]
            }
        },
        methods: {
            getTopicUrlFromName(name) {
                const slug = _.toLower(_.replace(name, /\s+/g, '-'));

                return this.getRoute('codes_category', {
                    category: slug
                });
            },
            showRevitPromo() {
                return AppParameters.display_revit_link;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        background-image: url('../../images/texture_background.png');
        background-position: center center;
        background-size: 100% auto;
    }
    img {
        max-width: 100%;
    }

    #mobile_upsell {
      background: rgba(236, 241, 244, 0.5);
      background-blend-mode: normal;
    }

    .revit-promo-banner-mobile {
        h2 {
            font-size: 32px!important;
        }
        p {
            font-size: 24px!important;
        }
    }
</style>
