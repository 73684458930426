var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      !_vm.isMobileAndTablet
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("h4", [
                  _vm._v("\n                Content Filters\n            "),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "start", justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pr-2", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.hasPremiumSubscription,
                    small: "",
                    dark: _vm.hasPremiumSubscription,
                    block: "",
                    outlined: "",
                    depressed: "",
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openSaveSearchModal()
                    },
                  },
                },
                [_vm._v("\n                Save Search\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pl-2", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.hasPremiumSubscription,
                    small: "",
                    block: "",
                    dark: _vm.hasPremiumSubscription,
                    outlined: "",
                    depressed: "",
                    color: "black",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clearSearch()
                    },
                  },
                },
                [_vm._v("\n                Reset Filters\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("filter-expander", {
        staticClass: "mt-3",
        attrs: {
          title: "Advanced Term Search",
          "component-name": "AdvancedSearch",
        },
      }),
      _vm._v(" "),
      _c("filter-expander", {
        staticClass: "mt-3",
        attrs: { title: "My Premium Titles", "component-name": "TitlesPanel" },
      }),
      _vm._v(" "),
      _c("filter-expander", {
        staticClass: "mt-3",
        attrs: { title: "Categories", "component-name": "CategoriesPanel" },
      }),
      _vm._v(" "),
      _c("filter-expander", {
        staticClass: "mt-3",
        attrs: { title: "Years", "component-name": "YearsPanel" },
      }),
      _vm._v(" "),
      _c("filter-expander", {
        staticClass: "mt-3",
        attrs: { title: "Content Type", "component-name": "ContentTypePanel" },
      }),
      _vm._v(" "),
      _c("saved-searches", { staticClass: "mt-3" }),
      _vm._v(" "),
      _c("add-search"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }