<template>
    <div v-if="hasProduct">
        <v-card class="mt-6">
            <v-toolbar
                id="related_subscriptions"
                color="white"
                flat>
                <v-toolbar-title class="title font-weight-thin">Related Subscriptions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-layout row>
                    <v-flex xs3>
                        <img class="my-2"
                             :src="getBookCover(related_product)"
                             @error="loadDefaultBookCover"
                             alt="related_product.display_title"
                             style="max-width: 100%"/>
                    </v-flex>
                    <v-flex
                        xs9
                        pt-2
                        pl-2>
                        <v-layout row>
                            <v-flex xs12>
                                <p class="font-weight-bold">{{ related_product.display_title }}</p>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs12>
                                <v-btn
                                    depressed
                                    class="primary right"
                                    @click="linkAction(related_product)">
                                    View Premium Options
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import RelatedProduct from '../../inc/mixins/relatedProduct';
    import BookCover from './../../inc/mixins/bookCover';
    export default {
        mixins: [
            RelatedProduct,  
            BookCover
        ]
    }
</script>

<style lang="scss">
    #related_subscriptions {
        .v-toolbar__content {
            border-bottom: 1px solid #efefef;
        }
    }
</style>
