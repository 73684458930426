import Seo from './seo';

export default {
    data() {
        return {
            contextMenuWidth: null,
            contextMenuHeight: null
        }
    },
    mixins: [
        Seo
    ],
    computed: {
        chapterArray() {
            return this.$store.getters.getChapters;
        },
        chapterCheck() {
            return (_.size(this.chapterArray) > 0);
        },
        isLoggedIn() {
            return this.$store.getters.getLoggedIn !== '';
        },
        activeHashTag() {
            return _.replace(this.$store.getters.getActiveHash, new RegExp('text-id-','g'), '');
        }
    },
    watch: {
        chapterArray: function() {
            let element = document.getElementById('chapter-wrapper');
            if (element && !this.$vuetify.breakpoint.smAndDown) {
                EventBus.fire('set-chapter-height');
            }
        }
    },
    methods: {
        chaptersTitle(chapter) {
            if (chapter.title) {
                return chapter.title;
            } else if(chapter.link) {
                return chapter.link.title;
            } else if(chapter.text) {
                return chapter.text;
            }
        },

        showContent(data, clickedFromMyNotes) {
            data.clickedFromMyNotes = clickedFromMyNotes || false;
            if (data.isHtml) {
                window.location = Routing.generate('content_show_chapter_content', { 'chapterId': data.id });
            } else {
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });

                let examId = params.examId;
                if(examId && '' !== examId) {
                    data.examId = examId;
                }
                if(data.action){
                    _.unset(data, 'action');
                }
                EventBus.fire('toggle-mobile-legend', false);
                EventBus.fire('show-book-content', data);
            }
        },
        showContextMenu(chapter, event){
            let menu = document.getElementById("context-menu"),
                menuSize = (this.isLoggedIn) ? 350 : 300,
                topBarHeight =  40;
            menu.style.left = event.pageX - menuSize + "px";
            menu.style.top = event.pageY - topBarHeight + "px";
            menu.classList.add('active');
            EventBus.fire('clicked-item-chapter', chapter);
        },
        isActiveSection(chapter) {
            // Code to add/remove canonical Tag
            this.addCanonicalTag();
            // If no hash in URL, not notes section, have a book without a SKU.
            return chapter.content_id == this.activeChapter && !window.location.hash ||
                // If no hash in URL, not notes section, have a book without SKU.
                chapter.chapter_slug && this.activeChapter == chapter.chapter_slug && !window.location.hash ||
                // If hash is present in the URL and not notes section.  Ex: #IBC2015_Ch02_Sec201
                window.location.hash && chapter.link && chapter.link.id == this.$store.getters.getActiveHash ||
                //if Active hash and chapter content id are the same
                this.activeHashTag == chapter.content_id ||
                // If text-id hash is present in the URL and not notes section.  Ex: #text-id-9665977
                window.location.hash && chapter.content_id == _.replace(this.$store.getters.getActiveHash, new RegExp('text-id-','g'), '');
        },
        isActivePDFChapter(chapter) {
            return this.isXML ? false : chapter.id === this.activeChapter;
        },
        findContentById(contentArray, contentId) {

            // sometimes will be null
            if (!Array.isArray(contentArray)) {
                return null;
            }

            let result = null;

            for (const value of contentArray) {

                // must be some invalid entry...
                if (!_.has(value, 'content_id')) {
                    continue;
                }

                if (value.content_id === contentId) {
                    result = value;
                    break;
                }

                if (value.sub_sections) {
                    result = this.findContentById(value.sub_sections, contentId);

                    if (result) {
                        break;
                    }
                }
            }

            return result;
        },
        getActiveChapterObject() {

            // always returns ROOT chapter object no matter how deep the currently "active" section was found
            return _.find(this.chapterArray, (chapter) => {
                if (chapter.content_id === this.activeChapter) {
                    return true;
                }
                return this.findContentById(chapter.sub_sections, this.activeChapter);
            });
        },
        getActiveContentObject() {
            // can return reference to Chapter, Section, etc...
            return this.findContentById(this.chapterArray, this.activeChapter);
        }
    },
}
