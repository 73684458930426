var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "font-weight-bold mt-5" }, [
      _vm._v("\n        Search Tips:\n    "),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("p", { staticClass: "font-weight-bold mt-5" }, [
      _vm._v("Still can’t find what you are looking for?"),
    ]),
    _vm._v(" "),
    _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.initLiveChat()
              },
            },
          },
          [_vm._v("Chat with us")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.showFeedbackModal()
              },
            },
          },
          [_vm._v("Submit Feedback")]
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("Check the spelling of your search term.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Widen your search by using fewer or more general terms."),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Reset some or all of your search filters")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "mailto:customersuccess@iccsafe.org" } }, [
        _vm._v("Email us"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v("\n            Call "),
      _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
        _vm._v("888-ICC-SAFE"),
      ]),
      _vm._v(" ("),
      _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
        _vm._v("888-422-7233"),
      ]),
      _vm._v(").\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }