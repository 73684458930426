<template>
    <v-card
        v-if="isPartOfComplete"
        flat
        class="accent2-top-border">
        <v-card-text class="py-0">
            <v-row class="text-center">
                <v-col>
                    <h3>
                        This title is included in our Premium Complete collection
                    </h3>
                    <p class="mt-4 mb-0">
                        Access a growing library of nearly {{ premiumCompleteTitlesCount() }}+ titles
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        dark
                        color="accent2"
                        @click="goToRoute('title_landing', {documentSlug: 'premium-complete'})">
                        Learn More
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            sku: {
                required: false,
                type: String
            },
            partOfComplete: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            isPartOfComplete() {
                return this.partOfComplete && !this.isPremiumSubscribed(this.sku);
            }
        }
    }
</script>
