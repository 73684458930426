<template>
    <div>
        <template v-if="searchResults.length > 0">
            <v-row>
                <v-col
                    cols="12"
                    class="py-2"
                    v-for="(result, key) in searchResults"
                    :key="key">
                    <content-results-item
                        :result="result"
                        :meta=result._source
                        class="item-result"
                        :scrollKey="key"
                        :search="search">
                    </content-results-item>
                </v-col>
            </v-row>
        </template>
        <v-card
            outlined
            v-else>
            <v-card-text>
                <p class="mb-0" v-if="!hasPremiumSubscription">
                    You currently do not have any Premium titles in your library.
                    To take advantage of this feature, please purchase a Digital Codes Premium
                    subscription through the
                    <a href="https://shop.iccsafe.org/" target="_blank">ICC shop</a>
                </p>
                <no-results
                    v-else
                    :all-search-params="allSearchParams()"
                    :did-you-mean="didYouMean"
                    :search-term="search.exact">
                </no-results>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import LoadingLinear from "../../loaders/LoadingLinear.vue";
    import ContentResultsItem from "./result/item.vue";
    import NoResults from "./NoResults.vue";

    export default {
        name: 'ContentSearchResults',
        components: {NoResults, ContentResultsItem, LoadingLinear},
        props: {
            searchResults: {
                required: false,
                type: Array,
                default: []
            },
            loading: {
                required: false,
                type: Boolean,
                default: true
            },
            didYouMean: {
                required: false,
                type: Object|Array,
                default: {}
            },
            search: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        exact: '',
                        any: '',
                        all: '',
                        none: '',
                        proximity: '',
                        proximityWithin: ''
                    }
                }
            }
        },
        methods: {
            allSearchParams() {
                let search = '';

                this.search_param = this.search.exact ? this.search.exact : '';
                if (this.search_param !== '') {
                    search = this.search_param;
                }
                if (this.search.all !== '' && this.search.all !== this.search_param) {
                    search = search + ' ' + this.search.all;
                }

                if (this.search.any !== '' && this.search.any !== this.search_param) {
                    search = search + ' ' + this.search.any;
                }

                if (this.search.none !== '' && this.search.none !== this.search_param) {
                    search = search + ' ' + this.search.none;
                }

                if (this.search.proximity !== this.search_param) {
                    if(this.search.proximity &&
                        this.search.proximity !== '' &&
                        this.search.proximityWithin &&
                        this.search.proximityWithin !== '') {
                        search = search + ' ' + this.search.proximity + ' near ' + this.search.proximityWithin;
                    } else if(this.search.proximity &&
                        this.search.proximity !== '') {
                        search = search + ' ' + this.search.proximity;
                    } else if(this.search.proximityWithin &&
                        this.search.proximityWithin !== '') {
                        search = search + ' ' + this.search.proximityWithin;
                    }
                }

                return search;
            },
        }
    }
</script>
