export default {
    methods: {
        linkAction(book) {
            if (book.excludeBook == 1) {
                return this.isPremiumOnlyBook(book.content_access_level) ? this.openFreeCompleteModal = true : this.redirectToBookToc(book);
            } else {
                if (!_.isUndefined(book.content_type)) {
                    if ('Collection' === book.content_type.name || 'Premium Complete' === book.content_type.name) {
                        window.location = Routing.generate("collections_landing", {documentSlug: book.document_slug});
                    }
                }
                return this.redirectToBookToc(book);
            }
        },
        getAssociatedBooks(book) {
            EventBus.fire("get-associated-books", book);
        },
        redirectToBookToc(book) {
            // XML type books
            if (1 === book.content_type.id || 4) {
                if (book.document_slug) {
                    window.location.href = Routing.generate("content_document_slug", {
                        documentSlug: book.document_slug
                    });
                } else {
                    window.location.href = Routing.generate("content_document", {
                        documentId: book.document_id
                    });
                }
            } else {
                // PDF type books
                window.location.href = Routing.generate("content_book_details_toc", {
                    documentId: book.document_id
                });
            }
        }
    }
}
