<template>
    <div>
        <!--Create or Update Bookmark-->
        <v-dialog
            persistent
            v-model="modal"
            max-width="700">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Bookmark / Note
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="closeModal">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <vue-editor v-model="editorData" :editor-toolbar="customToolbar" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <p class="mb-0">Select Existing Project Tag</p>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <template v-if="!loadingTags">
                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="selectedTag"
                                    :items="tags"
                                    item-text="tag"
                                    item-value="id"
                                    chips
                                    label="Select Tag from the list"
                                    solo>
                                </v-select>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    class="primary"
                                    @click="createNewTagModal(isUpdate, selectedTag)">
                                    <span v-if="isUpdate">Edit Tag</span>
                                    <span v-else>New Tag</span>
                                </v-btn>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col>
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeModal">Cancel</v-btn>
                    <v-btn color="primary" :loading="isSaving" @click="saveBookmark">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Remove Highlight annotation or bookmark Modal-->
        <v-dialog v-model="removeModal" max-width="400">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Remove Bookmark
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="removeModal = false">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="text-center">
                            <p class="mb-0 mt-5">Are you sure you want to remove this bookmark?</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="removeModal = false">Cancel</v-btn>
                    <v-btn color="error" :loading="isDeleting" @click="deleteBookmark">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Validate URLs in the user note -->
        <v-dialog v-model="invalidNoteUrl" max-width="400">
            <v-card>
                <v-card-title class="headline">Invalid URL</v-card-title>
                <v-card-text>
                    Your note contains an Invalid URL?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="invalidNoteUrl = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import TagsMixins from './mixins/tags';
    import urlHelper from './mixins/urlHelper';
    import Mobile from '../../inc/mixins/mobile';
    import EditorConfigs from '../../inc/editorConfigBar';

    export default {
        data() {
            return {
                modal: false,
                removeModal: false,
                bookmarkId: 0,
                bookmark: {},
                editorData: '',
                isUpdate: false,
                invalidNoteUrl: false,
                sectionId: '',
                isSaving: false,
                isDeleting: false
            }
        },
        mixins: [TagsMixins, urlHelper, Mobile, EditorConfigs],

        methods: {
            saveBookmark() {
                // Validate URLs in the user note
                if(!this.validateLinksInNote(this.editorData)) {
                    this.invalidNoteUrl = true;
                    return false;
                }
                let bookmarkObj = {
                    tag: this.selectedTag,
                    note: this.editorData,
                    data: this.bookmark
                };
                this.isSaving = true;
                this.$http.post(Routing.generate('save_bookmark'), bookmarkObj)
                    .then(response => {
                        let options;
                        if (this.isUpdate) {
                            options = {
                                action: 'update',
                                sectionId: response.data[0].section_id,
                                type: 'bookmark',
                                id: response.data[0].id,
                                notes: {
                                    bookmarks: response.data
                                }
                            };
                            response.data[0].isUpdate = true;
                            EventBus.fire('update-note-preview-'+response.data[0].id, response.data[0]);
                        } else {
                            options = {
                                action: 'create',
                                sectionId: response.data[0].section_id,
                                notes: {
                                    bookmarks: response.data
                                }
                            };
                        }
                        EventBus.fire('update-notes', options);
                        this.closeModal();
                    })
                    .finally(()=>{
                        this.isSaving = false;
                    })
            },

            updateBookmark(bookmark) {
                this.isUpdate = true;
                this.$http.get(Routing.generate('get_bookmark', {id: bookmark.id}))
                    .then(response => {
                        let data = response.data;
                        this.bookmark = response.data;
                        this.getUserTags(data.tag);
                        this.editorData = data.text;
                    });
            },
            closeModal(){
                this.bookmark = {};
                this.editorData = '';
                this.tags = [];
                this.selectedTag = [];
                this.isUpdate = false;
                this.loadingTags = true;
                this.modal = false;
            },

            deleteBookmark() {
                this.isDeleting = true;
                this.$http.get(Routing.generate('remove_bookmark', {id: this.bookmarkId}))
                    .then(response => {
                        let options = {
                            action: 'remove',
                            type: 'bookmark',
                            id: this.bookmarkId,
                            sectionId: this.sectionId
                        };
                        EventBus.fire('update-notes', options);
                        this.removeModal = false;
                    })
                    .finally(()=>{
                        this.isDeleting = false;
                    })
            }
        },

        created() {
            EventBus.listen('bookmark-modal', (bookmark) => {
                if (bookmark.id) {
                    this.updateBookmark(bookmark);
                } else {
                    this.bookmark = bookmark;
                    this.getUserTags(1);
                }
                this.modal = true;
            });

            EventBus.listen('remove-bookmark', (data) => {
                this.bookmarkId = data.id;
                this.sectionId = data.sectionId;
                this.removeModal = true;
            })
        }

    }
</script>
<style lang="scss">
    .ql-editor {
        height: 200px !important;
    }
</style>
