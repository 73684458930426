<template>
    <v-dialog
        v-model="dialog"
        width="650">
        <v-card>
            <v-card-title
                v-if="!trialActive
                    && !isPremiumComplete"
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Upgrade to Premium!
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="accent2--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-title
                v-else
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="accent2--text"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-container class="lighten-3 px-3">
                <v-row>
                    <v-col align="center" class='pt-0 pb-3'>
                        <div>
                            <img :src="asset(require('../../images/icc_digital_codes_premium.png'))"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class='pt-0 pb-2'>
                        <p align="center">
                            <template v-if="isPremiumComplete || trialActive">
                                For content printing within Digital Codes Premium, please utilize the section level printing controls available within the Premium toolbar for each section.
                            </template>
                            <template v-else>
                                Printing is a feature of Digital Codes Premium.  To access printing capabilities, please subscribe to a Digital Codes Premium subscription.
                            </template>
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="center" class='pt-0 pb-2'>
                        <div v-if="!trialActive
                            && !isPremiumComplete">
                            <v-btn
                                dark
                                color="accent2"
                                class="white--text"
                                :href="getRoute('title_landing', {documentSlug: documentInfo.document_slug})"
                                data-aq="start-premium-trial-button">
                                Upgrade to Premium
                            </v-btn>
                            <v-btn
                                dark
                                color="accent2"
                                @click="goToRoute('trial_signup')"
                                data-aq="start-premium-trial-button">
                                Start Premium Trial
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn
                                dark
                                color="accent2"
                                @click="quickStartUrl()">
                                Quick Start
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
    import Mobile from '../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                dialog: false,
                isPremiumComplete: false,
                documentInfo: []
            };
        },
        mixins: [Mobile],
        methods: {
            showDialog(data = null) {
                if (data) {
                    this.isPremiumComplete = data.isPremiumComplete;
                    this.documentInfo = data.documentInfo;
                }
                this.dialog = true;
            },
            quickStartUrl() {
                window.open('https://support.iccsafe.org/?ht_kb_category=quick-start', '_blank');
            },
        },
        created() {
            EventBus.listen("show-printing-restricted-modal", data => this.showDialog(data));
        }
    };
</script>

<style>
    .image-div {
        position: relative;
    }
</style>
