<template>
    <v-menu
        ref="menu"
        v-model="menuOpen"
        offset-y
        max-width="100%"
        attach="#filterMenu"
        absolute
        :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                outlined
                depressed
                v-bind="attrs"
                v-on="on">
                Filter
            </v-btn>
        </template>
        <v-card>
            <v-card-text class="pb-0">
                <v-row align="center">
                    <v-col>
                        <p class="mb-0">
                            Filter your Premium content for more precise results.
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto">
                        <v-btn
                            :block="isMobilePort"
                            :class="{'mb-2': isMobilePort}"
                            small
                            color="primary"
                            @click="clearAll()">
                            Clear All
                        </v-btn>
                        <v-btn
                            :block="isMobilePort"
                            small
                            color="primary"
                            @click="closeMenu()">
                            Close
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        lg="6">
                        <v-text-field
                            @keyup.enter="closeMenu"
                            v-model="searchFilter"
                            dense
                            outlined
                            clearable
                            label="Search Year, State, Title, Topic, Term"
                            hide-details
                            prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <template v-for="(items, key) in filterData">
                        <v-col
                            :key="key"
                            class="py-0"
                            sm="3"
                            >
                            <v-menu
                                v-model="menus[key]"
                                :key="key"
                                offset-y
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        outlined
                                        class="mb-3"
                                        block
                                        height="40"
                                        v-bind="attrs"
                                        v-on="on">
                                        {{upperFirst(key)}}
                                        <v-icon right>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <filter-dropdown
                                    :label="upperFirst(key)"
                                    :filter-key="key"
                                    :filters="items"
                                    @updateActive="activateFilter"
                                    @closeMenu="menus[key] = false">
                                </filter-dropdown>
                            </v-menu>
                        </v-col>
                    </template>
                </v-row>
                <v-row v-if="mergedFilters">
                    <v-col>
                        <template v-for="(filters, key) in mergedFilters">
                            <v-chip
                                v-for="(item, keyb) in filters"
                                :key="keyb"
                                close
                                class="mr-2 mb-2"
                                @click:close="activateFilter(item, key, true)">
                                {{item.name}}
                            </v-chip>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    import SectionCollapsable from "../../SectionCollapsable.vue";
    import FilterDropdown from "./FilterDropdown.vue";
    import {ArrayUtils} from "../../../util/ArrayUtils";
    import Mobile from '../../inc/mixins/mobile';

    export default {
        name: 'titles-filters',
        data() {
            return {
                menus: {},
                initialized: false,
                categories: this.$store.getters.getSearchNavigationCategories,
                filterData: {},
                searchFilter: null,
                searchWithinFilters: {locations: ''},
                activeCategories: [],
                activeCollections: [],
                menuOpen: false
            }
        },
        mixins: [Mobile],
        computed: {
            mergedFilters() {
                return {
                    categories: this.activeCategories,
                    collections: this.activeCollections
                }
            }
        },
        components: {
            SectionCollapsable,
            FilterDropdown
        },
        methods: {
            upperFirst(word) {
                let category = word === 'i-codes' ? 'I-Codes' : word;
                return _.upperFirst(category);
            },
            populateFilters(key = null) {
                const data = this.$store.getters.getSearchNavigationCategories;
                if (key) {
                    this.$set(this.filterData, key, data[key])
                } else {
                    this.$set(this.filterData, 'Locations', data.Location)

                    let categoryKeyInclude = [
                        'i-codes',
                        'standards',
                        'Locations',
                        'commentaries',
                        'resources',
                        'topics',
                        'publisher',
                        'collections'
                    ]
                    data.Category.forEach((category) => {
                        if (categoryKeyInclude.includes(category.key)) {
                            this.searchWithinFilters[category.key] = '';
                            if (category.key === 'collections') {
                                this.$http.get(Routing.generate('get_collections_with_counts'))
                                    .then(response => {
                                        let collections = response.data;

                                        // available globally now
                                        this.$store.commit('setCollections', collections);

                                        const completeObj = {
                                            documentId: this.$getConst('premiumCompleteSku'),
                                            title:  this.$getConst('contentTypeComplete'),
                                        }
                                        collections.unshift(completeObj);
                                        let keys = { documentId: 'id', title: 'name' };
                                        this.$set(
                                            this.filterData,
                                            category.key,
                                            response.data.map(function(o) {
                                                    return _.mapKeys(o, function(v, k) {
                                                        return k in keys ? keys[k] : k;
                                                    });
                                                })
                                            );
                                    });
                            } else {
                                this.$set(this.filterData, category.key, category.children);
                            }
                        }
                        const filterDataObjects = this.filterData;
                        this.filterData = categoryKeyInclude
                        .reduce(function(newObj, key) {
                            newObj[key] = filterDataObjects[key];
                            return newObj;
                        }, {});

                    });
                }
            },
            activateFilter(category, key = null, remove = false, clearAll = false) {
                let filterKey = 'activeCategories';
                if (key === 'collections') {
                    filterKey = 'activeCollections'
                }

                if (remove) {
                    this[filterKey] = this[filterKey].filter(function (value) {
                        return value.id !== category.id;
                    });
                } else if (clearAll) {
                    this.activeCategories = [];
                    this.activeCollections = [];
                    this.searchFilter = null;
                    this.menuOpen = false;
                } else {
                    if (_.find(this[filterKey], {id: category.id})) {
                        return;
                    }
                    this[filterKey].push(category);
                }

                this.updateFilters();
            },
            updateFilters() {
                let data = {
                    categories: this.activeCategories,
                    collections: this.activeCollections,
                    search: this.searchFilter
                }
                this.$emit('updateFilters', data);
            },
            closeMenu() {
                this.menuOpen = false;
                this.$refs.menu.save();
            },
            clearAll() {
                EventBus.fire('clear_all_filters');
            }
        },
        created() {
            EventBus.listen('clear_all_filters', () => this.activateFilter(false, false, false, true));
        },
        mounted() {
            this.populateFilters()
            this.initialized = true;

            const debouncedUpdateFilters = _.debounce(this.updateFilters, 420);

            this.$watch(() => this.searchFilter, (newValue) => {
                debouncedUpdateFilters();
            });
        }
    }
</script>
