var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("new-tag-modal"),
      _vm._v(" "),
      _c("bookmark-modal"),
      _vm._v(" "),
      _c("notes-modal"),
      _vm._v(" "),
      _c("share-note", { key: _vm.shareModalKey }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }