<template>
    <div>
        <v-btn
            text
            small
            @click="showLegendModal">
            <v-icon left>
                mdi-information-outline
            </v-icon>
            Legend
        </v-btn>
        <v-dialog
            v-model="dialog"
            :fullscreen="isMobilePort"
            scrollable
            :max-width="1200">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Legend Information
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="dialog = false">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <template v-if="!loading">
                            <v-col>
                                <span v-html="legendContent"></span>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col class="text-center py-12">
                                <v-progress-circular
                                    indeterminate
                                    color="primary">
                                </v-progress-circular>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {TitlesApi} from '../../../api/Titles'
    import TagLabel from "../../tags/partials/tag_label.vue";
    import Mobile from '../../inc/mixins/mobile'

    export default {
        name: 'LegendButton',
        components: {TagLabel},
        mixins: [Mobile],
        data() {
            return {
                dialog: false,
                legendContent: '',
                loading: false
            }
        },
        props: {
            documentId: {
                required: true,
                type: Number
            }
        },
        methods: {
            async showLegendModal() {
                this.dialog = true
                if (!this.legendContent) {
                    this.loading = true
                    try {
                        const response = await TitlesApi.getLegendContent(this.documentId)
                        this.legendContent = response.data
                    } catch (error) {
                    } finally {
                        this.loading = false
                    }
                }
            }
        }
    }
</script>
