var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ft-container mb-n3",
      class: { "overflow-hidden": !_vm.isMobilePort },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "gradient mt-11",
          class: { "py-0 mx-0": !_vm.isMobilePort },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 mx-0",
              class: {
                "ft-img-web": !_vm.isMobilePort,
                "ft-img-mob": _vm.isMobilePort,
                "mb-9":
                  _vm.isMobilePort && (3 === _vm.persona || 4 === _vm.persona),
              },
              attrs: {
                cols: `{ '7': !isMobilePort, auto: isMobilePort }`,
                order: !_vm.isMobilePort
                  ? 3 === _vm.persona || 4 === _vm.persona
                    ? 2
                    : 1
                  : 2,
              },
            },
            [
              3 === _vm.persona || 4 === _vm.persona
                ? _c("v-img", {
                    staticClass: "float-right",
                    class: { img: !_vm.isMobilePort },
                    attrs: {
                      "max-width": "690px",
                      src: !_vm.isMobilePort
                        ? _vm.asset(
                            require("../../../images/" +
                              _vm.displayData.imageWeb.src),
                            "default"
                          )
                        : _vm.asset(
                            require("../../../images/" +
                              _vm.displayData.imageMobile.src),
                            "default"
                          ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              2 === _vm.persona || 1 === _vm.persona
                ? _c("v-img", {
                    staticClass: "float-right",
                    class: { img: !_vm.isMobilePort },
                    attrs: {
                      "max-width": "690px",
                      src: !_vm.isMobilePort
                        ? _vm.asset(
                            require("../../../images/exams/" +
                              _vm.displayData.imageWeb.src),
                            "default"
                          )
                        : _vm.asset(
                            require("../../../images/exams/" +
                              _vm.displayData.imageMobile.src),
                            "default"
                          ),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              attrs: {
                cols: `{ '5': !isMobilePort, auto: isMobilePort }`,
                order: !_vm.isMobilePort
                  ? 3 === _vm.persona || 4 === _vm.persona
                    ? 1
                    : 2
                  : 1,
              },
            },
            [
              _vm.persona === 3
                ? _c("h2", {
                    staticClass: "pt-9 px-0",
                    class: {
                      "text-center": _vm.isMobilePort,
                      headline: !_vm.isMobilePort,
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.displayData.browseCodesTitle),
                    },
                  })
                : _c("h2", {
                    staticClass: "pt-9 px-6",
                    class: {
                      "mr-16":
                        !_vm.isMobilePort &&
                        (1 === _vm.persona || 2 === _vm.persona),
                      "text-center px-0": _vm.isMobilePort,
                      headline: !_vm.isMobilePort,
                      "pl-6": _vm.freeTrialAvailable,
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.freeTrialAvailable
                          ? _vm.displayData.title
                          : _vm.displayData.browseCodesTitle
                      ),
                    },
                  }),
              _vm._v(" "),
              _c("h5", {
                staticClass: "pl-6 pt-2",
                class: {
                  "text-center": _vm.isMobilePort,
                  title: _vm.isMobilePort,
                  "subtitle-1": !_vm.isMobilePort,
                },
                domProps: { innerHTML: _vm._s(_vm.displayData.subtitle) },
              }),
              _vm._v(" "),
              _c(
                "ul",
                {
                  class: {
                    "mx-3":
                      !_vm.isMobilePort &&
                      (1 === _vm.persona || 2 === _vm.persona),
                    "title px-9 list": _vm.isMobilePort,
                    "body-2 px-16": !_vm.isMobilePort,
                  },
                },
                _vm._l(_vm.displayData.features, function (feature) {
                  return _c("li", { key: feature.id }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(feature.title) +
                        "\n                "
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt-9 ml-6",
                  class: { "text-center mb-12": _vm.isMobilePort },
                },
                [
                  _vm.freeTrialAvailable
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            rounded: "",
                            color: "accent2",
                            depressed: "",
                            "x-large": "",
                            dark: "",
                            target: _vm.displayData.button.target,
                            href: _vm.displayData.button.link,
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.displayData.button.text) +
                              "\n                "
                          ),
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            rounded: "",
                            color: "accent2",
                            depressed: "",
                            "x-large": "",
                            dark: "",
                            target: _vm.displayData.buttonBrowseCodes.target,
                            href: _vm.displayData.buttonBrowseCodes.link,
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.displayData.buttonBrowseCodes.text) +
                              "\n                "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", [
        4 === _vm.persona
          ? _c("p", { staticClass: "caption pl-10" }, [
              _vm._v(
                "*Digital Codes Premium is available for exam preparation, but the Premium features are deactivated during the exam itself."
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }