<template>
    <v-chip
        small
        v-if="booksInfo.length === 1"
        color="primaryLight"
        class="primary--text"
        @click="answerViewPage
            ? goToRoute('premium_answers', {document: booksInfo[0].id})
            : $emit('titleClick', booksInfo[0].id)">
        {{booksInfo[0].title}}
    </v-chip>
    <v-menu
        absolute
        v-else>
        <template v-slot:activator="{ on, attrs }">
            <v-chip
                small
                color="primaryLight"
                class="primary--text"
                v-bind="attrs"
                v-on="on">
                {{booksInfo[0].title}} {{booksInfo.length > 1 && `+ ${booksInfo.length - 1} more`}}
            </v-chip>
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in booksInfo"
                :key="index">
                <v-list-item-title>
                    <v-chip
                        small
                        color="primaryLight"
                        class="primary--text"
                        @click="answerViewPage
                            ? goToRoute('premium_answers', {document: item.id})
                            : $emit('titleClick', item.id)">
                        {{item.title}}
                    </v-chip>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        props: {
            booksInfo: {
                required: false,
                type: Array
            },
            answerViewPage: {
                required: false,
                type: Boolean,
                default: false
            },
        }
    }
</script>
