var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.checkoutError,
            callback: function ($$v) {
              _vm.checkoutError = $$v
            },
            expression: "checkoutError",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Proceed To Checkout"),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n                Error! Sorry! We are unable to process your request. Please try again later.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "flat" },
                      on: {
                        click: function ($event) {
                          _vm.checkoutError = false
                        },
                      },
                    },
                    [_vm._v("\n                    Ok\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.checkoutLoader,
            callback: function ($$v) {
              _vm.checkoutLoader = $$v
            },
            expression: "checkoutLoader",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Proceed To Checkout"),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n                Please wait, you are being forwarded to the checkout page.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center py-6" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.loginModal,
            callback: function ($$v) {
              _vm.loginModal = $$v
            },
            expression: "loginModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Sign In Required"),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v("\n                Please "),
                _c("a", { attrs: { href: _vm.getLoginUrl() } }, [
                  _c("b", [_vm._v("sign in")]),
                ]),
                _vm._v(" or\n                "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.iccsafe.org/register/",
                      target: "_blank",
                    },
                  },
                  [_c("b", [_vm._v("register")])]
                ),
                _vm._v(
                  "\n                to add items to your cart.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "flat" },
                      on: {
                        click: function ($event) {
                          _vm.loginModal = false
                        },
                      },
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }