var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-text-field", {
            class: _vm.isActiveUser && _vm.hasPremiumSubscription ? "mr-4" : "",
            attrs: {
              id: "txt_search",
              clearable: "",
              dense: "",
              outlined: "",
              "hide-details": "",
              "append-icon": "mdi-magnify",
              autocomplete: "off",
              label: _vm.getSearchLabel(),
            },
            on: {
              "click:clear": _vm.clickedClear,
              focus: _vm.focusGained,
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.focusGained.apply(null, arguments)
              },
              keydown: _vm.keydown,
              "click:append": _vm.performSearch,
            },
            model: {
              value: _vm.defaultSearch,
              callback: function ($$v) {
                _vm.defaultSearch = $$v
              },
              expression: "defaultSearch",
            },
          }),
          _vm._v(" "),
          _vm.isActiveUser && _vm.hasPremiumSubscription
            ? _c(
                "v-btn",
                {
                  staticClass: "primary white--text float-right adv-search-btn",
                  on: {
                    click: function ($event) {
                      return _vm.redirectToContentSearchPage(true)
                    },
                  },
                },
                [_vm._v("\n            Advanced Search\n        ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.clickedOutside,
              expression: "clickedOutside",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSuggestions,
              expression: "showSuggestions",
            },
          ],
          staticClass: "search-dropdown elevation-3 white rounded",
        },
        [
          _vm.defaultSearch
            ? [
                _c("ai-suggest", {
                  attrs: {
                    search: _vm.defaultSearch,
                    premiumUser: _vm.activeUserHasSubscription,
                  },
                }),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _vm.activeUserHasSubscription
                  ? [
                      _c("search-list", {
                        attrs: {
                          title: "Content",
                          search: _vm.defaultSearch,
                          results: _vm.contentResults,
                          loading: _vm.loading.contentResults,
                          icon: "mdi-magnify",
                          "content-key": "search",
                        },
                        on: {
                          itemClick: () => (this.showSuggestions = false),
                          setSearchTerm: (term) => (_vm.defaultSearch = term),
                        },
                      }),
                      _vm._v(" "),
                      _c("v-divider"),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("search-list", {
                  attrs: {
                    title: "Titles",
                    search: _vm.defaultSearch,
                    results: _vm.titleResults,
                    loading: _vm.loading.titleResults,
                    category: _vm.category,
                    "show-upsell": "",
                  },
                  on: { itemClick: () => (this.showSuggestions = false) },
                }),
              ]
            : [
                _c("ai-suggest", {
                  attrs: { premium: _vm.activeUserHasSubscription },
                }),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _vm.isActiveUser
                  ? [
                      _vm.activeUserHasSubscription
                        ? _c("search-list", {
                            attrs: {
                              title: "Recent Searches",
                              results: _vm.recentSearches,
                              loading: _vm.loading.recentSearches,
                              icon: "mdi-history",
                              "content-key": "recentSearches",
                            },
                            on: {
                              itemClick: () => (this.showSuggestions = false),
                              setSearchTerm: (term) =>
                                (_vm.defaultSearch = term),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c("search-list", {
                        attrs: {
                          title: "Recently Viewed Titles",
                          results: _vm.recentlyVisited,
                          loading: _vm.loading.recentlyVisited,
                          "show-upsell": "",
                        },
                        on: { itemClick: () => (this.showSuggestions = false) },
                      }),
                    ]
                  : _c("search-list", {
                      attrs: {
                        title: "Popular Titles",
                        results: _vm.popularTitles,
                        loading: _vm.loading.popularTitles,
                        "show-upsell": "",
                      },
                      on: { itemClick: () => (this.showSuggestions = false) },
                    }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }