<template>
    <iframe
        title=""
        ref="iFrameContent"
        v-on:load="tag.tag.iframe_height ? null : updateFrameHeight($refs.iFrameContent)"
        :src="iFrameUrl"
        width="100%"
        :height="tag.tag.iframe_height ? tag.tag.iframe_height : 500"
        frameborder="0">
    </iframe>
</template>

<script>

export default {
    props: {
        tag: {
            type: Object,
            required: true
        }
    },
    computed: {
        iFrameUrl() {
            const iFrameUrl = this.tag.tag.iFrameUrl;
            if(iFrameUrl){
                return iFrameUrl;
            }

            return null;
        }
    }
    
}
</script>
