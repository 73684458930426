export default {
    data: () => ({
        page: '',
        route: '',
        topBanner: true,
        category_list: [],
        book_list: [],
        tag_list: [],
        category: '',
        book: '',
        tag: '',
        report_title: '',
        document_sku: [],
        tag_ids: [],
        chapterNotes: [],
        load: false,
        default_filter_tag: false,
        report_load: false,
        show_process_received_modal: false,
        filteredResults: [],
        panel: [],
        selectedBookSections: [],
        shared_by_me_status: [],
        selectAllNotes: [],
        selectedBookValues: {
            all: false,
            books: [],
            chapters: [],
            sections: []
        },
        barFixed: false,
        itemsPerPageOptions: [10, 15, 20],
        pagination: {
            rowsPerPage: 10
        }
    }),

    watch: {
        panel: {
            handler() {
                let isExapanded = _.find(this.panel, function(item){
                    return item == true;
                });
                if(this.panel.length && isExapanded == undefined){
                    this.panel = [];
                }
            },
        },
    },

    methods: {
        setRoute(){
            switch(this.page){
                case 'SharedByMe':
                    this.route = 'get_shared_by_me';
                    break;
                case 'SharedWithMe':
                    this.route = 'get_shared_with_me';
                    break;
                case 'SharedHistory':
                    this.route = 'get_shared_with_me_history';
            }
            this.getNotes();
        },
        getNotes(){
            this.$http.get(Routing.generate(this.route))
                .then(response => {
                    this.filterArray(response.data);
                    this.document_sku = response.data.documentSku;
                    this.tag_ids = response.data.tags;
                    this.chapterNotes = response.data.allData;
                    this.getCategories();
                    this.getCodes();
                    this.getTags();
                    this.load = true;
                });
        },
        checkPanelEvent(panelKey){
            if(!this.panel.length){
                this.panel = [...Array(this.filteredResults.length).keys()].map(_ => false);
            }
            let array = [];
            this.panel.forEach(function(item, key){
                array[key] = (key === panelKey.index) ? !item : item;
            });

            this.panel = array;
        },
        countNotesInChapter(item){
            let result = 0;
            _.forEach(item, function (chapter, chapterId) {
                result += chapter.chapterNotesCount;
            });
            return result;
        },

        filterResult: function (e, type) {
            var params = {};

            switch (type) {
                case 'category':
                    params = {category: e, tag: this.tag, book: this.book};
                    break;
                case 'tag':
                    params = {category: this.category, tag: e, book: this.book};
                    break;
                case 'book':
                    params = {category: this.category, tag: this.tag, book: e};
                    break;
                default:
                    break;
            }
            this.$http.get(
                Routing.generate(this.route, params))
                .then(response => {
                    this.filterArray(response.data);
                    this.chapterNotes = response.data.allData;
                })
        },
        selectAllChapters(){
            if(!this.selectedBookValues.all){
                this.cleanSelectedValues();
            }else {
                let data = this.filteredResults,
                    books = [],
                    chapters = [];

                data.forEach(function(item){
                    books.push(item.info.id);
                    Object.keys(item.chapter).forEach(function(chapterId){
                        chapters.push(chapterId);
                    });
                });

                this.selectedBookValues = {
                    all: true,
                    books: books,
                    chapters: chapters,
                    sections: []
                };

                for(let i = 0; i < chapters.length; i++){
                    if(!_.isArray(chapters[i])){
                        this.parentChecked(chapters[i]);
                    }
                }
            }
        },
        selectBookSections(item){
            let chapters = [];
            if(!_.includes(this.selectedBookValues.books, item.info.id)){
                let removeChapters = [];

                Object.keys(item.chapter).forEach(function(chapterId){
                    removeChapters.push(chapterId);
                });

                for(let i = 0; i < removeChapters.length; i++){
                    let chapter = this.selectedBookValues.chapters.indexOf(removeChapters[i]);
                    this.selectedBookValues.chapters.splice(chapter, 1);
                    this.parentChecked(removeChapters[i]);
                }
            }else{
                Object.keys(item.chapter).forEach(function(chapterId){
                    chapters.push(chapterId);
                });

                if(!_.includes(this.selectedBookValues.books, item.info.id)) {
                    this.selectedBookValues.books.push(item.info.id);
                }
                this.selectedBookValues.chapters = chapters;
                for(let i = 0; i < chapters.length; i++){
                    if(!_.isArray(chapters[i])){
                        this.parentChecked(chapters[i]);
                    }
                }
            }
        },
        filterArray(data){
            let items = _.map(data, function(item) {
                if (item.title !== undefined) {
                    return item;
                }
            });

            this.filteredResults = _.orderBy(_.without(items, undefined),
                ['info.year.year', 'title'], ['desc', 'asc']
            );
        },
        resetFilter: function () {
            this.category = '';
            this.tag = '';
            this.book = '';
            this.getNotes();
            this.panel = [...Array(this.filteredResults.length).keys()].map(_ => false);
            this.report_title = '';
        },
        getCategories: function () {
            if (this.document_sku != undefined) {
                this.$http.post(
                    Routing.generate('get_categories_for_annotation_bookmarks'),
                    {skus: this.document_sku},
                    {emulateJSON: true}
                )
                    .then(response => {
                        this.category_list = response.data;
                    })
            }
        },
        getCodes: function () {
            if (this.document_sku != undefined) {
                this.$http.post(Routing.generate('get_codes'),
                    {skus: this.document_sku},
                    {emulateJSON: true}
                )
                    .then(response => {
                        this.book_list = response.data;
                    })
            }
        },
        getTags: function () {
            if (this.tag_ids != undefined) {
                this.$http.get(Routing.generate('get_tags', {tagIds: this.tag_ids}))
                    .then(response => {
                        this.tag_list = response.data;
                    })
            }
        },

        parentChecked: function (chapterId) {
            let unChecked = this.selectedBookValues.chapters.includes(chapterId);
            let parentArray = this.chapterNotes[chapterId];
            let i;
            for (i = 0; i < parentArray.length; i++) {
                if (unChecked == false) {
                    let index = this.selectedBookValues.sections.indexOf(parentArray[i]);
                    this.selectedBookValues.sections.splice(index, 1);
                } else {
                    if (!this.selectedBookValues.sections.includes(parentArray[i])) {
                        this.selectedBookValues.sections.push(parentArray[i]);
                    }
                }
            }

        },
        childChecked: function (chapterId, selectedContent) {
            let checked = this.selectedBookValues.sections.includes(selectedContent);
            let allChecked = true;
            let i;
            let parentArray = this.chapterNotes[chapterId];
            if (checked == false) {
                let index = this.selectedBookValues.chapters.indexOf(chapterId);
                if (index !== -1) {
                    this.selectedBookValues.chapters.splice(index, 1);
                }
            } else {
                for (i = 0; i < parentArray.length; i++) {
                    if (this.selectedBookValues.sections.includes(parentArray[i]) == false) {
                        allChecked = false;
                    }
                }
                if (allChecked == true) {
                    this.selectedBookValues.chapters.push(chapterId);
                }
            }
        },

        cleanSelectedValues(){
            this.selectedBookValues = {
                all: false,
                books: [],
                chapters: [],
                sections: []
            };
        },
        printEdition(data) {
            if (!_.isUndefined(data)) {
                return data.version;
            }
        },
        showAcceptSharedWithMeModal() {
            this.show_process_received_modal = true;
            EventBus.fire('show-modal-accept-received-annotation-bookmarks', this.selectedBookValues.sections);
        },
        showRejectSharedWithMeModal() {
            this.show_process_received_modal = true;
            EventBus.fire('show-modal-reject-received-annotation-bookmarks', this.selectedBookValues.sections);
        },
        sharedStatus: function (contentType, contentId) {
            return this.shared_by_me_status[contentType] ? this.shared_by_me_status[contentType][contentId] : '';
        },

        expandCollapsePanels(){
            if(this.panel.length > 0) {
                this.panel = [];
            }else{
                this.panel = [...Array(this.filteredResults.length).keys()].map(_ => true);
            }
        },

        redirectToSection(documentId, chapterId, sectionId) {
            this.$http.post(Routing.generate('get_url_slug', {
                'documentId': documentId,
                'chapterId': chapterId
            })).then(response => {
                if (!response.data.success) {
                    window.open(response.data.slugURL + '#text-id-' + sectionId, '_blank');
                }
            });
        },

        bookLink(book){
            // No click action for premium complete book
            if (!_.isUndefined(book.content_type) && 'Premium Complete' === book.content_type.name) {
                return false;
            }

            if (book.document_slug) {
                window.open(Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug
                }), '_blank');
            } else {
                window.open(Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': 'toc'
                }), '_blank');
            }
        },
        getDescription(data) {
            switch(data.type) {
                case('note'):
                    return data.text;
                    break;
                case('annotation'):
                    return data.annotationHtml;
                    break;
                case('bookmark'):
                    return data.content;
                    break;
            }
        },
        getIcon(type) {
            return (type == 'note' || type == 'annotation') ? 'border_color' : 'bookmark';
        },
        getTagColor(id) {
            if(this.tag_list.length > 0){
                let tag = _.find(this.tag_list, {'id': _.toInteger(id)});
                if (tag && tag.color) {
                    return '#'+tag.color;
                }
            }
        },
        getTagName(id) {
            if(this.tag_list.length > 0){
                let tag = _.find(this.tag_list, {'id': _.toInteger(id)});
                return tag ? tag.label : '';
            }

        },
    },
    created(){
        EventBus.listen('show-shared-with-me-history-tab', ()=> {
            this.page = 'SharedHistory';
            this.getNotes();
        });
        EventBus.listen('update-shared-with-me', ()=> {
            this.page = 'SharedWithMe';
            this.getNotes();
        })
    }
}
