var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return "" !== _vm.userAccessLevel
    ? _c(
        "v-row",
        {
          attrs: { dense: "", align: "center" },
          model: {
            value: _vm.hasPremiumSubscription,
            callback: function ($$v) {
              _vm.hasPremiumSubscription = $$v
            },
            expression: "hasPremiumSubscription",
          },
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-col", { staticClass: "pa-0", attrs: { cols: "auto" } }, [
            _c("img", {
              attrs: {
                height: "12px",
                src: _vm.asset(
                  require("../../../images/icons/diamond_" +
                    _vm.userAccessLevel +
                    ".jpg"),
                  "default"
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "pa-0 text-left" }, [
            _c("p", {
              staticClass: "mb-0 caption accent2--text",
              domProps: {
                innerHTML: _vm._s(_vm.titleCase(_vm.userAccessLevel)),
              },
            }),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }