<template ref="manageUsers">
    <div>
        <user-summary
        :activeUsers="activeUsers"
        :pendingUsers="pendingUsers"
        :enterpriseTotalSeats="enterpriseTotalSeats"
        :totalUsers="totalUsers">
        </user-summary>
        <v-row>
            <v-col>
                <v-card class="white rounded-xl" outlined>
                    <v-card-title>

                        <div class="flex-grow-1 body-3 primary--text">
                            <v-icon>
                                person
                            </v-icon> Manage Users
                        </div>

                        <div class="d-flex mt-5 mt-md-0">
                        <v-text-field
                            dense
                            clearable
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search by keywords"
                            outlined
                            hide-details
                            class="d-none d-md-block mr-2 rounded-xl shrink">
                        </v-text-field>
                        <v-btn
                            height="40"
                            dense
                            outlined
                            hide-details
                            class="primary--text mr-2 rounded-xl shrink"
                            @click="showModal()">
                            <v-icon class="mr-2">
                                mdi-account-plus
                            </v-icon> Add User
                        </v-btn>
                        <v-btn
                            height="40"
                            dense
                            outlined
                            hide-details
                            class="primary--text rounded-xl shrink"
                            @click="showImportModal()">
                            <v-icon class="mr-2">
                                mdi-file-import
                            </v-icon> Import
                        </v-btn>
                        </div>

                    </v-card-title>
                    <v-card-text class="pa-0" v-if="users.length">
                        <v-data-table
                            must-sort
                            :search="search"
                            :headers="headers"
                            :items="users"
                            :loading="loading">
                            <template v-slot:item="{ item }">
                                <tr :class="{active: (users.indexOf(item) + 1) % 2 == 0}">
                                    <td class="no-border">{{ item.first_name }}</td>
                                    <td class="no-border">{{ item.last_name }}</td>
                                    <td class="no-border">{{ item.email }}</td>
                                    <td class="no-border" v-if="item.last_login">
                                        {{ item.last_login.date | moment("MMMM DD, YYYY") }}
                                    </td>
                                    <td class="no-border" v-else>
                                        Login Pending
                                    </td>
                                    <td class="no-border">
                                        <template v-if="loggedInUser
                                            && loggedInUser.email !== item.email">
                                            <v-menu
                                                v-if="item.role === 0 || item.role === 1"
                                                :close-on-content-click="false"
                                                offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        :class="{'pl-1': item.role === 1}"
                                                        :x-small="isMobileAndTablet"
                                                        text
                                                        v-on="on">
                                                        <span v-if="item.role === 0">Regular</span>
                                                        <span v-else-if="item.role === 1">
                                                            <v-icon
                                                                small
                                                                color="accent2"
                                                                class="mr-2">
                                                                mdi-crown
                                                            </v-icon>Admin
                                                        </span>
                                                        <v-icon right>
                                                            {{menu ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-text class="py-0">
                                                        <v-row
                                                            align="center"
                                                            dense>
                                                            <v-col cols="auto">
                                                                <a
                                                                    class="mt-0"
                                                                    hide-details
                                                                    @click="updateUser(item, 1, null)">
                                                                    Admin
                                                                </a>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row
                                                            align="center"
                                                            dense>
                                                            <v-col cols="auto">
                                                                <a
                                                                    class="mt-0"
                                                                    hide-details
                                                                    @click="updateUser(item, 0, null)">
                                                                    Regular
                                                                </a>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                            <template v-else>
                                                <span class="grey--text lighten-3">Pending User</span>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span class="grey--text lighten-3">
                                                <v-icon
                                                    small
                                                    color="accent2"
                                                    class="mr-2 ml-1">
                                                    mdi-crown
                                                </v-icon>Admin</span>
                                        </template>
                                    </td>
                                    <td class="no-border"
                                        :class="{
                                            'green--text': item.response_status === enterpriseAccepted,
                                            'red--text': item.response_status === enterpriseDeclined,
                                            'blue--text': item.response_status === enterprisePending
                                        }"
                                    >
                                        {{ item.response_status }}
                                    </td>
                                    <td class="text-center no-border">
                                        <Invitation :token='item.token' v-if="item.token" />
                                    </td>
                                    <td class="no-border">
                                        <template v-if="loggedInUser
                                            && loggedInUser.email !== item.email
                                            && item.response_status === enterpriseAccepted
                                            && item.last_login">
                                            <v-btn
                                                v-if="item.status"
                                                x-small
                                                @click="updateUser(item, null, 0)">
                                                Deactivate
                                            </v-btn>
                                            <v-btn
                                                v-else-if="!item.status"
                                                x-small
                                                @click="updateUser(item, null, 1)">
                                                Activate
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            <v-btn
                                                v-if="loggedInUser
                                                    && loggedInUser.email !== item.email"
                                                x-small
                                                @click="confirmRemove(item)">
                                                Remove
                                            </v-btn>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-text v-else class="text-center subtitle-1 title pa-4">
                        <template v-if="loading">Loading...</template>
                        <template v-else>No users found</template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="confirmDialog"
            persistent
            width="500">
            <v-card>
                <v-card-title>
                    Confirm Removal
                </v-card-title>
                <v-card-text class="text-center">
                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                Are you sure you want to delete this enterprise user?
                            </p>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-btn
                                color="error"
                                @click="denyRemove()">
                                No
                            </v-btn>
                            <v-btn
                                dark
                                class="green"
                                @click="removeUser()">
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <add-user>
        </add-user>
        <import-user>
        </import-user>
    </div>
</template>

<script>
    import Mobile from '../../../inc/mixins/mobile';
    import AddUser from './add_user.vue';
    import UserSummary from './user_summary.vue';
    import ImportUser from '../../../modals/enterpriseImportUser';
    import Enterprise from '../../../inc/mixins/enterprise';
    import Constants from "../../../../constants";
    import ConfirmDialog from "../../../ConfirmDialog";
    import Invitation from '../../../admin/enterprise/dialogs/invitation.vue';

    export default {
        data: () => ({
            search: '',
            enterpriseName: '',
            activeUsers: '',
            pendingUsers: '',
            enterpriseTotalSeats: '',
            totalUsers: '',
            users: [],
            confirmDialog: false,
            confirmRemoveAdminId: -1,
            headers: [
                {text: 'First Name', align: 'left', filterable: true, sortable: true, value: 'name', class: 'manage-user-tbl-header'},
                {text: 'Last Name', align: 'left', filterable: true, sortable: true, value: 'name', class: 'manage-user-tbl-header'},
                {text: 'Email', align: 'left', filterable: true, sortable: true, value: 'email', class: 'manage-user-tbl-header'},
                {text: 'Last Login', align: 'left', filterable: true, sortable: true, value: 'last_login.date', class: 'manage-user-tbl-header'},
                {text: 'Role', align: 'left', filterable: true, sortable: false, class: 'manage-user-tbl-header'},
                {text: 'Status', align: 'left', filterable: true, sortable: true, value: 'response_status', class: 'manage-user-tbl-header'},
                {text: 'Invitation Link', align: 'left', sortable: true, value: 'token', class: 'manage-user-tbl-header'},
                {text: 'Action', align: 'left', sortable: true, value: 'status', class: 'manage-user-tbl-header'}
            ],
            menu: false,
            enterpriseAccepted: Constants.enterpriseAccepted,
            enterpriseDeclined: Constants.enterpriseDeclined,
            enterprisePending: Constants.enterprisePending
        }),
        computed: {
            loggedInUser() {
                return this.$store.getters.getUser;
            }
        },
        mixins: [Mobile, Enterprise, Constants],
        components: {
            AddUser,
            ImportUser,
            UserSummary,
            ConfirmDialog,
            Invitation
        },
        methods: {
            showModal() {
                EventBus.fire('userModal', this.$store.getters.enterpriseId);
            },
            showImportModal() {
                EventBus.fire('userImport', this.$store.getters.enterpriseId);
            }
        },
        created() {
            this.getUsers(this.$store.getters.enterpriseId);
        }
    }
</script>

<style lang="scss">
    .v-data-table-header {
        .manage-user-tbl-header {
            white-space: nowrap !important;
            padding: 0 0 0 18px!important;
        }
    }
    .no-border{
        border: none !important;
    }
</style>
