<template>
    <v-btn
        v-if="!isActiveUser || canStartTrial"
        light
        :large="large"
        class="font-weight-bold"
        :color="color"
        :outlined="outlined"
        elevation="0"
        :max-height="maxHeight"
        :block="block"
        @click="handleDirectStartTrial()">
        Start Free Trial
    </v-btn>
</template>

<script>
    import Mobile from "../inc/mixins/mobile";
    export default {
        name: 'StartFreeTrial',
        mixins: [
            Mobile
        ],
        props: {
            outlined: {
                required: false,
                type: Boolean,
                default: false
            },
            block: {
                required: false,
                type: Boolean,
                default: false
            },
            color: {
                required: false,
                type: String,
                default: 'accent2'
            },
            large: {
                required: false,
                type: Boolean,
                default: true
            },
            directStartTrial: {
                required: false,
                type: Boolean,
                default: false
            },
            maxHeight: {
                required: false,
                type: Number
            }
        },
        methods: {
            handleDirectStartTrial() {
                return this.directStartTrial ? this.startTrial() : this.goToRoute('trial_signup');
            }
        }
    }
</script>
