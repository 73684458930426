<template>
    <v-card
        flat
        class="title-cover-container">
        <v-card-text>
            <a
                :title="'Click to access'"
                :href="title.link">
                <v-row justify="center">
                    <v-col cols="auto">
                        <graphic :title="title">
                        </graphic>
                    </v-col>
                </v-row>
                <v-row
                    v-if="showTitle"
                    dense
                    justify="center">
                    <v-col
                        cols="auto"
                        class="text-center">
                        {{ title.display_title }}
                    </v-col>
                </v-row>
                <v-row
                    v-if="!title.is_collection"
                    dense
                    justify="center">
                    <v-col
                        cols="auto"
                        v-if="title.subscription_required">
                        <v-img
                            width="30px"
                            :src="asset(require('../../../images/icons/premium-only.png'))">
                        </v-img>
                    </v-col>
                    <v-col
                        cols="auto"
                        v-if="!title.is_xml">
                        <v-img
                            width="30px"
                            :src="asset(require('../../../images/icons/pdf_book_cover.png'))">
                        </v-img>
                    </v-col>
                </v-row>
            </a>
        </v-card-text>
    </v-card>
</template>

<script>
    import Graphic from './Graphic'

    export default {
        props: {
            title: {
                required: true,
                type: Object
            },
            showTitle: {
                required: true,
                type: Boolean
            }
        },
        components: {
            Graphic
        }
    }
</script>

<style lang="scss" scoped>
    .title-cover-container:hover {
        opacity: .40;
    }
</style>
