<template>
    <v-select
            label="Select State"
            outlined
            hide-details
            :items="states"
            v-model="state"
    ></v-select>
</template>

<script>
import {DomUtils} from "../util/DomUtils";

export default {
    emits: ['changed'],
    data() {
        return {
            state: '',
        }
    },
    computed: {
        states() {
            const categories = DomUtils.getMetaContents('categories');
            const locations = categories.Location || [];

            return locations.map((location) => {
                return location.name;
            });
        }
    },
    mounted() {

        this.$watch(() => this.state, (newValue) => {
            this.$emit('changed', newValue);
        });
    }
}
</script>
