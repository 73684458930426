<template>
    <v-row dense>
        <v-spacer>
        </v-spacer>
        <v-col cols="auto">
            <v-btn
                class="elevation-0"
                color="primary"
                small
                @click="performSearch()">
                Apply
            </v-btn>
        </v-col>
<!--        <v-col cols="auto">-->
<!--            <v-btn-->
<!--                outlined-->
<!--                small-->
<!--                @click="$emit('clearSearch')">-->
<!--                Clear-->
<!--            </v-btn>-->
<!--        </v-col>-->
    </v-row>
</template>

<script>
import { EventNames } from '../../../../classes/EventNames';
export default {
    name: 'FilterButtons',
    props: {
        filter: {
            required: false,
            default: null
        }
    },
    methods: {
        performSearch() {
            const {key, value} = this.filter;
            EventBus.fire('update-content-search-filters', {key: key, value: value, performSearch: true})
        }
    }
}
</script>
