<template>
    <div class="section-content-body" :id="'section-content_'+sectionId">
        <div v-if="!isXMLType()">
            <div v-for="(result, index) in content" :key="index">
                <p v-html="result"></p>
            </div>
        </div>
        <div v-else v-html="parseText(content, Boolean(cleanTitle(title)))"></div>
    </div>
</template>

<script>
    import string from '../../../inc/str_replace';
    import strip from '../../../inc/strip_tags';

    export default {
        props: ['title', 'content', 'type', 'breadcrumbs', 'sectionId'],
        data: function() {
            return {
                replace: ['<b>', '</b>', '<i>', '</i>', '<table>', '<table', '</table>',
                    '<span>', '<span ', '</span>', '<div', '<div>', '</div>', '<br/>',
                    '<sub>', '</sub>', '<sup>', '</sup>', '</p>', '<p', '<p>',
                    '<tr>', '</tr>', '<td>', '<td', '</td>', '<a>', '<a', '</a>', '>', '—', '<img', '<' ],
                find: ['{%ICC_BOLD%}', '{%ICC_ENDBOLD%}', '{%ICC_ITALIC%}', '{%ICC_ENDITALIC%}', '{%ICC_TABLE%} ',
                    '{%ICC_TABLE', '{%ICC_ENDTABLE%}', '{%ICC_SPAN%} ', '{%ICC_SPAN ', '{%ICC_ENDSPAN%}',
                    '{%ICC_DIV ', '{%ICC_DIV%}', '{%ICC_ENDDIV%}', '{%ICC_BREAK%}', '{%ICC_SUB%}',
                    '{%ICC_ENDSUB%}', '{%ICC_SUP%}', '{%ICC_ENDSUP%}', '{%ICC_ENDPARA%}', '{%ICC_PARA ', '{%ICC_PARA%}',
                    '{%ICC_TR%}', '{%ICC_ENDTR%}', '{%ICC_TD%}', '{%ICC_TD', '{%ICC_ENDTD%}', '{%ICC_ANCHOR%}', '{%ICC_ANCHOR', '{%ICC_ENDANCHOR%}', '%}', 'â??', '{%ICC_IMAGE ', '{% ' ],
                allowed_tags: '<figure><img><figcaption><section><h1><h2><h3><ol><ul><li><p><br><span><table><tbody><th><tr><td>'
            };
        },
        methods : {
            parseText : function (html, hasTitle) {
                var resultContent = html;
                if (this.type === 'New DB schema XML') {
                    resultContent = string.str_replace(this.find, this.replace, html);
                }
                resultContent = strip.strip_tags(resultContent, this.allowed_tags);
                if (!hasTitle) {
                    resultContent = this.getContentHeading(html) + resultContent;
                }
                return resultContent;
            },
            isXMLType : function () {
                return !Array.isArray(this.content);
            },
            getContentHeading : function (html) {
                var contentHeading = '';
                if(html.indexOf('<h1') === -1 && html.indexOf('<h2') === -1 ) {
                    contentHeading = this.getTableHeading();
                }
                return contentHeading;
            },
            getTableHeading : function () {
                var tableHeading = '';

                if(this.breadcrumbs != null && this.breadcrumbs.subSection != null && '' !== this.breadcrumbs.subSection) {
                    var subSectionTitle = this.breadcrumbs.subSection.toLowerCase();
                    if(subSectionTitle.indexOf('table ') !== -1) {
                        tableHeading = '<h2 class="level3_title">' + this.breadcrumbs.subSection + '</h2> ';
                    }
                }
                return tableHeading;
            },
            cleanTitle(title) {
                return title ? title.trim() : title;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #contentParent section {
        clear: none;
    }
</style>
