var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("outlined-card", {
    attrs: { showDivider: false },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c(
              "h2",
              { staticClass: "text-h6 font-weight-bold profile-wrappe" },
              [
                _vm._v(
                  "\n            Your Active Profile: " +
                    _vm._s(_vm.activeUserProfile.profileType) +
                    "\n            "
                ),
                _c("span", { staticClass: "active-code" }, [
                  _vm._v(_vm._s(_vm.activeUserProfile.accessCodeOrName)),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "fs-16" },
              [
                _vm.activeUserProfile.profileType
                  ? [
                      _c(
                        "p",
                        { staticClass: "mb-2 font-weight-bold" },
                        [
                          _c("access-icon", {
                            attrs: {
                              accessLevel: _vm.hasPremiumRole
                                ? "premiumActive"
                                : "basic",
                            },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.premiumTier) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _c("v-progress-linear", {
                      staticClass: "mt-3",
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                _vm._v(" "),
                _c("v-divider", { staticClass: "my-3" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grey--text text--darken-2 text-uppercase text-sm font-weight-bold",
                  },
                  [_vm._v("Premium Answers")]
                ),
                _vm._v(" "),
                _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  _vm._l(_vm.premiumAnswerLinks, function (link, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        staticClass: "pa-0",
                        staticStyle: { "min-height": "32px" },
                        attrs: { role: "listitem" },
                      },
                      [
                        !_vm.hasPremiumRole && link.premiumRequired
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "disabled-link",
                                  attrs: { "aria-disabled": "true" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(link.text) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "primary--text text-decoration-underline",
                                  attrs: {
                                    href: link.url,
                                    target: "_blank",
                                    rel: "noopener",
                                    role: "link",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(link.text) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                        _vm._v(" "),
                        link.new
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ml-2 cp text-uppercase",
                                attrs: {
                                  small: "",
                                  light: "",
                                  color: "primary",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        New\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { staticClass: "my-2" }),
                _vm._v(" "),
                _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  _vm._l(_vm.links, function (link, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        staticClass: "pa-0",
                        staticStyle: { "min-height": "32px" },
                        attrs: { role: "listitem" },
                      },
                      [
                        !_vm.hasPremiumRole && link.premiumRequired
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "disabled-link",
                                  attrs: { "aria-disabled": "true" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(link.text) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "primary--text text-decoration-underline",
                                  attrs: {
                                    href: link.url,
                                    target: "_blank",
                                    rel: "noopener",
                                    role: "link",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(link.text) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.hasPremiumRole
              ? _c(
                  "div",
                  { staticClass: "pt-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          outlined: "",
                          depressed: "",
                          target: "_blank",
                          href: _vm.getRoute("premium_features"),
                        },
                      },
                      [
                        _vm._v(
                          "\n                Learn About Premium\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }