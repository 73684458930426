var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.hover && "title-card-hover" },
    [
      _c(
        "v-card",
        {
          staticClass: "rounded-lg light-border-color",
          attrs: {
            outlined: _vm.listOutline,
            flat: !_vm.listOutline,
            color: _vm.background,
          },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-1" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { staticClass: "py-0" }, [
                            _c(
                              "a",
                              { attrs: { href: _vm.getUrl() } },
                              [
                                _c(
                                  "graphic",
                                  {
                                    attrs: {
                                      title: _vm.title,
                                      width: _vm.width,
                                      additionalClasses: "mt-2 mx-1",
                                      additionalClassesAstm: "mx-1 my-2",
                                      overrideTitleIcon: _vm.overrideTitleIcon,
                                      overrideCover: _vm.overrideCover,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    thumbnail>\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    _vm._g(
                      { staticClass: "py-0 pl-0" },
                      _vm.preventLink
                        ? { click: () => _vm.updateFavorite(_vm.title) }
                        : null
                    ),
                    [
                      _c(
                        "a",
                        {
                          staticClass: "accent--text",
                          attrs: { href: _vm.getUrl() },
                        },
                        [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.title.display_title) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.showVersion
                            ? _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      this.getBookPrintingInfo(_vm.title)
                                    ) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.title.is_astm
                            ? _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.title.subtitle) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.title.is_collection
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _vm.title.subscription_required
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          width: "20px",
                                          src: _vm.asset(
                                            require("../../../images/icons/premium-only.png")
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.title.is_xml
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          width: "20px",
                                          src: _vm.asset(
                                            require("../../../images/icons/pdf_book_cover.png")
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showFavorite
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("favorite-icon", {
                            attrs: {
                              document_id: _vm.title.document_id
                                ? _vm.title.document_id
                                : _vm.title.documentId,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showLinkIcon
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                icon: "",
                                text: "",
                                href: _vm.title.link,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "accent2" } }, [
                                _vm._v(
                                  "\n                            mdi-chevron-right\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }