var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              staticClass: "cdpBlue",
              attrs: { block: "", "data-qa": "landing-begin-trial", dark: "" },
              on: { click: _vm.trialClick },
            },
            [
              _c("span", { staticClass: "roboto caption" }, [
                _vm._v("Start Free 14-day Premium Complete Trial"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }