<template>
    <a @click="openFeedbackModal()">
        Share your feedback <v-icon color="primary" small>mdi-comment-text-outline</v-icon>
    </a>
</template>
<script>
    import FeedbackChat from '../inc/mixins/feedback_chat';

    export default {
        mixins: [FeedbackChat],
        methods: {
            openFeedbackModal() {
                EventBus.fire('show-feedback-modal');
            }
        }
    }
</script>