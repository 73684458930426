import axios, {AxiosError} from "axios";
import {EventNames} from "./EventNames";

/**
 *
 * @param {AxiosError|Error} err
 * @return {boolean}
 */
const shouldReport = function (err) {

    if (err === null) {
        return false;
    }

    // certain errors we want to ignore...
    if (axios.isCancel(err)) {
        return false;
    }

    // when axios request in progress, if you navigate away from current page, axios will throw this error
    if (err.code === "ECONNABORTED") {
        return false;
    }

    return true;
}

export class ErrorHandler {

    static get vueErrorHandler() {

        return function (err, vm, info) {
            return ErrorHandler.handle(err);
        }
    }

    static get handle() {

        return function (err) {
            console.error(err);

            if (shouldReport(err)) {
                ErrorHandler.alert('Something unexpected happened. Try again later...');

                if ('newrelic' in window && typeof window.newrelic.noticeError === 'function') {
                    window.newrelic.noticeError(err);
                }
            }

        };
    }

    /**
     *
     * @param {string} error
     */
    static alert(error) {

        return;

        EventBus.fire(EventNames.ERROR, {
            text: error
        })
    }
}
