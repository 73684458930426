var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "confirm-dialog",
    {
      attrs: {
        "is-open": _vm.dialogVisible,
        width: 800,
        "is-loading": _vm.isLoading,
      },
      on: { closed: _vm.emitClosed },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
          },
          proxy: true,
        },
        {
          key: "buttons",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-full d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      on: { click: _vm.clickedClose },
                    },
                    [_vm._v("\n                Close\n            ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", {
        staticClass: "pt-5",
        staticStyle: { "font-size": "14px" },
        domProps: { innerHTML: _vm._s(_vm.html) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }