var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "map-wrapper-container" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
            _c(
              "h2",
              { staticClass: "text-center primary--text font-weight-regular" },
              [_vm._v("\n                Find Codes by State\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-center mb-5 mt-5" },
              [
                _c("state-codes-dropdown", {
                  staticClass: "mr-3",
                  on: { changed: _vm.stateChangedCallback },
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", large: "" },
                    on: { click: _vm.goClicked },
                  },
                  [_vm._v("\n                    Go\n                ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "map" } }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-avatar", {
                        attrs: { color: "#04A9A5", size: "20" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Statewide adoption of one or more ICC Codes (also includes locally adopted codes)"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-avatar", {
                        attrs: { color: "#0B5940", size: "20" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "States where ICC Codes are adopted at the local level by jurisdictions"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "my-2" }, [
                      _vm._v(
                        "\n                        *ICC makes every effort to provide current, accurate code adoption information. Not all\n                        jurisdictions notify ICC of code adoptions. To obtain more detailed information on\n                        amendments and changes to adopted codes, please contact the jurisdiction. To submit code\n                        adoption information:"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "accent2--text",
                          attrs: {
                            href: "https://www.iccsafe.org/adoptions",
                            target: "_blank",
                          },
                        },
                        [_vm._v("www.iccsafe.org/adoptions")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }