var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "white elevation-1",
          attrs: {
            app: "",
            "clipped-left": "",
            "clipped-right": "",
            flat: "",
            fixed: "",
            dense: "",
            id: "navigation-app",
            height: "64px",
          },
        },
        [
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.showNavDrawer()
                    },
                  },
                },
                [
                  [
                    _c("v-icon", { attrs: { color: "black" } }, [
                      _vm._v("menu"),
                    ]),
                    _vm._v("\n                Menu\n            "),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: {
                    text: "",
                    "aria-label": "Link opens home page",
                    href: _vm.getRoute("index_landing"),
                  },
                },
                [
                  !_vm.hasPremiumSubscription
                    ? _c("img", {
                        staticClass: "logo",
                        attrs: {
                          alt: "ICC Digital Codes logo",
                          width: "80",
                          height: "42",
                          src: _vm.asset(
                            require("../../../images/logo_dc.png"),
                            "default"
                          ),
                        },
                      })
                    : _c("img", {
                        staticClass: "logo",
                        attrs: {
                          alt: "ICC Digital Codes Premium Logo",
                          width: "100",
                          height: "42",
                          src: _vm.asset(
                            require("../../../images/icc_digital_codes_premium_100.png"),
                            "default"
                          ),
                        },
                      }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "", clss: "ml-2" } }),
          _vm._v(" "),
          _c("search-bar", { staticClass: "mx-5" }),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("cart-updates"),
          _vm._v(" "),
          _c("logo-menu"),
          _vm._v(" "),
          _c("navigation-help-menu"),
          _vm._v(" "),
          !_vm.hasPremiumSubscription
            ? [
                _c(
                  "v-btn",
                  {
                    staticClass: "font-weight-bold",
                    attrs: {
                      color: "accent2",
                      small: "",
                      depressed: "",
                      href: _vm.getRoute(
                        _vm.pricingPageEnabled
                          ? "pricing_page"
                          : "premium_features"
                      ),
                    },
                  },
                  [_vm._v("\n      Subscribe\n    ")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          [_c("v-toolbar-items", [_c("profiles-menu")], 1)],
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", fullscreen: _vm.isMobilePort },
              model: {
                value: _vm.userNoticeModal,
                callback: function ($$v) {
                  _vm.userNoticeModal = $$v
                },
                expression: "userNoticeModal",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "primary white--text",
                      class: {
                        headline: !_vm.isMobilePort,
                        "caption mobile-title": _vm.isMobilePort,
                      },
                      attrs: { id: "user-notice" },
                    },
                    [
                      _vm._v("\n                Notice\n                "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 accent2--text",
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeNoticeModal()
                            },
                          },
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("p", { staticClass: "mb-0" }, [
                              _c("b", [_vm._v("ICC welcomes you!")]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n\n                            By using the website, you are agree to our updated "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.iccsafe.org/about/terms-of-use/",
                                  },
                                },
                                [_c("b", [_vm._v("Terms of Use")])]
                              ),
                              _vm._v(
                                ",\n                            which will become effective on " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.termsEffectiveDate,
                                      "LL"
                                    )
                                  ) +
                                  ". "
                              ),
                              _c("br"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeNoticeModal()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Got It\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showCartQue
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                "disable-resize-watcher": "",
                app: "",
                clipped: "",
                right: "",
                width: "420px",
                height: "100vh",
              },
            },
            [_c("cart-que")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }