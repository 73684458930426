<template>
    <div :class="hover && 'title-card-hover'">
    <v-card
        :outlined="listOutline"
        :flat="!listOutline"
        :color="background"
        class="rounded-lg light-border-color">
        <v-card-text class="pa-1">
            <v-row align="center">
                <v-col cols="auto" class="py-0">
                    <v-row dense>
                        <v-col class="py-0">
                            <a :href="getUrl()">
                                <graphic
                                    :title="title"
                                    :width="width"
                                    additionalClasses="mt-2 mx-1"
                                    additionalClassesAstm="mx-1 my-2"
                                    :overrideTitleIcon="overrideTitleIcon"
                                    :overrideCover="overrideCover">
                                    thumbnail>
                                </graphic>
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-0 pl-0" v-on="preventLink ? { click: () => updateFavorite(title) } : null">
                    <a
                        class="accent--text"
                        :href="getUrl()">
                        <p class="mb-0">
                            {{ title.display_title }}
                        </p>
                        <p
                            v-if="showVersion"
                            class="caption mb-0">
                            {{ this.getBookPrintingInfo(title) }}
                        </p>
                        <p
                            v-if="title.is_astm"
                            class="caption mb-0">
                            {{ title.subtitle }}
                        </p>
                    </a>
                    <v-row
                        v-if="!title.is_collection"
                        dense>
                        <v-col
                            class="py-0"
                            cols="auto"
                            v-if="title.subscription_required">
                            <v-img
                                width="20px"
                                :src="asset(require('../../../images/icons/premium-only.png'))">
                            </v-img>
                        </v-col>
                        <v-col
                            class="py-0"
                            cols="auto"
                            v-if="!title.is_xml">
                            <v-img
                                width="20px"
                                :src="asset(require('../../../images/icons/pdf_book_cover.png'))">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    v-if="showFavorite"
                    cols="auto">
                    <favorite-icon :document_id="title.document_id ? title.document_id : title.documentId">
                    </favorite-icon>
                </v-col>
                <v-col
                    v-if="showLinkIcon"
                    cols="auto">
                    <v-btn
                        small
                        icon
                        text
                        :href="title.link">
                        <v-icon color="accent2">
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
    import Graphic from "./Graphic";
    import FavoriteIcon from './../../content/favorite';

    export default {
            data: function(){
            return {
                is_favorite : false
            };
        },
        props: {
            title: {
                required: true,
                type: Object
            },
            showVersion: {
                required: true,
                type: Boolean
            },
            showFavorite: {
                required: true,
                type: Boolean
            },
            showLinkIcon: {
                required: true,
                type: Boolean
            },
            listOutline: {
                required: true,
                type: Boolean
            },
            preventLink: {
                required: false,
                type: Boolean,
                default: false
            },
            hover: {
                required: false,
                type: Boolean,
                default: true
            },
            background: {
                required: false,
                type: String,
                default: 'white'
            },
            overrideTitleIcon: {
                required: false,
                type: Boolean,
                default: false
            },
            overrideCover: {
                required: false,
                type: Boolean,
                default: false
            },
            width: {
                required: false,
                type: String,
                default: '50px'
            }
        },
        components: {
            Graphic,
            FavoriteIcon
        },
        methods: {
            getUrl() {
                if (!this.preventLink) {
                    return this.title.link;
                }
                return null;
            },
            isFavorite() {
                const document_id = this.title.document_id;
                EventBus.fire("update-favorites-after");
                return _.find(this.favorites, function(o) {
                    return o.title.document_id === _.toNumber(document_id);
                }) !== undefined;
            },
            updateFavorite(title) {
                 this.$http.get(Routing.generate('update_favorite_status', {
                    documentId: title.document_id
                }))
                .then(response => {
                    if(response.data['success']) {
                        this.is_favorite = !this.is_favorite;
                        EventBus.fire("reset-favorites");
                    }
                });
            }
        },
        mounted() {
            this.is_favorite = this.isFavorite();
        }
    }
</script>

<style lang="scss" scoped>
    .title-card-hover:hover {
        background: rgba(238, 233, 233, 0.897) !important;
    }
    .image-border {
        border: 2px solid #FFA201;
    }
</style>
