<template>
    <div>
        <v-divider />
        <v-row
            align="center"
            dense>
            <v-col class="d-flex px-2">
                <a 
                    :href="clearCalcUrl"
                    target="_blank" 
                    class="d-flex align-center">
                    <img
                        :src="asset(require('../../../app/images/calculators/clearcals_icon.webp'))"
                        alt="ClearCalcs Logo"
                        title="ClearCalcs Logo"/>
                    <span class="black--text font-weight-bold">Powered by ClearCalcs</span>
                </a>
            </v-col>
            <v-col 
                class="text-right px-2" 
                :class="!isMobilePort ? 'pr-6' : 'pt-2'">
                <v-btn
                    class="px-10"
                    color="primary"
                    :href="getRoute('compliance_calculators')"
                    target="_blank">
                    View More Calculators
                </v-btn>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
import Constants from "../../constants";
import Mobile from "./../inc/mixins/mobile";

export default {
    mixins: [
        Constants,
        Mobile
    ],
    data() {
        return {
            clearCalcUrl: Constants.links.clearCalcUrl
        }
    },
}
</script>
