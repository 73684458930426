var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPremiumSubscribed(_vm.documentInfo.sku) &&
    ("1" === _vm.contentPremium || _vm.documentInfo.contentPremium)
    ? _c(
        "v-chip",
        {
          staticClass: "mx-2 px-1",
          attrs: { dark: "", color: "accent2", "x-small": "", label: "" },
        },
        [
          "1" == _vm.contentProtection || _vm.documentInfo.contentProtection
            ? _c(
                "a",
                {
                  staticClass: "white--text",
                  on: {
                    click: function ($event) {
                      return _vm.openAccessLevelPermissionModal("Premium Lite")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$getConst("accessLevels").premiumLite) +
                      "\n    "
                  ),
                ]
              )
            : _c(
                "a",
                {
                  staticClass: "white--text",
                  on: {
                    click: function ($event) {
                      return _vm.openAccessLevelPermissionModal("Premium")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$getConst("accessLevels").premium) +
                      "\n    "
                  ),
                ]
              ),
        ]
      )
    : !_vm.isContentRestricted(_vm.documentInfo)
    ? _c(
        "v-chip",
        {
          staticClass: "mx-2 px-1",
          attrs: { dark: "", color: "primary", "x-small": "", label: "" },
        },
        [
          ("1" == _vm.contentBasic && "1" == _vm.contentProtection) ||
          (_vm.documentInfo.contentBasic && _vm.documentInfo.contentProtection)
            ? _c(
                "a",
                {
                  staticClass: "white--text",
                  on: {
                    click: function ($event) {
                      return _vm.openAccessLevelPermissionModal("Basic")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$getConst("accessLevels").basic) +
                      "\n    "
                  ),
                ]
              )
            : _c(
                "a",
                {
                  staticClass: "white--text",
                  on: {
                    click: function ($event) {
                      return _vm.openAccessLevelPermissionModal("Basic Plus")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$getConst("accessLevels").basicPlus) +
                      "\n    "
                  ),
                ]
              ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }