<template>
    <div
        data-qa="dashboard-notes"
        class="dashboard-notes">

        <breadcrumb title="My Notes"></breadcrumb>

        <v-container id="contentParent">
            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">
                    <v-alert
                        v-model="topBanner"
                        class="mb-2"
                        color="primary"
                        type="info"
                        dismissible>
                        The Annotations and Reports pages have combined together into the My Notes page. Annotations
                        created for tags and bookmarks are called Notes.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-if="error_status">
                <v-col
                    xl="10"
                    offset-xl="1">
                    <v-alert
                        :value="error_status"
                        color="cyan"
                        icon="info">
                        {{ error_message }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>

        <v-container id="tableHeadingContainer">
            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">
                    <v-row
                        align="center"
                        justify="center">
                        <v-col
                            cols="12"
                            sm="3">
                            <v-select
                                hide-details
                                :menu-props="{ bottom: true, offsetY: true }"
                                label="All Project Tags"
                                :loading="tagsFilterLoading"
                                :disabled="tagsFilterLoading"
                                v-model="tag"
                                :items="tagsOptionsItems"
                                solo>
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3">
                            <v-select
                                hide-details
                                :menu-props="{ bottom: true, offsetY: true }"
                                label="All Categories"
                                v-model="category"
                                :loading="categoryTitleFiltersLoading"
                                :disabled="categoryTitleFiltersLoading"
                                :items="categoriesDropdownValues"
                                solo>
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3">
                            <v-select
                                hide-details
                                :menu-props="{ bottom: true, offsetY: true }"
                                label="All My Library Titles"
                                v-model="book"
                                :loading="categoryTitleFiltersLoading"
                                :disabled="categoryTitleFiltersLoading"
                                :items="titlesDropdownValues"
                                solo>
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="auto">
                            <v-btn
                                color="primary"
                                large
                                class="mx-0"
                                @click="clickedResetFilters"
                                block
                                title="Reset All Filters">
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">
                    <v-card class="grey lighten-3">
                        <v-card-text>
                            <v-row
                                align="center"
                                dense>
                                <v-col
                                    cols="12"
                                    sm="6">
                                    <v-btn
                                            color="primary"
                                            class="large"
                                            @click="clickedExpandAll"
                                            :block="isMobilePort">
                                        <span
                                                title="Show Tag Details"
                                                v-if="openPanels.length === 0">Expand All</span>
                                        <span
                                                title="Hide Tag Details"
                                                v-else>Collapse All</span>
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6">
                                    <v-row
                                        align="center"
                                        dense
                                        :justify="isMobilePort ? 'center' : 'end'"
                                        :class="{
                                            'my-3': isMobilePort
                                        }">
                                        <v-col
                                            cols="12"
                                            sm="6">
                                            <v-text-field
                                                data-qa="dashboard-notes-pdf-title"
                                                solo
                                                hide-details
                                                v-model="report_title"
                                                label="PDF Title">
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="auto"
                                            sm="6">
                                            <v-btn
                                                data-qa="dashboard-notes-generate-pdf"
                                                color="primary"
                                                @click="clickedGeneratePdf"
                                                title="Print Documents"
                                            :loading="report_load">
                                                <span v-if="!report_load">
                                                    Generate PDF
                                                </span>
                                                <v-progress-circular
                                                    v-else
                                                    indeterminate
                                                    color="green">
                                                </v-progress-circular>
                                            </v-btn>
                                            <v-btn
                                                v-if="isOwnUser == '1'"
                                                color="primary"
                                                class="v-btn-right"
                                                @click="clickedShare"
                                                title="Share Tags">
                                                Share
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        dense
                                        align="center"
                                        :justify="isMobilePort ? 'center' : 'end'">
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            :class="{'text-center': isMobilePort}">
                                            <p class="v-label theme--light mb-0">
                                                {{ selectedNotesCombined.length }}
                                                <span v-if="selectedNotesCombined.length === 1">Note</span>
                                                <span v-else>Notes</span>
                                                Currently Selected
                                            </p>
                                        </v-col>
                                        <v-col
                                            cols="auto"
                                            sm="6"
                                            :class="{'text-center': isMobilePort}">
                                            <v-checkbox
                                                hide-details
                                                class="pa-0 ma-0"
                                                label="Select all notes"
                                                @change="selectAllChanged">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row>
                <v-col
                    xl="10"
                    offset-xl="1">

                    <div v-if="loading" class="pt-12 text-md-center">
                        <v-progress-circular
                                :size="32"
                                indeterminate>
                        </v-progress-circular>
                    </div>

                    <note-list v-else
                               :data="data"
                               @panelSelectionChanged="openPanelsChanged"
                               @selectionChanged="onSelectionChanged">
                    </note-list>

                </v-col>
            </v-row>
            <notes></notes>
            <back-to-top></back-to-top>
        </v-container>
    </div>
</template>

<script>
    import Breadcrumb from '../../content/partials/page_titles';
    import BackToTop from '../../partials/buttons/back_to_top.vue';
    import NoteMixins from './mixins/note';
    import BookTitle from "../licenses/parts/content_title";
    import TitleCover from "./../../titles/cover/Index";
    import Notes from '../../titles/notesModal/index';
    import Mobile from './../../inc/mixins/mobile';
    import axios from "axios";
    import NoteList from "./NoteList";
    export default {
        props: {
            userId: {
                  type: String,
                  required: true
            },
            isOwnUser: {
                  type: String,
                  required: true
            },
            isConcurrent: {
                type: String,
                required:false
            }
        },
        data: function () {
            return {
                page: 1,
                openPanels: [],
                loading: false,
                pdfLoading: false,
                tagsOptionsItems: [],
                titles: [],
                allExpanded: false,
                tagsFilterLoading: false,
                categoryTitleFiltersLoading: false,
                data: [],
                selectedNotesCombined: []
            }
        },
        computed: {
            titlesDropdownValues() {
                return this.titles.map((title) => {
                    return {
                        text: title['display_title'],
                        value: title['document_id']
                    }
                });
            },
            categoriesDropdownValues() {

                const allCategories = [];

                this.titles.forEach((title) => {

                    const categories = title.categories || [];

                    categories.forEach((category) => {
                        allCategories.push(category);
                    });

                });

                return allCategories.map((cat) => {
                    return {
                        text: cat.name,
                        value: cat.id
                    }
                });

            },
            allNotes() {
                let notes = [];

                this.data.forEach((datum) => {
                    notes = notes.concat(
                        (datum.notes || [])
                    )
                });

                return notes;
            },
            allNoteIds() {

                return this.allNotes.map((item) => {

                    if (item.type === 'bookmark') {
                        return 'bookmark_' + item.bookmarkId;
                    }

                    return 'annotation_' + item.id;
                });
            }
        },
        mixins: [NoteMixins, Mobile],
        components: {
            NoteList,
            BackToTop,
            BookTitle,
            Notes,
            Breadcrumb,
            TitleCover
        },
        methods: {
            getTooltipMessage(isAdmin) {
                return isAdmin ? '' : 'You can only edit or delete your own notes within concurrent connection.';
            },
            generateNotesLog(documentId) {
                this.pdfLoading = true;
                this.exportPdf(Routing.generate('generate_notes_log', {documentId : documentId}), 'notes_migration_log.pdf');
                this.pdfLoading = false;
            },
            onSelectionChanged(notes) {
                this.selectedNotesCombined = notes;
            },
            openPanelsChanged(open) {
                this.openPanels = open;
            },
            selectAllChanged(value) {

                if (value) {
                    this.selectedNotesCombined = this.allNoteIds;
                } else {
                    this.selectedNotesCombined = [];
                }

                EventBus.fire('notes:updateSelection', this.selectedNotesCombined);
            },
            clickedExpandAll() {
                this.allExpanded = !this.allExpanded;

                if (this.openPanels.length) {
                    EventBus.fire('notes:collapse_all');
                } else {
                    EventBus.fire('notes:expand_all');
                }
            },
            clickedGeneratePdf(){
               // this.generateReport();

                if (this.selectedNotesCombined.length === 0) {
                    this.error_status = true;
                    this.error_message = 'Please select at least one Annotation or Bookmark before generating a Report.';
                    return;
                }

                this.report_load = true;

                axios.post(Routing.generate('generate_cache_report', {'userId': this.userId}), {
                    'selectedIds': this.selectedNotesCombined,
                    'reportTitle': (this.report_title === '') ? 'Annotations/Highlights & Bookmarks Report' : this.report_title
                }).then(response => {
                    this.report_load = false;
                    window.open(response.data.url);
                });
            },
            clickedShare() {
                EventBus.fire('show-modal-share-annotation-bookmark', this.selectedNotesCombined);
            },
            clickedResetFilters() {
                this.tag = '';
                this.category = '';
                this.book = '';
            },
            loadTagFilters() {
                this.tagsFilterLoading = true;

                const noteTagsRoute = Routing.generate('get_note_tags');
                axios.get(noteTagsRoute).then((res) => {

                    const tags = res.data['tags'] || [];

                    this.tagsOptionsItems = tags.map((item) => {
                        return {
                            text: item.label,
                            value: item.id
                        }
                    });
                }).finally(() => {
                    this.tagsFilterLoading = false;
                })
            },
            loadCategoryTitleFilters() {
                const titlesRoute = Routing.generate('get_annotation_bookmark_titles');

                this.categoryTitleFiltersLoading = true;

                axios.get(titlesRoute).then((res) => {
                    this.titles = res.data['documents'] || [];
                }).finally(() => {
                    this.categoryTitleFiltersLoading = false;
                });
            },
            loadNotes(){
                this.loading = true;

                const notesRoute = Routing.generate('get_annotation_bookmarks_paginated', {
                    tag: this.tag,
                    category: this.category,
                    title: this.book,
                    userId: this.userId
                });

                axios.get(notesRoute).then((res) => {
                    this.data = res.data['data'];
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.loadNotes();

            const loadNotesDebounced = _.debounce(this.loadNotes, 500);

            this.$watch(() => [this.tag, this.category, this.book], () => {
                loadNotesDebounced();
            });

            this.loadTagFilters();
            this.loadCategoryTitleFilters();

            EventBus.listen('update-notes', () => {
                this.resetFilter()
                this.loadNotes();
            });
            this.$watch(() => this.isLargeAndUp, (isLarge) => {
                const classBody = document.querySelector('.body');
                if (classBody) {
                    if (isLarge) {
                        classBody.classList.remove('overflow-x-hidden');
                    } else {
                        classBody.classList.add('overflow-x-hidden');
                    }
                }
            }, {
                immediate: true
            });
        }
    }
</script>

<style lang="scss">

#tableHeadingContainer {

  @media (min-width: 1264px) {

    position: sticky;
    top: 65px;
    padding: 0 !important;
    background: white;
    z-index: 2;
  }
}

</style>
