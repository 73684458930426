<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div
            class="content-container"
            :class="isMobilePort ? 'px-4 pt-4' : ''">
            <hero-banner-new
                title="Enhance productivity with Premium solutions for teams"
                subtitle="Coordinate team access to ICC Digital Codes Premium content and features for enhanced collaboration. Find tailored solutions that fit your needs and budget.">
                <template v-slot:button>
                    <contact-sales-button class="mr-1">
                    </contact-sales-button>
                    <get-demo-button class="mx-1">
                    </get-demo-button>
                </template>
                <template v-slot:image>
                    <img
                        alt="Team looking at a computer screen"
                        :src="asset(require('../../images/teams/teams_header.png'))"
                        :style="isMobilePort ? '' : 'max-width: 580px;'"/>
                </template>
                <template v-slot:footer>
                    <p class="text-body-2 text--secondary mb-0 mt-6">
                        Already have an Enterprise account or concurrent code?
                        <a
                            class="text-decoration-underline"
                            :href="getRoute('security_login')">
                            Sign in to access
                        </a>
                    </p>
                </template>
            </hero-banner-new>
            <v-row dense>
                <v-col class="text-center">
                    <h2 class="text-h6 text--secondary">
                        Leaders in industry and government rely on Digital Codes
                    </h2>
                    <logo-carousel class="mt-6 mb-16">
                    </logo-carousel>
                </v-col>
            </v-row>
        </div>
        <div class="grey lighten-5 py-16">
            <div
                class="text-center"
                :class="isMobilePort ? 'px-4' : ''">
                <h3 class="text-h4 font-weight-bold oxygen">
                    Flexible plans for teams of any size
                </h3>
                <p class="mt-4 mb-0">
                    Choose from our plans designed for small teams or enterprise-level collaboration
                </p>
            </div>
            <v-row justify="center">
                <v-col
                    cols="auto"
                    v-for="(item, key) in teamPlans"
                    :key="key">
                    <div class="py-6 fill-height">
                        <v-card
                            max-width="384"
                            class="fill-height rounded-lg elevation-4">
                            <v-card-text class="pa-4">
                                <p class="text-overline">
                                    {{item.heading}}
                                </p>
                                <h4 class="text-h5">
                                    <v-avatar
                                        size="24"
                                        :color="item.title.iconColor">
                                        <v-icon
                                            color="grey darken-4"
                                            size="16">
                                            mdi-creation
                                        </v-icon>
                                    </v-avatar>
                                    {{item.title.text}}
                                </h4>
                                <p class="text-body-1 mt-4">
                                    {{item.description}}
                                </p>
                                <v-row
                                    v-for="(listItem, key) in item.items"
                                    :key="key"
                                    dense>
                                    <v-col cols="auto">
                                        <v-icon
                                            color="teal"
                                            left
                                            class="mr-0"
                                        >
                                            mdi-check-circle-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col>
                                        <p class="mb-0">
                                            {{listItem}}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-row
                dense
                justify="center">
                <v-col cols="auto">
                    <contact-sales-button>
                    </contact-sales-button>
                </v-col>
                <v-col cols="auto">
                    <get-demo-button>
                    </get-demo-button>
                </v-col>
            </v-row>
        </div>
        <div
            class="content-container"
            :class="isMobilePort ? 'px-4' : ''">
            <info-banner
                title="Achieve more together with collaborative tools"
                subtitle="Find exactly what you need with advanced search filters. Stay organized and save time with bookmarking and note features."
                :text-right="!isMobilePort">
                <template v-slot:image>
                    <img
                        alt="Digital Codes Premium tools"
                        :src="asset(require('../../images/teams/teams_premium_tools.png'))"
                        :style="isMobilePort ? '' : 'max-width: 580px;'"/>
                </template>
                <template v-slot:button>
                    <v-btn
                        outlined
                        large
                        :href="getRoute('premium_features')">
                        Learn About Premium
                    </v-btn>
                </template>
            </info-banner>
            <v-row>
                <v-col
                    v-for="(item, key) in tools"
                    :key="key"
                    cols="12"
                    sm="4">
                    <icon-card
                        class="fill-height"
                        :item="item">
                    </icon-card>
                </v-col>
            </v-row>
            <info-banner
                title="Operate at scale with Premium Enterprise"
                subtitle="Dedicated access for your entire team, anytime and anywhere. Enhance operational efficiency with our custom solutions and streamlined onboarding.">
                <template v-slot:image>
                    <img
                        alt="Digital Codes Premium tools"
                        :src="asset(require('../../images/teams/team_enterprise.png'))"
                        :style="isMobilePort ? '' : 'max-width: 580px;'"/>
                </template>
                <template v-slot:button>
                    <v-row
                        dense>
                        <v-col cols="auto">
                            <contact-sales-button>
                            </contact-sales-button>
                        </v-col>
                        <v-col cols="auto">
                            <get-demo-button>
                            </get-demo-button>
                        </v-col>
                    </v-row>
                </template>
            </info-banner>
            <v-row>
                <v-col
                    v-for="(item, key) in enterpriseTools"
                    :key="key"
                    cols="12"
                    sm="4">
                    <icon-card
                        class="fill-height"
                        icon-color="cdpBlue"
                        :item="item">
                    </icon-card>
                </v-col>
            </v-row>
            <v-row v-if="!isMobilePort">
                <v-col class="link-color">
                    <h3 class="text-h4 font-weight-bold oxygen text-center mt-16 mb-4">
                        Compare Premium plans for teams
                    </h3>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left text-h6">
                                    Features
                                </th>
                                <th class="text-center text-h6">
                                    Premium
                                </th>
                                <th class="text-center text-h6">
                                    Premium Enterprise
                                </th>
                            </tr>
                            </thead>
                            <tbody class="link-underline">
                            <tr
                                v-for="(item, key) in compareTable"
                                :key="key">
                                <td>
                                    <template v-if="item.feature instanceof Object">
                                        <template v-if="item.feature.link">
                                            <a
                                                :href="item.feature.link"
                                                target="_blank">
                                                {{item.feature.title}}
                                                <v-icon small>
                                                    mdi-open-in-new
                                                </v-icon>
                                            </a>
                                        </template>
                                        <template v-if="item.feature.tooltip">
                                            <v-tooltip
                                                bottom>
                                                <template
                                                    v-slot:activator="{ on }">
                                                    <div
                                                        v-on="on">
                                                        {{item.feature.title}}
                                                        <v-icon
                                                            small>
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </div>
                                                </template>
                                                <template>
                                                    <span class="tooltip-content">
                                                        {{item.feature.tooltip}}
                                                    </span>
                                                </template>
                                            </v-tooltip>
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{item.feature}}
                                    </template>
                                </td>
                                <td
                                    class="text-center">
                                    <v-icon
                                        v-if="item.premium === 'check'"
                                        color="primary">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <div
                                        v-else
                                        v-html="item.premium">
                                    </div>
                                </td>
                                <td class="text-center">
                                    <v-icon
                                        v-if="item.enterprise === 'check'"
                                        color="primary">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <div
                                        v-else
                                        v-html="item.enterprise">
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <p class="text-body-1 text-center mb-0">
                        <a :href="getRoute('premium_features')" title="Learn more">Learn more</a> about all the included Premium features
                    </p>
                </v-col>
            </v-row>
        </div>
        <div class="grey lighten-5 py-16 my-16">
            <customer-testimonials>
            </customer-testimonials>
        </div>
        <div class="content-container pb-16">
            <v-row
                dense
                justify="center">
                <v-col
                    cols="12"
                    class="text-center">
                    <h2 class="text-h6 font-weight-bold mb-4">
                        Take the next step towards success - contact our sales team today!
                    </h2>
                </v-col>
                <v-col cols="auto">
                    <contact-sales-button>
                    </contact-sales-button>
                </v-col>
                <v-col cols="auto">
                    <get-demo-button>
                    </get-demo-button>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import Mobile from '../../components/inc/mixins/mobile';
    import Concurrent from '../../components/concurrent/mixins/concurrent';

    import Moment from 'moment';
    import Login from '../../components/content/partials/login.vue';
    import HeroBannerNew from "../../components/banners/HeroBannerNew.vue";
    import ContactSalesButton from "../../components/buttons/ContactSalesButton.vue";
    import GetDemoButton from "../../components/buttons/GetDemoButton.vue";
    import InfoBanner from "../../components/banners/InfoBanner.vue";
    import IconCard from "../../components/cards/IconCard.vue";
    import LogoCarousel from "../../components/banners/LogoCarousel.vue";
    import CustomerTestimonials from "../../components/homepage/CustomerTestimonials.vue";

    export default {
        data () {
            return {
                teamPlans: [
                    {
                        heading: 'SHARED and dedicated ACCess',
                        title: {
                            iconColor: 'accent2Light',
                            text: 'Premium',
                        },
                        description: 'Licenses that can be used by up to 4 users or assigned individually.',
                        items: [
                            'Targeted for smaller teams',
                            'Includes Premium features',
                            'Subscribe today and self-serve',
                            'License qty. and duration discounts'
                        ]
                    },
                    {
                        heading: 'Dedicated access',
                        title: {
                            iconColor: 'cdpBlueLight',
                            text: 'Premium Enterprise',
                        },
                        description: 'Dedicated user access for larger organizations.',
                        items: [
                            'Ideal for larger teams',
                            'Includes Premium features',
                            'Dedicated Digital Codes account representative',
                            'Account setup and onboarding with continuous support',
                            'Granular user controls via admin dashboard',
                            'License qty. and duration discounts',
                            'Custom solutions available'
                        ]
                    }
                ],
                tools: [
                    {
                        icon: 'mdi-bookmark-multiple-outline',
                        title: 'Bookmark sections',
                        description: 'Quickly access important information with saved bookmarks and notes.'
                    },
                    {
                        icon: 'mdi-share-variant-outline',
                        title: 'Share notes',
                        description: 'Keep your team informed and connected through shared bookmarks, annotations, and notes.'
                    },
                    {
                        icon: 'mdi-label-outline',
                        title: 'Tag projects',
                        description: 'Categorize your notes with project tags to effortlessly maintain organization between different projects.'
                    }
                ],
                enterpriseTools: [
                    {
                        icon: 'mdi-information-outline',
                        title: 'Account onboarding and support',
                        description: 'Our team will set you up for success and provide ongoing support when you need it with a dedicated account manager..'
                    },
                    {
                        icon: 'mdi-account-group-outline',
                        title: 'Advanced admin tools',
                        description: 'Enhanced administrative capabilities and more control mechanisms to optimize team performance and accountability.'
                    },
                    {
                        icon: 'mdi-check-circle-outline',
                        title: 'Custom and flexible solutions',
                        description: 'Need a code subscription bundle tailored to your specific needs? Our sales team is ready to work with you to create the perfect package.'
                    }
                ],
                compareTable: [
                    {
                        feature: 'Code titles included',
                        premium: 'Singles, collections or complete',
                        enterprise: 'Singles, collections, complete or a custom bundle'
                    },
                    {
                        feature: {
                            title: 'All Premium features',
                            link: this.getRoute('premium_features')
                        },
                        premium: 'check',
                        enterprise: 'check'
                    },
                    {
                        feature: 'License setup options',
                        premium: 'Shared (1-4 users per license) and <br/>Dedicated (1 user per license)',
                        enterprise: 'Dedicated (1 user per license)'
                    },
                    {
                        feature: 'Account admins allowed',
                        premium: '1',
                        enterprise: 'Unlimited'
                    },
                    {
                        feature: 'Pricing discounts',
                        premium: 'Volume (2-20 licenses) and billing duration',
                        enterprise: 'Volume (50+ licenses) and billing duration'
                    },
                    {
                        feature: 'Admin access to user history',
                        premium: '',
                        enterprise: 'check'
                    },
                    {
                        feature: {
                            title: 'Managed user access controls',
                            tooltip: 'Dedicated user tracking with invited, accepted and pending with Enterprise'
                        },
                        premium: '',
                        enterprise: 'check'
                    },
                    {
                        feature: 'Account onboarding and support',
                        premium: '',
                        enterprise: 'check'
                    },
                    {
                        feature: 'Dedicated account manager',
                        premium: '',
                        enterprise: 'check'
                    },
                    {
                        feature: {
                            title: 'Account branding',
                            tooltip: 'Your company’s name vs. a generic concurrent code'
                        },
                        premium: '',
                        enterprise: 'check'
                    }
                ]
            };
        },
        mixins: [Mobile, Concurrent],
        components: {
            CustomerTestimonials,
            LogoCarousel,
            IconCard,
            GetDemoButton,
            ContactSalesButton,
            HeroBannerNew,
            Moment,
            Login,
            InfoBanner
        },
        methods: {
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
            showModal() {
                EventBus.fire('show-modal-concurrent-access');
            }
        },
        mounted() {

        }
    }
</script>
<style
    lang="scss"
    scoped>
    .premium-team-container {
        max-width: 1200px;

        margin: 0 auto;
        img {
            max-width: 100%;
        }
        .oxygen {
            font-family: Oxygen;
        }
    }
    @media (min-width: 685px) {
        h1 {
            font-size: 54px;
            line-height: 68px;
        }
        h2 {
            font-size: 32px;
            line-height: 42px;
        }
        h3 {
            font-size: 23px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 685px) and (max-width: 820px)  {
         h1 {
            font-size: 42px;
        }
    }
    @media (max-device-width: 684px) {
        .access-display {
            padding: 0px !important;
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 13px;
            }
            h3 {
                font-size: 10px;
            }
        }
        .quoted-wrapper{
            padding: 30px !important;
        }
    }
    .option-text {
        font-size: 20px;
        line-height: 25px;
    }
    .access-highlights {
        background: #FEF5E4 !important;
    }
    .v-divider{
        border-color: #fff;
        border-width: 1px;
    }
    .quoted{
        font-size: 35px;
    }

</style>
