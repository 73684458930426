<template>
    <div
        class="ft-container mb-n3"
        :class="{'overflow-hidden': !isMobilePort}">
        <v-row
            justify="center"
            class="gradient mt-11"
            :class="{ 'py-0 mx-0': !isMobilePort }">
            <v-col
                class="py-0 mx-0"
                :class="{
                    'ft-img-web': !isMobilePort,
                    'ft-img-mob': isMobilePort,
                    'mb-9': isMobilePort && (3 === persona || 4 === persona)
                }"
                :cols="`{ '7': !isMobilePort, auto: isMobilePort }`"
                :order="!isMobilePort ? ((3 === persona || 4 === persona) ? 2 : 1) : 2">
                <v-img
                    v-if="3 === persona || 4 === persona"
                    max-width="690px"
                    class="float-right"
                    :class="{ img: !isMobilePort }"
                    :src="!isMobilePort ?
                    asset(
                        require('../../../images/' + displayData.imageWeb.src),
                        'default'
                    ) :
                    asset(
                        require('../../../images/' + displayData.imageMobile.src),
                        'default'
                    )" />
                <v-img
                    v-if="2 === persona || 1 === persona"
                    max-width="690px"
                    class="float-right"
                    :class="{ img: !isMobilePort }"
                    :src="!isMobilePort ?
                    asset(
                        require('../../../images/exams/' + displayData.imageWeb.src),
                        'default'
                    ) :
                    asset(
                        require('../../../images/exams/' + displayData.imageMobile.src),
                        'default'
                    )" />
            </v-col>
            <v-col
                :cols="`{ '5': !isMobilePort, auto: isMobilePort }`"
                :order="!isMobilePort ? ((3 === persona || 4 === persona) ? 1 : 2) : 1">
                <h2
                    v-if="persona===3"
                    class="pt-9 px-0"
                    :class="{'text-center': isMobilePort, 'headline': !isMobilePort }"
                    v-html="displayData.browseCodesTitle">
                </h2>
                <h2
                    v-else
                    class="pt-9 px-6"
                    :class="{
                        'mr-16':!isMobilePort && (1 === persona || 2 === persona),
                        'text-center px-0': isMobilePort,
                        'headline': !isMobilePort,
                        'pl-6': freeTrialAvailable
                    }"
                    v-html="freeTrialAvailable ? displayData.title : displayData.browseCodesTitle">
                </h2>
                <h5 :class="{
                    'text-center': isMobilePort,
                    'title': isMobilePort,
                    'subtitle-1': !isMobilePort,
                    }"
                    class="pl-6 pt-2"
                    v-html="displayData.subtitle">
                </h5>
                <ul :class="{
                    'mx-3': !isMobilePort && (1 === persona || 2 === persona),
                    'title px-9 list': isMobilePort,
                    'body-2 px-16': !isMobilePort
                    }">
                    <li
                        v-for="feature in displayData.features"
                        :key="feature.id">
                        {{ feature.title }}
                    </li>
                </ul>
                <div
                    class="mt-9 ml-6"
                    :class="{ 'text-center mb-12': isMobilePort }">
                    <v-btn
                        v-if="freeTrialAvailable"
                        rounded
                        class="white--text"
                        color="accent2"
                        depressed
                        x-large
                        dark
                        :target="displayData.button.target"
                        :href="displayData.button.link">
                        {{ displayData.button.text }}
                    </v-btn>
                    <v-btn
                        v-else
                        rounded
                        class="white--text"
                        color="accent2"
                        depressed
                        x-large
                        dark
                        :target="displayData.buttonBrowseCodes.target"
                        :href="displayData.buttonBrowseCodes.link">
                        {{ displayData.buttonBrowseCodes.text }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <p v-if="4 === persona" class="caption pl-10">*Digital Codes Premium is available for exam preparation, but the Premium features are deactivated during the exam itself.</p>
        </v-row>
    </div>
</template>

<script>
    import Mobile from "../../inc/mixins/mobile";
    export default {
        props: {
            persona: {
                require: true,
                type: Number,
                default: 1
            },
            displayData: {
                require: true,
                type: Object
            },
            freeTrialAvailable: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        mixins: [Mobile]
    };
</script>

<style lang="scss" scoped>
    .ft-container {

        margin-left: calc(52% - 50vw);
        margin-right: calc(52% - 50vw);

        .ft-img-web {
            height: 360px;
        }

        .ft-img-mob {
            height: auto;
            text-align: center;
        }

        .img {
            top: -43px;
            height: auto;
            width: 100%;
        }

        .gradient {
            background-image: linear-gradient(180deg, #fdf5e6 45.83%, #ffffff 100%);
        }

        .list {
            display: block;
            text-align: center;
        }
        h2 {
            font-size: 28px;
        }
    }
</style>
