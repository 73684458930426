<template v-if="documentCollections.length">
    <v-container class="white">

        <v-row align="start" class="d-flex" v-if="documentCollections.length">

            <v-icon large class="accent2--text w-40">
                mdi-package-variant-closed
            </v-icon>

            <div class="px-2">
                <h3>Bundle and Save!</h3>
                <p>
                    This title is also available as part of:
                </p>
            </div>
        </v-row>

        <template v-for="(item, index) in documentCollections">
            <v-row
                    align="start"
                    class="d-flex flex-nowrap py-2">

                <v-icon class="accent2--text w-40">
                    mdi-check-circle-outline
                </v-icon>

                <div class="d-flex flex-nowrap justify-space-between w-full">

                    <div class="px-2">
                        <p class="font-weight-bold mb-0">{{ item.title }}</p>
                        <p class="mb-0" v-if="item.title === 'Premium Complete'">Unlock access to nearly {{ premiumCompleteTitlesCount() }}+ titles and more</p>
                    </div>

                    <a class="caption primary--text text-decoration-underline font-weight-bold text-no-wrap"
                       :href="getLandingPageUrl(item)">Learn More</a>

                </div>

            </v-row>
        </template>

        <v-row class="d-flex flex-nowrap justify-space-between">

            <div class="w-40">&nbsp;</div>

            <div class="px-2">
                <a :href="getViewOtherFormatsLink()" target="_blank" class="cdpBlue--text font-weight-bold">
                    View Other Available Print & Digital Formats
                </a>
            </div>

            <v-icon large class="cdpBlue--text">
                mdi-arrow-right
            </v-icon>

        </v-row>

    </v-container>
</template>
<script>
export default {
    props: {
        documentInfo: {
            required: true,
            type: Object
        }
    },
    computed: {
        documentCollections() {
            if (this.documentInfo && Array.isArray(this.documentInfo['collections'])) {
                return this.documentInfo['collections'];
            }

            return [];
        }
    },
    methods: {
        getLandingPageUrl(document) {
            return Routing.generate("collections_landing", {
                documentSlug: document.document_slug
            });
        },
        getViewOtherFormatsLink() {
            return this.documentInfo['document_url'];
        }
    }
}
</script>
<style lang="scss">
.w-full {
  width: 100%;
}
.w-40 {
  min-width: 40px;
}
</style>
