<template>
    <div v-show="showMessage" class="text-sm" :class="{ 'red--text text--accent-4': !countdownremaining }">
        {{ countDownMessage }}
        <v-tooltip top>
            <span v-if="countdownremaining" class="tooltip-content">
                ICC AI Navigator allows users to ask up to 100 questions
                across a 14 day period.<br/>
                You have {{ countdownremaining }} questions remaining within the current period.<br/>
                The next period begins on {{ countdownReset }} with allowance
                of up to 100 additional questions.
            </span>
            <span v-else class="tooltip-content">
                ICC AI Navigator allows user to ask 100 questions across
                each 14 day period.<br/>
                You have used all of your allowed questions for this period,
                but will be granted<br/>
                up to 100 additional questions when the
                next period begins on {{ countdownReset }}.
            </span>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4"
                    :class="{ 'red--text text--accent-4': !countdownremaining }"
                >
                    mdi-information-outline
                </v-icon>
            </template>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        countdownremaining: {
            type: [Number, null],
            required: false
        },
        countdownReset: {
            type: [String, null],
            required: false
        }
    },
    computed: {
        showMessage() {
            return this.countdownremaining !== null && this.countdownremaining <= 10;
        },
        countDownMessage() {
            let message = '';
            if (this.countdownremaining === 0) {
                message = 'You have used all of your allotted questions. New period resets on ' + this.countdownReset;
            } else if (this.countdownremaining !== null && this.countdownremaining <= 10) {
                message = 'You have ' + this.countdownremaining + ' questions remaining';
            }
            return message;
        },
    }
}
</script>
