<!--Alert Component-->
<!--If cookie is provided then dismiss forever will be applied on dismiss click unless dismissible prop is not provided.-->
<!--If dismissible then the elid prop needs to be provied.-->
<!--If the icon prop is not provided then it will default to type icon.-->
<!--title, color and type props are required.-->

<template>
    <v-alert
        :id="getID"
        :class="elclass"
        :value="isVisible"
        :type="type"
        :color="color"
        :dismissible="dismissible"
        :icon="icon">
        {{ title }}
    </v-alert>
</template>

<script>
    export default {
        data: function() {
            return {
                visible: true
            }
        },
        computed: {
            isVisible() {
                // Check to see if cookie is provided.  If so check to see if the alert has been dismissed forever.
                if (this.cookieID) {
                    if (this.$cookies.isKey(this.cookieID)) {
                        return false;
                    }
                }
                return this.visible;
            },
            cookieID() {
                if (this.cookieid) {
                    return 'dismiss-alert-'+this.cookieid;
                }
            },
            getID() {
                if (this.elid) {
                    return this.elid;
                }
            }
        },
        props: {
            elid: {
                type: String,
                required: false
            },
            elclass: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: true
            },
            color: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: true
            },
            dismissible: {
                type: Boolean,
                required: false
            },
            cookieid: {
                type: String,
                required: false
            }
        },
        methods: {
            closeAlert() {
                if (this.cookieID) {
                    this.$cookies.set(this.cookieID, true, Infinity)
                }
                this.visible = false;
            }
        },
        mounted() {
            if (this.dismissible) {
                let _this = this;
                document.getElementById(this.getID).lastChild.addEventListener("click", function(){
                    _this.closeAlert();
                })
            }
        }
    }
</script>
