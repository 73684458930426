var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-container",
      style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
      attrs: { flat: "" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "px-4 py-2" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c("h2", { staticClass: "fs-20 font-weight-bold" }, [
                  _vm._v(
                    "\n                    Premium Bundle\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-n2",
                      attrs: { small: "", icon: "" },
                      on: { click: _vm.setHideCartQue },
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v(
                          "\n                        mdi-close\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-text",
        {
          staticClass: "scrollable-content",
          class: _vm.isMobilePort ? "pt-1 px-2" : "",
        },
        [
          _c("billing-frequency"),
          _vm._v(" "),
          _vm.unavailableTitles.length > 0
            ? _c("div", [
                _c("p", { staticClass: "mb-0 mt-2" }, [
                  _vm._v(
                    "\n                The following title(s) are not available for " +
                      _vm._s(_vm.unavailableFrequenciesText) +
                      " billing frequency:\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.unavailableTitles, function (item) {
                    return _c("li", { key: item.id }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.display_title) +
                          "\n                "
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("license-quantity"),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h4", { staticClass: "pl-1" }, [
                  _vm._v("Titles (" + _vm._s(_vm.getCartItems.length) + ")"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.isMobilePort
                ? "scrollable-content-mobile"
                : "scrollable-content",
            },
            _vm._l(_vm.getCartItems, function (item, key) {
              return _c(
                "v-row",
                {
                  key: key,
                  staticClass: "mx-1 mb-1",
                  staticStyle: { border: "1px solid #E0E0E0" },
                },
                [
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-14 font-weight-bold mb-0 text-truncate",
                        style: _vm.isMobilePort
                          ? "max-width: 275px !important;"
                          : "max-width: 290px !important;",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.display_title) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    item.pricePerLicense
                      ? _c("p", { staticClass: "fs-14 mb-0" }, [
                          _vm._v(
                            "\n                        $" +
                              _vm._s(
                                _vm._f("formatPrice")(item.pricePerLicense)
                              ) +
                              " / " +
                              _vm._s(_vm.billingPeriodShortText) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "black" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.removeItemFromCart(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        mdi-delete-outline\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "action-container" },
        [
          _c(
            "v-container",
            { staticClass: "py-0 px-2" },
            [
              _vm.savingsText.length
                ? _c(
                    "v-sheet",
                    {
                      staticClass: "rounded py-0 px-2 py-1 my-1",
                      attrs: { color: "accent2Light" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-n1",
                                  attrs: { color: "accent2" },
                                },
                                [
                                  _vm._v(
                                    "\n                            mdi-sale-outline\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("h4", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n                            Discounts Applied:\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.savingsText, function (item, key) {
                                return _c("li", { key: key }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.discount) +
                                      "% " +
                                      _vm._s(item.label) +
                                      "\n                            "
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "px-2", attrs: { dense: "" } },
                [
                  _c("v-col", [_c("h2", [_vm._v("Total Price")])]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("h2", [
                      _vm._v(
                        "\n                        $" +
                          _vm._s(
                            _vm._f("formatPrice")(
                              _vm.getIsMember
                                ? _vm.cartTotalPrice.memberTotal
                                : _vm.cartTotalPrice.nonMemberTotal
                            )
                          ) +
                          " / " +
                          _vm._s(_vm.billingPeriodShortText) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.getIsMember
                ? _c(
                    "v-row",
                    { staticClass: "px-2", attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-0 fs-16" }, [
                          _vm._v("ICC Member Price"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "text-decoration-underline",
                            attrs: {
                              target: "_blank",
                              href: _vm.getIccMembershipUrl(),
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Learn about membership\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("p", { staticClass: "mb-0 fs-16" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm._f("formatPrice")(
                                  _vm.cartTotalPrice.memberTotal
                                )
                              ) +
                              " / " +
                              _vm._s(_vm.billingPeriodShortText)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("alert-bar", {
                            staticClass: "mt-2",
                            attrs: {
                              message: _vm.message,
                              "notification-type": _vm.type,
                            },
                            on: {
                              dismiss: function ($event) {
                                ;[(_vm.message = null), (_vm.type = null)]
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "px-2", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", outlined: "" },
                          on: { click: _vm.setHideCartQue },
                        },
                        [
                          _vm._v(
                            "\n                        Close\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            color: "accent2",
                            depressed: "",
                            disabled: !_vm.addToCartButtonEnabled,
                            loading: _vm.cartLoading,
                          },
                          on: { click: _vm.addToCart },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n                            mdi-cart-outline\n                        "
                            ),
                          ]),
                          _vm._v(
                            "\n                        Add to Cart\n                    "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }