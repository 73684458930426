<template>
    <div>
        <p 
            v-if="showMessage" 
            class="text-center black--text emailVerificationSent py-3">
            <v-icon color="black">mdi-check-circle-outline</v-icon> A verification email has been sent to your inbox.
        </p>
        <v-container class="content-container">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h1 class="primary--text font-weight-regular">
                                Verify your email
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 class="primary--text font-weight-regular">
                                One more step to complete
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                Please, check your email for the <b>"ICC Verify your email"</b> letter.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                If you <b>don't see our email</b> in your Primary email folder, 
                                please check all your folders (Promotions, Spam, etc.) 
                                or use the button below.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                :disabled="showMessage"
                                :block="!isMobileAndTablet"
                                :loading="showLoader"
                                large
                                color="primary"                                
                                @click="resendEmail">
                                Resend Verification Email
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col 
                    v-if="!isMobileAndTablet"
                    cols="6">
                    <img
                        max-width="100%"
                        :src="asset(require('../../images/illistrations/key_holder.webp'))"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Mobile from '../inc/mixins/mobile'
    import Forms from '../security/mixins/forms';

    export default {
        data() {
            return {
                showMessage: false,
                email: '',
                showLoader: false
            }
        },
        props: {
            token: {
                required: true,
                type: String
            },
            userEmail: {
                required: true,
                type: String
            },
            error: {
                required: false,
                type: String
            }
        },
        mixins: [
            Forms,
            Mobile
        ],
        methods: {
            resendEmail() {
                this.showLoader = true;
                this.$http.post(Routing.generate('request_reg_code', {'email': this.email,}))
                    .then(response => {
                        if (response.status === 200) {
                            this.showMessage = true;
                            this.showLoader = false;
                            this.gtmEvent('Sign Up', 'Free Trial', 'Resend Code');
                        } else {
                            this.showMessage = false;
                            this.showLoader = false;
                        }
                    })               
            }
        },
        mounted() {
            this.email = this.userEmail;
        }
    }
</script>
<style scoped>

.emailVerificationSent {
    background-color: rgb(160, 222, 187);
    border-color: rgb(160, 222, 187);
}
</style>
