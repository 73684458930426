<template>
    <v-row dense class="mx-2">
        <v-col>
            <h1 class="pl-2">{{ getEnterpriseName }}</h1>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
                enterpriseDetails: {}
            }
        },
        computed: {
            getEnterpriseName(){
                return this.enterpriseDetails.enterpriseName;
            }
        },
        methods: {
            setEnterpriseDetails(enterprise){
                this.enterpriseDetails = enterprise;
            }
        },
        created() {
            EventBus.listen('get-enterprise-details', (data) => this.setEnterpriseDetails(data));
        }
    }
</script>
