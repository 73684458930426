var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { small: "", text: "", disabled: _vm.isDisabled },
      on: { click: _vm.toggleFullScreen },
    },
    [
      _c("v-icon", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.isFullscreenContent ? "mdi-fullscreen-exit" : "mdi-fullscreen"
            ) +
            "\n    "
        ),
      ]),
      _vm._v(
        "\n    " +
          _vm._s(_vm.isFullscreenContent ? "Exit Fullscreen" : "Fullscreen") +
          "\n"
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }