<template>
    <div class="d-flex flex-column fill-height">
        <v-card
            outlined>
            <v-card-text class="pt-0">
                <v-row>
                    <v-col cols="auto" class="d-flex align-center">
                        <h3 class="my-1">
                            Location / I-Codes
                        </h3>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    icon>
                                    <v-icon style="color:black !important;">mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <span class="tooltip-content">Select a US state or "I-Codes" for ICC model code</span>

                        </v-tooltip>

                    </v-col>
                </v-row>
                <navigator-location-selection>
                </navigator-location-selection>
            </v-card-text>
        </v-card>
        <divider-expand class="my-6 flex-grow-1" style="max-width: 95%;">
            Tips for using AI Navigator
            <template v-slot:expand>
                <navigator-tips-block>
                </navigator-tips-block>
            </template>
        </divider-expand>
        <navigator-sidebar-disclaimer-block>
        </navigator-sidebar-disclaimer-block>
    </div>
</template>

<script>
import AssetImage from "../AssetImage.vue";
import NavigatorTipsBlock from "./blocks/NavigatorTipsBlock.vue";
import NavigatorSidebarDisclaimerBlock from "./blocks/NavigatorSidebarDisclaimerBlock.vue";
import NavigatorLocationSelection from "./blocks/NavigatorLocationSelection.vue";
import DividerExpand from "../expands/DividerExpand.vue";

export default {
    components: {
        DividerExpand,
        NavigatorLocationSelection,
        NavigatorSidebarDisclaimerBlock,
        NavigatorTipsBlock,
        AssetImage,
    }
}
</script>
