var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: !_vm.isMobilePort ? "pa-4" : "" }, [
    !_vm.isMobileAndTablet ? _c("h4", [_vm._v("Title Filters")]) : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pa-1" },
      [
        _c(
          "v-btn",
          {
            attrs: {
              small: "",
              block: "",
              dark: "",
              outlined: "",
              depressed: "",
              color: "black",
            },
            on: { click: _vm.resetFilters },
          },
          [_vm._v("\n      Reset Filters\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filters" },
      [
        _c("title-filter-expander", {
          staticClass: "mt-3",
          attrs: { title: "Title Scope", "component-name": "TitleScopePanel" },
        }),
        _vm._v(" "),
        _c("title-filter-expander", {
          staticClass: "mt-3",
          attrs: {
            title: "Categories / Locations",
            "component-name": "CategoriesPanel",
          },
        }),
        _vm._v(" "),
        _c("title-filter-expander", {
          staticClass: "mt-3",
          attrs: { title: "Years", "component-name": "YearsPanel" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }