import AssignModal from './../parts/assign_modal.vue';
import EmailBind from "../mixins/susbcriptions_email";

export default {
    data() {
        return {
            pagination: {
                descending: true,
                page: 1,
                itemsPerPage: 10,
                sortBy: '',
                totalItems: 0,
                rowsPerPageItems: [10, 20, 30, 50, 50, 100, 250]
            },
            subscriptions: [],
            loading: true,
            int: false,
            detailsLoading: true,
            dialog: false,
            errorMessage: '',
            editedIndex: -1,
            oldEmail: '',
            editedItem: {
                assigned_to: {
                    email: ''
                },
                licenses: 0
            },
            reassign_loading: false,
            activeElement: []
        };
    },
    watch: {
        'pagination.page': function () {
            this.getAssignedByMeSubscriptions();
        },
        'pagination.itemsPerPage': function () {
            this.getAssignedByMeSubscriptions();
        }
    },
    components: {
        AssignModal
    },
    mixins: [
        EmailBind
    ],
    methods: {
        getAssignedByMeSubscriptions: function () {
            this.loading = true;
            if (this.int) {
                this.$scrollTo(document.getElementById('license-configuration'), {duration: 0, offset: 66,});
                this.$nextTick(() => {
                    this.subscriptions = [];
                });
            } else {
                this.subscriptions = [];
            }
            let paginationData = _.pick(this.pagination, ['descending', 'page', 'sortBy', 'itemsPerPage']);
            paginationData.rowsPerPage = paginationData.itemsPerPage;
            delete paginationData.itemsPerPage;
            this.$http.post(Routing.generate('assign_by_me_subscription', {
                userId: this.userId,
                pagination: paginationData
            })).then(response => {
                this.subscriptions = response.data.subscriptions;
                this.pagination.totalItems = response.data.resultTotal;
                this.loading = false;
                this.int = true;

                if (_.size(this.activeElement) > 0) {
                    this.checkPanelEvent(this.activeElement);
                }
            });
        },
        triggerDetails(item) {
            if (_.isUndefined(item.assigned_by_me_licenses) || _.size(this.activeElement) > 0) {
                this.detailsLoading = true;
                this.$http.post(Routing.generate('assign_by_me_details', {
                    userId: this.userId,
                    id: item.id
                })).then(response => {
                    let subscriptionIndex = _.findIndex(this.subscriptions, {id: item.id});

                    if(!_.isUndefined(this.subscriptions[subscriptionIndex])) {
                        this.subscriptions[subscriptionIndex].assigned_by_me_licenses = [];
                        if(!_.isUndefined(response.data.assigned_by_me_licenses)) {
                            this.subscriptions[subscriptionIndex].assigned_by_me_licenses = response.data.assigned_by_me_licenses;
                            let parentObjects = _.pick(this.subscriptions[subscriptionIndex], ['expiration_date', 'subscription_type']);
                            _.merge(this.subscriptions[subscriptionIndex].assigned_by_me_licenses[0], parentObjects);
                        }
                    }
                    this.detailsLoading = false;
                });

                this.activeElement = [];
            }
        },
        getExpirationDate(assignment, item) {
            let expirationDate = assignment.expiration_date;
            if (!_.isUndefined(item.subscription) && _.size(item.subscription) > 0) {
                expirationDate = item.subscription.expiration_date;
            }
            return expirationDate;
        },
        bookLink(book) {
            // No click action for premium complete book
            if (!_.isUndefined(book.content_type) && 'Premium Complete' === book.content_type.name) {
                return false;
            }
            if (book.document_slug) {
                window.location.href = Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug
                });
            } else {
                window.location = Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': 'toc'
                });
            }
        },

        getAvailableLicense(licenses){
            let result =0;
            if (licenses)
                licenses.forEach(function(item){
                    result += item.licenses;
                });

            return result;
        },

        checkStatus(expiration_date, status){
            let currentDate = new Date(),
                expirationDate = new Date(expiration_date),
                msg = '';
            if (status != true) {
                msg = 'Revoked';
            } else if (currentDate <= expirationDate){
                msg = 'Active';
            } else {
                msg = 'Expired';
            }
            return msg;
        },

        editItem(item) {
            this.activeElement = item;
            this.oldEmail = this.getAssignedToEmail(item);
            this.editedIndex = this.subscriptions.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true
        },

        close() {
            this.dialog = false;
            this.editedIndex = -1;
        },

        save() {
            this.reassign_loading = true;
            let data = {
                id: this.editedItem.id,
                assigned_to: this.getAssignedToEmail(this.editedItem)
            };
            this.$http.get(Routing.generate('re_assign_subscription', {license: data, userId: this.userId}))
                .then(response => {
                    if(!response.data.id) {
                        this.errorMessage = 'This email does not have an Digital Codes account!';
                    }else{
                        this.errorMessage = '';
                        this.close();
                        this.sendEmailNotification(this.REASSIGN_SUBSCRIPTION, {
                            id: response.data.id,
                            emails: this.oldEmail
                        });
                        this.$session.set('switch-to-license-tab', 'AssignedByMe');
                        this.reloadSubscriptions();
                    }
                    this.reassign_loading = false;
                });
        },

        deleteItem(item) {
            this.activeElement = item
            confirm('Are you sure you want to unassign this license ?') &&
            this.$http.get(Routing.generate('remove_assigned_subscription', {'license' : item.id}))
                .then(response => {
                    this.sendEmailNotification(this.REVOKE_SUBSCRIPTION, {
                        id: response.data.id
                    });
                    this.close();
                    this.$session.set('switch-to-license-tab', 'AssignedByMe');
                    this.reloadSubscriptions();
                });
        },
        openAssignModal(){
            this.$set(this.$refs.assigned_by_me_table.expanded, false);
            EventBus.fire('show-modal-assign-subscription');
        },
        getLicenseUsageReport: function (sku) {
            this.$http.get(Routing.generate('assigned_by_me_license_logs', {sku : sku, userId: this.userId}))
                .then(response => {
                    this.exportCsv(response.data, 'assignment');
                });
        },
        getAssignedToEmail(item){
            return item.assigned_to ? item.assigned_to.email : item.pending_user_email;
        },
        checkPanelEvent(panelKey){
            if(!this.panel.length){
                this.panel = [...Array(this.subscriptions.length).keys()].map(_ => false);
            }
            let array = [];
            this.panel.forEach(function(item, key){
                array[key] = (key === panelKey.index) ? !item : item;
            });

            this.triggerDetails(panelKey);
            this.panel = array;
        }
    },
    created() {
        EventBus.listen('change-panel-assign-by-me', () => this.getAssignedByMeSubscriptions());
    },
}
