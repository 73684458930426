<template>
    <div>
        <template v-for="(navigationItem, a) in $store.getters.getSearchNavigationCategories">
            <p class="mb-0 caption px-4 grey white--text darken-2">{{a}}</p>
            <v-treeview
                ref="treeview"
                activatable
                :active="[activeCategory]"
                :open="openNodes"
                v-model="category"
                :items="navigationItem"
                item-key="key">
                <div slot="label" slot-scope="{ item, active }" @click.stop="setTreeCategory(item);">
                    <a :href="getCategoryUrl(item)" @click.self.prevent>
                        {{ item.name }}
                    </a>
                </div>
            </v-treeview>
        </template>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                category: [],
                navigation: {},
                navigationStyles: {},
                openNodes: [],
                activeCategory: 'all-titles'
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            setTreeCategory(catObject) {
                let data = {
                    resetPage: true,
                    query: {
                        searchTerm: '',
                        category: catObject.name,
                        astmSubject: null,
                        year: this.$store.getters.getSearchNavigationCategoryNames.includes(catObject.name)
                            ? null
                            : 'Current Adoption'
                    }
                }
                this.defaultSearch = '';
                EventBus.fire('update-search-data', data);
            },
            formatValue(category) {
                return _.kebabCase(category);
            },
            checkAstmPage(slug) {
                if('astm-standards' === slug &&
                   window.location.pathname.includes('astm-standards/')
                ) {
                    window.location = this.getRoute('codes_category',{'category': slug});
                }
            },
            getCategoryUrl(item) {
                return this.getRoute('codes_category', {
                    'category': item.slug
                });
            },
            syncOpenNodes() {

                this.openNodes = [];

                const searchNavigationCategories = this.$store.getters.getSearchNavigationCategories;

                // find parent node that have this child item
                for (const name in searchNavigationCategories) {

                    /** @type Array */
                    let items = searchNavigationCategories[name];

                    const itemMatch = items.find((value) => {
                        return _.find(value.children, {'key': this.activeCategory}) !== undefined;
                    });

                    if (itemMatch) {
                        this.currentCategoryPath = '/codes/'+ itemMatch.key + '/' + this.titleSearchCategory;
                        this.openNodes = [itemMatch.key];
                        break;
                    } else {
                        this.currentCategoryPath = '/codes/' + this.titleSearchCategory;
                    }
                }
            },
            scrollActiveItemIntoView(sendPageView = true) {

                this.$nextTick(() => {
                    const activeNode = document.querySelector('.v-treeview-node--active');

                    if (activeNode) {
                        activeNode.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest'
                        });

                        if(sendPageView) {
                            EventBus.fire('send-page-view', this.currentCategoryPath);
                        }
                    }
                });
            },
            updateActiveCategoryFromUrl() {
                const location = window.location.pathname;

                const parts = location.split("/");

                if (parts.length > 2) {
                    this.activeCategory = parts[parts.length - 1];
                } else {
                    this.activeCategory = 'all-titles';
                }
            }
        },
        created() {

            EventBus.listen('location:changed', (data) => {
                this.updateActiveCategoryFromUrl();
            });

            this.updateActiveCategoryFromUrl();

            this.$nextTick(() => {
                this.syncOpenNodes();
                this.scrollActiveItemIntoView();
            });

            // doing it like this provides an autocomplete
            // titleSearchCategory could be changed from outside this component
            this.$watch(() => this.titleSearchCategory, () => {

                // if this was a child item, find a parent item belonging to it, and "open" it first!
                this.syncOpenNodes();
                this.scrollActiveItemIntoView();
            }, {
                immediate: true
            });
        }
    }
</script>
