var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.data, function (items) {
        return [
          _c(
            "v-container",
            { staticClass: "grey lighten-3 px-6" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  items.categoryLogo
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.asset(
                                "/uploads/PremiumContentReportTag/category_logos/" +
                                  items.categoryLogo
                              ),
                              "max-width": "30px",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("h3", { staticClass: "font-weight-regular" }, [
                      _vm._v(_vm._s(items.categoryName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("p", { staticClass: "caption mb-0 font-weight-bold" }, [
                      _vm._v("\n                        ( "),
                      _c("span", { staticClass: "error--text" }, [_vm._v("*")]),
                      _vm._v(
                        " sign indicates the report as featured. )\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(items.category, function (reportTag) {
            return [
              _c(
                "v-container",
                { staticClass: "px-6" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          reportTag.tag.content_type.name === "PDF"
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "primary--text",
                                    attrs: {
                                      href: _vm.getReportLink(reportTag),
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("h3", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(reportTag.tag.title) +
                                          "\n                                    "
                                      ),
                                      reportTag.tag.is_featured
                                        ? _c(
                                            "span",
                                            { staticClass: "error--text" },
                                            [
                                              _vm._v(
                                                "\n                                        *\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]
                            : [
                                _c(
                                  "a",
                                  {
                                    staticClass: "primary--text",
                                    attrs: {
                                      href: reportTag.tag.content,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("h3", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(reportTag.tag.title) +
                                          "\n                                    "
                                      ),
                                      reportTag.tag.is_featured
                                        ? _c(
                                            "span",
                                            { staticClass: "error--text" },
                                            [
                                              _vm._v(
                                                "\n                                        *\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ],
                          _vm._v(" "),
                          _c("p", { staticClass: "caption mb-0 font-italic" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.getContentTypeText(
                                    reportTag.tag.content_type.name
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }