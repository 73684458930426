var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.booksInfo.length === 1
    ? _c(
        "v-chip",
        {
          staticClass: "primary--text",
          attrs: { small: "", color: "primaryLight" },
          on: {
            click: function ($event) {
              _vm.answerViewPage
                ? _vm.goToRoute("premium_answers", {
                    document: _vm.booksInfo[0].id,
                  })
                : _vm.$emit("titleClick", _vm.booksInfo[0].id)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.booksInfo[0].title) + "\n")]
      )
    : _c(
        "v-menu",
        {
          attrs: { absolute: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-chip",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", color: "primaryLight" },
                        },
                        "v-chip",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.booksInfo[0].title) +
                          " " +
                          _vm._s(
                            _vm.booksInfo.length > 1 &&
                              `+ ${_vm.booksInfo.length - 1} more`
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            _vm._l(_vm.booksInfo, function (item, index) {
              return _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-title",
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "primary--text",
                          attrs: { small: "", color: "primaryLight" },
                          on: {
                            click: function ($event) {
                              _vm.answerViewPage
                                ? _vm.goToRoute("premium_answers", {
                                    document: item.id,
                                  })
                                : _vm.$emit("titleClick", item.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.title) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }