<template>
    <div>
        <loading-linear v-if="loading">
        </loading-linear>
        <div
            v-else
            v-html="html"
            id="contentParentModal"
            :class="cssWrapper">
        </div>
    </div>
</template>

<script>
import {TagsApi} from "../../../api/TagsApi";

import LoadingLinear from "../../loaders/LoadingLinear.vue";

export default {
    components: {
        LoadingLinear
    },
    data() {
        return {
            html: '',
            cssWrapper: 'newIccStyles',
            loading: true
        }
    },
    props: {
        tagData: {
            type: Object,
            required: true
        }
    },
    methods: {
        async getResults() {
            this.loading = true
            const results = await TagsApi.getXMLId(this.tagData.tag.content_id)
            this.html = results.data.section_text
            this.cssWrapper = results.data.css_wrapper
            this.loading = false
            this.$nextTick(() => {
                let container = document.getElementById('contentParentModal');
                if (container) {
                    let links = container.getElementsByTagName('a');
                    links = Array.from(links);
                    _.forEach(links, function(link) {
                        if (!link.classList.contains('relocated_to') && !link.classList.contains('new_location')) {
                            let linkText = document.createTextNode(link.text);
                            link.parentNode.insertBefore(linkText, link);
                        }
                        if (link) {
                            link.text = ''
                            link.removeAttribute("href")
                        }
                    });
                }
            });
        }
    },
    mounted() {
        this.getResults()
    }
}
</script>
