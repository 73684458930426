<template>
    <div>
        <search-header
            :total-results="pagination.totalResults"
            :show-results-text="results.length > 0  && !loading"
            :search="activeSearch"
            :sort="sort"
            :filters="filters"
            :loading="loading"
            @clearSearch="(key) => clearSearch(key)"
            @sortChange="(data) => updateSort(data)"
            @showModal="() => dialog = true">
        </search-header>
        <v-row>
            <v-col>
                <v-row v-if="loading">
                    <v-col>
                        <v-card outlined>
                            <v-card-text>
                                <loading-linear>
                                </loading-linear>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <content-search-results
                    v-else-if="hasInput"
                    :search-results="results"
                    :loading="loading"
                    :search="activeSearch"
                    :did-you-mean="didYouMean">
                </content-search-results>
                <missing-search-terms v-else>
                </missing-search-terms>
            </v-col>
        </v-row>
        <v-row v-if="pagination.totalResults && !loading">
            <v-col>
                <pagination-pages
                    :pagination="pagination"
                    @changed="(value) => updatePage(value)">
                </pagination-pages>
            </v-col>
        </v-row>
        <share-section-modal :key="shareModalKey">
        </share-section-modal>
        <notes-modal>
        </notes-modal>
        <v-dialog
            v-model="dialog"
            fullscreen
            scrollable>
            <v-card>
                <v-card-title>
                    <h3>Content Filters</h3>
                    <v-spacer></v-spacer>
                    <v-icon
                        @click="dialog = false">
                        close
                    </v-icon>
                </v-card-title>
                <v-card-text class="pa-4">
                    <content-search-filters
                        :filters="filters">
                    </content-search-filters>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col>
                            <v-btn
                                block
                                outlined
                                @click="dialog = false"
                                color="primary">
                                Close
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Url from '../../inc/mixins/url';
    import SearchHeader from "./SearchHeader.vue";
    import {ContentSearchApi} from "../../../api/ContentSearchApi";
    import ContentSearchResults from "./Results.vue";
    import LoadingLinear from "../../loaders/LoadingLinear.vue";
    import PaginationPages from "../../pagination/PaginationPages.vue";
    import MissingSearchTerms from "./MissingSearchTerms.vue";
    import {SuperAxios} from "../../../api/SuperAxios";
    import {ErrorHandler} from "../../../classes/ErrorHandler";
    import ContentSearchFilters from './filters/ContentSearchFilters.vue';
    import ShareSectionModal from "../../modals/ShareSection.vue";
    import NotesModal from "../../titles/notesModal/index.vue";
    import {EventNames} from "../../../classes/EventNames";

    export default {
        components: {
            ShareSectionModal,
            NotesModal,
            SearchHeader,
            MissingSearchTerms,
            PaginationPages,
            LoadingLinear,
            ContentSearchResults,
            ContentSearchFilters
        },
        data() {
            return {
                activeSearch: {
                    exact: '',
                    any: '',
                    all: '',
                    none: '',
                    proximity: '',
                    proximityWithin: ''
                },
                filters: {
                    categories: [],
                    years: [],
                    titles: [],
                    types: [],
                    sources: [],
                    advancedSearch: []
                },
                sort: 'relevance',
                loading: true,
                results: [],
                didYouMean: [],
                pagination: {
                    page: 1,
                    pages: 0,
                    disabled: false,
                    totalResults: 0
                },
                dialog: false,
                shareModalKey: 0,
                initialSearch: false
            }
        },
        computed: {
            hasInput() {
                return Object.values(this.activeSearch).some(value => value !== '')
            }
        },
        mixins: [Url],
        methods: {
            clearSearch(key = null) {
                this.results = []
                this.pagination = {
                    page: 1,
                    pages: 0,
                    disabled: false,
                    totalResults: 0
                }
                if (key) {
                    this.activeSearch[key] = ''
                    if (key === 'any') {
                        EventBus.fire('set-default-search', '')
                    }
                    EventBus.fire('clear-advanced-search', key)
                    this.getResults()
                } else {
                    this.activeSearch = {
                        exact: '',
                        any: '',
                        all: '',
                        none: '',
                        proximity: '',
                        proximityWithin: ''
                    }
                    EventBus.fire('set-default-search', '')
                    EventBus.fire('clear-advanced-search')
                }
            },
            updateSort(sort) {
                this.sort = sort
                this.getResults()
            },
            updatePagination(value) {
                const maxPages = Math.floor(10000 / 20);
                return {
                    page: Number(value.currentPage),
                    pages: Math.min(maxPages, Number(value.maxPerPage)),
                    disabled: this.pagination.pages > 1,
                    totalResults: Number(value.totalResults)
                }
            },
            updateSearch(data) {
                this.activeSearch = data
                EventBus.fire('set-advanced-search', data)
                this.getResults()
                EventBus.fire('get-relevant-titles-results', data.any);
            },
            updatePage(value) {
                this.pagination.page = value
                this.getResults()
            },
            updateFilters(filters) {
                if (filters.key === 'types') {
                    this.filters['sources'] = filters.value.sources
                    this.filters['types'] = filters.value.types
                } else {
                    this.filters[filters.key] = filters.value
                }
                if (filters.performSearch) {
                    this.getResults()
                }
            },
            async getResults(sid = null) {
                if (!this.hasInput && !sid) {
                    // Initialize search for those who come in from Advanced search button.
                    this.loading = false
                    this.initialSearch = true
                    return false
                }
                this.loading = true
                let getNotes = false
                window.scrollTo(0, 0)
                try {
                    // Set the tabbed search value for content search so we can identify if we need to refresh the data on tab change.
                    this.$store.commit('setTabbedSearchValues', {
                        tab: this.$getConst('contentSearchType'),
                        searchValue: this.activeSearch.any
                    });
                    const response = await ContentSearchApi.getResults({
                        sid: sid,
                        params: this.filters['advancedSearch'],
                        category: this.filters['categories'].map(category => category.name),
                        contentSource: this.filters['sources'].map(source => source.id),
                        contentType: this.filters['types'].map(type => type.id),
                        title: this.filters['titles'].map(title => title.id),
                        year: this.filters['years'].map(year => year.name),
                        sort: this.sort,
                        limit: this.limit,
                        page: this.pagination.page,
                        explain: this.$store.getters.getDebugShowResultScores
                    })
                    const {data, pagination, didYouMean, search} = response.data
                    if(this.$session.has('searchedDocument')) {
                        this.$session.remove('searchedDocument');
                    }
                    if (data) {
                        this.results = data
                        getNotes = true
                    }
                    if (didYouMean) {
                        this.didYouMean = didYouMean
                    }
                    if (pagination) {
                        this.pagination = this.updatePagination(pagination)
                    }
                    this.loading = false
                    if (search) {
                        this.$store.commit('setSearchId', search.id);
                        window.history.pushState(search,
                            "Content Search results",
                            '?' + search.urlParams
                        );
                        // Update our store with the search URL so we can use it for the tabbed search while navigating.
                        this.$store.commit('setTabbedSearchUrl', {
                            tab: this.$getConst('contentSearchType'),
                            searchUrl: search.urlParams
                        });
                        await this.updateSearchFromResponse(search);
                    }
                    if (getNotes) {
                        await this.fetchUserNotes()
                    }
                } catch (error) {
                    // Todo: Handle this once we have the error banner
                    this.loading = false
                }
                this.initialSearch = true
            },
            async updateSearchFromResponse(data) {
                const query = data['query'];
                let advancedSearchObject = {
                    exact: query.searchTermExact,
                    any: query.searchTermAny,
                    all: query.searchTermAll,
                    none: query.searchTermNone,
                    proximity: query.searchTermProximity,
                    proximityWithin: query.searchTermProximityWithin
                };
                // {...advancedSearchObject} is to prevent object from being observable, because it will
                // always change its state and send wrong info to the request. This is
                // related to filters being able to be applied independently from eachother
                this.filters = {
                    categories: query.categoriesWithNames,
                    years: query.years,
                    titles: query.titlesWithNames,
                    types: query.contentTypes,
                    sources: query.contentSources,
                    advancedSearch: {...advancedSearchObject}
                }
                this.activeSearch = advancedSearchObject
                EventBus.fire('set-default-search', query.searchTermAny)
                EventBus.fire('set-advanced-search', this.activeSearch)
                EventBus.fire('set-title-filter', query.titlesWithNames)
                EventBus.fire('set-category-filter', query.categoriesWithNames)
                EventBus.fire('set-year-filter', query.years)
                EventBus.fire('set-content-type-filter', {
                    sources: query.contentSources,
                    types: query.contentTypes
                })
            },
            setSearchFromUrl() {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const search = {
                    exact: urlParams.get('searchTermExact') || '',
                    any: urlParams.get('searchTermAny') || '',
                    all: urlParams.get('searchTermAll') || '',
                    none: urlParams.get('searchTermNone') || '',
                    proximity: urlParams.get('searchTermProximity') || '',
                    proximityWithin: urlParams.get('searchTermProximityWithin') || ''
                }
                // {...search} is to prevent object from being observable, because it will
                // always change its state and send wrong info to the request. This is
                // related to filters being able to be applied independently from eachother
                EventBus.fire('update-content-search-filters', {key: 'advancedSearch', value: {...search}});
                this.activeSearch = search;
                if (this.activeSearch.any) {
                    EventBus.fire('set-default-search', this.activeSearch.any)
                }
            },
            fireResults(search) {
                if (this.initialSearch) {
                    if (search) {
                        this.activeSearch.any = search
                        EventBus.fire('update-content-search-filters', {key: 'advancedSearch', value: {...search, any: search}});
                        EventBus.fire('set-default-search', search)
                    }
                    if (this.hasInput) {
                        this.getResults();
                    }
                }
            },
            fetchUserNotes() {
                const uri = Routing.generate('get_annotation_bookmarks', {'action': 'content_search_notes'});

                return new Promise((resolve) => {

                    SuperAxios.getOnce(uri)
                        .then((response) => {

                            const {annotations, bookmarks} = response.data;

                            this.$store.commit('setAnnotations', annotations);
                            this.$store.commit('setBookmarks', bookmarks);
                        })
                        .catch(ErrorHandler.handle)
                        .finally(() => {
                            resolve();
                        });
                });
            },
        },
        created() {

            EventBus.listen(EventNames.INIT_CONTENT_SEARCH, () => {
                this.setSearchFromUrl();
                let sid
                if (this.$session.has('searchedDocument')) {
                    let document = this.$session.get('searchedDocument');
                    this.searchTitles = [
                        {
                            id: document.master_document_id ?
                                document.master_document_id :
                                document.document_id,
                            name: document.title
                        }
                    ];
                    this.filters['titles'] = this.searchTitles;
                } else {
                    const urlSearchParams = new URLSearchParams(window.location.search);
                    sid = urlSearchParams.get('sid');
                }
                this.getResults(sid)
            });
            EventBus.listen('update-content-search-results', (search) => this.fireResults(search));
            EventBus.listen('update-content-search-filters', (filters) => this.updateFilters(filters));
            EventBus.listen('reset-content-search-terms', () => this.clearSearch());
            EventBus.listen('force-rerender-share-sections', () => this.shareModalKey += 1);
            EventBus.listen('update-notes', () => this.fetchUserNotes());
            EventBus.listen('update-advanced-search', (value) => this.updateSearch(value));
        }
    }
</script>