<template>
    <v-sheet>
        <v-container>
            <v-row
                dense
                align="center">
                <v-col>
                    <v-text-field
                        v-model="search"
                        dense
                        outlined
                        clearable
                        hide-details
                        :placeholder="`Search ${label}`">
                    </v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-sheet max-height="250px">
            <v-list class="pt-0">
                <v-data-iterator
                    :items="filters"
                    hide-default-footer
                    :search="search"
                    item-key="name"
                    :items-per-page="-1">
                    <template v-slot:default="{ items }">
                        <v-list-item
                            v-for="(item, key) in items"
                            :key="key"
                            @click="activateFilter(item, filterKey)">
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    <template v-slot:no-results>
                        <p class="caption">
                            No results found
                        </p>
                    </template>
                </v-data-iterator>
            </v-list>
        </v-sheet>
    </v-sheet>
</template>

<script>
    export default {
        data() {
            return {
                search: ''
            }
        },
        props: {
            label: {
                required: true,
                type: String
            },
            filterKey: {
                required: true,
                type: String
            },
            filters: {
                required: true,
                type: Array
            }
        },
        methods: {
            activateFilter(name, key) {
                this.search = '';
                this.$emit('updateActive', name, key);
                this.$emit('closeMenu');
            }
        }
    }
</script>
