var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        { attrs: { text: "", small: "" }, on: { click: _vm.showLegendModal } },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("\n            mdi-information-outline\n        "),
          ]),
          _vm._v("\n        Legend\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.isMobilePort,
            scrollable: "",
            "max-width": 1200,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v(
                    "\n                Legend Information\n                "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      !_vm.loading
                        ? [
                            _c("v-col", [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.legendContent),
                                },
                              }),
                            ]),
                          ]
                        : [
                            _c(
                              "v-col",
                              { staticClass: "text-center py-12" },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }