import Routing from '../../../../../public/bundles/fosjsrouting/js/router.min.js';
import Constants from "../../../constants";

export default {
    persona1: {
        headerSection: {
            title: 'An Easier ICC Certification Exam Experience',
            subtitle: 'Use Digital Codes during your next PRONTO exam.',
            button: {
                text: 'Sign In or Register to learn more',
                link: Routing.generate('signup'),
                target: '_blank'
            },
            image: {
                src: 'pronto_tablet_front.webp',
                class: '',
                width: '681',
                height: '381'
            }
        },
        featureSection: {
            title: '<b>More convenient, more affordable, more accessible</b>',
            description: '<p>No need to get physical copies of all reference titles before the exam. With Digital Codes for Exams, many of the reference titles are provided at no charge.</p> <p>Preview the exam experience! <a target="_blank" href="'+Routing.generate('security_login')+'">Sign in</a> or <a target="_blank" href="'+Routing.generate('signup')+'">register</a> for more details.</p>',
            displayType: '',
            features: []
        },
        featureSection1: {
            title: 'What Is a <b>PRONTO Exam ?</b>',
            description: '<p>PRONTO is an online proctored exam for ICC certification. Take your ICC exam from any secure location with high speed internet access. There’s no waiting period and it’s available 24/7.</p> <p>ICC Certifications are more convenient than ever with <a target="_blank" href="https://www.iccsafe.org/professional-development/assessment-center/proctored-remote-online-testing/">PRONTO</a></p>',
            displayType: 'list',
            features: [
                {
                    title: 'High speed',
                    description: 'Take your ICC Certification exam from any secure location with high speed internet access.',
                    src: 'high-speed.webp'

                },
                {
                    title: '24/7',
                    description: 'Plan your exam for the day and time most convenient for you—PRONTO is available 24/7.',
                    src: '24-7.webp'
                },
                {
                    title: 'Integrity',
                    description: 'With advanced exam security features you can be confident in the integrity of the testing process and exam results.',
                    src: 'integrity.webp'
                },
                {
                    title: 'Fast',
                    description: 'Know your results immediately upon exam completion.',
                    src: 'fast.webp'
                }
            ]
        },
        footerSection: {
            title: '<b>Get the 14 day free trial for Premium Complete</b>',
            subtitle: '<b>what will you have access to?</b>',
            features: [
                {
                    id: 1,
                    title: 'Learning and training content for upcomming exams includes study companions, essentials,and significant code changes.'
                },
                {
                    id: 2,
                    title: 'Study guides includes notes and flash cards.'
                }
            ],
            imageWeb: {
                src: 'digital-codes-ICC-phones-1.webp'
            },
            imageMobile: {
                src: 'digital-codes-ICC-phones-2.webp'
            },
            button: {
                text: 'Get Started Today',
                target: '_self',
                link: Routing.generate("trial_signup")
            }
        }
    },
    persona2: {
        headerSection: {
            title: 'Test Drive Digital Codes for Your ICC Certification Exam ',
            subtitle: 'Preview the Digital Codes exam experience and take the first step towards your next exam.',
            image: {
                src: 'pronto_tablet_front.webp',
                class: '',
                width: '681',
                height: '381'
            },
        },
        examTipsSection: {
            title: 'Before you take your next certification exam:',
            features: [
                {
                    id: 1,
                    description: '<p style="margin-bottom: 40px">1. Test drive the Digital Codes exam interface and familiarize yourself with it.</p>',
                    buttonText: 'Learn More',
                    link: '#exam_resources',
                    target: '_self'
                },
                {
                    id: 1,
                    description: '2. Browse the exam catalog and purchase your next certification exam. Take it whenever you’re ready!',
                    buttonText: 'Purchase Your Exam Today',
                    link: Constants.links.iccSafeExamCatalog,
                    target: '_blank'
                },
            ],
        },
        featureSection: {
            title: 'Preview the Exam Experience',
            description: '<p>Access <a target="_blank" href="'+Constants.links.shopPronto101+'">PRONTO 101</a> and Simulate Exam Experience with Digital Codes to get familiar with the exam experience ahead of time.</p>',
            displayType: '',
            features: []
        },
        footerSection: {
            title: '<b>Get the 14 day free trial for Premium Complete</b>',
            browseCodesTitle: '<b>Check out all the Digital Codes Premium testing resources available</b>',
            subtitle: '<b>what will you have access to?</b>',
            features: [
                {
                    id: 1,
                    title: 'Learning and training content for upcomming exams includes study companions, essentials,and significant code changes.'
                },
                {
                    id: 2,
                    title: 'Study guides includes notes and flash cards.'
                }
            ],
            imageWeb: {
                src: 'digital-codes-ICC-phones-1.webp'
            },
            imageMobile: {
                src: 'digital-codes-ICC-phones-2.webp'
            },
            button: {
                text: 'Get Started Today',
                target: '_self',
                link: Routing.generate("trial_signup")
            },
            buttonBrowseCodes: {
                text: 'Access Study Materials',
                target: '_self',
                link: '#exam_resources'
            }
        }
    },
    persona3: {
        headerSection: {
            title: 'Take Your ICC Certification Exam with Digital Codes',
            subtitle: 'Ready to take your exam within PRONTO? Here’s how it works.',
            image: {
                src: 'pronto_tablet_front.webp',
                class: '',
                width: '681',
                height: '381'
            }
        },
        featureSection: {
            title: 'Get access to the <b>Exam Preparation</b> Content with Digital Codes Premium',
            description: 'You can access an exam tutorial within PRONTO before exam day, so you can feel confident and prepared for the exam.',
            displayType: '',
            features: []
        },
        examTipsSection: {
            title: 'Get ready for your exam with these tips!',
            features: [
                {
                    id: 1,
                    title: 'Check your WiFi connection, hard-wired preferred',
                    description: '<ul><li>Find a quiet room and be prepared to be on camera during the exam</li><li>Perform a systems check</li><li>Make sure webcam and microphone are working properly</li><li>Remove multiple monitors prior to exam</li></ul>'
                },
                {
                    id: 2,
                    title: 'What to expect the day of the exam',
                    description: '<ul><li>Be prepared with valid government-issued photo identification.</li><li>You will have a virtual notepad and calculator.</li><li>Once you submit, you will receive immediate feedback on the pass/fail results.</li></ul>'
                },
            ],
        },
        footerSection: {
            title: '<b>Get the 14 day free trial for Premium Complete</b>',
            browseCodesTitle: '<b>Try Premium Complete for 14 days and prepare for your certification</b>',
            subtitle: '<b>what will you have access to?</b>',
            features: [
                {
                    id: 1,
                    title: 'Learning and training content for upcomming exams includes study companions, essentials,and significant code changes.'
                },
                {
                    id: 2,
                    title: 'Study guides includes notes and flash cards.'
                }
            ],
            imageWeb: {
                src: 'pc_computer.webp'
            },
            imageMobile: {
                src: 'pc_computer.webp' // If needs to be changed in future
            },
            button: {
                text: 'Start Free Trial',
                target: '_self',
                link: Routing.generate("trial_signup")
            },
            buttonBrowseCodes: {
                text: 'Browse Resources',
                target: '_self',
                link: Routing.generate("codes_category", { 'category': 'resources' })
            }
        }
    },
    persona4: {
        headerSection: {
            title: 'Take Your ICC Certification Exam with Digital Codes',
            subtitle: 'Ready to take your exam within PRONTO? Here’s how it works.',
            image: {
                src: 'pronto_tablet_front.webp',
                class: '',
                width: '681',
                height: '381'
            }
        },
        featureSection: {
            title: 'Get access to the <b>Exam Preparation</b> Content with Digital Codes Premium',
            description: 'You can access an exam tutorial within PRONTO before exam day, so you can feel confident and prepared for the exam.',
            displayType: '',
            features: []
        },
        examTipsSection: {
            title: 'Get ready for your exam with these tips!',
            features: [
                {
                    id: 1,
                    title: 'Check your WiFi connection, hard-wired preferred',
                    description: '<ul><li>Find a quiet room and be prepared to be on camera during the exam</li><li>Perform a systems check</li><li>Make sure webcam and microphone are working properly</li><li>Remove multiple monitors prior to exam</li></ul>'
                },
                {
                    id: 2,
                    title: 'What to expect the day of the exam',
                    description: '<ul><li>Be prepared with valid government-issued photo identification.</li><li>You will have a virtual notepad and calculator.</li><li>Once you submit, you will receive immediate feedback on the pass/fail results.</li></ul>'
                },
            ],
        },
        footerSection: {
            title: '<b>Upgrade Your Exam Prep with Digital Codes Premium</b>',
            browseCodesTitle: '<b>Check out all the Digital Codes Premium testing resources available</b>',
            subtitle: '<b>Your subscription to Digital Codes Premium helps you boost your exam preparation*:</b>',
            features: [
                {
                    id: 1,
                    title: 'Learning and training content for upcoming exams, including study companions, essentials, and significant code changes'
                },
                {
                    id: 2,
                    title: 'Study guides, including notes and flash cards'
                }
            ],
            imageWeb: {
                src: 'pc_computer.webp'
            },
            imageMobile: {
                src: 'pc_computer.webp' // If needs to be changed in future
            },
            button: {
                text: 'Start Free Trial',
                target: '_self',
                link: Routing.generate("trial_signup")
            },
            buttonBrowseCodes: {
                text: 'Access Study Materials',
                target: '_self',
                link: Routing.generate("codes_category", { 'category': 'resources' })
            }
        }
    }
}
