export class DomUtils {

    /**
     *
     * @param {string} name
     * @return {{}|string|null}
     */
    static getMetaContents(name) {

        const metaElement = document.querySelector('meta[name=' + name + ']');

        if (metaElement) {
            const content = metaElement.getAttribute('content');

            try {
                return JSON.parse(content);
            } catch (e) {

            }

            return content;
        }

        return null;
    }

    /**
     *
     * @param selector
     * @param {number} offsetTop
     * @return {null|HTMLElement}
     */
    static findFirstVisibleElement(selector, offsetTop = 0) {

        let result = null;
        let smallestTop = null;

        const elements = document.querySelectorAll(selector);
        for (const element of elements) {
            const rect = element.getBoundingClientRect();

            // negative value means that the top of the element is scrolled out of view at the top of the viewport
            const elementTopIncludingOffset = rect.top - offsetTop;

            // find element with smallest POSITIVE top offset
            if (elementTopIncludingOffset >= 0) {

                if (smallestTop === null || elementTopIncludingOffset < smallestTop) {
                    smallestTop = elementTopIncludingOffset;
                    result = element;
                }
            }
        }

        return result;
    }

    static updatePageTitle(newPageTitle) {
        // TODO: modify actual <title>
        document.title = newPageTitle;
    }
}
