var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: _vm.center ? "center" : null } },
    _vm._l(this[_vm.type], function (item, key) {
      return _c("v-col", { key: key, attrs: { cols: "auto" } }, [
        _c(
          "h3",
          {
            staticClass: "font-weight-bold",
            class: _vm.isMobilePort && "caption",
          },
          [
            _c("v-icon", { staticClass: "mb-1", attrs: { color: "accent2" } }, [
              _vm._v(
                "\n                " + _vm._s(item.icon) + "\n            "
              ),
            ]),
            _vm._v("\n            " + _vm._s(item.title) + "\n        "),
          ],
          1
        ),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }