<template>
    <div v-if="!loading">
        <span v-if="categoryContentCount > 1">
            <v-tabs
                centered
                v-model="tab"
                background-color="white"
                color="primary"
                :height="20"
                :show-arrows="false"
                class="mt-5">
                <v-tab
                    v-for="(item, index) in categoryContent"
                    :key="index">
                        {{ $getConst('categoryTabs')[index] }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(item, index) in categoryContent"
                    :key="index">
                    <v-card class="link-color"
                        flat>
                        <v-card-text v-if="index === 'info'">
                            <v-row>
                                <v-col class="text-center">
                                    <img
                                        v-if="item.backgroundSeal"
                                        :alt="item.state"
                                        :title="item.state"
                                        :src="asset(item.backgroundSeal)"/>
                                    <h3
                                        v-if="'ASTM' !== categoryContent.info.state"
                                        v-html="item.state"
                                        class="primary--text my-3">
                                    </h3>
                                    <astm-sidebar v-if="isChildCategoryPage">
                                    </astm-sidebar>
                                    <div v-html="item.introText">
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else-if="index === 'contacts'">
                            <v-row
                                v-for="(results, index) in item"
                                :key="index">
                                <v-col>
                                    <h4 class="primary--text mb-2">{{ results.office }}</h4>
                                    <p class="mb-0">{{ results.name }}</p>
                                    <p class="mb-0" v-if="results.address">{{ results.address }}</p>
                                    <p class="mb-0" v-if="results.address2">{{ results.address2 }}</p>
                                    <p class="mb-0" v-if="results.city && results.state && results.zipcode">{{ results.city }}, {{ results.state }} {{ results.zipcode }}</p>
                                    <p class="mb-0" v-if="results.phone">{{ results.phone }}</p>
                                    <p class="mb-0" v-if="results.fax">{{ results.fax }}</p>
                                    <p class="mb-0" v-if="results.url"><a :href="results.url">{{ results.url }}</a></p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else-if="index === 'links'">
                            <v-row
                                dense
                                v-for="(results, index) in item"
                                :key="index">
                                <v-col>
                                    <p class="mb-0"><a :href="results.url" target="_blank">{{ results.title }}</a></p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </span>
        <span v-else>
            <v-card
                flat>
                <v-card-text v-if="tab === 'info'">
                    <v-row>
                        <v-col class="text-center">
                            <img
                                v-if="categoryContent.info.backgroundSeal"
                                :alt="categoryContent.info.state"
                                :title="categoryContent.info.state"
                                :src="asset(categoryContent.info.backgroundSeal)"/>
                            <h3
                                v-if="'ASTM' !== categoryContent.info.state"
                                v-html="categoryContent.info.state"
                                class="primary--text my-3">
                            </h3>
                            <div v-html="categoryContent.info.introText"></div>
                            <astm-sidebar v-if="isChildCategoryPage && isAstmCompassEnabled()">
                            </astm-sidebar>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="tab === 'contacts'">
                    <v-row
                        v-for="(results, index) in categoryContent.contacts"
                        :key="index">
                        <v-col>
                            <h4 class="primary--text mb-2">{{ results.office }}</h4>
                            <p class="mb-0">{{ results.name }}</p>
                            <p class="mb-0" v-if="results.address">{{ results.address }}</p>
                            <p class="mb-0" v-if="results.address2">{{ results.address2 }}</p>
                            <p class="mb-0" v-if="results.city && results.state && results.zipcode">{{ results.city }}, {{ results.state }} {{ results.zipcode }}</p>
                            <p class="mb-0" v-if="results.phone">{{ results.phone }}</p>
                            <p class="mb-0" v-if="results.fax">{{ results.fax }}</p>
                            <p class="mb-0" v-if="results.url"><a :href="results.url">{{ results.url }}</a></p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="tab === 'links'">
                    <v-row
                        dense
                        v-for="(results, index) in categoryContent.links"
                        :key="index">
                        <v-col>
                            <p class="mb-0"><a :href="results.url" target="_blank">{{ results.title }}</a></p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </span>
    </div>
</template>

<script>
    import AstmSidebar from '../astm/Sidebar';

    export default {
        data() {
            return {
                lodash: _,
                tab: '',
                loading: true,
                categoryContent: {},
                categoryContentCount: 0,
                isChildCategoryPage: false,
            }
        },
        props: {
            category: {
                required: false,
                type: String
            },
            isAstmStandardPage: {
                required: true,
                type: Boolean,
            }
        },
        components: {
            AstmSidebar
        },
        watch: {
            category(value) {
                this.resetCategoryContent(value);
            }
        },
        methods: {
            getCategoryData() {
                this.$http.get(Routing.generate('get_category_data', {
                    category: this.category
                })).then(response => {
                    this.categoryContent = response.data;
                    this.categoryContentCount = this.categoryContent.bannerInfo
                        ? _.size(this.categoryContent) - 1
                        : _.size(this.categoryContent);
                    if (this.categoryContent.contacts) {
                        this.tab = 'contacts';
                    } else {
                        this.tab = 'links';
                    }
                    if (this.categoryContent.info) {
                        let introText = this.categoryContent.info.introText ?
                        this.categoryContent.info.introText.replace(/<[^>]+>/g, '') :
                        '';
                        if (_.size(introText) > 0) {
                            let metaTags = document.getElementsByTagName('meta');
                            _.forEach(metaTags, function(value) {
                                if (value.getAttribute('name') === 'description') {
                                    value.setAttribute('content', introText);
                                }
                            });
                        }
                        this.tab = 'info';
                    }
                    this.$nextTick(() => {
                        this.$set(this, 'loading', false);
                    });
                });
            },
            resetCategoryContent(category) {
                this.categoryContent = {};
                if (category) {
                    this.$set(this, 'loading', true);
                    this.getCategoryData();
                }
            }
        },
        mounted() {

            EventBus.listen('location:changed', (data) => {

                /** @type {Location} */
                const loc = data.location;

                // should only be visible when browsing specific standard: /astm-standards/something
                this.isChildCategoryPage = !!loc.pathname.match(/astm-standards\/\w+/);

            });

            EventBus.listen('reset-category-content', () => this.resetCategoryContent());
        }
    }
</script>

<style lang="scss" scoped>
    .v-tab {
        min-width: unset;
        text-transform: unset;
        font-size: 12px;
        padding: 0 10px;
    }
</style>
