<template>
  <div :class="!isMobilePort ? 'pa-4' : ''">

    <h4 v-if="!isMobileAndTablet">Title Filters</h4>

    <div class="pa-1">
      <v-btn
          small
          block
          dark
          outlined
          depressed
          color="black"
          @click="resetFilters">
        Reset Filters
      </v-btn>
    </div>

    <div class="filters">

      <title-filter-expander
          class="mt-3"
          title="Title Scope"
          component-name="TitleScopePanel">
      </title-filter-expander>

      <title-filter-expander
          class="mt-3"
          title="Categories / Locations"
          component-name="CategoriesPanel">
      </title-filter-expander>

      <title-filter-expander
          class="mt-3"
          title="Years"
          component-name="YearsPanel">
      </title-filter-expander>
    </div>

  </div>
</template>

<script>
import TitleFilterExpander from "./TitleFilterExpander.vue";
import Mobile from "../../../inc/mixins/mobile";
import {EventNames} from "../../../../classes/EventNames";

export default {
    components: {TitleFilterExpander},
    methods: {
        resetFilters() {
            EventBus.fire(EventNames.RESET_TITLE_SEARCH)
        }
    },
    mixins: [
        Mobile
    ]
}
</script>
