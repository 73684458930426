<template>
    <outlined-card
        v-if="!isEnterpriseOrConcurrentActive"
        :showDivider="false">
        <template v-slot:title>
            <h2 class="text-h6 font-weight-bold">
                Concurrent Access
            </h2>
        </template>
        <template v-slot:content>
            <div class="fs-16">
                <p>Concurrent access lets multiple people access the same title and share notes for that title.</p>
                <v-text-field
                    dense
                    v-model="accessCode"
                    outlined
                    hide-details
                    label="Concurrent Access Code">
                </v-text-field>
                <v-btn
                    class="mt-4 mb-2 font-weight-bold"
                    depressed
                    outlined
                    @click="concurrentAccessValidation()">
                    Submit
                </v-btn>
                <template
                    v-if="isPreviousCodesBusy">
                    <v-progress-linear
                        class="mt-3"
                        indeterminate
                        color="primary">
                    </v-progress-linear>
                </template>
                <v-row v-else>
                    <v-col class="pa-2">
                        <v-chip
                            v-for="(code, key) in previousCodes"
                            text
                            :key="key"
                            class="ma-1"
                            :outlined="isExpired(code)"
                            :color="isExpired(code) ? 'red' : 'primaryLight'"
                            @click="!isExpired(code) && setUserProfile({ type: 'concurrent', metadata: code.accessCode })"
                            >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <p class="mb-0 primary--text" v-bind="attrs" v-on="on">
                                    {{ code.accessCode }}
                                    <span v-if="isExpired(code)"> EXPIRED </span>
                                </p>
                                </template>
                                <span class="tooltip-content">
                                    Last Used, {{ code.lastUsedTime | moment('MMMM Do, YYYY') }}
                                </span>
                            </v-tooltip>
                            <v-icon
                                right
                                color="primary"
                                @click.stop="showConfirmation(code)">
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </v-col>
                </v-row>
                <v-dialog
                    v-model="confirmDialog"
                    max-width="500">
                    <v-card>
                        <v-card-title>
                            Please Confirm
                        </v-card-title>
                        <v-card-text>
                            <p>
                                Are you sure you want to remove this code from the list?
                            </p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                light
                                @click="cancelRemoveCode">
                                Cancel
                            </v-btn>
                            <v-btn
                                class="error"
                                @click="confirmRemoveCode">
                                Remove
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </outlined-card>
</template>

<script>

    import OutlinedCard from '../../../components/cards/OutlinedCard.vue';
    import concurrent from '../../../components/concurrent/mixins/concurrent';

    export default {
        data(){
            return {
                confirmDialog: false,
                codeToBeRemoved: null,
            }
        },
        mixins: [concurrent],
        components: {
            OutlinedCard
        },
        methods: {
            isExpired(code) {
                return code.status !== undefined && !code.status;
            },
            showConfirmation(code) {
                const plainCode = Object.assign({}, code);
                this.codeToBeRemoved = plainCode;
                this.confirmDialog = true
            },
            confirmRemoveCode() {
                this.confirmDialog = false;
                // will take care of setting/unsetting of "busy" state
                this.removePreviousUsedCode(this.codeToBeRemoved);
            },
            cancelRemoveCode() {
                this.codeToBeRemoved = null;
                this.confirmDialog = false;
            }
        },
        mounted() {
            this.getPreviousUsedCodes();
        }
    }
</script>
<style scoped>

</style>