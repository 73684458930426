var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full d-flex align-start border elevation-2 rounded pa-3 chat-message",
      style: _vm.isSystemType
        ? `border-left: 6px solid ${_vm.$vuetify.theme.themes.light.primaryMedium}`
        : `border-left: 6px solid ${_vm.$vuetify.theme.themes.light.lightgray}`,
    },
    [
      _vm.isSystemType
        ? _c("asset-image", {
            staticClass: "mr-4",
            attrs: {
              src: require("../../../images/ai_navigator/ai_navigator_icon.svg"),
              width: "40",
            },
          })
        : _c(
            "v-avatar",
            {
              staticClass: "mr-4",
              attrs: { color: "grey font-weight-bold lighten-2", size: "40" },
            },
            [
              _vm.userNameInitials
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userNameInitials) +
                        "\n        "
                    ),
                  ]
                : _c("v-icon", [_vm._v("\n            mdi-account\n        ")]),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "api-response pa-0",
          staticStyle: { "overflow-x": "hidden" },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }