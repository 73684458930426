var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pa-3" }, [
    _c("h2", {
      staticClass: "mb-5",
      domProps: { innerHTML: _vm._s(_vm.tagData.title) },
    }),
    _vm._v(" "),
    _c("h4", [_vm._v("\n        Question in Detail:\n    ")]),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.tagData.question) } }),
    _vm._v(" "),
    _vm.attachmentUrls
      ? _c(
          "div",
          { staticClass: "my-5" },
          [
            _c("strong", [_vm._v("Attachment(s):")]),
            _vm._v(" "),
            _vm._l(_vm.attachmentUrls, function (item) {
              return [
                _c(
                  "a",
                  { attrs: { href: item, target: "_blank" } },
                  [
                    _c("v-icon", [_vm._v("attachment")]),
                    _vm._v(
                      " " + _vm._s(_vm.labelFromUrl(item)) + "\n            "
                    ),
                  ],
                  1
                ),
                _vm._v("  \n        "),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("h4", [_vm._v("\n        ICC Opinion:\n    ")]),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.tagData.content) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }