export default {
    computed: {
        defaultSearch: {
            get() {
                return this.$store.getters.getDefaultSearch;
            },
            set(value) {
                this.$store.commit('setDefaultSearch', value);
            }
        },
        searchTitles: {
            get() {
                return this.$store.getters.getSearchTitles;
            },
            set(value) {
                this.$store.commit('setSearchTitles', value);
            }
        },
        searchYears: {
            get() {
                return this.$store.getters.getSearchYears;
            },
            set(value) {
                this.$store.commit('setSearchYears', value);
            }
        },
        searchCategories: {
            get() {
                return this.$store.getters.getSearchCategories;
            },
            set(value) {
                this.$store.commit('setSearchCategories', value);
            }
        },
        searchTypes: {
            get() {
                return this.$store.getters.getSearchTypes;
            },
            set(value) {
                this.$store.commit('setSearchTypes', value);
            }
        },
        searchSource: {
            get() {
                return this.$store.getters.getSearchSource;
            },
            set(value) {
                this.$store.commit('setSearchSource', value);
            }
        },
        filterPanel: {
            get() {
                return this.$store.getters.getFilterPanel;
            },
            set(value) {
                this.$store.commit('setFilterPanel', value);
            }
        },
        codesPageTitle: {
            get() {
                return this.$store.getters.getCodesPageTitle;
            },
            set(value) {
                this.$store.commit('setCodesPageTitle', value);
            }
        },
        codesPagination: {
            get() {
                return this.$store.getters.getCodesPagination;
            },
            set(value) {
                this.$store.commit('setCodesPagination', value);
            }
        },
        titleSearchCategory: {
            get() {
                return this.$store.getters.getTitleSearchCategory;
            },
            set(value) {
                this.$store.commit('setTitleSearchCategory', value);
            }
        },
        searchTab: {
            get() {
                return this.$store.getters.getTabbedSearchType;
            }
        },
        tabbedSearchValues: {
            get() {
                return this.$store.getters.getTabbedSearchValues;
            }
        },
        tabbedSearchUrl: {
            get() {
                return this.$store.getters.getTabbedSearchUrl;
            }
        },
        titleSearchCategoryName() {
            if (
                this.getCategoryObject(this.titleSearchCategory) &&
                this.titleSearchCategory !== this.$getConst('titleSearchAllSlug')
            ) {
                return this.getCategoryObject(this.titleSearchCategory).name.replace('- ', '');
            }
            return '';
        },
        titleSearchCategories() {
            const temp = this.$store.getters.getSearchNavigationCategories;
            const storeCategories = JSON.parse(JSON.stringify(temp));

            // TODO: move this to CategoryModule?
            let categories = [
                {
                    children: [],
                    id: 0,
                    key: "",
                    name: "All Titles",
                    slug: this.$getConst('titleSearchAllSlug')
                }
            ];
            _.forEach(storeCategories.Category, function (value) {
                categories.push(value);
                if (_.size(value.children) > 0) {
                    _.forEach(value.children, function (value) {
                        value.name = '- ' + value.name;
                        categories.push(value);
                    });
                }
            });
            _.forEach(storeCategories.Location, function (value) {
                categories.push(value);
                if (_.size(value.children) > 0) {
                    _.forEach(value.children, function (value) {
                        value.name = '- ' + value.name;
                        categories.push(value);
                    });
                }
            });

            return categories;
        },
    },
    methods: {
        getCategoryObject(slug) {
            let parent = _.findDeep(this.titleSearchCategories, function(value, index) {
                if (index === 'slug' && value === slug)
                    return true
            });
            if (parent) {
                return parent.parent;
            }
        },
        getParent(slug) {
            let parent = _.findDeep(this.titleSearchCategories, function(value, index) {
                if (index === 'slug' && value === slug)
                    return true
            });
            if (parent) {
                return parent.parent;
            }
        },
    }
}
