var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "accent2-top-border mb-5", attrs: { flat: "" } },
    [
      _c(
        "v-container",
        [
          _vm.title.is_astm
            ? [
                _c(
                  "v-row",
                  { attrs: { dense: "", align: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pt-0 pr-0", attrs: { cols: "auto" } },
                      [
                        _c("img", {
                          staticClass: "float-left mr-5",
                          attrs: {
                            width: "80px",
                            src: _vm.asset(
                              require("../../../images/astm-logo.png"),
                              "default"
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-col", { staticClass: "pl-0 pb-0" }, [
                      _c("h4", { staticClass: "float-left" }, [
                        _vm._v(
                          "\n                        ASTM Standards are available exclusively as part of\n                    "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticClass: "pb-0" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pb-5", attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-2 pr-0", attrs: { cols: "auto" } },
                          [
                            _c("img", {
                              staticClass: "float-left ml-0",
                              attrs: {
                                width: "50%",
                                src: _vm.asset(
                                  require("../../../images/icons/diamond_complete.png"),
                                  "default"
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-col", { staticClass: "pl-0 pb-0" }, [
                          _c("h4", { staticClass: "float-left" }, [
                            _vm._v("Premium Complete Subscription"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.trialActive
                      ? _c(
                          "v-row",
                          {
                            staticClass: "yellow lighten-5 text-center mb-2",
                            attrs: { dense: "" },
                          },
                          [
                            _c("v-col", [
                              _c("p", { staticClass: "caption mb-0" }, [
                                _vm._v(
                                  "ASTM Standards are not active during trial period"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "mb-0 caption" }, [
                      _vm._v(
                        "\n                    This title is not included in the Premium Complete trial\n                "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
          _vm._v(" "),
          _vm.title.part_of_complete
            ? _c(
                "v-row",
                { staticClass: "text-center", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "caption",
                          attrs: { block: "", dark: "", color: "accent2" },
                          on: {
                            click: function ($event) {
                              return _vm.getPremiumCompleteBook()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    View Premium Complete Subscription Options\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }