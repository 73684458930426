<template>
    <div
        class="grey lighten-5">
        <div
            v-if="!isMobilePort"
            id="ai_header"
            class="white">
            <v-container class="px-0">
                <v-row dense align="center">
                    <v-col cols="auto">
                        <asset-image class="mb-n1" :src="require('../../images/ai_navigator/ai_navigator_icon.svg')"
                            width="40">
                        </asset-image>
                    </v-col>
                    <v-col cols="auto">
                        <h2 class="oxygen mt-n1">ICC AI Navigator</h2>
                        <share-feedback></share-feedback>
                    </v-col>
                    <v-col class="d-flex justify-end">
                        <v-btn small class="font-weight-bold mt-n1" color="accent2" elevation="0">
                            <v-icon left>
                                mdi-creation
                            </v-icon>
                            PREMIUM ACTIVE
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider>
            </v-divider>
        </div>

        <div
            v-else
            id="ai_header"
            class="d-lg-none d-flex flex-column">

            <div class="d-flex justify-space-between align-center pa-3 mb-2 border-bottom">

                <div class="d-flex align-center">

                    <asset-image
                        :src="require('../../images/ai_navigator/ai_navigator_icon.svg')"
                        width="30"
                        class="mr-2"
                        @load="setAiContainerHeight"
                    ></asset-image>

                    <h4 class="oxygen">ICC AI Navigator</h4>
                    <share-feedback></share-feedback>
                </div>

                <v-btn text small @click="mobileHeaderQuestionMarkClicked">
                    <v-icon color="primary">mdi-help-circle-outline</v-icon>
                </v-btn>

            </div>

            <div v-if="mobileHeaderDropdownVisible"  id="question_block" class="h-full pa-3">

                <div style="position: absolute; top:0; right: 20px;">
                    <v-btn
                        icon
                        @click="mobileHeaderDropdownVisible = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

                <navigator-tips-block></navigator-tips-block>

                <v-divider class="my-4"></v-divider>

                <navigator-sidebar-disclaimer-block></navigator-sidebar-disclaimer-block>

                <div class="text-center">
                    <v-btn class="primary white--text" @click="mobileHeaderDropdownVisible = false">
                        Close
                    </v-btn>
                </div>

            </div>

        </div>
        <div
            id="ai_container"
            class="content-container d-flex flex-column flex-lg-row">
            <ai-navigator-sidebar
                v-if="!isMobilePort"
                class="fill-height py-4"
                id="ai_right"
                style="width: 25%; overflow-y: auto;">
            </ai-navigator-sidebar>

            <confirm-terms-dialog
                    :visible="disclaimerModalVisible"
                    :can-be-closed="!disclaimerConfirmationRequired"
                    @closed="disclaimerModalVisible = false"
                    @confirmed="disclaimerConfirmed"
            ></confirm-terms-dialog>

            <notes></notes>

            <!--

            desktop (lg or above): [left] [right]
            mobile:
                [header]
                [left]
            -->

            <div id="ai_top" class="d-lg-none d-flex flex-column">


                <div v-if="mobileHeaderDropdownVisible" id="question_block" class="h-full pa-3">

                    <div style="position: absolute; top:0; right: 20px;">
                        <v-btn icon @click="mobileHeaderDropdownVisible = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>

                    <navigator-tips-block></navigator-tips-block>

                    <v-divider class="my-4"></v-divider>

                    <navigator-sidebar-disclaimer-block></navigator-sidebar-disclaimer-block>

                    <div class="text-center">
                        <v-btn class="primary white--text" @click="mobileHeaderDropdownVisible = false">
                            Close
                        </v-btn>
                    </div>

                </div>

            </div>

            <div class="h-full lg:w-9/12 flex-grow-1 d-flex flex-column px-3 px-lg-5 pt-4 grey lighten-5" style="overflow-x: hidden;">

                <div id="msg_rows" class="flex-grow-1 overflow-auto pr-1 pr-lg-3">

                    <template v-if="hasMessages">

                        <template v-for="(message, index) in messages">

                            <!--
                            questions are plain STRINGS, answers are complicated OBJECTS
                            -->

                            <template v-if="typeof message === 'object'">

                                <chat-server-message :prompt="message" :questionId="questionId"
                                                     @done="onServerResponse" @countdown="onCountDown"
                                                     class="mb-5">
                                </chat-server-message>

                            </template>

                            <chat-message v-else type="user" class="mb-3">
                                {{ message }}
                            </chat-message>

                        </template>
                    </template>

                    <ai-navigator-start v-else @question-clicked="onQuestionClicked"
                        :can-send-messages="!isQuestionLimitReached"></ai-navigator-start>
                </div>

                <div id="input_box" class="mt-3 mt-lg-5">
                    <div class="chat-busy-actions" v-show="isBusy"></div>

                    <question-limit-alert :countdownremaining="countdownremaining"
                        :countdown-reset="countdownReset"></question-limit-alert>

                    <div class="d-flex justify-space-between align-center mb-1 text-muted">

                        <div>

                            <v-progress-linear v-if="codeCyclesLoading" :active="true" color="primary" indeterminate
                                style="min-width: 10em;"></v-progress-linear>

                            <p v-else-if="codeCyclesError" class="red--text mb-0">
                                {{ codeCyclesError }}
                            </p>

                            <p v-else-if="'' !== getLocationNote" class="mb-0 text-sm">
                                {{ getLocationNote }}
                            </p>

                        </div>

                        <div class="text-sm text-no-wrap">
                            <v-btn
                                v-if="isMobilePort"
                                text
                                small
                                @click="locationSelectionModal = true">
                                <v-icon>mdi-tune</v-icon>
                            </v-btn>

                            <v-btn text small @click="clearChat">
                                <v-icon>mdi-delete-outline</v-icon>
                                <template v-if="$vuetify.breakpoint.mdAndUp">Clear</template>
                            </v-btn>
                        </div>

                    </div>

                    <v-textarea label="Enter question" v-model="question" hide-details outlined rows="1" auto-grow
                        counter="true" autocomplete="off" @keydown.enter="onEnterClicked" class="white">
                        <template v-slot:append>

                            <v-progress-circular v-if="isBusy" indeterminate color="grey" size="28"
                                class="disabled-icon"></v-progress-circular>

                            <v-btn v-else icon small color="primary" @click="sendMessage">
                                <v-icon>mdi-send-outline</v-icon>
                            </v-btn>

                        </template>
                    </v-textarea>

                    <div class="d-flex justify-space-between mt-2">
                        <p>&nbsp;</p>
                        <p class="caption text--secondary">{{ currentCharCount }} / {{ maxCharCount }} chars</p>
                    </div>
                </div>
            </div>
            <confirm-dialog
                :is-open="locationSelectionModal"
                @closed="locationSelectionModal = false">
                <template v-slot:title>
                    Location / I-Codes
                </template>
                <navigator-location-selection class="mt-5">
                </navigator-location-selection>
                <template v-slot:buttons>
                    <v-spacer>
                    </v-spacer>
                    <v-btn
                        @click="locationSelectionModal = false"
                        outlined>
                        Close
                    </v-btn>
                </template>
            </confirm-dialog>
        </div>
    </div>
</template>

<script>
import AiNavigatorSidebar from "./AiNavigatorSidebar.vue";
import TextTyped from "../TextTyped.vue";
import AssetImage from "../AssetImage.vue";
import ChatServerMessage from "./chat/ChatServerMessage.vue";
import AiNavigatorStart from "./AiNavigatorStart.vue";
import ChatMessage from "./chat/ChatMessage.vue";
import { ApiService } from "../../api/ApiService";
import { User } from '../../classes/User';
import ConfirmTermsDialog from "./ConfirmTermsDialog.vue";
import NavigatorTipsBlock from "./blocks/NavigatorTipsBlock.vue";
import NavigatorSidebarDisclaimerBlock from "./blocks/NavigatorSidebarDisclaimerBlock.vue";
import Notes from "../titles/notesModal/index.vue";
import QuestionLimitAlert from "./QuestionLimitAlert.vue";
import { EasyStorage } from "../../classes/EasyStorage";
import { StringUtil } from "../../util/StringUtil";
import Mobile from "../inc/mixins/mobile";
import ConfirmDialog from "../ConfirmDialog.vue";
import NavigatorLocationSelection from "./blocks/NavigatorLocationSelection.vue";
import Constants from "../../constants";
import ShareFeedback from "../links/ShareFeedback";

export default {
    name: 'AiNavigator',
    mixins: [
        Mobile
    ],
    components: {
        NavigatorLocationSelection,
        ConfirmDialog,
        QuestionLimitAlert,
        NavigatorSidebarDisclaimerBlock,
        NavigatorTipsBlock,
        ConfirmTermsDialog,
        Notes,
        ChatMessage,
        AiNavigatorStart,
        ChatServerMessage,
        AssetImage,
        TextTyped,
        AiNavigatorSidebar,
        ShareFeedback
    },
    data() {
        return {
            locationSelectionModal: false,
            disclaimerConfirmationRequired: false,
            disclaimerModalVisible: false,
            mobileHeaderDropdownVisible: false,
            maxCharCount: 500,
            messages: [],
            isBusy: false,
            question: '',
            // code cycle stuff
            codeCyclesLoading: false,
            codeCyclesError: '',
            countdownremaining: null,
            countdownReset: null,
            codeCyclesAll: [],
            questionId: null
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        currentCharCount() {
            return this.question.length;
        },
        isQuestionValidLength() {
            return this.question.length <= this.maxCharCount;
        },
        isQuestionLimitReached() {
            return this.countdownremaining === 0;
        },
        canSendMessage() {

            if (this.isQuestionLimitReached) {
                return false;
            }

            return this.question.length > 0 && this.isQuestionValidLength && this.isBusy === false;
        },
        hasMessages() {
            return this.messages.length > 0;
        },
        getLocationNote() {
            return this.$store.getters.getLocationNote;
        },
        selectedLocation() {
            return this.$store.getters.getCodeCycleLocation;
        },
        selectedYear() {
            return this.$store.getters.getCodeCycleYear;
        },
        selectedCodeCycle() {
            return this.$store.getters.getCodeCycle;
        }
    },
    methods: {
        disclaimerConfirmed() {
            this.disclaimerConfirmationRequired = false;
            this.disclaimerModalVisible = false;
        },
        mobileHeaderQuestionMarkClicked() {
            this.mobileHeaderDropdownVisible = !this.mobileHeaderDropdownVisible;
        },
        validateQuestion(question) {
            // TODO: return false is sensitive information found
            return true;
        },
        onQuestionClicked(question) {

            if (this.countdownremaining === 0) {
                return false;
            }

            //in case of recent asked questions available it provides as object otherwise a simple string
            if (typeof question === 'object') {
                const { question: text, scope, questionId } = question;
                this.question = text;
                if (scope && scope.cycles && scope.cycles.length > 0) {
                    this.$store.commit('setCodeCycle', scope.cycles[0]);
                    this.$store.commit('setCodeCycleLocation', scope.location);
                    this.$store.commit('setCodeCycleYear', scope.year);
                    ApiService.updatePreference({
                        [User.CODE_CYCLE_LOCATION]: scope.location,
                        [User.CODE_CYCLE_YEAR]: scope.year,
                    });
                }
                this.questionId = questionId;
            } else {
                this.question = question;
            }
            this.sendMessage();
        },
        onEnterClicked(event) {

            /** @type {KeyboardEvent} */
            const ev = event;

            // SHIFT + Enter = creates new line rather than SEND message
            if (ev.shiftKey) {
                return false;
            }

            ev.preventDefault();

            if (this.canSendMessage) {
                this.sendMessage();
            } else {
                return false;
            }
        },
        sendMessage() {

            if (!this.canSendMessage) {
                return;
            }

            if (!this.validateQuestion(this.question)) {
                return;
            }

            this.isBusy = true;
            this.messages.push(this.question);

            // we will then listen for server response
            this.messages.push({
                type: 'prompt',
                text: this.question
            });

            setTimeout(() => {
                this.question = '';
                this.questionId = null;
            }, 50);
        },
        onServerResponse() {
            this.isBusy = false;
        },
        onCountDown(data) {
            // will be stored as String first
            localStorage.setItem('ain_remaining', data.remaining);

            this.countdownremaining = data.remaining;
            this.countdownReset = data.reset;
        },
        clearChat() {
            this.messages = [];
        },
        getLimits() {

            ApiService.getLimits()
                .then((data) => {
                    this.countdownReset = data.reset;
                    this.countdownremaining = data.remaining;
                });
        },
        resetUrlQueryString() {
            window.history.pushState('', 'AI Navigator', this.getRoute('ai_navigator'));
        },
        setAiContainerHeight() {
            const systemBar = document.getElementById('app-system-bar');
            let navigationHeader = document.getElementById('navigation-app');
            const aiHeader = document.getElementById('ai_header');
            const aiContainer = document.getElementById('ai_container');

            if (!navigationHeader) {
                navigationHeader = document.getElementById('navigation-app-mobile')
            }

            let offsetHeight = navigationHeader.offsetHeight + aiHeader.offsetHeight;
            if (systemBar) {
                offsetHeight += systemBar.offsetHeight;
            }

            aiContainer.style.setProperty('height', `calc(100vh - ${offsetHeight}px)`, 'important');
        }
    },
    created() {

        this.getLimits();

        this.$watch(() => this.$store.getters.getUser, (user) => {

            if (user) {
                this.disclaimerConfirmationRequired = !!user.ai_navigator_terms_confirmation_required;

                if (user.ai_navigator_terms_confirmation_required) {
                    this.disclaimerModalVisible = true;
                }
            }

        }, { immediate: true })

        const urlParams = new URLSearchParams(window.location.search);
        const questionAsked = urlParams.get('ask');

        if (questionAsked && '' !== questionAsked) {
            this.question = questionAsked;
            this.resetUrlQueryString();
        }
    },
    mounted() {

        EventBus.listen('ai_navigator_show_disclaimer', () => {
            this.disclaimerModalVisible = true;
        });

        Mousetrap.bind('left+left+left+c', () => {
            this.disclaimerModalVisible = false;
        });

        /** @type {HTMLDivElement} */
        const el = document.querySelector('#main-content-container > div.body.overflow-hidden');

        if (el) {
            el.style.setProperty('min-height', '1px', 'important');
        }

        this.$store.commit('setFooterVisible', false);
        this.$store.commit('setMobileActionsVisible', false);

        this.$watch(() => this.messages, () => {

            const element = document.querySelector('#msg_rows');

            element.scrollTo({
                behavior: 'smooth',
                top: element.scrollHeight
            });

        });

        EasyStorage.watch("ain_remaining", (newValue) => {

            if (StringUtil.isNumeric(newValue)) {
                this.countdownremaining = parseInt(newValue);
            }

        });

        this.setAiContainerHeight();
        window.addEventListener('resize', this.setAiContainerHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setAiContainerHeight);
    }
}
</script>

<style lang="scss" scoped>
#question_block {
    min-height: 100vh;
    position: relative;
}
</style>

<style lang="scss">
.disabled-icon {
    cursor: not-allowed !important;
}

.border-bottom {
    border-bottom: 1px solid lightgray;
}

@media (min-width: 1264px) {
    .lg\:w-9\/12 {
        width: 75%
    }
}
</style>
