<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Pending Enterprise Invitation {{enterpriseInvitations.length > 1 ? `${enterprise+1} of ${enterpriseInvitations.length}` : '' }}
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="mr-2 accent2--text"
                    @click="closeInvitationAlertBox()">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text class="pt-5">
                <v-alert
                    v-if="message"
                    :type="type"
                    dismissible
                    @input="closeAlert()">
                    {{message}}
                </v-alert>
                <v-row>
                    <v-col>
                        <h3 class="font-weight-regular text-center">
                            You've been invited by <strong>{{ activeEnterprise ? activeEnterprise.name : '' }}</strong> to join their organization on ICC Digital Codes. You can accept or decline the invitation. If you decline, you won't see the invitation again unless you receive a new one.
                        </h3>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider>
            </v-divider>
            <v-card-actions class="grey lighten-4 text-center">
                <v-row
                    dense
                    justify="center">
                    <v-col cols="atuo">
                        <template v-if="done">
                            <v-btn
                                @click="closeInvitationAlertBox()">
                                Close
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn
                                color="primary"
                                @click="confirm()"
                                :disabled="loading">
                                Accept
                            </v-btn>
                            <v-btn
                                @click="confirm(true)"
                                :disabled="loading">
                                Decline
                            </v-btn>
                        </template>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../../inc/mixins/mobile'
    import {EnterpriseApi} from "../../../../api/EnterpriseApi";

    export default {
        data() {
            return {
                dialog: false,
                done: false,
                loading: false,
                type: null,
                message: null,
                enterprise: 0,
                acceptedAnyInvitation: false,
            }
        },
        computed: {
            enterpriseInvitations() {
                const user = this.$store.getters.getUser;
                if (user) {
                    return user.enterprise_pending_invitations
                }
                
                return [];
            },
            activeEnterprise() {
                if (this.enterpriseInvitations) {
                    return this.enterpriseInvitations[this.enterprise]
                }
            }
        },
        mixins: [
            Mobile
        ],
        methods: {
            closeInvitationAlertBox(){
                this.dialog = false;
                if(this.done){
                    localStorage.removeItem('hide-invitation');
                    if (this.acceptedAnyInvitation) { // if accept any invitation the load profile
                        EventBus.fire('full-page-loader', true);
                        this.clearUserSession();
                        setTimeout(function(){
                            window.location = Routing.generate('dashboard_landing');
                        }, 2000);
                    }
                } else {
                    localStorage.setItem('hide-invitation', '1');
                }
            },
            closeAlert() {
                this.message = null;
                this.type = null;
            },
            async confirm(decline = false) {
                this.loading = true;
                this.closeAlert();
                let status = 'accepted';
                if (decline) {
                    status = 'declined';
                } else {
                    this.acceptedAnyInvitation = true;
                }
                try {
                    const response = await EnterpriseApi.acceptInvitation({
                        response_status: status,
                        invitation_id: this.activeEnterprise.id
                    })
                    if (response.data && response.data.data) {
                        this.message = response.data.data[0];
                        this.type = 'success';
                    }
                } catch({error, response}) {
                    if (response.data && response.data.error) {
                        this.message = response.data.error;
                        this.type = 'error';
                    }
                }
                this.$nextTick(() => {
                    this.loading = false;
                })

                if (this.enterpriseInvitations.length > 1 &&
                    this.enterpriseInvitations.length !== (this.enterprise+1)) {
                    this.enterprise = this.enterprise+1;
                } else {
                    this.done = true;
                }
            }
        },
        mounted() {
            EventBus.listen('show-invitation-modal', () => this.dialog = true)
        }
    }
</script>
