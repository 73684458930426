import Vue from 'vue';
import VueSession from 'vue-session';

Vue.use(VueSession, {persist: true});

export const PremiumModule = {
    state: {
        trialStarted: Vue.prototype.$session.has('trialStarted') ?
            Vue.prototype.$session.get('trialStarted') :
            false,
        trialActive: Vue.prototype.$session.has('trialActive') ?
            Vue.prototype.$session.get('trialActive') :
            false,
        premiumSubscriptionSet: Vue.prototype.$session.has('premiumSubscriptionSet') ?
            Vue.prototype.$session.get('premiumSubscriptionSet') :
            false,
        premiumSubscriptions: Vue.prototype.$session.has('premiumSubscriptions') ?
            Vue.prototype.$session.get('premiumSubscriptions') :
            [],
        premiumBundleSet: Vue.prototype.$session.has('premiumBundleSet') ?
            Vue.prototype.$session.get('premiumBundleSet') :
            false,
        premiumBundledTitles: Vue.prototype.$session.has('premiumBundledTitles') ?
            Vue.prototype.$session.get('premiumBundledTitles') :
            [],
        isEnterpriseAdmin: Vue.prototype.$session.has('isEnterpriseAdmin') ?
            Vue.prototype.$session.get('isEnterpriseAdmin') :
            false,
        enterpriseId: Vue.prototype.$session.has('enterpriseId') ?
            Vue.prototype.$session.get('enterpriseId') :
            '',
        loginFailedCount: Vue.prototype.$session.has('loginFailedCount') ?
            Vue.prototype.$session.get('loginFailedCount') :
            0,
        concurrentCode: null,

        favorite_books: [],
        isSubscriptionLoaded: false,
    },
    getters: {
        hasTrialStarted : (state) => state.trialStarted,
        isTrialActive : (state) => state.trialActive,
        getPremiumSubscriptionSet : (state) => state.premiumSubscriptionSet,
        getPremiumSubscriptions : (state) => state.premiumSubscriptions,
        getPremiumBundleSet : (state) => state.premiumBundleSet,
        getPremiumBundledTitles : (state) => state.premiumBundledTitles,
        isEnterpriseAdmin : (state) => state.isEnterpriseAdmin,
        enterpriseId : (state) => state.enterpriseId,
        getLoginFailedCount : (state) => state.loginFailedCount,
        getConcurrentCode : (state) => state.concurrentCode,

        getFavoriteBooks : (state) => state.favorite_books,
        isSubscriptionLoaded: (state) => state.isSubscriptionLoaded,
    },
    mutations: {
        setTrialStarted (state, value) {
            Vue.prototype.$session.set('trialStarted', value);
            state.trialStarted = value;
        },
        setTrialActive (state, value) {
            Vue.prototype.$session.set('trialActive', value);
            state.trialActive = value;
        },
        setPremiumSubscriptionSet (state, value) {
            Vue.prototype.$session.set('premiumSubscriptionSet', value);
            state.premiumSubscriptionSet = value;
        },
        setPremiumSubscriptions (state, data) {
            Vue.prototype.$session.set('premiumSubscriptions', data);
            state.premiumSubscriptions = data;
        },
        setPremiumBundledSet (state, value) {
            Vue.prototype.$session.set('premiumBundleSet', value);
            state.premiumBundleSet = value;
        },
        setPremiumBundledTitles (state, data) {
            Vue.prototype.$session.set('premiumBundledTitles', data);
            state.premiumBundledTitles = data;
        },
        setIsEnterpriseAdmin (state, value) {
            Vue.prototype.$session.set('isEnterpriseAdmin', value);
            state.isEnterpriseAdmin = value;
        },
        setEnterpriseId (state, value) {
            Vue.prototype.$session.set('enterpriseId', value);
            state.enterpriseId = value;
        },
        setLoginFailedCount (state, value) {
            Vue.prototype.$session.set('loginFailedCount', value);
            state.loginFailedCount = value;
        },
        setConcurrentCode (state, value) {
            state.concurrentCode = value;
        },

        setFavoriteBooks (state, books) {
            state.favorite_books = books;
        },
        setSubscriptionLoaded (state, value) {
            state.isSubscriptionLoaded = value;
        }
    }
};
