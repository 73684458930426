<template>
    <div>
        <template v-for="items in data">
            <v-container class="grey lighten-3 px-6">
                <v-row align="center">
                    <v-col
                        v-if="items.categoryLogo"
                        cols="auto">
                        <v-img
                            :src="asset('/uploads/PremiumContentReportTag/category_logos/'+items.categoryLogo)"
                            max-width="30px">
                        </v-img>
                    </v-col>
                    <v-col>
                        <h3 class="font-weight-regular">{{items.categoryName}}</h3>
                    </v-col>
                    <v-col cols="auto">
                        <p class="caption mb-0 font-weight-bold">
                            ( <span class="error--text">*</span> sign indicates the report as featured. )
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <template v-for="reportTag in items.category">
                <v-container class="px-6">
                    <v-row>
                        <v-col>
                            <template v-if="reportTag.tag.content_type.name === 'PDF'">
                                <a
                                    :href="getReportLink(reportTag)"
                                    target="_blank"
                                    class="primary--text">
                                    <h3>
                                        {{reportTag.tag.title}}
                                        <span
                                            v-if="reportTag.tag.is_featured"
                                            class="error--text">
                                            *
                                        </span>
                                    </h3>
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    :href="reportTag.tag.content"
                                    target="_blank"
                                    class="primary--text">
                                    <h3>
                                        {{reportTag.tag.title}}
                                        <span
                                            v-if="reportTag.tag.is_featured"
                                            class="error--text">
                                            *
                                        </span>
                                    </h3>
                                </a>
                            </template>
                            <p class="caption mb-0 font-italic">
                                {{getContentTypeText(reportTag.tag.content_type.name)}}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                required: true,
                type: Object
            }
        },
        methods: {
            getContentTypeText(type) {
                switch (type) {
                    case 'PDF':
                        return '* PDF will open in restricted modal window';
                        break;
                    case 'Link':
                        return '* Link will open in New Tab';
                }
            },
            getReportLink(reportTag) {
                let data = {
                    document_id: reportTag.document_id,
                    chapter: reportTag.chapter_id,
                    section: reportTag.section_id,
                    tagId: reportTag.id,
                    reportType: 'premiumcontent',
                    site_type: 'premium'
                };

                return Routing.generate("premium_content_document_view", data);
            }
        }
    }
</script>
