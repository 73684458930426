export default {
    computed: {
        subjects: {
            get() {
                return this.$store.getters.getAstmSubjects;
            },
            set(value) {
                this.$store.commit('setAstmSubjects', value);
            }
        },
    },
    methods: {
        getAstmSubjects() {
            if (_.isEmpty(this.subjects)) {
                this.$http.get(Routing.generate(
                    'get_astm_subjects'
                )).then(response => {
                    if (response.data.code === 200) {
                        this.subjects = response.data.data;
                    } else {
                        this.message = response.errors;
                    }
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        showAstmTitles(subject) {
            let data = {
                resetPage: true,
                query: {
                    astmSubject: subject.slug,
                    searchTerm: null
                }
            }
            EventBus.fire('update-search-data', data);
        }
    },
    mounted() {
        this.getAstmSubjects();
    }
}
