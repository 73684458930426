<template>
    <expandable-panel
        :id="`tag-category-${contentId}`"
        :expand="expand"
        class="tags-expansion-panel-wrapper"
        @changeExpansion="changeExpansion"
        >
        <template v-slot:title>
            <span class="text--secondary font-weight-bold">INSIGHTS ({{ categories.length }}) </span>
        </template>
        <template v-slot:content>
            <div
                v-if="showPremiumInsights"
                class="premium-tag-category-item">
                    <v-row
                        dense
                        align="center">
                        <v-col
                            v-for="(item, key) in categories"
                            v-if="item.active"
                            :key="key"
                            cols="auto">
                            <v-tooltip
                                bottom
                                open-delay="400"
                                z-index="199"
                                max-width="300">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        small
                                        color="grey lighten-3"
                                        @click="selectCategory(item)"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon
                                            v-if="item?.icon"
                                            size="20"
                                            class="mr-1 text--secondary">
                                            mdi-{{ item.icon }}
                                        </v-icon>
                                        <span class="black--text">
                                            {{ item.name }}
                                        </span>
                                    </v-chip>
                                </template>
                                <span class="tooltip-codeinsights-text">
                                    {{ trimmedText(item.description) }}
                                </span>
                            </v-tooltip>
                        </v-col>
                </v-row>
                </div>
        </template>
    </expandable-panel>
</template>

<script>
import {ApiService} from "../../../api/ApiService";
import ExpandablePanel from "./ExpandablePanel";

export default {
    data() {
        return {
            selectedCategory: null,
            expand: false
        };
    },
    components: {ExpandablePanel},
    props: {
        contentId: {
            type: Number,
            required: true
        },
        tagType: {
            type: String,
            required: true
        },
        reportTypeShow: {
            type: String,
            required: true
        },
        categories: {
            type: Array,
            required: true,
            default: []
        },
        activeTag: {
            type:Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true
        },
        isMobile: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        showPremiumInsights() {
            return this.categories.some(obj => obj.active === true)
        }
    },
    methods: {
        changeExpansion(val){
            this.expand = val === 0 ? true : false;
        },
        logCategoryClick() {
            const category = this.selectedCategory?.id;
            ApiService.logPremiumCategoryClick(category, this.contentId);
        },
        selectCategory(category) {

            this.selectedCategory = category;
            let showSubscription = '' == this.reportTypeShow && 'premiumcontent' == this.tagType;
            if (showSubscription) { //display new subscribe modal
                let categoryInfo = {
                    categoryId: category.id,
                    tagType: this.activeTag.type
                }
                EventBus.fire('show-tag-restricted-dialog', categoryInfo);
            } else {
                EventBus.fire('show-premium-tag-modal', {
                    category: category,
                    contentId: this.contentId,
                    meta: this.meta
                });
            }

            this.logCategoryClick();
        },
    },
    mounted(){
        EventBus.listen('premium-insights-section-expand-' + this.contentId, () => {
            this.expand = true;
        });
    }
}
</script>

<style lang="scss">
    .premium-tag-category-item {
        position: relative;
    }
    .tooltip-codeinsights-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;   /* Limit to 2 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        max-width: 100%;
    }
</style>
