export default {
    computed: {
        activeDocumentInfo: {
            get() {
                return this.$store.getters.getDocumentInfo;
            },
            set(value) {
                this.$store.commit('setDocumentInfo', value);
            }
        },
        activeContentPremium: {
            get() {
                return this.$store.getters.getContentPremium;
            },
            set(value) {
                this.$store.commit('setContentPremium', value);
            }
        },
        activeContentBasic: {
            get() {
                return this.$store.getters.getContentBasic;
            },
            set(value) {
                this.$store.commit('setContentBasic', value);
            }
        },
        activeContentProtection: {
            get() {
                return this.$store.getters.getContentProtection;
            },
            set(value) {
                this.$store.commit('setContentProtection', value);
            }
        },
        activeChapter: {
            get() {
                return this.$store.getters.getActiveChapter;
            },
            set(value) {
                this.$store.commit('setActiveChapter', value);
            }
        },
        isXML: {
            get() {
                return this.$store.getters.getIsXML;
            },
            set(value) {
                this.$store.commit('setIsXML', value);
            }
        },
        tocMini: {
            get() {
                return this.$store.getters.getTocMini;
            },
            set(bool) {
                this.$store.commit('setTocMini', bool);
            }
        },
        isExam() {
            return '' !== this.examId;
        },
        isTitleLanding: {
            get() {
                return this.$store.getters.getIsLanding;
            },
            set(value) {
                this.$store.commit('setIsLanding', value);
            }
        },
        isFullscreenContent: {
            get() {
                return this.$store.getters.getIsFullScreenContent;
            },
            set(value) {
                this.$store.commit('setIsFullScreenContent', value);
            }
        }
    },
    methods: {
        tocWidth(isPdf = false) {
            return !isPdf ? 400 : 320;
        }
    }
}
