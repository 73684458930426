var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c(
              "p",
              { staticClass: "fs-20 my-3" },
              [
                _vm.showResultsText
                  ? [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.totalResults.toLocaleString()) +
                          " results for\n                    "
                      ),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold" },
                        [
                          !_vm.isAdvanceSearch && _vm.searchTerm
                            ? [
                                _vm._v(
                                  '\n                            "' +
                                    _vm._s(_vm.searchTerm) +
                                    '"\n                        '
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n                            (Advanced Term Search)\n                        "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  : _vm.loading
                  ? [
                      _vm._v(
                        "\n                    Loading Results...\n                "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n                    Enter search to find content in your Premium Titles\n                "
                      ),
                    ],
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.isMobilePort && _vm.showResultsText
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticStyle: { "max-width": "150px" },
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              items: _vm.sortItems,
                              label: "Sort by:",
                              "menu-props": { bottom: true, offsetY: true },
                            },
                            on: {
                              change: function ($event) {
                                return _vm.sortChange()
                              },
                            },
                            model: {
                              value: _vm.sort,
                              callback: function ($$v) {
                                _vm.sort = $$v
                              },
                              expression: "sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _vm._l(_vm.search, function (item, key) {
                return item.data && item.key !== "any"
                  ? _c(
                      "v-chip",
                      {
                        key: key,
                        staticClass: "primary--text mr-2 mb-1",
                        attrs: { color: "primaryLight" },
                      },
                      [
                        _c("span", { staticClass: "fs-16" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.label) +
                              " " +
                              _vm._s(item.data) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-icon",
                          {
                            attrs: { right: "" },
                            on: {
                              click: function ($event) {
                                return _vm.clearSearch(item.key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    mdi-close\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.activeFilters, function (item, key) {
                return item.data
                  ? [
                      item.data.length === 1
                        ? _c(
                            "v-chip",
                            {
                              key: item.data.key,
                              staticClass: "primary--text mr-2",
                              attrs: { color: "primaryLight" },
                            },
                            [
                              _c("span", { staticClass: "fs-16" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.data[0].name) +
                                    "\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                {
                                  attrs: { right: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearFilter(
                                        item.key,
                                        item.data[0].id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        mdi-close\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.data.length > 1
                        ? _c(
                            "v-menu",
                            {
                              attrs: { absolute: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "primary--text mr-2 mb-1",
                                                attrs: {
                                                  color: "primaryLight",
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fs-16" },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      `${item.subLabel} (${item.data.length})`
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { right: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clearFilter(
                                                      item.key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                mdi-close\n                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                _vm._l(item.data, function (subItem, index) {
                                  return _c(
                                    "v-list-item",
                                    { key: index },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "primary--text mr-2",
                                              attrs: { color: "primaryLight" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "fs-16" },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(subItem.name) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { right: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearFilter(
                                                        item.key,
                                                        subItem.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        mdi-close\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e()
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _vm.isMobileAndTablet
          ? _c(
              "v-row",
              { attrs: { align: "center" } },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("showModal")
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(
                            "\n                        mdi-tune\n                    "
                          ),
                        ]),
                        _vm._v(
                          "\n                    Content Filters\n                "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c("v-select", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        items: _vm.sortItems,
                        label: "Sort by:",
                        "menu-props": { bottom: true, offsetY: true },
                      },
                      on: {
                        change: function ($event) {
                          return _vm.sortChange()
                        },
                      },
                      model: {
                        value: _vm.sort,
                        callback: function ($$v) {
                          _vm.sort = $$v
                        },
                        expression: "sort",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      _vm._v(" "),
      _vm.defaultSearch
        ? _c("relevant-titles", { staticClass: "mt-3" })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }