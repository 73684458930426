var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { text: "", small: "" },
      on: {
        click: function ($event) {
          _vm.showCommentary = !_vm.showCommentary
        },
      },
    },
    [
      _c("v-icon", { attrs: { left: "" } }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.showCommentary ? "mdi-eye-off-outline" : "mdi-eye-outline"
            ) +
            "\n    "
        ),
      ]),
      _vm._v(
        "\n    " +
          _vm._s(_vm.showCommentary ? "Hide Commentary" : " Show Commentary") +
          "\n"
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }