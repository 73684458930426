var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg light-border-color",
      class: { scroll: _vm.titles.length > _vm.itemsPerPage },
      attrs: {
        outlined: "",
        color: "white",
        target: "_blank",
        height: "398px",
      },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2" },
        _vm._l(_vm.titles, function (item, key) {
          return _c(
            "v-row",
            {
              key: key,
              staticClass: "included-title-card rounded-lg ml-2 mt-2",
              attrs: { align: "center", tabindex: "0" },
              on: {
                click: function ($event) {
                  return _vm.clickAction(item)
                },
                keyup: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.clickAction(item)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    $event.preventDefault()
                    return _vm.clickAction(item)
                  },
                ],
              },
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "auto" } },
                [
                  _c("graphic", {
                    attrs: {
                      title: item,
                      thumbnail: "",
                      width: "50px",
                      additionalStyles: "max-width: 50px;",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", [
                _c("p", {
                  staticClass: "mb-0 font-weight-bold",
                  domProps: { innerHTML: _vm._s(item.display_title) },
                }),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }