var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-2 link-color", attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-2" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("p", [
                  _vm._v(
                    "With Premium search category you can search across your entire library of Premium titles or refine search results using available filters including:"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    Category / Location - narrow by category grouping from available contents "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    Years - narrow by specific year(s) from available contents "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    Premium Titles - narrow by specific titles from available contents "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    Content Type - define content type including sections, tables, figures "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    Advanced Search - define the type of search including all these words, any of these words and near search (terms that occur within 50 words of each other)\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "font-weight-bold mt-5" }, [
                  _vm._v("You Might Try:"),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.mostPopularSearchTerms, function (item, index) {
                    return _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doSearch(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("p", { staticClass: "font-weight-bold mt-5" }, [
                  _vm._v(
                    "\n                    Looking for help?\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { tabindex: "0", role: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.initLiveChat()
                          },
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.initLiveChat()
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              )
                                return null
                              return _vm.initLiveChat()
                            },
                          ],
                        },
                      },
                      [_vm._v("Chat with us")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: "mailto:support@iccsafe.org" } }, [
                      _vm._v("Email us"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { role: "link", tabindex: "0" },
                        on: {
                          click: function ($event) {
                            return _vm.showFeedbackModal()
                          },
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.showFeedbackModal()
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              )
                                return null
                              return _vm.showFeedbackModal()
                            },
                          ],
                        },
                      },
                      [_vm._v("Submit Feedback")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("\n                        Call "),
                    _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
                      _vm._v("888-ICC-SAFE"),
                    ]),
                    _vm._v(" ("),
                    _c("a", { attrs: { href: "tel:1-888-422-7233" } }, [
                      _vm._v("888-422-7233"),
                    ]),
                    _vm._v(")\n                    "),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              !_vm.isMobilePort
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("img", {
                      staticStyle: { "max-width": "350px" },
                      attrs: {
                        alt: "",
                        src: this.asset(
                          require("../../../images/category_search.png")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }