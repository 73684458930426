import Moment from 'moment';
import BookTitle from '../../dashboard/licenses/parts/content_title';

export default {
    data(){
        return {
            isRefreshing: false
        }
    },
    props:{
        userId: {
            type: String,
            required: false
        }
    },
    components: {
        Moment,
        BookTitle
    },
    methods: {
        getSubscriptionStatus(item) {
            return item.isCanceled ? 'Canceled' : item.status;
        },
        getStatusClass(item) {
            return (item.status == 'Expired'
            || item.isCanceled) ? 'red' : 'green';
        },
        sortTitles(data) {
            let return_data = _.sortBy(data, function (el) {
                return el.document_info.title;
            });
            for (var i = 0; i < return_data.length; i++) {
                return_data[i].year_title = this.getBookTitle(return_data[i].document_info);
            }

            return return_data;
        },
        getTabRequestMethod(page){
            switch (page) {
                case 'PersonalUse':
                    this.getSelfAssignedSubscription();
                    break;
                case 'AssignedByMe':
                    this.getAssignedByMeSubscriptions();
                    break;
                case 'AssignedToMe':
                    this.getAssignedToMeSubscriptions();
                    break;
                case 'Concurrent':
                    this.getConcurrentCodes();
                    break;
                default:
                    this.getUserAllSubscriptions();
            }
        },
        refreshSubscriptions(page){
            this.isRefreshing = true;
            this.$http.get(Routing.generate('sync_shop_purchase', {userId:this.userId}))
            .then(response => {
                this.$session.set('switch-to-license-tab', page);
                this.reloadSubscriptions();
            }).then(resposne => {
                this.isRefreshing = false;
            });
        },
        printEdition(data) {
            if (!_.isUndefined(data)) {
                return data.version;
            }
        }
    }
}
