<template>
    <v-row justify="center">
        <v-col
            :cols="isMobilePort ? 12 : 4">
            <v-sheet
                class="fill-height rounded-lg"
                rounded
                style="border: 1px solid #E0E0E0;"
                >
                <v-card-text class="pa-6">
                    <h2 class="fs-24 font-weight-bold">
                        <v-icon color="primaryMedium">
                            mdi-lock-open-variant-outline
                        </v-icon>
                        Basic
                    </h2>
                    <p class="fs-16 font-weight-medium my-6">
                        Access a limited selection of read-only titles for free.
                    </p>
                    <div>
                        <v-btn
                            outlined
                            target="_blank"
                            class="font-weight-bold"
                            :href="!isActiveUser ? this.getRoute('signup') : this.getRoute('sections_search_page')">
                            {{ !isActiveUser ? 'Sign Up' : 'View all titles' }}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-sheet>
        </v-col>
        <v-col :cols="isMobilePort ? 12 : 4">
            <v-sheet
                class="fill-height rounded-lg"
                rounded
                style="border: 1px solid #E0E0E0;"
                >
                <v-card-text class="pa-6">
                    <h2 class="fs-24 font-weight-bold">
                        <v-icon color="cdpBlue">
                            mdi-account-group-outline
                        </v-icon>
                        Teams
                    </h2>
                    <p class="fs-16 font-weight-medium my-6">
                        Empower collaboration within your organization.
                    </p>
                    <div>
                        <v-btn
                            outlined
                            class="font-weight-bold"
                            :href="`mailto:${this.$getConst('sales_email_address')}`">
                            Contact Sales
                        </v-btn>
                        <v-btn
                            text
                            target="_blank"
                            :href="this.getRoute('concurrent-access')"
                            class="font-weight-bold">
                            Learn More
                        </v-btn>
                    </div>
                </v-card-text>
            </v-sheet>
        </v-col>
    </v-row>
</template>
<script>
import mobile from '../inc/mixins/mobile';

export default {
    mixins:[mobile]
}
</script>