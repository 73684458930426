var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.isMobilePort,
        "max-width": "700px",
        scrollable: "",
      },
      model: {
        value: _vm.associated_books,
        callback: function ($$v) {
          _vm.associated_books = $$v
        },
        expression: "associated_books",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "max-height": "600px" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v("\n            Associated Books\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: { click: _vm.dialogClose },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("i", [
                      _vm._v(
                        "\n                        This book has associated titles or versions available."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                        Please select one of the available titles to view the code book contents.\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.associated_books_array, function (book, index) {
                return _vm.associated_books_array
                  ? _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-card",
                              { staticClass: "associated-books-card" },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.fireRedirectToBookToc(book)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { align: "center" } },
                                      [
                                        _c("v-col", { attrs: { cols: "3" } }, [
                                          _c("img", {
                                            staticClass: "cover",
                                            attrs: {
                                              src: _vm.getBookCover(book),
                                              width: "100%",
                                            },
                                            on: {
                                              error: _vm.loadDefaultBookCover,
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-col", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "subtitle-2 font-weight-bold mb-0 primary--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  book.display_title ||
                                                    book.title
                                                ) +
                                                  " - " +
                                                  _vm._s(book.year.year)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          book.print_version_year
                                            ? _c(
                                                "p",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.getBookPrintingInfo(
                                                          book
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.dialogClose } },
                [_vm._v("\n                Close\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }