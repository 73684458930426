import {RandomUtils} from "../util/RandomUtils";

const SESSION_KEY = "rt_id";

let _uid = null;

export class SessionStore {

    /**
     * Creates a session or resumes the current one based on SessionStorage
     * No harm in calling it twice
     */
    static start() {

        if (sessionStorage.getItem(SESSION_KEY)) {
            _uid = sessionStorage.getItem(SESSION_KEY);
            console.log(`Resume Session: ${_uid}`);
        } else {

            // chance of generating same ID twice is very small, so checking if already in database is not necessary
            const uid = RandomUtils.timestampFirstUid();
            sessionStorage.setItem(SESSION_KEY, uid + "");

            _uid = uid;
            console.log(`New Session: ${uid}`);
        }
    }

    static getId() {
        return _uid;
    }
}
