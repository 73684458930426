<template>
    <v-system-bar
        v-if="!hideBanner"
        id="app-system-bar"
        app
        fixed
        window
        ref="systemBar"
        color="accent2Light"
        height="auto"
        class="pa-0">

        <div class="w-full">
            <switch-user-banner></switch-user-banner>

            <system-notification-banner :message="systemMessage"
                                        v-show="systemMessageEnabled"></system-notification-banner>
        </div>

    </v-system-bar>
</template>

<script>
import SwitchUserBanner from "./banners/SwitchUserBanner";
import SystemNotificationBanner from "./banners/SystemNotificationBanner";
import {AppParameters} from "../AppParameters";

export default {
    components: {
        SwitchUserBanner,
        SystemNotificationBanner,
    },
    data() {
        return {
            barHeight: null,
            systemMessageEnabled: AppParameters.info_banner_enabled,
            systemMessage: AppParameters.info_banner_message
        }
    },
    computed: {
        switchUserActive() {
            return this.$store.getters.getSwitchUser !== null;
        }
    }
}
</script>

<style lang="scss">
#app-system-bar {
  z-index: 200;
}
</style>
