var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-item-breadcrumb" },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.getBookUrl([], _vm.getDocumentId()),
            target: "_blank",
          },
          on: { click: _vm.clickedFreeBreadcrumbsLink },
        },
        [_vm._v(_vm._s(_vm.documentTitle))]
      ),
      _vm._v(" "),
      _vm.breadcrumbs
        ? [
            _vm.isChapter(_vm.breadcrumbs)
              ? _c("span", [
                  _c("span", { attrs: { color: "primary" } }, [_vm._v("/")]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.generateBaseSlug(_vm.breadcrumbs),
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          _vm.registerClick(
                            _vm.breadcrumbs,
                            _vm.getDocumentId()
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.breadcrumbs.chapter))]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isSection(_vm.breadcrumbs)
              ? _c("span", [
                  _c("span", { attrs: { color: "primary" } }, [_vm._v("/")]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getSectionUrl(_vm.breadcrumbs),
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          _vm.registerClick(
                            _vm.breadcrumbs,
                            _vm.getDocumentId()
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.breadcrumbs.section))]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isSubsection(_vm.breadcrumbs)
              ? _c("span", [
                  _c("span", { attrs: { color: "primary" } }, [_vm._v("/")]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.getSubSectionUrl(
                          _vm.breadcrumbs,
                          this.meta.contentid
                        ),
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          _vm.registerClick(
                            _vm.breadcrumbs,
                            _vm.getDocumentId()
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.breadcrumbs.subSection))]
                  ),
                ])
              : _vm._e(),
          ]
        : [
            _vm.isBusy
              ? _c("v-progress-linear", {
                  staticClass: "breadcrumb-linear-container",
                  attrs: {
                    color: "primary",
                    "buffer-value": "0",
                    stream: "",
                    reverse: "",
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }