var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { attrs: { id: "container" } }, [
    _c(
      "div",
      {
        staticClass: "d-flex flex-column mx-auto py-md-8",
        attrs: { id: "inner_container" },
      },
      [
        _c(
          "h2",
          { staticClass: "oxygen accent2--text text-center px-md-8 mb-8" },
          [
            _vm._v(
              "\n            Sign up to receive updates on latest Premium features, contents and events.\n        "
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.isFormSent
          ? _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-text-field", {
                  staticClass: "rounded-0",
                  attrs: {
                    disabled: _vm.isLoading,
                    filled: "",
                    "hide-details": "",
                    "background-color": "white",
                    color: "black",
                    label: "Your Email:",
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "cdpBlue rounded-0",
                    attrs: {
                      "x-large": true,
                      dark: "",
                      loading: _vm.isLoading,
                    },
                    on: { click: _vm.subscribeNow },
                  },
                  [_vm._v("\n                Subscribe Now\n            ")]
                ),
              ],
              1
            )
          : _c("div", [
              _c("h3", { staticClass: "white--text text-center" }, [
                _vm._v("Thank you!"),
              ]),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }