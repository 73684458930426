
export default {
    data() {
        return {
            loaded: false,
            current_tab: 'Chapters',
            link_target_match: false
        }
    },
    computed: {
        stillLoading() {
            if (this.loaded) {
                return false;
            }
            return true;
        },
        chapterArray() {
            return this.$store.getters.getChapters;
        },
        chapterIndex() {
            if (this.chapterCheck) {
                if (!this.isXML) {
                    return _.findIndex(this.chapterArray, {id: _.toNumber(this.activeChapter)});
                }
                return _.findIndex(this.chapterArray, {content_id: _.toNumber(this.activeChapter)});
            }
        },
        currentTab() {
            return this.current_tab;
        },
    },
    methods: {
        triggerContent(data) {
            const isFromLanding = this.isTitleLanding;
            this.isTitleLanding = false;
            let dataUrl = '';
            let menuAction = !!data.action;
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            let contentId = params.contentId;
            if(data.section_number){
                this.$store.commit('setActiveSection', data.section_number)
            }
            if (!this.isXML) {
                let pdfTitle = data.title;
                if (this.$vuetify.breakpoint.xsOnly && this.loaded === true) {
                    EventBus.fire('toggle-mobile-legend', false);
                }
                this.activeChapter = data.id;
                this.updateUrl(data);
                data = _.assign({}, data, this.activeDocumentInfo);
                if(!menuAction){
                    let chapterTtile = (undefined != pdfTitle && '' != pdfTitle) ? pdfTitle : data.title;
                    EventBus.fire('update-chapter-title', chapterTtile);
                    EventBus.fire('update-pdf-url', data);
                    if(isFromLanding){
                        if(data.is_astm){
                            let astmDocumentInfoId = this.activeDocumentInfo.id;
                            this.logAstmAccess(astmDocumentInfoId, contentId)
                                .then((response)=>{
                                    location.reload();
                                })
                        } else {
                            location.reload();
                        }
                    }
                }
            } else {
                if (data.link === undefined) {
                    this.activeChapter = data.content_id;
                    if (data.document_slug && data.chapter_slug) {
                        dataUrl = Routing.generate(
                            'content_document_slug',
                            {
                                'documentSlug': data.document_slug,
                                'chapterSlug': data.chapter_slug
                            }
                        );
                        if (data.examId && '' !== data.examId) {
                            dataUrl = Routing.generate(
                                'content_document_slug',
                                {
                                    'documentSlug': data.document_slug,
                                    'chapterSlug': data.chapter_slug,
                                    'examId': data.examId
                                }
                            );
                        }
                    } else if (data.codeInsightFilter) {
                        dataUrl = Routing.generate('content_document', { 'documentId': this.activeDocumentInfo.document_id, 'chapterId': data.content_id })+'#text-id-'+data.section_number;
                    } else {
                        dataUrl = Routing.generate('content_document', { 'documentId': this.activeDocumentInfo.document_id, 'chapterId': data.content_id });
                    }
                } else {
                    let element = document.getElementById(data.link.id);
                    if (element) {
                        if (this.$vuetify.breakpoint.xsOnly) {
                            EventBus.fire('toggle-mobile-legend', false);
                        }
                        // Clear out CSS if active chapter.
                        let activeChapter = document.getElementById('section-list-'+this.activeChapter);
                        if (activeChapter && activeChapter.classList.contains('lightgreen')) {
                            activeChapter.classList.remove('lightgreen');
                        }
                        if(menuAction){
                            if(data.examId) {
                                dataUrl = window.location.pathname + '?examId=' + data.examId +'#'+data.link.id;
                            } else {
                                dataUrl = window.location.pathname+'#'+data.link.id;
                            }
                        }else{
                            this.scrollToTarget(element, data);
                        }
                    } else {
                        let element = document.getElementById('text-id-' + data.link.id);
                        // Recheck element if not found with the exact content id
                        if(!element) {
                            element = document.getElementById('text-id-' + data.link.id + '-0');
                        }
                        if(element) {
                            this.scrollToTarget(element, data);
                        } else {
                            let _this = this;
                            _.eachDeep(this.chapterArray, function(value, key, parent, context) {
                                if (key === "content_id" && (value === data.content_id || value === data.link.id)) {
                                    _this.link_target_match = true;
                                    let chapter = _this.chapterArray[_.toNumber(context.path.split('.')[0].match(/\d+/)[0])];
                                    chapter = _.isUndefined(chapter) ? parent : chapter;

                                    if (chapter.document_slug && chapter.chapter_slug) {
                                        if(data.examId) {
                                            dataUrl = Routing.generate(
                                                'content_document_slug',
                                                {
                                                    'documentSlug': chapter.document_slug,
                                                    'chapterSlug': chapter.chapter_slug
                                                }
                                            ) + '?examId=' + data.examId +'#text-id-'+data.content_id;
                                        } else {
                                            dataUrl = Routing.generate(
                                                'content_document_slug',
                                                {
                                                    'documentSlug': chapter.document_slug,
                                                    'chapterSlug': chapter.chapter_slug
                                                }
                                            )+'#text-id-'+data.content_id;
                                        }
                                    } else {
                                        dataUrl = Routing.generate('content_document', { 'documentId': _this.activeDocumentInfo.document_id, 'chapterId': data.content_id })+'#text-id-'+data.content_id;
                                    }
                                }
                            });
                            // Check by chapter id if content id did not matched
                            if(undefined != data.parentContentID && !_this.link_target_match) {
                                let chapterIndex = _.findIndex(this.chapterArray, {content_id: _.toNumber(data.parentContentID)});
                                if(chapterIndex > 0) {
                                    let chapter = _this.chapterArray[chapterIndex];
                                    if (undefined != chapter && chapter.document_slug && chapter.chapter_slug) {
                                        if(data.examId) {
                                            dataUrl = Routing.generate(
                                                'content_document_slug',
                                                {
                                                    'documentSlug': chapter.document_slug,
                                                    'chapterSlug': chapter.chapter_slug
                                                }
                                            )+ '?examId=' + data.examId +'#text-id-'+data.content_id;
                                        } else {
                                            dataUrl = Routing.generate(
                                                'content_document_slug',
                                                {
                                                    'documentSlug': chapter.document_slug,
                                                    'chapterSlug': chapter.chapter_slug
                                                }
                                            )+'#text-id-'+data.content_id;
                                        }
                                    } else {
                                        dataUrl = Routing.generate(
                                            'content_document',
                                            {
                                                'documentId': this.activeDocumentInfo.document_id,
                                                'chapterId': data.content_id
                                            }
                                        )+'#text-id-'+data.content_id;
                                    }
                                }
                            }
                        }
                    }
                }
                if (isFromLanding) {
                    window.location = dataUrl;
                }
                if(menuAction){
                    EventBus.fire('context-menu-action', {
                        'action': data.action,
                        'dataUrl' : dataUrl
                    });
                } else if(data.link === undefined || dataUrl !== '') {
                    let _this = this;
                    let activeChapter = document.getElementById('section-list-'+_this.activeChapter);
                    if (activeChapter && activeChapter.classList.contains('lightgreen')) {
                        activeChapter.classList.remove('lightgreen');
                    }
                    if(data.parentContentID) {
                        let sectionMatch = false;
                        let dataId = data.content_id;
                        let chapterTitle = '';
                        _.eachDeep(this.chapterArray, function (dataId, key, parent, context) {
                            if (key === "sub_sections" && _.size(context.path.sub_sections) > 0 && !_.isUndefined(context.path.title)) {
                                chapterTitle = context.path.title
                            }
                            if (key === "content_id" && (dataId === data.content_id)) {
                                let chapter = _this.chapterArray[_.toNumber(context.path.split('.')[0].match(/\d+/)[0])];
                                chapter = _.isUndefined(chapter) ? parent : chapter;

                                if (chapter && chapter.content_id) {
                                    sectionMatch = true;
                                    _this.activeChapter = chapter.content_id;
                                    _this.$store.commit('setActiveHash', dataId);
                                    let expandParentId = data.parentContentID ? data.parentContentID : dataId;
                                    _this.setNavigationExpanded(expandParentId);
                                    EventBus.fire('update-chapter-title', chapterTitle);
                                    EventBus.fire('get-content', {'chapterId':data.clickedFromMyNotes ? expandParentId : chapter.content_id, 'sectionId': dataId});
                                    if (!isFromLanding) {
                                        history.pushState(null, null, dataUrl);
                                    }
                                }
                            }
                        });
                        if(dataUrl && !sectionMatch) {
                            let chapterIndex = _.findIndex(
                                    this.chapterArray, {
                                        content_id: _.toNumber(data.parentContentID)
                                    }
                                );
                            if(chapterIndex) {
                                let chapter = this.chapterArray[chapterIndex];
                                if (chapter && chapter.content_id) {
                                    this.activeChapter = chapter.content_id;
                                    this.$store.commit('setActiveHash', _.toNumber(data.parentContentID));
                                    this.setNavigationExpanded(data.parentContentID);
                                    EventBus.fire('update-chapter-title', chapterTitle);
                                    EventBus.fire('get-content', {
                                        'chapterId':data.clickedFromMyNotes ? data.parentContentID : chapter.content_id,
                                        'sectionId': _.toNumber(data.parentContentID)
                                    });
                                    if (!isFromLanding) {
                                        history.pushState(null, null, dataUrl);
                                    }
                                }
                            }
                        }
                    } else {
                        window.scrollTo({ top: 0 });
                        _this.activeChapter = data.content_id;
                        _this.$store.commit('setActiveHash', data.content_id);
                        _this.setNavigationExpanded(data.content_id);
                        EventBus.fire('update-chapter-title', data.title);
                        EventBus.fire('get-content', data.content_id);
                        if (!isFromLanding) {
                            history.pushState(null, null, dataUrl);
                        }
                    }
                }
            }
            this.setTabTitle(data);
            this.loaded = true;
        },
        setNavigationExpanded(id) {
            let expand_active_item = _.find(this.$store.getters.getExpandedActive, {content_id: _.toNumber(id)});
            if (expand_active_item == undefined) {
                this.$store.commit('addExpandedActive', {content_id: id, active: true});
            }
        },
        scrollToTarget(element, data = {}) {
            let navigationBarHeight = document.getElementById('navigation-app') ?
                document.getElementById('navigation-app').getBoundingClientRect().height :
                0;
            let stickyHeight = document.getElementById('sticky-chapter-info') ?
                document.getElementById('sticky-chapter-info').getBoundingClientRect().height :
                0;
            let systemBar = document.getElementById('app-system-bar') ?
                document.getElementById('app-system-bar').getBoundingClientRect().height :
                0;
            this.$nextTick(() => {
                let realElement = element;
                // try to return real dom element by either the exact id 
                // OR try to get the first one that has trailing number (applicable with reference pages)
                if (_.isString(element)) {
                    realElement = document.querySelector(element);
                    if (!realElement) {
                        let sectionNumber = element + '-0'
                        let section = document.querySelector(sectionNumber);
                        if (section) {
                            realElement = section;
                        }
                    }
                }
                this.$scrollTo(realElement, 10, { offset: (navigationBarHeight + stickyHeight + systemBar) * -1 });

                // highlight that section
                if (element instanceof HTMLElement) {
                    let actionWrapper = element.querySelector('[id^=action-wrapper]');
                    if (actionWrapper) {
                        actionWrapper.click();
                    }
                }

                this.updateUrlHistory(element, data);
            });
        },
        updateUrlHistory(sectionElement, data = {}) {

            if (sectionElement === null) {
                return;
            }

            for (let i = 0; i < 7; i++) {
                if (sectionElement.tagName != 'SECTION') {
                    sectionElement = sectionElement.parentElement;
                    continue;
                }
                let iterationElement = sectionElement;
                let chapterSlug = "";
                for (let ii=0;ii<7;ii++) {
                    chapterSlug = iterationElement.querySelector('.section-action-wrapper').getAttribute("data-chapter-slug");
                    if (chapterSlug) {
                        break;
                    }
                    var chapter = iterationElement.querySelector('.section-action-wrapper').getAttribute("data-chapter-title");
                    var iterationChapterSlug = chapter.trim().replace(/\s\s+/g, ' ').toLowerCase().split(' ');
                    if (iterationChapterSlug[0] == 'chapter') {
                        chapterSlug = iterationChapterSlug.join('-');
                        break;
                    }
                    iterationElement = iterationElement.parentElement;
                }
                let sectionId = sectionElement.id;
                let href = window.location.href;
                href = href.split('#')[0].split('/').splice(0, 5).join('/');

                let examId = null;
                if(data.examId) {
                    examId = data.examId;
                } else {
                    const params = new Proxy(new URLSearchParams(window.location.search), {
                        get: (searchParams, prop) => searchParams.get(prop),
                    });
                    examId = params.examId && '' !== params.examId ? params.examId : examId;
                }

                if(examId) {
                    history.pushState(null, null, href + '/' + chapterSlug + '?examId=' + examId +'#' + sectionId);
                } else {
                    history.pushState(null, null, href + '/' + chapterSlug + '#' + sectionId);
                }
                break;
            }
        },
        pdfEvent(id) {
            EventBus.fire('pdf-event', id);
        },
        updateUrl(data) {
            let url_string = window.location.toString();
            let url = window.location.pathname.split('/');
            let i;

            let dataUrl = '';
            if (data.pdf_chapter_slug) {
                dataUrl = Routing.generate('content_document_slug', {'documentSlug': data.pdf_document_slug, 'chapterSlug': data.pdf_chapter_slug});
            } else {
                dataUrl = Routing.generate('content_show_chapter_content', {'chapterId': data.id});
            }

            for (i = 0; i < url.length; i++) {
                if(url[i] === 'document' || (url[i] === 'content' && !_.includes(url, 'chapter'))) {
                    if(data.action) {
                        let object = {
                            'action': data.action,
                            'dataUrl': dataUrl
                        };
                        EventBus.fire('context-menu-action', object);
                    }else{
                        EventBus.fire('update-chapter-title', data.title);
                        EventBus.fire('get-content', data.id);
                        history.pushState(null, null, dataUrl);
                    }
                }else if(url[i] === 'chapter') {
                    let new_url = url_string.replace(url[i+1], data.id);
                    if(data.action) {
                        let object = {
                            'action' : data.action,
                            'dataUrl': dataUrl
                        };
                        EventBus.fire('context-menu-action', object);
                    }else{
                        document.title = this.activeDocumentInfo.title+' - '+data.title;
                        history.pushState(null, null, new_url);
                        return;
                    }
                }
           }
        },
        showLandingPage(slug) {
            this.goToRoute('isTitleLanding', {documentSlug: slug})
        },
        setTabTitle(data) {
            let bookTitle = '';
            let chapterShortCode = '';
            let chapterSlug = '';

            if(!_.isUndefined(this.activeDocumentInfo)) {
                bookTitle = this.activeDocumentInfo.book_year + ' ' + this.activeDocumentInfo.title;
            }

            chapterSlug = data.chapter_slug ? data.chapter_slug : data.pdf_chapter_slug;

            if(!_.isUndefined(chapterSlug) && chapterSlug.length) {
                let parts = chapterSlug.split('-');
                if(chapterSlug.indexOf('chapter') != -1 || chapterSlug.indexOf('appendix') != -1) {
                    chapterShortCode = parts[0];
                    if (typeof parts[1] !== 'undefined') {
                        chapterShortCode = chapterShortCode + ' ' + parts[1];
                    }
                } else {
                    chapterShortCode = parts.join(' ');
                }
            }

            if(bookTitle.length && chapterShortCode.length) {
                document.title = bookTitle + ' - ' + chapterShortCode.toUpperCase();
            }
        }
    },
    created() {
        EventBus.listen('show-book-content', (data) => this.triggerContent(data));
        EventBus.listen('open-notes-tab', () => this.current_tab = 'Notes');
        EventBus.listen('scroll-to-target', (element) => this.scrollToTarget(element));
    }
}
