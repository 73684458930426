export class RandomUtils {

    /**
     * inclusive, exclusive
     *
     * @param {number} min
     * @param {number} max
     * @return {number}
     */
    static randInt(min, max) {
        return Math.floor(
            Math.random() * (max - min) + min
        )
    }

    static timestampFirstUid() {

        const timestampMs = (new Date()).getTime();

        // always 4 digits in length
        const randomPad = RandomUtils.randInt(1000, 9999);

        return Number(timestampMs.toString().concat(randomPad.toString()));
    }
}
