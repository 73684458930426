var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.alert.message
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-alert",
                {
                  staticClass: "mb-0 multi-line",
                  attrs: { type: _vm.alert.type },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "white--text",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.alert.message = ""
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    mdi-close\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3320830712
                  ),
                },
                [_vm._v(_vm._s(_vm.alert.message) + "\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }