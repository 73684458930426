<template>
    <v-container
        class="text-center pa-0"
        :class="{'white--text primary': dark}"
        id="login-form-component">
        <v-row v-if="showLogo !== false">
            <v-col>
                <img :src="asset(require('../../../images/palogo.jpg'))" width="95%" />
            </v-col>
        </v-row>
        <v-row v-if="hasError">
            <v-col>
                <v-chip
                    class="mx-1"
                    color="red"
                    text-color="white"
                    v-for="(error, key) in errorsAll"
                    :key="key">
                    {{ error }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row :dense="dense">
            <v-col>                
                <v-form
                    v-model="valid"
                    lazy-validation
                    :id="isModal ? 'modal_login_form' : 'login_form'"
                    method="post"
                    action="/login"
                    @submit.prevent="loginSubmit"
                    :class="{ 'mx-4': $vuetify.breakpoint.xsOnly }">
                    <v-text-field
                        solo
                        :flat="dark"
                        :dense="dense"
                        hide-details
                        id="emailAddress"
                        name="email"
                        maxlength="160"
                        v-model="email_address"
                        :class="{ 'input-group--dirty': dirtyEmail }"
                        placeholder="Email address"
                        class="border"
                        required>
                    </v-text-field>
                    <v-text-field
                        solo
                        :flat="dark"
                        :dense="dense"
                        hide-details
                        id="password"
                        name="password"
                        maxlength="50"
                        type="password"
                        v-model="password"
                        :class="{ 'input-group--dirty': dirtyPwd }"
                        placeholder="Password"
                        class="mt-4 border"
                        required>
                    </v-text-field>
                    <input type="hidden" v-model="csrfToken" name="_csrf_token">
                    <input type="hidden" name="_target_path" :value="currentPath">
                    <v-checkbox
                        :color="dark ? 'white' : null"
                        :dark="dark"
                        class="mt-2"
                        name="rememberMe"
                        v-model="rememberMe"
                        value="1">
                        <template v-slot:label>
                            <span
                                class="caption text-left"
                                :class="{'white--text': dark}">
                                Keep me logged in for 30 days
                            </span>
                        </template>
                    </v-checkbox>
                    <v-btn
                        dark
                        :small="dense"
                        color="accent2"
                        @click="goToRoute('signup')"
                        class="light">
                        Join Now
                    </v-btn>
                    <v-btn
                        dark
                        :small="dense"
                        color="accent2"
                        @click="loginSubmit"
                        type="submit"
                        class="light">
                        Sign In
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            showLogo: {
                required: false,
                type: Boolean
            },
            dense: {
                required: false,
                type: Boolean
            },
            isModal: {
                required: false,
                type: Boolean
            },
            dark: {
                required: false,
                type: Boolean
            },
            fromCart: {
                required: false,
                type: Boolean
            }
        },
        data() {
            return {
                valid: true,
                dirtyEmail: null,
                dirtyPwd: null,
                email_address: '',
                password: '',
                errors: [],
                csrfToken: document.getElementById('app').attributes['data-csrf-token'].value,
                rememberMe: [],
                currentPath: this.fromCart ? this.getRoute('cart_page') : window.location.href,
            }
        },
        computed: {
            errorsAll() {
                if (_.size(this.errors) > 0) {
                    return this.errors;
                }
            },
            hasError() {
                return _.size(this.errors) > 0;
            }
        },
        methods: {
            loginSubmit() {
                this.errors = [];
                if (!this.email_address) {
                    this.errors.push('Email Required');
                    document.getElementById("emailAddress").parentNode.classList.add('validate');
                } else if (!this.validEmail(this.email_address)) {
                    this.errors.push('Not a valid Email Address');
                    document.getElementById("emailAddress").parentNode.classList.add('validate');
                } else {
                    document.getElementById("emailAddress").parentNode.classList.remove('validate');
                }

                if (!this.password) {
                    this.errors.push('Password Required');
                    document.getElementById("password").parentNode.classList.add('validate');
                } else {
                    document.getElementById("password").parentNode.classList.remove('validate');
                }

                if(this.errors.length === 0) {
                    let formId = this.isModal ? 'modal_login_form' : 'login_form';
                    document.getElementById(formId).submit();
                }
            },
            validEmail:function(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }
    }
</script>

<style lang="scss">
    #login-form-component {
        .v-input--selection-controls__input {
            margin-right: 0;
        }
    }
</style>
