var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      style: _vm.dialogStyle,
      attrs: { fullscreen: "", "content-class": "mobile-chapter-content" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "div",
        { staticClass: "white", attrs: { id: "mobile-chapter-header" } },
        [
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-3" },
            [
              _c(
                "v-col",
                { staticClass: "py-2" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h4", [
                          _c(
                            "a",
                            {
                              staticClass: "black--text",
                              on: {
                                click: function ($event) {
                                  return _vm.bookLink(_vm.documentInfo)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.getBookTitle(_vm.documentInfo)))]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "close",
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "black--text" }, [
                                _vm._v("clear"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.documentInfo &&
                  _vm.isSingleTitle(_vm.documentInfo) &&
                  "" === _vm.examId
                    ? _c(
                        "v-row",
                        { attrs: { dense: "", align: "end" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0" },
                            [
                              _vm.documentInfo.title_type === "Custom Code"
                                ? _c("adoption-profile", {
                                    attrs: { documentInfo: _vm.documentInfo },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.documentInfo.supports_versions
                                ? [
                                    _c("title-reference", {
                                      attrs: {
                                        "document-id":
                                          _vm.documentInfo.document_id,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "auto" } },
                            [
                              _vm.documentInfo &&
                              _vm.isSingleTitle(_vm.documentInfo) &&
                              "" === _vm.examId
                                ? _c("favorite-icon", {
                                    attrs: {
                                      document_id: _vm.documentInfo.document_id,
                                      "show-message": false,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.showTabs
            ? _c(
                "v-tabs",
                {
                  attrs: { height: "40px", grow: "", color: "primary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    {
                      class:
                        _vm.activeTab === 0
                          ? "primary--text"
                          : "grey--darken-1",
                    },
                    [
                      _vm.isContentRestricted(_vm.documentInfo) &&
                      "" === _vm.examId
                        ? _c("v-icon", { staticClass: "pr-1" }, [
                            _vm._v(
                              "\n                    mdi-lock-outline\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v("\n                Contents\n            "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    {
                      class:
                        _vm.activeTab === 1
                          ? "primary--text"
                          : "grey--darken-1",
                      on: {
                        click: function ($event) {
                          return _vm.showUserNotes()
                        },
                      },
                    },
                    [
                      _vm.freeView
                        ? _c("v-icon", { staticClass: "pr-1" }, [
                            _vm._v(
                              "\n                    mdi-lock-outline\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v("\n                Notes\n            "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isCodeInsightsFilterEnabled() && _vm.showInsightTab
                    ? _c(
                        "v-tab",
                        {
                          class:
                            _vm.activeTab === 2
                              ? "primary--text"
                              : "grey--darken-1",
                          on: {
                            click: function ($event) {
                              return _vm.showUserNotes()
                            },
                          },
                        },
                        [
                          _vm.freeView
                            ? _c("v-icon", { staticClass: "pr-1" }, [
                                _vm._v(
                                  "\n                    mdi-lock-outline\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v("\n                Insights\n            "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "white", attrs: { id: "chapter-wrapper-mobile" } },
        [
          _c(
            "keep-alive",
            [
              _vm.activeTab === 0
                ? _c("chapter-panel", {
                    attrs: {
                      "document-info": _vm.documentInfo,
                      "exam-id": _vm.examId,
                    },
                  })
                : _vm.activeTab === 1
                ? _c("notes-panel", {
                    attrs: {
                      "free-view": _vm.freeView,
                      "document-info": _vm.documentInfo,
                    },
                  })
                : _vm.activeTab === 2 &&
                  _vm.isCodeInsightsFilterEnabled() &&
                  _vm.showInsightTab
                ? _c("panel-insights", {
                    attrs: {
                      codeInsightsData: _vm.codeInsightsData,
                      "free-view": _vm.freeView,
                      "document-info": _vm.documentInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }