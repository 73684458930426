var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-banner-outer", attrs: { id: "environment_banner" } },
    [
      _c(
        "div",
        {
          staticClass: "fixed-banner-inner",
          style: {
            backgroundColor: _vm.bannerbackgroundcolor,
            color: _vm.bannertextcolor,
          },
        },
        [
          _c("div", { staticClass: "top-banner-message" }, [
            _vm._v(
              " " +
                _vm._s(_vm.bannermessage) +
                " *** Version: " +
                _vm._s(_vm.appversion) +
                " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }