<template>
    <v-row
        v-model="hasPremiumSubscription"
        v-if="'' !== userAccessLevel"
        dense
        align="center">
        <v-spacer></v-spacer>
        <v-col
            class="pa-0"
            cols="auto">
            <img
                height="12px"
                :src="asset(require('../../../images/icons/diamond_'+ userAccessLevel +'.jpg'), 'default')"/>
        </v-col>
        <v-col class="pa-0 text-left">
            <p
                class="mb-0 caption accent2--text"
                v-html="titleCase(userAccessLevel)">
            </p>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
    export default {
        computed: {
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
            userAccessLevel() {
                return this.$store.getters.getUser ? this.$store.getters.getUser.displaySubscriptionType : '';
            },
        },
        methods: {
            titleCase(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            getSubscriptionType() {
                if(!this.$session.has('subscriptionType')) {
                    this.$http.get(Routing.generate('get_subscription_type'))
                        .then(response => {
                            this.setSubscriptionType(response.data.subscriptionType);
                        });
                }
            },
            setSubscriptionType(subscriptionType) {
                if(!this.$session.has('subscriptionType')) {
                    this.$session.set('subscriptionType', subscriptionType);
                }
            }
        },
        created() {
            if(this.isLoggedIn && (!this.$session.has('subscriptionType') || '' == this.$session.get('subscriptionType'))) {
                this.getSubscriptionType();
            }

            EventBus.listen('set-subscription-type', (data) => this.setSubscriptionType(data));
        }
    }
</script>
