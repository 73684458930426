<template>
    <v-card class="flex d-flex flex-column">
        <v-card-title
            class="py-2 white--text"
            :class="getPrimaryColor()">
            <v-row
                dense
                align="center"
                justify="center">
                <v-col cols="auto">
                    <v-img
                        :src="asset(require('../../../images/icons/diamond_'+(singleCollection ? 'collection'
                        :type)+'.png'),'default')">
                    </v-img>
                </v-col>
                <v-col cols="auto">
                    <span class="ma-auto">
                        <template v-if="type === 'single' && !singleCollection">
                            Singles
                        </template>
                        <template v-else-if="type === 'collection' || singleCollection">
                            Collections
                        </template>
                        <template v-else>
                            Complete
                        </template>
                    </span>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="text-center px-8 flex d-flex flex-column">
            <v-row justify="center">
                <v-col cols="auto">
                    <v-img
                        max-width="90"
                        :src="asset(require('../../../images/icons/package_'+(singleCollection ? 'collection'
                        :type)+'.png'),'default')">
                    </v-img>
                </v-col>
            </v-row>
            <v-row
                v-if="!loading"
                dense>
                <v-col
                    v-if="!hasCollections"
                    class="text-center">
                    <h4>
                        <template v-if="type === 'complete'">
                            {{product.document_info.title}}
                        </template>
                        <template v-else>
                            {{product.document_info.book_year ? product.document_info.book_year
                                : product.document_info.year.year + ' ' + product.document_info.title}}
                        </template>
                    </h4>
                </v-col>
                <v-col v-else>
                    <p class="caption mb-0 text-left">Available Collections</p>
                    <v-select
                        v-model="collectionValue"
                        solo
                        hide-details
                        dense
                        :menu-props="{ auto: true, overflowY: true }"
                        :items="collections"
                        item-text="display_title"
                        item-value="id"
                        label="Select a collection">
                    </v-select>
                </v-col>
            </v-row>
            <v-row
                dense
                align="end">
                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        transition="scale-transition"
                        type="list-item">
                    </v-skeleton-loader>
                    <template v-else>
                        <v-row dense>
                            <v-col class="text-center">
                                <p class="caption mb-0">
                                    Quantity
                                </p>
                            </v-col>
                        </v-row>
                        <v-row
                            dense
                            align="center"
                            justify="center">
                            <v-col cols="auto">
                                <v-btn
                                    fab
                                    x-small
                                    :dark="product.quantity > 1"
                                    :disabled="product.quantity === 1"
                                    color="primary"
                                    @click="decrementQuantity">
                                    <v-icon dark>remove</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    dense
                                    hide-details
                                    readonly
                                    :value="product.quantity"
                                    type="number"
                                    :min="1"
                                    solo>
                                </v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    fab
                                    dark
                                    x-small
                                    color="primary"
                                    @click="incrementQuantity">
                                    <v-icon dark>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <p class="mb-0 mt-5 font-weight-bold">
                                    Per License
                                </p>
                                <p v-if="isYearly" class="caption mb-0">
                                    ${{ pricePerItem(product, isMember) }}
                                    {{ 'Year' }}
                                </p>
                                <p v-else-if="isThreeYearly" class="caption mb-0">
                                    ${{ pricePerItem(product, isMember) }}
                                    {{ 'for 3 Years' }}
                                </p>
                                <p v-else class="caption mb-0">
                                    ${{ pricePerItem(product, isMember) }}
                                    {{ 'Month' }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="center-text">
                                <p class="mb-0 font-weight-bold">
                                    Total
                                </p>
                                <h1 :class="type === 'complete' ? 'accent2--text' : 'primary--text'">
                                    ${{ getProductTotalPrice(product, isMember) }}
                                </h1>
                                <p
                                    v-if="isYearly"
                                    class="mb-0">
                                    (${{ (getProductTotalPrice(product, isMember) / 12).toFixed(2) }}/Month)
                                </p>
                                <p
                                    v-if="isThreeYearly"
                                    class="mb-0">
                                    (${{ (getProductTotalPrice(product, isMember) / 36).toFixed(2) }}/Month)
                                </p>
                            </v-col>
                        </v-row>
                    </template>
                    <v-row>
                        <v-col>
                            <v-btn
                                :id="product.document_info.document_id"
                                dark
                                block
                                :color="getPrimaryColor()"
                                :disabled="showSubscribeButton"
                                @click="addItem">
                                <template v-if="!addToCartLoading">
                                    Subscribe Now
                                </template>
                                <template v-else>
                                    <v-progress-circular
                                        :size="20"
                                        indeterminate color="white">
                                    </v-progress-circular>
                                </template>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import PriceHelper from './../../inc/mixins/pricesHelper';

    import Duration from './duration';
    import Quantity from './quantity';

    export default {
        data() {
            return {
                loading: true,
                product: {
                    'quantity': 1,
                    'subscription_type': 'B',
                    'document_info': {},
                },
                collections: {},
                collectionValue: 0,
                addToCartLoading: false,
                showSubscribeButton: true
            }
        },
        props: {
            type: {
                required: true,
                type: String
            },
            document: {
                required: false,
                type: Object
            },
            singleCollection: {
                required: false,
                type: Boolean
            }
        },
        computed: {
            hasTitle() {
                return _.size(this.product.document_info) > 0;
            },
            hasCollections() {
                return _.size(this.collections) > 0;
            },
            isYearly() {
                return this.product && this.product.subscription_type === 'B';
            },
            isThreeYearly() {
                return this.product && this.product.subscription_type === 'C';
            }
        },
        watch: {
            collectionValue(val) {
                this.loading = true;
                this.product.document_info = _.find(this.collections, {'id': val});
                this.setProductPrices();
            },

        },
        mixins: [
            PriceHelper
        ],
        components: {
            Duration,
            Quantity
        },
        methods: {
            setSingle() {
                this.showSubscribeButton = true;
                this.product.document_info = this.document
                this.setProductPrices();
            },
            setCollection() {
                this.$http.get(Routing.generate('get_book_collections', {documentId: this.document.document_id}))
                    .then(response => {
                        if(_.size(response.data.collections) > 0) {
                            this.showSubscribeButton = true;
                            this.collections = response.data.collections;
                            this.collectionValue = response.data.collections[0].id;
                            this.product.document_info = response.data.collections[0];
                            this.$nextTick(function () {
                                this.setProductPrices();
                            });
                        }
                    });
            },
            setComplete() {
                this.$http.get(Routing.generate('get_document_by_sku', {sku: this.$getConst('premiumCompleteSku')}))
                    .then(response => {
                        this.showSubscribeButton = true;
                        this.product.document_info = response.data;
                        this.$nextTick(function () {
                            this.setProductPrices();
                        });
                    });
            },
            setProductPrices() {
                this.prices = [];
                this.$http.get(Routing.generate('get_product_price', {documentSku: this.product.document_info.sku}))
                    .then(response => {
                        this.showSubscribeButton = false;
                        this.prices = response.data;
                        this.isMember = response.data.is_member;
                        EventBus.fire('updateCartMember', this.isMember);
                        this.loading = false;
                    });
            },
            getPrimaryColor() {
                return this.type === 'complete' ? 'accent2' : 'primary'
            },
            incrementQuantity() {
                this.product.quantity = _.toInteger(this.product.quantity) + 1;
            },
            decrementQuantity() {
                if (this.product.quantity > 1) {
                    this.product.quantity = _.toInteger(this.product.quantity) - 1;
                }
            },
            addItem() {
                if (this.$store.getters.getLoggedIn !== '') {
                    this.addToCartLoading = true;
                    let data = {
                        'quantity': this.product.quantity,
                        'sku': this.product.document_info.sku,
                        'subscription_type': this.product.subscription_type
                    };
                    this.$http.get(Routing.generate('create_cart_product', {product: data}))
                        .then(response => {
                            EventBus.fire('set-cart-items-count', {
                                type: 'increment',
                                qty: data.quantity
                            });
                            EventBus.fire(
                                'show-checkout-modal',
                                {
                                    product: this.product,
                                    totalPrice: this.getProductTotalPrice(this.product, this.isMember)
                                }
                            );
                            this.addToCartLoading = false;
                        }).catch(error => {
                        this.error = true;
                        this.addToCartLoading = false;
                    });
                }else{
                    this.$session.set('cartItem', {
                        'quantity': this.product.quantity,
                        'sku': this.product.document_info.sku,
                        'subscription_type': this.product.subscription_type
                    });
                    EventBus.fire('openLoginModal', {
                        message: 'Login to add this item to your cart.',
                        fromCart: true
                    });
                }
            }
        },
        created() {
            if (this.type) {
                EventBus.listen('change-duration', (data) => this.product.subscription_type = data);
                EventBus.listen('increment-quantity-modal-'+this.type, (data) => this.incrementQuantity(data));
                EventBus.listen('decrement-quantity-modal-'+this.type, (data) => this.decrementQuantity(data));
            }
        },
        mounted() {
            switch(this.type){
                case 'single':
                    this.setSingle();
                    break;
                case 'collection':
                    this.setCollection();
                    break;
                case 'complete':
                    this.setComplete();
                    break;
            }
        }
    }
</script>
