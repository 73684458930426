<template>
    <div v-if="sectionMeta" class="pa-2 rounded-lg">
        <div class="text-center">
            <v-progress-circular
                    v-if="loadingReferenceContent"
                    indeterminate
                    color="primary">
            </v-progress-circular>
        </div>

        <section-content
                v-show="!loadingReferenceContent && !referenceError"
                type="ai_navigator"
                :selected-section-data="sectionData">
        </section-content>
        <div v-if="referenceError && !loadingReferenceContent" class="text-center">Something went wrong or data not
            found.
        </div>
    </div>
</template>

<script>
import {ApiService} from "../api/ApiService";
import SectionContent from "./dashboard/quick_access/section_content.vue";

export default {
    components: {SectionContent},
    props: {
        sectionMeta: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            loadingReferenceContent: false,
            referenceError: null,
            sectionData: null,
        }
    },
    methods: {
        loadContents(metaData) {
            this.loadingReferenceContent = true;
            this.referenceError = '';

            ApiService.getReferenceSection(metaData.meta_xmlId)
                .then((response) => {
                    if (response.data) {

                        let selectedSection = {
                            'documentId': response.data.content.documentId,
                            'documentSku': response.data.content.documentSku,
                            'sectionId': response.data.contentId,
                            'chapterId': response.data.content.chapterId,
                            'sectionContent': response.data.content.section_text,
                            'sectionTitle': '',
                            'isDefinitionSection': response.data.content.isDefinitionSection,
                            'metaXmlId': metaData.meta_xmlId
                        };

                        this.sectionData = selectedSection;

                    } else {
                        this.loadingReferenceContent = false;
                        this.referenceError = 'data not found';
                    }
                })
                .catch((err) => {
                    this.referenceError = err;
                })
                .finally(() => {
                    this.loadingReferenceContent = false;
                });
        }
    },
    mounted() {

        this.$watch(() => this.sectionMeta, (newValue) => {
            if (newValue) {
                this.loadContents(newValue);
            } else {
                this.sectionData = null
            }
        }, {
            immediate: true
        });
    }
}
</script>
