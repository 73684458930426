var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "white" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h4 font-weight-bold" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.heading) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }