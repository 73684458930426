<template>
    <div v-if="isloadingMetaInfo" class="d-flex flex-column pa-8 align-center">
        <p class="mb-2">Loading link information</p>
        <v-progress-linear
            :active="true"
            color="primary"
            indeterminate
        ></v-progress-linear>
    </div>
    <div class="pa-10" v-else>
        <a target="_blank" :href="linkUrl">
            <div style="font-size:20px">
                {{ tabTitle }}
                <v-icon  style="top:-12px">mdi-open-in-new</v-icon>
            </div>
            <div v-if="isMetaInfoAvailable">
                <p  class="text-caption py-2 mb-1 black--text">
                    {{ metaInfo.title }}
                </p>
                <v-card flat>
                    <v-card-text 
                        class="pa-2">
                        <v-row align="center">
                            <v-col
                                cols="auto"
                                class="py-0">
                                <v-img 
                                    :src="metaInfo.faviconUrl" 
                                    width="30">
                                </v-img>            
                            </v-col>
                            <v-col>
                                <p class="mb-1 font-weight-bold">
                                    {{ metaInfo.author }}
                                </p>
                                <p class="mb-0 text-caption">
                                    {{ linkUrl }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </a>
    </div>    
</template>

<script>
export default {
    data() {
        return {
            isloadingMetaInfo: false,
            metaInfo: {
                title: '',
                author: '',
                faviconUrl: ''
            }
        }
    },
    computed: {
        tabTitle(){
            this.getLinkUrl(this.linkUrl);
            return this.tagData.tag.title;
        },
        linkUrl(){
            return this.tagData.tag.content;
        },
        isMetaInfoAvailable(){
            return !_.isEmpty(this.metaInfo.title) ? true : false; 
        }
    },
    props: {
        tagData: {
            type: Object,
            required: true
        }
    },
    methods: {
        getLinkUrl(linkUrl){
            this.isloadingMetaInfo = true;
            fetch(linkUrl)
                .then(response => response.text())
                    .then(html => {
                        this.isloadingMetaInfo = false;
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(html, "text/html");
                        this.metaInfo.title = doc.querySelector('title').textContent;
                        this.metaInfo.author = doc.querySelector('meta[name="author"]').getAttribute('content');
                        const faviconLink = doc.querySelector("link[rel~='icon']") || doc.querySelector("link[rel~='ICON']");
                        if (faviconLink) {
                            const faviconHref = faviconLink.getAttribute('href');
                            let faviconUrl = new URL(faviconHref, linkUrl);
                            if (faviconUrl) {
                                this.metaInfo.faviconUrl = faviconUrl.href;
                            } else {
                                this.metaInfo.faviconUrl = '';
                            }
                        } else {
                            this.metaInfo.faviconUrl = '';
                        }
                })
                .catch(error => {
                    // if meta info not available
                    this.isloadingMetaInfo = false;
                    this.metaInfo = {
                        title: '',
                        author: '',
                        faviconUrl: ''
                    }
                });
        }
    },
    mounted(){
        const linkUrl = this.tagData.tag.content;
        this.getLinkUrl(linkUrl);
    }
}
</script>
