<template>
    <div class="white">
        <v-container>
            <v-row>
                <v-col>
                    <h1 class="text-h4 font-weight-bold">
                        {{ heading }}
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-divider>
        </v-divider>
    </div>
</template>

<script>
    export default {
        name: 'GlobalHeader',
        props: {
            heading: {
                type: String,
                required: true
            }
        }
    }
</script>