import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

export const UpdatesModule = {
    state: {
        userCount: Vue.prototype.$cookies.get('userCount') !== null ?
            Vue.prototype.$cookies.get('userCount') :
            0,
        updateCount: 0,
        hasUpdate: false,
        updatesData: []
    },
    getters: {
        getUserCount: (state) => state.userCount,
        getUpdateCount: (state) => state.updateCount,
        hasUpdate: (state) => state.hasUpdate,
        getUpdatesData: (state) => state.updatesData,
    },
    mutations: {
        setUserCount(state) {
            let count = state.updateCount;
            Vue.prototype.$cookies.set('userCount', count);
            state.userCount = count;
        },
        setUpdateCount(state, value) {
            state.updateCount = value;
        },
        setHasUpdate(state, value) {
            state.hasUpdate = value;
        },
        setUpdatesData(state, value) {
            state.updatesData = value;
        }
    }
};
