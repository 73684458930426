var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.content
                ? [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
                : [
                    _c("v-progress-circular", {
                      staticClass: "my-12",
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }