var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("mega-menu", { attrs: { concurrent: _vm.concurrent } }),
      _vm._v(" "),
      _c("app-system-bar"),
      _vm._v(" "),
      !_vm.isMobileAndTablet
        ? _c("navigation", {
            ref: "appNavigation",
            attrs: { concurrent: _vm.concurrent },
          })
        : _c("navigation-mobile", {
            ref: "appNavigation",
            attrs: { concurrent: _vm.concurrent },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }