var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    {
      attrs: { accordion: "", multiple: true },
      model: {
        value: _vm.openPanels,
        callback: function ($$v) {
          _vm.openPanels = $$v
        },
        expression: "openPanels",
      },
    },
    [
      _c("v-data-iterator", {
        staticClass: "w-full",
        attrs: { items: _vm.data, "items-per-page": 10 },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                _c("div", { staticClass: "pt-12 text-md-center" }, [
                  _c("p", [_vm._v("You do not have any bookmarks or notes.")]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ items, isExpanded, expand }) {
              return _vm._l(items, function (item, index) {
                return _c(
                  "v-expansion-panel",
                  { key: index },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c("title-cover", {
                                  attrs: {
                                    title: item.document,
                                    type: "list",
                                    preventLink: true,
                                    showVersion: true,
                                    showFavorite: false,
                                    showLinkIcon: false,
                                    listOutline: false,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "pr-7", attrs: { cols: "auto" } },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        class: {
                                          small:
                                            _vm.$vuetify.breakpoint.smAndDown,
                                        },
                                        attrs: {
                                          type: "button",
                                          color: "primary",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.migrateNotesClicked(
                                              item.document.document_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Migrate Notes\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-4",
                                        class: {
                                          small:
                                            _vm.$vuetify.breakpoint.smAndDown,
                                        },
                                        attrs: {
                                          type: "button",
                                          color: "primary",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clickedNotesLog(
                                              item.document.document_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        true
                                          ? _c("span", [_vm._v("Notes Log")])
                                          : _c("v-progress-circular", {
                                              attrs: {
                                                size: 20,
                                                indeterminate: "",
                                                color: "white",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center mt-3",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(item.notes.length) + " Notes"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "vuetify-checkbox-label v-label theme--light",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.documentSelectAllSelections,
                                                expression:
                                                  "documentSelectAllSelections",
                                              },
                                            ],
                                            staticClass:
                                              "vuetify-checkbox ma-1 pa-0",
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              value: item.document.document_id,
                                              checked: Array.isArray(
                                                _vm.documentSelectAllSelections
                                              )
                                                ? _vm._i(
                                                    _vm.documentSelectAllSelections,
                                                    item.document.document_id
                                                  ) > -1
                                                : _vm.documentSelectAllSelections,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.documentSelectAllSelections,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v =
                                                        item.document
                                                          .document_id,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.documentSelectAllSelections =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.documentSelectAllSelections =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.documentSelectAllSelections =
                                                      $$c
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.selectAllDocumentNotesChanged(
                                                    item.document.document_id
                                                  )
                                                },
                                              ],
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "\n                                        Select all notes\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      { staticClass: "notes-expand grey lighten-4" },
                      [
                        _c("v-data-iterator", {
                          attrs: { items: item.notes },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ items, isExpanded, expand }) {
                                  return [
                                    _vm._l(items, function (item, index) {
                                      return [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { md: "11" } },
                                              [
                                                _c("note-card", {
                                                  attrs: { item: item },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "1" },
                                              },
                                              [
                                                item.type === "annotation" ||
                                                item.type === "highlight"
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "vuetify-checkbox-label v-label theme--light",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.selectedNotes,
                                                              expression:
                                                                "selectedNotes",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "vuetify-checkbox",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            value:
                                                              "annotation_" +
                                                              item.id,
                                                            checked:
                                                              Array.isArray(
                                                                _vm.selectedNotes
                                                              )
                                                                ? _vm._i(
                                                                    _vm.selectedNotes,
                                                                    "annotation_" +
                                                                      item.id
                                                                  ) > -1
                                                                : _vm.selectedNotes,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm.selectedNotes,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      "annotation_" +
                                                                      item.id,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      (_vm.selectedNotes =
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        ))
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      (_vm.selectedNotes =
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          ))
                                                                  }
                                                                } else {
                                                                  _vm.selectedNotes =
                                                                    $$c
                                                                }
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.noteSelectionChanged(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                                            Select for Sharing and/or Printing\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === "bookmark"
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "vuetify-checkbox-label v-label theme--light",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.selectedNotes,
                                                              expression:
                                                                "selectedNotes",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "vuetify-checkbox",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            value:
                                                              "bookmark_" +
                                                              item.bookmarkId,
                                                            checked:
                                                              Array.isArray(
                                                                _vm.selectedNotes
                                                              )
                                                                ? _vm._i(
                                                                    _vm.selectedNotes,
                                                                    "bookmark_" +
                                                                      item.bookmarkId
                                                                  ) > -1
                                                                : _vm.selectedNotes,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm.selectedNotes,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      "bookmark_" +
                                                                      item.bookmarkId,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      (_vm.selectedNotes =
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        ))
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      (_vm.selectedNotes =
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          ))
                                                                  }
                                                                } else {
                                                                  _vm.selectedNotes =
                                                                    $$c
                                                                }
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.noteSelectionChanged(
                                                                  item
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                                            Select for Sharing and/or Printing\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }