import {ProfileApi} from "../api/ProfileApi";

// disable caching, because there is no way to clear this when you click DISCONNECT from admin backend
const userProfiles = null; //JSON.parse(localStorage.getItem('userProfiles'));

const getterTypes = {
    profiles: 'Profiles',
    switchLoading: 'SwitchLoading'
}

const mutationTypes = {
    setProfiles: 'SetProfiles',
    removeProfile: 'RemoveProfile'
}

const actionTypes = {
    getProfiles: 'GetProfiles'
}

const getters = {
    [getterTypes.profiles]: (state) => state.profiles
}

const mutations = {
    [mutationTypes.setProfiles](state, data) {
        localStorage.setItem('userProfiles', JSON.stringify(data));
        state.profiles = data;
        EventBus.fire('full-page-loader', false)
    },
    [mutationTypes.removeProfile](state, id) {
        const index = _.findIndex(state.profiles, item => item.metadata === id);

        if (index >= 0) {
            state.profiles.splice(index, 1);

            const data = JSON.parse(localStorage.getItem('userProfiles'));
            const indexInLocalStorage = _.findIndex(data, item => item.metadata === id);

            if (indexInLocalStorage >= 0) {
                data.splice(indexInLocalStorage, 1);
                localStorage.setItem('userProfiles', JSON.stringify(data));
            }
        }
    }
}

const state = {
    profiles: userProfiles ? userProfiles : []
}

const actions = {
    async [actionTypes.getProfiles]({commit}, user = null) {
        try {
            if(!userProfiles){
                const response = await ProfileApi.getProfiles(user);
                commit(mutationTypes.setProfiles, response.data)
                return response;
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export const ProfilesModule = {
    namespaced: true,
    getterTypes,
    mutationTypes,
    state,
    getters,
    mutations,
    actions
}
