var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("p", { staticClass: "primary--text caption mb-0" }, [
              _vm._v(
                "\n                Assigned by Me licenses are those where the individual who purchases the premium subscription is different than the individual who is able to use the premium subscription.  Assigned licenses are dedicated access to the individual as identified by the email address.\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary licenses-step-3_1",
                  attrs: { block: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openAssignModal()
                    },
                  },
                },
                [_vm._v("\n                Assign Subscription\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "" } },
        [
          _c(
            "v-data-iterator",
            {
              ref: "assigned_by_me_table",
              staticClass: "elevation-0 no-margin parent-row",
              attrs: {
                items: _vm.subscriptions,
                "items-per-page": _vm.pagination.rowsPerPage,
                loading: _vm.loading,
                "single-expand": true,
                "footer-props": {
                  itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
                },
                "server-items-length": _vm.pagination.totalItems,
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (data) {
                    return [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.checkPanelEvent(data)
                                              },
                                            },
                                          },
                                          [_vm._v("expand_more")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "", align: "center" } },
                                [
                                  _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.bookLink(
                                              data.item.document_info
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("graphic", {
                                          attrs: {
                                            title: data.item.document_info,
                                            thumbnail: "",
                                            width: "40px",
                                            additionalClasses: "mr-2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-col", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.bookLink(
                                              data.item.document_info
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("book-title", {
                                          attrs: {
                                            title: _vm.getBookTitle(
                                              data.item.document_info
                                            ),
                                            print_version_edition:
                                              _vm.printEdition(
                                                data.item.document_info
                                                  .print_version_edition.version
                                              ),
                                            printing_text:
                                              data.item.document_info
                                                .print_info,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "grey lighten-4" },
                            [
                              _c("v-data-table", {
                                staticClass: "no-margin details-row",
                                attrs: {
                                  headers: _vm.assigned,
                                  items: data.item.assigned_by_me_licenses,
                                  loading: _vm.detailsLoading,
                                  "items-per-page": -1,
                                  "hide-default-footer": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              staticClass:
                                                "green lighten-5 text-center",
                                            },
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Assigned Licences"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Assigned To"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        item.assigned_to
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item
                                                                    .assigned_to
                                                                    .email
                                                                )
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.pending_user_email
                                                                  )
                                                              ),
                                                            ]),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Assigned Date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                item.created_at,
                                                                "MMMM Do, YYYY"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Expiration Date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm._f("moment")(
                                                                _vm.getExpirationDate(
                                                                  data,
                                                                  item
                                                                ),
                                                                "MMMM Do, YYYY"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Subscription Type"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.checkSubscriptionType(
                                                                data.item
                                                                  .subscription_type
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("v-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-bold mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Subscription Status"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.checkStatus(
                                                                _vm.getExpirationDate(
                                                                  data,
                                                                  item
                                                                ),
                                                                item.status
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          class: {
                                                            xs12:
                                                              _vm.checkStatus(
                                                                _vm.getExpirationDate(
                                                                  data,
                                                                  item
                                                                ),
                                                                item.status
                                                              ) === "Active",
                                                            xs6:
                                                              _vm.checkStatus(
                                                                _vm.getExpirationDate(
                                                                  data,
                                                                  item
                                                                ),
                                                                item.status
                                                              ) !== "Active",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getLicenseUsageReport(
                                                                      data.item
                                                                        .document_info
                                                                        .sku
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    Usage Report\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.checkStatus(
                                                        _vm.getExpirationDate(
                                                          data,
                                                          item
                                                        ),
                                                        item.status
                                                      ) !== "Active"
                                                        ? [
                                                            _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.goToTheShop(
                                                                            _vm.shopEncodesUrl
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Renew"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.checkStatus(
                                                    _vm.getExpirationDate(
                                                      data,
                                                      item
                                                    ),
                                                    item.status
                                                  ) === "Active"
                                                    ? _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "primary",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editItem(
                                                                          data,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Reassign"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "error",
                                                                    dark: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteItem(
                                                                          data,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Revoke"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "template",
                { slot: "no-data" },
                [
                  !_vm.loading
                    ? _c(
                        "v-card",
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: true },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("p", { staticClass: "mt-4" }, [
                        _vm._v("You do not have any Premium subscriptions."),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Re-assign this subscription license"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.errorMessage.length
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        value: true,
                                        outline: "",
                                        color: "error",
                                        icon: "warning",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.errorMessage) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editedItem.assigned_to
                                ? _c("v-text-field", {
                                    attrs: {
                                      "data-qa":
                                        "dashboard-licenses-reassign-email",
                                      label: "Enter new email address",
                                    },
                                    model: {
                                      value: _vm.editedItem.assigned_to.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem.assigned_to,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.assigned_to.email",
                                    },
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      "data-qa":
                                        "dashboard-licenses-reassign-email",
                                      label: "Enter new email address",
                                    },
                                    model: {
                                      value: _vm.editedItem.pending_user_email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "pending_user_email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.pending_user_email",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  !_vm.reassign_loading
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.close },
                        },
                        [_vm._v("Cancel")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.reassign_loading,
                      },
                      on: { click: _vm.save },
                    },
                    [
                      !_vm.reassign_loading
                        ? _c("span", [_vm._v("Save")])
                        : _c("v-progress-circular", {
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "white",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AssignModal", { attrs: { "user-id": _vm.userId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }