<template>
    <v-container 
        fluid 
        class="grey lighten-5 full-height" 
        style="min-height: 100vh" 
        data-qa="enterprise-manage-users">
        <!-- <v-row>
            <v-col
                v-if="isLargeAndUp"
                lg="auto">
                <enterprise-nav>
                </enterprise-nav>
            </v-col>
            <v-col class="pt-0">
                <manage-users></manage-users>
            </v-col>
        </v-row> -->
        <enterprise-name></enterprise-name>
        <v-row class="mx-2">
            <v-col>
                <enterprise-nav :handleTabClick="tabClicked" :tab="1">
                </enterprise-nav>
            </v-col>
        </v-row>
        <v-row class="mx-2">
            <v-col>
                <account-overview v-if="panel === 'Account Overview'"></account-overview>
                <manage-users v-else-if="panel === 'Manage Users'"></manage-users>
                <activity-report v-else :profile-id="profileId"></activity-report>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import EnterpriseNav from './../partials/enterprise_nav';
    import ManageUsers from './partials/manage_users';
    import AccountOverview from './../overview/partials/account_overview';
    import ActivityReport from './../activity/partials/activity_report';
    import EnterpriseName from './../partials/enterprise_name';

    export default {
        props: ["profileId"],
        data: function () {
            return {
                loading: true,
                panel: 'Manage Users'
            };
        },
        mixins: [Mobile],
        components: {
            EnterpriseNav,
            ManageUsers,
            AccountOverview,
            ActivityReport,
            EnterpriseName
        },
        methods: {
            tabClicked(activeTab){
                this.panel = activeTab;
            }
        },
        computed: {
            getActivePanel() {
                return this.panel;
            }
        }
    }
</script>
