import axios from 'axios'

export const QuickAccessApi = {
    http: axios.create(),
    getRecentlyAccessed() {
        return this.http.get(Routing.generate('recently_accessed'));
    },
    getAutoSuggest(search) {
        let postData = {
            search : search,
            forQuickAccess: 1
        };
        return this.http.post(Routing.generate('autosuggest_titles'), postData);
    },
    getVersions(id) {
        return this.http.get(Routing.generate('associated_books', {document_id: id}));
    },
    getSections(search, documentId, contentId = 0) {
        let getData = {
            search: search,
            document: documentId,
            contentId: contentId
        };
        return this.http.get(Routing.generate('get_section_titles', getData))
    }
}
