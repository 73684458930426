var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.dialog_share_annotation_bookmark,
        callback: function ($$v) {
          _vm.dialog_share_annotation_bookmark = $$v
        },
        expression: "dialog_share_annotation_bookmark",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v("\n            Share Notes\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.content_shared
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: { value: true, type: "success" },
                              model: {
                                value: _vm.content_shared,
                                callback: function ($$v) {
                                  _vm.content_shared = $$v
                                },
                                expression: "content_shared",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.share_success_message) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.error_message
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                value: _vm.error_status,
                                color: "error",
                                icon: "warning",
                                outline: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.error_message) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.emails, function (email, index) {
                return _c(
                  "v-row",
                  { key: index, attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          key: index,
                          class: { "mb-1": _vm.$vuetify.breakpoint.smAndUp },
                          attrs: {
                            "hide-details": "",
                            solo: "",
                            label: "Enter email address",
                          },
                          model: {
                            value: email.email,
                            callback: function ($$v) {
                              _vm.$set(email, "email", $$v)
                            },
                            expression: "email.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { sm: "auto" } },
                      [
                        index === 0
                          ? _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addNewEmail()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Add\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeEmail(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Remove\n                    "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          solo: "",
                          label:
                            "Enter your message/notes to recipient here...",
                          rows: "5",
                          "auto-grow": "",
                          counter: "1000",
                        },
                        model: {
                          value: _vm.message,
                          callback: function ($$v) {
                            _vm.message = $$v
                          },
                          expression: "message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeModal } },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              !_vm.content_shared
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.shareLoading,
                        disabled: _vm.shareLoading,
                      },
                      on: { click: _vm.inputFiledValidation },
                    },
                    [_vm._v("\n                Submit\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }