var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-space-between h-full" },
    [
      _c(
        "div",
        { staticClass: "h-full d-flex flex-column align-center" },
        [
          _c(
            "chat-message",
            { staticClass: "mb-1", attrs: { type: "system" } },
            [
              _c("p", [
                _vm._v(
                  "\n                Hello, I am ICC’s AI Navigator. I am trained to answer simple code questions. Please ask me a question and I’ll do my best. I am still learning, so your feedback on my answer helps me get better.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "font-weight-bold mb-2" },
                [
                  _vm.isRecentAskedQuestionsAvailable
                    ? [
                        _vm._v(
                          "\n              Your most recently asked questions:\n            "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n              Not sure where to start? Try one of these questions:\n            "
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _vm.loadingQuestion
                ? _c("v-progress-linear", {
                    staticStyle: { "min-width": "10em" },
                    attrs: {
                      active: true,
                      color: "primary",
                      indeterminate: "",
                    },
                  })
                : _vm._l(_vm.questions, function (question, key) {
                    return _c("p", { key: key, staticClass: "mb-2" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.questionClicked(question)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.isRecentAskedQuestionsAvailable
                                  ? question.question
                                  : question
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ])
                  }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.canSendMessages
        ? _c("div", { staticClass: "d-none d-md-flex" }, [
            _c(
              "div",
              { staticClass: "primaryLight rounded pa-3" },
              [
                _c("v-icon", { attrs: { color: "black" } }, [
                  _vm._v("mdi-arrow-down"),
                ]),
                _vm._v("\n            Start with your question here\n        "),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }