var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list-none pl-0" },
    [
      _vm._l(_vm.links, function (item, index) {
        return [
          _c(
            "li",
            [
              item.active
                ? _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: {
                        href:
                          !item.checkAccess ||
                          (item.checkAccess && _vm.isActiveUser)
                            ? item.link
                            : null,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "updateComponent",
                            item.component ? item.component : null
                          )
                        },
                      },
                    },
                    [
                      !_vm.hideIcon
                        ? _c(
                            "v-list-item-icon",
                            { staticClass: "mr-2" },
                            [
                              item.checkAccess && !_vm.isActiveUser
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "grey lighten-1" } },
                                    [
                                      _vm._v(
                                        "\n                    mdi-lock-outline\n                "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: item.iconColor
                                          ? item.iconColor
                                          : "primary",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.icon) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "fs-16 font-weight-bold",
                          class:
                            item.checkAccess && !_vm.isActiveUser
                              ? "grey--text lighten-1"
                              : "accent--text",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n                "
                          ),
                          item.chip
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2 primary--text cp",
                                  attrs: { small: "", color: "primaryLight" },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.chip) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.new
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-2 primary--text cp",
                                  attrs: { small: "", color: "primaryLight" },
                                },
                                [
                                  _vm._v(
                                    "\n                    New\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-icon",
                        [
                          item.component
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { color: "accent" },
                                  on: {
                                    click: [
                                      function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return item.component.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function ($event) {
                                        return _vm.$emit(
                                          "updateComponent",
                                          item.component ? item.component : null
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    mdi-chevron-right\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }