var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.topics, function (item, key) {
      return _c(
        "v-chip",
        {
          key: key,
          staticClass: "cdpBlue--text my-1",
          class: !_vm.isMobilePort && "ml-2",
          attrs: { small: "", color: "cdpBlueLight" },
          on: {
            click: function ($event) {
              _vm.answerViewPage
                ? _vm.goToRoute("premium_answers", { topic: item.id })
                : _vm.$emit("topicClick", item.id)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(item.title) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }