var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    _vm._l(_vm.categoryList, function (category, key) {
      return _c(
        "v-col",
        { key: key, attrs: { cols: "12", sm: "3" } },
        [
          _vm.hasSubCategory(category.children) && _vm.isMediumAndUp
            ? [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "open-on-click": "",
                      "offset-y": "",
                      "nudge-left": _vm.calculateNudgeLeft(category),
                      transition: false,
                      "max-width": "90%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      block: "",
                                      "x-large": _vm.xLargeButtons,
                                      color: "primary",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(category.name) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("arrow_drop_down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-list",
                      {
                        staticClass: "list_with_cols",
                        style: _vm.calculateColumnCountStyle(category),
                      },
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              href: _vm.getCategoryUrlFromSlug(category.slug),
                            },
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v("Browse All " + _vm._s(category.name)),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(
                          category.children,
                          function (subcategory, index) {
                            return _c(
                              "v-list-item",
                              {
                                key: index,
                                attrs: {
                                  href: _vm.getCategoryUrlFromSlug(
                                    subcategory.slug
                                  ),
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(subcategory.name)),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      block: "",
                      "x-large": _vm.xLargeButtons,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.visitCategory(category.key)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(category.name) +
                        "\n            "
                    ),
                  ]
                ),
              ],
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }