export default {
    methods: {
        validateLinksInNote(html) {
            let link;
            let regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/ig;

            while ((link = regex.exec(html)) !== null) {
                if (!this.validURL(link[2])) {
                    return false;
                }
            }
            return true;
        },
        validURL(url) {
            let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(url);
        }
    }
}
