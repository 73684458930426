var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-block-wrapper mt-5" },
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            attrs: { size: 32, color: "primary", indeterminate: "" },
          })
        : [
            _c(
              "div",
              {
                staticClass:
                  "content-block-header primary pa-3 white--text rounded-t-lg d-flex align-center cursor-pointer",
                class: _vm.isExpanded ? "rounded-t-lg" : "rounded-lg",
                attrs: { id: _vm.name },
                on: { click: _vm.clickedExpand },
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { large: "", color: "white" } },
                  [
                    _vm.isExpanded
                      ? [
                          _vm._v(
                            "\n                    mdi-chevron-up\n                "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                    mdi-chevron-down\n                "
                          ),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._t("title"),
                _vm._v(" "),
                !_vm.hasTitleSlot
                  ? _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(this.title)),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("v-expand-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isExpanded,
                      expression: "isExpanded",
                    },
                  ],
                  staticClass:
                    "content-block-content pa-3 rounded-b-lg elevation-2",
                },
                [_vm._t("default")],
                2
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }