var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { on: { click: _vm.callMapPage } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", "content-class": "map-tooltip" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c("span", _vm._g({}, on), [
                    _c("p", [_vm._v("Find Codes by Location")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: _vm.asset(require("../../images/landing_map.png")),
                      },
                    }),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", { staticClass: "tooltip-content" }, [
            _vm._v("Click to access the map search."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }