<template>
    <v-sheet elevation="2" class="mt-md-8">
        <v-tabs
            v-model="activeTab"
            show-arrows
            >
            <v-tabs-slider color="accent2"></v-tabs-slider>
            <v-tab
                v-for="item in items"
                :key="item.id"
                @click="tabAction(item.text)"
                class="text-capitalize no-border">
                {{ item.text }}
            </v-tab>
        </v-tabs>
    </v-sheet>
</template>

<script>
    export default {
        props: {
            tab :{
                type: Number,
                default: 0
            },
            handleTabClick: {
                type: Function,
            }
        },
        data() {
            return {
                activeTab: 0,
                items: [
                    {
                        text: 'Account Overview',
                        //icon: 'mdi-view-list',
                        href: Routing.generate('enterprise_account_overview')
                    },
                    {
                        text: 'Manage Users',
                        //icon: 'mdi-account-supervisor',
                        href: Routing.generate('enterprise_manage_users')
                    },
                    {
                        text: 'Activity Report',
                        //icon: 'mdi-file-chart',
                        href: Routing.generate('enterprise_activity_report')
                    },
                ],
            };
        },
        methods: {
            tabAction(tab){
                this.handleTabClick(tab);
            }
        },
        mounted(){
            this.activeTab  = this.tab;
        }
    }
</script>
<style scoped>
    .v-tabs-bar{
        background-color: unset !important;
    }
    .v-tab {
        color: #0b5940!important;
    }
    .v-tab--active {
        background: unset !important;
        font-weight: 700!important;
        color:rgba(0,0,0,.87) !important;

    }   
</style>
