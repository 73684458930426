var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "text-center pa-0",
      class: { "white--text primary": _vm.dark },
      attrs: { id: "login-form-component" },
    },
    [
      _vm.showLogo !== false
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("img", {
                  attrs: {
                    src: _vm.asset(require("../../../images/palogo.jpg")),
                    width: "95%",
                  },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                _vm._l(_vm.errorsAll, function (error, key) {
                  return _c(
                    "v-chip",
                    {
                      key: key,
                      staticClass: "mx-1",
                      attrs: { color: "red", "text-color": "white" },
                    },
                    [
                      _vm._v(
                        "\n                " + _vm._s(error) + "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: _vm.dense } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-form",
                {
                  class: { "mx-4": _vm.$vuetify.breakpoint.xsOnly },
                  attrs: {
                    "lazy-validation": "",
                    id: _vm.isModal ? "modal_login_form" : "login_form",
                    method: "post",
                    action: "/login",
                  },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.loginSubmit.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "border",
                    class: { "input-group--dirty": _vm.dirtyEmail },
                    attrs: {
                      solo: "",
                      flat: _vm.dark,
                      dense: _vm.dense,
                      "hide-details": "",
                      id: "emailAddress",
                      name: "email",
                      maxlength: "160",
                      placeholder: "Email address",
                      required: "",
                    },
                    model: {
                      value: _vm.email_address,
                      callback: function ($$v) {
                        _vm.email_address = $$v
                      },
                      expression: "email_address",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mt-4 border",
                    class: { "input-group--dirty": _vm.dirtyPwd },
                    attrs: {
                      solo: "",
                      flat: _vm.dark,
                      dense: _vm.dense,
                      "hide-details": "",
                      id: "password",
                      name: "password",
                      maxlength: "50",
                      type: "password",
                      placeholder: "Password",
                      required: "",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.csrfToken,
                        expression: "csrfToken",
                      },
                    ],
                    attrs: { type: "hidden", name: "_csrf_token" },
                    domProps: { value: _vm.csrfToken },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.csrfToken = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "_target_path" },
                    domProps: { value: _vm.currentPath },
                  }),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass: "mt-2",
                    attrs: {
                      color: _vm.dark ? "white" : null,
                      dark: _vm.dark,
                      name: "rememberMe",
                      value: "1",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "caption text-left",
                                class: { "white--text": _vm.dark },
                              },
                              [
                                _vm._v(
                                  "\n                            Keep me logged in for 30 days\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.rememberMe,
                      callback: function ($$v) {
                        _vm.rememberMe = $$v
                      },
                      expression: "rememberMe",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "light",
                      attrs: { dark: "", small: _vm.dense, color: "accent2" },
                      on: {
                        click: function ($event) {
                          return _vm.goToRoute("signup")
                        },
                      },
                    },
                    [_vm._v("\n                    Join Now\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "light",
                      attrs: {
                        dark: "",
                        small: _vm.dense,
                        color: "accent2",
                        type: "submit",
                      },
                      on: { click: _vm.loginSubmit },
                    },
                    [_vm._v("\n                    Sign In\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }