<template>
    <v-row dense>
        <v-col>
            <h4 class="mt-1">
                Billing Frequency
            </h4>
            <v-btn-toggle
                class="mt-2 rounded"
                v-model="frequency"
                mandatory
                tile
                active-class="primary white--text"
                @change="updateFrequency">
                <v-btn
                    outlined
                    :disabled="!getAvailableFrequencies.includes('M')"
                    :class="{'primary--text': frequency !== 'M'}"
                    :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                    value="M">
                    Monthly
                </v-btn>
                <v-btn
                    outlined
                    :disabled="!getAvailableFrequencies.includes('B')"
                    :class="{'primary--text': frequency !== 'B'}"
                    :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                    value="B">
                    Annually
                </v-btn>
                <v-btn
                    outlined
                    :disabled="!getAvailableFrequencies.includes('C')"
                    :class="{'primary--text': frequency !== 'C'}"
                    :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                    value="C">
                    3-Year
                </v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    export default {
        computed: {
            frequency: {
                get: function () {
                    return this.getFrequency;
                },
                // setter
                set: function (newValue) {

                }
            },
            ...mapGetters('PricingModule', ['billingPeriodText', 'getFrequency', 'getAvailableFrequencies'])
        },
        methods: {
            ...mapActions('PricingModule', ['setFrequency']),
            updateFrequency(value) {
                this.setFrequency(value);
            }
        }
    };
</script>
