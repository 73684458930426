var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c("h4", { class: { "fs-16": _vm.displayBigger } }, [
            _vm._v("License Quantity"),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "my-1", attrs: { align: "center", dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        small: "",
                        color: "primary",
                        disabled: _vm.quantity === 1 || _vm.actionDisabled,
                      },
                      on: { click: _vm.decrementQuantity },
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-minus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-text-field", {
                    staticClass: "center-aligned-input",
                    staticStyle: { "max-width": "50px" },
                    attrs: {
                      outlined: "",
                      value: _vm.quantity,
                      "hide-details": "",
                      dense: "",
                    },
                    on: { input: _vm.updateQuantity },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.actionDisabled,
                        icon: "",
                        small: "",
                        color: "primary",
                      },
                      on: { click: _vm.incrementQuantity },
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }