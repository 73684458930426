var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      clearable: "",
                      "hide-details": "",
                      placeholder: `Search ${_vm.label}`,
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-sheet",
        { attrs: { "max-height": "250px" } },
        [
          _c(
            "v-list",
            { staticClass: "pt-0" },
            [
              _c("v-data-iterator", {
                attrs: {
                  items: _vm.filters,
                  "hide-default-footer": "",
                  search: _vm.search,
                  "item-key": "name",
                  "items-per-page": -1,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ items }) {
                      return _vm._l(items, function (item, key) {
                        return _c(
                          "v-list-item",
                          {
                            key: key,
                            on: {
                              click: function ($event) {
                                return _vm.activateFilter(item, _vm.filterKey)
                              },
                            },
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          1
                        )
                      })
                    },
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c("p", { staticClass: "caption" }, [
                          _vm._v(
                            "\n                        No results found\n                    "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }