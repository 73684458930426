<template>
    <div>
        <v-card
            outlined
            class="grey lighten-4">
            <v-card-text class="py-0 px-3">
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <p class="font-weight-bold mb-0">
                            Saved Searches ({{ savedSearches.length ? savedSearches.length : 0 }})
                        </p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col class="pb-3">
                        <v-progress-linear
                            v-if="loading"
                            color="grey lighten-2"
                            indeterminate
                            rounded
                            height="6">
                        </v-progress-linear>
                        <template v-else>
                            <v-list
                                v-if="savedSearches.length"
                                dense
                                subheader
                                class="pa-0">
                                <v-list-item
                                    v-for="(result, index) in savedSearches"
                                    :key="index"
                                    :href="result.url"
                                    @click.stop.prevent="clickedSavedSearch(result)">
                                    <v-list-item-content :href="result.url">
                                        <v-list-item-title class="caption">
                                            {{ result.label }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="ma-0">
                                        <v-btn
                                            x-small
                                            text
                                            icon
                                            class="ma-0 pa-0 right"
                                            @click.stop.prevent="deleteSearch(result.id)">
                                            <v-icon>
                                                delete
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <p
                                v-else
                                class="mb-0 caption">
                                No saved searches
                            </p>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <confirm-remove-search>
        </confirm-remove-search>
    </div>
</template>

<script>
    import ConfirmRemoveSearch from '../../dialogs/confirm_remove_search.vue';
    import {EventNames} from "../../../../classes/EventNames";
    import Mobile from '../../../inc/mixins/mobile';

    export default {
        data() {
            return {
                loading: true,
                savedSearches: []
            }
        },
        components: {
            ConfirmRemoveSearch
        },
        mixins: [Mobile],
        methods: {
            getSavedSearches() {
                this.loading = true;
                this.$http.get(Routing.generate('list_save_search'))
                    .then(response => {
                        this.savedSearches = response.data.map((value) => {

                            if (value.searchId) {
                                value.url = '/search/content?sid=' + value.searchId;
                            } else {
                                value.url = "";
                            }

                            return value;
                        });
                        this.loading = false;
                    });
            },
            deleteSearch(id) {
                EventBus.fire('open-confirm-remove-search', id);
            },
            clickedSavedSearch(item) {
                this.loading = true;
                window.location = item.url;
            },
        },
        created() {
            EventBus.listen(EventNames.INIT_CONTENT_SEARCH, () => {
                this.getSavedSearches();
            });
            EventBus.listen('get-saved-searches', () => this.getSavedSearches());
        },
        mounted(){
            //for mobile view, component render in modal, which requires data
            if (this.isMobilePort) {
                this.getSavedSearches();
            }
        }
    }
</script>
