<template>
    <div
            class="w-full d-flex align-start border elevation-2 rounded pa-3 chat-message"
            :style="isSystemType ?
            `border-left: 6px solid ${$vuetify.theme.themes.light.primaryMedium}` :
            `border-left: 6px solid ${$vuetify.theme.themes.light.lightgray}`"
    >

        <asset-image
                v-if="isSystemType"
                :src="require('../../../images/ai_navigator/ai_navigator_icon.svg')"
                width="40"
                class="mr-4"
        ></asset-image>

        <v-avatar
                v-else
                color="grey font-weight-bold lighten-2"
                size="40"
                class="mr-4">

            <template v-if="userNameInitials">
                {{ userNameInitials }}
            </template>

            <v-icon v-else>
                mdi-account
            </v-icon>

        </v-avatar>

        <div class="api-response pa-0" style="overflow-x: hidden;">
            <slot></slot>
        </div>

    </div>
</template>

<script>
import AssetImage from "../../AssetImage.vue";

export default {
    components: {AssetImage},
    props: {
        type: {
            type: String,
            required: false,
            default: "user"
        }
    },
    computed: {
        isUserType() {
            return this.type === 'user';
        },
        isSystemType() {
            return this.type === 'system';
        }
    }
}
</script>

<style lang="scss" scoped>
.chat-message {
  background: white;
}
</style>
