<template>
    <v-app-bar
        :app="!isMobilePort"
        clipped-left
        flat
        width="100%"
        dense
        :height="isMobilePort ? '120px' : '64px'"
        id="digital-reference-navigation"
        class="white elevation-1">
        <v-row
            align="center"
            dense
            :class="{'mt-n3': isMobileAndTablet}">
            <v-col cols="auto">
                <img
                    :src="this.asset(require('../../images/exams/assessment_center.png'))"
                    :style="isMediumAndDown ? 'max-height: 26px' : 'max-height: 36px'">
            </v-col>
            <v-spacer v-if="isMobilePort">
            </v-spacer>
            <v-col
                v-if="isMobilePort"
                cols="auto"
                class="text-right">
                <exam-back-button
                    :is-pronto-referer="isProntoReferer"
                    :small="true">
                </exam-back-button>
            </v-col>
            <v-col
                cols="12"
                sm="auto">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-tooltip
                            bottom
                            z-index="205">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary">
                                    info
                                </v-icon>
                            </template>
                            <span class="tooltip-content">
                                <p class="mb-0 font-italic caption">
                                    Full title contents available for viewing with browser search (Ctrl + F).<br/>
                                    Access all available titles from Digital References dropdown.
                                </p>
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col>
                        <p
                            class="font-weight-bold mb-0 subtitle-1"
                            :class="{'caption': isSmallOnly}"
                            style="line-height: 18px">
                            {{ userExamTitle }} Digital References <br v-if="isSmallOnly"/>for online proctored ICC exams
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="!isMobilePort"
                class="text-right">
                <exam-back-button
                    :is-pronto-referer="isProntoReferer">
                </exam-back-button>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';

    import ExamBackButton from "./partials/ExamBackButton";
    import SearchTextBar from '../titles/partials/SearchTextBar';

    export default {
        name: 'DigitalReferenceHeader',
        mixins: [
            Mobile
        ],
        components: {
            ExamBackButton,
            SearchTextBar
        },
        props: {
            userExamTitle: {
                type: String,
                required: true
            },
            isProntoReferer :{
                required: true
            }
        },
        methods: {
            goBackClicked() {
                window.location = Routing.generate("user_exams");
            }
        },
        mounted() {
            console.log(this.isProntoReferer);
        }
    }
</script>

<style lang="scss">
    #digital-reference-navigation{
        z-index: 10;
        .v-toolbar__content{
            display: grid !important;
        }
        @media (min-width: 600px) and (max-width: 960px){
            .v-toolbar__content, .v-toolbar__extension{
                padding: 20px 20px;
            }
        }
        @media screen and (max-width: 600px) {
            .v-toolbar__content, .v-toolbar__extension{
                padding: 8px 8px;
            }
        }
        @media screen and (max-width: 300px) {
            .logo-img{
                max-height: 18px !important;
            }
        }
    }
</style>
