export default {
    data: function() {
        return {
            isOpen: false,
            results: [],
            search: "",
            isLoading: false,
            arrowCounter: 0,
            isAsync: false,
            items: [],
        };
    },
    props: ["fromFavorites"],
    methods: {
        onChange() {
            // Let's warn the parent that a change was made
            this.$emit("input", this.search);

            // Is the data given by an outside ajax request?
            if (this.isAsync) {
                this.isLoading = true;
            } else {
                // Let's search our flat array
                if(_.size(this.search) > 0) {
                    this.isLoading = true;
                    this.debouncedGetResults();
                    this.isOpen = true;
                } else {
                    this.isOpen = false;
                    this.arrowCounter = 0;
                    this.$session.set('search_term', '');
                }
            }
        },
        getResults() {
            this.$session.set('search_term', this.search);
            if(this.search.length) {
                let postData = {
                    search : this.search,
                    forQuickAccess: 0
                };
                this.$http.post(Routing.generate('autosuggest_titles'), postData)
                    .then(response => {
                        let data = [];
                        if(response.data.length){
                            response.data.forEach(function(item){
                                if(item !== undefined){
                                   data.push(item);
                                }
                            });
                        }
                        this.items = data;
                    });
            }
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter += 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter -= 1;
            }
        },
        onEnter() {
            // To select first option (Kept for reference)
            //this.search = this.results[this.arrowCounter];
            //this.isOpen = false;
            //this.arrowCounter = -1;

            // Search typed content
            this.searchTitles();
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target) && !evt.target.closest("#searchIconToggle")) {
                this.isOpen = false;
                this.arrowCounter = -1;
                EventBus.fire('set-search-expand', false);
            }
        },
        searchTitles() {
            // let category = this.$session.get('category', 'all');
            //     category = ('all' != category && '' != category) ? 'category[]=' + category + '&' : '';

            let search = '';
            if (this.search !== undefined && this.search !== '') {
                search = '?search=' + this.search;
            }

            let route = function () {
                window.location = Routing.generate('search_page') + search;
            };

            // Log user searched terms for the autosuggestion entries
            let promise = this.logUserSearchTerms();

            promise.finally(route);
        },
        logUserSearchTerms() {
            return this.$http.get(Routing.generate('log_user_search_terms', {'search': this.search}));
        },
        populateSearch(search){
            this.search = search;
            this.searchTitles();
        },
        setSearchedText() {
            let searchTerm = this.$session.get('search_term', '');
            if (searchTerm != undefined) {
                this.search = searchTerm;
            }
        }
    },
    watch: {
        items: function (val, oldValue) {
            this.results = val;
            this.isLoading = false;
        }
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    created() {
        EventBus.listen('search-titles', (search) => this.populateSearch(search));
        this.debouncedGetResults = _.debounce(this.getResults, 500);
        this.setSearchedText();
    }
}
