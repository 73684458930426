var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.isMobilePort,
        scrollable: "",
        "max-width": "700px",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "font-weight-bold oxygen fs-20",
              style: `border-top: 4px solid ${_vm.$vuetify.theme.themes.light.accent2}`,
            },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "accent2" } },
                [_vm._v("\n                    mdi-creation\n                ")]
              ),
              _vm._v(
                "\n                Get Started with Premium\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "my-4 fs-16 font-weight-medium" }, [
                _vm._v(
                  "\n                Premium gives you code tools that go beyond Basic read-only access:\n            "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.premiumTools, function (item, key) {
                return _c(
                  "v-row",
                  { key: key, attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        !item.icon
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "px-1 font-weight-bold mx-1",
                                attrs: {
                                  "x-small": "",
                                  color: "accent2",
                                  title: "",
                                  label: "",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        P\n                    "
                                ),
                              ]
                            )
                          : _c("v-icon", { attrs: { color: "accent2" } }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.icon) +
                                  "\n                    "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", [
                      _c(
                        "p",
                        { staticClass: "font-weight-medium mb-1 fs-16" },
                        [_c("b", [_vm._v(_vm._s(item.title))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "fs-16 mb-2 roboto",
                          staticStyle: { "line-height": "24px" },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.description) +
                              " \n                        "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-underline",
                              attrs: {
                                href: item.learnMorelink,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Learn More")]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("p", { staticClass: "fs-16 mt-4 mb-0 font-weight-medium" }, [
                _vm._v(
                  "\n                Do more with your Premium subscription:\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c("b", { staticClass: "fs-16" }, [
                              _vm._v("Code Intelligence Tools"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.leftButtons, function (item, key) {
                        return _c(
                          "v-row",
                          { key: key },
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      outlined: "",
                                      color: "primary",
                                      href: item.link,
                                      target: "_blank",
                                      block: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pr-1",
                                        attrs: { color: "primary" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.icon) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.title) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c("b", { staticClass: "fs-16" }, [
                              _vm._v("Onboarding Resources"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.rightButtons, function (item, key) {
                        return _c(
                          "v-row",
                          { key: key },
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      outlined: "",
                                      color: "primary",
                                      href: item.link,
                                      target: "_blank",
                                      block: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pr-1",
                                        attrs: { color: "primary" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(item.icon) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.title) +
                                        "\n                            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobilePort ? "12" : "" } },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _c("b", [
                          _vm._v("Need support? "),
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-underline",
                              attrs: {
                                href: this.$getConst("links")["contactSupport"],
                                target: "_blank",
                              },
                            },
                            [_vm._v("Contact Us")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: !_vm.isMobilePort
                        ? "text-right"
                        : "text-center pt-2",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Close\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }