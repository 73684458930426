var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pdf.access
        ? [
            _c(
              "v-layout",
              { staticClass: "hidden-md-and-up", attrs: { row: "" } },
              [
                _c(
                  "v-flex",
                  { staticClass: "text-xs-right d-inline pt-2" },
                  [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Adjust font Size:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { fab: "", small: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.zoomOut()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("remove"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { fab: "", small: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.zoomIn()
                          },
                        },
                      },
                      [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("add")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    class: {
                      "pa-2": !_vm.getLoggedIn,
                      "pr-12": !_vm.isMobilePort,
                    },
                  },
                  [
                    !_vm.stillLoading
                      ? _c("pdf-iframe", {
                          attrs: {
                            url: _vm.pdf.pdf_url_full,
                            pdf: _vm.pdf,
                            contentProtection: _vm.contentProtection,
                          },
                        })
                      : _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("back-to-top"),
          ]
        : _vm.stillLoading
        ? [
            _c(
              "v-layout",
              { attrs: { row: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "text-center": "", "py-12": "" } },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-layout",
              [
                _c("v-flex", { class: { "pa-2": !_vm.getLoggedIn } }, [
                  _c("p", { staticClass: "text-center my-12" }, [
                    _vm._v("You do not have access to this book."),
                  ]),
                ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }