<template ref="request_reset">
    <v-container>
        <v-row class="request_password_reset">
            <v-col
                    order="2"
                    order-sm="1"
                    cols="12"
                    sm="6"
                    v-if="content">
                <div class="login-content" v-html="content"></div>
            </v-col>
            <v-col
                    order="1"
                    order-sm="2"
                    cols="12"
                    sm="6">
                <v-card>
                    <v-card-title>
                        <h2 class="primary--text title">Request Password Reset</h2>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="errors.length > 0" class="error-message mb-4">
                            <span v-for="(error, key) in errors">
                                <br>
                                <v-chip
                                        color="red"
                                        text-color="white"
                                        small>
                                {{ error }}
                            </v-chip>
                            </span>
                        </div>
                        <v-form
                                ref="form"
                                lazy-validation
                                id="request_password_reset"
                                method="post"
                                action="/request-reset"
                                :class="{ 'mx-4': $vuetify.breakpoint.xsOnly }">
                            <v-row dense>
                                <v-col>
                                    <v-text-field
                                            solo
                                            hide-details
                                            id="registrationEmail"
                                            name="email"
                                            maxlength="50"
                                            v-model="email"
                                            placeholder="Email"
                                            class="border"
                                            required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <input type="hidden" v-model="token" name="_csrf_token">
                        </v-form>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                        type="submit"
                                        color="primary"
                                        @click="requestReset">
                                    Request Password Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: ['content', 'token', 'email'],
        data: () => ({
            code: '',
            errors: []
        }),
        methods: {
            requestReset() {
                this.errors = [];
                if (!this.email) {
                    this.errors.push('Email Is Required');
                    document.getElementById("registrationEmail").parentNode.classList.add('validate');
                }
                if (this.errors.length === 0) {
                    document.getElementById("request_password_reset").submit()
                }
            }
        }
    }
</script>
<style>
    .login-content img {
        max-width: 100%;
    }
</style>
