var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(
        _vm.$store.getters.getSearchNavigationCategories,
        function (navigationItem, a) {
          return [
            _c(
              "p",
              { staticClass: "mb-0 caption px-4 grey white--text darken-2" },
              [_vm._v(_vm._s(a))]
            ),
            _vm._v(" "),
            _c("v-treeview", {
              ref: "treeview",
              refInFor: true,
              attrs: {
                activatable: "",
                active: [_vm.activeCategory],
                open: _vm.openNodes,
                items: navigationItem,
                "item-key": "key",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function ({ item, active }) {
                      return _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setTreeCategory(item)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.getCategoryUrl(item) },
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.name) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      )
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.category,
                callback: function ($$v) {
                  _vm.category = $$v
                },
                expression: "category",
              },
            }),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }