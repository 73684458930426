<template>
    <div class="grey lighten-5 container-height">
        <div class="white">
            <v-container class="content-container">
                <v-row>
                    <v-col>
                        <h1>
                            Account
                        </h1>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider>
            </v-divider>
        </div>
        <v-container class="content-container">
            <alert-bar
                class="mt-4"
                dismissible
                :message="message"
                :notification-type="type"
                @dismiss="[message = null, type = null]">
            </alert-bar>
            <v-row>
                <v-col
                    cols="12"
                    md="6">
                    <v-card
                        outlined
                        class="mb-4">
                        <v-card-text>
                            <h2>
                                <v-icon class="mt-n1 black--text">
                                    mdi-account-outline
                                </v-icon>
                                Personal Details
                            </h2>
                            <loading-linear
                                v-if="loading.user"
                                :show-message="false"
                                container-padding="px-0 pt-8 pb-4">
                            </loading-linear>
                            <template v-else>
                                <p class="font-weight-bold mt-4 mb-1">
                                    Name
                                </p>
                                <p v-if="name">
                                    {{ name }}
                                </p>
                                <p class="font-weight-bold mt-4 mb-1">
                                    Email
                                </p>
                                <p
                                    v-if="email"
                                    class="mb-0">
                                    {{ email }}
                                </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-card outlined>
                        <v-card-text>
                            <h2>
                                <v-icon class="mt-n1 black--text">
                                    mdi-shield-outline
                                </v-icon>
                                Security
                            </h2>
                            <p class="font-weight-bold mt-4 mb-1">
                                Password
                            </p>
                            <p>
                                Your password can be changed on your myICC account.
                            </p>
                            <a
                                class="font-weight-bold"
                                href="https://my.iccsafe.org/profile/info/change-password"
                                title="Manage Password"
                                target="_blank">
                                Manage Password
                            </a>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    md="6">
                    <v-card outlined>
                        <v-card-text class="pb-0">
                            <h2>
                                <v-icon class="mt-n1 black--text">
                                    mdi-cog-outline
                                </v-icon>
                                Preferences
                            </h2>
                            <p class="font-weight-bold mt-4 mb-1">
                                Title Image
                            </p>
                            <p>
                                Title cards and title overview will display on Digital Codes with new icons or the print book cover.
                            </p>
                            <loading-linear
                                v-if="loading.preferences"
                                :show-message="false"
                                container-padding="px-0 pt-4 pb-8">
                            </loading-linear>
                            <v-radio-group
                                v-else
                                class="custom-radios"
                                v-model="selected"
                                :disabled="loading.preferencesSaving">
                                <v-radio
                                    value="icon"
                                    @click="updatePreferences('icon')">
                                    <template v-slot:label>
                                        <div>
                                            <p class="mb-1">
                                                New Digital Codes icons
                                            </p>
                                            <list
                                                listOutline
                                                :override-cover="true"
                                                :title="defaultTitle"
                                                :show-version="false"
                                                :show-favorite="false"
                                                :show-link-icon="false">
                                            </list>
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    class="mt-2"
                                    value="cover"
                                    @click="updatePreferences('cover')">
                                    <template v-slot:label>
                                        <div>
                                            <p class="mb-1">
                                                Print book cover (legacy)
                                            </p>
                                            <list
                                                listOutline
                                                width="70px"
                                                :override-title-icon="true"
                                                :title="defaultTitle"
                                                :show-version="false"
                                                :show-favorite="false"
                                                :show-link-icon="false">
                                            </list>
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import TitleList from "../../components/titles/cover/List.vue";

    const DEFAULT_TITLE_ID = '3309'; // 2024 IBC

    import List from "../../components/titles/cover/List.vue";
    import {ApiService} from "../../api/ApiService";
    import {AsyncUtils} from "../../util/AsyncUtils";
    import AlertBar from "../../components/notifications/AlertBar.vue";
    import LoadingLinear from "../../components/loaders/LoadingLinear.vue";

    export default {
        name: 'DashboardAccount',
        data() {
            return {
                defaultTitle: null,
                selected: null,
                email: null,
                name: null,
                message: null,
                type: null,
                loading: {
                    user: true,
                    preferences: true,
                    preferencesSaving: false
                },
                preferenceKey: this.$getConst('preferenceKey')
            }
        },
        components: {
            LoadingLinear,
            AlertBar,
            TitleList,
            List
        },
        methods: {
            fetchDefaultTitle() {
                this.$http.get(Routing.generate('get_book_info', {
                    'documentId': DEFAULT_TITLE_ID
                })).then(
                    response => {
                        if (response.data) {
                            this.defaultTitle = response.data;
                        }
                        this.$set(this.loading, 'preferences', false);
                    }
                )
            },
            async updatePreferences(value, showNotification = true) {
                this.$set(this.loading, 'preferencesSaving', true);
                const response = await ApiService.updatePreference({[this.preferenceKey]: value});
                if (response.success) {
                    if (showNotification) {
                        this.message = 'Account settings updated successfully';
                        this.type = 'success';
                    }
                } else {
                    this.message = 'Failed to update account settings';
                    this.type = 'error';
                }
                this.$set(this.loading, 'preferencesSaving', false);
            },
            checkIsSet() {
                AsyncUtils.awaitUntil(() => {
                    return this.$store.getters.getUser !== null;
                }, 200).then(() => {
                    const user = this.$store.getters.getUser;
                    this.name = user.first_name + ' ' + user.last_name;
                    this.email = user.email;
                    this.$set(this.loading, 'user', false);

                    const coverView = user.getPreference([this.preferenceKey]);
                    if (coverView !== undefined) {
                        this.selected = coverView
                    } else {
                        this.updatePreferences(null, false);
                    }
                });
            }
        },
        created() {
            this.fetchDefaultTitle();
        },
        mounted() {
            this.checkIsSet();
        }
    }
</script>

<style lang="scss">
    .custom-radios {
        .v-radio {
            align-items: start;
        }
        .v-radio>.v-label {
            display: inline;
        }
    }
</style>