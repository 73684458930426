<template>
    <div>
        <breadcrumb title="Favorites"></breadcrumb>
        <v-container class="favorite-dashboard">
            <v-row justify="center">
                <v-col xl="8">
                    <v-card class="grey lighten-5">
                        <v-toolbar
                            flat
                            color="primary"
                            dark>
                            <v-toolbar-title>
                                <v-icon color="accent2">favorite</v-icon> Manage Favorites
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <p>Click the heart icon while viewing any book to flag as a favorite. Once a book is flagged, it is added to the existing favorites and displayed as per the priority order below, that you can change via drag and drop. The top 10 favorite titles will appear in your left hand green sidebar for quick access from anywhere on the site.</p>
                                    <v-divider>
                                    </v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p>To add a book as a favorite, please search for it below, and select the heart icon to add it as a favorite:</p>
                                    <autocomplete></autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="mb-0">Book Title</p>
                                </v-col>
                            </v-row>
                            <template v-if="!favoritesLoading">
                                <draggable
                                    :list="favorites"
                                    :disabled="!enabled"
                                    ghost-class="ghost"
                                    @end="updateSortOrder()">
                                    <v-row
                                        align="center"
                                        v-for="(favorite, index) in favorites"
                                        :key="favorite.id">
                                        <v-col
                                            v-if="!isMobilePort"
                                            cols="auto">
                                            <v-icon
                                                class="cp"
                                                color="primary">
                                                mdi-sort
                                            </v-icon>
                                        </v-col>
                                        <v-col>
                                            <book-cover
                                                :title="favorite.title"
                                                type="list"
                                                :showVersion="true"
                                                :showFavorite="true">
                                            </book-cover>
                                        </v-col>
                                    </v-row>
                                </draggable>
                            </template>
                            <template v-else>
                                <div class="text-center pt-6">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </div>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import draggable from "vuedraggable";
    import BookCover from '../titles/cover/Index';
    import Autocomplete from '../autocomplete/favorites';
    import Breadcrumb from '../content/partials/page_titles';
    import Mobile from './../inc/mixins/mobile';

    let id = 1;
    export default {
        name: "simple",
        display: "Simple",
        order: 0,
        components: {
            BookCover,
            draggable,
            Autocomplete,
            Breadcrumb
        },
        data() {
            return {
                enabled: true,
                dragging: false
            };
        },
        mixins: [Mobile],
        methods: {
            executeUpdateSort() {
                let formData = new FormData();
                formData.append('favorites', JSON.stringify(this.favorites));

                this.$http.post(Routing.generate('set_favorite_books_sort'), formData).then(
                    response => {
                        if(response.data['success']) {
                            EventBus.fire("reset-favorites");
                        }
                    }
                );
            },
            updateSortOrder() {
                this.updateSort();
            },
            printEdition(data) {
                if (!_.isUndefined(data)) {
                    return data.version;
                }
            },
            linkAction(book) {
                window.location.href = this.getBookURL(book.document_id);
            },
        },
        created() {
            this.updateSort = _.debounce(this.executeUpdateSort, 500);
        }
    };
</script>

<style lang="scss">
    .favorite-dashboard {
        .search-code-titles, .list-group-item {
            border: 1px solid #adadad;
        }
        .list-group-item {
            cursor: move;
        }
    }
</style>
