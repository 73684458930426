<template>
    <v-row dense>
        <v-col cols="auto">
            <v-btn
                class="mx-2"
                fab
                x-small
                :color="sectionId === activeBookmark ? 'primary' : ''"
                @click="setActiveBookmark()">
                <v-icon dark>
                    <template v-if="sectionId === activeBookmark">
                        bookmark
                    </template>
                    <template v-else>
                        bookmark_outline
                    </template>
                </v-icon>
            </v-btn>
        </v-col>
        <v-col>
            <v-card
                flat
                class="grey lighten-5 scroll">
                <v-card-text>
                    <div v-html="html"></div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
                activeBookmark: 0
            }
        },
        props: {
            html: {
                required: true,
                type: String
            },
            sectionId: {
                required: true,
                type: Number
            }
        },
        methods: {
            setActiveBookmark() {
                EventBus.fire('set-active-bookmark', this.sectionId)
            }
        },
        mounted() {
            EventBus.listen('set-active-bookmark-callback', (data) => this.activeBookmark = data);
        }
    }
</script>

<style lang="scss" scoped>
    .scroll {
        overflow-x: auto;
    }
</style>
