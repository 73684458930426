var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialog_process_annotation_bookmark,
        callback: function ($$v) {
          _vm.dialog_process_annotation_bookmark = $$v
        },
        expression: "dialog_process_annotation_bookmark",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.action) + " Notes\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 accent2--text",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog_process_annotation_bookmark = false
                    },
                  },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "text-center pt-8" },
            [
              _c("p", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
              _vm._v(" "),
              _c(
                "v-alert",
                {
                  attrs: {
                    value: _vm.error_status,
                    color: "error",
                    icon: "warning",
                    outlined: "",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.error_message) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", primary: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog_process_annotation_bookmark = false
                    },
                  },
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.submitValidation },
                },
                [_vm._v("\n                Ok\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }