var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      directives: [
        {
          name: "click-outside-directive",
          rawName: "v-click-outside-directive",
          value: _vm.hideContextMenu,
          expression: "hideContextMenu",
        },
      ],
      attrs: { id: "context-menu" },
    },
    [
      _c(
        "li",
        {
          staticClass: "context-menu-item",
          on: {
            click: function ($event) {
              return _vm.menuAction("open")
            },
          },
        },
        [_vm._v("Open Link")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "context-menu-item",
          on: {
            click: function ($event) {
              return _vm.menuAction("new-tab")
            },
          },
        },
        [_vm._v("Open Link In New Tab")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "context-menu-item",
          on: {
            click: function ($event) {
              return _vm.menuAction("copy")
            },
          },
        },
        [_vm._v("Copy Link")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }