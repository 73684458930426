<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    Concurrent Use licenses allow the individual who purchases the premium subscription to make access available to anyone who knows the concurrent code. Concurrent access is limited by the number of simultaneous users who are able to access the premium content and features at the same time. Concurrent codes, associated books and quantity are subject to change at the discretion of the individual who purchased the premium licenses.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    block
                    class="primary licenses-step-5_1"
                    @click="openConcurrencyModal()">
                    Generate Concurrent Code
                </v-btn>
            </v-col>
        </v-row>
        <v-expansion-panels accordion>
            <v-data-iterator
                :items="concurrent_codes"
                :items-per-page="pagination.rowsPerPage"
                :loading="loading"
                :single-expand="true"
                :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                class="elevation-0 no-margin parent-row"
                :server-items-length="pagination.totalItems">
                <template v-slot:item="data">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row dense>
                                <v-col>
                                    {{data.item.access_code}}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="grey lighten-4">
                            <v-data-table
                                :headers="license"
                                :items="data.item.assigned_subscriptions"
                                hide-default-footer
                                class="no-margin details-row">
                                <template v-slot:item="{ item }">
                                    <tr class="green lighten-5">
                                        <td>
                                            <v-row>
                                                <v-col cols="auto">
                                                    <graphic
                                                        :title="item.subscription.document_info"
                                                        thumbnail
                                                        width="40px"
                                                        additionalClasses="mr-2">
                                                    </graphic>
                                                </v-col>
                                                <v-col>
                                                    <a @click="bookLink(item.subscription.document_info)">
                                                        <book-title
                                                            :title="getBookTitle(item.subscription.document_info)"
                                                            :print_version_edition="printEdition(item.subscription.document_info.print_version_edition.version)"
                                                            :printing_text="item.subscription.document_info.print_info"></book-title>
                                                    </a>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Configured Licences</p>
                                                    {{item.licenses}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Created</p>
                                                    {{item.created_at | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Purchased Date</p>
                                                    {{item.subscription.purchase_date | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Expiration Date</p>
                                                    {{item.subscription.expiration_date | moment("MMMM Do, YYYY")}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Type</p>
                                                    {{checkSubscriptionType(item.subscription.subscription_type)}}
                                                </v-col>
                                                <v-col>
                                                    <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                    {{checkStatus(item.subscription)}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn
                                                        small
                                                        @click="getCodeAccessLogs(item.id, item.access_code)">
                                                        Usage Report
                                                    </v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        small
                                                        class="mr-2"
                                                        @click="goToTheShop(shopEncodesUrl)">
                                                        <span v-if="item.subscription.subscription_type === 'M'">Manage</span>
                                                        <span v-else>Renew</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn
                                                        small
                                                        @click="openConcurrencyModal(parent.item)">
                                                        Edit
                                                    </v-btn>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        small
                                                        class="error"
                                                        @click="removeCodeAlertModal(parent.item)">
                                                        Remove
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </template>
                <template slot="no-data">
                    <v-card v-if="!concurrent_codes_loaded">
                        <v-progress-linear :indeterminate="true"></v-progress-linear>
                    </v-card>
                    <p
                        class="mt-4"
                        v-if="concurrent_codes_loaded">You do not have any Premium subscriptions.</p>
                </template>
            </v-data-iterator>
        </v-expansion-panels>
        <ConcurrencyModal :user-id="userId" :key="componentKey"></ConcurrencyModal>
        <v-dialog v-model="removeCodeModal" max-width="500px">
            <v-card>
                <v-card-title>
                    Remove Concurrent code!
                </v-card-title>
                <v-card-text>
                    <p>
                        All subscriptions currently configured under the concurrent code will be moved over to
                        Personal Use and become available for assigning to another user or configuring within
                        a different concurrent code.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" flat @click="removeCodeModal=false">Cancel</v-btn>
                    <v-btn color="error" flat @click="removeCode()">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import Concurrent from '../mixins/concurrent.js';
    import Graphic from "../../../titles/cover/Graphic";
    import Constants from "../../../../constants/index";

    export default {
        mixins: [Licenses, Concurrent],
        components: {
            Graphic
        },
        data() {
            return {
                headers: [
                    {text: 'Code', align: 'left', value: 'access_code', expand: true}
                ],
                shopEncodesUrl: Constants.shopEncodesUrl
            }
        }
    }

</script>
