var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-4" },
        [
          _c(
            "v-col",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("img", {
                      staticStyle: { "max-width": "100%" },
                      attrs: { src: _vm.getBookCover(_vm.item.document_info) },
                      on: { error: _vm.loadDefaultBookCover },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "9" } }, [
                    _c(
                      "p",
                      { staticClass: "font-weight-bold mb-0" },
                      [
                        _vm.item.document_info.year
                          ? [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.item.document_info.year.year) +
                                  " " +
                                  _vm._s(_vm.item.document_info.title) +
                                  "\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.item.document_info.title) +
                                  "\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "caption mb-0 text--grey" }, [
                      _vm._v("Item #: " + _vm._s(_vm.item.document_info.sku)),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "caption mb-0" }, [
                      _vm._v("Subscription Price"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "caption font-weight-bold" }, [
                      _vm._v("$" + _vm._s(_vm.itemPrice)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("p", { staticClass: "caption mb-0" }, [
                      _vm._v("Total Item Price"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "caption font-weight-bold" }, [
                      _vm._v("$" + _vm._s(_vm.itemTotal)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.showExtra = !_vm.showExtra
                            },
                          },
                        },
                        [
                          !_vm.showExtra
                            ? [_c("v-icon", [_vm._v("keyboard_arrow_down")])]
                            : [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showExtra,
                      expression: "showExtra",
                    },
                  ],
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        [_c("quantity", { attrs: { item: _vm.item } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "right font-weight-bold remove_link error--text caption",
                            on: { click: _vm.openRemoveModal },
                          },
                          [
                            _vm._v(
                              "\n                            Remove\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [_c("duration", { attrs: { item: _vm.item } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }