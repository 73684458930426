<template>
    <iframe
        ref="pdfContent"
        v-on:load="updateFrameHeight($refs.pdfContent)"
        :src="'/static/secure.html?file=' + encodeURIComponent(this.pdfUrl)"
        width="100%"
        height="500">
    </iframe>
</template>

<script>
import PdfViewer from "../../titles/partials/pdf.vue";

export default {
    components: {PdfViewer},
    props: {
        tag: {
            type: Object,
            required: true
        }
    },
    computed: {
        pdfUrl() {
            const content = this.tag.tag.content;

            if (content) {
                return `/uploads/PremiumContentReportTag/${content}`;
            }

            return null;
        }
    }
}
</script>
